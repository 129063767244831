// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-select {
  width: 100%;
}

ion-item {
  /*border-radius: 13px; */
  --background: transparent;
}

ion-toolbar.toolbar-background {
  --background: #a11313 !important;
}
ion-toolbar.toolbar-background ion-button {
  margin-left: 15px !important;
}

/* Qui definisci lo stile normale del modal */
.select-class {
  background-color: rgba(150, 150, 150, 0.055);
  border-radius: 15px;
  border-width: 2px;
  border-color: #2F94C0;
  border-style: solid;
  margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/orders/components/modal-order-meta/modal-order-meta.component.scss"],"names":[],"mappings":"AAEA;EACI,WAAA;AADJ;;AAGA;EAEI,wBAAA;EACE,yBAAA;AADN;;AAIE;EACE,gCAAA;AADJ;AAEI;EACI,4BAAA;AAAR;;AAGA,6CAAA;AAGA;EACI,4CAAA;EAEA,mBAAA;EACA,iBAAA;EACA,qBAAA;EACA,mBAAA;EACA,gBAAA;AAHJ","sourcesContent":["\n\nion-select{\n    width: 100%;\n}\nion-item{\n    //margin-top: 10px;\n    /*border-radius: 13px; */\n      --background: transparent;\n  }\n  \n  ion-toolbar.toolbar-background {\n    --background: #a11313 !important;\n    ion-button{\n        margin-left: 15px !important;\n    }\n   }\n/* Qui definisci lo stile normale del modal */\n\n\n.select-class{\n    background-color: rgba(150, 150, 150, 0.055);\n    //padding: 3px;\n    border-radius: 15px;\n    border-width: 2px;\n    border-color: #2F94C0;\n    border-style: solid;\n    margin-top: 10px;\n}\n\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
