import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// import * as H from '@here/maps-api-for-javascript';

@Injectable({
  providedIn: 'root'
})
export class GeoLocationService {

  public platform: any;
  public geocoder: any;
  public coordinates = new BehaviorSubject<any>(null);

  public constructor() {
    // this.platform = new H.service.Platform({
    //   apikey: 'WvoIDiyn3Hivh38is8IOVrerzGjS_4JA9veL7TtEMzQ',
      
    //   //apikey: "cOvIk_c4BGPXxlRjDZMnw4LYVIJNf5R0CWdGPiN3a70"
    // });
    // this.geocoder = this.platform.getGeocodingService();
    
  }

  public getAddress(query: string) {
    return new Promise((resolve, reject) => {
      this.geocoder.geocode({ searchText: query }, result => {
        if (result.Response.View.length > 0) {
          if (result.Response.View[0].Result.length > 0) {
            this.coordinates.next(result.Response.View[0].Result)
            resolve(result.Response.View[0].Result)

          } else {

            this.coordinates.next({})
            reject({ message: "no results found" });
          }
        } else {
          this.coordinates.next({})
          reject({ message: "no results found" });
        }
      }, error => {
        reject(error);
      });
    });
  }

  public getAddressFromLatLng(query: string) {
    return new Promise((resolve, reject) => {
      this.geocoder.reverseGeocode({ prox: query, mode: "retrieveAddress" }, result => {
        if (result.Response.View.length > 0) {
          if (result.Response.View[0].Result.length > 0) {
            this.coordinates.next(result.Response.View[0].Result)
            resolve(result.Response.View[0].Result);
          } else {
            this.coordinates.next({})
            reject({ message: "no results found" });
          }
        } else {
          this.coordinates.next({})
          reject({ message: "no results found" });
        }
      }, error => {
        reject(error);
      });
    });
  }

}