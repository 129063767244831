import { WokrersService } from './../../services/workers/wokrers.service';

import { ChangeDetectionStrategy, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';

import { ModalController, IonRouterOutlet, Platform, IonNav, ToastController, IonSearchbar } from '@ionic/angular';
import { NewWorkerComponent } from '../new-worker/new-worker.component';

import { WorkerDetailsPage } from '../worker-details/worker-details.page';
import { ListWorkersComponent } from '../components/list/list.component';
import { TitleAppService } from 'src/app/services/utils/title-app/title-app.service';
//import { ChangeDetectorRef, AfterContentChecked } from '@angular/core';


const TIMEOUT = 5;

@Component({
  //changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-workers-list',
  templateUrl: './workers-list.page.html',
  styleUrls: ['./workers-list.page.scss'],
})
export class WorkersListPage /* implements AfterContentChecked */ {
  //workers: Observable<Worker[]>;
  workers: any = [];
  workers_columns = [];

  currentSegment: string = "All";
  color: string;
  disableLink = false;
  selectedItem: any = [];
  selecAllItems: boolean = true;
  timeoutPress = TIMEOUT;

  isLoad = false;

  total_page = 0;
  page_number = 1;
  page_limit = 30;

  toast: any;



  @ViewChild('prj') prjElement: ElementRef;
  @ViewChild('prjs') prjList: ElementRef;
  @ViewChild('star') btnStar: ElementRef;
  @ViewChild(IonSearchbar) myInput: IonSearchbar;
  @ViewChild(ListWorkersComponent) listTable: ListWorkersComponent;

  checkbox: boolean = false;

  constructor(
    //private cdref: ChangeDetectorRef,
    private api: WokrersService,
    public modalController: ModalController,
    private routerOutlet: IonRouterOutlet,
    private renderer: Renderer2,
    //private nav: IonNav,
    public plt: Platform,
    private toastController: ToastController,
    private titleAppService: TitleAppService
 
  ) {

    //this.workers = this.api.getProjectsList(true);

    this.workers_columns = [
      { prop: 'id', name: 'ID'/* , frozenLeft: true */, minWidth: 75, maxWidth: 75, cellClass: 'td-id', headerClass: 'th-id' },
      {
        prop: 'firstname', name: 'Name', width: 90, minWidth: 90, cellClass: 'td-priority', headerClass: 'th-priority'
      },
      {
        prop: 'code_fiscal', name: 'Code Fiscal', width: 90, minWidth: 90, cellClass: 'td-priority', headerClass: 'th-priority'
      },
      {
        prop: 'gender', name: 'Gender', width: 90, minWidth: 90, cellClass: 'td-priority', headerClass: 'th-priority'
      },
      {
        prop: 'birthcity', width: 90, minWidth: 90, cellClass: 'td-priority', headerClass: 'th-priority'
      },
      {
        prop: 'birthday', width: 220, minWidth: 220, cellClass: 'td-priority', headerClass: 'th-priority'
      }

    ]
  }

  ionViewWillEnter() {
    console.log('Will enter');
    this.isLoad = false;
    this.toast = undefined;
    this.titleAppService.title.next('Workers')


    this.timeoutPress = TIMEOUT;
    this.disableLink = false;
    this.total_page = 0;
    this.page_number = 1;
    this.myInput.value = "";
    this.loadItems(this.currentSegment);

  }

  filterProjects(event: any) {
    const selectedCategory = event.detail.value;
    this.currentSegment = selectedCategory;

    //this.loadItems(selectedCategory);
  }

  loadItems(status: string) {
    //if (status === 'All') { //category
    //this.workers = this.dataService.getProjects();
    /* this.api.getProjectsList_rev(url).subscribe(e => {
      if (e && e['results'])
        this.workers = e['results']
      console.log(this.workers);
    }) */


    this.api.getWokrersList().subscribe(data => {


      this.workers = data
      console.log(this.workers);
          
      this.listTable.listItems = this.workers
      this.listTable.totalElements = this.workers.length;
      this.listTable.total_page = 1;
      this.listTable.page_number = 1;

      setTimeout(() => {
        this.listTable.table.bodyComponent.offsetX = 0;
        this.listTable.table.bodyComponent.offsetY = 0;
        this.listTable.table.headerComponent.offsetX = 0;
        this.listTable.table.recalculateColumns();
        console.log('update list');
        this.listTable.listItems = [...this.listTable.listItems]
      }, 100);

      this.isLoad = true;

      /* } else {
        //this.workers = this.dataService.getProjectsByCategory(category);
        //this.workers = this.dataService.getProjectsByStatus(status);
        this.isLoad = true;
      } */

    })
  }



  async openNewProjectModal() {
    const modal = await this.modalController.create({
      component: NewWorkerComponent,
      canDismiss: true,
      presentingElement: this.routerOutlet.nativeEl,
    });

    modal.onWillDismiss().then((e) => {

      if (e.data && e.data.message != 'OK') {
        console.log('no modify');
        return
      } else {
        this.loadItems(this.currentSegment);

      }
    });

    return await modal.present();
  }

  randomColor() {
    const colors = ["#4caf50", "#ffc107", "#607d8b", "#2196f3", "#e91e63", "#673ab7", "#009688"];
    const indexColor = Math.floor(Math.random() * colors.length);
    const randomColor = colors[indexColor];
    //this.color == randomColor ? ,
    return randomColor; //this.color;
  }


  /*   ngAfterContentChecked() {
      this.cdref.detectChanges();
    } */

  methodAction(action) {
    try {
      this[action]()
    } catch {
      console.log('not call in parent component');
    }
    //console.log(action)
  }

  /**
   * @description onClick on row open order details
   * @param event pass obj row clicked
   */
  onActivate(event) {
    /* if (this.table.rowDetail && event.type == 'click') {
      this.table.rowDetail.toggleExpandRow(event.row);
      console.log('Activate Event', event);
    } */
    console.log(event);

    if (event.type == 'click') {
      console.log('Activate Event', event);
      this.goTo(event.row.id, event.row)
    }


  }




  /**
   * Select item on longpress + multiple select + selecAll
   * @param item Elemento della lista
   */

  activeLine(item) {
    setTimeout(() => {

      this.renderer.addClass(item, 'select');
      this.renderer.setStyle(item.firstChild, 'transform', 'rotate3d(0, 1, 0, 360deg)');
      this.renderer.setProperty(item.firstChild, 'innerHTML', '<ion-icon name="checkmark-outline"></ion-icon>');
      /* let icon = this.renderer.createElement('ion-icon');
      this.renderer.setAttribute(icon, 'name', 'checkmark-outline');
      this.renderer.appendChild(item, icon) */
    }, 100);
  }

  dectiveLine(item) {
    setTimeout(() => {
      this.renderer.removeClass(item, 'select');
      this.renderer.setStyle(item.firstChild, 'transform', 'rotate3d(0, 0, 0, 0deg)');
      let letter = item.innerText.split('')[0]
      this.renderer.setProperty(item.firstChild, 'innerHTML', '<span class="letter_badge">' + letter + '</span>');
    }, 100);
  }

  resetSelect() {
    this.disableLink = false;
    this.selectedItem.map((e, a, i) => {
      this.dectiveLine(e.el);
    });
    this.selectedItem = [];
  }

  selectAll(event, list) {
    let prjs = [];
    for (let e = 0; e <= list.el.children.length - 1; e++) {
      if (!event.detail.checked) {
        this.resetSelect();
      } else {
        this.activeLine(list.el.children[e]);
        prjs.push({
          el: list.el.children[e],
          id: Number(list.el.children[e].id)
        });
      }
    }
    this.checkbox = true;
    this.selectedItem = prjs;
  }

  goTo(index, user) {

    console.log(index, user);

    //console.log(this.nav);;

    //nav: this.nav(WorkerDetailsPage, { worker: user }, { animated: true, direction: 'forward' })



  }

  onLongPress(event, $event?) {
    console.log("onLongPress", $event);
    this.timeoutPress = TIMEOUT;

  }

  onLongPressing(event, $event?: MouseEvent) {
    if (this.timeoutPress == TIMEOUT) {

      //console.log("onLongPressing", event, $event);

      //console.log('onLongPressing2-2');

      this.renderer.addClass(event.el, 'select');
      this.renderer.setStyle(event.el.firstChild, 'transform', 'rotate3d(0, 1, 0, 360deg)');
      this.renderer.setProperty(event.el.firstChild, 'innerHTML', '<ion-icon name="checkmark-outline"></ion-icon');
      this.disableLink = true;
      this.timeoutPress = this.timeoutPress - 1;
      console.log(this.timeoutPress);
    }

  }



  clickStar(elem, event) {
    event.stopPropagation();
    console.log(elem);
    if (this.selecAllItems) {
      this.renderer.setProperty(elem.el, 'name', 'star')
      this.renderer.setStyle(elem.el, 'color', '#fff')
    } else {
      this.renderer.setProperty(elem.el, 'name', 'star-outline')
      this.renderer.setStyle(elem.el, 'color', '#fff')

    }
    this.selecAllItems = !this.selecAllItems;

  }


  async presentToast(text, color) {
    const toast = await this.toastController.create({
      message: text,
      position: 'bottom',
      duration: 2000,
      cssClass: 'toast',
      color: color
    });
    toast.present();
  }


}

