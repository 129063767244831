import { AuthenticationService } from '../services/authentication/authentication.service';

import { Storage } from '@ionic/storage';
import { BehaviorSubject, of } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

const TOKEN_KEY = 'access-token';
const REFRESH_TOKEN = 'refresh-token';


export function appInitializer(
  storage: Storage,
  authenticationService: AuthenticationService
) {
  return () => new Promise(resolve => {
    // attempt to refresh token on app start up to auto authenticate
    console.log('%c Create db ', 'background-color:#4CAF50;color:#fff;border-radius:4px');

    const token = JSON.parse(localStorage.getItem(TOKEN_KEY));    
    const refresh = localStorage.getItem(REFRESH_TOKEN) != 'undefined' ? JSON.parse(localStorage.getItem(REFRESH_TOKEN)) : null;
    const applaunchCount = localStorage.getItem('launchCount');

    //Check if it already exists or not
    if (applaunchCount) {
      //This is a second time launch, and count = applaunchCount
      localStorage.setItem('launchCount', '1');
    } else {
      //Local storage is not set, hence first time launch. set the local storage item
      localStorage.setItem('launchCount', '0');

      //Do the other stuff related to first time launch
    }

    storage.create().then(e => {
      console.log('create db ', e);
      storage.length().then(_e => {
        console.log('length ', _e);
        storage.forEach((v, k, i) => {
          console.log(v, k, i);
          if (k == 'authService-access-token') {
            authenticationService.currentTokenSubject = new BehaviorSubject<any>(v || token)
            authenticationService.currentToken = authenticationService.currentTokenSubject.asObservable().pipe(distinctUntilChanged());
          }/* else if (k == 'authService-refresh-token') {
            authenticationService.currentRefreshTokenSubject = new BehaviorSubject<any>(v || refresh)
            authenticationService.currentRefreshToken = authenticationService.currentRefreshTokenSubject.asObservable().pipe(distinctUntilChanged());
          } */
        })
        if (_e == 0) {
          console.log('@ App INIT: 56');

          authenticationService.currentTokenSubject = new BehaviorSubject<any>(token || null)
          authenticationService.currentToken = authenticationService.currentTokenSubject.asObservable().pipe(distinctUntilChanged());
          /* authenticationService.currentRefreshTokenSubject = new BehaviorSubject<any>(refresh || null)
          authenticationService.currentRefreshToken = authenticationService.currentRefreshTokenSubject.asObservable().pipe(distinctUntilChanged()); */
        }

        setTimeout(() => {
          // attempt to refresh token on app start up to auto authenticate
          console.log('%c Check Authorized Token ', 'background-color:#4CAF50;color:#fff;border-radius:4px');
          authenticationService.checkRemoteToken(/* authenticationService.currentRefreshTokenSubject.value */ true)
            .subscribe(res => {
              console.log(res);
              if (res == 'exipred') {
                authenticationService.refreshToken(refresh).subscribe(e => {
                  console.log(e);

                })
              }
            })
            .add(resolve(0));
        }, 300);

      })

    })

  });
}
