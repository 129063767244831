import { AfterViewInit, ChangeDetectorRef, Component, ComponentFactoryResolver, ComponentRef, ElementRef, Input, OnInit, QueryList, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { ModalController, Platform, ToastController } from '@ionic/angular';
import { IonicSelectableComponent } from 'ionic-selectable';
import { CustomersService } from 'src/app/services/customers/customers.service';
import { ReportsService } from 'src/app/services/reports/reports.service';
import { ProjectsService } from 'src/app/services/projects/projects.service';
import { VendorsService } from 'src/app/services/vendors/vendors.service';

import { Report } from '../models/report.model';

export class Port {
  public id: number;
  public name: string;
}

@Component({
  selector: 'app-new-report',
  templateUrl: './new-report.component.html',
  styleUrls: ['./new-report.component.scss'],
})
export class NewReportComponent {

  @Input() project_in: any; // per un ipotentico inject se necessita di ereditare delle scelte
  @Input() title: string;
  @ViewChild('createForm') createForm: FormGroupDirective;
  @ViewChild('wrapperAddress', { read: ViewContainerRef }) target: ViewContainerRef;
  @ViewChildren(IonicSelectableComponent)
  vendorComponents: QueryList<IonicSelectableComponent>

  private componentRef: ComponentRef<any>;
  private emailRegex = "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$";
  private maxRowAddress = 5;
  private maxRowPhone = 5;
  private maxRowEmail = 5;
  // group form general
  createReportForm: FormGroup;
  types: any = [];
  categories: any = [];
  vendors: any = [];
  priorities: any = [];
  projects: any = [];
  customers: any = [];
  port: Port;

  response
  subscription

  constructor(
    private modalController: ModalController,
    private toastController: ToastController,
    private platform: Platform,
    private fb: FormBuilder,
    private api: ReportsService,
    private vendor: VendorsService,
    private project: ProjectsService,
    private customer: CustomersService

  ) {


  }

  addPortFormControl() {
    this.portFormControls.push(this.fb.control(null));

    // Wait for view to refresh after adding a new form control.
    // setTimeout(() => {
    //   this.portComponents.last.open();
    // });
  }

  removePortFormControl() {
    this.portFormControls.removeAt(this.portFormControls.length - 1)

    // Wait for view to refresh after adding a new form control.
    // setTimeout(() => {
    //   this.portComponents.last.open();
    // });
  }


  get portFormControls(): FormArray {
    return this.createReportForm.get('vendor_id') as FormArray;
  }

  getReportMeta() {

    let page_project = 1;
    let page_customer = 1;
    let page_vendor = 1;

    
    this.project.getProjectsList_rev('?page=' + page_project + '&items=' + 25).subscribe(e => {
      //console.log(e);
      this.projects = e.results;
    })
    this.customer.getCustomersList('?page=' + page_customer + '&items=' + 25).subscribe(e => {
      //console.log(e);
      this.customers = e.results;
    })
    this.vendor.getVendorsList('?page=' + page_vendor + '&items=' + 25).subscribe(e => {
      //console.log(e);
      this.vendors = e.results;
    })

  }

  checkProjectIn() {
    if (this.project_in)
      return this.project_in.id;
    else
      return ''
  }

  onChange(event) {
    console.log(event);
    //this.createReportForm.controls['vendor_id'].setValue(event.value.id)

  }

  dismissModal(message?) {
    if (message)
      this.response = message
    this.modalController.dismiss(this.response);
  }

  ionViewDidEnter() {
    this.subscription = this.platform.backButton.subscribe((e) => {
      console.log(e, this.response);
      this.dismissModal()
    });
  }

  ionViewWillLeave() {
    this.subscription.unsubscribe();
  }


  ionViewWillEnter() {

    this.getReportMeta()
    let _project = this.checkProjectIn();

    console.log(_project);

    this.createReportForm = this.fb.group({
      priority_id: this.fb.control('', Validators.required),
      //code: this.fb.control('', Validators.required),
      type_id: this.fb.control('', Validators.required),
      category_id: this.fb.control('', Validators.required),
      customer_id: this.fb.control('', Validators.required),
      /*  vendor_id: this.fb.array([
        // If seletable ionic  
         this.fb.control(null)
       ]) , */
      vendor_id: this.fb.control('', Validators.required),
      project_id: this.fb.control(_project, Validators.required),

    });

  }


  // Call submitForm with click Save
  submitForm() {
    console.log('onSubmit');
    this.createForm.onSubmit(undefined);
  }



  async presentToast(text, color) {
    const toast = await this.toastController.create({
      message: text,
      position: 'bottom',
      duration: 3000,
      cssClass: 'toast',
      color: color
    });
    return await toast.present();
  }

  createReport(values: any) {
    // copy all the form values into the new vendor
    const newReport: Report = { ...values };
    //newReport.created = moment(newReport.created).format('YYYY-MM-DD')


    console.log(newReport);

    /* this.api.createReport(newReport).subscribe(e => {
      console.log(e);

      if (e.message && e.message.includes('OK')) {
        this.presentToast(e.message, 'success')
        this.response = e.data
      } else {
        console.log('no modify');

      }
      this.dismissModal();
    }) */
  }



  reset() {
    this.createReportForm.reset();
  }

}
