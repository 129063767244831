import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stakeholders'
})
export class StakeholdersPipe implements PipeTransform {
  transform(stakeholders: any[]): string {
    // console.log(stakeholders);  
    if (!stakeholders || !Array.isArray(stakeholders) || stakeholders.length === 0) {
      return '';
    }
    return stakeholders.map(stakeholder => stakeholder.name || '').join(', ');
  }
}
