import { CurrencyPipe } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, ComponentFactoryResolver, ComponentRef, ElementRef, Input, OnInit, QueryList, Renderer2, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { IonSegment, ModalController, Platform, ToastController } from '@ionic/angular';
import { IonicSelectableComponent } from 'ionic-selectable';
import * as moment from 'moment';
import { CustomersService } from 'src/app/services/customers/customers.service';
import { OffersService } from 'src/app/services/offers/offers.service';
import { ProjectsService } from 'src/app/services/projects/projects.service';
import { VendorsService } from 'src/app/services/vendors/vendors.service';



export class Port {
  public id: number;
  public name: string;
}

@Component({
  selector: 'app-new-offer',
  templateUrl: './new-offer.component.html',
  styleUrls: ['./new-offer.component.scss'],
})
export class NewOfferComponent {

  tableRange = [
    {
      "id": 1,
      "start": 0,
      "end": 250,
      "code": "F1",
      "created": "2022-01-29T12:08:48.698115+01:00"
    }, {
      "id": 2,
      "start": 251,
      "end": 200000,
      "code": "F2",
      "created": "2022-01-29T12:08:48.698115+01:00"
    }, {
      "id": 3,
      "start": 200001,
      "end": 300000,
      "code": "F3",
      "created": "2022-01-29T12:08:48.698115+01:00"
    }, {
      "id": 4,
      "start": 300001,
      "end": 400000,
      "code": "F4",
      "created": "2022-01-29T12:08:48.698115+01:00"
    }, {
      "id": 5,
      "start": 400001,
      "end": 500000,
      "code": "F5",
      "created": "2022-01-29T12:08:48.698115+01:00"
    }
  ]

  @Input() project_in: any; // per un ipotetico inject se necessita di ereditare delle scelte
  @Input() title: string;
  @ViewChild('createForm') createForm: FormGroupDirective;
  @ViewChild('wrapperAddress', { read: ViewContainerRef }) target: ViewContainerRef;
  @ViewChild('priority') priority: ElementRef;

  private componentRef: ComponentRef<any>;
  private emailRegex = "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$";
  private maxRowAddress = 5;
  private maxRowPhone = 5;
  private maxRowEmail = 5;
  // group form general
  createOfferForm: FormGroup;
  types: any = [];
  categories: any = [];
  vendors: any = [];
  priorities: any = [];
  types_cost: any = [{ name: 'Single Cost', value: 's' }/* , { name: 'Multiple Cost', value: 'm' } */];
  type_cost: any;
  amount

  projects: any = [];
  customers: any = [];
  port: Port;

  response
  subscription

  options_lessor = [{ name: 'Locatore', value: true }, { name: 'Conduttore', value: false }]
  options_year = []
  actual_year = parseInt(moment(Date.now()).format('Y'))

  constructor(
    private modalController: ModalController,
    private toastController: ToastController,
    private platform: Platform,
    private fb: FormBuilder,
    private api: OffersService,
    private vendor: VendorsService,
    private project: ProjectsService,
    private customer: CustomersService,
    private renderer: Renderer2,
    private currencyPipe: CurrencyPipe

  ) {



    this.createOptionsYear();
  }

  createOptionsYear() {
    let past_year = 15;

    console.log(this.actual_year);

    for (let i = 0; i <= past_year; i++) {
      this.options_year.push(this.actual_year--)
    }
  }


  segmentPriority(event) {

    let val = event
    //console.log('segmentPriority', val.value);
    switch (val.value) {
      case '1':
        return 'low'
        break;
      case '2':
        return 'normal'
        break;
      case '3':
        return 'high'
        break;
      default:
        return 'normal'
        break;
    }
  }

  createBaseForm() {
    const amountInCurrencyFormated = this.currencyPipe.transform(0);


    let _project = this.checkProjectIn();

    console.log(_project, this.priorities, this.types, this.categories, this.projects, this.customers, this.vendors);

    this.createOfferForm = this.fb.group({

      //code: this.fb.control('', Validators.required),
      //offer_id: this.fthis.fb.control('', Validators.required),b.control('', Validators.required),
      //is_lessor: this.fb.control(true, Validators.required),
      subject: this.fb.control('', Validators.required),
      note: this.fb.control('', Validators.required),
      customer_id: this.fb.control(1, Validators.required),
      vendor_id: this.fb.control('', Validators.required),
      project_id: this.fb.control(_project, Validators.required),
      desc_cost: this.fb.control('', Validators.required),
      cost: this.fb.control(amountInCurrencyFormated, Validators.required),
      range: this.fb.control('', Validators.required),
    });

    this.createOfferForm.controls['customer_id'].disable()



    this.createOfferForm.controls['cost'].valueChanges.subscribe(e => {
      console.log(e)
    })
  }

  
  amountChanged(event) {
    console.log('event',event);
    let range
    this.amount = event;
    this.createOfferForm.controls['cost'].setValue(event)
      if (event <= 25099) {
        range = '1'

      } else if (event >= 25100 && event <= 20000099) {
        range = '2'

      }else if (event >= 20000100 && event <= 30000099) {
        range = '3'

      }else if (event >= 30000100 && event <= 40000099) {
        range = '4'

      }else if (event >= 40000100) {
        range = '5'

      }

      this.createOfferForm.controls['range'].setValue(range)
  }

  async getOfferMeta() {

    let page_project = 1;
    let page_customer = 1;
    let page_vendor = 1;

    this.projects = await this.project.getProjectsList_rev('?page=' + page_project + '&items=' + 25).toPromise().then(e => e.results)
    this.customers = await this.customer.getCustomersList('?page=' + page_customer + '&items=' + 25).toPromise().then(e => e.results)
    this.vendors = await this.vendor.getVendorsList('?page=' + page_vendor + '&items=' + 25).toPromise().then(e => e.results)

    let res = await Promise.all([this.projects, this.customers, this.vendors]);
    return res

  }

  checkProjectIn() {
    if (this.project_in)
      return this.project_in.id;
    else
      return ''
  }

  onChange(event) {
    console.log(event);
    //this.createOfferForm.controls['vendor_id'].setValue(event.value.id)
  }

  dismissModal(message?) {
    if (message)
      this.response = message
    this.modalController.dismiss(this.response);
  }

  async ionViewDidEnter() {
    let meta = await this.getOfferMeta();
    console.log(meta);
    if (meta['0'].length == 0 || meta[1].length == 0)
      return

    this.createBaseForm()

  }

  ionViewWillLeave() {
  }


  ionViewWillEnter() { }


  // Call submitForm with click Save
  submitForm() {
    console.log('onSubmit');
    this.createForm.onSubmit(undefined);
  }



  async presentToast(text, color) {
    const toast = await this.toastController.create({
      message: text,
      position: 'bottom',
      duration: 3000,
      cssClass: 'toast',
      color: color
    });
    return await toast.present();
  }

  createOffer(values: any) {
    // copy all the form values into the new vendor
    const newOffer: any = { ...values };
    //newOffer.created = moment(newOffer.created).format('YYYY-MM-DD')


    console.log(newOffer);

    this.api.createOffer(newOffer).subscribe(e => {
      console.log(e);

      if (e.message && e.message.includes('OK')) {
        this.presentToast(e.message, 'success')
        this.response = e.data
      } else {
        console.log('no modify');

      }
      this.dismissModal();
    })
  }

  reset() {
    this.createOfferForm.reset();
  }

  setTypeCost(e) {
    this.type_cost = e;

  }





}
