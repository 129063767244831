// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.project-name {
  color: #4CAF50; /* Verde chiaro */
}

.job-code {
  color: #FF9800; /* Arancione acceso */
}

ion-accordion-group {
  border-radius: 15px;
  border-style: solid;
  border-color: var(--ion-color-primary);
  border-width: 2px;
}

ion-accordion {
  border-radius: 15px;
  margin: 0 auto;
}

ion-accordion.accordion-expanding,
ion-accordion.accordion-expanded {
  width: calc(100% - 32px);
  margin: 20px auto;
}`, "",{"version":3,"sources":["webpack://./src/app/timesheet/timesheet-charts/timesheet-charts.page.scss"],"names":[],"mappings":"AAAA;EACE,cAAA,EAAA,iBAAA;AACF;;AAEA;EACE,cAAA,EAAA,qBAAA;AACF;;AAEA;EACE,mBAAA;EACA,mBAAA;EACA,sCAAA;EACA,iBAAA;AACF;;AAEA;EACE,mBAAA;EACA,cAAA;AACF;;AAEA;;EAEE,wBAAA;EAEA,iBAAA;AAAF","sourcesContent":[".project-name {\n  color: #4CAF50; /* Verde chiaro */\n}\n\n.job-code {\n  color: #FF9800; /* Arancione acceso */\n}\n\nion-accordion-group{\n  border-radius: 15px;\n  border-style: solid;\n  border-color: var(--ion-color-primary);\n  border-width: 2px\n}\n\nion-accordion{\n  border-radius: 15px;\n  margin: 0 auto;\n\n}\nion-accordion.accordion-expanding,\nion-accordion.accordion-expanded {\n  width: calc(100% - 32px);\n\n  margin: 20px auto;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
