import { Component, Input, ViewChild } from "@angular/core";
//import { ClrWizard, ClrWizardPage } from "@clr/angular";

@Component({
  selector: 'app-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss'],
})
export class WizardComponent {
  title
 // @ViewChild("wizard") wizard: ClrWizard;
  skipStepTwo: boolean = true;
  _open: boolean = false;

  toggleStepTwo() {
    this.skipStepTwo = !this.skipStepTwo;
  }

  open() {  
  console.log('open');

    this._open = !this.open;
  }
}
  