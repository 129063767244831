import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
if (environment.production) {
  enableProdMode();
  if (!environment.production) {
    window.console.log = () => { }
  }
}

console.log(`

╱╱╱╱╱╱╱╱╱╭╮╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╭╮
╱╱╱╱╱╱╱╱╱┃┃╱╱╱╱╱╱╱╱╭╮╱╱╱╱╭╯╰╮
╭━━┳━━┳━━┫┃╭━━┳━┳━━╋╋━━┳━┻╮╭╋━━╮
┃╭━┫╭╮┃╭╮┃┃┃╭╮┃╭┫╭╮┣┫┃━┫╭━┫┃┃━━┫
┃╰━┫╰╯┃╰╯┃╰┫╰╯┃┃┃╰╯┃┃┃━┫╰━┫╰╋━━┃
╰━━┻━━┻━━┻━┫╭━┻╯╰━━┫┣━━┻━━┻━┻━━╯
╱╱╱╱╱╱╱╱╱╱╱┃┃╱╱╱╱╱╭╯┃
╱╱╱╱╱╱╱╱╱╱╱╰╯╱╱╱╱╱╰━╯

vers.${environment.version}

This is a browser feature intended for developers. If someone told you to copy-paste something here to enable a CoolProjects feature or "hack" someone's account, it is a scam and will give them access to your CoolProjects account.
`)

if (!environment.log) {
  window.console.log = () => { }
}
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
