import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-custom-toast-modal',
  templateUrl: './custom-toast-modal.component.html',
  styleUrls: ['./custom-toast-modal.component.scss'],
})
export class CustomToastModalComponent implements AfterViewInit {

  @Input() data!: any;

  private animationItem!: AnimationItem;

  options!: AnimationOptions


  constructor(public modalCtrl: ModalController) {
  }

  ngAfterViewInit(): void {

    this.options = {
      name: 'test',
      path: '/assets/lottie/' + this.data.status + '-alert.json',
      autoplay: true,
      loop: false,
    };

    setTimeout(() => {
      this.modalCtrl.dismiss(undefined, undefined, 'ion-overlay-toast-modal')
    }, this.data.duration + 1000);

  }


  animationCreated(animationItem: AnimationItem): void {
    console.log('animationItem', animationItem);
    if (animationItem) {
      this.animationItem = animationItem;
      this.animationItem.stop()
      //this.animationItem.goToAndStop()
      ////this.animationItem.play()
      ////this.animationItem.setSpeed(2 )
    }
  }

  public dismiss() {
    this.modalCtrl.dismiss();
  }
}
