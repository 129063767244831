import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { IonNav, IonSearchbar } from '@ionic/angular';
// import { SwiperComponent } from 'swiper/angular';

@Component({
  selector: 'app-nav-sub-menu',
  templateUrl: './sub-menu.component.html',
  styleUrls: ['./sub-menu.component.scss'],
})

export class SubMenuComponent implements OnChanges {



  // config: SwiperOptions =
  //  {
    
  // };

  isOpen = false;
  selectedFilter = 'code'
  stringFilter

  // @ViewChild('swiper') swiperRef: SwiperComponent
  @ViewChild(IonSearchbar) myInput: IonSearchbar;
  @ViewChild('popover') popover;

  @Input() enableSearch = false;
  @Input() enableFIlter = false;
  @Input() isFocus = false;
  @Input() isFirstTopMenu = true;
  @Input() childs: any;

  @Output()
  callBack: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  filterTerm: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  typeFilterTerm: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  refresh: EventEmitter<string> = new EventEmitter<string>();

  inside = false;

  constructor() {
  }


  @HostListener("click")
  clicked() {
    this.inside = true;
  }

  @HostListener("document:click")
  clickedOut() {
    this.inside
      ? null
      : this.enableSearch = false;
    this.inside = false;
  }


  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);
    if (changes['isFocus'] && !changes['isFocus'].firstChange) {
      //this.imageRef.src = changes['url'].currentValue;
      this.isFocus = changes['isFocus'].currentValue;
      //if (this.isFocus)
      setTimeout(() => {
        this.myInput ? this.myInput.setFocus() : null
      }, 150);
    } else if (changes['childs'] && !changes['childs'].firstChange) {
    }

    // console.log(this.swiperRef);

  }

  methodAction(action, event?) {
    console.log(event);

    try {
      this[action](event)
    } catch {
      console.log('not call in component, run callback, sub menu', action);
      this.callBack.emit(action)
    }
  }

  presentPopover(e: Event) {
    this.popover.event = e;
    this.isOpen = true;
  }

  toggleSearch(ev?) {
    this.enableSearch = !this.enableSearch;
    if (this.enableSearch) {
      setTimeout(() => {
        this.myInput.setFocus()
      }, 150);
    }
  }

  //old
  onType(event) {
    console.log(event.detail.value);
    if (event.detail.value.length >= 3) {
      this.stringFilter = event.detail.value

      //let queryFilter = { search_by: 'search', search_data: event.detail.value }
      //localStorage.setItem('filter_search_projects', JSON.stringify(queryFilter))
      this.filterTerm.emit(JSON.stringify({ 'type': this.selectedFilter, 'data': event.detail.value }))

    } else if (event.detail.value == '') {
      this.stringFilter = ''

      this.filterTerm.emit(JSON.stringify({ 'type': this.selectedFilter, 'data': '' }))
    }
    this.refresh.emit('')


  }

  onType2(event) {
    // here comes the object as parameter
    console.log('dataChangedReaded', event);
    //this.activeFilterProject = true
    this.filterTerm.emit(JSON.stringify({ 'type': this.selectedFilter, 'data': event }))
    this.refresh.emit('')

  }

  changeFilter(next, prev) {
    this.selectedFilter = next
    //this.typeFilterTerm.emit(next)
    this.filterTerm.emit(JSON.stringify({ 'type': next, 'data': this.myInput.value }))
    this.filterTerm.emit(JSON.stringify({ 'type': prev, 'data': '' }))
    if (this.myInput.value != '') {
      this.myInput.value = ''
      this.refresh.emit('')

    }

  }

  onClear(event) {
    /*  setTimeout(() => {
       this.myInput.setFocus()
     }, 150); */
    this.enableSearch = false
    this.filterTerm.emit('')
  }



}
