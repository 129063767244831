import { StoresListPage } from './../stores/stores-list/stores-list.page';
import { animate, query,  stagger,  style, transition, trigger } from '@angular/animations';
import {  Component,  ViewChild, } from '@angular/core';
import { IonContent, ModalController, Platform } from '@ionic/angular';
import { ListFileComponent } from '../components/media/list-file/list-file.component';
import { RenderDocumentsPage } from '../documents/render-documents/render-documents.page';
import { OrderDetailsPage } from '../orders/order-details/order-details.page';
import { ApiService } from '../services/api/api.service';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { IonLoaderService } from '../services/ion-loader/ion-loader.service';
import { ProfileUserPage } from '../users/profile-user/profile-user.page';
import { DdtListPage } from '../trasport_documents/ddt-list/ddt-list.page';
import { ProductsListPage } from '../products/products-list/products-list.page';
import { VendorsListPage } from '../vendors/vendors-list/vendors-list.page';
import { OrdersListPage } from '../orders/orders-list/orders-list.page';

const images = [
  'https://media.giphy.com/media/JIX9t2j0ZTN9S/giphy.gif',
  'https://media.giphy.com/media/6C4y1oxC6182MsyjvK/giphy.gif',
  'https://media.giphy.com/media/Ov5NiLVXT8JEc/giphy.gif',
  'https://media.giphy.com/media/SRO0ZwmImic0/giphy.gif',
  'https://media.giphy.com/media/mlvseq9yvZhba/giphy.gif',
  'https://media.giphy.com/media/JIX9t2j0ZTN9S/giphy.gif',
  'https://media.giphy.com/media/6C4y1oxC6182MsyjvK/giphy.gif',
  'https://media.giphy.com/media/Ov5NiLVXT8JEc/giphy.gif',
  'https://media.giphy.com/media/SRO0ZwmImic0/giphy.gif',
  'https://media.giphy.com/media/mlvseq9yvZhba/giphy.gif',
  'https://media.giphy.com/media/JIX9t2j0ZTN9S/giphy.gif',
  'https://media.giphy.com/media/6C4y1oxC6182MsyjvK/giphy.gif',
  'https://media.giphy.com/media/Ov5NiLVXT8JEc/giphy.gif',
  'https://media.giphy.com/media/SRO0ZwmImic0/giphy.gif',
  'https://media.giphy.com/media/mlvseq9yvZhba/giphy.gif',
  'https://media.giphy.com/media/JIX9t2j0ZTN9S/giphy.gif'
];
export const fadeAnimation = trigger('fadeAnimation', [
  transition(':enter', [
    style({ opacity: 0 }), animate('300ms', style({ opacity: 1 }))]
  ),
  transition(':leave',
    [style({ opacity: 1 }), animate('300ms', style({ opacity: 0 }))]
  )
]);


const listAnimation = trigger('listAnimation', [
  transition('* <=> *', [
    query(':enter',
      [style({ opacity: 0 }), stagger('180ms', animate('300ms ease-out', style({ opacity: 1 })))],
      { optional: true }
    ),
    query(':leave',
      animate('200ms', style({ opacity: 0 })),
      { optional: true }
    )
  ])
]);
@Component({
  selector: 'app-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss'],
  // ✨ New! Defining the animation
  animations: [fadeAnimation, listAnimation]
})
export class HomePage {
openTab2(arg0: any) {
throw new Error('Method not implemented.');
}

  profile = ProfileUserPage;
  documan = RenderDocumentsPage;
  uploadFile = ListFileComponent;
  vendor = VendorsListPage;
  mahazin = ProductsListPage;
  order = OrdersListPage;



  items = [];
  images = [];
  show = true;
  @ViewChild(IonContent) content: IonContent;
  //@ViewChild(HasPermsDirective) perms: HasPermsDirective;
  articles: any[];
  fab: boolean = false;


  constructor(
    private platform: Platform,
    private loader: IonLoaderService,
    private api: ApiService,
    private auth: AuthenticationService,
    private modalController: ModalController,
  ) {
    this.articles = [
      //{ id: 1, title: 'Home', code: 'home', image: 'https://img.freepik.com/free-vector/podium-wallpaper-with-geometrical-3d-shapes_52683-61183.jpg?t=st=1645923561~exp=1645924161~hmac=076d1e67c913626a112c7b88e2c25b3cff5b9c09ce6cf2e5f760cae69a6def97&w=1380' },
      { id: 2, name: 'Progetti', code: 'project', image: 'https://img.freepik.com/free-vector/podium-wallpaper-with-geometrical-3d-shapes_52683-61182.jpg?t=st=1645923397~exp=1645923997~hmac=193e027e0b73108ebaf73fda5bf1ce3a56414ea37edb4bef7695c622ede3666b&w=1380' },


      { id: 2, name: 'Profilo', code: 'profile', target: this.profile, image: 'https://img.freepik.com/free-vector/podium-wallpaper-with-geometrical-3d-shapes_52683-61183.jpg?t=st=1645923561~exp=1645924161~hmac=076d1e67c913626a112c7b88e2c25b3cff5b9c09ce6cf2e5f760cae69a6def97&w=1380' },
      { id: 3, name: 'Documenti', code: 'documan', target: this.documan, image: 'https://img.freepik.com/free-vector/podium-wallpaper-with-geometrical-3d-shapes_52683-61182.jpg?t=st=1645923397~exp=1645923997~hmac=193e027e0b73108ebaf73fda5bf1ce3a56414ea37edb4bef7695c622ede3666b&w=1380' },
      { id: 4, name: 'Vendor', code: 'vendor', target: this.vendor, image: 'https://img.freepik.com/free-vector/podium-wallpaper-with-geometrical-3d-shapes_52683-61180.jpg?t=st=1645923405~exp=1645924005~hmac=af931250b39cd02f20e6e87d0eed01ecb73bc82a752fb3e4bd314a7fe842b812&w=1380' },
      { id: 5, name: 'Product', code: 'mahazin', target: this.mahazin, image: 'https://img.freepik.com/free-vector/podium-wallpaper-with-geometrical-3d-shapes_52683-61177.jpg?w=1060' },
      { id: 6, name: 'Order', code: 'order', target: this.order, image: 'https://img.freepik.com/free-vector/podium-wallpaper-with-geometrical-3d-shapes_52683-61178.jpg?t=st=1646955179~exp=1646955779~hmac=e934a45e1263bb9d6a172b0a41c753f6f602901ffa3b9398735ab3632c7edeb6&w=1380' }

    ];

    this.auth.getLocalData('auth-profile').then(e => {

      this.articles = e.applications;
      this.articles.map(e=>{
        e['target'] = this[e.code]
      })

      let items = this.articles.filter(o1 => e.applications.some(o2 => o1.code !== 'home'));

      this.articles = items.sort(function (a, b) {
        var x = a['id']; var y = b['id'];
        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
      });
      /// this.articles2 = this.articles
    });

    setTimeout(() => {
      this.loader.dismissLoader();
    }, 200);


    this.platform.ready().then(() => {

      if (!this.platform.is("desktop")) {


      }
    })
  }



  // goBack() {
  //   //this.navCtrl.back({ animated: false });
  //   this.nav.pop();
  // }

  /*   openDetails(ev?) {
      //nav: this.nav(this.uploadFile);
      //this.api.getFiles().subscribe()
    } */

  openAboutTab() {
  }

  openTab(n) {
    console.log(n);

  }

  // openTab2(n) {
  //   console.log(n);
  //   //nav: this.nav(n)
  // }

  methodAction(action) {
    if (action) {
      this[action]()
    } else {
      console.log('not call in parent component');
    }
    //console.log(action)
  }

  onScroll(event) {

    if (event.detail.currentY >= 100) {
      //console.log(event);
      //console.log(this.fab);
      this.fab = true;
    } else if (event.detail.currentY <= 99) {
      //console.log(event);
      //console.log(this.fab);
      this.fab = false;
    }
  }
  toggleImages() {
    this.images = this.images.length ? [] : images;
    this.articles = this.articles.length ? [] : this.articles
  }


  async openNewProjectModal() {
    const modal = await this.modalController.create({
      component: OrderDetailsPage,
      canDismiss: true,
      keyboardClose: false, // da vedere se conviene metterlo ovunque sui modali altrimenti con "ESC" chiude il modal
      cssClass: 'fullscreen',
      componentProps: {
        id: 9
      }
    });

    modal.onWillDismiss().then((e) => {

      if (e.data && e.data.message != 'OK') {
        console.log('no modify');
        return
      } else {


      }
    });

    return await modal.present();
  }



  goTo(ev?) {
    if (ev == 'order_9') {/* 
      //nav: this.nav(OrderDetailsPage,{id: 12}) */
      this.openNewProjectModal()
    }

    // if (ev == 'ddt') {
    //   //nav: this.nav(DdtListPage, { type: 'IN' })
    // }
    // if (ev == 'stores') {
    //   //nav: this.nav(StoresListPage, { type: 'IN' })
    // }

  }

}
