import { Component, Input, OnInit } from '@angular/core';
import { ThemeService } from 'src/app/services/theme/theme.service';

import { ECharts, EChartsOption, format } from 'echarts';
import '../../../../assets/charts_themes/cpj_charts';
import '../../../../assets/charts_themes/fgb_charts';
import * as echarts from 'echarts';

@Component({
  selector: 'app-dataset-link',
  templateUrl: './dataset-link.component.html',
  styleUrls: ['./dataset-link.component.scss'],
})
export class DatasetLinkComponent implements OnInit {

  @Input() pie
  @Input() listItems
  options: EChartsOption;
  echartsInstance: ECharts;
  loadingGraph = true;
  theme: string = 'cpj'
  loadingOpts
  dark: any = true

  constructor(
    private themeDetection: ThemeService
  ) {
    this.loadingOpts = {
      text: 'loading',
      color: 'rgb(0, 145, 147)',
      textColor: !this.dark ? 'rgba(18,18,18,0.9)' : 'rgba(255,255,255,0.9)',
      maskColor: this.dark ? 'rgba(18,18,18,0.9)' : 'rgba(255,255,255,0.9)',
      zlevel: 0
    }

    this.dark = this.themeDetection.observStatus()


    this.themeDetection.dark.subscribe(e => {
      console.log(e);

      if (!e)
        return

      this.dark = e
      this.loadingOpts = {
        text: 'loading',
        color: 'rgb(0, 145, 147)',
        textColor: !this.dark ? 'rgba(18,18,18,0.9)' : 'rgba(255,255,255,0.9)',
        maskColor: this.dark ? 'rgba(18,18,18,0.9)' : 'rgba(255,255,255,0.9)',
        zlevel: 0
      }
    })
    this.loadingGraph = true;

  }

  ngOnInit() {
    console.log('start graph');

    setTimeout(() => {
      this.theme = 'cpj'

      if (this.listItems) {

        this.options = {
          legend: {},
          tooltip: {
            trigger: 'axis',
            showContent: true
          },
          dataZoom: [
            {
              type: 'slider',
              xAxisIndex: [0, 1],
              filterMode: 'empty'
            },
            {
              type: 'slider',
              xAxisIndex: [0, 1],
              filterMode: 'filter', // Set as 'filter' so that the modification
              // of window of xAxis willl effect the
              // window of yAxis.
              show: false,
              start: 0,
              end: 100
            }
          ],
          xAxis: this.listItems.xAxis,
          yAxis: this.listItems.yAxis,
          series: this.listItems.series
        };
      } else {
        this.options = {
          legend: {},
          tooltip: {
            trigger: 'axis',
            showContent: true
          },
          dataZoom: [
            {
              type: 'slider',
              xAxisIndex: [0, 1],
              filterMode: 'empty'
            },
            {
              type: 'slider',
              xAxisIndex: [0, 1],
              filterMode: 'filter', // Set as 'filter' so that the modification
              // of window of xAxis willl effect the
              // window of yAxis.
              show: false,
              start: 0,
              end: 100
            }
          ],
          xAxis: [
            {
              type: 'category',
              boundaryGap: true,
              data: ['24/05/2022', '25/05/2022', '26/05/2022']
            },
            {
              type: 'category',
              boundaryGap: true,
              data: ['24/05/2022', '25/05/2022', '26/05/2022']
            }
          ],
          yAxis: [
            {
              type: 'value',
              scale: true,
              name: 'Price',
              //max: 150,
              min: 0,
              boundaryGap: [0.1, 0.1]
            },
            {
              type: 'value',
              scale: true,
              name: 'Order',
              //max: 1200,
              min: 0,
              boundaryGap: [0.1, 0.1]
            }
          ],
          series: [
            {
              name: 'Edile',
              type: 'bar',
              xAxisIndex: 1,
              yAxisIndex: 1,
              data: [100, 50, 100]
            },
            {
              name: 'Architettonico',
              type: 'bar',
              xAxisIndex: 1,
              yAxisIndex: 1,
              data: [100, 50, 50]
            },
            {
              name: 'Ricerca e Sviluppo',
              type: 'bar',
              xAxisIndex: 1,
              yAxisIndex: 1,
              data: [100, 50, 150]
            },
            {
              name: 'Totale',
              type: 'line',
              data: [300, 150, 300],
              universalTransition: true,
              animationDurationUpdate: 1000,
              emphasis: {
                lineStyle: {
                  width: 2
                }
              },
              blur: {
                lineStyle: {
                  width: 2
                }
              },
              smooth: false,
            }
          ]
        };
      }

      this.loadingGraph = false;

    }, 650)
  }


  onChartInit(ec) {
    this.echartsInstance = ec;
    console.log(ec);

  }

  onChartEvent(e, ele) {
    console.log(e, ele);
  }

  onChartLegend(e, ele) {
    console.log(e, ele);
  }
  onChartScroll(e, ele) {
    console.log(e, ele);
  }
}
