import { StepModel } from './../../orders/models/step.models';
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable object-shorthand */
/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/semi */
/* eslint-disable @typescript-eslint/naming-convention */


import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Platform, ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { catchError, delay, distinctUntilChanged, map, retryWhen, scan, tap, timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../authentication/authentication.service';
import { NetworkService, ConnectionStatus } from '../network/network.service';
//import { Storage } from '@ionic/storage';
import { OfflineManagerService } from '../network/offline-manager.service';

//import environment from '../../../config.json';
import { Project } from 'src/app/projects/models/projects';
import { Docs } from 'src/app/documents/models/docs';
import { Order } from 'src/app/orders/models/order.model';


const API_STORAGE_KEY = 'ordersService';
const TOKEN_KEY = 'access-token';

const STEPS = [
    { stepIndex: 1, isComplete: false },
    { stepIndex: 2, isComplete: false },
    { stepIndex: 3, isComplete: false }
  ];

@Injectable({
  providedIn: 'root'
})
export class StepsOrderService {

  private url: string = 'http';
  private token: any;    
  steps$: BehaviorSubject<StepModel[]> = new BehaviorSubject<StepModel[]>(STEPS);
  currentStep$: BehaviorSubject<StepModel> = new BehaviorSubject<StepModel>(null);

  constructor(
    private http: HttpClient,
    public toastController: ToastController,
    private authService: AuthenticationService,
    private networkService: NetworkService,
    
  ) {

    this.currentStep$.next(this.steps$.value[0]);



    /**
     * Map url API
     */
    this.url = environment.SSL.active ? this.url + 's' : this.url
    this.url += '://' + environment.API_URI.url + ':' + environment.API_URI.port + environment.API_URI.path

    this.authService.currentToken.subscribe(x => {
      //console.log(x);
      this.token = x //= JSON.parse(localStorage.getItem(TOKEN_KEY))

    })

  }


  setCurrentStep(step: StepModel): void {
    this.currentStep$.next(step);
  }


  setResetStep(step: StepModel): void {
    const index = 0;        
  }

  getCurrentStep(): Observable<StepModel> {
    return this.currentStep$.asObservable();
  }

  getSteps(): Observable<StepModel[]> {
    return this.steps$.asObservable();
  }

  moveToNextStep(): void {
    const index = this.currentStep$.value.stepIndex;
    console.log('index current next : ', index);
    

    if (index < this.steps$.value.length) {
      this.currentStep$.next(this.steps$.value[index]);
    }
  }

 moveToPrewStep():void{

    const index = this.currentStep$.value.stepIndex - 1
    console.log('index current prev : ', index);        
    if (index > 1) {
      this.currentStep$.next(this.steps$.value[index]);
      console.log('current' , this.steps$.value);
      
    }
  }

  isLastStep(): boolean {
    return this.currentStep$.value.stepIndex === this.steps$.value.length;
  }

  isFirstStep(): boolean {
    console.log(this.steps$);
    const index = this.currentStep$.value.stepIndex
    console.log(index);
    
    return this.currentStep$.value.stepIndex === 1;
  }



  




}
