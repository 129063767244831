import { OrdersService } from 'src/app/services/orders/orders.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { Component, Input, OnInit, Output, Renderer2, ViewChild, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { ModalController, PopoverController, ToastController } from '@ionic/angular';
import { ModalOrderDiscountLineComponent } from './modal/modal.component';

@Component({
  selector: 'app-discount-line-order',
  templateUrl: './discount-line.component.html',
  styleUrls: ['./discount-line.component.scss'],
})
export class DiscountLineComponent {

  @ViewChild('createForm') createForm: FormGroupDirective;
  rows = [];
  selected;

  createDiscountForm: FormGroup;


  columns = [
    { prop: 'name' },
    { name: 'Gender' },
    { name: 'Company' }
  ];

  @ViewChild('table') table: DatatableComponent;
  site;
  response;
  @Input() order;
  @Input() line;
  @Input() type;
  @Input() lines_discount;




  items: any = [];


  approved: any = {};
  max: any = {};
  globals = [];
  discounts: any;
  modal: HTMLIonModalElement;


  item_columns = [
    //{ prop: 'id', name: 'ID', frozenLeft: true, minWidth: 65, maxWidth: 65, width: 65, cellClass: 'td-id', headerClass: 'th-id' },
    { prop: 'vendor.name', name: 'Vendor', minWidth: 230, maxWidth: 230, width: 230 }

  ]

  numPages

  constructor(
    private popoverController: PopoverController,
    private modalController: ModalController,
    private render: Renderer2,
    private fb: FormBuilder,
    private api: OrdersService,
    private toastController: ToastController,
    private orderApi: OrdersService
  ) {

        
    this.orderApi.newDiscountOrder.subscribe(e => {
      console.log(e, e['orderline'], e['order']);
      let keys = Object.keys(e)
      let keyOrderDiscountLine = keys.indexOf('orderline')
      let keyOrderDiscountGlobal = keys.indexOf('order')

      console.log(keyOrderDiscountLine);
      console.log(keyOrderDiscountGlobal);


      if (keyOrderDiscountLine != -1) {
        this.order.order_lines.map(e => {
          e.id == e.orderline ? e.discounts.push(e) : null
        })
        //this.order.order_lines.push(e)

      } else if (keyOrderDiscountGlobal != -1) {
        this.order.discounts.push(e)
      }


      /* 


      
          this.checkIsLineDiscountsApproved()
          this.checkIsGlobalDiscountsApproved()
          
            // Get invoices
            this.getInvoices(this.order.id)
            // Get invoices
            this.getPayments(this.order.id)
            // Get files
            this.getListFiles() */



    })

  }



  groupArrayOfObjects(list, key) {
    console.log(list);

    if (list && list.length == 0)
      return ''

    let obj = [];
    let vendorsGroup = list.reduce(function (r, a, i) {
      r[a.vendor] = r[a.vendor] || [];
      r[a.vendor].push(a);
      return r;
    }, Object.create(null));

    console.log(vendorsGroup);


    Object.keys(vendorsGroup).map((e, i, a) => {
      obj[e] = obj[e] || [];
      obj[e].push(vendorsGroup[e])
    })

    return obj

  };



  groupByVendor(discounts) {
    console.log(discounts);
    this.discounts.map(e => {
      console.log('groupByVendor', e);


    })

  }

  groupByApprove(list) {

    console.log('groupByApprove', list);
    if (list && list.length == 0)
      return

    const approved = list.filter((element) => element.approved)

    return approved

  }


  ionViewWillEnter(response) {
    console.log('app-discount-line', 'ionViewWillEnter');


    this.createDiscountForm = this.fb.group({
      id: this.fb.control('', Validators.required)
    });

    this.response = '';
    this.approved = [];
    this.discounts = [];

   /*  if (response) {
      this.discounts.push(response);
    } */

    console.log('input discount', this.lines_discount);
    console.log('input type', this.type);
    console.log('input line', this.line);
    console.log('input order', this.order);




    //console.log(this.order);




    this.order.discounts.map(e => {
      e.global = true
    })

    if (this.type == 'line') {

      this.items = this.line.discounts
      console.log('-----------------line-------------------');

      this.discounts.push(...this.lines_discount)
      console.log(this.discounts);

      let approveds = this.groupByApprove(this.discounts)
      let filter;
      console.log(approveds);
      if (!approveds)
        return

      console.log(approveds[0]);
      if (approveds && approveds.length > 0 && approveds[0].id) {
        this.approved = approveds[0];
        filter = this.discounts.filter(e => {
          return e.id !== this.approved.id
        })
      } else {

        filter = this.discounts.filter(e => {
          return e.id !== this.max.id
        })
      }


      let vendorsGroup = this.groupArrayOfObjects(filter, 'vendor')

      this.discounts = vendorsGroup;

      console.log(this.approved, this.max, filter, this.discounts);


      //let filter = max.filter(e => e.approved)

      //this.discounts.push(...this.order.discounts)

      //this.approved = filter;

      //let a = this.groupArrayOfObjects(this.discounts, 'vendor')
      //let b = this.groupArrayOfObjects(this.discounts, 'version')

      //console.log(a);
      //console.log(b);



    } else if (this.type == 'global') {

      console.log('-----------------global-------------------');
      //this.discounts.push(...this.discount)
      this.discounts.push(...this.order.discounts)

      this.items = this.discounts
      let filter = this.discounts.filter(e => e.approved == true)
      this.approved = filter[0];
      //this.approved = this.discounts

      let a = this.groupArrayOfObjects(this.discounts, 'vendor')
      let b = this.groupArrayOfObjects(this.discounts, 'version')

      console.log(a, b);


    }



  }

  dismissModal() {
    //this.popoverController.dismissModal(this.response);
    this.modalController.getTop().then(e => {
      console.log('this.modalController.getTop()', e);

    })
    this.modalController.dismiss(this.response);
  }

  clickLine(d) {
    //this.response = { res: 'OK', data: d }
  }


  /**
   * @description Create new order, open modal
   * @returns 
   */
  async opneNewDiscountModal(type, event, row) {
    console.log(row, type, this.order);

    const modal = await this.modalController.create({
      component: ModalOrderDiscountLineComponent,
      backdropDismiss: true,
      mode: 'md',
      componentProps: {
        title: (type != 'line') ? 'Global' : 'on line' + ' ' + row?.id,
        line: row,
        type: type,
        order_: this.order
      }
      //presentingElement: this.routerOutlet.nativeEl,
    })


    modal.onWillDismiss().then((e) => {
      if (!e.data) {
        console.log('no modify');
        return
      } else {
        // Se Ok passo la risposta di conferma dell'api per risparmiare sui servizi http
        console.log(e);
        console.log(e.data, this.type);


        console.log('ON Dissmiss app-modal from app-discount-line:');

        this.items.push(e.data)
        console.log(this.items);
        this.items = [...this.items]
        // brutale vai all'ultima pagina ed ordina x id
        this.table.offset = 900;
        this.table.sorts = ['id']

        this.response = e.data
        console.log('DismissModal app-discount');
        this.dismissModal()


      }
    });

    return await modal.present();
  }


  /**
   * @description Create new order, open modal
   * @returns 
   */
  async opneNewDiscountModal2(type, event) {
    const modal = await this.modalController.create({
      component: ModalOrderDiscountLineComponent,
      backdropDismiss: true,
      mode: 'md',
      componentProps: {
        title: 'global',
        type: type
      }
      //presentingElement: this.routerOutlet.nativeEl,
    })


    modal.onWillDismiss().then((e) => {
      // Se Ok passo la risposta di conferma dell'api per risparmiare sui servizi http
      console.log(e);
      if (e.data == '' && e.data.res != 'OK' || e.role == 'backdrop') {
        console.log('no modify');
        return
      } else {

        this.lines_discount.push(e.data.data)

      }
    });
    return await modal.present();
  }

  click(e) {
    console.log(e['el'], e);

    this.popoverController.getTop().then(e => {
      console.log(e);
      //this.popoverController.dismissModal()

    })


  }


  async setVendorDiscount(values: any) {
    // copy all the form values into the new vendor
    const value = { ...values }
    const form = {} //{ ...id };        

    form['discount_category'] = this.type
    form['approved'] = true

    //newOrder.created = moment(newOrder.created).format('YYYY-MM-DD')


    console.log(form)
    console.log(this.order);
    console.log(value.id);



    this.api.chooseDiscountVendor(form, this.order.id, value.id).subscribe(async e => {
      console.log(e);
      /* {
        "id": 15,
        "orderline": 5,
        "vendor": {
          "id": 4,
          "type": {
            "id": 4,
            "name": "Società a Responsabilità Limitata",
            "code": "SRL",
            "created": "2022-05-30T13:15:33+02:00"
          },
          "name": "Cool Tech",
          "code": "CT",
          "code_vat": "15931681009",
          "code_fiscal": "15931681009",
          "code_unique": "SUBM70N",
          "code_iban": "IT",
          "address": "[{'name': 'legal', 'position': {'street': 'via Pio Emanuelli', 'number': '1', 'city': 'Roma', 'cap': '00143', 'region': 'Lazio', 'province': 'Roma', 'nation': 'Italia'}}]",
          "contact": "[{'name': 'phone', 'data': '0690282661'}, {'name': 'email', 'data': 'info@cool-tech.it'}]",
          "is_customer": true,
          "is_vendor": true
        },
        "discount_type": "P",
        "value": 3,
        "approved": true,
        "version": 1,
        "deleted": false,
        "created": "2022-06-23T09:28:23.361326+02:00"
      }   */
      this.approved = e.data
      this.approved = this.approved

      if (e.message && e.message.includes('OK')) {
        this.presentToast(e.message, 'success')
        this.response = e.data
        console.log(this.response);




      } else {
        console.log('no modify');

      }
      this.dismissModal();
    })
  }

  // Call submitForm with click Save
  submitForm() {
    console.log('onSubmit');
    this.createForm.onSubmit(undefined);
  }

  setSelected(id) {
    this.selected = id
  }

  async presentToast(text, color) {
    const toast = await this.toastController.create({
      message: text,
      position: 'bottom',
      duration: 3000,
      cssClass: 'toast',
      color: color
    });
    return await toast.present();
  }


}
