/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable object-shorthand */
/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/semi */
/* eslint-disable @typescript-eslint/naming-convention */


import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Platform, ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { catchError, delay, distinctUntilChanged, map, retryWhen, scan, tap, timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../authentication/authentication.service';
import { NetworkService, ConnectionStatus } from '../network/network.service';
//import { Storage } from '@ionic/storage';
import { OfflineManagerService } from '../network/offline-manager.service';

//import environment from '../../../config.json';
import { Project } from 'src/app/projects/models/projects';
import { Docs } from 'src/app/documents/models/docs';
import { Product } from 'src/app/products/models/product.model';


const API_STORAGE_KEY = 'productsService';
const TOKEN_KEY = 'access-token';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  private url: string = 'http';
  private token: any;
  private projects: Project[];
  private documents: Docs[];
  private document: Docs;
  private project_types: any[];
  //public docList = new BehaviorSubject(null);

  constructor(
    private storage: Storage,
    private plt: Platform,
    private http: HttpClient,
    public toastController: ToastController,
    private authService: AuthenticationService,
    private networkService: NetworkService,
    private offlineManager: OfflineManagerService
  ) {

    /**
     * Map url API
     */
    this.url = environment.SSL.active ? this.url + 's' : this.url
    this.url += '://' + environment.API_URI.url + ':' + environment.API_URI.port + environment.API_URI.path

    this.authService.currentToken.subscribe(x => {
      //console.log(x);
      this.token = x //= JSON.parse(localStorage.getItem(TOKEN_KEY))

    })

  }

  /**
   * 
   * PRODUCTS
  */


  /**
  * 
  *
  * @returns list products
  * @params ?mode=[query] of list - (empty/'') or ('simple')
  */
  getProductsList(params, query?): Observable<any> {

    query = query && query != '' ? '?mode=' + query + '&' : '';

    console.log(params);

    return this.http.get<any>(`${this.url}product/products/${query}${params}`)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          this.setLocalData('list', data);
          return data
        }),
        catchError(this.handleError<any[]>('Get products list', []))
      );

  }

  getServicesList(params, query?): Observable<any> {

    query = query && query != '' ? '?mode=' + query + '&' : '';

    console.log(params);

    return this.http.get<any>(`${this.url}service/services/${query}${params}`)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          this.setLocalData('list', data);
          return data
        }),
        catchError(this.handleError<any[]>('Get Services list', []))
      );

  }

    /**
     *
     *
     * @returns list products
     * @params ?mode=[query] of list - (empty/'') or ('simple')
     */
    shared_units_list(params, query?): Observable<any> {

        return this.http.get<any>(`${this.url}shared/units/`)
            .pipe(
                timeout(16000),
                tap(data => {
                    console.log(data);
                    this.setLocalData('list', data);
                    return data
                }),
                catchError(this.handleError<any[]>('Get unit list', []))
            );

    }

  /**
  * 
  *
  * @returns Type Product Types
  */
  /* getProductTypes(params): Observable<any> {
    return this.http.get<any>(`${this.url}mahazin/types/${params}`)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          this.setLocalData('types', data);
          return data
        }),
        catchError(this.handleError<any[]>('Get product types', []))
      ); */  /* #Ric Product type list non pervenuta */
 /*  } */

  /**
  * 
  *
  * @returns Type Product Category
  */
  product_categories_list(params): Observable<any> {
    return this.http.get<any>(`${this.url}product/categories/${params}`)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          this.setLocalData('categories', data);
          return data
        }),
        catchError(this.handleError<any[]>('Get product categories', []))
      );
  }
  /**
   * 
   * @description 
   * @params 
  */

  /**
  * 
  *
  * @returns Type Product Models List
  */
  product_models_list(params): Observable<any> {
    return this.http.get<any>(`${this.url}product/models/${params}`)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          this.setLocalData('categories', data);
          return data
        }),
        catchError(this.handleError<any[]>('Get product categories', []))
      );
  }

  /**
  * 
  *
  * @returns Type Product Models List
  */
  product_features_list(params): Observable<any> {
    return this.http.get<any>(`${this.url}product/features/${params}`)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          this.setLocalData('categories', data);
          return data
        }),
        catchError(this.handleError<any[]>('Get product categories', []))
      );
  }
  

    /**
  * 
  *
  * @returns Type Product Category
  */
    product_models_create(form): Observable<any> {
      return this.http.post<any>(`${this.url}product/models/`, form)
        .pipe(
          timeout(16000),
          tap(data => {
            console.log(data);
  
            return data
          }),
          catchError(this.handleError<any[]>('Post product model', []))
        );
    }


    
    /**
  * 
  *
  * @returns Type Product Category
  */
    product_create_from_orders(form): Observable<any> {
      return this.http.post<any>(`${this.url}product/product_model/`, form)
        .pipe(
          timeout(16000),
          tap(data => {
            console.log(data);
  
            return data
          }),
          catchError(this.handleError<any[]>('Post product from order', []))
        );
    }


    service_create_from_orders(form): Observable<any> {
      return this.http.post<any>(`${this.url}service/services/`, form)
        .pipe(
          timeout(16000),
          tap(data => {
            console.log(data);
  
            return data
          }),
          catchError(this.handleError<any[]>('Post service from order', []))
        );
    }
  
  /**
  * 
  *
  * @returns Type Product Category
  */
  product_categories_create(form): Observable<any> {
    return this.http.post<any>(`${this.url}product/categories/`, form)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);

          return data
        }),
        catchError(this.handleError<any[]>('Post product categories', []))
      );
  }

  /**
  * 
  *
  * @returns Type Product Feature
  */
  product_featurevalues_create(feature): Observable<any> {
    return this.http.post<any>(`${this.url}product/featurevalues/`, feature)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);

          return data
        }),
        catchError(this.handleError<any[]>('Post product feature', []))
      );
  }


  /**
  * 
  *
  * @returns Type Product Category
  */
  getProductBrands(params): Observable<any> {
    return this.http.get<any>(`${this.url}mahazin/brands/${params}`)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          this.setLocalData('brands', data);
          return data
        }),
        catchError(this.handleError<any[]>('Get product Brands', []))
      );
  }




  /**
 * 
 * @param id id of product
 * @returns product
 */
  product_product_read(id): Observable<any> {

    return this.http.get<any>(`${this.url}product/product/${id}/`)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Get product by id', []))
      );

  }



  /**
   * 
   * @param product 
   * @returns 
   */

  product_products_create(product: Product) {
    console.log(this.url)
    return this.http.post<any>(`${this.url}product/products/`, product)
      .pipe(
        tap(data => {
          console.log(data);
        }),
        catchError(this.handleError<any[]>('Create product', []))
      );
  }


  updateProduct(product: Product, id) {
    return this.http.put<any>(`${this.url}mahazin/product/${id}/`, product)
      .pipe(
        tap(data => {
          console.log(data);
        }),
        catchError(this.handleError<any[]>('Update product', []))
      );
  }



  private handleError<T>(operation = 'operation', result?: T) {

    return (error: any): Observable<T> => {

      //console.error(error);
      console.log(result, error);

      console.log(`${operation} failed`, error);
      const status = error == 'Forbidden' ? 403 : 500
      this.presentToast(status, error == 'undefined' ? 'Internal Server Error' : error, operation)
      return of(result as T);
    };
  }


  async presentToast(status, statusText, message) {
    const toast = await this.toastController.create({
      message: status + ' ' + statusText + ': "' + message + '"',
      duration: 2000,
      mode: 'ios',
      cssClass: 'toast',
      color: status != '200' ? 'danger' : 'primary'
    });
    toast.present();
  }

  logSys(src, status, opt?) {
    const debugStyle1 = 'background: linear-gradient(135deg,#471ee9,#3a49b7); border: 1px solid #9a9a9a; color: #ffffff; border-bottom-left-radius: 2px; border-top-left-radius: 2px; padding: 2px 0 2px 4px;';
    const debugStyle2 = 'background: #252b3e; border: 1px solid #9a9a9a; border-top-right-radius: 2px; border-bottom-right-radius: 2px; margin-left: -2px; padding: 2px 4px; color: white;';

    //console.log('ForceRefresh API ', status, this.networkService.getCurrentNetworkStatus(), this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline);
    console.log(`%cApiService %c%s`, debugStyle1, debugStyle2, ' ' + src, status);
  }




  // Save result of API requests
  private setLocalData(key, data) {
    this.storage.set(`${API_STORAGE_KEY}-${key}`, data);
  }

  // Get cached API result
  private getLocalData(key) {
    return this.storage.get(`${API_STORAGE_KEY}-${key}`);
  }


  // Get Product Async




}
