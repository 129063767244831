import { Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoadingController, ModalController } from '@ionic/angular';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { forkJoin, map } from 'rxjs';
import { ReportsService } from 'src/app/services/reports/reports.service';
import { TasksService } from 'src/app/services/tasks/tasks.service';

@Component({
  selector: 'app-timesheet-charts',
  templateUrl: './timesheet-charts.page.html',
  styleUrls: ['./timesheet-charts.page.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class TimesheetChartsPage implements OnInit {
  constructor(
    private modalCtrl: ModalController,
    private taskService: TasksService,
    private loadingController: LoadingController,
    private apiReport: ReportsService,
    private formBuilder: FormBuilder,


  ) {}
  @ViewChild('myTable') table: DatatableComponent;
  @ViewChild('descriptionTemplate', { static: true }) descriptionTemplate: TemplateRef<any>;
  @ViewChild('jobTemplate', { static: true }) jobTemplate: TemplateRef<any>;
  @ViewChild('projectNameTemplate', { static: true }) projectNameTemplate: TemplateRef<any>;
  @ViewChild('statusTemplate', { static: true }) statusTemplate: TemplateRef<any>;

  loading:any
  view: any = [700, 400];
  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  showXAxisLabel = true;
  xAxisLabel = 'Ore';
  showYAxisLabel = true;
  yAxisLabel = 'Giorni';
  deps:any = []
  colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA'],
  };

  chartData: any[] = [];
  allChartData: any[] = [];
  pieChartData: any[] = [];
  allPieChartData: any[] = [];
  selectedMonth: string = '';
  rows: any;
  columns:any
  user: any;
  dataDeps:any = []
  months = [
    { name: 'Gennaio', value: '01' },
    { name: 'Febbraio', value: '02' },
    { name: 'Marzo', value: '03' },
    { name: 'Aprile', value: '04' },
    { name: 'Maggio', value: '05' },
    { name: 'Giugno', value: '06' },
    { name: 'Luglio', value: '07' },
    { name: 'Agosto', value: '08' },
    { name: 'Settembre', value: '09' },
    { name: 'Ottobre', value: '10' },
    { name: 'Novembre', value: '11' },
    { name: 'Dicembre', value: '12' },
  ];
  report: string = '';
  showDetailsButton: boolean = false;
  showDetails: boolean = false;
  details: any[] = [];
  isTaskDetailsOpen = false;
  allApproved: any;
  userTask: any;
  addTaskForm: any = FormGroup;
  userSelected = false
  dataUserSelected:any 
  isResp:boolean 
  ngOnInit() {
    console.log(this.rows);
    console.log(this.deps);
    console.log(this.user);
    
    
    this.setupColumns()
    this.prepareChartData(this.rows);
    this.preparePieChartData(this.rows);
    this.allChartData = [...this.chartData];
    this.allPieChartData = [...this.pieChartData]; 
    console.log(this.allChartData);
    console.log(this.allPieChartData);
  }
  setupColumns() {
    this.columns = [
      { prop: 'status', name: 'Stato', flexGrow: 1, sortable: true, draggable: false, resizeable: true, maxWidth: 100, minWidth:100, cellTemplate: this.statusTemplate },
      { prop: 'date', name: 'Data', flexGrow: 1, sortable: true, draggable: false, resizeable: true, maxWidth: 100, minWidth:100 },
      { prop: 'hours', name: 'Ore', flexGrow: 1, sortable: true, draggable: false, resizeable: true, maxWidth: 100, minWidth:100 },
      { prop: 'description', name: 'Description', flexGrow: 5, sortable: true, draggable: false, resizeable: true, maxWidth: 700, minWidth:100, cellTemplate: this.descriptionTemplate },
      { prop: 'name', name: 'Job Code', flexGrow: 2, sortable: true, draggable: false, resizeable: true, maxWidth: 300, minWidth:100, cellTemplate: this.jobTemplate },
      { prop: 'projectName', name: 'Project Name', flexGrow: 2, sortable: true, draggable: false, resizeable: true, maxWidth: 200, minWidth:100, cellTemplate: this.projectNameTemplate },
    ];
  }

  prepareChartData(rows: any) {
    let dataMap = new Map<string, any[]>();
    console.log(rows);
    
    rows.forEach((row) => {
      let timesheet = row.timesheet;
      let projectCode = row.project_code;
      let projectName = row.project_name;

      if (!projectCode) {
        return;
      }
      if (Array.isArray(timesheet)) {
        timesheet.forEach((entry) => {
          const date = entry.workhistory_date
            ? new Date(entry.workhistory_date).toLocaleDateString()
            : 'Unknown';
          const taskData = {
            name: projectCode ? (projectName + ' ' + projectCode  ) : 'Unknown project_code',
            value: entry.workhistory_hour ? entry.workhistory_hour : 0,
            extra: {
              workhistory_id: entry.workhistory_id,
              projectName: projectName ? projectName : 'Unknown Project',
              description: entry.workhistory_description
                ? entry.workhistory_description
                : 'No Description',
              approved: entry?.workhistory_approved,
              not_approved: entry?.workhistory_not_approved

            },
          };

          if (!dataMap.has(date)) {
            dataMap.set(date, []);
          }
          dataMap.get(date).push(taskData);
        });
      }
    });

    // Sort the data by date in ascending order
    const sortedData = Array.from(dataMap.entries()).sort((a, b) => {
      const dateA = new Date(a[0].split('/').reverse().join('/'));
      const dateB = new Date(b[0].split('/').reverse().join('/'));
      return dateA.getTime() - dateB.getTime();
    });

    this.chartData = sortedData.map(([name, series]) => ({ name, series }));
  }

  prepareChartDataTeam(rows: any) {
    let dataMap = new Map<string, any[]>();
    console.log(rows);
    
    rows.forEach((row) => {
      let timesheet = row.timesheet;
      let projectCode = row.project_code;
      let projectName = row.project_name;

      if (!projectCode) {
        return;
      }
      if (Array.isArray(timesheet)) {
        timesheet.forEach((entry) => {
          const date = entry.workhistory_date
            ? new Date(entry.workhistory_date).toLocaleDateString()
            : 'Unknown';
          const taskData = {
            name: projectCode ? projectCode : 'Unknown project_code',
            value: entry.workhistory_hour ? entry.workhistory_hour : 0,
            extra: {
              workhistory_id: entry.workhistory_id,
              projectName: projectName ? projectName : 'Unknown Project',
              description: entry.workhistory_description
                ? entry.workhistory_description
                : 'No Description',
              approved: entry?.workhistory_approved,
              not_approved: entry?.workhistory_not_approved

            },
          };

          if (!dataMap.has(date)) {
            dataMap.set(date, []);
          }
          dataMap.get(date).push(taskData);
        });
      }
    });

    // Sort the data by date in ascending order
    const sortedData = Array.from(dataMap.entries()).sort((a, b) => {
      const dateA = new Date(a[0].split('/').reverse().join('/'));
      const dateB = new Date(b[0].split('/').reverse().join('/'));
      return dateA.getTime() - dateB.getTime();
    });

    return sortedData.map(([name, series]) => ({ name, series }));
  }

  onActivateCharts(event: any): void {
    console.log('Activate', event);

    if (event.name) {
      const taskName = event.name;
      let totalValue = 0;

      this.chartData.forEach((data) => {
        data.series.forEach((series) => {
          if (series.name === taskName) {
            totalValue += series.value;
          }
        });
      });

      console.log(`Total value for ${taskName}:`, totalValue);
    } else {
      const taskName = event;
      let totalValue = 0;

      this.chartData.forEach((data) => {
        data.series.forEach((series) => {
          if (series.name === taskName) {
            totalValue += series.value;
          }
        });
      });

      console.log(`Total value for ${taskName}:`, totalValue);
    }
  }

  preparePieChartData(rows: any) {
    let projectMap = new Map<string, number>();

    rows.forEach((row) => {
      let timesheet = row.timesheet;
      let projectName = row.project_name;

      // Ignora le righe con il nome "Totale"
      if (projectName === 'Totale') {
        return;
      }

      if (Array.isArray(timesheet)) {
        timesheet.forEach((entry) => {
          let hours = entry.workhistory_hour ? entry.workhistory_hour : 0;
          if (projectMap.has(projectName)) {
            projectMap.set(projectName, projectMap.get(projectName) + hours);
          } else {
            projectMap.set(projectName, hours);
          }
        });
      }
    });

    this.pieChartData = Array.from(projectMap, ([name, value]) => ({
      name: name === '' ? 'Altro' : name, // Imposta "Altro" se name è una stringa vuota
      value,
      extra: {
        projectName: name,
      },
    }));
  }

  preparePieChartDataTeam(rows: any) {
    let projectMap = new Map<string, number>();

    rows.forEach((row) => {
      let timesheet = row.timesheet;
      let projectName = row.project_name;

      // Ignora le righe con il nome "Totale"
      if (projectName === 'Totale') {
        return;
      }

      if (Array.isArray(timesheet)) {
        timesheet.forEach((entry) => {
          let hours = entry.workhistory_hour ? entry.workhistory_hour : 0;
          if (projectMap.has(projectName)) {
            projectMap.set(projectName, projectMap.get(projectName) + hours);
          } else {
            projectMap.set(projectName, hours);
          }
        });
      }
    });

    return Array.from(projectMap, ([name, value]) => ({
      name: name === '' ? 'Altro' : name, // Imposta "Altro" se name è una stringa vuota
      value,
      extra: {
        projectName: name,
      },
    }));
  }

  filterByMonth(event: any) {
    const selectedMonth = event.detail.value;
    if (!selectedMonth) {
      this.chartData = [...this.allChartData];
      this.pieChartData = [...this.allPieChartData];
    } else {
      this.chartData = this.allChartData.filter((item) => {
        const itemMonth = item.name.substring(3, 5); // Extract month from date string in DD/MM/YYYY format
        return itemMonth === selectedMonth;
      });

      const filteredRows = this.rows.filter((row) => {
        return row.timesheet.some((entry) => {
          const entryMonth = entry.workhistory_date.substring(5, 7); // Extract month from date string in YYYY-MM-DD format
          return entryMonth === selectedMonth;
        });
      });

      this.preparePieChartData(filteredRows); // Update pie chart data based on filtered rows
    }
  }

  generateReport() {
    let approvedHours = 0;
    let notApprovedHours = 0;
    let pendingApprovalHours = 0;
    this.details = [];  // Reset the details array

    console.log(this.allChartData);
    
    this.allChartData.forEach(item => {
      item.series.forEach(entry => {
        if (entry.extra?.approved === true) {
          approvedHours += entry.value;
        } else if (entry.extra?.not_approved === true) {
          notApprovedHours += entry.value;
          this.details.push({
            id: entry.extra.workhistory_id,
            status: 'DENY',
            date: item.name,
            hours: entry.value,
            description: entry.extra.description,
            name: entry.name,
            projectName: entry.extra.projectName
          });
        } else {
          pendingApprovalHours += entry.value;
          this.details.push({
            id: entry.extra.workhistory_id,
            status: 'WAIT',
            date: item.name,
            hours: entry.value,
            description: entry.extra.description,
            name: entry.name,
            projectName: entry.extra.projectName
          });
        }
      });
    });

    this.showDetailsButton = notApprovedHours > 0 || pendingApprovalHours > 0;

    this.report = `Report delle ore lavorate:
    - Ore approvate: ${approvedHours}
    - Ore non approvate: ${notApprovedHours}
    - Ore in attesa di approvazione: ${pendingApprovalHours}`;
  }

  generateReportSelected() {
    let approvedHours = 0;
    let notApprovedHours = 0;
    let pendingApprovalHours = 0;
    this.details = [];  // Reset the details array

    this.dataUserSelected?.barChartData?.forEach(item => {

      item.series.forEach(entry => {
        if (entry.extra?.approved === true) {
          approvedHours += entry.value;
        } else if (entry.extra?.not_approved === true) {
          notApprovedHours += entry.value;
          console.log(item)

          this.details.push({
            id: entry.extra.workhistory_id,
            status: 'DENY',
            date: item.name,
            hours: entry.value,
            description: entry.extra.description,
            name: entry.name,
            projectName: entry.extra.projectName
          });
        } else {
          pendingApprovalHours += entry.value;

          this.details.push({
            id: entry.extra.workhistory_id,
            status: 'WAIT',
            date: item.name,
            hours: entry.value,
            description: entry.extra.description,
            name: entry.name,
            projectName: entry.extra.projectName
          });
        }
      });
    });

    this.showDetailsButton = notApprovedHours > 0 || pendingApprovalHours > 0;

    this.report = `Report delle ore lavorate:
    - Ore approvate: ${approvedHours}
    - Ore non approvate: ${notApprovedHours}
    - Ore in attesa di approvazione: ${pendingApprovalHours}`;
  }


  onHandleCheckbox(data:any){
    if (this.userSelected === data.ref_id) {
      this.userSelected = null; // Deseleziona se già selezionato
    } else {
      this.userSelected = data.ref_id; // Seleziona nuovo utente
    }
    this.dataUserSelected = data
    this.report = ''
    this.details = []
    this.user = []
    if(this.showDetailsButton){
      this.showDetailsButton = !this.showDetailsButton
    }
    if(this.showDetails){
      this.showDetails = !this.showDetails
    }
  }


  generateExcel() {
    this.apiReport.generateExcel(this.dataDeps, 6, 2024);
  }

  onActivate(event: any) {
    if (event.type === 'click' && this.isResp) {
      this.setTaskDetailOpen(true);
      console.log(event);      
      // Trova l'oggetto specifico con l'id corrispondente

      if(this.dataDeps.length!==0){
        const targetUser = this.dataDeps.find(dep => 
          dep.meta.some(task => task.timesheet.some(ts => ts.workhistory_id === event.row.id))
          );

          if (targetUser) {
            this.userTask = targetUser.meta
            .find(task => task.timesheet.some(ts => ts.workhistory_id === event.row.id))
            .timesheet
            .sort((a, b) => {
            const aNullCount =
            (a.workhistory_approved === null ? 1 : 0) +
            (a.workhistory_not_approved === null ? 1 : 0);
            const bNullCount =
            (b.workhistory_approved === null ? 1 : 0) +
            (b.workhistory_not_approved === null ? 1 : 0);
            
            if (aNullCount > bNullCount) {
              return -1;
            } else if (aNullCount < bNullCount) {
              return 1;
            } else {
              return 0;
            }
          });
          console.log(this.userTask);
          this.allApproved = this.checkAllApproved(this.userTask);
        }
      }
      else{
            this.userTask = this.rows
            .find(task => task.timesheet.some(ts => ts.workhistory_id === event.row.id))
            .timesheet
            .sort((a, b) => {
            const aNullCount =
            (a.workhistory_approved === null ? 1 : 0) +
            (a.workhistory_not_approved === null ? 1 : 0);
            const bNullCount =
            (b.workhistory_approved === null ? 1 : 0) +
            (b.workhistory_not_approved === null ? 1 : 0);
            
            if (aNullCount > bNullCount) {
              return -1;
            } else if (aNullCount < bNullCount) {
              return 1;
            } else {
              return 0;
            }
          });
          console.log(this.userTask);
          this.allApproved = this.checkAllApproved(this.userTask);
        
      }
    }
    }
    
    
    setTaskDetailOpen(isOpen?: boolean, value?: boolean) {
      //Modale dettagli
      this.isTaskDetailsOpen = isOpen;
    if (isOpen === true) {
    } else if (isOpen === false && value === false) {
      this.callbackModal();
    } else {
      this.isTaskDetailsOpen = false;
    }
  }

  toggleAllApproval(event, all: boolean) {
    this.userTask.forEach((task) => {
      this.onCheckboxChange(task, event.detail.checked ? '1' : '0', 'true');
    });

    this.taskService
      .getTimesheet(this.dataUserSelected.ref_id)
      .subscribe((e) => {
        console.log('1238');

        this.rows = [...e];
        this.rows.forEach((e) => {
          if (e.workhistory_approved) this.allApproved = true;
          else {
            this.allApproved = false;
          }
        });
        this.setTaskDetailOpen(false, false);
      });
    // this.setTaskDetailOpen(false, false);
  }

  onCheckboxChange(taskGroup: FormGroup, checkbox: any, all: any) {
    console.log(taskGroup);
    console.log(checkbox);
    console.log(all);

    this.addTaskForm = this.formBuilder.group({
      // taskresource_id: [this.rows[0].taskresource_id, Validators.required],
      approved: ['', Validators.required],
    });
    if (checkbox === '1') {
      this.addTaskForm.controls['approved'].setValue('1');
      this.taskService
        .approveHour(taskGroup['workhistory_id'], this.addTaskForm.value)
        .subscribe((e) => {
          console.log(e);
        });
    } else if (checkbox === '0') {
      this.addTaskForm.controls['approved'].setValue('0');
      this.taskService
        .approveHour(taskGroup['workhistory_id'], this.addTaskForm.value)
        .subscribe((e) => {
          console.log(e);
        });
    } else if (checkbox.detail.checked === true) {
      this.addTaskForm.controls['approved'].setValue('1');
      this.taskService
        .approveHour(taskGroup['workhistory_id'], this.addTaskForm.value)
        .subscribe((e) => {
          console.log(e);
        });
    } else if (checkbox.detail.checked === false) {
      this.addTaskForm.controls['approved'].setValue('0');
      this.taskService
        .approveHour(taskGroup['workhistory_id'], this.addTaskForm.value)
        .subscribe((e) => {
          console.log(e);
        });
    }
  }

  callbackModal() {
    this.taskService
      .getTimesheet(this.dataUserSelected.ref_id)
      .subscribe((e) => {
        this.rows = [...e];
        this.rows.forEach((e) => {
          if (e.workhistory_approved) this.allApproved = true;
          else {
            this.allApproved = false;
          }
        });
      });
  }
  checkAllApproved(task: any) {
    console.log(task);
    let counter: boolean = true;
    task.forEach((e) => {
      if (!e.workhistory_approved) counter = false;
    });
    return counter;
  }


  getAllTimesheet() {
    this.openLoadingController()
    // if(this.user)

    if(this.user){
      this.onHandleCheckbox(this.user)
    }
    
    // this.user = []
    this.report = ''
    this.details = [];  // Reset the details array
    if(this.showDetailsButton){
      this.showDetailsButton = !this.showDetailsButton
    }
    if(this.showDetails){
      this.showDetails = !this.showDetails
    }

    const timesheetRequests = this.deps.map(dep => 
      this.taskService.getTimesheet(dep.ref_id).pipe(
        map(timesheet => ({
          ref_id: dep.ref_id,
          ref_name: dep.ref_name,
          meta: timesheet,
          barChartData: this.prepareChartDataTeam(timesheet),
          pieChartData: this.preparePieChartDataTeam(timesheet)
        }))
      )
    );

    forkJoin(timesheetRequests).subscribe(results => {
      this.dataDeps = results;
      this.loading.dismiss()
      console.log(this.dataDeps); // Controlla i dati in console
    });
  }


  async openLoadingController() {
    this.loading = await this.loadingController.create();

    this.loading.present();
  }
  
  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  confirm() {
    return this.modalCtrl.dismiss('name', 'confirm');
  }
}
