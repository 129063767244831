
import { AfterContentChecked, ChangeDetectorRef, Component, OnChanges, OnInit, Renderer2, SimpleChanges, TemplateRef, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { NavController, ModalController, IonNav, IonRouterOutlet, IonInfiniteScroll, IonSearchbar, IonContent, IonSegment, IonSegmentButton, IonMenu, MenuController, PopoverController } from '@ionic/angular';

import { animate, query, sequence, stagger, state, style, transition, trigger } from '@angular/animations';

import { TabsService } from 'src/app/services/tabs/tabs.service';

import { ColumnChangesService, ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { OrdersService } from 'src/app/services/orders/orders.service';
import { SubMenuService } from 'src/app/services/utils/sub-menu/sub-menu.service';
import { ListJobsComponent } from '../components/list/list.component';
import { NewOfferComponent } from 'src/app/offers/new-offer/new-offer.component';
import { TitleAppService } from 'src/app/services/utils/title-app/title-app.service';
import { PlatformService } from 'src/app/services/utils/platform/plarformservice';
import { ListProjectsComponent } from 'src/app/projects/components/list/list.component';
import { ProjectsListPage } from 'src/app/projects/projects-list/projects-list.page';

import { NewJobComponent } from '../new-job/new-job.component';
import { JobDetailsPage } from '../job-details/job-details.page';
import { OrdersListPage } from 'src/app/orders/orders-list/orders-list.page';
import { NewOrderComponent } from 'src/app/orders/new-order/new-order.component';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription, filter } from 'rxjs';
import { RoutingStateService } from 'src/app/services/routing-state/routing-state.service';


interface PageInfo {
  offset: number;
  pageSize: number;
  limit: number;
  count: number;
}
/**
 * An object used to get page information from the server
 */
export class Page {
  // The number of elements in the page
  size: number = 0;
  // The total number of elements
  totalElements: number = 0;
  // The total number of pages
  totalPages: number = 0;
  // The current page number
  pageNumber: number = 0;
}

const listAnimation = trigger('listAnimation', [
  transition('* <=> *', [
    query(':enter',
      [style({ opacity: 0 }), stagger('180ms', animate('300ms ease-out', style({ opacity: 1 })))],
      { optional: true }
    ),
    query(':leave',
      animate('200ms', style({ opacity: 0 })),
      { optional: true }
    )
  ])
]);

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs-list.page.html',
  styleUrls: ['./jobs-list.page.scss'],
  // ✨ New! Defining the animation
  animations: [listAnimation],
  //changeDetection: 
})
export class JobsListPage {
  public data: any; // Data;
  public columns: any;
  public rows: any;

  rowIsNotExpanded = true
  isPopoverOpen = false

  editing = {};

  selected = [];

  filterTerm: string;
  enableSearch = false;

  funder = [];
  calculated = [];
  pending = [];
  ColumnMode: ColumnMode
  expanded: any = {};
  timeout: any;
  total_order = 0;
  currentSegment: string = "all";


  @ViewChild('segment') segment: IonSegment;
  @ViewChild('btn_segment') btn_segment: IonSegmentButton;
  @ViewChild('myTable') table: DatatableComponent;
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  @ViewChild(IonContent) content: IonContent;
  @ViewChild(IonSearchbar) myInput: IonSearchbar;
  @ViewChild('popover') popover;


  @ViewChild(ListJobsComponent) listTable: ListJobsComponent;
  @Input() category:any =[];
  @Output() searchEmitter: EventEmitter<string> = new EventEmitter<string>();


  fab: boolean = false;
  customAlertOptions: any = {
    header: 'List groups',
    //subHeader: 'Select group for user',
    message: 'Select group for user',
    translucent: true
  };
  orders: any = []
  _orders: any = []
  searching = false
  res: any = []
  order_status = [];
  order_types = [];
  order_categories = [];
  searchValue: string = '';
  code: boolean = false

  total_page = 0;
  page_number = 1;
  page_limit = 30;


  isSearch: boolean = false;
  isFocus: boolean = false;
  isLoad: boolean = false;

  queryString = {
    search_by: '',
    search_data: ''
  };


  totalElements: number;
  pageNumber: number;
  cache: any = {};


  isLoading = 0;
  testDisabled = false

  navMenu = [
    // { action: 'openNewOrderModal', label: 'New Job', icon: 'receipt-outline' },
    // { action: 'openNewOrderFromCostModal', label: 'New Order', icon: 'receipt-outline' },    

   /*  {
      action: 'presentPopover', label: 'New', icon: 'add-outline', childs: [
        { action: 'openNewOrderModal', label: 'New Order', icon: 'receipt-outline' },
        { action: 'openNewOfferModal', label: 'New Offer', icon: 'receipt-outline' }
      ]
    },
    { action: 'toggleSearch', label: ' ', icon: 'search-outline' },
    //{ action: '', label: 'Export', icon: 'download-outline' },
    { action: 'openMenu', label: 'Filter', icon: 'menu-outline' }, */
  ]

  media_query

  selectedFilter = 'name'
  filterItems: any = [
    {
      search_by: 'name', //name //
      search_data: ''
    },
    {
      search_by: 'code',
      search_data: ''
    },
    {
      search_by: 'status',
      search_data: ''
    }/* 
    {
      search_by: 'vendor',
      search_data: ''
    }, */

  ]
  activeFilterJobs // = false
  stringFilterJobs = ''
  stringStatusOrderFilter = ''
  activeFilterStatusOrder

  constructor(
    private menu: MenuController,
    private subMenu: SubMenuService,
    private cd: ChangeDetectorRef,
    private api: OrdersService,
    private modalController: ModalController,
    //private nav: IonNav,
    private routerOutlet: IonRouterOutlet,
    private tabService: TabsService,
    private renderer: Renderer2,
    private columnChangesService: ColumnChangesService,
    private titleAppService: TitleAppService,
    private popoverController: PopoverController,
    private platformService: PlatformService,
    private router: Router,
    private routingState: RoutingStateService
  ) {

/*     this.navMenu = [
    
    ]
 */

   this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      // Access the navigation state
      const currentNavigation = this.router.getCurrentNavigation();
      const state = currentNavigation?.extras.state;
      console.log('Navigation state:', state);
      if(state && (state['category'] && this.category.length===0)){
        this.category = state['category']
        console.log(this.category);
      }
    });

    
    
  }

  openMenu() {
    console.log(this.menu);

    this.menu.get('end').then(e => {
      console.log(e);
    });
    this.menu.get('start').then(e => {
      console.log(e);

    });


    setTimeout(() => {
      //this.menu.enable(true, 'start');
      this.menu.toggle('end');
      console.log('setTimeout');
    }, 500);


    this.subMenu.params.next({
      title: 'Filter',
      icon: 'funnel-outline',
      accordions: [
        {
          target: '',
          color: '',
          icon: 'list-outline',
          title: 'Status'
        },
        {
          target: '',
          color: '',
          icon: 'list-outline',
          title: 'Priority'
        },
        {
          target: '',
          color: '',
          icon: 'list-outline',
          title: 'Category'
        },
        {
          target: '',
          color: '',
          icon: 'list-outline',
          title: 'Type'
        }
      ]
    })
  }


  createColumns() {
    this.columns = [
      //{ prop: 'id', name: 'ID'/* , frozenLeft: true */, minWidth: 75, maxWidth: 75, cellClass: 'td-id', headerClass: 'th-id' },
      /* {
        prop: 'id', width: 75, minWidth: 75
      }, */
      {
        prop: 'priority.name', name: 'Priority', width: 90, minWidth: 90, cellClass: 'td-priority', headerClass: 'th-priority'
      },
      {
        prop: 'code', width: 255, minWidth: 255, maxWidth: 255
      },
      { prop: 'category.name', name: 'Category', maxWidth: 200 },
      { prop: 'type.name', name: 'Type', maxWidth: 180 },

      //{ prop: 'creator.email', name: 'Email', width: 190, minWidth: 190, maxWidth: 230 },
      { prop: 'project.name', name: 'Project', maxWidth: 240 },
      { prop: 'customer.name', name: 'Customer', maxWidth: 200 },
      { prop: 'vendor.name', name: 'Vendor', maxWidth: 200 },
      //{ prop: 'deleted', maxWidth: 70, cellClass: 'td-deleted', headerClass: 'th-deleted' },

      {
        prop: 'cost', minWidth: 155, maxWidth: 155,/*  summaryFunc: () => this.caclulateSumm('€'), */
      },

    ]
  }


  ionViewWillEnter() {


    // this.router.events.pipe(
    //   filter(event => event instanceof NavigationEnd)
    // ).subscribe(() => {
    //   // Access the navigation state
    //   const currentNavigation = this.router.getCurrentNavigation();
    //   const state = currentNavigation?.extras.state;
    //   console.log('Navigation state:', state);
    //   if(state['category']){
    //     this.category = state['category']
    //     console.log(this.category);
    //   }
    //   else{
    //     this.category = false
    //   }
    // });
    

    setTimeout(() => {
      console.log(this.category);

    }, 700);
    
    this.myInput ? this.myInput.value : null;
    this.titleAppService.title.next(this.category.name)
    this.cache = {}
    //this.orders = [];
    this.fab = true
    this.isPopoverOpen = false;

    //this.loadProjects(this.queryString)

    this.callFilterItem()


  }






  /**
   * @description Get order metadata
   */
  getOrdersMeta() {

    /* this.api.getOrderTypes()
      .subscribe(data => {
        //console.log(data);
        this.order_types = data
      }) */
    /* this.api.order_categories_list()
      .subscribe(data => {
        //console.log(data);
        this.order_categories = data
      }) */
    /* this.api.getOrderStatus()
      .subscribe(data => {
        //console.log(data);
        this.order_status = data
        this.order_status.push(
          {
            id: 'all',
            name: 'All'
          },

        )
      }) */

  }

  returnOrders($event) {
    console.log('returnOrders', $event);
    this.orders = $event
  }

  // onSearchInput(event: any) {
  //   this.searchValue = event.target.value;
  //   console.log(event.target.value)
  // }

  
  onSearchInput(event){
    console.log(event);
    console.log(this.code);
    if(!this.code){
      let name = event.detail.value
      console.log(this.category);
      // let url = '?page=1&items=25&parent_id=0&category_id'+this.category.id+'&name_slug='+name
      let url = name
      console.log(url);
      this.listTable?.searchJob(url, name, this.category.id)
    }
    else{
      let name = event.detail.value
      console.log(this.category);
      // let url = '?page=1&items=25&parent_id=0&category_id'+this.category.id+'&code_slug='+name
      let url = name

      console.log(url);
      this.listTable?.searchJob(url, name, this.category.id)
    }
  }
  

  changeFilter(){
    this.code = !this.code
  }


  triggerColumnChangeDetection(): void {
    this.columnChangesService.onInputChange();
  }


  /**
   * @description Richiama nomi funzioni passate dalla vista (si può fare anche tutto da .ts)
   * @param action 
   */
  fabCallBack(action) {
    console.log;

    if (action) {
      this[action]()
    } else {
      console.log('not call in component, run callback', 'list');
    }
  }

  methodAction(action) {
    try {
      this[action]()
    } catch {
      console.log('not call in parent component');
    }
    //console.log(action)
  }




  // refershEvent(event) {
  //   this.listTable.setPage({ offset: 0 })

  // }

  filterTermEvent(event) {
    this.filterTerm = event
    this.isFocus = false
    console.log('search', this.selectedFilter, event, this.filterTerm);

    try {
      event = JSON.parse(event);



      //this.queryString = event

      //this.listTable.setPage({ offset: 0 })


      this.updateFilterItem(event.type, event.data)


    }
    catch {
      //this.loadProjects(this.queryString);
      //this.getOrders(false, event, this.queryString);

      this.updateFilterItem(event.type, event.data)

    }
    //this.listTable.setPage({ offset: 0 })

    setTimeout(() => {
      this.isFocus = true
    }, 1800);
  }

  typeFilterTerm(event: any) {
    console.log(event);
    this.selectedFilter = event
    //this.updateFilterItem(event.type, event.value)


  }



  toggleExpandRow(row) {
    console.log('Toggled Expand Row!', row);
    this.table.rowDetail.collapseAllRows();
    this.table.rowDetail.toggleExpandRow(row);
  }


  sorted(columnProp: string, direction: string) {
    console.log(columnProp, direction);
    localStorage.setItem('filter_sort_orders', JSON.stringify({ search_by: 'sort', search_data: [{ prop: columnProp, dir: direction }] }))
  }


  onSelect({ selected }) {
    console.log('Select Event', selected, this.selected);
  }

  /**
   * @description onClick on row open order details
   * @param event pass obj row clicked
   */
  onActivate(event) {
    /* if (this.table.rowDetail && event.type == 'click') {
      this.table.rowDetail.toggleExpandRow(event.row);
      console.log('Activate Event', event);
    } */
    console.log(event);

    if (event.type == 'click') {
      console.log('Activate Event', event);
      let comp = OrdersListPage
      console.log('job to order');
      console.log(event.row);
      
      
      this.router.navigate(['/order'], { state: { job: event.row, jobId: event.row} });

      //nav: this.nav(comp, { job: event.row }, { animated: true, direction: 'forward' });

      //this.openOrderModal(event.row)
    }


  }
  goBack(){
    this.router.navigate(['/'+ this.routingState.navigateBack()]);
  }

  onActivateCategory(event) {
    /* if (this.table.rowDetail && event.type == 'click') {
      this.table.rowDetail.toggleExpandRow(event.row);
      console.log('Activate Event', event);
    } */


    console.log('Activate Event', event);
    let comp = JobsListPage

    //nav: this.nav(comp, { dataProps: event }, { animated: true, direction: 'forward' });

  }

  setExpandStatus(expanded: any): void {
    console.log('setExpandStatus Event', expanded);
  }



  /**
   * @description Create new order, open modal
   * @returns 
   */
  async openNewOrderModal(event?) {
    await this.popoverController.getTop() ? this.popoverController.dismiss() : null;
    const modal = await this.modalController.create({
      component: NewJobComponent,
      cssClass: 'newOrderModalForm',
      backdropDismiss: true,
      mode: 'md',
      componentProps: {
        title: 'New job',
        category_id: this.category
      },
      breakpoints: [0,  0.6, 0.7],
      initialBreakpoint: 0.6,
      //presentingElement: this.routerOutlet.nativeEl,
    })


    modal.onWillDismiss().then((e) => {
      // Se Ok passo la risposta di conferma dell'api per risparmiare sui servizi http
      console.log('NewOrderComponent to OrderListPage', e);
      if (!e.data) {
        console.log('no modify');
        return
      } else {
        console.log(e.data);
        
        // Se esiste refresh brutalmente (si puo rimpiazzare il risultato della singola riga come fatto per la tabella prodotti)
        this.listTable.rows.push(e.data)
        this.listTable.page.totalElements += 1
        this.listTable.rows = [...this.listTable.rows]
        //this.orders = [];

      }
    });
    return await modal.present();
  }


  async openNewOrderFromCostModal(event?) {
    await this.popoverController.getTop() ? this.popoverController.dismiss() : null;
    const modal = await this.modalController.create({
      component: NewOrderComponent,
      cssClass: 'newOrderModalForm',
      backdropDismiss: true,
      mode: 'md',
      componentProps: {
        title: 'New Order Draft',
        project_in: '',
        category: this.category,
        categoryId: this.category.id,
        fromJobsList: true
      }
      //presentingElement: this.routerOutlet.nativeEl,
    })


    modal.onWillDismiss().then((e) => {
      // Se Ok passo la risposta di conferma dell'api per risparmiare sui servizi http
      console.log('NewOrderComponent to OrderListPage', e);
      if (!e.data) {
        console.log('no modify');
        return
      } else {

        console.log(e.data);
        
        // Se esiste refresh brutalmente (si puo rimpiazzare il risultato della singola riga come fatto per la tabella prodotti)
        // this.listTable.rows.unshift(e.data)
        // this.listTable.page.totalElements += 1
        // this.listTable.rows = [...this.listTable.rows]
        //this.orders = [];

      }
    });
    return await modal.present();
  }



  /**
   * @description Create new order, open modal
   * @returns 
   */
  async openNewOfferModal() {
    await this.popoverController.dismiss();
    ////nav: this.nav(NewOfferComponent, { animate: true }, { direction: 'forward' })

    const modal = await this.modalController.create({
      component: NewOfferComponent,
      cssClass: 'newOrderModalForm',
      backdropDismiss: true,
      mode: 'md',
      componentProps: {
        title: 'New Offer Draft',
      }
      //presentingElement: this.routerOutlet.nativeEl,
    })


    modal.onWillDismiss().then((e) => {
      // Se Ok passo la risposta di conferma dell'api per risparmiare sui servizi http
      console.log('NewOfferComponent to OrderListPage', e);
      if (!e.data) {
        console.log('no modify');
        return
      } else {
        // Se esiste refresh brutalmente (si puo rimpiazzare il risultato della singola riga come fatto per la tabella prodotti)
        this.listTable.rows.push(e.data)
        this.listTable.totalElements = this.listTable.rows.length
        this.listTable.rows = [...this.listTable.rows]
        //this.orders = [];

      }
    });
    return await modal.present();
  }

  /**
   * @description Apre il modale per il dettaglio ordine 
   * @trigger this.onActivate($row)
   * @param item 
   * @returns 
   */
  async openOrderModal(item) {

    const modal = await this.modalController.create({
      component: OrdersListPage,
      cssClass: 'fullscreen',
      //backdropDismiss: false,
      mode: 'md',
      componentProps: {
        id: item.id,
        title: item.name,
        //nav: this.nav
      }
      //presentingElement: this.routerOutlet.nativeEl,
    })


    modal.onWillDismiss().then((e) => {
      // Se Ok passo la risposta di conferma dell'api per risparmiare sui servizi http
      console.log('OrderDetailsPage to OrderListPage', e, this.listTable.rows);

      if (!e.data) {
        console.log('no modify');
        return
      } else {
        // Se esiste refresh brutalmente (si puo rimpiazzare il risultato della singola riga come fatto per la tabella prodotti)

        this.orders = this.listTable.rows
        let ordersIndex = this.orders.map((e, i, a) => e.id).indexOf(item.id)
        this.orders[ordersIndex] = e.data;
        console.log(this.orders, ordersIndex);

        this.listTable.rows = this.orders;

        setTimeout(() => {
          this.listTable.table.bodyComponent.offsetX = 0;
          this.listTable.table.bodyComponent.offsetY = 0;
          this.listTable.table.headerComponent.offsetX = 0;
          this.listTable.table.recalculateColumns();
          this.listTable.rows = [...this.listTable.rows]
        }, 100);

        console.log(this.orders);


      }
    });
    return await modal.present();
  }


  onGetRowClass = (row) => {
    //console.log(row);
    // id 3 is Delete for Status Order
    if (row.status.id === 3) {
      return 'deleted';
    } else if (row.status.id === 2) {
      return 'request';
    } else if (row.status.id === 1) {
      return 'draft';
    } else if (row.status.id === 4) {
      return 'order';
    }
    else
      return ''
  }


  /**
   * @description ritorna la somma delle colonne che gli si assegna questa funzione
   * @param n 
   * @returns somma della colonna in "rowSummary" template
   */
  caclulateSumm(n?) {
    console.log();

    this.total_order = 0;
    this.orders.map((item) => {
      this.total_order += item.cost;
      //this.currencyCode = item.currency;
      //console.log(this.total_order);
    });
    return this.total_order.toFixed(2) + ' ' + n;
  }


  /* 
    onChangeSearchbar(event) {
      console.log('search', event);
      if (event != '') {
        //this.queryString = localStorage.getItem('filter_search_orders') ? JSON.parse(localStorage.getItem('filter_search_orders')) : { search_by: '', search_data: 'all' }
  
        this.page_number = 1;
        let url = this.concateQuery(this.queryString)
  
        this.api.searchOrder(true, url, event.toString().toLowerCase().trim()).subscribe(e => {
          console.log(e);
          if (e) {
            this.listTable.rows = e.results;
            this.listTable.totalElements = e.total_items;
  
            setTimeout(() => {
              this.cache = {}
              this.listTable.table.bodyComponent.offsetX = 0;
              this.listTable.table.bodyComponent.offsetY = 0;
              this.listTable.table.headerComponent.offsetX = 0;
              this.listTable.table.recalculateColumns();
              this.listTable.rows = [...this.listTable.rows]
            }, 100);
          }
        })
  
      } else {
        //this.loadProjects(this.queryString);
        this.getOrders(false, event, this.queryString);
      }
    } */


  onSwichStatusSeg(event) {
    console.log(event, this.myInput);
    if (this.myInput && this.myInput.value != '') {
      document.querySelector('ion-searchbar').getInputElement().then((searchInput) => {
        searchInput.value = '';
      });
    }

  }



  toggleSearch() {
    this.enableSearch = !this.enableSearch;
    if (this.enableSearch) {
      setTimeout(() => {
        this.myInput.setFocus()
      }, 150);

    }
  }


  callFilterItem() {

    let readFilter = {};

    try {
      readFilter = JSON.parse(localStorage.getItem('filter_orders'))
      console.log(readFilter, this.filterItems);


      /*       if (readFilter == null) {
              localStorage.setItem('filter_orders', JSON.stringify(this.filterItems))
            } else {
              this.filterItems = readFilter;
            } */

      // forza il reset al l'entrata - viene svolta dal filglio
      /* readFilter[0].search_data = ''
      readFilter[1].search_data = ''
      localStorage.setItem('filter_orders', JSON.stringify(readFilter)) */


      this.filterItems.map(e => {
        if (e.search_data != '') {
          switch (e.search_by) {
            case 'name':
              this.activeFilterJobs = true
              this.stringFilterJobs = e.search_data
              break;
            case 'code':
              this.activeFilterJobs = true
              this.stringFilterJobs = e.search_data
              break;
            case 'status':
              this.activeFilterJobs = true
              this.stringFilterJobs = e.search_data
              break;

            default:
              break;
          }
        } else if (e.search_data == '') {
          switch (e.search_by) {
            case 'name':
              this.activeFilterJobs = false
              this.stringFilterJobs = e.search_data
              break;
            case 'code':
              this.activeFilterJobs = false
              this.stringFilterJobs = e.search_data
              break;
            case 'status':
              this.activeFilterJobs = false
              this.stringFilterJobs = e.search_data
              break;

            default:
              break;
          }
        }
      })

    }
    catch {
      console.log('error');
      localStorage.setItem('filter_orders', JSON.stringify(this.filterItems))
      this.filterItems = readFilter;
    }



    this.listTable.setPage({ offset: 0 })

  }

  updateFilterItem(filter, data) {

    this.filterItems = JSON.parse(localStorage.getItem('filter_orders'))
    let foundIndex

    foundIndex = this.filterItems.map(e => e.search_by).indexOf(filter);
    if (foundIndex == -1) {
      this.filterItems.push({ search_by: filter, search_data: data });
    } else {
      this.filterItems[foundIndex] = { search_by: filter, search_data: data };

    }

    localStorage.setItem('filter_orders', JSON.stringify(this.filterItems))
    console.log('updateFilterItem', this.filterItems, filter, data);


    this.filterItems.map(e => {
      if (e.search_data != '') {
        switch (e.search_by) {
          case 'name':
            this.activeFilterJobs = true
            this.stringFilterJobs = e.search_data
            break;
          case 'code':
            this.activeFilterJobs = true
            this.stringFilterJobs = e.search_data
            break;
          case 'status':
            this.activeFilterJobs = true
            this.stringFilterJobs = e.search_data
            break;

          default:
            break;
        }
      } else if (e.search_data == '') {
        switch (e.search_by) {
          case 'name':
            this.activeFilterJobs = false
            this.stringFilterJobs = e.search_data
            break;
          case 'code':
            this.activeFilterJobs = false
            this.stringFilterJobs = e.search_data
            break;
          case 'status':
            this.activeFilterJobs = false
            this.stringFilterJobs = e.search_data
            break;

          default:
            break;
        }
      }
    })
  }




}
