import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit {

  @Input() type :any;
  constructor() { }

  ngOnInit() {}

  getSearch(event){
    console.log(event.detail.value, this.type)

  }

}
