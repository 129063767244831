import { AddItemSelectOptionComponent } from './../components/add-item-select-option/add-item-select-option.component';
//import { AddModelSelectOptionComponent } from './../components/add-model-select-option/add-model-select-option.component';/* #Ric */
import { ModalOrderUploadFileComponent } from './../orders/components/modal-order-upload-file/modal-order-upload-file.component';
import { ModalOrderLegalComponent } from './../orders/components/modal-order-legal/modal-order-legal.component';
import { DateComponent } from './../orders/order-update/date/date.component';
import { NewTimeregistryComponent } from '../tasks/components/new-timeregistry/new-timeregistry.component';
import { NewTaskComponent } from './../tasks/new-task/new-task.component';
import { DdtLineComponent } from './../trasport_documents/ddt-details/ddt-line-progress/ddt-line.component';
import { NewStoresComponent } from './../stores/new-stores/new-stores.component';
import { SearchBarComponent } from './../components/form/search-bar/search-bar.component';
import { NewDdtComponent } from './../trasport_documents/new-ddt/new-ddt.component';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';


import { ModalComponent } from '../components/modal/modal.component';
import { PreloaderImgComponent } from '../components/preloader-img/preloader-img.component';
import { NumberSuffixPipe } from '../pipes/number-suffix.pipe';
import { FormatFileSizePipe } from '../pipes/format-file-size.pipe';

import { FabBtnComponent } from '../components/fab-btn/fab-btn.component';
import { SubMenuComponent } from '../components/sub-menu/sub-menu.component';
import { UploadComponent } from '../components/media/upload/upload.component';
import { UploadDesktopComponent } from '../components/media/upload-desktop/upload-desktop.component';
import { ListFileComponent } from '../components/media/list-file/list-file.component';
import { LongPress } from './ionic-gesture-config';
import { ModalInfoFileComponent } from '../components/media/modal-info-file/modal-info-file.component';
import { ImageSkeletonComponent } from '../components/image-skeleton/image-skeleton.component';
import { TextSkeletonComponent } from '../components/text-skeleton/text-skeleton.component';
import { NewProjectComponent } from '../projects/new-project/new-project.component';
import { NewVendorComponent } from '../vendors/new-vendor/new-vendor.component';
import { ModalContentComponent } from '../components/form/modal-content/modal-content.component';
import { EmptyNotificationThirdComponent } from '../components/empty-notification-third/empty-notification-third.component';
import { LoaderItemsComponent } from '../components/loader-items/loader-items.component';
import { NewProductComponent } from '../products/new-product/new-product.component';
import { NewOrderComponent } from '../orders/new-order/new-order.component';
import { ModalNFCComponent } from '../components/nfc/nfc.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { ModalOrderCreateUpdateComponent } from '../orders/components/modal-order-meta/modal-order-meta.component';
import { NgxEchartsModule } from 'ngx-echarts';
import * as echarts from 'echarts';
import { DatasetLinkComponent } from '../components/graph/dataset-link/dataset-link.component';
import { ModalDdtMetaComponent } from '../trasport_documents/modal-ddt-meta/modal-ddt-meta.component';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ListOrdersComponent } from '../orders/components/list/list.component';
import { ListCategoryOrdersComponent } from '../orders/components2/list/list.component';
import { ListJobsComponent } from '../jobs/components/list/list.component';

import { ListInvoiceComponent } from '../invoices/components/list/list.component';
import { ModalInvoiceMetaComponent } from '../invoices/components/modal-invoice-meta/modal-invoice-meta.component';
import { NewInvoiceComponent } from '../invoices/new-invoice/new-invoice.component';
import { InvoiceLineComponent } from '../orders/order-details/invoice-line/invoice-line.component';
//import { NgxGanttModule } from '@worktile/gantt';
import { GraphicStrokeAnimationComponent } from '../components/graph/graphic-stroke-animation/graphic-stroke-animation.component';
import { NewWorkerComponent } from '../workers/new-worker/new-worker.component';
import { NewUserComponent } from '../users/new-user/new-user.component';
import { NewReportComponent } from '../reports/new-report/new-report.component';
import { ListWorkersComponent } from '../workers/components/list/list.component';
import { ListUsersComponent } from '../users/components/list/list.component';
import { ErrorFormsComponent } from '../components/errors/forms/forms.component';
import { TableListComponent } from '../components/table/list/list.component';
import { TooltipComponent } from '../components/tooltip/tooltip.component';
import { BarStackComponent } from '../components/graph/bar-stack/bar-stack.component';
import { PieComponent } from '../components/graph/pie/pie.component';

import { NewOfferComponent } from '../offers/new-offer/new-offer.component';
import { ListProductsServiceComponent } from '../products/components/list/list.component';
import { InputCurrencyComponent } from '../components/form/input-currency/input-currency.component';
import { StepsComponent } from '../components/steps/steps.component';
import { StepTemplateComponent } from '../components/steps/step-template/step-template.component';
import { HeaderComponent } from '../components/header/header.component';
import { ClaritySharedModule } from './clarity.module';
import { RangeComponent } from '../components/form/range/range.component';

import { icon, Marker } from 'leaflet';
import { ListProjectsComponent } from '../projects/components/list/list.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { IonSelectorComponent } from '../components/ion-selectable/ion-selector/ion-selector.component';
import { CustomToastModalComponent } from '../components/custom-toast-modal/custom-toast-modal.component';

import { CarouselComponent } from '../components/carousel/carousel.component';
import { CardComponent } from '../components/card/card.component';
import { IonicSelectableComponent } from 'ionic-selectable';
import { NewJobComponent } from '../jobs/new-job/new-job.component';
import { NewOrderCategoryComponent } from '../orders/new-category/new-category.component';
import { ListDateComponent } from '../orders/order-update/date/list/list.component';
import { NewDateComponent } from '../orders/order-update/date/new-date/new-date.component';
import { NewLineAddress } from '../components/new-line-address/new-line-address.component';
import { IonSelectorReportComponent } from '../components/ion-selectable-report/ion-selector-report/ion-selector-report.component';
import { NewServiceComponent } from '../products/new-service/new-service.component';
import { TranslatePipe } from '../pipes/translate.pipe';
import { StakeholdersPipe } from '../pipes/stakeholders.pipe';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBar } from '@angular/material/progress-bar';
import { MatTreeModule } from '@angular/material/tree';
import { TaskAssignComponent } from '../projects/project-details/component/task-assign.component';
import { titleAssignesPipe } from '../projects/project-details/task-assign-title.pipe';
// import { TotalOrderDetailsPage } from '../totalorders/order-details/order-details.page';
// import { TotalOrderUpdatePage } from '../totalorders/order-update/order-update.page';
// import { TotalOrdersListComponent } from '../totalorders/components/list/list.component';
// import { NewOrderTotalComponent } from '../totalorders/new-order/new-order.component';
// import { ModalOrderTotalCreateUpdateComponent } from '../totalorders/components/modal-order-meta/modal-order-meta.component';
// import { ModalOrderTotalLegalComponent } from '../totalorders/components/modal-order-legal/modal-order-legal.component';
// import { ModalOrderDiscountLineComponent } from '../orders/components/discount-line/modal/modal.component';
// import { ModalOrderTotalDiscountLineComponent } from '../totalorders/components/discount-line/modal/modal.component';
// import { ModalOrderTotalUploadFileComponent } from '../totalorders/components/modal-order-upload-file/modal-order-upload-file.component';
// import { DiscountTotalLineComponent } from '../totalorders/components/discount-line/discount-line.component';
// import { TotalInvoiceLineComponent } from '../totalorders/order-details/invoice-line/invoice-line.component';
// import { TotalDateComponent } from '../totalorders/order-update/date/date.component';
// import { TotalNewDateComponent } from '../totalorders/order-update/date/new-date/new-date.component';
// import { TotalListDateComponent } from '../totalorders/order-update/date/list/list.component';




const iconRetinaUrl = './assets/marker-icon-2x.png';
const iconUrl = './assets/marker-icon.png';
const shadowUrl = './assets/marker-shadow.png';
const iconDefault = icon({
iconRetinaUrl,
iconUrl,
shadowUrl,
iconSize: [25, 41],
iconAnchor: [12, 41],
popupAnchor: [1, -34],
tooltipAnchor: [16, -28],
shadowSize: [41, 41]
});
Marker.prototype.options.icon = iconDefault;




@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    NgCircleProgressModule,
    NgxEchartsModule.forChild(),
    //SwiperModule,
    NgxDatatableModule,
    //NgxGanttModule,
    ClaritySharedModule,
    NgxPaginationModule,
    
    //LottieModule,
    MatTreeModule,
    MatIconModule,
    MatButtonModule,
    MatProgressBar,
    IonicSelectableComponent,

  ],
  declarations: [
    HeaderComponent,
    CustomToastModalComponent,
    LongPress,
    
    NumberSuffixPipe,
    FormatFileSizePipe,
    TranslatePipe,
    StakeholdersPipe,

    

    CarouselComponent,
    CardComponent,
    ModalInfoFileComponent,
    SubMenuComponent,
    FabBtnComponent,
    PreloaderImgComponent,
    ModalComponent,
    ModalOrderCreateUpdateComponent,
    ModalNFCComponent,
    ModalOrderLegalComponent,
    UploadComponent,
    UploadDesktopComponent,
    ListFileComponent,
    ImageSkeletonComponent,
    TextSkeletonComponent,
    NewProjectComponent,
    ModalContentComponent,
    NewVendorComponent,
    NewProductComponent,
    NewServiceComponent,
    NewInvoiceComponent,
    NewOrderComponent,
    NewOrderCategoryComponent,
    NewOfferComponent,
    NewDdtComponent,
    NewStoresComponent,
    NewWorkerComponent,
    NewTaskComponent,
    NewTimeregistryComponent,
    NewUserComponent,
    NewReportComponent,
    ListInvoiceComponent,
    StepsComponent,
    StepTemplateComponent,
    RangeComponent,

    NewJobComponent,
    DdtLineComponent,
    InvoiceLineComponent,

    ListProjectsComponent,
    ListOrdersComponent,
    ListCategoryOrdersComponent,
    ListWorkersComponent,
    ListUsersComponent,
    ListProductsServiceComponent,
    ErrorFormsComponent,

    ListJobsComponent,

    TableListComponent,
    TooltipComponent,

    EmptyNotificationThirdComponent,
    LoaderItemsComponent,
    ModalInvoiceMetaComponent,
    ModalOrderCreateUpdateComponent,
    ModalDdtMetaComponent,
    ModalOrderUploadFileComponent,
    DatasetLinkComponent,
    InputCurrencyComponent,
    SearchBarComponent,
    GraphicStrokeAnimationComponent,
    BarStackComponent,
    PieComponent,
    ListDateComponent,

    NewDateComponent,
    NewLineAddress,
    
    IonSelectorComponent,
    TaskAssignComponent,
    IonSelectorReportComponent,
    DateComponent,
    AddItemSelectOptionComponent,
    titleAssignesPipe
    
    // TotalOrdersListComponent,
    // NewOrderTotalComponent,
    // ModalOrderTotalCreateUpdateComponent,
    // ModalOrderTotalLegalComponent,
    // ModalOrderTotalDiscountLineComponent,
    // ModalOrderTotalDiscountLineComponent,
    // ModalOrderTotalUploadFileComponent,
    // DiscountTotalLineComponent,
    // TotalInvoiceLineComponent,
    // TotalDateComponent,
    // TotalNewDateComponent,
    // TotalListDateComponent
    //AddModelSelectOptionComponent
    // TestcomponentComponent
  ],
  exports: [
    IonicSelectableComponent,
  

    ClaritySharedModule,
    HeaderComponent,
    CustomToastModalComponent,
    
    LongPress,

    NumberSuffixPipe,
    FormatFileSizePipe,
    TranslatePipe,
    StakeholdersPipe,

    
    NgxPaginationModule,

    FormsModule,
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
    //NgxGanttModule,

    NgCircleProgressModule,

    NgxEchartsModule,
    // LottieModule,

    // SwiperModule,
    NgxDatatableModule,

    NewJobComponent,
    CarouselComponent,
    CardComponent,
    ModalInfoFileComponent,
    SubMenuComponent,
    FabBtnComponent,
    PreloaderImgComponent,
    ModalComponent,
    ModalOrderCreateUpdateComponent,
    ModalNFCComponent,
    ModalOrderLegalComponent,
    UploadComponent,
    UploadDesktopComponent,
    ListFileComponent,
    ImageSkeletonComponent,
    TextSkeletonComponent,
    NewProjectComponent,
    ModalContentComponent,
    NewVendorComponent,
    NewProductComponent,
    NewServiceComponent,
    NewOrderComponent,
    NewOrderCategoryComponent,
    NewOfferComponent,
    NewInvoiceComponent,
    NewDdtComponent,
    NewStoresComponent,
    NewWorkerComponent,
    NewTaskComponent,
    NewTimeregistryComponent,
    NewUserComponent,
    NewReportComponent,
    InvoiceLineComponent,
    StepsComponent,
    StepTemplateComponent,
    RangeComponent,

    ListProjectsComponent,
    ListOrdersComponent,
    ListCategoryOrdersComponent,
    ListInvoiceComponent,
    ListWorkersComponent,
    ListUsersComponent,
    ListProductsServiceComponent,

    ListJobsComponent,

    ErrorFormsComponent,
    TableListComponent,
    TooltipComponent,

    EmptyNotificationThirdComponent,
    LoaderItemsComponent,
    ModalInvoiceMetaComponent,
    ModalOrderCreateUpdateComponent,
    ModalDdtMetaComponent,
    ModalOrderUploadFileComponent,
    InputCurrencyComponent,
    ListDateComponent,
    NewDateComponent,
    
    DatasetLinkComponent,
    SearchBarComponent,
    GraphicStrokeAnimationComponent,
    BarStackComponent,
    PieComponent,
    IonSelectorComponent,
    TaskAssignComponent,
    IonSelectorReportComponent,
    DateComponent,
    AddItemSelectOptionComponent,
    NewLineAddress,
    MatTreeModule,
    MatIconModule,
    MatButtonModule,
    MatProgressBar,
    titleAssignesPipe
    // TotalOrdersListComponent,
    // NewOrderTotalComponent,
    // TotalOrdersListComponent,
    // ModalOrderTotalCreateUpdateComponent,
    // ModalOrderTotalLegalComponent,
    // ModalOrderTotalDiscountLineComponent,
    // ModalOrderTotalDiscountLineComponent,
    // ModalOrderTotalUploadFileComponent,
    // DiscountTotalLineComponent,
    // TotalInvoiceLineComponent,
    // TotalDateComponent,
    // TotalNewDateComponent,
    // TotalListDateComponent
    // TestcomponentComponent
    //AddModelSelectOptionComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }
