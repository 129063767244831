/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable object-shorthand */
/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/semi */
/* eslint-disable @typescript-eslint/naming-convention */


import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Platform, ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { catchError, delay, distinctUntilChanged, map, retryWhen, scan, tap, timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../authentication/authentication.service';
import { NetworkService, ConnectionStatus } from '../network/network.service';
//import { Storage } from '@ionic/storage';
import { OfflineManagerService } from '../network/offline-manager.service';

//import environment from '../../../config.json';


const API_STORAGE_KEY = 'storesService';
const TOKEN_KEY = 'access-token';

@Injectable({
  providedIn: 'root'
})
export class StoresService {

  private url: string = 'http';
  private token: any;
  //private stores: Stores[];  


  constructor(
    private storage: Storage,
    private plt: Platform,
    private http: HttpClient,
    public toastController: ToastController,
    private authService: AuthenticationService,
    private networkService: NetworkService,
    private offlineManager: OfflineManagerService
  ) {

    /**
     * Map url API
     */
    this.url = environment.SSL.active ? this.url + 's' : this.url
    this.url += '://' + environment.API_URI.url + ':' + environment.API_URI.port + environment.API_URI.path

    this.authService.currentToken.subscribe(x => {
      //console.log(x);
      this.token = x //= JSON.parse(localStorage.getItem(TOKEN_KEY))

    })

  }

  /**
   * 
   * Stores
  */

  /**
    * 
    * @param id id of store
    * @returns store
  */


  getStores(params): Observable<any> {

    return this.http.get<any>(`${this.url}mahazin/stores/${params}`)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          this.setLocalData('list', data);
          return data
        }),
        catchError(this.handleError<any[]>('Get product by id', []))
      );

  }


  /**
   * 
   * @param store 
   * @returns 
   */

  createStore(store: any) {
    return this.http.post<any>(`${this.url}mahazin/stores/`, store)
      .pipe(
        tap(data => {
          console.log(data);
        }),
        catchError(this.handleError<any[]>('Create Store', []))
      );
  }



  /**
 * 
 * @param id id of store
 * @returns store
 */
  getStore(id): Observable<any> {
    return this.http.get<any>(`${this.url}mahazin/store/${id}/`)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Get stores by id', []))
      );

  }

  /**
   * 
   * 
   * @returns Product by store
  */

  getStoresLines(url, id) {
    return this.http.get<any>(`${this.url}mahazin/store/${id}`)
      .pipe(
        tap(data => {
          console.log(data.products);
        }),
        catchError(this.handleError<any[]>('Create Store', []))
      );

  }

  /**
 * 
 * 
 * @returns Product id history ddt IN/OUT
*/

  getHistoryProduct(product_id) {

    //return this.http.get<any>(`${this.url}mahazin/flows/${product_id}/history/`)
    return this.http.get<any>(`${this.url}mahazin/ddtlinehistory/${product_id}/`)
      .pipe(
        tap(data => {
          console.log(data.products);
        }),
        catchError(this.handleError<any[]>('History product', []))
      );
 
  }


  /**
   * 
   * @param product 
   * @returns 
   */
  /* 
     createProduct(product: Product) {
      
      return this.http.post<any>(`${this.url}mahazin/products/`, product)
        .pipe(
          tap(data => {
            console.log(data);
          }),
          catchError(this.handleError<any[]>('Create product', []))
        );
    }
    
    updateProduct(product: Product,id) {    
      return this.http.put<any>(`${this.url}mahazin/product/${id}/`, product)
        .pipe(
          tap(data => {
            console.log(data);
          }),
          catchError(this.handleError<any[]>('Update product', []))
        );
    }
  */


  private handleError<T>(operation = 'operation', result?: T) {

    return (error: any): Observable<T> => {
      console.log(result, error);
      console.log(`${operation} failed`, error);
      const status = error == 'Forbidden' ? 403 : 500
      this.presentToast(status, error == 'undefined' ? 'Internal Server Error' : error, operation)
      return of(result as T);
    };
  }


  async presentToast(status, statusText, message) {
    const toast = await this.toastController.create({
      message: status + ' ' + statusText + ': "' + message + '"',
      duration: 2000,
      mode: 'ios',
      cssClass: 'toast',
      color: status != '200' ? 'danger' : 'primary'
    });
    toast.present();
  }

  logSys(src, status, opt?) {
    const debugStyle1 = 'background: linear-gradient(135deg,#471ee9,#3a49b7); border: 1px solid #9a9a9a; color: #ffffff; border-bottom-left-radius: 2px; border-top-left-radius: 2px; padding: 2px 0 2px 4px;';
    const debugStyle2 = 'background: #252b3e; border: 1px solid #9a9a9a; border-top-right-radius: 2px; border-bottom-right-radius: 2px; margin-left: -2px; padding: 2px 4px; color: white;';
    console.log(`%cApiService %c%s`, debugStyle1, debugStyle2, ' ' + src, status);
  }



  // Save result of API requests
  private setLocalData(key, data) {
    this.storage.set(`${API_STORAGE_KEY}-${key}`, data);
  }

  // Get cached API result
  private getLocalData(key) {
    return this.storage.get(`${API_STORAGE_KEY}-${key}`);
  }
}
