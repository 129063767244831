import { forkJoin, from } from 'rxjs';
import { OrdersService } from 'src/app/services/orders/orders.service';
import { ModalController, ToastController } from '@ionic/angular';
import { sequence } from '@angular/animations';
import { FormGroup, FormGroupDirective, Validators, FormBuilder } from '@angular/forms';
import { Component, Input, Renderer2, ViewChild } from '@angular/core';
import { PaymentsService } from 'src/app/services/payments/payments.service';
import { CurrencyPipe } from '@angular/common';


class CurrencyOnlyPipe extends CurrencyPipe {
  public override transform(value): any {
    return super.transform(value, 'EUR', 'symbol');
  }
}

@Component({
  selector: 'app-modal-order-legal',
  templateUrl: './modal-order-legal.component.html',
  styleUrls: ['./modal-order-legal.component.scss'],
})
export class ModalOrderLegalComponent {
  @Input() data: any = '';
  @Input() type: any = '';
  @Input() form: any = '';
  @Input() order_id: any = '';
  @Input() canEdit: any = false;

  @ViewChild('createForm') createForm: FormGroupDirective;

  updateGroupForm: FormGroup = null;
  updatePayGroupForm: FormGroup = null;

  onSearchSelector
  portsSubscription

  onSelectCategory
  category_in

  onSelectTerm
  term_in

  onSelectReference
  reference_in

  onSelectDayReference
  day_reference_in

  onSelectMethod
  method_in

  response = '';
  title = ''

  categories = []

  methods = []
  types = []
  references = []
  terms = []
  isIconSize = false;
  /* */
  chgToolbar = ''
  iconName = 'chevron-up-circle-outline'
  showConfirm = true
  showFormPayment = false

  amount: any
  constructor(
    private fb: FormBuilder,
    private modalController: ModalController,
    private api: OrdersService,
    private apiPay: PaymentsService,
    private toastController: ToastController,
    private currencyPipe: CurrencyPipe,
    private renderer: Renderer2
  ) { }

  ionViewWillEnter() {
    console.log(this.data, this.type, this.title);
    this.response = '';
    this.getMetaInfoPayment()

    // if create or update Legal
    if (this.type == 'legal') {
      if (this.form == 'update') {
        this.title = 'Legal: '
        this.createUpdateForm();
      }
      else if (this.form == 'create') {
        this.title = 'New Legal'
        this.createBaseForm();
      }

    }
    else if (this.type == 'extra') {
      if (this.form == 'update') {
        this.title = 'Extra: '
        this.createUpdateForm();

      }
      else if (this.form == 'create') {
        this.title = 'New Extra: '
        this.createBaseForm();
      }

    }
    else if (this.type == 'payments') {
      //this.getMetaInfoPayment().then(e => {

      if (this.form == 'update') {
        this.title = 'Payment: '
        this.createUpdateFormPay();

      }
      else if (this.form == 'create') {
        this.title = 'New Payment: '
        this.createBaseFormPay();
      }
      //})

    }


  }

  createBaseForm() {
    // Generete FormGroup Update Legal 
    this.updateGroupForm = this.fb.group({
      title: this.fb.control('', Validators.required),
      sequence: this.fb.control(null),
      active: this.fb.control(true),
      deleted: this.fb.control(false),
      order_id: this.fb.control(this.order_id),
      content: this.fb.control('')
    });


  }

  createUpdateForm() {
    // Generete FormGroup Update Legal 
    this.updateGroupForm = this.fb.group({
      active: this.fb.control(this.data.active),
      deleted: this.fb.control(this.data.deleted),
      order_id: this.fb.control(this.order_id),
      title: this.fb.control(this.data.title, Validators.required),
      sequence: this.fb.control(this.data.sequence),
      content: this.fb.control(this.data.content)
    });
    //this.updateGroupForm.controls['title'].disable()

  }

  createBaseFormPay() {
    console.log('create form payment', this.order_id);
    console.log(this.amount);
    
    // Generete FormGroup Update Legal 
    this.updatePayGroupForm = this.fb.group({
      order_id: this.fb.control(this.order_id, Validators.required),
      category_id: this.fb.control(null, Validators.required),
      term_id: this.fb.control(true, Validators.required),
      method_id: this.fb.control(false, Validators.required),
      term_reference_id: this.fb.control(false, Validators.required),
      day_reference: this.fb.control(0),
      day_reference_type_id: this.fb.control('', Validators.required),
      amount: this.fb.control(0, Validators.required),
      percentage: this.fb.control('', Validators.required)
    });

    this.updatePayGroupForm.get('percentage').valueChanges.subscribe(value => {
      this.updateAmount();
    });
  }

  createUpdateFormPay() {
    // Generete FormGroup Update Legal 
    let newAmount = (this.data.amount)/(this.data.percentage/100)
    this.updatePayGroupForm = this.fb.group({
      order_id: this.fb.control(this.order_id, Validators.required),
      category_id: this.fb.control(this.data.category.id, Validators.required),
      term_id: this.fb.control(this.data.term.id, Validators.required),
      method_id: this.fb.control(this.data.method.id, Validators.required),
      term_reference_id: this.fb.control(this.data.term_reference.id, Validators.required),
      day_reference: this.fb.control(this.data.day_reference),
      day_reference_type_id: this.fb.control(this.data.day_reference_type.id, Validators.required),
      amount: this.fb.control(newAmount, Validators.required),
      percentage: this.fb.control(this.data.percentage, Validators.required)
    });

    this.amount = newAmount
    this.category_in = this.data.category
    this.method_in = this.data.method
    this.term_in = this.data.term
    this.reference_in = this.data.term_reference
    this.day_reference_in = this.data.day_reference_type
    //this.updateGroupForm.controls['title'].disable()
    this.updatePayGroupForm.get('percentage').valueChanges.subscribe(value => {
      this.updateAmount();
    });
  }

  submitForm() {
    /* console.log(this.createForm.value);
    return */
    this.createForm.onSubmit(undefined);
  }

  subForm(form) {
    //CallApi   
    if (this.type == 'legal') {
      if (this.form == 'update') {
        this.order_orderlegal_update(form.value, this.data.id, this.data.order);
      }
      if (this.form == 'create') {
        this.createLegal(form.value, this.order_id)
      }
    } else if (this.type == 'extra') {
      if (this.form == 'update') {
        this.updateExtra(form.value, this.data.id, this.data.order);
      }
      if (this.form == 'create') {
        this.createExtra(form.value, this.order_id)
      }
    } else if (this.type == 'payments') {
      if (this.form == 'update') {
        this.updatePayment(form.value, this.data.id, this.data.order);
      }
      if (this.form == 'create') {
        this.createPayment(form.value, this.order_id)
      }
    }

  }

  createLegal(form, order) {
    this.api.createLegal(form, order).subscribe(e => {
      console.log(e);
      if (e.message && e.message.includes('OK')) {
        this.presentToast(e.message, 'success')
        this.response = 'OK';
        this.dismissModal();
      } else {
        this.response = 'KO';
      }
    })
  }

  order_orderlegal_update(form, id, order) {
    this.api.order_orderlegal_update(form, id, order).subscribe(e => {
      console.log(e);
      if (e.message && e.message.includes('OK')) {
        this.presentToast(e.message, 'success')
        this.response = 'OK';
        this.dismissModal();
      } else {
        this.response = 'KO';
      }
    })
  }


  createExtra(form, order) {
    this.api.order_orderextras_create(form, order).subscribe(e => {
      console.log(e);
      if (e.message && e.message.includes('OK')) {
        this.presentToast(e.message, 'success')
        this.response = 'OK';
        this.dismissModal();
      } else {
        this.response = 'KO';
      }
    })
  }

  updateExtra(form, id, order) {
    this.api.order_orderextra_update(form, id, order).subscribe(e => {
      console.log(e);
      if (e.message && e.message.includes('OK')) {
        this.presentToast(e.message, 'success')
        this.response = 'OK';
        this.dismissModal();
      } else {
        this.response = 'KO';
      }
    })
  }


  createPayment(form, order) {
    console.log(form);
    form['amount'] = form['amount'] * this.updateAmount()
    this.api.order_orderpayment_create(form, order).subscribe(e => {
      console.log(e);
      if (e.message && e.message.includes('OK')) {
        this.presentToast(e.message, 'success')
        this.response = 'OK';
        this.dismissModal();
      } else {
        this.response = 'KO';
      }
    })
  }

  updatePayment(form, id, order) {
    form['amount'] = form['amount'] * this.updateAmountEdit()    
    this.api.order_orderpayment_update(form, id, order).subscribe(e => {
      console.log(e);
      if (e.message && e.message.includes('OK')) {
        this.presentToast(e.message, 'success')
        this.response = 'OK';
        this.dismissModal();
      } else {
        this.response = 'KO';
      }
    })
  }

  getMetaInfoPayment() {
    forkJoin({
      categories: this.apiPay.payment_categories_list('?page=1&items=25'),
      methods: this.apiPay.payment_methods_list('?page=1&items=25'),
      types: this.apiPay.payment_types_list('?page=1&items=25'),
      terms: this.apiPay.payment_terms_list('?page=1&items=25'),
      references: this.apiPay.payment_references_list('?page=1&items=25')
    }).subscribe({
      next: (responses) => {
        this.categories = responses.categories.results;
        this.methods = responses.methods.results;
        this.types = responses.types.results;
        this.terms = responses.terms.results;
        this.references = responses.references.results;
  
        console.log(this.categories);
  
        this.showFormPayment = true;
      },
      error: (err) => {
        console.error('Error fetching meta info:', err);
        // Handle error appropriately
      }
    });
  }

  // only payments form
  onSelect(event) {
    console.log(event);
    if (event.isSelected/*  && !this.project_in */) {
      if (event.module == 'category') {
        this.onSelectCategory = event.item
        this.updatePayGroupForm.controls['category_id'].setValue(event.item.id)

      } else if (event.module == 'method') {
        this.onSelectMethod = event.item
        this.updatePayGroupForm.controls['method_id'].setValue(event.item.id)

      } else if (event.module == 'term') {
        this.onSelectTerm = event.item
        this.updatePayGroupForm.controls['term_id'].setValue(event.item.id)

      } else if (event.module == 'term reference') {
        this.onSelectReference = event.item
        this.updatePayGroupForm.controls['term_reference_id'].setValue(event.item.id)

      } else if (event.module == 'day reference type') {
        this.onSelectDayReference = event.item
        this.updatePayGroupForm.controls['day_reference_type_id'].setValue(event.item.id)

      }
    } else if (!event.isSelected /* && !this.project_in */) {
      if (event.module == 'category') {
        this.onSelectCategory = ''
        this.updatePayGroupForm.controls['category_id'].setValue(0)

      } else if (event.module == 'method') {
        this.onSelectMethod = ''
        this.updatePayGroupForm.controls['method_id'].setValue(0)

      } else if (event.module == 'term') {
        this.onSelectTerm = ''
        this.updatePayGroupForm.controls['term_id'].setValue(0)

      } else if (event.module == 'term reference') {
        this.onSelectReference = ''
        this.updatePayGroupForm.controls['term_reference_id'].setValue(0)

      } else if (event.module == 'day reference type') {
        this.onSelectDayReference = ''
        this.updatePayGroupForm.controls['day_reference_type_id'].setValue('')
      }
    }
  }
  // Metodo para usar con Infinity Scroll en ionic-selectable
  onInfiniteScroll(/* 
    event: { component: IonicSelectableComponent; text: string },
    type: string */ event) {

    console.log(event);
    console.log(this.title);

    let url
    let _type = event['type'] ? event['type'] : null;
    console.log('after', _type);

    switch (_type) {

      case 'category': {


        /* console.log('page', this.numPage_project);

        url = '?page=' + (++this.page_project) + '&items=' + this.size;

        this.project.getProjectsList_rev(url).subscribe((data) => {
          console.log('onInfiniteScroll', data);
          //event.component.endInfiniteScroll();
          //data.results = event.component.items.concat(data.results);
          this.onSearchSelector = '';
          this.numPage_project = data.num_pages;
          this.projects = data.results;
          //event.component._modalComponent._content.scrollY = true
          //event.component.items = data.results;
        });
 */
        break;
      }
      default:
        console.log('Default');

        break;

      //.... more code case
    }



  }



  searchPorts(event/*:  { component: IonicSelectableComponent; text: string }, type */) {

    console.log(event, event.text);
    //console.log(event.component._modalComponent);
    //console.log(event.component._modalComponent._content);

    //event.component._modalComponent._content.scrollToBottom(1500);
    //event.component._modalComponent._content.scrollY = false

    let url = ''

    const text = event.text.trim().toLowerCase();
    //event.component.startSearch();


    // Close any running subscription.
    if (this.portsSubscription) {
      this.portsSubscription.unsubscribe();
    }


    // se non ho text di ritorno dal comp chiamo la lista globale altrimenti chiamo filtrando per name
    if (!text) {

      // Close any running subscription.
      if (this.portsSubscription) {
        this.portsSubscription.unsubscribe();
      }


      //event.component.endSearch();
      //event.component.enableInfiniteScroll();
      switch (event['module']) {
        case 'category':
          this.apiPay.payment_categories_list('?page=1&items=25').subscribe(e => {
            this.categories = e.results


            this.onSearchSelector = ''
            //event.component.endInfiniteScroll();
          })
          break;

        case 'method':
          this.apiPay.payment_methods_list('?page=1&items=25').subscribe(e => {
            this.methods = e.results


            this.onSearchSelector = ''
            //event.component.endInfiniteScroll();
          })
          break;

        case 'term':
          this.apiPay.payment_terms_list('?page=1&items=25').subscribe(e => {
            this.terms = e.results


            this.onSearchSelector = ''
            //event.component.endInfiniteScroll();
          })
          break;

        case 'term reference':
          this.apiPay.payment_references_list('?page=1&items=25').subscribe(e => {
            this.references = e.results


            this.onSearchSelector = ''
            //event.component.endInfiniteScroll();
          })
          break;

        case 'day reference type':
          this.apiPay.payment_types_list('?page=1&items=25').subscribe(e => {
            this.types = e.results


            this.onSearchSelector = ''
            //event.component.endInfiniteScroll();
          })
          break;

        default:
          console.log('Default');

          break;
      }

      return

    }


    switch (event['module']) {
      case 'category':
        this.apiPay.payment_categories_list('?page=1&items=25&name=' + text).subscribe(e => {
          this.categories = e.results


          this.onSearchSelector = text
          //event.component.endInfiniteScroll();
        })
        break;
      case 'method':
        this.apiPay.payment_methods_list('?page=1&items=25&name=' + text).subscribe(e => {
          this.categories = e.results


          this.onSearchSelector = text
          //event.component.endInfiniteScroll();
        })
        break;

      case 'term':
        this.apiPay.payment_terms_list('?page=1&items=25&name=' + text).subscribe(e => {
          this.terms = e.results


          this.onSearchSelector = text
          //event.component.endInfiniteScroll();
        })
        break;

      case 'term reference':
        this.apiPay.payment_references_list('?page=1&items=25&name=' + text).subscribe(e => {
          this.references = e.results


          this.onSearchSelector = text
          //event.component.endInfiniteScroll();
        })
        break;

      case 'day reference type':
        this.apiPay.payment_types_list('?page=1&items=25&name=' + text).subscribe(e => {
          this.types = e.results


          this.onSearchSelector = text
          //event.component.endInfiniteScroll();
        })
        break;
      /*  case 'day_reference_type':
         this.apiPay.p('?page=1&items=25&name=' + text).subscribe(e => {
           this.methods = e.results
 
 
           this.onSearchSelector = ''
           //event.component.endInfiniteScroll();
         })
         break; */

      default:
        console.log('Default');

        break;
    }

    return

  }

  amountChanged(event) {
    console.log('event',event);
   
    
    this.updatePayGroupForm.controls['amount'].setValue(event/1000)

  }

  dismissModal(event?) {
    this.modalController.dismiss(this.response)
  }

  fabCallBack(action) {
    console.log(action);

    if (action) {
      this[action]()
    } else {
      console.log('not call in component, run callback', 'details');


    }
  }

  async presentToast(text, color) {
    const toast = await this.toastController.create({
      message: text,
      position: 'bottom',
      duration: 3000,
      cssClass: 'toast',
      color: color
    });
    return await toast.present();
  }


  changeModalSize() {
    // Seleziona tutti gli ion-modal
    const modals = document.querySelectorAll('ion-modal');
    
    // Controlla se ci sono modali aperti
    if (modals.length > 0) {
      // Prendi l'ultimo modale aperto che dovrebbe essere quello attivo
      const modalElement = modals[modals.length - 1];
  
      // Controlla se la classe 'small-icon' è già applicata
      if (modalElement.classList.contains('small-icon')) {
        // Se 'small-icon' è applicata, rimuovila per tornare alla dimensione originale
        this.renderer.removeClass(modalElement, 'small-icon');
        // Aggiungi qui la classe per la dimensione originale se la conosci
        this.iconName = 'chevron-up-circle-outline'
        this.showConfirm = true
        this.chgToolbar = ''
      } else {
        // Se 'small-icon' non è applicata, aggiungila per ridurre le dimensioni del modale
        this.renderer.addClass(modalElement, 'small-icon');
        this.iconName = 'chevron-down-circle-outline'
        this.showConfirm = false
        this.chgToolbar = 'toolbar-background'
      }
    }
  }
  showAlert = false
  updateAmount() {
    
    return  (this.updatePayGroupForm.controls['percentage'].value / 100);


  }

  updateAmountEdit() {
    
    return  (this.updatePayGroupForm.controls['percentage'].value / 100);


  }

}
