import { StoresService } from './../../services/stores/stores.service';
import { animate, query, sequence, stagger, state, style, transition, trigger } from '@angular/animations';
import { DatatableComponent, ColumnMode } from '@swimlane/ngx-datatable';
import { ActivatedRoute, Router } from '@angular/router';

import { Platform, AnimationController, IonNav, ModalController } from '@ionic/angular';
import { Component, OnInit, ElementRef, ViewChild, Input, Pipe } from '@angular/core';
import { Store } from '../models/stores.model';
import { NewStoresComponent } from '../new-stores/new-stores.component';
import { StoresUpdatePage } from '../stores-update/stores-update.page';
import { ApiService } from 'src/app/services/api/api.service';
import { UploadDesktopComponent } from 'src/app/components/media/upload-desktop/upload-desktop.component';
import { Order } from 'src/app/orders/models/order.model';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ModalProductHystoryComponent } from '../components/modal-product-history/modal-product-history.component';
import { VendorDetailsPage } from 'src/app/vendors/vendor-details/vendor-details.page';
import {DdtService} from "../../services/trasport_documents/ddt.service";
import {DdtListPage} from "../../trasport_documents/ddt-list/ddt-list.page";


const listAnimation = trigger('listAnimation', [
  transition('* <=> *', [
    query(':enter',
        [style({ opacity: 0 }), stagger('180ms', animate('300ms ease-out', style({ opacity: 1 })))],
        { optional: true }
    ),
    query(':leave',
        animate('200ms', style({ opacity: 0 })),
        { optional: true }
    )
  ])
]);

interface PageInfo {
  offset: number;
  pageSize: number;
  limit: number;
  count: number;
}

export class Page {
  // The number of elements in the page
  size: number = 0;
  // The total number of elements
  totalElements: number = 0;
  // The total number of pages
  totalPages: number = 0;
  // The current page number
  pageNumber: number = 0;


}
@Component({
  selector: 'app-store-details',
  templateUrl: './stores-details.page.html',
  styleUrls: ['./stores-details.page.scss'],
})
export class StoresDetailsPage {
  @ViewChild('myTable') table: DatatableComponent;
  @ViewChild('favIcon', { read: ElementRef }) favIcon: ElementRef;
  @ViewChild(UploadDesktopComponent, { static: true }) UploadDesktopComponent: UploadDesktopComponent;

  /* @Input() id: any = ''; */
  @Input() overlayController
  /*@Input() nav: IonNav;*/
  @Input() store: Store;
  @Input() type: any = '';

  public showProgressStatus = false;
  public favState = false;
  public favOnAnimation: Animation;
  public favOffAnimation: Animation;
  public columns_line: any = [];
  rowIsNotExpanded = true

  page_number = 1;
  page_limit = 30;

  filteredAddress = [];
  selectedSegment
  stores_lines: any = []
  ddt_lines: any = []

  total_page = 0;
  totalElements: number;
  pageNumber: number;
  cache: any = {};
  selected = [];
  timeout: any;
  subscription
  filteredData = []

  typeFilter: string;
  enableSearch = false;
  disableClickItem = false

  navMenu = [
    { action: 'DdtList', label: 'DDT LIST', icon: 'file-tray-full-outline', class: 'custom-chip', title: 'Accedi alla lista dei DDT del store corrente' },
    /*{ action: 'DdtListOut', label: 'DDT OUT', icon: 'storefront-outline' },*/
    //{ action: 'openNewProductModal', label: 'New', icon: 'receipt-outline' },
    /*{ action: 'toggleSearch', label: 'Search' },*/
  ]

 


  constructor(public platform: Platform,
              private storesApi: StoresService,
              private api: ApiService,
              private apiDdt: DdtService,
              private route: ActivatedRoute,
              private router: Router,
              //private nav: IonNav,
              private animationCtrl: AnimationController,
              private modalController: ModalController
  ) {

    //this.id = this.route.snapshot.paramMap.get('id');


  }


  ionViewDidEnter() {
    this.subscription = this.platform.backButton.subscribe((e) => {
      console.log(e);
      this.dismissModal()
    });
  }

  ionViewWillLeave() {
    this.subscription.unsubscribe();
  }



  ionViewWillEnter() {

    this.total_page = 0;
    this.page_number = 1;
    //this.myInput.value = "";

    this.columns_line = [];
    this.stores_lines = [];
    console.log('item', this.store);

    //this.ListFileComponent
    //this.dataService.getProjectById(parseInt(id, 10)

    let url = '?page=' + this.page_number + '&items=' + this.page_limit;
    this.apiDdt.mahazin_ddts_list(url, this.type).subscribe(data => {

      // console.log(data)
      // console.log(data.results)
      let test = []
      test = data.results.filter(e => e.store.name === this.store.name)
      console.log(this.ddt_lines)
      test.forEach(e => {
        console.log(e.lines)
        if (e.lines.length !== 0) {
          this.ddt_lines.push(e.lines);
        }
      });

      //this.stores_lines = this.ddt_lines
      console.log(this.stores_lines)
      this.storesApi.getStore(this.store.id)
          .subscribe(e => {
            console.log(e)
            this.stores_lines = e.products
            this.filteredData = e.products
            console.log(this.stores_lines.id)
            console.log(this.stores_lines) 
            //console.log(this.ddt_lines)
            //console.log(filteredDdt)
            // filteredDdt.forEach(ddt =>{
            //   console.log(ddt)
            //   if (!Array.isArray(this.stores_lines)) {
            //     this.stores_lines = []; // Initialize as an array if it's not already
            //   }
            //   this.stores_lines.push(...ddt.lines);
            // })
            // if the project doesn't exists, return to home page*!/
            //console.log(this.stores_lines)
            //this.store = e
            // Transforma Object che ricevo in Array
            // const products = Object.keys(e.products).map(index => {
            //   let product = e.products[index];
            //   return product;
            // });
            // this.stores_lines = products
            this.selectedSegment = ''

            console.log(this.store?.description);



          })
    })
    //this.getStoresLines(false, "")
    this.columns_line = [
      { prop: 'product_detail.id', name: 'ID', frozenLeft: true, minWidth: 50, maxWidth: 75, cellClass: 'td-id', headerClass: 'th-id' },
      { prop: 'product_detail.name', name: 'Name', minWidth: 350 },
      { prop: 'product_detail.product.category.name', name: 'Category', minWidth: 100 },
      { prop: 'product_detail.code', name: 'Code', minWidth: 90 },
      //{ prop: 'units.name', name: 'Udm', minWidth: 20 },
      /*{ prop: 'default_price', name: 'Default Price', minWidth: 50 },*/
      /*       { prop: 'realqua', name: 'Qty in magazzino', minWidth: 250 }, */
      { prop: 'quantity', name: 'Qty attuali', minWidth: 250 },
      { prop: 'Note', name: 'Note', minWidth: 250 }
    ]


  }

  /*  */

  onGetRow1Class = (row :any) => {
    // id 3 is Delete for Status Order
    if (row.status === 'WAIT') {
      return 'wait';
    } else if (row.status === 'PREP') {
      return 'prep';
    } else if (row.status === 'EXE') {
      return 'exe';
    } else if (row.status === 'END') {
      return 'end';
    }
    else
      return ''
  }




  /*   getDocuments(id_folder) {
      let url = '?page=' + this.page_number + '&items=' + this.page_limit;
      this.api.getFiles(id_folder, url).subscribe(x => {
        this.page_number++
        return x
      })
    } */




  fav(): void {
    if (this.favState) {
      // this.favOnAnimation.stop();
      this.favOffAnimation.play();
    } else {
      // this.favOffAnimation.stop();
      this.favOnAnimation.play();
    }

    this.favState = !this.favState;
  }

  onChangeSelectAcc(event) {
    console.log(event);
  }


  old_editStores() {
    this.dismissModal();
    //this.navCtrl.navigateForward(['/members/store-details/', item.id]);    
    //nav: this.nav(StoresUpdatePage, { id: this.store.id }, { animated: true, direction: 'forward' });
  }


  async openUpdateStoresModal() {
    console.log(this.store);
    const modal = await this.modalController.create({
      component: StoresUpdatePage,
      canDismiss: true,
      mode: 'md',
      componentProps: {
        title: 'Add Update Store',
        item: this.store,
        parent_type: 'root'
      }
      //presentingElement: this.routerOutlet.nativeEl,
    })


    modal.onWillDismiss().then((e) => {
      //if (e.data && e.data.status != -1) {
      /*  this.storesApi.getStoresById(this.store.id)
         .subscribe(e => {
           // if the project doesn't exists, return to home page
           if (!e) {
             //this.router.navigate(['/store-list']);
           } else {
             this.store = e;
           }
         }) */
      /* this.page_number = 1;
      this.myInput.value = "";
      this.store = [];
      this.getStoress(false, '') */
      //}
    });
    return await modal.present();
  }


  fabCallBack(action) {
    console.log(action);

    if (action) {
      this[action]()
    } else {
      console.log('not call in component, run callback', 'details');


    }
  }

  methodAction(action) {
    this.dismissModal()
    console.log(action, this.stores_lines.id)
    try {
      this[action]()

    } catch {
      console.log('not call in parent component');
    }
    //console.log(action)
  }


  removeDuplicate(types) {
    if (types && types.lenght) {
      const names = types.map(o => o.name)
      const filtered = types.filter(({ name }, index) => !names.includes(name, index + 1))
      return filtered
    }
  }


  filterAddress(address, event) {

    if (event) {
      console.log(address, event);
      this.filteredAddress = address.filter(function (type) {
        return type.name == event.detail.value;
      });
    }

  }



  setPage(pageInfo: PageInfo) {
    // Current page number is determined by last call to setPage
    // This is the page the UI is currently displaying
    // The current page is based on the UI pagesize and scroll position
    // Pagesize can change depending on browser size
    this.pageNumber = pageInfo.offset;

    // Calculate row offset in the UI using pageInfo
    // This is the scroll position in rows
    const rowOffset = pageInfo.offset * pageInfo.pageSize;

    // When calling the server, we keep page size fixed
    // This should be the max UI pagesize or larger
    // This is not necessary but helps simplify caching since the UI page size can change
    const page = new Page();
    page.size = 20;
    page.pageNumber = Math.floor(rowOffset / page.size);

    // We keep a index of server loaded pages so we don't load same data twice
    // This is based on the server page not the UI
    if (this.cache[page.pageNumber]) return;
    this.cache[page.pageNumber] = true;

    // Counter of pending API calls


    /*  this.serverResultsService.getResults(page).subscribe(pagedData => {
       // Update total count
       this.totalElements = pagedData.page.totalElements;
 
       // Create array to store data if missing
       // The array should have the correct number of with "holes" for missing data
       if (!this.rows) {
         this.rows = new Array<CorporateEmployee>(this.totalElements || 0);
       }
 
       // Calc starting row offset
       // This is the position to insert the new data
       const start = pagedData.page.pageNumber * pagedData.page.size;
 
       // Copy existing data
       const rows = [...this.rows];
 
       // Insert new rows into correct position
       rows.splice(start, pagedData.page.size, ...pagedData.data);
 
       // Set rows to our new rows for display
       this.rows = rows;
 
       // Decrement the counter of pending API calls
       this.isLoading--;
     }); */
  }

  onPage(event) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      console.log('paged!', event);
    }, 100);
  }

  onSelect({ selected }) {
    console.log('Select Event', selected, this.selected);
  }
  onActivate(event) {
    if (event.type == 'click' && !this.disableClickItem) {
      this.goTo(event.row)
    }
  }

  async DdtList() {
    //this.myCustomDir.disableBtn(this.element)
    //nav: this.nav(DdtListPage, { type: 'IN', storeName: this.store.name, storeId: this.store.id }, { animated: true, direction: 'forward' });
  }

  async newProductModal() {
    //nav: this.nav(DdtListPage, { type: 'OUT' }, { animated: true, direction: 'forward' });
  }


  goTo(item, prj?, action?) {
    this.disableClickItem = true

    console.log('goTo', item.id);
    this.storesApi.getHistoryProduct(item.id).subscribe(async e => {
      console.log(e);

      const modal = await this.modalController.create({
        component: ModalProductHystoryComponent,
        //event: event,
        backdropDismiss: true,
        //cssClass: 'popover-setting',
        cssClass: 'newOrderModalForm',
        componentProps: {
          overlayController: this.modalController,
          //nav: this.nav,
          items: e
          /* order: this.order,
          lines_discount: discount,
          event: event,
          line: row,
          type: 'line' */
        },
        //translucent: true
      });
      modal.onWillDismiss().then((e) => {
        this.disableClickItem = false
        if (e.data && e.data.status != -1) {
          //this.loadProjects(this.currentSegment);
        }
      });


      //this.ionLoaderService.dismissLoader();
      return modal.present().finally(() => {
      })

    })

  }

  setExpandStatus(expanded: any): void {
    console.log('setExpandStatus Event', expanded);
  }


  async openModalUpload() {
    const modal = await this.modalController.create({
      component: UploadDesktopComponent,
      canDismiss: true,
      componentProps: {
        //title: 'Add New Store',
        item: this.store,
        header: { ion_header: true },
        files: [],
        parent_type: 'root'
      }
      //presentingElement: this.routerOutlet.nativeEl,
    })


    modal.onWillDismiss().then((e) => {
      if (e.data && e.data.status != -1) {
        /* this.page_number = 1;
        this.myInput.value = "";
        this.store = [];
        this.getStoress(false, '') */
      }
    });
    return await modal.present();
  }


  dismissModal(event?) {
    this.modalController.dismiss(event)
  }

  ddt: any = []
  dataSearch = []
  current_page = 1
  onSearchInput(event: any) {
    let input = event.target.value;
    //console.log(input)
    // Puoi fare altre operazioni qui con il valore inserito, come filtrare i risultati o eseguire una ricerca.
    //console.log(this.stores_lines[0].product_detail.product.name)
    let dataCompany = this.filteredData;
    this.dataSearch = dataCompany.filter(e => {
      return (e.product_detail.product?.name?.toString().toLowerCase().indexOf(input) > -1 ||
      e.product_detail.code.toString().toLowerCase().indexOf(input) > -1 ||
      e.product_detail.product?.category?.name.toString().toLowerCase().indexOf(input) > -1
      )
      
    });
    this.stores_lines = this.dataSearch
    
    if(input === ''){
      this.table.offset = 0
    }
    else{
      // whenever the filter changes, always go back to the first page
      this.table.offset = this.current_page - 1;
    }
  }

}
