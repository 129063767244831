import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { BehaviorSubject, Observable, Subject, forkJoin, of, throwError, from } from 'rxjs';
import { catchError, distinctUntilChanged, map, tap, timeout } from 'rxjs/operators';
//import { MqttDataService } from '../mqtt/mqtt.service';
//import { IMqttMessage, MqttService } from 'ngx-mqtt';

import { HttpClient, HttpErrorResponse, HttpHeaders, HttpRequest } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ToastController } from '@ionic/angular';
import { NetworkService, ConnectionStatus } from '../network/network.service';
//import { Storage } from '@ionic/storage';
import { OfflineManagerService } from '../network/offline-manager.service';

//import { AnalyticsService } from '../analytics/analytics.service';
//import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';

//import environment from '../../../config.json';


const API_STORAGE_KEY = 'authService';
const TOKEN_KEY = 'access-token';
const REFRESH_TOKEN = 'refresh-token';
const PROFILE = 'auth-profile';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private url_old: string = 'http';
  private url: string = 'http';
  private token: any;
  public roleAs: string;
  public environment: any;

  public currentUserSubject = new Subject<any>();
  public authenticationState = new BehaviorSubject(null);

  public currentTokenSubject: BehaviorSubject<any>;
  public currentToken: Observable<any>;

  public currentRefreshTokenSubject = new BehaviorSubject(null);
  public currentRefreshToken: Observable<any>;


  private refreshTokenTimeout;
  private refreshTokenInterval;

  refreshTokenStr


  constructor(
    private storage: Storage,
    private plt: Platform,
    private http: HttpClient,
    public toastController: ToastController,
    private networkService: NetworkService,
    private offlineManager: OfflineManagerService,
    //private analyticsService: AnalyticsService
  ) {


    /**
     * Map url API
     */
    this.url = environment.SSL.active ? this.url + 's' : this.url;
    this.url += '://' + environment.API_URI.url + ':' + environment.API_URI.port + environment.API_URI.path
    // this.url_old = environment.SSL.active ? this.url_old + 's' : this.url_old;
    // this.url_old += '://' + environment.API_URI.url_old + ':' + environment.API_URI.port_old + environment.API_URI.path


  }


  /**
   * @description Aggiorna e tiene in memoria l'utente
   * @param data Corrisponde obj user
   */
  publishProfile(data: any) {
    console.log('publishProfile', data);
    this.currentUserSubject.next(data);
    this.currentTokenSubject.next(data.access);
    this.currentRefreshTokenSubject.next(data.refresh);
  }

  /**
   * @returns Ritorna il valore obj dell'utente
   */
  observProfile(): Subject<any> {
    return this.currentUserSubject;
  }

  /**
   * Si chiama all'avvio della piattaforma
   * Verifica se esiste il PROFILO nel db ionic, se si passa true alla variabile che verifica auth
   */
  checkToken() {
    //console.log('check');
    this.storage.get(PROFILE).then(res => {
      console.log(res);
      if (res && res.access) {
        //this.authenticationState.next(true);
        this.startRefreshTokenTimer()

        this.currentTokenSubject.next(res.access);
        return res.access;
      } else {
        //this.authenticationState.next(false)
      }
    }).catch(err => { console.log(err); }
    );
  }


  checkRemoteToken(forceRefresh) {

    console.log(this.currentTokenSubject);
    
      return this.http.post<any>(`${this.url}auth/token/check/`, { token: this.currentTokenSubject.value })
        .pipe(
          timeout(16000),
          tap(data => {
            console.log(data);

            if (data && !data.code) {
              console.log('Res auth token', data);
              this.authenticationState.next(true);
              //this.currentTokenSubject.next()
              this.startRefreshTokenTimer()
              return this.currentTokenSubject.value

            } else {
              console.log('Res error auth token', data);
              this.authenticationState.next(false);
              //this.authenticationState.next(false)
              //this.currentTokenSubject
            }
          }),
          catchError(this.handleError<any[]>('Verify token', []))
        );
    
  }

  refreshToken(refreshToken) {

    return this.http.post<any>(`${this.url}auth/token/refresh/`, { refresh: refreshToken })
      .pipe(map((data) => {
        console.log(data);

        this.setLocalData(TOKEN_KEY, data.access);
        data.refresh ? this.setLocalData(REFRESH_TOKEN, data.refresh) : null
        //this.setLocalData('user', data);


        this.currentTokenSubject.next(data.access);
        data.refresh ? this.currentRefreshTokenSubject.next(data.refresh) : null


        //this.publishProfile(data);

        this.authenticationState.next(true);

        //localStorage.setItem(PROFILE, JSON.stringify(data));
        localStorage.setItem(TOKEN_KEY, JSON.stringify(data.access));
        data.refresh ? localStorage.setItem(REFRESH_TOKEN, JSON.stringify(data.refresh)) : null;
        //this.startRefreshTokenTimer();
        this.startRefreshTokenTimer()

        return data;
      }));
  }


  // helper methods


  private async startRefreshTokenTimer() {
    // parse json object from base64 encoded jwt token
    this.stopRefreshTokenTimer()
    const accessToken = await this.getLocalData(TOKEN_KEY);
    const refreshToken = await this.getLocalData(REFRESH_TOKEN);
    console.log(accessToken);
    /* 
        console.log(accessToken, accessToken.split('.'));
    
        const expiry = (JSON.parse((accessToken.split('.')[1]))).exp;
        let res = (Math.floor((new Date).getTime() / 1000)) - (Date.now() - (1 * 1000)) >= expiry;
        console.log(Math.floor((new Date).getTime() / 1000));
    
        console.log(res);
        console.log(expiry);
    
        // set a timeout to refresh the token a minute before it expires
        const expires = new Date(expiry);
        const timeout = expires.getTime() - (Date.now() - (1 * 1000));
        console.log(expires);
        console.log(timeout);
        console.log(new Date((Math.floor((new Date).getTime() / 1000)) - (Date.now() - (1 * 1000))));
     */
    const jwtToken = JSON.parse(atob(accessToken.split('.')[1]));
    const expires = new Date(jwtToken.exp * 1000);
    //const timeout = expires.getTime() - Date.now() - (360 * 1000);  //6 min prima del token
    console.log(jwtToken);
    console.log(expires);
    //console.log(timeout);

    
    //this.refreshTokenInterval = setInterval(() => {
    const timeout = expires.getTime() - Date.now() - ((20 * 60) * 1000)  //20 min prima del token

    console.log('%c Set timeout refresh ', 'background-color:#ff9800;color:#000;border-radius:4px');
    this.refreshTokenTimeout = setTimeout(() => this.refreshToken(refreshToken).subscribe(), timeout);
    console.log('', ((timeout / 1000) / 60).toFixed(2), 'min');

    console.log('time', ((timeout / 1000) / 60).toFixed(2), 'min');

    //}, 30 * 1000);
    //this.refreshTokenTimeout = setTimeout(() => this.refreshToken(this.currentRefreshTokenSubject.value), timeout);
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
    //clearInterval(this.refreshTokenInterval);
  }

  /**
   *
   * @returns true or false
   */
  isAuthenticated() {
    return this.authenticationState.value;
  }

  /**
   *
   * @returns profileUser obj
   */
  profileUser() {
    console.log('get-profile');

    return this.storage.get(PROFILE).then(res => {
      console.log('res', res);

      if (res) {
        return res;
      }
    });
  }

  /**
   * @description POST auth get users
   * @param email
   * @param pass
   * @returns Obj user PROFILE with TOKEN
   */
  getUsers(email: string) {
    /* ${this.url} */
    return this.http.post<any>(`${this.url}get-users`,
      {
        accessToken: this.currentTokenSubject.value,
        //project: Number(id),
        email: email
      })
      .pipe(
        tap(data => {
          console.log(data);
          return data;
        }),
        catchError(this.handleError<any[]>('Get users', []))
      );
  }

  /**
   * @description POST auth get users
   * @param email
   * @param pass
   * @returns Obj user PROFILE with TOKEN
   */
  updateUser(user: any,) {
    /* ${this.url} */
    return this.http.post<any>(`${this.url}update-user`,
      {
        accessToken: this.currentTokenSubject.value,
        //project: Number(id),
        user: user,
      })
      .pipe(
        tap(data => { return data }),
        catchError(this.handleError<any[]>('Post users', []))
      );
  }

  /**
   * @description POST auth login
   * @param username
   * @param password
   * @returns Obj user PROFILE with TOKEN
   */
  login(username: string, password: string) {

    return this.http.post<any>(`${this.url}auth/token/auth/`, { username, password, ip: localStorage.getItem('ip')/* , fcm_token: this.analyticsFirebase.fcm.value  */ })
      .pipe(
        tap(async data => {

          if (data && data.access) {
            console.log(data);
            //await this.storage.set(PROFILE, data);
            this.setLocalData(PROFILE, data);
            this.setLocalData(TOKEN_KEY, data.access);
            this.setLocalData(REFRESH_TOKEN, data.refresh);
            //this.setLocalData('user', data);

            this.publishProfile(data);

            this.authenticationState.next(true);

            localStorage.setItem(PROFILE, JSON.stringify(data));
            localStorage.setItem(TOKEN_KEY, JSON.stringify(data.access));
            localStorage.setItem(REFRESH_TOKEN, JSON.stringify(data.refresh));
            setTimeout(() => {
              this.startRefreshTokenTimer();
            }, 500);


            //this.analyticsFirebase.trackScreen("LoginPage");
            //this.analyticsFirebase.setUserProperty("token", data.accessToken);
            //this.analyticsFirebase.logEvent("login", { content_type: "page_view", item_id: 1, time: new Date().toISOString() });
            //this.analyticsService.logEvent('login', { content_type: 'page_view', id: data.accessToken });


            /* let decodedJWT = JSON.parse(window.atob(data.access.split('.')[1]));

            console.log('exp: ' + decodedJWT.exp);
            console.log('id user: ' + decodedJWT.id);

            const expires = new Date(decodedJWT.exp * 1000);
            const timeout = expires.getTime() - Date.now() - (30 * 1000);
            console.log(expires, timeout); */

            //this.storage.setEncryptionKey('123456');
          } else {
            this.handleError<any[]>('Get user', []);
          }

        }),
        catchError(this.handleError<any[]>('Login user', []))
      );
  }

  /**
   * @description GET perms user
   * @param user_id
   * @returns Obj user PROFILE with TOKEN
   */
  permUser(user_id: string = '2') {

    return this.http.get<any>(`${this.url}mgmt/user/${user_id}/`)
      .pipe(
        tap(async data => {
          console.log(data);
          if (false) {

            //await this.storage.set(PROFILE, data);
            this.setLocalData(TOKEN_KEY, data.access);
            this.setLocalData(REFRESH_TOKEN, data.refresh);
            //this.setLocalData('user', data);

            this.currentTokenSubject.next(data.access);
            this.currentRefreshTokenSubject.next(data.refresh);
            //this.publishProfile(data);

            this.authenticationState.next(true);

            //localStorage.setItem(PROFILE, JSON.stringify(data));
            localStorage.setItem(TOKEN_KEY, JSON.stringify(data.access));
            localStorage.setItem(REFRESH_TOKEN, JSON.stringify(data.refresh));
            //this.startRefreshTokenTimer();


            //this.analyticsFirebase.trackScreen("LoginPage");
            //this.analyticsFirebase.setUserProperty("token", data.accessToken);
            //this.analyticsFirebase.logEvent("login", { content_type: "page_view", item_id: 1, time: new Date().toISOString() });
            //this.analyticsService.logEvent('login', { content_type: 'page_view', id: data.accessToken });


            //this.storage.setEncryptionKey('123456');
          } else {
            this.handleError<any[]>('Get user', []);
          }

        }),
        catchError(this.handleError<any[]>('Login user', []))
      );
  }

  /**
   * @description POST auth registration
   * @param email
   * @param pass
   * @param firstName
   * @param lastName
   * @returns Obj reg user PROFILE
   */
  doRegister(email: string, password: string, firstName: string, lastName: string) {
    return this.http.post<any>(`${this.url}users/register`, { email, password, firstName, lastName, ip: localStorage.getItem('ip') })
      .pipe(
        tap(data => {

          console.log({ email, password, firstName, lastName }, data);

          if (data != null) {

            console.log(email, password, firstName, lastName);

          } else {
            this.handleError<any[]>('Reg user', []);
          }

        }),
        catchError(this.handleError<any[]>('Error register user', [])));
  }

  /**
   * @description Logout utente
   * @returns elimina PROFILO nel db ionic e passa false alla variabile che verifica auth
   */
  async logout() {

    //this.analyticsService.setUserProperty('logout', localStorage.getItem(TOKEN_KEY))
    localStorage.removeItem(PROFILE)
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN);
    this.storage.clear()
    this.authenticationState.next(false);


    this.storage.length().then(e => {
      console.log('authentication service after logout', e);
      //this.analyticsFirebase.logEvent('logout', { content_type: "page_view", item_id: 1, time: new Date().toISOString() })
      // To check if delete db keys
      this.storage.forEach((v, k, i) => {
        console.log('check if not deleted', v, k, i);
      })

      window.location.reload();
    }).catch(e => {
      console.log(e);
      window.location.reload();
    })

  }

  /**
   * @description Rispristina pass utente
   * @returns elimina PROFILO nel db ionic e passa false alla variabile che verifica auth
   */
  forgot(email: string) {
    return this.http.post<any>(`${this.url}users/forgot`, { email, ip: localStorage.getItem('ip') })
      .pipe(
        tap(data => {

          console.log({ email }, data);

          if (data != null) {

            console.log(email);

          } else {
            this.handleError<any[]>('Forgot pass user', [])
          }

        }),
        catchError(this.handleError<any[]>('Error recovery pass user', [])));
  }

  registerTagUser(params) {

    const formData = new FormData();

    Object.keys(params).forEach(function (key) {
      console.log('Key : ' + key + ', Value : ' + params[key])
      formData.append(key, params[key]);
    })

    return this.http.post<any>(`${this.url_old}axess/register`, formData)
      .pipe(
        tap(async data => {

          if (data && data.access) {
            console.log(data);
            //await this.storage.set(PROFILE, data);

          } else {
            this.handleError<any[]>('Pass registerTagUser', []);
          }

        }),
        catchError(this.handleError<any[]>('Pass registerTagUser', []))
      );
  }

  accessTagUser(params) {

    const formData = new FormData();

    Object.keys(params).forEach(function (key) {
      console.log('Key : ' + key + ', Value : ' + params[key])
      formData.append(key, params[key]);
    })

    return this.http.post<any>(`${this.url_old}axess/authenticate`, formData)
      .pipe(
        tap(async data => {

          if (data && data.access) {
            console.log(data);
            //await this.storage.set(PROFILE, data);

          } else {
            this.handleError<any[]>('Pass accessTagUser', []);
          }

        }),
        catchError(this.handleError<any[]>('Pass accessTagUser', []))
      );
  }


  getRole() {
    this.getLocalData('user').then(e => {
      console.log('Role', e);
      return this.roleAs = e.role

    })

  }
  private handleError<T>(operation = 'operation', result?: T) {

    return (error: any): Observable<T> => {

      //console.error(error);
      console.log(result, error.error);

      console.log(`${operation} failed: `, error.error);
      const status = error.status; //error == 'Forbidden' ? 403 : 500

      if (operation != 'Verify token' && operation != 'Pass accessTagUser')
        this.presentToast((status ? status : '') + ': ', error.error ? error.error : error.type, operation)
      return of(error.error as T);
    };
  }

  async presentToast(status, statusText, message) {
    const toast = await this.toastController.create({
      message: status + ' ' + statusText + ': "' + message + '"',
      duration: 2000,
      mode: 'ios',
      cssClass: 'toast',
      color: status != '200' ? 'danger' : 'primary'
    });
    toast.present();
  }

  // Save result of API requests
  private setLocalData(key, data) {
    console.log('Set db:', `${API_STORAGE_KEY}-${key}`, data);
    this.storage.set(`${API_STORAGE_KEY}-${key}`, data);
  }

  // Get cached API result
  public getLocalData(key) {
    console.log('Get db:', `${API_STORAGE_KEY}-${key}`);
    return this.storage.get(`${API_STORAGE_KEY}-${key}`);
  }

}

