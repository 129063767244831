/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable object-shorthand */
/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/member-offering */
/* eslint-disable @typescript-eslint/semi */
/* eslint-disable @typescript-eslint/naming-convention */


import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Platform, ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { catchError, delay, distinctUntilChanged, map, retryWhen, scan, tap, timeout } from 'rxjs/operators';
import { AuthenticationService } from '../authentication/authentication.service';
import { NetworkService, ConnectionStatus } from '../network/network.service';
//import { Storage } from '@ionic/storage';
import { OfflineManagerService } from '../network/offline-manager.service';

import { Project } from 'src/app/projects/models/projects';
import { Docs } from 'src/app/documents/models/docs';
import { Offer } from 'src/app/orders/models/order.model';
import { environment } from 'src/environments/environment';



const API_STORAGE_KEY = 'offersService';
const TOKEN_KEY = 'access-token';

@Injectable({
  providedIn: 'root'
})
export class OffersService {

  private url: string = 'http';
  private token: any;
  private projects: Project[];
  private documents: Docs[];
  private document: Docs;
  private project_types: any[];
  //public docList = new BehaviorSubject(null);

  constructor(
    private storage: Storage,
    private plt: Platform,
    private http: HttpClient,
    public toastController: ToastController,
    private authService: AuthenticationService,
    private networkService: NetworkService,
    private offlineManager: OfflineManagerService
  ) {

    /**
     * Map url API
     */
    this.url = environment.SSL.active ? this.url + 's' : this.url
    this.url += '://' + environment.API_URI.url + ':' + environment.API_URI.port + environment.API_URI.path

    this.authService.currentToken.subscribe(x => {
      //console.log(x);
      this.token = x //= JSON.parse(localStorage.getItem(TOKEN_KEY))

    })

  }

  /**
   * 
   * ORDERS
  */



   searchOffers(forceRefresh: boolean = true, url: string, string: string): Observable<any> {

    this.logSys('searchOffer', { forceRefresh, url, string })

    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline || !forceRefresh) {
      // Return the cached data from Storage
      return from(this.getLocalData('search-offer'));
    } else {
      let body = {
        txt: string.toString()
      };

      return this.http.get<any>(`${this.url}offers/offers/${url}&code=${string.toString()}`)
        .pipe(
          timeout(12000),
          tap(data => {
            console.log(data);
            this.setLocalData('search-offer', data);
            return data
          }),
          catchError(this.handleError<any[]>('Search offer', []))
        );
    }

  }



  /**
  * 
  *
  * @returns list offers
  */
  getOffersList(params): Observable<any> {

    return this.http.get<any>(`${this.url}offers/offers/${params}`)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          this.setLocalData('list', data);
          return data
        }),
        catchError(this.handleError<any[]>('Get offers list', []))
      );

  }


  /**
 * 
 * @param id id of offer
 * @returns get offer by project_id?
 */
  getOffersByProjectId(project_id, params): Observable<any> {

    return this.http.get<any>(`${this.url}offers/offers/${params}&project_id=${project_id}`)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Get offers by project_id', []))
      );

  }

  /**
  * 
  *
  * @returns Type Offer Types
  */
  getOfferTypes(params?): Observable<any> {
    return this.http.get<any>(`${this.url}offers/types/`)///${params}
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          this.setLocalData('types', data);
          return data
        }),
        catchError(this.handleError<any[]>('Get offer types', []))
      );
  }

  /**
  * 
  *
  * @returns Type Offer Extra Types
  */
  getOfferExtraTypes(params?): Observable<any> {
    return this.http.get<any>(`${this.url}offers/extratypes/`)///${params}
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Get offer extratypes', []))
      );
  }

  /**
  * 
  *
  * @returns Type Offer Invoiceterms
  */
  getOfferInvoiceterms(params?): Observable<any> {
    return this.http.get<any>(`${this.url}offers/invoiceterms/`)///${params}
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Get offer invoiceterms', []))
      );
  }

  /**
  * 
  *
  * @returns Type Offer Paymentterms
  */
  getOfferPaymentterms(params?): Observable<any> {
    return this.http.get<any>(`${this.url}offers/paymentterms/`)///${params}
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Get offer paymentterms', []))
      );
  }


  /**
  * 
  *
  * @returns Type Offer Paymenttypes
  */
  getOfferPaymenttypes(params?): Observable<any> {
    return this.http.get<any>(`${this.url}offers/paymenttypes/`)///${params}
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Get offer paymenttypes', []))
      );
  }

  /**
  * 
  *
  * @returns Type Offer Category
  */
  getOfferCategories(params?): Observable<any> {
    return this.http.get<any>(`${this.url}offers/categories/`)//${params}
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          this.setLocalData('categories', data);
          return data
        }),
        catchError(this.handleError<any[]>('Get offer categories', []))
      );
  }



  /**
 * 
 * @param id id of offer
 * @returns get offer by id?
 */
  getOfferById(id, mode): Observable<any> {

    return this.http.get<any>(`${this.url}offers/offer/${id}/${mode}`)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Get offer by id', []))
      );

  }



  /**
 * 
 * @param id id of offer
 * @returns put offer by id?
 */
  putOfferById(form, offer): Observable<any> {

    return this.http.put<any>(`${this.url}offers/offer/${offer.id}/`, form)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Update offer by id', []))
      );

  }

  /**
 * 
 * @param id id of offer
 * @returns delete offer by id?
 */
  deleteOfferById(offer): Observable<any> {

    return this.http.delete<any>(`${this.url}offers/offer/${offer.id}`)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Delete offer by id', []))
      );

  }


  /**
   * 
   * @param offer 
   * @returns create offer by project_id
   */

  createOffer(offer) {

    return this.http.post<any>(`${this.url}offers/offers/`, offer)
      .pipe(
        tap(data => {
          console.log(data);
        }),
        catchError(this.handleError<any[]>('Create offer by project id', []))
      );
  }



  /**
   * 
   * @param offer 
   * @returns create line offer
   */

  createLineOffer(form, offer) {

    return this.http.post<any>(`${this.url}offers/offer/${offer.id}/lines/`, form)
      .pipe(
        tap(data => {
          console.log(data);
        }),
        catchError(this.handleError<any[]>('Create line offer', []))
      );
  }

  /**
   * 
   * @param offer 
   * @returns put details line offer
   */

  putDetailsLinesOffer(form, offer: Offer) {

    return this.http.put<any>(`${this.url}offers/offer/${offer.id}/line/${form.id}/`, form)
      .pipe(
        tap(data => {
          console.log(data);
        }),
        catchError(this.handleError<any[]>('put detail line offer', []))
      );
  }


  /**
   * 
   * @param offer 
   * @returns get lines offer
   */

  getLinesOffer(offer_id) {

    return this.http.get<any>(`${this.url}offers/offer/${offer_id}/lines/`)
      .pipe(
        tap(data => {
          console.log(data);
        }),
        catchError(this.handleError<any[]>('get lines offer', []))
      );
  }


  /**
   * 
   * @param offer 
   * @returns get details line offer
   */

  getDetailsLinesOffer(offer_id) {

    return this.http.get<any>(`${this.url}offers/offer/${offer_id}/line/`)
      .pipe(
        tap(data => {
          console.log(data);
        }),
        catchError(this.handleError<any[]>('get detail line offer', []))
      );
  }




  /**
   * 
   * @param offer 
   * @returns get legal note offer
   */

  getLegalOffer(offer_id: Offer) {

    return this.http.get<any>(`${this.url}offers/offer/${offer_id}/legals/`)
      .pipe(
        tap(data => {
          console.log(data);
        }),
        catchError(this.handleError<any[]>('get legals offer', []))
      );
  }



  /**
   * 
   * @param offer 
   * @returns get details legal offer
   */

  getDetailsLegalOffer(offer_id: Offer) {

    return this.http.get<any>(`${this.url}offers/offer/${offer_id}/legal/`)
      .pipe(
        tap(data => {
          console.log(data);
        }),
        catchError(this.handleError<any[]>('get detail legal offer', []))
      );
  }


  /**
   * 
   * @param offer 
   * @returns create details legal offer
   */

  createDetailsLegalOffer(form, offer: Offer) {

    return this.http.post<any>(`${this.url}offers/offer/${offer.id}/legal/`, offer)
      .pipe(
        tap(data => {
          console.log(data);
        }),
        catchError(this.handleError<any[]>('put detail legal offer', []))
      );
  }


  /**
   * 
   * @param offer 
   * @returns put details legal offer
   */

  putDetailsLegalOffer(form, offer: Offer) {

    return this.http.put<any>(`${this.url}offers/offer/${offer.id}/legal/${form.id}/`, form)
      .pipe(
        tap(data => {
          console.log(data);
        }),
        catchError(this.handleError<any[]>('put detail legal offer', []))
      );
  }


  /**
   * 
   * @param offer 
   * @returns get priorities offer
   */

  getPrioritiesOffer() {

    return this.http.get<any>(`${this.url}offers/priorities/`)
      .pipe(
        tap(data => {
          console.log(data);
          this.setLocalData('priorities', data);
        }),
        catchError(this.handleError<any[]>('get priorities offer', []))
      );
  }


  /**
   * 
   * @param offer 
   * @returns get list status offer
   */

  getOfferStatus() {

    return this.http.get<any>(`${this.url}offers/status/`)
      .pipe(
        tap(data => {
          console.log(data);
          this.setLocalData('status', data);
        }),
        catchError(this.handleError<any[]>('get status offer', []))
      );
  }


  /**
   * 
   * @param offer 
   * @returns put status offer
   */

  putOfferStatus(offer, form) {
    return this.http.put<any>(`${this.url}offers/offer/${offer.id}/status/`, form)
      .pipe(
        tap(data => {
          console.log(data);
        }),
        catchError(this.handleError<any[]>('put status offer', []))
      );
  }


  /**
   * 
   * SCONTI
  */

  /**
  * @description Visualizza le tipoligie di sconto  offer
  * @returns [{"code":"P","label":"Percentage"},{"code":"S","label":"Subtraction"},{"code":"D","label":"Last Cost"}]
  */
  getOffersDiscountType(): Observable<any> {
    console.log('get type discount');

    return this.http.get<any>(`${this.url}offers/discount/types/`)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Get types discount offer list', []))
      );

  }

  /**
  * @description Visualizza gli sconti di un ordine
  * @offer offer obj
  * @returns {"offer_id":9,"global_discounts":[{"id":1,"discount_type":"P","value":10.0,"approved":false,"version":0,"deleted":false,"created":"2022-04-25T08:53:49Z","offer":9,"vendor":1}],"lines":[{"line_id":1,"discounts":[]},{"line_id":2,"discounts":[]}]}
  */
  getOffersGlobalDiscount(offer): Observable<any> {
    return this.http.get<any>(`${this.url}offers/offer/${offer.id}/discounts/`)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Get discount list', []))
      );

  }

  /**
  * @description crea uno sconto sull'ordine (una linea)
  * @offer_id
  * @form {"discount_category":"line","line_id":2,"vendor_id":1,"discount_type":"P","value":20,"version":1}
  * @returns {"message":"OK: Global discount inserted","data":{"id":3,"discount_type":"P","value":20.0,"approved":false,"version":1,"deleted":false,"created":"2022-04-25T13:02:47.517974Z","offer":9,"vendor":1}}
  */
  craeteOffersDiscount(offer_id, form): Observable<any> {
    return this.http.post<any>(`${this.url}offers/offer/${offer_id}/discounts/`, form)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Create single line discount', []))
      );

  }

  /**
  * 
  *
  * @returns aggiorna il numbering tra il local_id e id mysql 
  */
  updateNumberingIdLocal(offer, form): Observable<any> {

    return this.http.put<any>(`${this.url}offers/offer/${offer.id}/numbering/`, form)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          //this.setLocalData('list', data);
          return data
        }),
        catchError(this.handleError<any[]>('Set line offer numbering linew offer', []))
      );

  }

  /**
  * 
  *
  * @returns aggiorna il numbering tra il local_id e id mysql 
  */
  updateAllNumberingIdLocal(offer, form): Observable<any> {

    return this.http.post<any>(`${this.url}offers/${offer.id}/numbering/`, form)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          //this.setLocalData('list', data);
          return data
        }),
        catchError(this.handleError<any[]>('Set line offer numbering linew offer', []))
      );

  }



  /**
  * 
  *
  * @returns print offers
  */
  getOffersPrint(offer): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    const requestOptions: Object = {
      headers: headers,
      responseType: 'text'
    }
    return this.http.get<any>(`${this.url}offers/offer/${offer.id}/print/`, requestOptions)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Print offers ', []))
      );

  }




  private handleError<T>(operation = 'operation', result?: T) {

    return (error: any): Observable<T> => {

      //console.error(error);
      console.log(result, error);

      console.log(`${operation} failed`, error);
      const status = error.status; //error == 'Forbidden' ? 403 : 500
      this.presentToast(status, error == 'undefined' ? 'Internal Server Error' : error, operation)
      return of(error.error as T);
    };
  }


  async presentToast(status, statusText, message) {
    const toast = await this.toastController.create({
      message: status + ' ' + statusText + ': "' + message + '"',
      duration: 2000,
      mode: 'ios',
      cssClass: 'toast',
      color: status != '200' ? 'danger' : 'primary'
    });
    toast.present();
  }

  logSys(src, status, opt?) {
    const debugStyle1 = 'background: linear-gradient(135deg,#471ee9,#3a49b7); boffer: 1px solid #9a9a9a; color: #ffffff; boffer-bottom-left-radius: 2px; boffer-top-left-radius: 2px; padding: 2px 0 2px 4px;';
    const debugStyle2 = 'background: #252b3e; boffer: 1px solid #9a9a9a; boffer-top-right-radius: 2px; boffer-bottom-right-radius: 2px; margin-left: -2px; padding: 2px 4px; color: white;';

    //console.log('ForceRefresh API ', status, this.networkService.getCurrentNetworkStatus(), this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline);
    console.log(`%cApiService %c%s`, debugStyle1, debugStyle2, ' ' + src, status);
  }




  // Save result of API requests
  private setLocalData(key, data) {
    this.storage.set(`${API_STORAGE_KEY}-${key}`, data);
  }

  // Get cached API result
  private getLocalData(key) {
    return this.storage.get(`${API_STORAGE_KEY}-${key}`);
  }


  /**
   * 
   * @param date
   * @param offer_id 
   * @returns update date expected stop 
   */
  updateDateExpected(date, offer_id): Observable<any> {
    let form = {
      expected_date_stop: date
    }
    return this.http.put<any>(`${this.url}offers/offer/${offer_id}/`, form)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Update offer by id', []))
      );

  }
  /**
   * Create New Legal 
   * @param offer_id 
   * @param form 
   * @returns 
   */



  createLegal(form, offer_id): Observable<any> {
    console.log(form, offer_id);
    //return;

    return this.http.post<any>(`${this.url}offers/offer/${offer_id}/legals/`, form)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Create single line discount', []))
      );

  }


  /**
 * 
 * @param date
 * @param offer_id 
 * @returns update date expected stop 
 */
  order_orderlegal_update(form, line_id, offer_id): Observable<any> {
    return this.http.put<any>(`${this.url}offers/offer/${offer_id}/legal/${line_id}/`, form)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Update offer by id', []))
      );

  }

}