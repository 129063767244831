
import { AfterViewInit, Component, Input, OnChanges, SimpleChanges, ViewChild, ViewChildren } from "@angular/core";
import { IonImg } from "@ionic/angular";

@Component({
  selector: 'app-preloader-img',
  templateUrl: './preloader-img.component.html',
  styleUrls: ['./preloader-img.component.scss']
})
export class PreloaderImgComponent implements AfterViewInit, OnChanges {
  viewImage = false;
  dark: any;
  errorLoadImg: any = false;
  addClass: any = '';

  @Input() isIconSVG: boolean;
  @Input() url: string;
  @Input() class: string;
  @ViewChild('image', { static: true }) imageRef: IonImg;


  constructor(

  ) {
    this.isIconSVG = false;
    this.viewImage = false;
  }

  ngAfterViewInit() {
    //setTimeout(() => {
    //console.log(this.url, this.class);
    this.imageRef.src = this.url;
    this.viewImage = false;
    

    //}, 150)
  }

  ngOnChanges(changes: SimpleChanges) {
    //console.log(changes);
    if (changes['class'] ? changes['class'].firstChange : false) {
      //this.imageRef.src = changes['url'].currentValue;
      this.addClass = changes['class'].currentValue;
    }
  }

  loadImage() {
    //console.log(this.imageRef.src);
    this.addClass = this.class;
    this.viewImage = true;
  }

  errorLoad() {
    setTimeout(() => {
      //this.imageRef.src = './assets/img/unnamed.gif'
    }, 500);

  }
}
