import { ActivatedRoute, Router } from '@angular/router';
import { VendorsService } from 'src/app/services/vendors/vendors.service';
import { Platform, AnimationController, IonNav, ModalController, IonRouterOutlet } from '@ionic/angular';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Vendor } from '../models/vendor.model';
import { NewVendorComponent } from '../new-vendor/new-vendor.component';
import { VendorUpdatePage } from '../vendor-update/vendor-update.page';
import { ApiService } from 'src/app/services/api/api.service';
import { UploadDesktopComponent } from 'src/app/components/media/upload-desktop/upload-desktop.component';
import { TitleAppService } from 'src/app/services/utils/title-app/title-app.service';

@Component({
  selector: 'app-vendor-details',
  templateUrl: './vendor-details.page.html',
  styleUrls: ['./vendor-details.page.scss'],
})
export class VendorDetailsPage {
  @ViewChild('favIcon', { read: ElementRef }) favIcon: ElementRef;
  @ViewChild(UploadDesktopComponent, { static: true }) UploadDesktopComponent: UploadDesktopComponent;

  id: any = '';
  modal: any = '';
  public showProgressStatus = false;
  public vendor: Vendor;
  public favState = false;
  public favOnAnimation: Animation;
  public favOffAnimation: Animation;

  page_number = 1;
  page_limit = 30;

  filteredAddress = [];
  selectedSegment

  response

  constructor(public platform: Platform,
    private vendorApi: VendorsService,
    private api: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private animationCtrl: AnimationController,
    //private nav: IonNav,
    private modalController: ModalController,
    private routerOutlet: IonRouterOutlet,
    private titleAppService: TitleAppService
  ) {

    this.id = this.route.snapshot.paramMap.get('id');
    this.modal = this.route.snapshot.paramMap.get('modal');
  }


  ionViewWillEnter() {
    console.log('ionViewWillEnter', this.id);

    //this.ListFileComponent
    //this.dataService.getProjectById(parseInt(id, 10))
    this.vendorApi.getVendorById(parseInt(this.id, 10))
      .subscribe(e => {
        // if the project doesn't exists, return to home page
        if (!e) {
          //this.router.navigate(['/vendor-list']);
        } else {
          this.vendor = e;
          this.titleAppService.title.next(this.vendor.name)

          console.log(this.vendor)
          console.log(this.vendor?.address?.name)

          //this.selectedSegment = this.vendor?.address[0].name
          if(this.vendor.address != null){
            this.filteredAddress.push(this.vendor?.address)
          }
          console.log(this.filteredAddress.length)
          // if(this.selectedSegment === undefined)  
          //   this.selectedSegment = this.vendor?.address?.name

          // console.log(this.selectedSegment)

          if (this.UploadDesktopComponent && e.folders.length && e.folders[0].folder.id != undefined) {
            this.UploadDesktopComponent.passItem(e);
          } else {
            //this.UploadDesktopComponent.passItem('');
          }
          console.log('this.vendor.documents', this.vendor.documents);
        }
      });

  }


  getDocuments(id_folder) {
    let url = '?page=' + this.page_number + '&items=' + this.page_limit;
    this.api.getFiles(id_folder, url).subscribe(x => {
      this.page_number++
      return x
    })
  }


  ngAfterViewInit() {
    console.log(this.favIcon);
    
  }


  fav(): void {
    if (this.favState) {
      // this.favOnAnimation.stop();
      this.favOffAnimation.play();
    } else {
      // this.favOffAnimation.stop();
      this.favOnAnimation.play();
    }

    this.favState = !this.favState;
  }

  onChangeSelectAcc(event) {
    console.log(event);
  }


  editVendor() {
    //this.navCtrl.navigateForward(['/members/vendor-details/', item.id]);    
    //nav: this.nav(VendorUpdatePage, { id: this.id }, { animated: true, direction: 'forward' });
  }


  async openNewVendorModal() {
    const modal = await this.modalController.create({
      component: NewVendorComponent,
      canDismiss: true,
      mode: 'md',
      componentProps: {
        title: 'New Vendor',
        parent_type: 'root'
      },
      //presentingElement: this.routerOutlet.nativeEl,
    })


    modal.onWillDismiss().then((e) => {
      if (e.data && e.data.status != -1) {
        /* this.page_number = 1;
        this.myInput.value = "";
        this.vendors = [];
        this.getVendors(false, '') */
      }
    });
    return await modal.present();
  }


  async openEditVendorModal() {
    const modal = await this.modalController.create({
      component: VendorUpdatePage,
      canDismiss: true,
      mode: 'md',
      cssClass: 'newOrderModalForm',
      componentProps: {
        id: this.id
      },
      //presentingElement: this.routerOutlet.nativeEl,
    })


    modal.onWillDismiss().then((e) => {
      if (e.data && e.data.status != -1) {
        /* this.page_number = 1;
        this.myInput.value = "";
        this.vendors = [];
        this.getVendors(false, '') */
      }
    });
    return await modal.present();
  }

  dismissModal() {
    this.modalController.dismiss(this.response);
  }

  fabCallBack(action) {
    console.log(action);

    if (action) {
      this[action]()
    } else {
      console.log('not call in component, run callback', 'details');


    }
  }


  // removeDuplicate(address) {
  //   console.log('addresses', address);

  //    if (address) {
  //     const names = address.map(o => o.name)
  //     console.log('names', names);

  //      const filtered = address.filter(({ name }, index) => !names.includes(name, index + 1))
  //     console.log(filtered);

  //     return filtered
  //   }
  //   return []
  // }


  // filterAddress(address, event) {
  //   console.log('filterAddress', address, event);

  //   if (event) {
  //     console.log(address, event);
  //     this.filteredAddress = address.filter(function (type) {
  //       return type.name == event.detail.value;
  //     });
  //   }

  // }


}
