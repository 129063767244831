import { ActivatedRoute, Router } from '@angular/router';

import { Platform, AnimationController, IonNav, ModalController, ToastController, PopoverController, AlertController, IonInput, ActionSheetController } from '@ionic/angular';
import { Component, OnInit, ElementRef, ViewChild, Input, TemplateRef } from '@angular/core';
import { Report } from '../models/report.model';
import { NewReportComponent } from '../new-report/new-report.component';
import { ApiService } from 'src/app/services/api/api.service';
import { UploadDesktopComponent } from 'src/app/components/media/upload-desktop/upload-desktop.component';
import { ReportsService } from 'src/app/services/reports/reports.service';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { CurrencySymbolPipe } from 'src/app/pipes/currency/currency-symbol.pipe';
import { InvoicesService } from 'src/app/services/invoices/invoices.service';
import { InvoicesListPage } from 'src/app/invoices/invoices-list/invoices-list.page';
import { retryWhen } from 'rxjs/operators';
import { ThemeService } from 'src/app/services/theme/theme.service';


@Component({
  selector: 'app-reports-details',
  templateUrl: './reports-details.page.html',
  styleUrls: ['./reports-details.page.scss'],
})
export class ReportDetailsPage {
  @ViewChild('favIcon', { read: ElementRef }) favIcon: ElementRef;
  @ViewChild(UploadDesktopComponent, { static: true }) UploadDesktopComponent: UploadDesktopComponent;
  @ViewChild('myTable') table: DatatableComponent;
  @ViewChild(CurrencySymbolPipe, { static: true }) CurrencySymbolPipe: CurrencySymbolPipe;

  @ViewChild('totalSummaryTemplate') totalSummaryTemplate: TemplateRef<any>;
  @ViewChild('unitSummaryTemplate') unitSummaryTemplate: TemplateRef<any>;
  @ViewChild('costSummaryTemplate') costSummaryTemplate: TemplateRef<any>;
  @ViewChild('advanceTemplate') advanceTemplate: TemplateRef<any>;

  @ViewChild('costDiscountTemplate') costDiscountTemplate: TemplateRef<any>;
  @ViewChild('costUnityTemplate') costUnityTemplate: TemplateRef<any>;
  @ViewChild('costTemplate') costTemplate: TemplateRef<any>;
  @ViewChild('invoicedTemplate') invoicedTemplate: TemplateRef<any>;
  @ViewChild('cellTax') cellTax: TemplateRef<any>;

  @ViewChild('inputEl', { static: false }) inputEl: IonInput;
  @ViewChild('inputTax', { static: false }) inputTax: IonInput;

  @Input() id: any = '';
  @Input() nav: IonNav;

  public showProgressStatus = false;
  public report: any;
  public favState = false;
  public favOnAnimation: Animation;
  public favOffAnimation: Animation;
  hidden = false;

  page_number = 1;
  page_limit = 30;

  filteredAddress = [];
  selectedSegment

  listStatus: any = [];
  listTypesInvoice: any = [];

  availableChangeStatusReport: any = [];

  editing_local_id_cell = {};

  report_lines: any = [];
  report_legals: any = [];
  columns_line = [];
  columns_legal = [];

  selectedLinesRows = [];
  selectedLegalsRows = [];

  selectedFilterLinesRows = [];
  selectedFilterLegalsRows = [];

  lineAdvanceToInvoice = [];
  prepareLinesReportToInvoice = [];

  ColumnMode = ColumnMode;
  SelectionType = SelectionType;



  total_report = 0
  diff_discount_report = 0

  response;
  subscription


  vamps = [
    { name: "Bad Vamp" },
    { name: "Petrovitch the Slain" },
    { name: "Bob of the Everglades" },
    { name: "The Optimistic Reaper" }
  ];

  filterTerm: string;
  enableSearch = false;
  listTaxes


  constructor(
    public platform: Platform,
    private reportApi: ReportsService,
    private invoiceApi: InvoicesService,
    private api: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private animationCtrl: AnimationController,
    ////private nav: IonNav,
    private modalController: ModalController,
    private popoverController: PopoverController,
    private alertController: AlertController,
    private toastController: ToastController,
    private actionSheetCtrl: ActionSheetController,
    private currencyPipe: CurrencySymbolPipe,
  ) {
    this.id = this.route.snapshot.paramMap.get('id');

    console.log(this.costTemplate);
    console.log(this.totalSummaryTemplate);



  }

 

  ionViewWillEnter() {
    console.log('ionViewWillEnter', this.id, this.nav);
    this.selectedFilterLinesRows = []
   
    this.hidden = false;
    //this.ListFileComponent
    //this.dataService.getProjectById(parseInt(id, 10))
   
  }

  ionViewDidEnter() {
    this.subscription = this.platform.backButton.subscribe((e) => {
      console.log(e);
      this.dismissModal()
    });

    // !! Await for load template Reference and cretea columns table



    this.response = '';
    this.selectedLegalsRows = [];
    this.selectedLinesRows = [];

   
  }

  ionViewWillLeave() {
    this.subscription.unsubscribe();
  }

  getMetaReport() {
 
    
  }


  fav(): void {
    if (this.favState) {
      // this.favOnAnimation.stop();
      this.favOffAnimation.play();
    } else {
      // this.favOffAnimation.stop();
      this.favOnAnimation.play();
    }

    this.favState = !this.favState;
  }



  fabCallBack(action) {
    console.log(action);

    if (action) {
      this[action]()
    } else {
      console.log('not call in component, run callback', 'details');


    }
  }


  removeDuplicate(types) {
    if (types && types.lenght) {
      
      
    }
  }


  filterAddress(address, event) {

    if (event) {
     
    }

  }



  editLine(type_report_meta, type_report_form, event) {

    /* if (event.type == 'mouseenter' || event.type == 'mouseleave')
      return */

    console.log(event);
    ////nav: this.nav(ReportDetailsPage, { id: event.detail }, { animated: true })

  }

  

  

  dismissModal(event?) {
    this.hidden = true
    this.modalController.dismiss(this.response)
  }

  
  async presentToast(text, color) {
    const toast = await this.toastController.create({
      message: text,
      position: 'bottom',
      duration: 3000,
      cssClass: 'toast',
      color: color
    });
    return await toast.present();
  }

  onSelect(selectedRows, type) {
    console.log('Select Event', selectedRows);
    if (!selectedRows.selected)
      return

    if (type == 'line') {
      this.selectedLinesRows.splice(0, this.selectedLinesRows.length);
      this.selectedLinesRows.push(...selectedRows.selected);
      this.selectedFilterLinesRows = [...selectedRows.selected]
    } else if (type == 'legal') {
      /*  this.selectedLegalsRows.splice(0, this.selectedLegalsRows.length);
       this.selectedLegalsRows.push(...selectedRows.selected);
       this.selectedFilterLegalsRows = this.getAllstatusadvanceLine(this.selectedLegalsRows); */
    }

    console.log(this.selectedFilterLinesRows);


  }

  getAllstatusadvanceLine(lines) {
    let array = [];
    lines.map(e => {
      this.invoiceApi.getStatusAdvance(e).subscribe(e => {
        console.log(e);
        array.push(e)
      })
    })
    return array;
  }


  addLineToInvoice(type, event) {


    const newInvoice = {
      type_id: '',
      vendor_id: this.report.vendor.id,
      customer_id: this.report.customer.id,
      lines: this.lineAdvanceToInvoice
    }

    console.log(event, newInvoice);


  }

  




  onActivate(event) {
    console.log('Activate Event', event);
  }

  /*   add() {
      this.selected.push(this.rows[1], this.rows[3]);
    }
   
    update() {
      this.selected = [this.rows[1], this.rows[3]];
    } */


  

  




}
