import { ActivatedRoute, Router } from '@angular/router';

import { Platform, AnimationController, IonNav, ModalController, ToastController, PopoverController } from '@ionic/angular';
import { Component, OnInit, ElementRef, ViewChild, Input, TemplateRef } from '@angular/core';
import { Invoice } from '../models/invoice.model';

import { ApiService } from 'src/app/services/api/api.service';
import { UploadDesktopComponent } from 'src/app/components/media/upload-desktop/upload-desktop.component';

import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { ModalInvoiceMetaComponent } from '../components/modal-invoice-meta/modal-invoice-meta.component';
import { CurrencySymbolPipe } from 'src/app/pipes/currency/currency-symbol.pipe';
import { InvoiceUpdatePage } from '../invoice-update/invoice-update.page';
import { NewInvoiceComponent } from '../new-invoice/new-invoice.component';
import { InvoicesService } from 'src/app/services/invoices/invoices.service';
import { OrdersService } from 'src/app/services/orders/orders.service';
import { CustomersService } from 'src/app/services/customers/customers.service';
import { VendorsService } from 'src/app/services/vendors/vendors.service';
import { PaymentInvoiceLineComponent } from '../components/payment-invoice-line/payment-invoice-line.component';
import { DatePipe } from '@angular/common';



class DateOnlyPipe extends DatePipe {
   public override transform(value): any {
    return super.transform(value, 'dd-MM-y');
  }
}

@Component({
  selector: 'app-invoice-details',
  templateUrl: './invoice-details.page.html',
  styleUrls: ['./invoice-details.page.scss'],
})
export class InvoiceDetailsPage {
  @ViewChild('favIcon', { read: ElementRef }) favIcon: ElementRef;
  @ViewChild(UploadDesktopComponent, { static: true }) UploadDesktopComponent: UploadDesktopComponent;
  @ViewChild('myTable') table: DatatableComponent;
  @ViewChild(CurrencySymbolPipe, { static: true }) CurrencySymbolPipe: CurrencySymbolPipe;

  @ViewChild('totalSummaryTemplate') totalSummaryTemplate: TemplateRef<any>;
  @ViewChild('unitSummaryTemplate') unitSummaryTemplate: TemplateRef<any>;
  @ViewChild('costSummaryTemplate') costSummaryTemplate: TemplateRef<any>;

  @ViewChild('costDiscountTemplate') costDiscountTemplate: TemplateRef<any>;
  @ViewChild('costUnityTemplate') costUnityTemplate: TemplateRef<any>;
  @ViewChild('costTemplate') costTemplate: TemplateRef<any>;
  @ViewChild('cellTaxes') cellTaxes: TemplateRef<any>;

  @Input() id: any = '';
  public showProgressStatus = false;
  public invoice: Invoice;
  public favState = false;
  public favOnAnimation: Animation;
  public favOffAnimation: Animation;
  hidden = false;

  page_number = 1;
  page_limit = 30;

  filteredAddress = [];
  selectedSegment

  listtypeadvance: any = [];

  availableChangeStatusOrder: any = [];

  vendors: any = [];
  customers: any = [];

  columns_line = [];
  invoice_lines = [];
  selectedLinesRows = [];


  ColumnMode = ColumnMode;
  SelectionType = SelectionType;


  total_order = 0
  diff_discount_order = 0

  response = '';
  subscription

  taxes = []

  constructor(
    public platform: Platform,
    private vendorApi: VendorsService,
    private customerApi: CustomersService,
    private invoiceApi: InvoicesService,
    private api: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private animationCtrl: AnimationController,
    //private nav: IonNav,
    private modalController: ModalController,
    private popoverController: PopoverController,
    private toastController: ToastController,
    private currencyPipe: CurrencySymbolPipe
  ) {
    this.id = this.route.snapshot.paramMap.get('id');

    console.log(this.costTemplate);
    console.log(this.totalSummaryTemplate);


  }

  createColumns() {
    this.columns_line = [
      { prop: 'id', name: 'ID', sortable: true, resizable: true, frozenLeft: true, width: 105, minWidth: 105, maxWidth: 105, cellClass: 'td-id', headerClass: 'th-id', summaryFunc: () => null },
      //{ prop: 'invoice', name: 'Invoice id', maxWidth: 100, summaryFunc: () => null },
      {
        prop: 'advance_type', name: "Advance", sortable: true, resizable: true, minWidth: 85, width: 85, maxWidth: 85, frozenLeft: false, summaryFunc: () => null,
        cellClass: 'td-advance_type', headerClass: 'th-advance_type',
      },

      //{ prop: 'udm', sortable: true, resizable: true, width: 50, minWidth: 50, maxWidth: 50, summaryFunc: () => null },
      {
        prop: 'value', sortable: true, resizable: true, width: 100, maxWidth: 100, minWidth: 100, summaryFunc: () => null,
        cellClass: 'td-value', headerClass: 'th-value',
      },

      { prop: 'orderline', sortable: true, resizable: true, width: 380, maxWidth: 380, minWidth: 380, summaryFunc: () => null },
      {
        prop: 'tax.name', name: 'Tax', sortable: true, resizable: true, width: 150, maxWidth: 150, minWidth: 150, summaryFunc: () => null,
        cellTemplate: this.cellTaxes,
      },
      //{ prop: 'creator', sortable: true, resizable: true, width: 80, maxWidth: 80, minWidth: 80, summaryFunc: () => null },

      { prop: 'created', pipe: new DateOnlyPipe('en-Us'), sortable: true, resizable: true, width: 100, maxWidth: 170, summaryFunc: () => null },

      //{ prop: 'deleted', sortable: true, resizable: true, width: 75, maxWidth: 75, minWidth: 75, summaryFunc: () => null },

      //{ prop: 'empty', sortable: false, resizable: false, width: 145, maxWidth: 145, minWidth: 145, summaryFunc: () => null },

      /*  {
         prop: 'cost', name: 'Cost Unit',
         sortable: true,
         minWidth: 160, width: 160, maxWidth: 160,
         summaryFunc: () => null,
         cellTemplate: this.costUnityTemplate,
         //summaryTemplate: this.costSummaryTemplate,
         cellClass: 'td-cost_unit', headerClass: 'th-cost_unit',
       } */
    ]

  }

  ionViewDidEnter() {
    this.subscription = this.platform.backButton.subscribe((e) => {
      console.log(e);
      this.dismissModal()
    });

    // !! Await for load template Reference and cretea columns table
    this.createColumns();



  }

  ionViewWillLeave() {
    this.subscription.unsubscribe();

  }

  getMetaOrder(line_id?) {
    let url = '?page=1&items=25';


    this.vendorApi.getVendorsList(url)
      .subscribe(e => {
        console.log(e);

        // if the project doesn't exists, return to home page
        if (!e) {
          //this.router.navigate(['/order-list']);
        } else {
          this.vendors = e.results

        }
      });

    this.customerApi.getCustomersList(url)
      .subscribe(e => {
        console.log(e);

        // if the project doesn't exists, return to home page
        if (!e) {
          //this.router.navigate(['/order-list']);
        } else {
          this.customers = e.results

        }
      });


  }

  ionViewWillEnter() {
    console.log('ionViewWillEnter', this.id);

    this.hidden = false;
    //this.ListFileComponent
    //this.dataService.getProjectById(parseInt(id, 10))
    this.invoiceApi.getInvoiceById(parseInt(this.id, 10))
      .subscribe(e => {
        // if the project doesn't exists, return to home page
        if (!e) {
          //this.router.navigate(['/invoice-list']);
        } else {

          this.invoice = e;
          this.invoice_lines = this.invoice.invoice_lines


          //this.caclulateSumm();
          //this.caclulateSummDiscount();
          this.getMetaOrder();
          //this.getMetaOrder(this.invoice.invoice_lines[0].id);


          /* if (this.UploadDesktopComponent && e.folders.length && e.folders[0].folder.id != undefined) {
            this.UploadDesktopComponent.passItem(e);
          } else {
            
          } */


        }
      });
    this.invoiceApi.getLinesTax().subscribe(e => {
      console.log(e);

      this.taxes = e
    })

  }

  postTaxt(value) {
    console.log(event, this.id);

  }

  fav(): void {
    if (this.favState) {
      // this.favOnAnimation.stop();
      this.favOffAnimation.play();
    } else {
      // this.favOffAnimation.stop();
      this.favOnAnimation.play();
    }

    this.favState = !this.favState;
  }

  onChangeSelectAcc(event) {
    console.log(event);
  }



  editOrder() {
    //this.navCtrl.navigateForward(['/members/invoice-details/', item.id]);    
    //nav: this.nav(InvoiceDetailsPage, { id: this.id }, { animated: true, direction: 'forward' });
  }


  async openNewOrderModal() {
    const modal = await this.modalController.create({
      component: NewInvoiceComponent,
      canDismiss: true,
      componentProps: {
        title: 'Add New Invoice',
        parent_type: 'root'
      }
      //presentingElement: this.routerOutlet.nativeEl,
    })


    modal.onWillDismiss().then((e) => {
      if (e.data && e.data.status != -1) {
        /* this.page_number = 1;
        this.myInput.value = "";
        this.invoices = [];
        this.getOrders(false, '') */
      }
    });
    return await modal.present();
  }


  fabCallBack(action) {
    console.log(action);

    if (action) {
      this[action]()
    } else {
      console.log('not call in component, run callback', 'details');


    }
  }


  filterAddress(address, event) {

    if (event) {
      console.log(address, event);
      this.filteredAddress = address.filter(function (type) {
        return type.name == event.detail.value;
      });
    }

  }



  editLine(type_order_meta, type_order_form, event) {

    /* if (event.type == 'mouseenter' || event.type == 'mouseleave')
      return */

    console.log(event);
    ////nav: this.nav(InvoiceDetailsPage, { id: event.detail }, { animated: true })
    this.modalInvoiceMeta(type_order_meta, type_order_form, event/* .row */)
  }

  async modalInvoiceEdit() {
    const modal = await this.modalController.create({
      component: InvoiceUpdatePage,
      canDismiss: true,
      mode: 'md',
      initialBreakpoint: 0.25,
      breakpoints: [0, 0.2, 0.5, 0.75],
      componentProps: {
        //title: 'Add New Invoice',
        id: this.id,
        //name: this.project.name,

      }
    })


    modal.onWillDismiss().then((e) => {
      console.log(e);

      if (e.data && e.data.res.includes('OK')) {
        this.invoice = e.data.data
      }
    });
    return await modal.present();
  }


  /**
   * 
   * @param type_order_meta Lines or Legals
   * @param row 
   * @returns 
   */

  async modalInvoiceMeta(type_order_meta, type_order_form, row) {
    const modal = await this.modalController.create({
      component: ModalInvoiceMetaComponent,
      canDismiss: true,
      mode: 'md',
      initialBreakpoint: 0.25,
      breakpoints: [0, 0.2, 0.5, 0.75],
      componentProps: {
        //title: 'Add New Invoice',
        type_form: type_order_form,
        type_meta: type_order_meta, // if event.value
        invoice: this.invoice,
        data: row
        //name: this.project.name,

      }
      //presentingElement: this.routerOutlet.nativeEl,
    })


    modal.onWillDismiss().then((e) => {
      console.log(e);
      if (e.data && e.data.message == 'OK') {
        this.ionViewWillEnter();
        this.response = 'OK';
      }
    });
    return await modal.present();
  }


  dismissModal(event?) {
    this.hidden = true
    this.modalController.dismiss({ message: this.response })
  }

  emptySumm() {
    return null;
  }



  clone(string, status) {
    this.showProgressStatus = true;
    const updatedOrderStatus: any = { status_id: status.id };
    console.log(updatedOrderStatus, string);
    setTimeout(() => {
      this.showProgressStatus = false;
    }, 2000);
  }

  updateStatus(string, status) {
    this.showProgressStatus = true;
    const updatedOrderStatus: any = { status_id: status.id };
    console.log(updatedOrderStatus, string);


    /*const projectUpdated = await */
    /* this.invoiceApi.(this.invoice, updatedOrderStatus).subscribe(e => {
      console.log(e);
      if (e.message && e.message.includes('OK')) {
        this.presentToast(e.message, 'success')
  
        this.invoice.status = status;
        this.ionViewWillEnter();
        this.response = 'OK';
  
      } else {
  
        this.presentToast(e.message, 'danger')
  
      }
  
      this.showProgressStatus = false;
  
    }); */
    /* if (projectUpdated != null) {
      this.router.navigate(['/projects-list']);
    } */

    setTimeout(() => {
      this.showProgressStatus = false;

    }, 7000);
  }


  async presentToast(text, color) {
    const toast = await this.toastController.create({
      message: text,
      position: 'bottom',
      duration: 3000,
      cssClass: 'toast',
      color: color
    });
    return await toast.present();
  }

  onSelect(selectedLegalsRows) {
    console.log('Select Event', selectedLegalsRows);
    if (!selectedLegalsRows.selected)
      return

  }

  onActivate(event) {
    console.log('Activate Event', event);
  }

  /*   add() {
      this.selected.push(this.rows[1], this.rows[3]);
    }
   
    update() {
      this.selected = [this.rows[1], this.rows[3]];
    } */



  displayCheck(row) {
    return row.active !== false;
  }


  async openPopoverDiscounts(discount, event, row) {
    console.log(this.invoice.discounts);
    console.log(discount);
    console.log(event);
    console.log(row);

    /* const popover = await this.popoverController.create({
      component: DiscountLineComponent,
      //event: event,
      backdropDismiss: true,
      cssClass: 'popover-setting',
      componentProps: {
        invoice: this.invoice,
        discount: discount,
        event: event,
        line: row,
        type: 'line'
      },
      translucent: true
    });
  
    popover.onDidDismiss().then((e) => {
      console.log(e);
      if (!e.data || e.data.res != 'OK' || e.role == 'backdrop') {
        console.log('no modify');
        return
      } else {
  
        console.log(e.data.data);
        this.ionViewWillEnter()
  
  
      }
    });
  
    return await popover.present(); */
    /** Sync event from popover component */

  }




  async openPopoverDiscountsToolbar(event) {
    console.log(this.invoice.discounts);
    console.log(event);

    /*  const popover = await this.popoverController.create({
       component: DiscountLineComponent,
       //event: event,
       backdropDismiss: true,
       cssClass: 'popover-setting',
       componentProps: {
         invoice: this.invoice,
         event: event,
         type: 'global'
       },
       translucent: true
     });
   
     popover.onDidDismiss().then((e) => {
       console.log(e);
       if (!e.data || e.data.res != 'OK' || e.role == 'backdrop') {
         console.log('no modify');
         return
       } else {
   
         console.log(e.data.data);
         this.ionViewWillEnter()
   
   
       }
     });
   
     return await popover.present(); */
    /** Sync event from popover component */

  }



  async openPopoverAdvanceLine(row, event) {
    console.log(row, event);

    const popover = await this.popoverController.create({
      component: PaymentInvoiceLineComponent,
      //event: event,
      backdropDismiss: true,
      cssClass: 'popover-setting',
      componentProps: {
        event: event,
        line: row,
        type: 'payment'
      },
      translucent: true
    });

    popover.onDidDismiss().then((e) => {
      console.log(e);
      if (!e.data) {
        console.log('no modify');
        return
      } else {

        console.log(e.data);



      }
    });

    return await popover.present();
    /** Sync event from popover component */
  }



}
