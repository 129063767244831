import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, Input, OnInit, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { IonContent, IonNav, ModalController, PopoverController, ToastController } from '@ionic/angular';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { Subject } from 'rxjs';
import { WizardComponent } from 'src/app/components/clarity/wizard/wizard.component';
import { VendorDetailsPage } from 'src/app/vendors/vendor-details/vendor-details.page';
import { DdtTimelineComponent } from '../timeline/timeline.component';

class DateOnlyPipe extends DatePipe {
  public override transform(value): any {
    return super.transform(value, 'dd-MM-y');
  }
}

@Component({
  selector: 'app-modal-product-history',
  templateUrl: './modal-product-history.component.html',
  styleUrls: ['./modal-product-history.component.scss'],
})
export class ModalProductHystoryComponent implements OnInit {

  @Input() items
  @Input() nav
  @Input() overlayController

  @ViewChild('directionColumn') directionColumn: TemplateRef<any>;
  @ViewChild('table') table: DatatableComponent;
  @ViewChild(DdtTimelineComponent) timelineComp: DdtTimelineComponent;
  @ViewChild(IonContent) content: IonContent;

  emitNav = new Subject<any>()
  rows = [];
  selected;

  item_columns = []
  sortArrow = false;

  site;
  response;

  constructor(
    private popoverController: PopoverController,
    private modalController: ModalController,
    private render: Renderer2,
    private toastController: ToastController,
  ) {
    this.item_columns = [
      { prop: 'id', name: 'ID', frozenLeft: false, minWidth: 45, maxWidth: 45, width: 45, cellClass: 'td-id', headerClass: 'th-id' },
      { prop: 'quantity', name: 'Quantity', minWidth: 90, maxWidth: 90, width: 90, cellClass: 'td-qnt', headerClass: 'th-qnt' },
      {
        prop: 'ddt.status', name: 'Status',
        minWidth: 90, maxWidth: 90, width: 90, cellClass: 'td-status', headerClass: 'th-status'
      },
      { prop: 'ddt.type', name: 'Type', cellTemplate: this.directionColumn, minWidth: 90, maxWidth: 90, width: 90, cellClass: 'td-type', headerClass: 'th-type' },
      { prop: 'created', name: 'Created', minWidth: 220, maxWidth: 220, width: 220, pipe: new DateOnlyPipe('it-IT') }


    ]
  }


  onGetRowClass = (row) => {
    //console.log(row);
    // id 3 is Delete for Status Order

    return row.ddt.type
  }


  ngOnInit() {
    setTimeout(() => {
      this.timelineComp.elements = this.items
      this.timelineComp.emitSort.subscribe(e => {
        this.sortArrow = e
      })

    }, 200);
  }

  ionViewWillEnter(response) {

    console.log(this.items);

    this.response = '';
    if (response) {
    }

    console.log('modal product history', this.items);

  }

  dismissModal() {
    this.modalController.dismiss(this.response);
  }

}
