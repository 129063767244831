
import { AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, Renderer2, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { NavController, ModalController, IonNav, IonInfiniteScroll, IonSearchbar, IonContent, IonSegment, IonSegmentButton, IonMenu, MenuController } from '@ionic/angular';

import { animate, query, sequence, stagger, state, style, transition, trigger } from '@angular/animations';

import { TabsService } from 'src/app/services/tabs/tabs.service';

import { ColumnChangesService, ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';

import { SubMenuService } from 'src/app/services/utils/sub-menu/sub-menu.service';
import { CurrencyPipe } from '@angular/common';
import { OrdersService } from 'src/app/services/orders/orders.service';
import { ProductsService } from 'src/app/services/products/products.service';

interface PageInfo {
  offset: number;
  pageSize: number;
  limit: number;
  count: number;
}
/**
 * An object used to get page information from the server
 */
export class Page {
  // The number of elements in the page
  size: number = 0;
  // The total number of elements
  totalElements: number = 0;
  // The total number of pages
  totalPages: number = 0;
  // The current page number
  pageNumber: number = 0;
}

const listAnimation = trigger('listAnimation', [
  transition('* <=> *', [
    query(':enter',
      [style({ opacity: 0 }), stagger('180ms', animate('300ms ease-out', style({ opacity: 1 })))],
      { optional: true }
    ),
    query(':leave',
      animate('200ms', style({ opacity: 0 })),
      { optional: true }
    )
  ])
]);

class CurrencyOnlyPipe extends CurrencyPipe {
  public override transform(value, digitInfo, locale): any {
    return super.transform(value, 'EUR', 'symbol', digitInfo, locale);
  }
}
@Component({
  selector: 'app-list-products-services',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListProductsServiceComponent implements OnInit, AfterViewInit, OnChanges {


  n = 0
  public data: any; // Data;
  public columns: any;
  public rows: any;

  rowIsNotExpanded = true

  editing = {};

  selected = [];

  funder = [];
  calculated = [];
  pending = [];
  ColumnMode: ColumnMode
  expanded: any = {};
  timeout: any;
  total_order = 0;
  currentSegment: string = "all";

  @Input() projectId;
  @Input() cache;
  @Input() type;

  items;
  @Output() clicked = new EventEmitter<any>();
  @Output() switch = new EventEmitter<any>();


  @ViewChild('segment') segment: IonSegment;
  @ViewChild('btn_segment') btn_segment: IonSegmentButton;
  @ViewChild('myTable') table: DatatableComponent;
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  @ViewChild(IonContent) content: IonContent;


  fab: boolean = false;
  customAlertOptions: any = {
    header: 'List groups',
    //subHeader: 'Select group for user',
    message: 'Select group for user',
    translucent: true
  };

  searching = false
  res: any = []
  type_item = ['product','service'];
  order_types = [];
  order_categories = [];

  total_page = 0;
  page_number = 1;
  page_limit = 30;

  isSearch: boolean = false;
  isLoad: boolean = false;

  queryString = {
    search_by: '',
    search_data: ''
  };

  queryFilter = {
    search_by: 'sort',
    search_data: [{ prop: 'priority.id', dir: 'asc' }]
  };


  totalElements: number;
  pageNumber: number;

  isLoading = 0;

  constructor(
    private menu: MenuController,
    private subMenu: SubMenuService,
    private cd: ChangeDetectorRef,
    private navCtrl: NavController,
    private api: ProductsService,
    private modalController: ModalController,
    //private nav: IonNav,
    private tabService: TabsService,
    private renderer: Renderer2,
    private columnChangesService: ColumnChangesService,
    private el: ElementRef
  ) {

    console.log('list items constructor');

    this.columns = [
      { prop: 'id', name: 'ID', frozenLeft: true, maxWidth: 75, cellClass: 'td-id', headerClass: 'th-id' },
      //{ prop: 'qty', minWdth: 50, maxWidth: 50 },
      //{ prop: 'default_price', minWidth: 250, maxWidth: 250 },
      { prop: 'for_buy', minWidth: 70, maxWidth: 70 },
      { prop: 'for_sell', minWidth: 70, maxWidth: 70 },
      { prop: 'product.code', minWidth: 250, maxWidth: 250 },
      { prop: 'name', minWidth: 250, maxWidth: 250 },
      { prop: 'description', minWidth: 350, maxWidth: 250 },
      { prop: 'product.category.name', name: 'Category', minWidth: 250, maxWidth: 250 },
      //{ prop: 'type.name', name: 'Type', minWidth: 250, maxWidth: 250 },
      { prop: 'created', minWidth: 250, maxWidth: 250 },
      { prop: 'updated', minWidth: 250, maxWidth: 250 }
    ]


  }

  ngOnInit() {
    console.log('list items ngOnInit');

    //this.items = [];
    this.fab = true

    //this.loadProjects(this.queryString);
    //this.getOrders(false, "", this.queryString)

    this.queryString = localStorage.getItem('filter_search_items') ? JSON.parse(localStorage.getItem('filter_search_items')) : { search_by: '', search_data: 'all' }

    this.total_order = 0;
    this.total_page = 0;
    this.page_number = 1;

    this.items = []
    this.cache = {}


    setTimeout(() => {
      this.onScroll(0, null)
    }, 1000);
  }


  ngAfterViewInit(): void {

    console.log('list items ngAfterViewInit', this.pageNumber);
    console.log(this.items);
    console.log(this.projectId);
    console.log(this.cache);


  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);
    // if (changes['items'] && !changes['items'].firstChange) {

    // } else if (changes['cache'] && !changes['cache'].firstChange) {

    // }
    /*    
   
         this.total_order = 0;
         this.total_page = 0;
         this.page_number = 0;
   
         this.items = []
         this.cache = {}
        
         setTimeout(() => {
           this.table.bodyComponent.offsetX = 0;
           this.table.bodyComponent.offsetY = 0;
           this.table.headerComponent.offsetX = 0;
           this.table.recalculateColumns();
           this.items = [...this.items];
         }, 100);
         this.loadProjects(this.queryString)
       } */
  }

  openMenu() {
    this.menu.open('admin')
    this.subMenu.params.next({
      title: 'Filter',
      icon: 'funnel-outline',
      accordions: [
        {
          target: '',
          color: '',
          icon: 'list-outline',
          title: 'Status'
        },
        {
          target: '',
          color: '',
          icon: 'list-outline',
          title: 'Priority'
        },
        {
          target: '',
          color: '',
          icon: 'list-outline',
          title: 'Category'
        },
        {
          target: '',
          color: '',
          icon: 'list-outline',
          title: 'Type'
        }
      ]
    })
  }

  checkedSegment(event) {
    //console.log(this.queryString.search_data == event.id, this.queryString.search_data, event.id);
    //if (this.queryString.search_data == event.id)

    return this.queryString.search_data == event.id
  }


  /**
   * @description after click segment switch for selected status
   * @param data = this.queryString LocalStorage
   */
  loadProjects(data: any) {


    this.getProducts()


  }

  concateQuery(search) {
    console.log(search);


    const index = this.columns.findIndex(item => item.name === "Status");
    console.log(index)


   /*  if (this.page_number <= 1 && index == -1)
      this.columns.splice(2, 0, { prop: 'status.name', name: 'Status', width: 95, maxWidth: 95, minWidth: 95, cellClass: 'td-status', headerClass: 'th-status' })
 */

    let url = '?page=' + this.page_number + '&items=' + this.page_limit;
    let query = '';


    if (search && search != '' && search.search_data != 'all') {
      query += '&' + search.search_by + '=';
      query += search.search_data;
      url += query
      let filtered = this.columns.filter(obj => obj.name !== 'Status');


      console.log(filtered);

      this.columns = [...filtered]

    } else {
      this.columns = [...this.columns]
    }

    console.log(url);
    return url
  }

  /**
   * @description Tigger on click on segment filter on top view 
   * @param search_by 
   * @param search_data 
   * @param btn_segment template ref of element in segment
   */
  filterOrders(search_by, search_data, btn_segment, event) {
    if (this.queryString.search_data != '-') {
      console.log(event);

      this.total_order = 0;
      this.total_page = 0;
      this.page_number = 1;

      this.items = []
      this.cache = {}
      const selectedStatus = search_data.value;
      this.currentSegment = selectedStatus;

      this.queryString = { search_by, search_data: this.currentSegment }
      localStorage.setItem('filter_search_items', JSON.stringify(this.queryString))
      this.switch.emit()
      this.loadProjects(this.queryString);
    }

  }





  triggerColumnChangeDetection(): void {
    this.columnChangesService.onInputChange();
  }




  onDetailToggle(event) {
    console.log('Detail Toggled', event);
  }

  toggleExpandRow(row) {
    console.log('Toggled Expand Row!', row);
    this.table.rowDetail.collapseAllRows();
    this.table.rowDetail.toggleExpandRow(row);
  }

  /**
   * @description Aggiunge elementi allo scroll incrementando le pagina per l'api 
   * @param pageInfo ritorna dall'evento default con interfaccia dichiarata in alto PageInfo
   * @returns La paginazione fino al confronto pagine totati pagina attuale interrogata
   */
  setPage(pageInfo: PageInfo) {
    //console.log('pageInfo', this.n);
    console.log(pageInfo);
    //console.log(this.cache);
    this.n++;

    // Current page number is determined by last call to setPage
    // This is the page the UI is currently displaying
    // The current page is based on the UI pagesize and scroll position
    // Pagesize can change depending on browser size
    this.pageNumber = pageInfo.offset;

    // Calculate row offset in the UI using pageInfo
    // This is the scroll position in rows
    const rowOffset = pageInfo.offset * pageInfo.pageSize;

    // When calling the server, we keep page size fixed
    // This should be the max UI pagesize or larger
    // This is not necessary but helps simplify caching since the UI page size can change
    const page = new Page();
    page.size = this.page_limit;
    page.pageNumber = Math.floor(rowOffset / page.size);

    //console.log(page.pageNumber);
    //console.log(this.cache);

    // We keep a index of server loaded pages so we don't load same data twice
    // This is based on the server page not the UI

    /* if (this.cache[page.pageNumber]) return;
    this.cache[page.pageNumber] = true;

    if (this.n <= 1) { this.cache = {} }; // esclude l'errore del conteggio pagina in cache

    // Chiamo l'api vericicando il tot elementi, le pagine e i risultati per pagina
    this.loadProjects(this.queryString) */

  }


  onScroll(offsetY: number, event) {
    //console.log(event);

    if (event && event.offsetY == 0) return

    // total height of all rows in the viewport
    const viewHeight = this.el.nativeElement.getBoundingClientRect().height - 50;


    // check if we scrolled to the end of the viewport
    if (this.isLoading <= 0 && offsetY + viewHeight >= this.items.length * 35) {
      // total number of results to load
      let limits = this.page_limit;

      // check if we haven't fetched any results yet
      if (this.items.length === 0) {
        // calculate the number of rows that fit within viewport
        const pageSize = Math.ceil(viewHeight / 35);

        // change the limit to pageSize such that we fill the first page entirely
        // (otherwise, we won't be able to scroll past it)
        limits = Math.max(pageSize, this.page_limit);
      }

      if (this.cache[this.page_number]) return;
      this.cache[this.page_number] = true;

      this.loadProjects(this.queryString)
    }
  }


  sorted(columnProp: string, direction: string) {
    console.log(columnProp, direction);
    if (columnProp == 'priority.name') {
      columnProp = 'priority.id'
    }

    this.queryFilter = { search_by: 'sort', search_data: [{ prop: columnProp, dir: direction }] }
    localStorage.setItem('filter_sort_items', JSON.stringify({ search_by: 'sort', search_data: [{ prop: columnProp, dir: direction }] }))

    console.log(this.queryFilter);
  }


  onSelect({ selected }) {
    console.log('Select Event', selected, this.selected);
  }

  /**
   * @description onClick on row open order details
   * @param event pass obj row clicked
   */
  onActivate(params, event) {
    /* if (this.table.rowDetail && event.type == 'click') {
      this.table.rowDetail.toggleExpandRow(event.row);
      console.log('Activate Event', event);
    } */

    if (this.table.rowDetail && event.type == 'click') {
      this.table.rowDetail.toggleExpandRow(event.row);
      console.log('Activate Event', event);
     /*  if ($param == 'store') {
        this.goStore();
      }
 */
    }


  }

  setExpandStatus(expanded: any): void {
    console.log('setExpandStatus Event', expanded);
  }



  onGetRowClass = (row) => {
    //console.log(row);
    // id 3 is Delete for Status Order
    if (row.status.id === 3) {
      return 'deleted';
    } else if (row.status.id === 2) {
      return 'request';
    } else if (row.status.id === 1) {
      return 'draft';
    } else if (row.status.id === 4) {
      return 'order';
    }
    else
      return ''
  }


  /**
   * @description ritorna la somma delle colonne che gli si assegna questa funzione
   * @param n 
   * @returns somma della colonna in "rowSummary" template
   */
  caclulateSumm(n?) {
    console.log();

    this.total_order = 0;
    this.items.map((item) => {
      this.total_order += item.cost;
      //this.currencyCode = item.currency;
      //console.log(this.total_order);
    });
    return this.total_order.toFixed(2) + ' ' + n;
  }




  getProducts() {
    console.log('getproducts');


    this.isSearch = false;
    this.fab = true

    let url = '?page=' + this.page_number + '&items=25'// + this.page_limit;
    let query = '';

    this.api.getProductsList(url, '')
      .subscribe(data => {
        console.log(data)

        //this.totalElements = data.total_items;

        //this.total_page = data.num_pages;
        if (data.num_pages >= (this.page_number)) {


          // Update total count

          // Create array to store data if missing
          // The array should have the correct number of with "holes" for missing data
          if (!this.items) {
            this.items = new Array<any>(this.totalElements || 0);
          }

          // Calc starting row offset
          // This is the position to insert the new data
          const start = this.page_number * Number(25);


          let itemtype = []
          data.results.map(e => {
            console.log(e.type.id, this.type);

            if (e.type.id == this.type) {
              itemtype.push(e);
            }
          })


          this.totalElements = itemtype.length
          // Copy existing data
          const rows = [...this.items];

          // Insert new rows into correct position
          console.log(itemtype);

          rows.splice(start, Number(25), ...itemtype);

          this.items = [...rows];
          console.log(this.items);

          //if (this.page_number == 1) {
          /**
          * @description la migliore soluzione per risolvere il problema dopo aver scrollato in una sezione
          * @fix https://github.com/swimlane/ngx-datatable/issues/861/#issuecomment-733851318
          */
          setTimeout(() => {
            this.table.bodyComponent.offsetX = 0;
            this.table.bodyComponent.offsetY = 0;
            this.table.headerComponent.offsetX = 0;
            this.table.recalculateColumns();
            this.items = [...this.items]
          }, 300);
          //}
          // Decrement the counter of pending API calls
          this.page_number++;
          setTimeout(() => {
            this.isLoading--;
          }, 900);
        } else {
          console.log('return if n_page >= page_number', this.page_number);
          this.isLoading--;

        }



      }, error => {
        this.total_page = 0;
        console.log(error);
      })
  }



}




