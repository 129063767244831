
import { AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, OnChanges, OnInit, Output, Renderer2, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { NavController, ModalController, IonNav, IonInfiniteScroll, IonSearchbar, IonContent, IonSegment, IonSegmentButton, IonMenu, MenuController, LoadingController } from '@ionic/angular';

import { animate, query, sequence, stagger, state, style, transition, trigger } from '@angular/animations';

import { TabsService } from 'src/app/services/tabs/tabs.service';

import { ColumnChangesService, ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { ProjectsService } from 'src/app/services/projects/projects.service';
import { SubMenuService } from 'src/app/services/utils/sub-menu/sub-menu.service';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { StakeholdersPipe } from 'src/app/pipes/stakeholders.pipe';


interface PageInfo {
  offset: number;
  pageSize: number;
  limit: number;
  count: number;
}
/**
 * An object used to get page information from the server
 */
export class Page {
  limit: number = 25;
  // The number of elements in the page
  size: number = 25;
  // The total number of elements
  totalElements: number = 0;
  // The total number of pages
  totalPages: number = 0;
  // The current page number
  pageNumber: number = 1;
}

class DateOnlyPipe extends DatePipe {
  public override transform(value): any {
    return super.transform(value, 'MM/dd/y');
  }
}

const listAnimation = trigger('listAnimation', [
  transition('* <=> *', [
    query(':enter',
      [style({ opacity: 0 }), stagger('180ms', animate('300ms ease-out', style({ opacity: 1 })))],
      { optional: true }
    ),
    query(':leave',
      animate('200ms', style({ opacity: 0 })),
      { optional: true }
    )
  ])
]);

class CurrencyOnlyPipe extends CurrencyPipe {
  public override transform(value, digitInfo, locale): any {
    return super.transform(value, 'EUR', 'symbol', digitInfo, locale);
  }
}


@Component({
  selector: 'app-list-projects',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListProjectsComponent implements OnInit, AfterViewInit, OnChanges {

  /*   currentPage = 1;
    pageSize = 30; */

  module = 'projects'
  n = 0
  public data: any; // Data;
  public columns: any;
  public rows = new Array<any>()

  rowIsNotExpanded = true

  editing = {};

  selected = [];

  funder = [];
  calculated = [];
  pending = [];
  ColumnMode: ColumnMode
  expanded: any = {};
  timeout: any;
  total_order = 0;
  currentSegment: string = "all";

  @Input() projectId;
  @Input() cache = {}

  projects;
  @Output() clicked = new EventEmitter<any>();
  @Output() sendProjects = new EventEmitter<any>();
  @Output() switch = new EventEmitter<any>();

  @Output() emitterOpenNewProject = new EventEmitter<any>();


  @ViewChild('list') list: ElementRef;


  @ViewChild('segment') segment: IonSegment;
  @ViewChild('btn_segment') btn_segment: IonSegmentButton;
  @ViewChild('myTable') table: DatatableComponent;
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  @ViewChild(IonContent) content: IonContent;
  @ViewChild('priorityTemplate') priorityTemplate: TemplateRef<any>;
  @Input() searchInput: string;



  fab: boolean = false;
  customAlertOptions: any = {
    header: 'List groups',
    //subHeader: 'Select group for user',
    message: 'Select group for user',
    translucent: true
  };

  searching = false
  res: any = []
  order_status = [];
  order_types = [];
  order_categories = [];

  page = new Page();



  isSearch: boolean = false;
  isLoad: boolean = false;

  queryString = {
    search_by: '',
    search_data: ''
  };

  queryFilter = {
    search_by: 'sort',
    search_data: [{ prop: 'priority.id', dir: 'asc' }]
  };



  totalElements: number;
  pageNumber: number = 1;

  isLoading = 0;
  typeData = true


  navMenu = [
    { action: 'toggleSearch', label: 'Search' },
    { action: 'openMenu', label: 'Filter' },
  ]

  heightTable
  innerHeight



  constructor(
    private ngZone: NgZone,
    private menu: MenuController,
    private subMenu: SubMenuService,
    private cd: ChangeDetectorRef,
    private navCtrl: NavController,
    private api: ProjectsService,
    private modalController: ModalController,
    //private nav: IonNav,
    private tabService: TabsService,
    private renderer: Renderer2,
    private columnChangesService: ColumnChangesService,
    private el: ElementRef,
    private loaderCtrl: LoadingController
  ) {

    console.log('list project constructor');


    this.page.size = 25;


  }

  

  code
  category
  onSearchInput(event: any) {
    const input = event?.toLowerCase();
  
    if (input !== '') {
      console.log(this.code)
      let queryParams = this.concateQuery();
      if(this.code === true){
        queryParams += '&ecode=' + input;
      }
      else{
        queryParams += '&name=' + input;
      }
  
        console.log(queryParams)
        this.api.getProjectsList_rev(queryParams).subscribe(data => {
          this.rows = data.results;
        });
    } else {
      this.setPage({ offset: this.page.pageNumber }); // Reload the current page data when the search input is cleared
    }
  }


  createColumns() {

    this.columns = [
      //{ prop: 'id', name: 'ID'/* , frozenLeft: true */, minWidth: 75, maxWidth: 75, flexGrow: 0.3, cellClass: 'td-id', headerClass: 'th-id' },
      /* {
        prop: 'id',flexGrow: 1 minWidth: 75
      }, */
      //{ prop: 'id', name: 'ID', frozenLeft: true, flexGrow: 1, maxWidth: 75, minWidth: 75, cellClass: 'td-id', headerClass: 'th-id', summaryFunc: () => null },
      { prop: 'code', flexGrow: 2, maxWidth: 200, minWidth: 50, cellClass: 'td-code', headerClass: 'th-code' },
      { prop: 'name', flexGrow: 2, maxWidth: 500, minWidth: 500 },
      // {
      //   prop: 'date_start', name: 'Start', flexGrow: 1, maxWidth: 150, minWidth: 150, pipe: new DateOnlyPipe('it-IT')
      // },
      // { prop: 'date_end', name: 'End', flexGrow: 1, maxWidth: 150, minWidth: 150, pipe: new DateOnlyPipe('it-IT') },
      { prop: 'location.name', name: 'Luogo', flexGrow: 2, maxWidth: 300, minWidth: 140 },
      { prop: 'stakeholders', name: 'Stakeholders', flexGrow: 4, maxWidth: 1200, minWidth: 140, pipe: new StakeholdersPipe()},
      // { prop: 'total_order_in', name: 'SELL ORD', flexGrow: 1, maxWidth: 140, minWidth: 140 },
      // { prop: 'total_order_out', name: 'BUY ORD', flexGrow: 1, maxWidth: 140, minWidth: 140 },
      // { prop: 'prev_invoice_in', name: 'PREV SELL INV', flexGrow: 1, maxWidth: 140, minWidth: 140 },
      // { prop: 'prev_invoice_out', name: 'PREV BUY INV', flexGrow: 1, maxWidth: 140, minWidth: 140 },
      // { prop: 'total_invoice_in', name: 'SELL INV', flexGrow: 1, maxWidth: 140, minWidth: 140 },
      // { prop: 'total_invoice_out', name: 'BUY INV', flexGrow: 1, maxWidth: 140, minWidth: 140 }

    ]

  }


  ngOnInit() {
    console.log('list project ngOnInit');

    //this.projects = [];
    this.fab = true

    //this.loadProjects(this.queryString);
    //this.getProjects(false, "", this.queryString)

    this.queryString = localStorage.getItem('filter_projects') ? JSON.parse(localStorage.getItem('filter_projects')) : { search_by: '', search_data: 'all' }


    this.getProjectsMeta()


  }


  ngAfterViewInit(): void {

    console.log('list project ngAfterViewInit', this.pageNumber);
    console.log(this.projects);
    console.log(this.projectId);
    console.log(this.cache);
    this.createColumns()

  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);
    if (changes['project'] && !changes['project'].firstChange) {

    } else if (changes['cache'] && !changes['cache'].firstChange) {

    }
    if (changes['searchInput'] && changes['searchInput'].currentValue) {
      this.onSearchInput(changes['searchInput'].currentValue);
  }
  else{
    this.onSearchInput('');
  }
    if (changes['orders'] && !changes['orders'].firstChange) {

    } else if (changes['cache'] && !changes['cache'].firstChange) {

    }
    /*    
   
         this.total_order = 0;
         this.total_page = 0;
         this.page_number = 0;
   
         this.projects = []
         this.cache = {}
        
         setTimeout(() => {
           this.table.bodyComponent.offsetX = 0;
           this.table.bodyComponent.offsetY = 0;
           this.table.headerComponent.offsetX = 0;
           this.table.recalculateColumns();
           this.projects = [...this.projects];
         }, 100);
         this.loadProjects(this.queryString)
       } */
  }

  openMenu() {
    this.menu.open('admin')
    this.subMenu.params.next({
      title: 'Filter',
      icon: 'funnel-outline',
      accordions: [
        {
          target: '',
          color: '',
          icon: 'list-outline',
          title: 'Status'
        },
        {
          target: '',
          color: '',
          icon: 'list-outline',
          title: 'Priority'
        },
        {
          target: '',
          color: '',
          icon: 'list-outline',
          title: 'Category'
        },
        {
          target: '',
          color: '',
          icon: 'list-outline',
          title: 'Type'
        }
      ]
    })
  }

  checkedSegment(event) {
    //console.log(this.queryString.search_data == event.id, this.queryString.search_data, event.id);
    //if (this.queryString.search_data == event.id)

    return this.queryString.search_data == event.name
  }

  segmentChanged(event) {
    console.log(event);
    this.typeData = !this.typeData

  }




  concateQuery() {
    console.log('concateQuery');
    let params = JSON.parse(localStorage.getItem('filter_projects'))
    //if (this.pageNumber <= 1 && index == -1)
    //this.columns.splice(2, 0, { prop: 'status.name', name: 'Status', width: 95, maxWidth: 95, minWidth: 95, cellClass: 'td-status', headerClass: 'th-status' })
    console.log(params);


    let url = '?page=' + (this.page.pageNumber + 1) + '&items=' + this.page.size;


    if (!params) return url
    params.map((param, i) => {
      let query = '';

      /*  console.log('index', i);
       console.log(param);
       console.log(param != '');
       console.log(param.search_data, param.search_data != ''); */


      if (param && param != '' && param.search_data != '') {
        query += '&' + param.search_by + '=';
        query += param.search_data;


        //let filtered = this.columns.filter(obj => obj.name !== 'Status');


        //console.log(filtered);


        //this.columns = [...this.columns]
        url += query
      } else {
        console.log('else');

      }


    })
    console.log(url);

    return url
  }

  /**
   * @description Get project metadata
   */
  getProjectsMeta() {


    /* this.api.getProjectTypes()
      .subscribe(data => {
        //console.log(data);
        this.order_types = data
      })
    this.api.getProjectCategories()
      .subscribe(data => {
        //console.log(data);
        this.order_categories = data
      }) */


  }

  triggerColumnChangeDetection(): void {
    this.columnChangesService.onInputChange();
  }

  onDetailToggle(event) {
    console.log('Detail Toggled', event);
  }

  toggleExpandRow(row) {
    console.log('Toggled Expand Row!', row);
    this.table.rowDetail.collapseAllRows();
    this.table.rowDetail.toggleExpandRow(row);
  }


  setPage(pageInfo) {
    console.log(pageInfo);
    console.log('load api list');

    let loader = this.loaderCtrl.create({
      cssClass: 'loading-in-content',
      message: '',
      duration: 9000,
    }).then((response) => {
      //response.present();
    });

    this.page.pageNumber = pageInfo.offset
    // set the loading flag, which serves two purposes:
    // 1) it prevents the same page from being loaded twice
    // 2) it enables display of the loading indicator
    this.isLoading = 1;


    let url = this.concateQuery()

    this.api.getProjectsList_rev(url).subscribe(data => {
      //console.log(data);

      this.page.totalElements = data.total_items;
      this.page.totalPages = this.page.totalElements / this.page.size;
      this.rows = data.results
      this.rows = [...this.rows]


      console.log(this.rows);

      this.isLoading = 0;


      //this.loaderCtrl.dismiss()

    });


  }




  sorted(columnProp: string, direction: string) {
    console.log(columnProp, direction);
    if (columnProp == 'priority.name') {
      columnProp = 'priority.id'
    }

    this.queryFilter = { search_by: 'sort', search_data: [{ prop: columnProp, dir: direction }] }
    localStorage.setItem('filter_sort_projects', JSON.stringify({ search_by: 'sort', search_data: [{ prop: columnProp, dir: direction }] }))

    console.log(this.queryFilter);
  }


  onSelect({ selected }) {
    console.log('Select Event', selected, this.selected);
  }

  /**
   * @description onClick on row open project details
   * @param event pass obj row clicked
   */
  onActivate(event) {
    /* if (this.table.rowDetail && event.type == 'click') {
      this.table.rowDetail.toggleExpandRow(event.row);
      console.log('Activate Event', event);
    } */
    console.log(event);
    
    this.clicked.emit(event);

    if (event.type == 'click') {
      console.log('Activate Event', event);
      //this.openProjectModal(event.row)
    }
  }

  onActivate2(event) {
    /* if (this.table.rowDetail && event.type == 'click') {
      this.table.rowDetail.toggleExpandRow(event.row);
      console.log('Activate Event', event);
    } */
    let res = {
      type: 'click',
      row: event
    }
    console.log('Activate Event', res);
    //this.openProjectModal(event.row)
    this.clicked.emit(res);

  }

  setExpandStatus(expanded: any): void {
    console.log('setExpandStatus Event', expanded);
  }



  onGetRowClass = (row) => {
    //console.log(row);
    // id 3 is Delete for Status Project
    // if (row.status.id === 3) {
    //   return 'deleted';
    // } else if (row.status.id === 2) {
    //   return 'request';
    // } else if (row.status.id === 1) {
    //   return 'draft';
    // } else if (row.status.id === 4) {
    //   return 'project';
    // }
  }


  /**
   * @description ritorna la somma delle colonne che gli si assegna questa funzione
   * @param n 
   * @returns somma della colonna in "rowSummary" template
   */
  caclulateSumm(n?) {
    console.log();

    this.total_order = 0;
    this.projects.map((item) => {
      this.total_order += item.cost;
      //this.currencyCode = item.currency;
      //console.log(this.total_order);
    });
    return this.total_order.toFixed(2) + ' ' + n;
  }




  getProjects(url) {

    return
  }


  getProjectCode(code) {

    let codes = code.split('.')
    let string = ''
    for (var i = 5; i < codes.length; i++) {
      if (i != 5)
        string += '.' + codes[i]
      else
        string += codes[i]
    }
    return string;
  }


  methodAction(action) {
    try {
      this[action]()
    } catch {
      console.log('not call in parent component');
      this.emitterOpenNewProject.emit(action)
    }
    console.log(action)
  }





}




