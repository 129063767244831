// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content {
  --padding-top: 16px;
}
ion-content .add-photo-wrapper {
  justify-content: center;
}
ion-content .user-image {
  --size: 50px;
  margin: auto;
}

ion-title {
  padding-inline-start: 0;
  padding-inline-end: 0;
  text-align: center;
}

.size-14 {
  font-size: 1.4em;
  /* margin-right: 20px; */
}

.custom-label {
  /*  display: contents;
   font-size: 13px;
   padding-top: 11px; */
}

.custom-label ion-icon {
  font-size: 28px;
}

.p_d_t {
  padding-top: 10px;
}

::ng-deep app-new-project {
  border-radius: 4px;
}

.type_entity {
  --background: #00000057 !important;
  height: 40px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/new-line-address/new-line-address.component.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;AACJ;AACI;EACE,uBAAA;AACN;AAEI;EACE,YAAA;EACA,YAAA;AAAN;;AAIE;EACE,uBAAA;EACA,qBAAA;EACA,kBAAA;AADJ;;AAIE;EACE,gBAAA;EACA,wBAAA;AADJ;;AAIE;EACC;;uBAAA;AACH;;AAIE;EACE,eAAA;AADJ;;AAIE;EACE,iBAAA;AADJ;;AAIE;EACE,kBAAA;AADJ;;AAKE;EACE,kCAAA;EACA,YAAA;AAFJ","sourcesContent":["ion-content {\n    --padding-top: 16px;\n  \n    .add-photo-wrapper {\n      justify-content: center;\n    }\n  \n    .user-image {\n      --size: 50px;\n      margin: auto;\n    }\n  }\n  \n  ion-title {\n    padding-inline-start: 0;\n    padding-inline-end: 0;\n    text-align: center;\n  \n  }\n  .size-14{\n    font-size: 1.4em;\n    /* margin-right: 20px; */\n  }\n  \n  .custom-label{\n   /*  display: contents;\n    font-size: 13px;\n    padding-top: 11px; */\n  }\n  \n  .custom-label ion-icon{\n    font-size: 28px;\n  }\n  \n  .p_d_t{\n    padding-top: 10px;\n  }\n  \n  ::ng-deep app-new-project{\n    border-radius: 4px;\n  }\n\n\n  .type_entity{\n    --background: #00000057 !important;\n    height: 40px;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
