import { TasksService } from 'src/app/services/tasks/tasks.service';
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable object-shorthand */
/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/semi */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable quote-props */


import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ModalController, Platform, ToastController } from '@ionic/angular';
import { FormGroup, FormControl, Validators, FormGroupDirective } from '@angular/forms';
import { DataService } from '../../services/data/data.service';
import * as moment from 'moment';
import { ApiService } from 'src/app/services/api/api.service';
import { IonDatetime } from '@ionic/angular';
import { format, parseISO } from 'date-fns';





@Component({
  selector: 'app-new-task',
  templateUrl: './new-task.component.html',
  styleUrls: ['./new-task.component.scss'],
})
export class NewTaskComponent {


  @Input() tasks: any;

  minDate = moment().format();
  maxDate = moment().add(1, 'd').format();

  
  

  createTaskForm: FormGroup;
  types_category: any = [];
  subscription

  @ViewChild('createForm') createForm: FormGroupDirective;  

  constructor(
    private platform: Platform,
    private modalController: ModalController,
    private toastController: ToastController,
    private dataService: DataService,
    private api: TasksService
  ) { }

  dismissModal(status = '') {
    this.modalController.dismiss(status);
  }

  ionViewDidEnter() {
    this.subscription = this.platform.backButton.subscribe((e) => {
      console.log(e);
      this.dismissModal()
    });
  }

  ionViewWillLeave() {
    this.subscription.unsubscribe();
  }

  ionViewWillEnter(): void {   

    this.createTaskForm = new FormGroup({
      'name': new FormControl('', Validators.required),
      'description': new FormControl('', Validators.required),
      'start_date': new FormControl(this.minDate, Validators.required),
      'end_date': new FormControl(this.maxDate, Validators.required)  
    });

   
  }


  submitForm() {
    this.createForm.onSubmit(undefined);
  }

  async presentToast(text, title, color) {
    const toast = await this.toastController.create({
      message: title + ': ' + (text.message),
      position: 'bottom',
      duration: 2000,
      cssClass: 'toast',
      color: color
    });
    toast.present();
  }

  createTask(values: any) {
    // copy all the form values into the new task
    const newTask: any = {};
    //this.dataService.createTask(newTask);
    //newTask.start_date = moment(newTask.start_date).format().split('+')[0];
    //newTask.end_date = moment(newTask.end_date).format().split('+')[0];
    newTask['id'] = (parseInt(this.tasks[this.tasks.length -1].id.replace('0', "")) + 1).toString().padStart(7, "0");         
    newTask['title'] = values.name  
    newTask['start'] = parseInt(this.epochDate(values.start_date)) 
    newTask['end'] = parseInt(this.epochDate(values.end_date))
    //{ id: '000000', title: 'Task 0', start: 1627729997, end: 1628421197, expandable: true },
    
    
    this.tasks.push(newTask)
    this.dismissModal(newTask)
    console.log(this.tasks, newTask);
    

    /* this.api.createTask(newTask).subscribe(e => {
      console.log(e);
      if (e[0].status != 'ko')
        this.dismissModal(newTask)        
      else
        this.presentToast(e[0], 'Error', 'danger')
    }) */
  }

  epochDate(date){
    var epoch = moment(date).unix();
    return epoch.toString();
  }

  /* onChangeStartDate(event) {
    this.maxDate = moment(event.detail.value).add(1, 'd').format();
    this.createTaskForm.controls.end_date.setValue(this.maxDate)
  } */

  /* formatDate(value: string) {
    return format(parseISO(value), 'MMM dd yyyy');
  } */
  onChangeStartDate(event) {
    this.maxDate = moment(event.detail.value).add(1, 'd').format();
    //this.createTaskForm.controls.date_pre_end.setValue(this.maxDate)
  }

  formatDate(value: string) {
    return format(parseISO(value), 'MMM dd yyyy');
  }
}
