import { Injectable } from '@angular/core';
import { HttpClient,HttpEvent,HttpHeaders,HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable({
  providedIn: 'root'
})


export class TimesheetService {

  private url: string = 'http';
  private token: any;

  private project_types: any[]


  constructor(private http: HttpClient, private authService: AuthenticationService) {
    
    this.url = environment.SSL.active ? this.url + 's' : this.url
    this.url += '://' + environment.API_URI.url + ':' + environment.API_URI.port + environment.API_URI.path

    this.authService.currentToken.subscribe(x => {
      //console.log(x);
      this.token = x //= JSON.parse(localStorage.getItem(TOKEN_KEY))

    })

  }

  listFiles = new Subject()
  canReload = new BehaviorSubject<boolean>(false);

  getSheet(): Observable<any> {
    return this.http.get(this.url + '/get-sheet').pipe(tap(e=>{
      //console.log(e);
      this.listFiles.next(e)
    }))
  }
  getAvatar(id:any): Observable<any> {
    return this.http.get(this.url+'get-avatar/'+id,{ responseType: 'blob' });
  }

  addRow(data: any): Observable<any> {
    return this.http.post(this.url + '/add-row', data);
  }

  addRequest(data: any): Observable<any> {
    return this.http.post(this.url + '/add-request', data);
  }

  upload(id:any, data: any): Observable<any> {
    return this.http.post(this.url + '/upload/'+id, data)
  }
  getListAttachment(): Observable<any> {
    return this.http.get(this.url + 'get-list-attachment');
  }
  updateNotes(id: any, data: any): Observable<any> {
    return this.http.put(`${this.url}/update-notes/${id}`,data);
  }
  deleteRow(row_id: any): Observable<any> {
    return this.http.delete(`${this.url}/${row_id}`);
  }
  updateRow(id: any, data: any): Observable<any> {
    return this.http.put(`${this.url}/${id}`,data);
  }
  getAttachment(id: any): Observable<any> {
    return this.http.get(this.url + '/get-attachment/' + id);
  }
  deleteAttachment(row_id: any): Observable<any> {
    return this.http.delete(`${this.url}/delete-attachment/${row_id}`);
  }
  approval(id: any, data: any): Observable<any> {
    return this.http.put(`${this.url}/approval/${id}`,data);
  }
  denial(id: any, data: any): Observable<any> {
    return this.http.put(`${this.url}/denial/${id}`,data);
  }
}
