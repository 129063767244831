import { ActivatedRoute, Router } from '@angular/router';

import { Platform, AnimationController, IonNav, ModalController, ToastController } from '@ionic/angular';
import { Component, OnInit, ElementRef, ViewChild, Input } from '@angular/core';
import { NewOfferComponent } from '../new-offer/new-offer.component';
import { ApiService } from 'src/app/services/api/api.service';
import { UploadDesktopComponent } from 'src/app/components/media/upload-desktop/upload-desktop.component';
import { OffersService } from 'src/app/services/offers/offers.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { ProjectsService } from 'src/app/services/projects/projects.service';
import { CustomersService } from 'src/app/services/customers/customers.service';
import { VendorsService } from 'src/app/services/vendors/vendors.service';

@Component({
  selector: 'app-offer-update',
  templateUrl: './offer-update.page.html',
  styleUrls: ['./offer-update.page.scss'],
})

export class OfferUpdatePage {
  @ViewChild('favIcon', { read: ElementRef }) favIcon: ElementRef;
  @ViewChild(UploadDesktopComponent, { static: true }) UploadDesktopComponent: UploadDesktopComponent;
  @ViewChild('myTable') table: DatatableComponent;
  @ViewChild('createForm') createForm: FormGroupDirective;

  @Input() id: any = '';
  public showProgressStatus = false;
  public offer: any;
  public favState = false;
  public favOnAnimation: Animation;
  public favOffAnimation: Animation;

  page_number = 1;
  page_limit = 30;

  filteredAddress = [];
  selectedSegment


  createOfferForm: FormGroup;


  response;

  types: any = [];
  categories: any = [];
  status: any = [];
  priorities: any = [];
  projects: any = [];
  customers: any = [];
  vendors: any = [];



  constructor(
    public platform: Platform,
    private offerApi: OffersService,
    private projectApi: ProjectsService,
    private customerApi: CustomersService,
    private vendorApi: VendorsService,
    private api: ApiService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
    private animationCtrl: AnimationController,
    //private nav: IonNav,
    private modalController: ModalController,
    private toastController: ToastController
  ) {

    this.id = this.route.snapshot.paramMap.get('id');
  }

  createBaseForm() {

    // Generete FormGroup Create Offer 
    this.createOfferForm = this.fb.group({
      type_id: this.fb.control(this.offer.type, Validators.required),
      category_id: this.fb.control(this.offer.category, Validators.required),
      priority_id: this.fb.control(this.offer.priority, Validators.required),
      project_id: this.fb.control(this.offer.project, Validators.required),
      customer_id: this.fb.control(this.offer.customer, Validators.required),
      vendor_id: this.fb.control(this.offer.vendor, Validators.required),
      cost: this.fb.control(this.offer.cost, Validators.required)


    });
    //console.log(this.createOfferForm);

  }

  getOfferMeta() {

    let page_project = 1;
    let page_customer = 1;
    let page_vendor = 1;


    this.projectApi.getProjectsList_rev('?page=' + page_project + '&items=' + 25).subscribe(e => {
      //console.log(e);
      this.projects = e.results;
      /* if (e.num_pages > 1) {
        for (let i = 2; i <= e.num_pages; i++) {
          //console.log(i);
          page_project++
          this.projectApi.getProjectsList_rev('?page=' + page_project + '&items=' + 25).subscribe(e => {
            //console.log(e);
            this.projects.push(...e.results);
          })
        }
      } */

    })
    this.customerApi.getCustomersList('?page=' + page_customer + '&items=' + 25).subscribe(e => {
      //console.log(e);
      this.customers = e.results;
      /* if (e.num_pages > 1) {
        for (let i = 2; i <= e.num_pages; i++) {
          //console.log(i);
          page_customer++
          this.customerApi.getCustomersList('?page=' + page_customer + '&items=' + 25).subscribe(e => {
            //console.log(e);
            this.customers.push(...e.results);
          })
        }
      } */

    })
    this.vendorApi.getVendorsList('?page=' + page_vendor + '&items=' + 25).subscribe(e => {
      //console.log(e);
      this.vendors = e.results;
      /* if (e.num_pages > 1) {
        for (let i = 2; i <= e.num_pages; i++) {
          //console.log(i);
          page_vendor++
          this.vendorApi.getVendorsList('?page=' + page_vendor + '&items=' + 25).subscribe(e => {
            //console.log(e);
            this.customers.push(...e.results);
          })
        }
      } */
    })
  }

  ionViewWillEnter() {
    console.log('ionViewWillEnter', this.id);


    this.response = '';
   /* < this.offerApi.getOfferById(parseInt(this.id, 10), '?mode=simple')
      .subscribe(e => {
        // if the project doesn't exists, return to home page
        if (!e) {
          //this.router.navigate(['/offer-list']);
        } else {
          this.offer = e;
          this.selectedSegment = ''

          this.getOfferMeta();
          this.createBaseForm()
      
        }
      });
> */

  }


  getDocuments(id_folder) {
    let url = '?page=' + this.page_number + '&items=' + this.page_limit;
    this.api.getFiles(id_folder, url).subscribe(x => {
      this.page_number++
      return x
    })
  }



  fav(): void {
    if (this.favState) {
      // this.favOnAnimation.stop();
      this.favOffAnimation.play();
    } else {
      // this.favOffAnimation.stop();
      this.favOnAnimation.play();
    }

    this.favState = !this.favState;
  }

  onChangeSelectAcc(event) {
    console.log(event);
  }


  editOffer() {
    //this.navCtrl.navigateForward(['/members/offer-details/', item.id]);    
    //nav: this.nav(OfferUpdatePage, { id: this.id }, { animated: true, direction: 'forward' });
  }


  async openNewOfferModal() {
    const modal = await this.modalController.create({
      component: NewOfferComponent,
      canDismiss: true,
      componentProps: {
        title: 'Add New Offer',
        parent_type: 'root'
      }
      //presentingElement: this.routerOutlet.nativeEl,
    })


    modal.onWillDismiss().then((e) => {
      if (e.data && e.data.status != -1) {
        /* this.page_number = 1;
        this.myInput.value = "";
        this.offers = [];
        this.getOffers(false, '') */
      }
    });
    return await modal.present();
  }


  submitForm() {
    this.createForm.onSubmit(undefined);
  }

  fabCallBack(action) {
    console.log(action);

    if (action) {
      this[action]()
    } else {
      console.log('not call in component, run callback', 'details');


    }
  }


  removeDuplicate(types) {
    if (types && types.lenght) {
      const names = types.map(o => o.name)
      const filtered = types.filter(({ name }, index) => !names.includes(name, index + 1))
      return filtered
    }
  }


  filterAddress(address, event) {

    if (event) {
      console.log(address, event);
      this.filteredAddress = address.filter(function (type) {
        return type.name == event.detail.value;
      });
    }

  }



  async openModalUpload() {
    const modal = await this.modalController.create({
      component: UploadDesktopComponent,
      canDismiss: true,
      componentProps: {
        //title: 'Add New Offer',
        item: this.offer,
        header: { ion_header: true },
        files: [],
        parent_type: 'root'
      }
      //presentingElement: this.routerOutlet.nativeEl,
    })


    modal.onWillDismiss().then((e) => {
      if (e.data && e.data.status != -1) {
        /* this.page_number = 1;
        this.myInput.value = "";
        this.offers = [];
        this.getOffers(false, '') */
      }
    });
    return await modal.present();
  }


  dismissModal(event?) {
    this.modalController.dismiss( this.response )
  }

  onActivate(event) {
    if (this.table.rowDetail && event.type == 'click') {
      this.table.rowDetail.toggleExpandRow(event.row);
      console.log('Activate Event', event);
    }
  }



  updateOffer(form) {

    //Call API
    console.log(form, this.offer);

    return
    /* this.offerApi.putOfferById(form, this.offer).subscribe(e => {
      console.log(e);
      if (e.message && e.message.includes('OK')) {
        this.presentToast(e.message, 'success')
        this.response = e.data;
        this.dismissModal();
      } else {
        this.response = 'KO';
      }
    }) */

    //console.log(form)
  }


  async presentToast(text, color) {
    const toast = await this.toastController.create({
      message: text,
      position: 'bottom',
      duration: 3000,
      cssClass: 'toast',
      color: color
    });
    return await toast.present();
  }


}
