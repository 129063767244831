import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  //dark = new Subject<boolean>()
  public dark = new BehaviorSubject(true);


  constructor(
  ) { }


  publishStatus(status) {
    console.log(status);
    this.dark.next(status);
  }

  /**
   * @returns Ritorna il valore obj dell'utente
   */
   observStatus() {
    return this.dark.value;
  }

  isAvailable() {

  }

  isDarkModeEnabled() {

  }

}
