
import { AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, Renderer2, SimpleChanges, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { NavController, ModalController, IonNav, IonInfiniteScroll, IonSearchbar, IonContent, IonSegment, IonSegmentButton, IonMenu, MenuController, LoadingController } from '@ionic/angular';

import { animate, query, sequence, stagger, state, style, transition, trigger } from '@angular/animations';

import { TabsService } from 'src/app/services/tabs/tabs.service';

import { ColumnChangesService, ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { OrdersService } from 'src/app/services/orders/orders.service';
import { SubMenuService } from 'src/app/services/utils/sub-menu/sub-menu.service';
import { CurrencyPipe } from '@angular/common';
import { PlatformService } from 'src/app/services/utils/platform/plarformservice';
import { JobsService } from 'src/app/services/jobs/jobs.service';
import { TitleAppService } from 'src/app/services/utils/title-app/title-app.service';
import { ThemeService } from 'src/app/services/theme/theme.service';

interface PageInfo {
  offset: number;
  pageSize: number;
  limit: number;
  count: number;
}
/**
 * An object used to get page information from the server
 */
export class Page {
  limit: number = 25;
  // The number of elements in the page
  size: number = 25;
  // The total number of elements
  totalElements: number = 0;
  // The total number of pages
  totalPages: number = 0;
  // The current page number
  pageNumber: number = 1;
}

const listAnimation = trigger('listAnimation', [
  transition('* <=> *', [
    query(':enter',
      [style({ opacity: 0 }), stagger('180ms', animate('300ms ease-out', style({ opacity: 1 })))],
      { optional: true }
    ),
    query(':leave',
      animate('200ms', style({ opacity: 0 })),
      { optional: true }
    )
  ])
]);

class CurrencyOnlyPipe extends CurrencyPipe {
  public override transform(value, digitInfo, locale): any {
    return super.transform(value, 'EUR', 'symbol', digitInfo, locale);
  }
}
@Component({
  selector: 'app-list-category-orders',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ListCategoryOrdersComponent implements OnInit, AfterViewInit, OnChanges {


  n = 0
  public data: any; // Data;
  public columns: any;
  public rows = new Array<any>()

  rowIsNotExpanded = true

  editing = {};

  selected = [];

  funder = [];
  calculated = [];
  pending = [];
  ColumnMode: ColumnMode
  expanded: any = {};
  timeout: any;
  total_order = 0;
  currentSegment: string = "all";

  module = 'orders'
  @Input() projectId;
  @Input() cache;
  @Input() tenders;

  orders;
  @Output() clicked = new EventEmitter<any>();
  @Output() clickedCategory = new EventEmitter<any>();
  @Output() sendOrders = new EventEmitter<any>();
  @Output() switch = new EventEmitter<any>();

  @Output() emitterOpenNewOrder = new EventEmitter<any>();

  @ViewChild('list') _list: ElementRef;
  @ViewChild('categories') categories: ElementRef;


  @ViewChild('segment') segment: IonSegment;
  @ViewChild('btn_segment') btn_segment: IonSegmentButton;
  @ViewChild('myTable') table: DatatableComponent;
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  @ViewChild(IonContent) content: IonContent;
  @ViewChild('priorityTemplate') priorityTemplate: TemplateRef<any>;


  fab: boolean = false;
  customAlertOptions: any = {
    header: 'List groups',
    //subHeader: 'Select group for user',
    message: 'Select group for user',
    translucent: true
  };

  searching = false
  res: any = []
  order_status = [];
  order_types = [];
  order_categories = [];

  page = new Page();

  total_page = 0;
  page_number = 1;
  page_limit = 30;

  isSearch: boolean = false;
  isLoad: boolean = false;

  queryString = [{
    search_by: '',
    search_data: ''
  }];

  queryFilter = {
    search_by: 'sort',
    search_data: [{ prop: 'priority.id', dir: 'asc' }]
  };


  totalElements: number;
  pageNumber: number = 1;

  isLoading = 0;
  typeData = true


  navMenu = [
    { action: 'toggleSearch', label: 'Search' },
    { action: 'openMenu', label: 'Filter' },
  ]


  filterItems: any = [
    {
      search_by: 'status', //name //
      search_data: ''
    },
  ]

  activeStatusOrder
  stringStatusOrderFilter = '--'

  heightTable
  innerHeight

  sortedCategories: any = []
  tableOrder = false

  isDark = true
  isMobile = false

  constructor(
    private menu: MenuController,
    private subMenu: SubMenuService,
    private cd: ChangeDetectorRef,
    private navCtrl: NavController,
    private api: OrdersService,
    private apiJob: JobsService,
    private modalController: ModalController,
    private tabService: TabsService,
    private renderer: Renderer2,
    private columnChangesService: ColumnChangesService,
    private el: ElementRef,
    private loaderCtrl: LoadingController,
    private utils: PlatformService,
    private titleAppService: TitleAppService,
    private themeDetection: ThemeService
  ) {

    console.log(this.isDark)

    this.themeDetection.dark.subscribe(e => {
      console.log(e);

      if(e === undefined){
        this.isDark = true
      }
      else{
        this.isDark = e
      }

    })

    console.log('list orders constructor');

    this.page.size = 25;
    var width = window.innerWidth
      || document.documentElement.clientWidth
      || document.body.clientWidth;

    this.isMobile = width < 530

    this.utils.autoWidth.subscribe(e => {
      this.isMobile = e < 530
    })

    this.callFilterItem()
  }


  // forza il reset al l'entrata
  callFilterItem() {

    let readFilter = {};

    try {
      readFilter = JSON.parse(localStorage.getItem('filter_orders'))
      console.log(readFilter, this.filterItems);


      /*       if (readFilter == null) {
              localStorage.setItem('filter_orders', JSON.stringify(this.filterItems))
            } else {
              this.filterItems = readFilter;
            } */

      readFilter[0].search_data = ''
      readFilter[1].search_data = ''
      localStorage.setItem('filter_orders', JSON.stringify(readFilter))



    }
    catch {
      console.log('error');
      localStorage.setItem('filter_orders', JSON.stringify(this.filterItems))
      this.filterItems = readFilter;
    }



  }

  createColumns() {

    this.columns = [
      //{ prop: 'id', name: 'ID'/* , frozenLeft: true */, minWidth: 75, maxWidth: 75, flexGrow: 0.3, cellClass: 'td-id', headerClass: 'th-id' },
      /* {
        prop: 'id', width: 75, minWidth: 75
      }, */
      /* {
        prop: 'priority.name', name: 'Priority', flexGrow: 1, minWidth: 90, maxWidth: 90,
        cellClass: 'td-priority', headerClass: 'th-priority',
        cellTemplate: this.priorityTemplate
      }, */
      {
        prop: 'code', flexGrow: 1, minWidth: 520, maxWidth: 520, cellClass: 'td-code', headerClass: 'th-code'
      },
      /* {
        prop: 'cost', flexGrow: 1, minWidth: 180, maxWidth: 180, pipe: new CurrencyOnlyPipe('en-US', '€'),
        /*  summaryFunc: () => this.caclulateSumm('€'), 
      }, */
      { prop: 'name', name: 'Name', flexGrow: 1, minWidth: 180, maxWidth: 180 },
      //{ prop: 'type.name', name: 'Type', minWidth: 260, maxWidth: 260, flexGrow: 1, },

      //{ prop: 'creator.email', name: 'Email', width: 190, minWidth: 190, maxWidth: 230 },
      //{ prop: 'project.name', name: 'Project', maxWidth: 420, minWidth: 400, flexGrow: 2 },
      //{ prop: 'customer.name', name: 'Customer', flexGrow: 1, minWidth: 240, maxWidth: 240, },
      //{ prop: 'vendor.name', name: 'Vendor', flexGrow: 1, minWidth: 290, maxWidth: 290, },
      //{ prop: 'deleted', maxWidth: 70, cellClass: 'td-deleted', headerClass: 'th-deleted' },



    ]

  }

  ngOnInit() {
    console.log('list orders ngOnInit');

    //this.orders = [];
    this.fab = true

    //this.loadProjects(this.queryString);
    //this.getOrders(false, "", this.queryString)


    this.getOrdersMeta()


  }

  // component.ts
getCategoryBackgroundColor(categoryName: string): string {
  switch(categoryName) {
    case 'Amministrazione':
      return '#819edb';
    case 'Cantiere':
      return '#ff851b';
    case 'Consulenza':
      return '#5BB450';
    case 'Gare':
      return '#FF7373';
    case 'Legale':
      return '#CBBEB5';
    case 'Manutenzione':
      return '#ff2525';
    case 'Noli':
      return '#DAA520';
    case 'Progettazione':
      return '#088DA5';
    case 'Ricerca e Sviluppo':
      return 'lightsteelblue';
    case 'Servizi':
      return '#ffffff';
    default:
      return 'white';
  }
}


  ngAfterViewInit(): void {

    console.log('list orders ngAfterViewInit', this.pageNumber);
    console.log(this.orders);
    console.log(this.projectId);
    console.log(this.cache);
    this.createColumns()

    this.queryString = localStorage.getItem('filter_orders') ? JSON.parse(localStorage.getItem('filter_orders')) : { search_by: '', search_data: 'all' }

    //let d = this.queryString.map(e => e.search_by == 'status').indexOf(true)
    //console.log(d);

    //this.stringStatusOrderFilter = this.queryString[d].search_data


    console.log(this.stringStatusOrderFilter);
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);
    if (changes['orders'] && !changes['orders'].firstChange) {

    } else if (changes['cache'] && !changes['cache'].firstChange) {

    }

    setTimeout(() => {

      console.log(this._list);
      console.log(this._list['el'].getBoundingClientRect());
      let rect = this._list['el'].getBoundingClientRect()
      this.heightTable = rect.top + 0
      this.innerHeight = innerHeight;
      console.log(innerHeight, rect.top);



    }, 200)

    /*    
   
         this.total_order = 0;
         this.total_page = 0;
         this.page_number = 0;
   
         this.orders = []
         this.cache = {}
        
         setTimeout(() => {
           this.table.bodyComponent.offsetX = 0;
           this.table.bodyComponent.offsetY = 0;
           this.table.headerComponent.offsetX = 0;
           this.table.recalculateColumns();
           this.orders = [...this.orders];
         }, 100);
         this.loadProjects(this.queryString)
       } */
  }

  openMenu() {
    this.menu.open('admin')
    this.subMenu.params.next({
      title: 'Filter',
      icon: 'funnel-outline',
      accordions: [
        {
          target: '',
          color: '',
          icon: 'list-outline',
          title: 'Status'
        },
        {
          target: '',
          color: '',
          icon: 'list-outline',
          title: 'Priority'
        },
        {
          target: '',
          color: '',
          icon: 'list-outline',
          title: 'Category'
        },
        {
          target: '',
          color: '',
          icon: 'list-outline',
          title: 'Type'
        }
      ]
    })
  }


  segmentChanged(event) {
    console.log(event);
    this.typeData = !this.typeData

  }




  concateQuery() {
    console.log('concateQuery');
    let params = JSON.parse(localStorage.getItem('filter_orders'))
    //if (this.pageNumber <= 1 && index == -1)
    //this.columns.splice(2, 0, { prop: 'status.name', name: 'Status', width: 95, maxWidth: 95, minWidth: 95, cellClass: 'td-status', headerClass: 'th-status' })
    console.log(params);


    let url = '?page=' + (this.page.pageNumber + 1) + '&items=' + this.page.size;


    if (!params) return url
    params.map((param, i) => {
      let query = '';

      /*  console.log('index', i);
       console.log(param);
       console.log(param != '');
       console.log(param.search_data, param.search_data != ''); */


      if (param && param != '' && param.search_data != '') {
        query += '&' + param.search_by + '=';
        query += param.search_data;


        //let filtered = this.columns.filter(obj => obj.name !== 'Status');


        //console.log(filtered);


        //this.columns = [...this.columns]
        url += query
      } else {
        console.log('else');

      }


    })
    console.log(url);

    return url
  }

  /**
   * @description Get order metadata
   */
  getOrdersMeta() {
    this.api.getOrderTypes()
      .subscribe(data => {
        console.log(data);
        this.order_types = data
      })
    /*     this.api.order_categories_list()
          .subscribe(data => {
            console.log(data);
            this.order_categories = data.results.filter((item) => item.parent === null);
            console.log(this.order_categories)
    
          }) */
    /* this.api.getOrderStatus()
   .subscribe(data => {
     //console.log(data);
     this.order_status = data
     this.order_status.push( */ /* #Ric */
    /*  {
       id: 'all',
       name: 'All',
       code: 'ALL'
     }, */
    /* 
            )
          }) */ /* #Ric */


    this.setPage({ offset: 0 })

  }

  triggerColumnChangeDetection(): void {
    this.columnChangesService.onInputChange();
  }

  onDetailToggle(event) {
    console.log('Detail Toggled', event);
  }

  toggleExpandRow(row) {
    console.log('Toggled Expand Row!', row);
    this.table.rowDetail.collapseAllRows();
    this.table.rowDetail.toggleExpandRow(row);
  }


  sorted(columnProp: string, direction: string) {
    console.log(columnProp, direction);
    if (columnProp == 'priority.name') {
      columnProp = 'priority.id'
    }

    this.queryFilter = { search_by: 'sort', search_data: [{ prop: columnProp, dir: direction }] }
    localStorage.setItem('filter_sort_orders', JSON.stringify({ search_by: 'sort', search_data: [{ prop: columnProp, dir: direction }] }))

    console.log(this.queryFilter);
  }


  onSelect({ selected }) {
    console.log('Select Event', selected, this.selected);
  }

  /**
   * @description onClick on row open order details
   * @param event pass obj row clicked
   */
  onActivate(event) {
    /* if (this.table.rowDetail && event.type == 'click') {
      this.table.rowDetail.toggleExpandRow(event.row);
      console.log('Activate Event', event);
    } */
    if (event.type == 'click') {
      console.log('Activate Event', event);
      //this.openOrderModal(event.row)

      this.clickedCategory.emit(event);
    }
  }

  onActivate2(event) {
    /* if (this.table.rowDetail && event.type == 'click') {
      this.table.rowDetail.toggleExpandRow(event.row);
      console.log('Activate Event', event);
    } */
    let res = {
      type: 'click',
      row: event
    }
    console.log('Activate Event', res);
    //this.openOrderModal(event.row)
    this.clicked.emit(res);

  }

  setExpandStatus(expanded: any): void {
    console.log('setExpandStatus Event', expanded);
  }



  onGetRowClass = (row) => {
    //console.log(row);
    // id 3 is Delete for Status Order
    /* if (row.status.id === 4 || row.status.id === 5) {
      return 'deleted';
    } else if (row.status.id === 2) {
      return 'request';
    } else if (row.status.id === 1) {
      return 'draft';
    } else if (row.status.id === 3) {
      return 'order';
    } */
  }


  /**
   * @description ritorna la somma delle colonne che gli si assegna questa funzione
   * @param n 
   * @returns somma della colonna in "rowSummary" template
   */
  caclulateSumm(n?) {
    console.log();

    this.total_order = 0;
    this.orders.map((item) => {
      this.total_order += item.cost;
      //this.currencyCode = item.currency;
      //console.log(this.total_order);
    });
    return this.total_order.toFixed(2) + ' ' + n;
  }

  setPage(pageInfo) {
    console.log(pageInfo, this.projectId);
    console.log('load api list');

    let loader = this.loaderCtrl.create({
      cssClass: 'loading-in-content',
      message: '',
      duration: 9000,
    }).then((response) => {
      //response.present();
    });

    this.page.pageNumber = pageInfo.offset
    // set the loading flag, which serves two purposes:
    // 1) it prevents the same page from being loaded twice
    // 2) it enables display of the loading indicator
    this.isLoading = 1;
    /* 
        const index = this.columns.findIndex(item => item.name === "Code");
        console.log(index)
    
    
        if (this.page_number <= 1 && index == -1)
          this.columns.splice(1, 0, { prop: 'code', name: 'Code', flexGrow: 2, minWidth: 150, maxWidth: 150, cellClass: 'td-status', headerClass: 'th-status' })
     */

    let url = this.concateQuery()

    this.api.order_categories_list(url)
      .subscribe(data => {
        console.log(data);
        this.order_categories = data.results.filter((item) => item.parent === null);
        console.log(this.order_categories)

        this.page.totalElements = data.total_items;
        this.page.totalPages = this.page.totalElements / this.page.size;
        this.rows = this.order_categories
        this.rows = [...this.rows]

        this.loaderCtrl?.dismiss()

        this.isLoading = 0;
      })


  }




  getOrdersListById(url) {

    console.log(url);

    if (url && url != '') {
      url = this.concateQuery()
    }
    //this.isLoading++;
    //this.isLoading++
    let loader = this.loaderCtrl.create({
      message: '',
      duration: 13000
    }).then((response) => {
      response.present();
    });

    return
    console.log('load api list by project id');
    this.api.getOrdersByProjectId(this.projectId, url).subscribe(data => {
      this.total_page = data.num_pages
      this.pageNumber++
      const rows = [...this.rows, ...data.results];
      this.rows = rows;
      //this.isLoading--;
      this.loaderCtrl.dismiss()
    });
  }

  getOrderCode(code) {

    let codes = code.split('.')
    let string = ''
    for (var i = 5; i < codes.length; i++) {
      if (i != 5)
        string += '.' + codes[i]
      else
        string += codes[i]
    }
    return string;
  }


  methodAction(action) {

    try {
      this[action]()
    } catch {
      console.log('not call in parent component', action);
      this.emitterOpenNewOrder.emit(action)
    }
  }


  dataChangedReaded(newObj) {
    // here comes the object as parameter
    console.log('dataChangedReaded', newObj);
    //this.activeStatusOrder = true
    this.updateFilterItem('status', newObj)

    this.setPage({ offset: 0 })

  }

  resetSort(event) {
    let sorted
    console.log('EVENT::resetSort', event);
    event.stopPropagation()
    event.preventDefault()

    this.stringStatusOrderFilter = ''
    this.activeStatusOrder = false

    this.updateFilterItem('status', '')

    this.setPage({ offset: 0 })

    console.log('EVENT::resetSort::End');

  }


  updateFilterItem(filter, data) {
    this.filterItems = JSON.parse(localStorage.getItem('filter_orders'))
    let foundIndex

    foundIndex = this.filterItems.map(e => e.search_by).indexOf(filter);
    if (foundIndex == -1) {
      this.filterItems.push({ search_by: filter, search_data: data });
    } else {
      this.filterItems[foundIndex] = { search_by: filter, search_data: data };

    }

    localStorage.setItem('filter_orders', JSON.stringify(this.filterItems))
    console.log('updateFilterItem', this.filterItems);


    this.filterItems.map(e => {
      if (e.search_data != '') {
        switch (e.search_by) {
          case 'status':
            this.activeStatusOrder = true
            this.stringStatusOrderFilter = e.search_data
            break;


          default:
            break;
        }
      } else if (e.search_data == '') {
        switch (e.search_by) {
          case 'status':
            this.activeStatusOrder = false
            this.stringStatusOrderFilter = e.search_data
            break;


          default:
            break;
        }
      }
    })



  }

  onActivateCategory(event) {
    console.log(event)
    //this.openOrderModal(event.row)
    this.clickedCategory.emit(event);

    /* this.submenuService.component.next(component.code) */
  }
}




