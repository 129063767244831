import { Component, Input, OnInit } from '@angular/core';
import { ECharts, EChartsOption } from 'echarts';
import { ThemeService } from 'src/app/services/theme/theme.service';

@Component({
  selector: 'app-pie',
  templateUrl: './pie.component.html',
  styleUrls: ['./pie.component.scss'],
})
export class PieComponent implements OnInit {

  @Input() items
  options: EChartsOption;
  echartsInstance: ECharts;
  loadingGraph = true;
  theme: string = 'cpj'
  loadingOpts
  dark: any = true

  constructor(
    private themeDetection: ThemeService
  ) {
    this.loadingOpts = {
      text: 'loading',
      color: 'rgb(0, 145, 147)',
      textColor: !this.dark ? 'rgba(18,18,18,0.9)' : 'rgba(255,255,255,0.9)',
      maskColor: this.dark ? 'rgba(18,18,18,0.9)' : 'rgba(255,255,255,0.9)',
      zlevel: 0
    }

    this.dark = this.themeDetection.observStatus()


    this.themeDetection.dark.subscribe(e => {
      console.log(e);

      if (!e)
        return

      this.dark = e
      this.loadingOpts = {
        text: 'loading',
        color: 'rgb(0, 145, 147)',
        textColor: !this.dark ? 'rgba(18,18,18,0.9)' : 'rgba(255,255,255,0.9)',
        maskColor: this.dark ? 'rgba(18,18,18,0.9)' : 'rgba(255,255,255,0.9)',
        zlevel: 0
      }
    })
    this.loadingGraph = true;

  }

  ngOnInit() {
    console.log('start graph', this.items);

    setTimeout(() => {
      console.log('enter', this.items);

      this.theme = 'cpj'
      // this.options = this.items

      this.options = {
        /* title: {
          text: 'Referer of a Website',
          subtext: '',
          left: 'center'
        }, */
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left'
        },
        series: [
          {
            /* name: 'Access From', */
            type: 'pie',
            radius: '80%',
            data: [
              { value: 1048, name: 'Ordered' },
              { value: 735, name: 'Remaining' }
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
 
      this.loadingGraph = false;

    }, 250)
  }


  onChartInit(ec) {
    this.echartsInstance = ec;
    console.log(ec);

  }

  onChartEvent(e, ele) {
    console.log(e, ele);
  }

  onChartLegend(e, ele) {
    console.log(e, ele);
  }
  onChartScroll(e, ele) {
    console.log(e, ele);
  }
}
