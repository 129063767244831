import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, FormGroupDirective, ValidationErrors } from '@angular/forms';

interface AllValidationErrors {
  controlName: string;
  errorName: string;
  errorValue: any;
}

@Component({
  selector: 'app-error-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.scss'],
})
export class ErrorFormsComponent implements OnInit {

  @Input() form: any;
  @Input() controls: any;
  @Input() formRef: FormGroupDirective;
  @Input() messages: Array<any>;

  errors: any[];

  constructor(
  ) {
    this.errors = [];
    this.messages = [];
  }

  ngOnInit() {
    console.log('init forms');

    this.form.valueChanges.subscribe((e) => {
      console.log(this.form);

      this.errors = [];
      console.log(this.calculateErrors(this.controls))
    });

    this.calculateErrors(this.form);
  }

  calculateErrors(controls) {
    if (!controls)
      return ''

    Object.keys(controls).forEach(field => {
      console.log(field);

      const control = controls[field];

      if (control instanceof FormControl) {
        console.log('errors', control);
        this.errors = this.errors.concat(control)
        return
      }

      console.log(this.errors);

      let _array = control.errors
      if (_array !== null /* || this.errors != undefined */) {
        Object.keys(_array).map(keyError => {
          console.log(keyError);

          this.errors.push({
            controlName: field,
            errorName: keyError,
            //errorValue: controlErrors[keyError]
          });
        });
      }
    });

    // This removes duplicates
    this.errors = this.errors.filter((error, index, self) => self.findIndex(t => {
      return t.controlName === error.controlName && t.errorName === error.errorName;
    }) === index);
    return this.errors;
  }


  getErrorMessage(error) {
    console.log(error);

    switch (error.errorName) {
      case 'required':
      default:
        return 'unknown error ' + error.errorName;
    }
  }
}