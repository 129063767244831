/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable object-shorthand */
/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/semi */

import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { BehaviorSubject, Observable, Subject, forkJoin, of, throwError, from } from 'rxjs';
import { catchError, distinctUntilChanged, map, tap, timeout } from 'rxjs/operators';
//import { MqttDataService } from '../mqtt/mqtt.service';
//import { IMqttMessage, MqttService } from 'ngx-mqtt';

import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ToastController } from '@ionic/angular';
import { NetworkService, ConnectionStatus } from '../network/network.service';
//import { Storage } from '@ionic/storage';
import { OfflineManagerService } from '../network/offline-manager.service';

//import { AnalyticsService } from '../analytics/analytics.service';
//import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';

//import environment from '../../../config.json';


const API_STORAGE_KEY = 'authService';
const TOKEN_KEY = 'access-token';
const REFRESH_TOKEN = 'refresh-token';
const PROFILE = 'auth-profile';


@Injectable({
  providedIn: 'root'
})
export class TabsService {


  private tab: any;
  public roleAs: string;
  public environment: any;

  public currentTabSubject = new Subject<any>();


  constructor(
    private storage: Storage,
    private plt: Platform,
    private http: HttpClient,
    public toastController: ToastController,
    private networkService: NetworkService,
    private offlineManager: OfflineManagerService,
    //private analyticsService: AnalyticsService
  ) {


  }

  getTab() {
    return this.currentTabSubject.asObservable()
  }

  /**
   * @description Aggiorna e tiene in memoria l'utente
   * @param data Corrisponde obj user
   */
  publishProfile(data: any) {
    //console.log('publishProfile', data);
    this.currentTabSubject.next(data);
  }


}

