import { CustomersService } from './../../services/customers/customers.service';
import { DatatableComponent, ColumnMode } from '@swimlane/ngx-datatable';
import { StoresService } from './../../services/stores/stores.service';

import { AfterContentChecked, ChangeDetectorRef, Component, OnChanges, OnInit, SimpleChanges, ViewChild, Input } from '@angular/core';
import { NavController, ModalController, IonNav, IonRouterOutlet, IonInfiniteScroll, IonSearchbar, IonContent, ActionSheetController, Platform } from '@ionic/angular';

import { StoresUpdatePage } from '../stores-update/stores-update.page';
import { animate, query, sequence, stagger, state, style, transition, trigger } from '@angular/animations';

import { NewStoresComponent } from '../new-stores/new-stores.component';
import { StoresDetailsPage } from '../stores-details/stores-details.page';
import { TabsService } from 'src/app/services/tabs/tabs.service';
import { VendorsService } from 'src/app/services/vendors/vendors.service';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { TitleAppService } from 'src/app/services/utils/title-app/title-app.service';
import {filter} from "rxjs/operators";
import * as XLSX from 'xlsx';
import { RoutingStateService } from 'src/app/services/routing-state/routing-state.service';
import { Router } from '@angular/router';





const listAnimation = trigger('listAnimation', [
  transition('* <=> *', [
    query(':enter',
      [style({ opacity: 0 }), stagger('180ms', animate('300ms ease-out', style({ opacity: 1 })))],
      { optional: true }
    ),
    query(':leave',
      animate('200ms', style({ opacity: 0 })),
      { optional: true }
    )
  ])
]);

class DateOnlyPipe extends DatePipe {
  public override transform(value): any {
    return super.transform(value, 'dd-MM-y');
  }
}

interface PageInfo {
  offset: number;
  pageSize: number;
  limit: number;
  count: number;
}

export class Page {
  // The number of elements in the page
  size: number = 0;
  // The total number of elements
  totalElements: number = 0;
  // The total number of pages
  totalPages: number = 0;
  // The current page number
  pageNumber: number = 0;
}
@Component({
  selector: 'app-store',
  templateUrl: './stores-list.page.html',
  styleUrls: ['./stores-list.page.scss'],
  // ✨ New! Defining the animation
  animations: [listAnimation]
})
export class StoresListPage {



  public data: any; // Data;
  public columns: any;
  public rows: any;

  rowIsNotExpanded = true

  editing = {};

  selected = [];

  funder = [];
  calculated = [];
  pending = [];
  ColumnMode: ColumnMode
  expanded: any = {};
  timeout: any;

  @ViewChild('myTable') table: DatatableComponent;
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  @ViewChild(IonContent) content: IonContent;
  @ViewChild(IonSearchbar) myInput: IonSearchbar;


  fab: boolean = false;
  customAlertOptions: any = {
    header: 'List groups',
    //subHeader: 'Select group for user',
    message: 'Select group for user',
    translucent: true
  };
  store: any = []
  _storess: any = []
  services: any = []
  searching = false
  res: any = []

  total_page = 0;
  page_number = 1;
  page_limit = 30;
  company: any = {};

  filterTerm: string;
  enableSearch = false;
  tablestyle = 'bootstrap';


  isSearch: boolean = false;
  totalElements: number;
  pageNumber: number;
  cache: any = {};


  navMenu = [
    { action: 'openNewStoresModal2', label: 'New', icon: 'receipt-outline' },
    { action: 'csvExport', label: 'Export', icon: 'download-outline' },
    //{ action: 'toggleSearch', label: 'Search' },
    //{ action: '', label: 'Export' }
  ]

  constructor(
    private http: HttpClient,
    private cd: ChangeDetectorRef,
    private navCtrl: NavController,
    private api: StoresService,
    private modalController: ModalController,
    //private nav: IonNav,
    private routerOutlet: IonRouterOutlet,
    private tabService: TabsService,
    private vendorApi: VendorsService,
    private customerApi: CustomersService,
    private titleAppService: TitleAppService,
    private actionSheetController: ActionSheetController,
    private platform: Platform,
    private router: Router,
    private routingState: RoutingStateService
  ) {

    /*  this.http.get<any>('../../assets/movies.json')
       .subscribe((res) => {
         console.log(res)
         this.rows = res.movies;
       }); */
    /*  this.tabService.currentTabSubject.subscribe(e => {
       console.log(e);
       if (e.name == 'Store' || e.id == 4) {
         this.total_page = 0;
         this.page_number = 1;
         //this.myInput.value = "";
         this.store = [];
         this.getStores(false, '')
       }
   
     }) */
    this.pageNumber = 0;
  }


  ionViewWillEnter() {
    this.total_page = 0;
    this.page_number = 1;
    //this.myInput.value = "";
    this.titleAppService.title.next('Stores')

    this.columns = [];
    this.store = [];
    this.getStores(false, "")

  }


  getStores(isFirstLoad, event) {

    this.isSearch = false;
    this.fab = true

    let url = '?page=' + this.page_number + '&items=' + this.page_limit;

    this.api.getStores(url).subscribe(data => {

      //this.total_page = data.num_pages;
      if (data.num_pages >= (this.page_number)) {

        for (let i = 0; i < data.results.length; i++) {

          let page_ = 1;
          let url = '?page=' + page_ + '&items=' + 25;
          console.log(data.results[i]);

          this.store.push(data.results[i]);
          this.store = [...this.store];
          //this.store[i].reference_name = (data.results[i].reference) ? data.results[i].reference.name : '';


        }


        console.log(this.store)

        if (isFirstLoad)
          event.target.complete();

        this.page_number++;

      } else {
        console.log('last page');
        event.target ? event.target.complete() : null;

      }

    }, error => {
      this.total_page = 0;
      console.log(error);
    })

    // Store Types - Store,Services
    /* this.api.getStoresTypes(url)
      .subscribe(data => {
        console.log(data.results);
      })
  
    // Store Categories
    this.api.getStoresCategories(url)
      .subscribe(data => {
        console.log(data.results);
      })
  
    // Store Brands
    this.api.getStoresBrands(url)
      .subscribe(data => {
        console.log(data.results);
      }) */







    this.columns = [
      { prop: 'id', name: 'ID', frozenLeft: true, width: 45, minWidth: 45, maxWidth: 45, cellClass: 'td-id', headerClass: 'th-id' },
      { prop: 'name', width: 190, minWidth: 190, maxWidth: 300, cellClass: 'td-status', headerClass: 'th-status' },
      { prop: 'description', width: 245, minWidth: 245,  cellClass: 'td-desc', headerClass: 'th-desc' }

    ]


  }

  onGetRow1Class = (row: any) => {
    // id 3 is Delete for Status Order
    if (row.status === 'WAIT') {
      return 'wait';
    } else if (row.status === 'PREP') {
      return 'prep';
    } else if (row.status === 'EXE') {
      return 'exe';
    } else if (row.status === 'END') {
      return 'end';
    }
    else
      return ''
  }


  goTo(item) {
    //this.navCtrl.navigateForward(['/members/vendor-details/', item.id]);    
    //nav: this.nav(StoresDetailsPage, { id: item.id }, { animated: true, direction: 'forward' });

  }


  doInfinite(event, search?) {
    console.log(event, search);
    this.getStores(true, event);
    if (this.isSearch) {
      //this.getStores(true, event, 0, search.value)
    } else {

    }
  }


  doRefresh(event) {
    console.log('Begin async operation');

    setTimeout(() => {
      console.log('Async operation has ended');

      this.total_page = 0;
      this.page_number = 1;
      ////this.myInput.value = "";
      this.store = [];
      this.columns = [];
      this.getStores(false, '')

      event.target.complete();
    }, 1000);
  }

  fabCallBack(action) {
    console.log;

    if (action) {
      this[action]()
    } else {
      console.log('not call in component, run callback', 'list');
    }
  }

  methodAction(action) {
    try {
      this[action]()
    } catch {
      console.log('not call in parent component');
    }
    //console.log(action)
  }




  getGroupRowHeight(group, rowHeight) {
    let style = {};

    style = {
      height: group.length * 40 + 'px',
      width: '100%'
    };

    return style;
  }



  updateValue(event, cell, rowIndex) {
    this.editing[rowIndex + '-' + cell] = false;
    this.store[rowIndex][cell] = event.target.value;
    this.store = [...this.store];
  }
  toggleExpandGroup(group, index) {
    console.log('Toggled Expand Group!', group, index);
    this.table.groupHeader.collapseAllGroups()
    this.table.groupHeader.toggleExpandGroup(group);

  }

  onDetailToggle(event) {
    console.log('Detail Toggled', event);
  }

  toggleExpandRow(row) {
    console.log('Toggled Expand Row!', row);
    this.table.rowDetail.collapseAllRows();
    this.table.rowDetail.toggleExpandRow(row);
  }
  setPage(pageInfo: PageInfo) {
    // Current page number is determined by last call to setPage
    // This is the page the UI is currently displaying
    // The current page is based on the UI pagesize and scroll position
    // Pagesize can change depending on browser size
    this.pageNumber = pageInfo.offset;

    // Calculate row offset in the UI using pageInfo
    // This is the scroll position in rows
    const rowOffset = pageInfo.offset * pageInfo.pageSize;

    // When calling the server, we keep page size fixed
    // This should be the max UI pagesize or larger
    // This is not necessary but helps simplify caching since the UI page size can change
    const page = new Page();
    page.size = 20;
    page.pageNumber = Math.floor(rowOffset / page.size);

    // We keep a index of server loaded pages so we don't load same data twice
    // This is based on the server page not the UI
    if (this.cache[page.pageNumber]) return;
    this.cache[page.pageNumber] = true;

    // Counter of pending API calls


    /*  this.serverResultsService.getResults(page).subscribe(pagedData => {
       // Update total count
       this.totalElements = pagedData.page.totalElements;
   
       // Create array to store data if missing
       // The array should have the correct number of with "holes" for missing data
       if (!this.rows) {
         this.rows = new Array<CorporateEmployee>(this.totalElements || 0);
       }
   
       // Calc starting row offset
       // This is the position to insert the new data
       const start = pagedData.page.pageNumber * pagedData.page.size;
   
       // Copy existing data
       const rows = [...this.rows];
   
       // Insert new rows into correct position
       rows.splice(start, pagedData.page.size, ...pagedData.data);
   
       // Set rows to our new rows for display
       this.rows = rows;
   
       // Decrement the counter of pending API calls
       this.isLoading--;
     }); */
  }

  onPage(event) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      console.log('paged!', event);
    }, 100);
  }

  onSelect({ selected }) {
    console.log('Select Event', selected, this.selected);
  }

  onActivate(event) {
    if (event.type == 'click') {

      this.openStoresDetails(event.row)
    }
  }

  setExpandStatus(expanded: any): void {
    console.log('setExpandStatus Event', expanded);
  }



  async openNewStoresModal2() {
    const checkPropmt = await this.modalController.getTop();
    if (checkPropmt) {
      this.modalController.dismiss();
    }

    const modal = await this.modalController.create({
      component: NewStoresComponent,
      canDismiss: true,
      keyboardClose: true,
      breakpoints: [0, 0.2, 0.3, 0.5, 0.8],
      initialBreakpoint: this.platform.is('desktop') ? 0.5 : 0.7,
      componentProps: {
        //item: item,
        //parent: { folders: [{ folder: { id: this.id } }] },
        //data: infos
      }
      //presentingElement: this.routerOutlet.nativeEl,
    });

    modal.onWillDismiss().then((e) => {
      if (e.data && e.data.status != -1) {
        //this.loadProjects(this.currentSegment);
      }
    });


    //this.ionLoaderService.dismissLoader();
    return modal.present().finally(() => {
    })
  }


  async openNewStoresModal() {
    console.log('clickModal Open');

    const modal = await this.modalController.create({
      component: NewStoresComponent,

      canDismiss: true,
      mode: "md",
      componentProps: {
        title: 'New Store',
      }

    })


    modal.onWillDismiss().then((e) => {
      console.log(e);

      if (e.data && e.data.status != -1) {
        this.total_page = 0;
        this.page_number = 1;
        //this.myInput.value = "";
        this.store = [];
        this.columns = [];
        this.getStores(false, '')
      }
    });
    return await modal.present();
  }



  async openStoresDetails(item) {
    //navPUSH

    ////nav: this.nav(StoresDetailsPage, {  store: item }, { animated: true, direction: 'forward' });
    const modal = await this.modalController.create({
      component: StoresDetailsPage,
      canDismiss: true,
      mode: 'md',
      cssClass: 'fullscreen',
      componentProps: {
        overlayController: this.modalController,
        //nav: this.nav,
        store: item,
        title: `( Store: ${item.id}`

        ////nav: this.nav  // Pass nav for IonNav from modal if necessary
      },
      //presentingElement: this.routerOutlet.nativeEl
    })


    modal.onWillDismiss().then((e) => {
      console.log(e);

      if (e.data) {
        this.total_page = 0;
        this.page_number = 1;
        //this.myInput.value = "";
        this.store = [];
        this.columns = [];
        this.getStores(false, '')
      }
    });
    return await modal.present();
  }

  toggleSearch() {
    this.enableSearch = !this.enableSearch;
    if (this.enableSearch) {
      setTimeout(() => {
        this.myInput.setFocus()
      }, 150);

    }
  }

  csvExport(): void {
    const wb = XLSX.utils.book_new();
    const wsName = 'Sheet1';
    console.log(this.store)
    const worksheetData= []

    this.store.forEach((store) => {
      store.products.forEach((product) => {
        const productDetail = product.product_detail;
        worksheetData.push({
          'Magazzino': store.name,
          'Prodotto': productDetail.name,
          'Codice prodotto': productDetail.product.code,
          'Quantità': product.quantity,
          'Colore': productDetail.fvalue_6?.name || '',
          'Dimensioni': productDetail.fvalue_3?.name || '',
          'Lunghezza': productDetail.fvalue_4?.name || '',
          'Marca': productDetail.fvalue_1?.name || '',
          'Peso': productDetail.fvalue_5?.name || '',
          'Descrizione/Note': productDetail.description,
        });
      });
    });

    console.log(worksheetData)

    const ws = XLSX.utils.json_to_sheet(worksheetData);

    // Getting the reference of the first row (header row)
  const range = XLSX.utils.decode_range(ws['!ref']);

  for(let C = range.s.c; C <= range.e.c; ++C) {
    // this is the header row
    const address = XLSX.utils.encode_col(C) + "1"; 
    if(!ws[address]) continue;
    ws[address].s = {
      font: {
        bold: true
      }
    };
  }

    
    XLSX.utils.book_append_sheet(wb, ws, wsName);

    XLSX.writeFile(wb, 'Export magazzino.xlsx');
  }

  
  goBack(){
    this.router.navigate(['/'+ this.routingState.navigateBack()])
  }

}
