import { Component, Input, OnInit } from '@angular/core';
import { ThemeService } from 'src/app/services/theme/theme.service';


import { ECharts, EChartsOption, format } from 'echarts';
import '../../../../assets/charts_themes/cpj_charts';
import '../../../../assets/charts_themes/fgb_charts';
import * as echarts from 'echarts';

@Component({
  selector: 'app-bar-stack',
  templateUrl: './bar-stack.component.html',
  styleUrls: ['./bar-stack.component.scss'],
})
export class BarStackComponent implements OnInit {

  @Input() items
  options: EChartsOption;
  echartsInstance: ECharts;
  loadingGraph = true;
  theme: string = 'cpj'
  loadingOpts
  dark: any = true

  constructor(
    private themeDetection: ThemeService
  ) {
    this.loadingOpts = {
      text: 'loading',
      color: 'rgb(0, 145, 147)',
      textColor: !this.dark ? 'rgba(18,18,18,0.9)' : 'rgba(255,255,255,0.9)',
      maskColor: this.dark ? 'rgba(18,18,18,0.9)' : 'rgba(255,255,255,0.9)',
      zlevel: 0
    }

    this.dark = this.themeDetection.observStatus()


    this.themeDetection.dark.subscribe(e => {
      console.log(e);

      if (!e)
        return

      this.dark = e
      this.loadingOpts = {
        text: 'loading',
        color: 'rgb(0, 145, 147)',
        textColor: !this.dark ? 'rgba(18,18,18,0.9)' : 'rgba(255,255,255,0.9)',
        maskColor: this.dark ? 'rgba(18,18,18,0.9)' : 'rgba(255,255,255,0.9)',
        zlevel: 0
      }
    })
    this.loadingGraph = true;

  }

  ngOnInit() {

    console.log('start graph');
    setTimeout(() => {
      console.log(this.items);

      this.theme = 'cpj';


      this.options = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: ['ORDERS', 'INVOICES']
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series:   [{
          name: 'PREV',
          type: 'bar',
          stack: 'Ad',
          emphasis: {
            focus: 'series'
          },
          data: [this.items.order[0],this.items.invoice[0]]
        },
        {
          name: 'DEF',
          type: 'bar',
          stack: 'Ad',
          emphasis: {
            focus: 'series'
          },
          data: [this.items.order[1],this.items.invoice[1]]
        }]
      };

      this.loadingGraph = false;

    }, 650)
  }


  onChartInit(ec) {
    this.echartsInstance = ec;
    console.log(ec);

  }

  onChartEvent(e, ele) {
    console.log(e, ele);
  }

  onChartLegend(e, ele) {
    console.log(e, ele);
  }
  onChartScroll(e, ele) {
    console.log(e, ele);
  }
}
