import { Injectable } from '@angular/core';
import { HammerGestureConfig } from '@angular/platform-browser';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})

export class IonLoaderService {

  constructor(public loadingController: LoadingController) { }


  // Simple loader
  startLoader() {
    this.loadingController.create({
      message: 'Loading...',
      duration: 200
    }).then((response) => {
      response.present();
      response.onDidDismiss().then((dis) => {
        //console.log('Loading dismissed! after 2 Seconds', dis);
      });
    });
  }

  // Simple loader
  simpleLoader() {
    this.loadingController.create({
      message: 'Loading...',
      cssClass:'custom-loader-class',
    }).then((response) => {
      response.present();
      response.onDidDismiss().then((dis) => {
        //console.log('Loading dismissed! after 2 Seconds', dis);
      });
    });
  }

  // Dismiss loader
  dismissLoader() {
    this.loadingController.dismiss().then((response) => {
      //console.log('Loader closed!', response);
    }).catch((err) => {
      //console.log('Error occured : ', err);
    });
  }

  // Auto hide show loader
  autoLoader() {
    this.loadingController.create({
      message: 'Loading...',
      duration: 2000
    }).then((response) => {
      response.present();
      response.onDidDismiss().then((response) => {
        //console.log('Loader dismissed', response);
      });
    });
  }   

  // Custom style + hide on tap loader
  customLoader(time) {
    this.loadingController.create({
      message: 'Loader with custom style',
      duration: time,
      cssClass:'loader-css-class',
      backdropDismiss:true
    }).then((res) => {
      res.present();
    });
  }   
  

}

