import { FormGroup, FormGroupDirective } from '@angular/forms';
import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';

@Component({
  selector: 'app-add-item-select-option',
  templateUrl: './add-item-select-option.component.html',
  styleUrls: ['./add-item-select-option.component.scss'],
})
export class AddItemSelectOptionComponent {

  @ViewChild('form') form: FormGroupDirective;
  @Input() formGroup: any;  
  @Input() submitFunction: string;
  @Input() name: any;
  @Input() rowitems: any;
  @Input() submitButtonParam: any ;
  @Output() submit_out: EventEmitter<any> = new EventEmitter();
  

  constructor() { 
  } 
  
  
  submit(event){         
    this.form.onSubmit(undefined); 
  }

  submit_function(event){
    console.log(event);
    let obj = {
      'function' : this.submitButtonParam.function, 
      'form' : event
    }
    this.submit_out.emit(obj)
    
  }

}
