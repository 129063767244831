// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `lb_default {
  font-size: 14px;
  line-height: 1.5;
  color: grey;
}`, "",{"version":3,"sources":["webpack://./src/app/products/product-update/product-update.page.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,gBAAA;EACA,WAAA;AACJ","sourcesContent":["lb_default{\n    font-size: 14px;\n    line-height: 1.5;\n    color: grey;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
