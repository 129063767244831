import { StoresListPage } from './../../stores/stores-list/stores-list.page';
import { DdtListPage } from './../../trasport_documents/ddt-list/ddt-list.page';
import { NewDdtComponent } from './../../trasport_documents/new-ddt/new-ddt.component';

import { AfterContentChecked, ChangeDetectorRef, Component, Directive, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { NavController, ModalController, IonNav, IonRouterOutlet, IonInfiniteScroll, IonSearchbar, IonContent } from '@ionic/angular';
import { ProductsService } from 'src/app/services/products/products.service';
import { ProductUpdatePage } from '../product-update/product-update.page';
import { animate, query, sequence, stagger, state, style, transition, trigger } from '@angular/animations';

import { NewProductComponent } from '../new-product/new-product.component';
import { ProductDetailsPage } from '../product-details/product-details.page';
import { TabsService } from 'src/app/services/tabs/tabs.service';
import { VendorsService } from 'src/app/services/vendors/vendors.service';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { delay } from 'rxjs/operators';
import { TitleAppService } from 'src/app/services/utils/title-app/title-app.service';
import { Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { RoutingStateService } from 'src/app/services/routing-state/routing-state.service';



const listAnimation = trigger('listAnimation', [
  transition('* <=> *', [
    query(':enter',
      [style({ opacity: 0 }), stagger('180ms', animate('300ms ease-out', style({ opacity: 1 })))],
      { optional: true }
    ),
    query(':leave',
      animate('200ms', style({ opacity: 0 })),
      { optional: true }
    )
  ])
]);

interface PageInfo {
  offset: number;
  pageSize: number;
  limit: number;
  count: number;
}

export class Page {
  // The number of elements in the page
  size: number = 0;
  // The total number of elements
  totalElements: number = 0;
  // The total number of pages
  totalPages: number = 0;
  // The current page number
  pageNumber: number = 0;
}

@Component({
  selector: 'app-products',
  templateUrl: './products-list.page.html',
  styleUrls: ['./products-list.page.scss'],
  // ✨ New! Defining the animation
  animations: [listAnimation]
})
export class ProductsListPage {
  public data: any; // Data;
  public columns: any;
  public rows: any;

  rowIsNotExpanded = true

  editing = {};

  selected = [];

  selectSegment = 0
  enableSearch = false;
  funder = [];
  calculated = [];
  pending = [];
  ColumnMode: ColumnMode
  expanded: any = {};
  timeout: any;

  @Input() dataProps: string;
  @ViewChild('myTable') table: DatatableComponent;
  @ViewChild('myTable2') table2: DatatableComponent;
  @ViewChild('myTable3') table3: DatatableComponent;
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  @ViewChild(IonContent) content: IonContent;
  @ViewChild(IonSearchbar) myInput: IonSearchbar;
  @ViewChild("element", { read: false }) element: ElementRef;


  fab: boolean = false;
  customAlertOptions: any = {
    header: 'List groups',
    //subHeader: 'Select group for user',
    message: 'Select group for user',
    translucent: true
  };

  products: any = []
  services: any = []
  priceLists: any = []

  items: any = []

  searching = false
  res: any = []

  total_page = 0;
  page_number = 1;
  page_limit = 30;

  isSearch: boolean = false;

  totalElements: number;
  pageNumber: number;

  cache: any = {};
  btnNew = ''
  isLoading = 0;

 /* navMenu = [
    { action: 'DdtListIn', label: 'DDT IN', icon: 'storefront-outline' },
    { action: 'DdtListOut', label: 'DDT OUT', icon: 'storefront-outline' },
    { action: 'openNewProductModal', label: 'New', icon: 'receipt-outline' },
    { action: 'toggleSearch', label: 'Search' },
  ]*/


  constructor(
    private cd: ChangeDetectorRef,
    private navCtrl: NavController,
    private api: ProductsService,
    private modalController: ModalController,
    //private nav: IonNav,
    private routerOutlet: IonRouterOutlet,
    private tabService: TabsService,
    private titleAppService: TitleAppService,
    private router: Router,
    private routingState: RoutingStateService
  ) {

    /*  this.http.get<any>('../../assets/movies.json')
       .subscribe((res) => {
         console.log(res)
         this.rows = res.movies;
       }); */
    /*  this.tabService.currentTabSubject.subscribe(e => {
       console.log(e);
       if (e.name == 'Product' || e.id == 4) {
         this.total_page = 0;
         this.page_number = 1;
         this.myInput.value = "";
         this.items = [];
         this.getProducts(false, '')
       }
 
     }) */
    this.pageNumber = 0;

  }

  setTitle() {
    /* switch (this.dataProps) {
      case '1':
        this.titleAppService.title.next('Products')
        this.btnNew = 'Product'
        break;
      case '2':
        this.titleAppService.title.next('Servizi')
        this.btnNew = 'Servizio'
        break;
      case '3':
        this.titleAppService.title.next('Listino')
        this.btnNew = 'Listino'
        break;

      default:
        break;
    } */
  }


  ionViewWillEnter() {
    this.total_page = 0;
    this.page_number = 1;
    this.myInput ? this.myInput.value = "" : null;

    this.setTitle()

    this.columns = [];
    this.items = [];
    this.getProducts(false, "")
  }


  getProducts(isFirstLoad, event) {

    this.isSearch = false;
    this.fab = true

    let url = '?page=' + this.page_number + '&items=25' + (this.dataProps ? '&type_id=' + this.dataProps: '');

    this.api.getProductsList(url, '')
      .subscribe(data => {
        console.log(data)

        //this.total_page = data.num_pages;
        if (data.num_pages >= (this.page_number)) {

          this.items = [...data.results];

          console.log(this.items);

          if (isFirstLoad)
            event.target.complete();

          this.page_number++;

        } else {
          console.log('last page');
          event.target ? event.target.complete() : null;

        }

      }, error => {
        this.total_page = 0;
        console.log(error);
      })

    this.columns = [
      { prop: 'id', name: 'ID', frozenLeft: true, maxWidth: 45, cellClass: 'td-id', headerClass: 'th-id' },
      /* { prop: 'qty', minWdth: 50, maxWidth: 50 },
      { prop: 'default_price', minWidth: 250, maxWidth: 250 }, */
      { prop: 'code', minWidth: 250, maxWidth: 250 },
      { prop: 'name', minWidth: 250, maxWidth: 250 },
      { prop: 'description', minWidth: 350, maxWidth: 350 },
      { prop: 'product.category.name', name: 'Category', minWidth: 250, maxWidth: 250 },

      //{ prop: 'created', minWidth: 250, maxWidth: 250 },
      //{ prop: 'updated', minWidth: 250, maxWidth: 250 }
    ]
  }


/*   goTo(item) {

    //this.navCtrl.navigateForward(['/members/vendor-details/', item.id]);    
    //nav: this.nav(ProductDetailsPage, { id: item.id }, { animated: true, direction: 'forward' });
  } */

  navToStores(item) {
    //this.navCtrl.navigateForward(['/members/vendor-details/', item.id]);    
    //nav: this.nav(StoresListPage, { animated: true, direction: 'forward' });
  }


  doInfinite(event, search?) {
    console.log(event, search);
    this.getProducts(true, event);
    if (this.isSearch) {
      //this.getProducts(true, event, 0, search.value)
    } else {

    }
  }


  doRefresh(event) {
    console.log('Begin async operation');

    setTimeout(() => {
      console.log('Async operation has ended');

      this.total_page = 0;
      this.page_number = 1;
      this.myInput.value = "";
      this.items = [];
      this.columns = [];
      this.getProducts(false, '')

      event.target.complete();
    }, 1000);
  }

  fabCallBack(action) {
    console.log(action);

    if (action) {
      this[action]()
    } else {
      console.log('not call in component, run callback', 'list');
    }
  }

  methodAction(action) {
    try {
      this[action]()
    } catch {
      console.log('not call in parent component');
    }
    //console.log(action)
  }


  getGroupRowHeight(group, rowHeight) {
    let style = {};

    style = {
      height: group.length * 40 + 'px',
      width: '100%'
    };

    return style;
  }


  updateValue(event, cell, rowIndex) {
    this.editing[rowIndex + '-' + cell] = false;
    this.items[rowIndex][cell] = event.target.value;
    this.items = [...this.items];
  }
  toggleExpandGroup(group, index) {
    console.log('Toggled Expand Group!', group, index);
    this.table.groupHeader.collapseAllGroups()
    this.table.groupHeader.toggleExpandGroup(group);

  }

  onDetailToggle(event) {
    console.log('Detail Toggled', event);
  }

  toggleExpandRow(row) {
    console.log('Toggled Expand Row!', row);
    this.table.rowDetail.collapseAllRows();
    this.table.rowDetail.toggleExpandRow(row);
  }

  setPage(pageInfo: PageInfo) {
    // Current page number is determined by last call to setPage
    // This is the page the UI is currently displaying
    // The current page is based on the UI pagesize and scroll position
    // Pagesize can change depending on browser size
    this.pageNumber = pageInfo.offset;

    // Calculate row offset in the UI using pageInfo
    // This is the scroll position in rows
    const rowOffset = pageInfo.offset * pageInfo.pageSize;

    // When calling the server, we keep page size fixed
    // This should be the max UI pagesize or larger
    // This is not necessary but helps simplify caching since the UI page size can change
    const page = new Page();
    page.size = 20;
    page.pageNumber = Math.floor(rowOffset / page.size);

    // We keep a index of server loaded pages so we don't load same data twice
    // This is based on the server page not the UI
    if (this.cache[page.pageNumber]) return;
    this.cache[page.pageNumber] = true;

    // Counter of pending API calls
    this.isLoading++;

    /*  this.serverResultsService.getResults(page).subscribe(pagedData => {
       // Update total count
       this.totalElements = pagedData.page.totalElements;
 
       // Create array to store data if missing
       // The array should have the correct number of with "holes" for missing data
       if (!this.rows) {
         this.rows = new Array<CorporateEmployee>(this.totalElements || 0);
       }
 
       // Calc starting row offset
       // This is the position to insert the new data
       const start = pagedData.page.pageNumber * pagedData.page.size;
 
       // Copy existing data
       const rows = [...this.rows];
 
       // Insert new rows into correct position
       rows.splice(start, pagedData.page.size, ...pagedData.data);
 
       // Set rows to our new rows for display
       this.rows = rows;
 
       // Decrement the counter of pending API calls
       this.isLoading--;
     }); */
  }

  onSelect({ selected }) {
    console.log('Select Event', selected, this.selected);
  }

  onActivate($param, event) {
    if (this.table.rowDetail && event.type == 'click') {
      this.table.rowDetail.toggleExpandRow(event.row);
      console.log('Activate Event', event);
      if ($param == 'store') {
        this.goStore();
      }

    }
  }

  setExpandStatus(expanded: any): void {
    console.log('setExpandStatus Event', expanded);
  }

  goStore(ev?) {
    //nav: this.nav(StoresListPage)
  }

  async DdtListIn() {
    //this.myCustomDir.disableBtn(this.element)
    //nav: this.nav(StoresListPage, { type: 'IN' }, { animated: true, direction: 'forward' });
  }

  async DdtListOut() {
    //nav: this.nav(DdtListPage, { type: 'OUT' }, { animated: true, direction: 'forward' });
  }


  async openNewProductModal() {
    const modal = await this.modalController.create({
      component: NewProductComponent,
      //backdropDismiss: false,
      mode: "md",
      cssClass: 'newOrderModalForm',
      componentProps: {
        title: 'New ' + this.btnNew,
        dataProps: this.dataProps,
        parent_type: 'root'
      },
    })


    modal.onWillDismiss().then((e) => {
      console.log(e);
      if (e.data.message == '' || e.data.message.res != 'OK' || e.role == 'backdrop') {
        console.log('no modify');
        return
      } else {
        /*   this.total_page = 0;
          this.page_number = 1;
          this.myInput.value = "";
          this.items = [];
          this.columns = [];
          this.getProducts(false, '') */
        this.items.push(e.data.message.data)
        this.items = [...this.items];

      }
    });
    return await modal.present();
  }


  async openProductModal(item) {

    const modal = await this.modalController.create({
      component: ProductDetailsPage,
      //backdropDismiss: false,
      cssClass: 'fullscreen',
      mode: 'md',
      componentProps: {
        id: item.id,
        title: item.name,
        parent_type: 'root',
        //nav: this.nav  // Pass nav for IonNav from modal if necessary
      },
      //presentingElement: this.routerOutlet.nativeEl
    })


    modal.onWillDismiss().then((e) => {
      console.log(e, this.items);
      if (e.data.message == '' || e.data.message.res != 'OK' || e.role == 'backdrop') {
        console.log('no modify');
        return
      } else {
        /*      
          this.total_page = 0;
          this.page_number = 1;
          this.myInput.value = "";
          this.items = [];
          this.columns = [];
          this.getProducts(false, '')
        */


        let productsIndex = this.items.map((e, i, a) => e.id).indexOf(item.id)
        this.items[productsIndex] = e.data.message.data;

        // if product on response
        this.items = [...this.items];
        console.log(this.items);

        this.table.rowDetail.toggleExpandRow(e.data.message.data);


      }
    });
    return await modal.present();
  }


  onGetRowClass = (row) => {
    //console.log(row);
    // id 3 is Delete for Status Order
    /* if (row.status.id === 3) {
      return 'deleted';
    } else if (row.status.id === 2) {
      return 'request';
    } else if (row.status.id === 1) {
      return 'draft';
    } else if (row.status.id === 4) {
      return 'order';
    } */
  }

  public getRowIndex(row: any): number {
    console.log(this.table.bodyComponent.getRowIndex(row));

    return this.table.bodyComponent.getRowIndex(row); // row being data object passed into the template
  }


  toggleSearch() {
    console.log('toggle search');

    this.enableSearch = !this.enableSearch;
    if (this.enableSearch) {
      setTimeout(() => {
        console.log('toggle search  2', this.myInput);
        this.myInput.setFocus()
      }, 250);

    }
  }

  goBack(){
    this.router.navigate(['/'+ this.routingState.navigateBack()]);
  }

}
