import { ActivatedRoute, Router } from '@angular/router';
import { ProductsService } from 'src/app/services/products/products.service';
import { Platform, AnimationController, IonNav, ModalController } from '@ionic/angular';
import { Component, OnInit, ElementRef, ViewChild, Input } from '@angular/core';
import { Product } from '../models/product.model';
import { NewProductComponent } from '../new-product/new-product.component';
import { ProductUpdatePage } from '../product-update/product-update.page';
import { ApiService } from 'src/app/services/api/api.service';
import { UploadDesktopComponent } from 'src/app/components/media/upload-desktop/upload-desktop.component';
import { VendorDetailsPage } from 'src/app/vendors/vendor-details/vendor-details.page';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.page.html',
  styleUrls: ['./product-details.page.scss'],
})
export class ProductDetailsPage {
  @ViewChild('favIcon', { read: ElementRef }) favIcon: ElementRef;
  @ViewChild(UploadDesktopComponent, { static: true }) UploadDesktopComponent: UploadDesktopComponent;

  @Input() id: any = '';
  @Input() modal: any = '';
  @Input() nav: IonNav;
  public showProgressStatus = false;
  public product: Product;
  public favState = false;
  public favOnAnimation: Animation;
  public favOffAnimation: Animation;

  page_number = 1;
  page_limit = 30;

  filteredAddress = [];
  selectedSegment

  response;
  subscription

  constructor(public platform: Platform,
    private productApi: ProductsService,
    private api: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private animationCtrl: AnimationController,
    ////private nav: IonNav,
    private modalController: ModalController
  ) {

    this.id = this.route.snapshot.paramMap.get('id');
    this.modal = this.route.snapshot.paramMap.get('modal');
  }

  ionViewDidEnter() {
    this.subscription = this.platform.backButton.subscribe((e) => {
      console.log(e);
      this.dismissModal()
    });
  }

  ionViewWillLeave() {
    this.subscription.unsubscribe();
  }


  ionViewWillEnter() {
    console.log('ionViewWillEnter', this.id);

    //this.ListFileComponent
    //this.dataService.getProjectById(parseInt(id, 10))
    this.productApi.product_product_read(parseInt(this.id, 10))
      .subscribe(e => {
        // if the project doesn't exists, return to home page
        if (!e) {
          //this.router.navigate(['/product-list']);
        } else {
          this.product = e;
          
          this.selectedSegment = ''
          this.response = '';

          if (this.UploadDesktopComponent && e.folders.length && e.folders[0].folder.id != undefined) {
            this.UploadDesktopComponent.passItem(e);
          } else {
            //this.UploadDesktopComponent.passItem('');
          }

        }
      });

  }


  getDocuments(id_folder) {
    let url = '?page=' + this.page_number + '&items=' + this.page_limit;
    this.api.getFiles(id_folder, url).subscribe(x => {
      this.page_number++
      return x
    })
  }


  ngAfterViewInit() {


    /* this.favOnAnimation = this.animationCtrl.create()
      .addElement(this.favIcon.nativeElement)
      .duration(300) 
      .afterStyles({
        fill: 'red'
      })
      .afterAddClass('fav')
      .afterClearStyles(['fill'])
      .keyframes([
        { offset: 0, transform: 'scale(1)', opacity: '0.4' },
        { offset: 0.5, transform: 'scale(1.5)', opacity: '0.8' },
        { offset: 1, transform: 'scale(1)', opacity: '1' }
      ]);

    this.favOffAnimation = this.animationCtrl.create()
      .addElement(this.favIcon.nativeElement)
      .duration(500)
      .afterStyles({
        fill: 'grey'
      })
      .afterRemoveClass('fav')
      .afterClearStyles(['fill'])
      .keyframes([
        { offset: 0, transform: 'scale(0.8)', opacity: '0.4' },
        { offset: 0.5, transform: 'scale(0.5)', opacity: '0.8' },
        { offset: 1, transform: 'scale(1)', opacity: '1' }
      ]); */
  }


  fav(): void {
    if (this.favState) {
      // this.favOnAnimation.stop();
      this.favOffAnimation.play();
    } else {
      // this.favOffAnimation.stop();
      this.favOnAnimation.play();
    }

    this.favState = !this.favState;
  }

  onChangeSelectAcc(event) {
    console.log(event);
  }


  old_editProduct() {
    this.dismissModal();
    //this.navCtrl.navigateForward(['/members/product-details/', item.id]);    
    //nav: this.nav(ProductUpdatePage, { id: this.id }, { animated: true, direction: 'forward' });
  }

  fabCallBack(action) {
    console.log(action);

    if (action) {
      this[action]()
    } else {
      console.log('not call in component, run callback', 'details');


    }
  }


  removeDuplicate(types) {
    if (types && types.lenght) {
      const names = types.map(o => o.name)
      const filtered = types.filter(({ name }, index) => !names.includes(name, index + 1))
      return filtered
    }
  }


  filterAddress(address, event) {

    if (event) {
      console.log(address, event);
      this.filteredAddress = address.filter(function (type) {
        return type.name == event.detail.value;
      });
    }

  }



  //
  // MODALS VIEWS
  //


  /**
   * @description Open Modal Module Upload File e Docs
   * @not_used 
   */
  async openModalUpload() {
    const modal = await this.modalController.create({
      component: UploadDesktopComponent,
      canDismiss: true,
      componentProps: {
        //title: 'Add New Product',
        item: this.product,
        header: { ion_header: true },
        files: [],

      }
      //presentingElement: this.routerOutlet.nativeEl,
    })


    modal.onWillDismiss().then((e) => {
      if (e.data && e.data.status != -1) {
        /* this.page_number = 1;
        this.myInput.value = "";
        this.products = [];
        this.getProducts(false, '') */
      }
    });
    return await modal.present();
  }

  /**
   * @description Open Modal Page Details Product
   */
  async openNewProductModal() {
    const modal = await this.modalController.create({
      component: NewProductComponent,
      canDismiss: true,
      componentProps: {
        title: 'Add New Product',
      }
      //presentingElement: this.routerOutlet.nativeEl,
    })

    modal.onWillDismiss().then((e) => {

      if (e.data && e.data.message != 'OK') {
        console.log('no modify');
        return
      } else {
        this.response = 'OK';
      }
    });
    return await modal.present();
  }

  /**
   * @description Open Modal Page Edit Product Details
   */
  async openUpdateProductModal() {
    console.log(this.product);
    const modal = await this.modalController.create({
      component: ProductUpdatePage,
      //backdropDismiss: false,
      mode: 'md',
      componentProps: {
        title: 'Add Update Product',
        item: this.product,
      }
    })


    modal.onWillDismiss().then((e) => {
      console.log(e);
      if (e.data.message == '' || e.data.message.res != 'OK' || e.role == 'backdrop') {
        console.log('no modify');
        return
      } else {

        // if the project doesn't exists, return to home page
        if (!e) {
          //this.router.navigate(['/product-list']);
        } else {
          this.product = e.data.message.data;
          this.response = { res: 'OK', data: this.product }
        }

      }
    });
    return await modal.present();
  }

  async clickVendor(e) {
    ////nav: this.nav(StoresDetailsPage, {  store: item }, { animated: true, direction: 'forward' });
    const modal = await this.modalController.create({
      component: VendorDetailsPage,
      canDismiss: true,
      mode: 'md',
      cssClass: 'newOrderModalForm',
      componentProps: {
        id: e,
        modal: true
        ////nav: this.nav  // Pass nav for IonNav from modal if necessary
      },
      //presentingElement: this.routerOutlet.nativeEl
    })


    modal.onWillDismiss().then((e) => {
      console.log(e);

      if (e.data) {

        //this.myInput.value = "";

      }
    });
    return await modal.present();
  }

  /**
   * @description Close Modal and pass var to parent
   */
  dismissModal(event?) {
    this.modalController.dismiss({ message: this.response })
  }

}
