import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';

import { ConfigService } from './config.service';
import * as models from './oauth.model';


@Injectable()
export class OauthService {
  basePath: string;

  constructor(private http: HttpClient, private config: ConfigService) {
    this.basePath = config.get('oauth');
  }

  getFiles(): Observable<any> {
    let headers = new HttpHeaders();

    headers = headers.set('Accept', 'application/json');

    return this.http.request(
      'get',
      Location
        .joinWithSlash(
          `${this.basePath}`,
          `v3/files?q="root"+in+parents+and+trashed=false&orderBy=folder,+name+asc&fields=files(*)`), {
      observe: 'response',
      headers
    })
      .pipe(map((response:any) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      }));
  }

  searchFiles(string, mimeType, orderBy, dir): Observable<any> {
    let headers = new HttpHeaders();
    mimeType = 'application%2Fvnd.google-apps.folder';

    headers = headers.set('Accept', 'application/json');

    return this.http.request('get', Location.joinWithSlash(`${this.basePath}`, `v3/files?orderBy=folder,%20${orderBy}%20${dir}&q=name%20contains%20%27${string}%27%20AND%20mimeType%20contains%20%27${mimeType}%27&fields=files(*)`), {
      observe: 'response',
      headers
    })
      .pipe(map((response:any) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      }));
  }



  getFilesFolder(item: any, id: string): Observable<any> {
    let headers = new HttpHeaders();

    headers = headers.set('Accept', 'application/json');

    console.log('start', item, id);
    /* ?pageToken=${pageToken} */
    return this.http.request('get', Location.joinWithSlash(`https://www.googleapis.com/drive/v3`, `files?q="${item}"+in+parents&fields=files(*)`), {
      observe: 'response',
      headers
    })
      .pipe(map((response:any) => {
        switch (response.status) {
          case 200: {
            console.log(response.body);

            return response.body;
          }
        }
      }));
  }
}
