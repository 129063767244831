import { StoresService } from './../../services/stores/stores.service';
import { ProductsService } from './../../services/products/products.service';
import { Product } from './../../products/models/product.model';
import { AfterViewInit, ChangeDetectorRef, Component, ComponentFactoryResolver, ComponentRef, ElementRef, Input, OnInit, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';

import { VendorsService } from 'src/app/services/vendors/vendors.service';
import { NewVendorComponent } from 'src/app/vendors/new-vendor/new-vendor.component';

import { Store } from '../models/stores.model';
import { CustomersService } from 'src/app/services/customers/customers.service';

@Component({
  selector: 'app-new-store',
  templateUrl: './new-stores.component.html',
  styleUrls: ['./new-stores.component.scss'],
})
export class NewStoresComponent {

  @Input() title: string;
  @Input() type: string;
  @ViewChild('createForm') createForm: FormGroupDirective;
  @ViewChild('createFormStore') createFormStore: FormGroupDirective;
  @ViewChild('wrapperAddress', { read: ViewContainerRef }) target: ViewContainerRef;
  private componentRef: ComponentRef<any>;
  private emailRegex = "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$";

  // group form general
  //createStoresForm: FormGroup;
  createStoreForm:FormGroup;
  types: any = [];
  status: any = [];
  stores: any = [];
  products: any = [];
  companies: any = [];  
  _flag_prd = 0;

  selectedValue


  isItemAvailable = false;
  items = [];
  selectProduct: any = {};

  constructor(
    private modalController: ModalController,
    private fb: FormBuilder,
    private api: StoresService,
    private storeApi: StoresService,
    private productApi: ProductsService,
    private vendorApi: VendorsService,
    private customerApi: CustomersService,

  ) {

    let url = '?page=' + 0 + '&items=' + 25;

   /*  this.api.getStoresTypes(url).subscribe(e => {
      console.log(e)
      this.types = e.results
    })     */  
    
    this.storeApi.getStores(url).subscribe(e => {
      console.log(e)
      this.stores = e.results
    })
    
  /*   // Generete FormGroup Create Vendor 
    this.createStoresForm = this.fb.group({
      name: this.fb.control('', Validators.required),
      store_id: this.fb.control('', Validators.required),     
      external_code: this.fb.control(''),
      description: this.fb.control('', Validators.required)   
         
    }); */

    // Form Store
    
     this.createStoreForm = this.fb.group({
        name: this.fb.control('', Validators.required),
        description: this.fb.control('', Validators.required)
      });
    
    

  }
  

  dismissModal(status = -1) {
    this.modalController.dismiss({ data: { status: status } });
  }



  ionViewWillEnter() {
    this._flag_prd = 0,
    this.getStoresMeta();
    
  }

  getStoresMeta() {

    let page_product = 1;
    let url = '?page=' + page_product + '&items=' + 25;

    this.productApi.getProductsList(url).subscribe(e => {
      console.log(e);
      this.products = e.results;
      /* if (e.num_pages > 1) {
        for (let i = 2; i <= e.num_pages; i++) {
          console.log(i);
          page_product++
          let url = '?page=' + page_product + '&items=' + 25;
          this.productApi.getProductsList(url).subscribe(e => {
            console.log(e);
            this.products.push(...e.results);
            
          })

        }

      } */
    })
  } 

  onChange(event){
    this.selectProduct = event.detail.value;    
    console.log(this.selectProduct);    
  }


  // Call submitForm with click Save

  submitForm(){
    console.log('Submit Store');
    this.createFormStore.onSubmit(undefined);
  }

  createStore(values: any){    
    const newStore: any = { ...values };   
    this.storeApi.createStore(newStore).subscribe(e => {                  
      this.stores.push(e.data);
      this.selectedValue = '';
      this.dismissModal(0);
      //this.createStoresForm.controls['store_id'].setValue(e.data.id);      
    })
    
  }

  









}
