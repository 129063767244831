// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-img {
  width: 80px;
  height: 80px;
  margin-left: auto;
  margin-right: auto;
  padding: 11px;
}

ion-label, ion-note {
  font-size: 12px !important;
}

::ng-deep .img_row {
  max-width: 55px;
  max-height: 50px !important;
  padding: 10px !important;
}

::ng-deep .img_padding {
  padding: 18px;
}

ion-grid::-webkit-scrollbar {
  width: 12px;
}

ion-grid::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
}

ion-grid::-webkit-scrollbar-thumb {
  background: #2c2c2c;
}

/* Handle on hover */
ion-grid::-webkit-scrollbar-thumb:hover {
  background: #555;
}`, "",{"version":3,"sources":["webpack://./src/app/components/media/list-file/list-file.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,iBAAA;EACA,kBAAA;EACA,aAAA;AACJ;;AAEA;EACI,0BAAA;AACJ;;AAEA;EACI,eAAA;EACA,2BAAA;EACA,wBAAA;AACJ;;AAEA;EACE,aAAA;AACF;;AAEA;EACI,WAAA;AACJ;;AACA;EACI,oDAAA;EACA,kBAAA;AAEJ;;AAAA;EACM,mBAAA;AAGN;;AADI,oBAAA;AACJ;EACM,gBAAA;AAIN","sourcesContent":["ion-img {\n    width: 80px;\n    height: 80px;\n    margin-left: auto;\n    margin-right: auto;\n    padding: 11px;\n}\n\nion-label, ion-note {\n    font-size: 12px !important;\n}\n\n::ng-deep .img_row{\n    max-width: 55px;\n    max-height: 50px !important;\n    padding: 10px !important;\n}\n\n::ng-deep .img_padding{\n  padding: 18px;\n}\n\nion-grid::-webkit-scrollbar {\n    width: 12px;\n    }\nion-grid::-webkit-scrollbar-track {\n    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);\n    border-radius: 3px; \n    }\nion-grid::-webkit-scrollbar-thumb {\n      background: #2c2c2c; \n    }    \n    /* Handle on hover */\nion-grid::-webkit-scrollbar-thumb:hover {\n      background: #555; \n    }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
