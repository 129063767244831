// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.is-empty-state-content-background {
  height: 100%;
  text-align: center;
}
.is-empty-state-content-background img {
  width: 31%;
  max-width: 140px;
  margin-top: 10%;
  filter: brightness(0.7);
}

.is-empty-state-content-background.theme-dark {
  background: black;
}

.is-empty-state-content-background.theme-light {
  background: #F6F9FA;
}`, "",{"version":3,"sources":["webpack://./src/app/components/empty-notification-third/empty-notification-third.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,kBAAA;AACF;AACE;EACE,UAAA;EACA,gBAAA;EACA,eAAA;EACA,uBAAA;AACJ;;AAGA;EACE,iBAAA;AAAF;;AAGA;EACE,mBAAA;AAAF","sourcesContent":[".is-empty-state-content-background {\n  height: 100%;\n  text-align:center;\n\n  img {\n    width: 31%;\n    max-width: 140px;\n    margin-top: 10%;\n    filter: brightness(0.7);\n  }\n}\n\n.is-empty-state-content-background.theme-dark {\n  background: black;\n}\n\n.is-empty-state-content-background.theme-light {\n  background: #F6F9FA;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
