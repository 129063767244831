import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, delay, flatMap, mergeMap, retryWhen, switchMap, take, tap } from "rxjs/operators";

import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { ConfigService } from '../services/googleapis/config.service';
import { OauthAuthorizationService } from '../services/googleapis/oauth-auth.service';

const REFRESH_TOKEN = 'refresh-token';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {


    private url: string = 'http';
    private isRefreshing = false;



    constructor(
        private config: ConfigService,
        private auth: OauthAuthorizationService,
        private http: HttpClient,
        private authenticationService: AuthenticationService) {


        /**
         * Map url API
         */
        this.url = environment.SSL.active ? this.url + 's' : this.url;
        this.url += '://' + environment.API_URI.url + ':' + environment.API_URI.port + environment.API_URI.path



    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add auth header with jwt if user is logged in and request is to api url
        let token;
        this.authenticationService.currentToken.subscribe(x => { token = x; });
        const refresh = localStorage.getItem(REFRESH_TOKEN) != 'undefined' ? JSON.parse(localStorage.getItem(REFRESH_TOKEN)) : null;
        let user;
        this.authenticationService.currentUserSubject.subscribe(x => { user = x; });



        // Aggiungi il token alle intestazioni della richiesta
        if (token) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`
                }
            });
        }

        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401) {
                    // La richiesta restituisce 401 (Unauthorized)
                    if (!this.isRefreshing) {
                        this.isRefreshing = true;
                        //this.refreshTokenSubject.next(null);

                        // Esegui il refresh del token
                        return this.authenticationService.checkRemoteToken(refresh).pipe(
                            switchMap((response: any) => {
                                this.isRefreshing = false;
                                //this.refreshTokenSubject.next(response.token);

                                // Ripeti la richiesta originale con il nuovo token
                                return next.handle(this.addToken(request, response.token));
                            }),
                            catchError((error: any) => {
                                this.isRefreshing = false;
                                // Gestisci l'errore di refresh del token
                                // Esempio: reindirizza l'utente alla pagina di login
                                this.authenticationService.logout()
                                return throwError(error);
                            })
                        );
                    } else {
                        // Attendi il completamento del refresh del token prima di ripetere la richiesta
                        /* return this.refreshTokenSubject.pipe(
                            switchMap((token: any) => {
                                return next.handle(this.addToken(request, token));
                            }),
                            catchError((error: any) => {
                                // Gestisci l'errore di refresh del token
                                // Esempio: reindirizza l'utente alla pagina di login
                                return throwError(error);
                            })
                        ); */
                    }
                }

                return throwError(error);
            }),
            retryWhen((errors: Observable<any>) => {
                return errors.pipe(
                    mergeMap((error: any) => {
                        if (error.status === 401) {
                            /* return this.refreshTokenSubject.pipe(
                                switchMap((token: any) => {
                                    return next.handle(this.addToken(request, token));
                                })
                            ); */
                        }

                        return throwError(error);
                    }),
                    delay(1000), // Ritenta dopo 1 secondo
                    take(3) // Ritenta per un massimo di 3 volte
                );
            })
        );
    }

    private addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
        return request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`
          }
        });
      }
}
