import { ProductsService } from 'src/app/services/products/products.service';
import { Product } from './../models/product.model';
import { map } from 'rxjs/operators';
import { Component, OnInit, ViewChild, Input, ChangeDetectorRef } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UploadDesktopComponent } from 'src/app/components/media/upload-desktop/upload-desktop.component';
import { ApiService } from 'src/app/services/api/api.service';
import { VendorsService } from 'src/app/services/vendors/vendors.service';
import { ModalController, ToastController } from '@ionic/angular';

@Component({
  selector: 'app-vendor-update',
  templateUrl: './product-update.page.html',
  styleUrls: ['./product-update.page.scss'],
})

export class ProductUpdatePage {

  @ViewChild('createForm') createForm: FormGroupDirective;
  /* @ViewChild(UploadDesktopComponent, { static: true }) UploadDesktopComponent: UploadDesktopComponent; */
  @Input() item: Product;
  isDisabled: boolean = true



  id: any = '';
  updateProductForm: FormGroup;
  types: any = [];
  categories: any = [];


  page_number = 1;
  page_limit = 30;



  response;

  constructor(
    private route: ActivatedRoute,
    private vendor: VendorsService,
    private fb: FormBuilder,
    private api: ProductsService,
    private cd: ChangeDetectorRef,
    private modalController: ModalController,
    private toastController: ToastController

  ) {
    /* this.id = this.route.snapshot.paramMap.get('id') || ''; */

  }


  createBaseForm() {

    // Generete FormGroup Create Vendor 
    this.updateProductForm = this.fb.group({
      name: this.fb.control(this.item.name, Validators.required),
      code: this.fb.control(this.item.code, Validators.required),
      type: this.fb.control(this.item.type.id, Validators.required),
      category: this.fb.control(this.item.category.id, Validators.required),
      description: this.fb.control(this.item.description, Validators.required),
      udm: this.fb.control(this.item.udm, Validators.required),
      default_price: this.fb.control(this.item.default_price, Validators.required),

    });
    console.log(this.updateProductForm);


  }


  filterByTypes(id) {
    this.types.map(e => {
      return e.id == id ? e : ''
    })
  }

  filterByCategories(id) {
    this.categories.map(e => {
      return e.id == id ? e : ''
    })
  }

  getProductMeta() {
    let url = '?page=' + 0 + '&items=' + 25;
    /* this.api.getProductTypes(url).subscribe(e => {
      console.log(e)
      this.types = e.results
    }) */ /* #Ric Product type list non pervenuta */

    this.api.product_categories_list(url).subscribe(e => {
      console.log(e)
      this.categories = e.results
    })

  }



  ionViewWillEnter() {
    //this.getProductById(this.id)


    this.response = '';
    this.getProductMeta();
    console.log(this.item);
    this.createBaseForm()

  }

  getProductById(id) {
    console.log(this.item);
    /* this.vendor.getVendorById(id).subscribe(e => {
      this.vendorItem = e;

      if (this.UploadDesktopComponent && e.folders.length && e.folders[0].folder.id != undefined) {
        this.UploadDesktopComponent.passItem(e.folders[0].folder.id);
      } else if (this.UploadDesktopComponent) {
        this.UploadDesktopComponent.passItem('');
      }

      this.createBaseForm(e)
      //console.log(this.updateProductForm)

      if (this.vendorItem.address) {        
        //console.log(this.vendorItem.address);
        this.createAddress(this.vendorItem.address)
        //console.log(this.getAddressGroupByName(this.vendorItem.address));

      }

      if (this.vendorItem.contact) {
        //console.log(this.vendorItem.contact);
        
        //this.updateProductForm.addControl('contact', this.fb.group({}))
        this.createContact(this.vendorItem.contact)
      }  
    }) */
  }


  updateProduct(form) {

    console.log('Update');

    //Call API update product details
    this.api.updateProduct(form, this.item.id).subscribe(e => {
      console.log(e);
      if (e.message && e.message.includes('OK')) {
        this.presentToast(e.message, 'success')
        this.response = { res: 'OK', data: e.data };
      } else {
        this.presentToast(e.message, 'danger')
        this.response = 'KO';
      }
      setTimeout(() => {
        this.dismissModal();
      }, 2000);
    })

    //console.log(form)
  }




  async presentToast(text, color) {
    const toast = await this.toastController.create({
      message: text,
      position: 'bottom',
      duration: 3000,
      cssClass: 'toast',
      color: color
    });
    return await toast.present();
  }
  /* 
    segmentChanged(ev: any) {
      console.log('Segment changed', ev);
      
    } */





  onChangeSelectAcc(event) {
    //console.log(event);
  }

  // Call submitForm with click Save
  submitForm() {
    //console.log('onSubmit');
    this.createForm.onSubmit(undefined);
  }

  fabCallBack(action) {
    ////console.log(action);

    if (action) {
      this[action]()
    } else {
      //console.log('not call in component, run callback', 'update');
    }
  }


  dismissModal(message?) {
    if (message)
      this.response = message;
    this.modalController.dismiss({ message: this.response })
  }

}


