// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-menu.md ion-list {
  padding: 20px 8px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/sub-menu-filter/sub-menu-filter.component.scss"],"names":[],"mappings":"AACA;EACI,iBAAA;AAAJ","sourcesContent":["\nion-menu.md ion-list {\n    padding: 20px 8px;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
