import { Order } from 'src/app/orders/models/order.model';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import * as moment from 'moment';
import { OfflineManagerService } from '../../network/offline-manager.service';
import { NetworkService } from '../../network/network.service';
import { AuthenticationService } from '../../authentication/authentication.service';
import { environment } from '../../../../environments/environment';
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable object-shorthand */
/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/semi */
/* eslint-disable @typescript-eslint/naming-convention */


import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Platform, ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { catchError, delay, distinctUntilChanged, map, retryWhen, scan, tap, timeout } from 'rxjs/operators';


import { Project } from 'src/app/projects/models/projects';
import { Docs } from 'src/app/documents/models/docs';
import { DatePipe } from '@angular/common';
//import { Customer } from 'src/app/customers/models/customer.model';


const API_STORAGE_KEY = 'wokrerservice';
const TOKEN_KEY = 'access-token';

@Injectable({
  providedIn: 'root'
})
export class PrintService {
  
  totalCost = 0
  private url: string = 'http';
  private token: any;
  private projects: Project[];
  private documents: Docs[];
  private document: Docs;
  private project_types: any[];
  //public docList = new BehaviorSubject(null);
  
  constructor(
    private storage: Storage,
    private plt: Platform,
    private http: HttpClient,
    public toastController: ToastController,
    private authService: AuthenticationService,
    private networkService: NetworkService,
    private offlineManager: OfflineManagerService,
    private _datePipe: DatePipe
    ) {
      
      /**
       * Map url API
     */
    //this.url = environment.SSL.active ? this.url + 's' : this.url
    //this.url += '://' + environment.API_URI.url + ':' + environment.API_URI.port + environment.API_URI.path

    /*this.authService.currentToken.subscribe(x => {
      //console.log(x);
      this.token = x //= JSON.parse(localStorage.getItem(TOKEN_KEY))

    })*/

  }

  /**
   * 
   * Create Document
  */

  setPage() {
    var doc = new jsPDF('p', 'mm', 'a4');
    return doc
  }


  /**
  * 
  * PRINT GENERAL 
  * 
  */

  print(params?) {
    params == undefined ? params = '' : null

    let status = params.status.code

    console.log('print general swtch qui ', params);
    
    
    this.print_order(params, 'ORDER')

    /* switch (status) {
      case 'ORD':
        this.print_order(params, 'ORDER')
        break;

      case 'DRF':
        //this.print_draft(params)
        this.print_order(params, 'ORDER')

        break;
      case 'REQ':
        //this.print_req(params)
        this.print_order(params, 'ORDER')

        break;
      case 'CLO':
        //this.print_order(params, 'CLOSED')
        this.print_order(params, 'ORDER')

        break;
      case 'DEL':
        this.print_order(params, 'DELETED')

        break;

      default:
        console.log('no print func in servicePrint');

        break;
    } */

  }

  /**
   * 
   * PRINT ORDER
   * 
  */


  print_order(params, title) {
    
    let doc = this.setPage()
    doc.setProperties({
      title: title
    });
    let order = params


    var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
    var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

    let id,
      code,
      vendor,
      customer,
      type;


    let headers_lines = [];
    let headers_legals = [];
    let cell_lines = [];
    let cell_legals = [];
    let order_lines = order.lines;
    let order_legals = order.legals;
    let totalsAmount = 0
    let headers_lines_dates = [];
    let headers_lines_payments = [];    
    let headers_lines_documents = [];   
    let order_dates = order.dates ? order.dates : [];
    let order_payments = order.payments ? order.payments : [];
    let order_documents = order.documents ? order.documents : [];
    


    var totalPagesExp = '{total_pages_count_string}'


    headers_lines = ['Prodotto Servizio','Descrizione','Quantità', 'Parziale','Imponibile']
    headers_lines_dates = ['Descrizione', 'Data']
    headers_lines_payments = ['Descrizione', 'Importo', 'Data riferimento ', 'Tipologia di Pagamento','Pagamento Fatturazione']
    headers_lines_documents = ['Descrizione']
    headers_legals = ['Nome', 'Descrizione']

    vendor = order.vendor
    customer = order.customer


    if (order_legals.length > 0) {
      let i = 1;
      order_legals.map(e => {        
        cell_legals.push([e.title, e.content])
      })
    }

    this.totalCost = order.cost


    let creted_at = "Data creazione: " + this._datePipe.transform(Date.now(), 'dd/MM/yyyy HH:mm:ss');
    let creator = "Creatore: "// + this.user.datas[0].firstname + ' ' + this.user.datas[0].lastname;    

    /**
     * 
     * Set x - y
     * 
    */
    let x_def = 10
    let y_def = 10

    let x_half = pageWidth / 2
    let x_div_three = pageWidth / 3
    let x_starLine = x_def
    let x_endLine = pageWidth - x_def
    let x = x_def
    let y = y_def

    
    //Set contorno grigio
    //doc.setDrawColor('#ccc');
    //doc.roundedRect(x, y, 191, 276, 1, 1, 'S')

    /**
     * LOGO + NUMBER PROJECT
     * 
    */
    // Logo + Copyright  
    doc.setDrawColor('#000');
    doc.addImage("assets/img/logo.png", "PNG", x + 5, 12, 50, 11);
    doc.setTextColor('#959595');
    doc.setFontSize(6);
    var year = moment().format('YYYY');
    doc.text("Revolution software web application - Copyright 1987 - " + year, 19, 26)


    //Set Number Project
    if(params.status.code !== 'ORD')
      doc.setFillColor('#6A0C0B')
    else
      doc.setFillColor('#286fb3')
    doc.setTextColor('#fff');
     doc.setFont(undefined, 'bold', undefined);
    x = x_half + 1
    y = y + 1
    let w = 94
    let h = 16
    doc.roundedRect(x, y, w, h, 1, 1, 'F')
    doc.setFontSize(10)
    y = y + 5
    x = x + 3
    doc.text('Progetto: ', x, y + 1)
    let prj_code =  order.job.code_slug//this.getProjectCode(order.code)
    doc.text('' + prj_code, x + 17, y + 1);
    doc.setFontSize(13);
    doc.setFont(undefined, 'bold', undefined);

    x = x_def
    y = y + 12
    doc.setDrawColor('#ccc');
    doc.line(x_starLine, y, x_endLine, y);


    /**
     * CUSTOMER & VENDOR
     * 
    */

    // CUSTOMER
    if (customer != null && customer.code_fiscal) {
      

      console.log(customer)
      let customer_email = '';
      let contact_pec ='';
      let contact_tel = '';
      
      //Address
      let customer_street_legal = customer?.address ?  customer.address?.name : '---';
      if(customer.company?.contacts?.length > 0){
        //Mail
        customer_email = customer.company?.contacts?.length > 0 ? this.getContact(customer?.company?.contacts,'Mail') : '---';
        //Pec
        contact_pec = customer.company?.contacts?.length > 0 ? this.getContact(customer?.company?.contacts,'PEC') : '---';
        //Phone
        contact_tel = customer.company?.contacts?.length > 0 ? this.getContact(customer?.company?.contacts,'Phone') : '---';
      }


      doc.setFont(undefined, 'bold', undefined);
      doc.setFontSize(10);
      doc.setTextColor('#337ab7');
      x = x_def + 5
      y = y_def + 25
      doc.text("Cliente:", x +3, y);
      doc.setDrawColor('#337ab7');
      let textWidth_c = doc.getTextWidth("Cliente:");
      doc.line(x + 3, y + 1, x + textWidth_c + 3, y + 1);
      //let x_res = x_def + 103
      let x_res = x_def + 8
      doc.setFontSize(9);
      doc.setTextColor('#337ab7');
      x = x_res
      y = y + 6
      doc.text((customer?.name) ? customer?.name : '', x, y);
      y = y + 4
      doc.text(customer_street_legal, x, y);
      //-------------------------
      x = x_res
      y = y + 4
      doc.text("P.IVA: ", x, y);
      x = x + 10
      doc.text((customer?.code_vat) ? customer?.code_vat : '', x, y);
      //-------------------------
      x = x_res
      y = y + 4
      doc.text("C.F: ", x, y);
      x = x + 10
      doc.text((customer?.code_fiscal) ? customer?.code_fiscal : '', x, y);
      //-------------------------
      x = x_res
      y = y + 4
      doc.text("SDI: ", x, y);
      x = x + 10
      let c_sdi = ''
      if (customer?.code_unique != null) {
        c_sdi = (customer.code_unique == 'none') ? '' : customer.code_unique
      }

      doc.text(c_sdi, x, y);
      //-------------------------
      x = x_res
      y = y + 4
      doc.text("IBAN: ", x, y);
      x = x + 10
      let c_iban = ''
      if (customer?.code_iban != null) {
        c_iban = (customer?.code_iban == 'none') ? '' : customer.code_iban
      }
      doc.text(c_iban, x, y);

      doc.setFont(undefined, 'normal', undefined);
      doc.setFontSize(8);
      doc.setTextColor('#000');
      //----------------------------
      x = x_res
      y = y + 4
      doc.text("Email: ", x, y, { align: 'left' });
      x = x_res + 10
      //doc.text('customer_email', x, y, { align: 'left' });
      doc.text(customer_email, x, y, { align: 'left' });
      //----------------------------
      x = x_res
      y = y + 4
      doc.text("PEC: ", x, y, { align: 'left' });
      x = x_res + 10
      //doc.text('contact_pec', x, y, { align: 'left' });
      doc.text(contact_pec, x, y, { align: 'left' });
      //----------------------------
      x = x_res
      y = y + 4
      doc.text("Tel: ", x, y, { align: 'left' });
      x = x_res + 10
      //doc.text('contact_tel', x, y, { align: 'left' });
      doc.text(contact_tel, x, y, { align: 'left' });

    }


    // VENDOR
    /** GET VENDOR  */

    if (vendor != null) {
     
  
      
      /*
      let vendor_street_legal = customer.company?.address?.length > 0 ?  customer.company?.address?.name : '---'; 
      let vendor_email = ''; //vendor.company?.contacts?.length > 0 ? vendor.company?.contacts.filter(function callback(element, index, array) {return element.type.name == 'Mail'})[0].contact : '---'
      let is_pec = vendor?.company?.contacts?.filter(function callback(element, index, array) {return element.type.name == 'PEC'})[0]?.length;
      let vendor_pec = ''; //(is_pec || (is_pec != undefined))  ?  vendor?.company?.contacts?.filter(function callback(element, index, array) {return element.type.name == 'PEC'})[0].contact : '---' ;
      let vendor_tel = vendor.company?.contacts?.length > 0 ? vendor.company?.contacts.filter(function callback(element, index, array) {return element.type.name == 'Phone'})[0].contact : '---'

      */
      let vendor_email = '' ;
      let vendor_pec = '';
      let vendor_tel = '';
     //Address
     let vendor_street_legal = vendor?.address ?  vendor.address?.name : '---';
     if(vendor.company?.contacts?.length > 0){
       //Mail
       vendor_email = vendor.company?.contacts?.length > 0 ? this.getContact(vendor?.company?.contacts,'Mail') : '---';
       //Pec
       vendor_pec = vendor.company?.contacts?.length > 0 ? this.getContact(vendor?.company?.contacts,'PEC') : '---';
       //Phone
       vendor_tel = vendor.company?.contacts?.length > 0 ? this.getContact(vendor?.company?.contacts,'Phone') : '---';
     }
      
      

     

      doc.setFont(undefined, 'bold', undefined);
      doc.setFontSize(10);
      doc.setTextColor('#337ab7');
      x = x_def + 98
      y = y_def + 25
      doc.text("Fornitore:", x + 3, y);
      doc.setDrawColor('#337ab7');
      let textWidth = doc.getTextWidth("Fornitore:");
      doc.line(x + 3, y + 1, x + textWidth + 3, y + 1);

      doc.setFontSize(9);
      doc.setTextColor('#337ab7');
      x = x + 3
      y = y + 6
      doc.text((vendor?.name) ? vendor.name : '', x, y, { align: 'left' });
      y = y + 4
      doc.text(vendor_street_legal, x, y, { align: 'left' });
      //-------------------------

      let xres = x_def + 98 + 3
      x = xres
      y = y + 4
      doc.text("P.IVA: ", x, y, { align: 'left' });
      x = xres + 10
      doc.text((vendor?.code_vat) ? vendor.code_vat : '', x, y, { align: 'left' });
      //-------------------------

      x = xres
      y = y + 4
      doc.text("C.F: ", x, y, { align: 'left' });
      x = xres + 10
      doc.text((vendor?.code_fiscal) ? vendor.code_fiscal : '', x, y, { align: 'left' });
      //-------------------------
      x = xres
      y = y + 4
      doc.text("SDI: ", x, y, { align: 'left' });
      x = xres + 10
      let sdi = ''
      if ((vendor?.code_unique) != null) {
        (vendor?.code_unique == 'none') ? '' : vendor.code_unique
      }
      doc.text(sdi, x, y, { align: 'left' });
      //-------------------------
      x = xres
      y = y + 4
      doc.text("IBAN: ", x, y, { align: 'left' });
      x = xres + 10
      doc.text((vendor?.code_iban) ? vendor?.code_iban : '', x, y, { align: 'left' });


      doc.setFont(undefined, 'normal', undefined);
      doc.setFontSize(8);
      doc.setTextColor('#000');

      //----------------------------
      x = xres
      y = y + 4
      doc.text("Email: ", x, y, { align: 'left' });
      x = xres + 10
      doc.text(vendor_email, x, y, { align: 'left' });
      //doc.text('vendor_email', x, y, { align: 'left' });
      //----------------------------
      x = xres
      y = y + 4
      doc.text("PEC: ", x, y, { align: 'left' });
      x = xres + 10
      doc.text(vendor_pec, x, y, { align: 'left' });
      //doc.text('vendor_pec', x, y, { align: 'left' });
      //----------------------------
      x = xres
      y = y + 4
      doc.text("Tel: ", x, y, { align: 'left' });
      x = xres + 10
      doc.text(vendor_tel, x, y, { align: 'left' });
      //doc.text('vendor_tel', x, y, { align: 'left' });

    }




    /**
     * 
     * 
     *  Number Offerta  linea header 
     * 
     * 
     * */

    //doc.setFillColor('#032c5d')    
    if(params.status.code !== 'ORD')
      doc.setFillColor('#6A0C0B')
    else
      doc.setFillColor('#286fb3')
    doc.setTextColor('#fff');
    x = x_def
    y = y + 4
    w = x_endLine - x_def
    h = 7
    doc.roundedRect(x, y, w, h, 1, 1, 'F')
    doc.setFontSize(10)
    doc.setFont(undefined, 'bold', undefined);
    let obj; // = "DRAFT";
    console.log(order)
    let textBefore = order.code_slug + ' ';
    // let textBefore = order.status.name + ': ' + order.job.code_slug + '.' + order.status.code + '.' + order.code_slug + ' ';
    doc.text(textBefore, x + 2, y + 5);
    let widthBefore = doc.getTextWidth(textBefore);
    doc.setTextColor('#FFA500'); // rosso
    doc.text(' (DA RIPORTARE IN FATTURAZIONE)', x + 2 + widthBefore, y + 5);
    doc.setTextColor('#fff');

    //doc.text(order.status.name + ': ' + order.job.code_slug+ '.' + order.code + ' (DA RIPORTARE IN FATTURAZIONE)', x + 2, y + 5);
    doc.setFontSize(13);
  

    // DETAILS  

    let margin = 8;



    // --------------------------------------

    x = x_def + 4
    y = y + margin
    doc.setDrawColor('#000');
    doc.setFontSize(10);
    doc.setTextColor('#337ab7');
    doc.setFont(undefined, 'bold', undefined);
    //doc.text("Data Emissione:", x, y);
    doc.setTextColor('#000');
    doc.setFont(undefined, 'normal', undefined);
    //x = x + 50
    //doc.text(moment(new Date(order.created)).format("DD/MM/YYYY"), x, y);
    //doc.setDrawColor('#ccc');
    //y = y + 2
    //doc.line(x_starLine, y, x_endLine, y);


    // ---------------------------------------
    //
    //  EXTRAS
    //     

    // Static Extra 
    let h_ = 0
    y = y + h_ + 5
    doc.setDrawColor('#000');
    doc.setFontSize(10);
    doc.setTextColor('#337ab7');
    doc.setFont(undefined, 'bold', undefined);
    doc.text('Tipologia Ordine', x, y);
    doc.setTextColor('#000');
    doc.setFont(undefined, 'normal', undefined);
    x = x + 50
    var pdfInMM = 210;
    paragraph = order?.type?.name;
    var lines = doc.splitTextToSize(paragraph, (pdfInMM - 80))
    var dim = doc.getTextDimensions(lines);
    doc.text(lines, x, y);
    doc.setDrawColor('#ccc');
    h_ = dim.h  - 2
    doc.line(x_starLine, y + h_, x_endLine, y + h_);

    x = x_def + 4
    y = y + h_ + 5
    doc.setDrawColor('#000');
    doc.setFontSize(10);
    doc.setTextColor('#337ab7');
    doc.setFont(undefined, 'bold', undefined);
    doc.text('Categoria', x, y);
    doc.setTextColor('#000');
    doc.setFont(undefined, 'normal', undefined);
    x = x + 50
    var pdfInMM = 210;
    paragraph = order?.category?.name;
    var lines = doc.splitTextToSize(paragraph, (pdfInMM - 80))
    var dim = doc.getTextDimensions(lines);
    doc.text(lines, x, y);
    doc.setDrawColor('#ccc');
    h_ = dim.h  - 2    
    doc.line(x_starLine, y + h_, x_endLine, y + h_);


    x = x_def + 4
    y = y + h_ + 5
    doc.setDrawColor('#000');
    doc.setFontSize(10);
    doc.setTextColor('#337ab7');
    doc.setFont(undefined, 'bold', undefined);
    doc.text('Causale', x, y);
    doc.setTextColor('#000');
    doc.setFont(undefined, 'normal', undefined);
    x = x + 50
    var pdfInMM = 210;
    paragraph = order?.causal?.name;
    var lines = doc.splitTextToSize(paragraph, (pdfInMM - 80))
    var dim = doc.getTextDimensions(lines);
    doc.text(lines, x, y);
    doc.setDrawColor('#ccc');
    h_ = dim.h  - 2    
    doc.line(x_starLine, y + h_, x_endLine, y + h_);

      //End Static

      // Extra da DB

    let o_extras = order.extras
    console.log(order.extras)
    for (let i = 0; i < o_extras.length; i++) { 
     

     /*  if (o_extras[i].title == 'Pagamenti') {

        
        doc.setDrawColor('#000');
        doc.setFontSize(10);
        doc.setTextColor('#337ab7');
        doc.setFont(undefined, 'bold', undefined);
        doc.text('Pagamenti', x, y);
        doc.setTextColor('#000');
        doc.setFont(undefined, 'normal', undefined);
        var pdfInMM = 210;
        var paragraph = params.invoice_term.name + ', ' + params.payment_term.name + ', ' + params.payment_type.name + '. ';
        var lines = doc.splitTextToSize(paragraph, (pdfInMM - 80))
        var dim = doc.getTextDimensions(lines);
        doc.text(lines, x + 50, y);

        paragraph = o_extras[i].content;
        var lines = doc.splitTextToSize(paragraph, (pdfInMM - 80))
        var dim = doc.getTextDimensions(lines);
        doc.text(lines, x + 50, y + 5);


        doc.setDrawColor('#ccc');
        h_ = dim.h
        console.log(lines);
        console.log(h_);
        doc.line(x_starLine, y + h_, x_endLine, y + h_);


      } else  */
      if (o_extras[i].title != 'Pagamenti' && o_extras[i].title != 'Allegati') {
        x = x_def + 4
        y = y + h_ + 5   
        
        doc.setDrawColor('#000');
        doc.setFontSize(10);
        doc.setTextColor('#337ab7');
        doc.setFont(undefined, 'bold', undefined);
        doc.text(o_extras[i].title, x, y);
        doc.setTextColor('#000');
        doc.setFont(undefined, 'normal', undefined);
        x = x + 50
        var pdfInMM = 210;
        var paragraph = o_extras[i].content;
        var lines = doc.splitTextToSize(paragraph, (pdfInMM - 80))
        var dim = doc.getTextDimensions(lines);
        doc.text(lines, x, y);
        doc.setDrawColor('#ccc');
        h_ = dim.h  - 2    
        doc.line(x_starLine, y + h_, x_endLine, y + h_);
        
        if ((y+h) > 180) {
          y = y_def
          x = x_def
          doc.addPage()
        } else {
          y = y
        }
      }
    }
    y = y + h_




    
    /**
     * 
     *  PRODUCTS
     * 
    */
    
    if(params.status.code !== 'ORD')
      doc.setFillColor('#6A0C0B')
    else
      doc.setFillColor('#286fb3')
      //doc.setFillColor('#fff');
    doc.setTextColor('#fff');
    x = x_def
    y = y + 5
    w = x_endLine - x_def
    h = 7
    doc.roundedRect(x, y, w, h, 1, 1, 'F')
    doc.setFontSize(10)
    obj = "Dettaglio";
    doc.setFont(undefined, 'bold', undefined);
    doc.text(obj, x + 2, y + 5);
    //doc.setFontSize(13);
    y = y + 10


    
    // Linee
    var qty_t = 0
    var cost_t = 0
    var prod = ''
    console.log(order_lines.length)
    console.log(order_lines)
    if (order_lines.length > 0) {
      let iIndex = -1;
      order_lines.map(e => {
        var lMargin = 15; //left margin in mm
        var rMargin = 15; //right margin in mm
        var pdfInMM = 210;  // width of A4 in mm        
        doc.setFontSize(13);
        let desc = (e.description)
        if(e.product){
          prod =e.product.name
        }
        else if(e.service){
          prod =e.service.name
        }
        else{
          prod = '-'
        }
        qty_t += parseInt(e.qty)
        cost_t = e.cost * parseInt(e.qty);
        //cost_t += parseInt(cost_qty);
        //cost_t += parseInt(e.cost)
        cell_lines.push([prod, desc, e.qty, this.formatMoney(e.cost), this.formatMoney(e.qty*e.cost) ])
        console.log(y)
      })
    }


    // Add line custom  TOTAL
    let fillColor = '#EBEFF2';
    console.log(cost_t)
    cell_lines.push([
      {
        content: ' Totale (IVA ed oneri di Legge esclusi) ', colSpan: 4,
        styles: {
          fillColor: fillColor,
          halign: 'right',
          fontStyle: 'bold'
        }
      },
      // {
      //   content: '',
      //   styles: { 
      //     fillColor: fillColor,
      //     fontStyle: 'bold' }
      // },
      {
        content: this.formatMoney(this.totalCost),
        styles: { fillColor: fillColor, fontStyle: 'bold'}
      },
      {
        content: 0,
        styles: { fillColor: fillColor, fontStyle: 'bold'}
      },
      {
        content: this.formatMoney(this.totalCost),
        styles: { fillColor: fillColor, fontStyle: 'bold'}
      }
    ])

    console.log(cell_lines)
    autoTable(doc, {
      theme: 'striped',
      bodyStyles: { fontSize: 9, fontStyle: 'normal', halign: "left" },
      startY: y,
      styles: {
        lineWidth: 0,
        textColor: 0,
        halign: "right",
      },
      headStyles: {
        fillColor: '#dadada',
        textColor: '#000',
        fontSize: 9,
        halign: "left",
        valign: "middle",
        //lineColor: Color;
      },
      columnStyles:{
        0: { cellWidth: 40, overflow: 'linebreak' }, // This sets the width of the first column and wraps the text
        1: { cellWidth: 50, overflow: 'linebreak' }, // This sets the width of the first column and wraps the text
        2: { cellWidth: 30, overflow: 'linebreak' }, // This sets the width of the first column and wraps the text
        3: { cellWidth: 30, overflow: 'linebreak' }, // This sets the width of the first column and wraps the text
        4: { cellWidth: 30, overflow: 'linebreak' }, // This sets the width of the first column and wraps the text
      },
      head: [headers_lines],
      body: cell_lines
    })
    let final = (doc as any).lastAutoTable.finalY

    // add page 
    console.log(final)
    if (final > 180) {
      y = y_def
      x = x_def
      doc.addPage()
    } else {
      y = final
    }
    cell_lines = []  

    /**
     * DATE
     * 
     */
        
        if(params.status.code !== 'ORD')
          doc.setFillColor('#6A0C0B')
        else
          doc.setFillColor('#286fb3')
        //doc.setFillColor('#fff');
        doc.setTextColor('#fff');
        x = x_def
        y = y + 4
        w = x_endLine - x_def
        h = 7
        doc.roundedRect(x, y, w, h, 1, 1, 'F')
        doc.setFontSize(10)
        obj = "Tempistiche";
        doc.setFont(undefined, 'bold', undefined);
        doc.text(obj, x + 2, y + 5);
        doc.setFontSize(13);
        y = y + 10
        // Add line custom  TOTAL
    
      
        if (order_dates.length > 0) {
          let iIndex = -1;
          order_dates.map(e => {
            var lMargin = 15; //left margin in mm
            var rMargin = 15; //right margin in mm
            var pdfInMM = 210;  // width of A4 in mm        
            let label = e.type.name
            let data
            console.log(label)
            let start =  (e.date_start) ? this._datePipe.transform(e.date_start, 'dd/MM/yyyy') : '--' 
            let end = (e.date_end)
            if(end === '--'){
              data = start
            }else{
              data = end
            }
            cell_lines.push([label, data])
            console.log(cell_lines)            
          })
        }
        
    
    
    
        autoTable(doc, {
          theme: 'striped',
          bodyStyles: { fontSize: 10, fontStyle: 'normal', halign: "left", lineColor: [189, 195, 199] },
          startY: y,
          styles: {
            lineWidth: 0,
            textColor: 0,
            halign: "right",
          },
          headStyles: {
            fillColor: '#dadada',
            textColor: '#000',
            fontSize: 9,
            halign: "left",
            valign: "middle",
            //lineColor: '#ffffff'
          },
          head: [headers_lines_dates],
          body: cell_lines
        })
    
        final = (doc as any).lastAutoTable.finalY
    
        // add page 
        if (final > 210) {
          y = y_def
          x = x_def
          doc.addPage()
        } else {
          y = final
        }

        
    
    
        cell_lines = []   



    /**
     * PAGAMENTI
     * 
     */

        
      if(params.status.code !== 'ORD')
        doc.setFillColor('#6A0C0B')
      else
        doc.setFillColor('#286fb3')
      //doc.setFillColor('#fff');
      doc.setTextColor('#fff');
      x = x_def
      y = y + 4
      w = x_endLine - x_def
      h = 7
      doc.roundedRect(x, y, w, h, 1, 1, 'F')
      doc.setFontSize(10)
      obj = "Pagamenti";
      doc.setFont(undefined, 'bold', undefined);
      doc.text(obj, x + 2, y + 5);
      doc.setFontSize(13);
      y = y + 10
      // Add line custom  TOTAL
  
    
      if (order_payments.length > 0) {
        let iIndex = -1;
        order_payments.map(e => {
          var lMargin = 15; //left margin in mm
          var rMargin = 15; //right margin in mm
          var pdfInMM = 210;  // width of A4 in mm            
          
          let descrizione = e.category.name
          let method = e.method.name
          let term = e.term.name
          let term_reference = e.term_reference.name
          let day_reference = e.day_reference
          let day_reference_type = e.day_reference_type.name           
          let amount = e.amount
          cell_lines.push([descrizione, this.formatMoney(amount) , term_reference, method + ' - ' + day_reference + 'gg ' + day_reference_type , term ])
          
        })
      }
      
  
  
  
      autoTable(doc, {
        theme: 'striped',
        bodyStyles: { fontSize: 10, fontStyle: 'normal', halign: "left", lineColor: [189, 195, 199] },
        startY: y,
        styles: {
          lineWidth: 0,
          textColor: 0,
          halign: "right",
        },
        headStyles: {
          fillColor: '#dadada',
          textColor: '#000',
          fontSize: 9,
          halign: "left",
          valign: "middle",
          //lineColor: '#ffffff'
        },
        head: [headers_lines_payments],        
        body: cell_lines
      })
  
      final = (doc as any).lastAutoTable.finalY
  
      // add page 
      if (final > 210) {
        y = y_def
        x = x_def
        doc.addPage()
      } else {
        y = final
      }

      
  
  
      cell_lines = []   
    
  

    
    /**
     * ALLEGATI
     * 
     */

        
    console.log(params.status.code);
    
    if(params.status.code !== 'ORD')
      doc.setFillColor('#6A0C0B')
    else
      doc.setFillColor('#286fb3')
    //doc.setFillColor('#fff');
    doc.setTextColor('#fff');
    x = x_def
    y = y + 4
    w = x_endLine - x_def
    h = 7
    doc.roundedRect(x, y, w, h, 1, 1, 'F')
    doc.setFontSize(10)
    obj = "Allegati";
    doc.setFont(undefined, 'bold', undefined);
    doc.text(obj, x + 2, y + 5);
   // doc.setFontSize(13);
    y = y + 10
    // Add line custom  TOTAL

  
    if (order_documents.length > 0) {
      let iIndex = -1;
      order_documents.map(e => {
        var lMargin = 15; //left margin in mm
        var rMargin = 15; //right margin in mm
        var pdfInMM = 210;  // width of A4 in mm            
        
        let descrizione = e.name        
        cell_lines.push([descrizione])
        
      })
    }
    



    autoTable(doc, {
      theme: 'striped',
      bodyStyles: { fontSize: 10, fontStyle: 'normal', halign: "left", lineColor: [189, 195, 199] },
      startY: y,
      styles: {
        lineWidth: 0,
        textColor: 0,
        halign: "right",
      },
      headStyles: {
        fillColor: '#dadada',
        textColor: '#000',
        fontSize: 9,
        halign: "left",
        valign: "middle",
        //lineColor: '#ffffff'
      },
      head: [headers_lines_documents],        
      body: cell_lines
    })

    final = (doc as any).lastAutoTable.finalY

    // add page 
    if (final > 210) {
      y = y_def
      x = x_def
      doc.addPage()
    } else {
      y = final
    }

    


    cell_lines = []  

    /**
     * 
     * 
     *  Add line LEGALS 
     * 
     * 
     * */



    if(params.status.code !== 'ORD')
      doc.setFillColor('#6A0C0B')
    else
      doc.setFillColor('#286fb3')    //doc.setFillColor('#fff');
    doc.setTextColor('#fff');
    x = x_def
    y = y + 3
    w = x_endLine - x_def
    h = 7
    doc.roundedRect(x, y, w, h, 1, 1, 'F')
    doc.setFontSize(10)
    doc.setFont(undefined, 'bold', undefined);
    obj = "Note Legali";
    y = y + 5
    doc.text(obj, x + 2, y);
    
    y = y + 3

    autoTable(doc, {
      theme: 'striped',
      bodyStyles: { fontSize: 8, fontStyle: 'italic' },
      startY: y + 2,
      headStyles: {
        fillColor: '#dadada',
        textColor: '#000',
        fontSize: 10,
        halign: "left",
        valign: "middle",
        //lineColor: Color;
      },
      head: [headers_legals],
      body: cell_legals,
      didParseCell: function (data) {
        var rows = data.table.body;
        /* if (data.row.index === rows.length - 1) {
          data.cell.styles.fillColor = [239, 154, 154];
        } */
      },

      didDrawPage: function (data) {

      },
    })

    final = (doc as any).lastAutoTable.finalY
    console.log(final)
    // add page 
    if (final > 180) {
      y = y_def
      x = x_def
      doc.addPage()
    } else {
      y = final + 5
    }

    
    doc.setDrawColor('#000');
    doc.addImage("assets/img/logo.png", "PNG", x + 10, y+3, 50, 11);
    doc.setTextColor('#959595');
    doc.setFontSize(6);
    var year = moment().format('YYYY');
    doc.text("Revolution software web application - Copyright 1987 - " + year, x + 10, y+17)


    //Set Number Project
    if(params.status.code !== 'ORD')
      doc.setFillColor('#6A0C0B')
    else
      doc.setFillColor('#286fb3')
    doc.setTextColor('#fff');
    doc.setFont(undefined, 'bold', undefined);
    x = x_half + 1
    y = y + 1
    w = 94
    h = 16
    doc.roundedRect(x, y, w, h, 1, 1, 'F')
    doc.setFontSize(10)
    y = y + 5
    x = x + 3
    doc.text('Progetto: ', x, y + 1)
    doc.text('' + prj_code, x + 17, y + 1);
    doc.text('Codice Ordine: ' + order.code, x, y + 7)
    doc.setFontSize(13);
    doc.setFont(undefined, 'bold', undefined);

    x = x_def
    y = y + 12
    doc.setDrawColor('#ccc');
    doc.line(x_starLine, y, x_endLine, y);


    doc.setFontSize(8);
    doc.setTextColor('#000')
    y = y+20
    let sign = `Il Committente ` + customer?.name + `
    - Legale Rappresentante
    Firma

    


    ______________________________`
    doc.text(sign, x_starLine + 50, y, { align: 'center' });
    

    doc.setFontSize(8);
    doc.setTextColor('#000')
    let sign2 = `Il Fornitore ` + vendor?.name + `
    (Per Accettazione)
    Il Legale rappresentante
    Firma



    ______________________________`
    doc.text(sign2, x_starLine + 150, y, { align: 'center' });


    y = y + 7 ;
    doc.setFontSize(8);
    doc.setTextColor('#000')
    let sign3 = `
    Ai sensi e per gli effetti dell'art. 1341 C.C, si dichiara dopo averle ben lette, di approvare specificatamente le seguenti clausole:
    3) Limitazioni alla facoltà di apportare le modifiche;
    4) Forma ed efficacia delle modifiche;
    5) Prevalenza delle condizioni più vantaggiose;
    6) Oggetto ed ALLEGATO A;
    7) Modalità di pagamento;
    8) Foro Convenzionale;
    9) Privacy
     - Informative e consenso al Trattamento dei dati - 
    NUOVO REGOLAMENTO UE GDPR 679/2016 E D.Lgs 101 del 10.08.2018 L.C.S.
    `
    doc.text(sign3, x_starLine+5, y+30, { align: 'left' });

    doc.setFontSize(8);
    doc.setTextColor('#000')
    let sign4 = `Il Fornitore ` + vendor?.name + `
    Il Legale rappresentante
    Firma
    


    ______________________________`
    doc.text(sign4, x_starLine + 150, y+70, { align: 'center' });

    doc.addPage()
    y = 0

    if(params.status.code !== 'ORD')
      doc.setFillColor('#6A0C0B')
    else
      doc.setFillColor('#286fb3')
    doc.setTextColor('#fff');
    x = x_def
    y = y + 5
    w = x_endLine - x_def
    h = 7
    doc.roundedRect(x, y, w, h, 1, 1, 'F')
    doc.setFontSize(10)
    doc.setFont(undefined, 'bold', undefined);
    obj = "PROTEZIONE DEI DATI PERSONALI " ;
    y = y + 5
    doc.text(obj, x + 2, y);
    y = y + 5


    doc.setFontSize(8)
    doc.setTextColor('#000');
    doc.setFont(undefined, 'bold', undefined);
    obj = `PROTEZIONE DEI DATI PERSONALI
INFORMATIVA AI SENSI DELL'ARTICOLO 13 DEL NUOVO REGOLAMENTO UE NUMERO 679/2016 (GDPR) E DEL D.LGS 101 DEL 10.08.2018
RECANTE DISPOSIZIONI PER L'ADEGUAMENTO DELLA NORMATIVA NAZIONALE ALLE DISPOSIZIONI DEL REGOLAMENTO UE 679/2016`
    y = y + 5
    doc.text(obj, x_starLine, y, { align: 'left' });
    doc.setFont(undefined, 'normal', undefined);


    let obj_lines = 0
    let ht = 0
    y = y + 12
    let marg = 10 //margine

    obj = `In seguito all'entrata in vigore del Regolamento UE numero 679/2016, e del D. Lgs 101 del 10.08.2018, recante disposizioni per l'adeguamento
della normativa nazionale alle disposizioni del Regolamento UE, conformemente a quanto citato dall'articolo 13 del Regolamento UE numero 679/2016,
desideriamo comunicarVi quanto segue:`;


    obj_lines = 4
    ht = this.calcTextHeight(obj_lines, 8)
    if (Math.round(y) > Math.round(pageHeight - 7)) {
      y = y_def
      x = x_def
      doc.addPage()
    }
    doc.text(obj, x_starLine, y, { align: 'left' });

    obj = `Il TITOLARE DEL TRATTAMENTO DEI DATI: Cool Projects S.r.l. in persona del Legale Rappresentante Pro-Tempore, con sede Legale in Via
Ostiense, 131 L, ROMA (RM), C.F e P.IVA 10577971004 e con sede operativa in ROMA (RM) in Via Ostiense, 131 L, PEC: ; EMAIL:
info@coolprojects.it; La informa ai sensi dell'art. 13 REGOLAMENTO UE n. 2016/679 (in seguito, "GDPR") che i Suoi dati saranno trattati con le modalità
e finalità seguenti:`

    y = y + ht
    obj_lines = 5
    ht = this.calcTextHeight(obj_lines, 8)
    if (Math.round(y + ht) > Math.round(pageHeight - 7)) {
      y = y_def
      x = x_def
      doc.addPage()
    }
    doc.text(obj, x_starLine, y, { align: 'left' });

    obj = `1. Oggetto del Trattamento
      Il Titolare tratterà i Suoi dati personali, identificativi e non sensibili (ad esempio nome, cognome, ragione sociale, indirizzo, telefono, e-mail,
      riferimenti bancari e di pagamento) in seguito " dati personali " o anche " dati " ) da Lei comunicati in occasione della conclusione di obblighi 
      precontrattuali- contrattuali e post contrattuali - ordini-preventivi e/o similari costituiti e costituendi anche a distanza e comunque solo per 
      finalità connesse e/o strumentali all'attività e per i servizi del Titolare.`

    obj_lines = 6
    y = y + ht
    ht = this.calcTextHeight(obj_lines, 8)
    if (Math.round(y + ht) > Math.round(pageHeight - 7)) {
      y = y_def
      x = x_def
      doc.addPage()
    }
    doc.text(obj, x_starLine, y, { align: 'left' });

    obj = `2. Finalità del Trattamento
      I suoi dati personali sono trattati: A) Senza il Suo consenso espresso art. 6 Lett. b), e), per le finalità di Servizio: - Concludere i contratti
      per i servizi del Titolare; - Adempiere agli obblighi pre-contrattuali, contrattuali . post contrattuali e fiscali derivanti da rapporti con Lei 
      in essere, - Adempiere agli obblighi previsti dalla Legge, da un regolamento, dalla normativa comunitaria o da un ordine dell'Autorità (come ad 
      esempio in materia di antiriciclaggio (D. lgs 231/2007 e successive modifiche ed integrazioni); - Esercitare i diritti del Titolare, ad esempio il 
      diritto di difesa in giudizio; B) Solo previo Suo specifico e distinto consenso art. 7: - inviarLe via e-mail, posta e/o sms e/o contatti telefonici,
      news letter, comunicazioni commerciali e/o materiale pubblicitario su prodotti o servizi offerti dal Titolare. Le segnaliamo che se siete già nostri
      clienti, potremmo inviarLe comunicazioni commerciali relative ai servizi e prodotti del Titolare analoghi a quelli di cui ha già usufruito,
      salvo Suo dissenso.`

    obj_lines = 9
    y = y + ht
    ht = this.calcTextHeight(obj_lines, 8)
    if (Math.round(y + ht) > Math.round(pageHeight - 7)) {
      y = y_def
      x = x_def
      doc.addPage()
    }
    doc.text(obj, x_starLine, y, { align: 'left' });

    obj = `3. Modalità di Trattamento
      3.1 Il trattamento dei Suoi dati personali è realizzato per mezzo delle operazioni indicate all'art. 4 n. 2 e precisamente: raccolta, registrazione,
      organizzazione, conservazione, consultazione, elaborazione, modificazione, selezione, estrazione, raffronto, utilizzo, interconnessione, blocco,
      comunicazione, cancellazione e distruzione dei Suoi dati. I Suoi dati personali sono sottoposti a trattamento sia cartaceo che elettronico. 
      I medesimi dati verranno trattati nel rispetto della sicurezza e riservatezza necessarie. 3.2 Il Titolare non adotta alcun processo decisionale 
      automatizzato, compresa la profilazione, di cui all'articolo 22, paragrafi 1 e 4. 3.3 Le segnaliamo che, nel rispetto dei principi di liceità,
      limitazione delle finalità e minimizzazione dei dati, ai sensi dell'art. 5, previo il Suo consenso libero ed esplicito espresso in calce alla presente
      informativa, il Titolare tratterà e conserverà i Suoi dati personali per il tempo strettamente necessario per il conseguimento delle finalità per le
      quali sono stati raccolti e trattati. Decorso tale termine, i
      dati verranno conservati per un tempo non superiore ai 30 giorni, trascorsi i quali i dati verranno definitivamente cancellati. Specifiche misure di
      sicurezza sono osservate per prevenire la perdita dei dati, usi illeciti o non corretti ed accessi non autorizzati. 3.4 Si fa presente che ai fini della
      normativa in materia di antiriciclaggio i dati relativi alle prestazioni rientranti nella predetta disciplina legislativa, verranno, in quanto previsto dalla
      Legge conservati dieci anni dalla ultimazione della prestazione/contratto e similari.`

    obj_lines = 12.5
    y = y + ht
    ht = this.calcTextHeight(obj_lines, 8)
    if (Math.round(y + ht) > Math.round(pageHeight - 7)) {
      y = y_def
      x = x_def
      doc.addPage()
    }
    doc.text(obj, x_starLine, y, { align: 'left' });

    obj = `4. Accesso ai dati
    I Suoi dati potranno essere resi accessibili per le finalità di cui all'art. 2. A) e 2. B) (solo previo Suo specifico e distinto consenso): - A dipendenti e
    collaboratori del Titolare per l'espletamento dei rapporti contrattuali in essere con il Titolare nella loro qualità di autorizzati e/o incaricati e/o
    Responsabili interni del Trattamento e/o Amministratori del Sistema laddove necessario; - a società terze o altri soggetti (a titolo indicativo, istituti di
    credito, studi professionali, consulenti, società di assicurazione per la prestazione di servizi assicurativi inerenti i rapporti contrattuali in essere con il
    Titolare (ad esempio adempimenti fiscali e similari- fornitori, istituti di credito etc) che svolgono attività di outsourcing per il Titolare, nella loro qualità
    di responsabili esterni del Trattamento). I Suoi dati non verranno in alcun modo diffusi e/o divulgati.`


    obj_lines = 8
    y = y + ht
    ht = this.calcTextHeight(obj_lines, 8)
    if (Math.round(y + ht) > Math.round(pageHeight - 7)) {
      y = y_def
      x = x_def
      doc.addPage()
    }
    doc.text(obj, x_starLine, y, { align: 'left' });

    obj = `5. Comunicazione dei dati
      Senza la necessità di un espresso consenso art.6 lett. b) e c), il Titolare potrà comunicare i Suoi dati per le finalità di cui all'art. 2.A) a Organismi di
      Vigilanza (quali IVASS), Autorità Giudiziarie, a società di assicurazione per la prestazione di servizi assicurativi, nonché a quei soggetti per i quali la
      comunicazione sia obbligatoria per legge per l'espletamento delle finalità dette. Detti soggetti tratteranno i dati nella loro qualità di autonomi titolari del
      trattamento.`

    obj_lines = 6
    y = y + ht
    ht = this.calcTextHeight(obj_lines, 8)
    if (Math.round(y + ht) > Math.round(pageHeight - 7)) {
      y = y_def
      x = x_def
      doc.addPage()
    }
    doc.text(obj, x_starLine, y, { align: 'left' });

    //Splitto il pdf
    doc.addPage()
    y= 0
    ht = this.calcTextHeight(obj_lines, 4)

    obj = `6. Trasferimento dati
      La gestione e la conservazione dei dati avverrà su server ubicati all'interno delle sedi ufficiali della società Titolare, a cura del Titolare e dei suoi
      collaboratori/incaricati e/o autorizzati. I suoi dati non verranno in alcun modo trasferiti ad un paese terzo o ad una organizzazione internazionale.`

    obj_lines = 3
    y = y + ht
    ht = this.calcTextHeight(obj_lines, 8)
    if (Math.round(y + ht) > Math.round(pageHeight - 7)) {
      y = y_def
      x = x_def
      doc.addPage()
    }
    doc.text(obj, x_starLine, y, { align: 'left' });

    obj = `7. Natura del conferimento dei dati e conseguenze del rifiuto di rispondere
      Il conferimento dei dati per le finalità di cui all'art. 2. A) è obbligatorio per l'espletamento dell'oggetto del contratto come al punto 1 riportato. In loro
      assenza, non potremmo garantirLe i servizi dell'art. 2. A). Il conferimento dei dati per le finalità di cui al punto 2.B) è invece facoltativo. Può quindi
      decidere di non conferire alcun dato o di negare successivamente la possibilità di trattare dati già forniti: in tal caso, non potrà ricevere news letter,
      comunicazioni commerciali e materiale pubblicitario inerenti ai Servizi offerti dal Titolare. Continuerà comunque ad avere diritto ai Servizi di cui
      all'art. 2. A).`

    obj_lines = 6
    y = y + ht
    ht = this.calcTextHeight(obj_lines, 8)
    if (Math.round(y + ht) > Math.round(pageHeight - 7)) {
      y = y_def
      x = x_def
      doc.addPage()
    }
    doc.text(obj, x_starLine, y, { align: 'left' });


    obj = `8. Diritti dell'interessato
      Nella Sua qualità di interessato, ha i diritti di cui agli articoli: 7.3 comma, 15,16,17,18,19,20,21,22 del REGOLAMENTO UE n. 2016/679 GDPR e
      precisamente i diritti di: I. Ottenere la conferma o meno dell'esistenza o meno di dati personali che La riguardano, anche se non ancora registrati, e la
      loro comunicazione in forma intellegibile; II. Ottenere l'indicazione:a) dell'origine dei dati personali; b) delle finalità e modalità di trattamento; c) della
      logica applicata in caso di trattamento effettivo con l'ausilio degli strumenti elettronici; d) degli estremi identificativi del Titolare, dei responsabili e del
      rappresentante designato ai sensi dell'art. 3 comma 1; e) dei soggetti o delle categorie di soggetti ai quali i dati personali possono essere comunicati o
      che possono venirne a conoscenza in qualità di rappresentante designato nel territorio dello Stato, di Responsabili o Incaricati; III. Ottenere: a)
      l'aggiornamento, la rettifica ovvero, quando vi ha interesse, l'integrazione dei dati; b) la cancellazione, la trasformazione in forma anonima o il blocco
      dei dati trattati in violazione di legge, compresi quelli di cui non è necessaria la conservazione in relazione agli scopi per i quali i dati sono stati raccolti
      o successivamente trattati; c) l'attestazione che le operazioni di cui alle lettere a)e b) sono state portate a conoscenza, anche per quanto riguarda il loro
      contenuto, di coloro ai quali i dati sono stati comunicati o diffusi, eccettuato il caso in cui tale adempimento si rivela impossibile o comporta un
      impiego di mezzi manifestatamente sproporzionato rispetto al diritto tutelato; IV. Opporsi, in tutto o in parte: A) per motivi legittimi al trattamento dei
      dati personali che La riguardano, ancorché pertinenti allo scopo della raccolta; b) al trattamento dei dati personali che La riguardano ai fini dell'invio
      del materiale pubblicitario o di vendita diretta o per il compimento di ricerche di mercato o di comunicazione commerciale, mediante e-mail e/o posta
      cartacea. V. In caso di sottoscrizione di una qualsiasi forma di consenso al trattamento richiesto dalla Titolare del Trattamento dei dati, si fa presente
      che l'interessato può revocarloin qualsiasi momento, fatti salvi gli adempimenti obbligatori previsti dalla normativa vigente al momento della richiesta
      di revoca, contattando il Titolare del trattamento ai recapiti in epigrafe riportati per esteso. Ove applicabili, ha altresì i diritti di cui agli articoli dal 16 al
      22 (Diritto di rettifica, diritto all'oblio, diritto di limitazione del trattamento, diritto alla portabilità dei dati, diritto di opposizione), nonché diritto di
      reclamo all'Autorità Garante ed al processo decisionale automatizzato.`
    obj_lines = 16
    y = y + ht
    ht = this.calcTextHeight(obj_lines, 8)
    if (Math.round(y + ht) > Math.round(pageHeight - 7)) {
      y = y_def
      x = x_def
      doc.addPage()
    }
    doc.text(obj, x_starLine, y, { align: 'left' });

    obj = `9. Modalità di esercizio dei diritti
      9.1 Potrà in qualsiasi momento esercitare i Suoi diritti inviando una raccomandata e/o una PEC al Titolare agli indirizzi in epigrafe indicati. 9.2
      L'interessato ha altresì diritto ai sensi e per gli effetti dell'articolo 77, di proporre reclamo anche direttamente all'Autorità Garante e di Controllo.`
    obj_lines = 3
    y = y + ht
    ht = this.calcTextHeight(obj_lines, 8)
    if (Math.round(y + ht) > Math.round(pageHeight - 7)) {
      y = y_def
      x = x_def
      doc.addPage()
    }
    doc.text(obj, x_starLine, y, { align: 'left' });

    obj = `10. Titolare, Responsabili e Incaricati/Autorizzati.
      Il Titolare del Trattamento è in epigrafe indicata. L'elenco aggiornato degli autorizzati al trattamento è custodito presso la sede legale del Titolare del
      trattamento. Ai sensi degli articoli 4,5 e 7, dichiaro di aver preso visione della presente Informativa.`
    obj_lines = 4
    y = y + ht
    ht = this.calcTextHeight(obj_lines, 8)
    if (Math.round(y + ht) > Math.round(pageHeight - 7)) {
      y = y_def
      x = x_def
      doc.addPage()
    }
    doc.text(obj, x_starLine, y, { align: 'left' });

    obj = `Firma del Titolare del Trattamento dei dati
    General Manager ( Procuratore )
    Firma
  
  

    ______________________________`;
    obj_lines = 4
    y = y + ht
    ht = this.calcTextHeight(obj_lines, 8)
    if (Math.round(y + ht) > Math.round(pageHeight - 7)) {
      y = y_def
      x = x_def
      doc.addPage()
    }
    doc.text(obj, x_starLine + 150, y, { align: 'center' });

 

    doc.save( order.job.code_slug+ '.' + order.code + '_' + vendor.name + '.pdf');
    console.log(vendor.name)
    doc.output('dataurlnewwindow', {filename: order.job.code_slug+ '.' + order.code+ '_' + vendor.name}); //to check pdf generate

    //SET FOOTER PAGE
    this.setFooter(doc)



    //doc.output('dataurlnewwindow');





  }


  /**
   *  
  *
  * @returns GET PROJECT CODE
  */

  getProjectCode(code) {

    let codes = code.split('.')
    let string = ''
    for (var i = 0; i < codes.length - 5; i++) {
      if (i != 0)
        string += '.' + codes[i]
      else
        string += codes[i]
    }
    return string;
  }

  /**
  * 
  *
  * @returns GET ORDER CODE
  */

  getOrderCode(code) {

    let codes = code.split('.')
    let string = ''
    for (var i = 5; i < codes.length; i++) {
      if (i != 5)
        string += '.' + codes[i]
      else
        string += codes[i]
    }
    return string;
  }






  private handleError<T>(operation = 'operation', result?: T) {

    return (error: any): Observable<T> => {
      const status = error == 'Forbidden' ? 403 : 500
      this.presentToast(status, error == 'undefined' ? 'Internal Server Error' : error, operation)
      return of(result as T);
    };
  }


  async presentToast(status, statusText, message) {
    const toast = await this.toastController.create({
      message: status + ' ' + statusText + ': "' + message + '"',
      duration: 2000,
      mode: 'ios',
      cssClass: 'toast',
      color: status != '200' ? 'danger' : 'primary'
    });
    toast.present();
  }

  logSys(src, status, opt?) {
    const debugStyle1 = 'background: linear-gradient(135deg,#471ee9,#3a49b7); border: 1px solid #9a9a9a; color: #ffffff; border-bottom-left-radius: 2px; border-top-left-radius: 2px; padding: 2px 0 2px 4px;';
    const debugStyle2 = 'background: #252b3e; border: 1px solid #9a9a9a; border-top-right-radius: 2px; border-bottom-right-radius: 2px; margin-left: -2px; padding: 2px 4px; color: white;';

  }




  // Save result of API requests
  private setLocalData(key, data) {
    this.storage.set(`${API_STORAGE_KEY}-${key}`, data);
  }

  // Get cached API result
  private getLocalData(key) {
    return this.storage.get(`${API_STORAGE_KEY}-${key}`);
  }

  // Calcola lo sconto sulla linea a seconda del tipo
  calculateSingleDiscount(val, row) {

    let approved = row.discounts.filter(e => e.approved)
    console.log(approved, approved.length);


    if (approved.length == 1) {
      console.log(val, row.qty);

      //return val * row.qty


      console.log('----', approved[0]);

      let discount_value = approved[0].value
      let discount_symbol = approved[0].discount_type

      console.log(discount_value, discount_symbol);


      switch (discount_symbol) {
        case 'P':
          val = this.calcPercent(val * row.qty, discount_value)
          break;

        case 'S':
          val = this.calcSubtraction(val, row.qty, discount_value)
          break;

        case 'D':

          break;

        default:
          break;
      }

      return val >= 0 ? val : 0

    } else if (approved.length == 0 || row.qty == 0) {
      console.log(val, row.qty);

      return 0
    }
  }

  // Calcola lo sconto sulla linea percent
  calcPercent(val, percentage) {
    console.log(val, percentage);

    let result = val * (percentage / 100);
    console.log(result);

    return val - result;
  }

  // Calcola lo sconto sulla linea fixed
  calcSubtraction(val, qty, discount_value) {
    console.log(val, qty, discount_value);

    let tot = val * qty;
    return tot - discount_value
  }


  calcTextHeight(lines, fontsize) {
    let f = (fontsize / 2)
    return (Math.round(f) * lines) - 1;
  }

  setFooter(doc) {
    const pages = doc.getCurrentPageInfo().pageNumber;
    doc.setFont(undefined, 'normal', undefined);
    doc.setTextColor('#8a8a8a');
    doc.setFontSize(8);  //Optional        
    for (let j = 1; j < pages + 1; j++) {
      doc.setPage(j);
      let pageCurrent = doc.getCurrentPageInfo().pageNumber; //Current Page            
      doc.text('page: ' + pageCurrent + '/' + pages, 190, 292);
    }
  }

  getContact(contacts,type){
    console.log(contacts);
    console.log(type);
    
    
    if(contacts){
        if(type == 'Mail'){         
          var a = contacts.filter((element) => {            
                return element.type.name === 'Mail';              
          })   
          return (a.length > 0) ? a[0].contact : '-';   
        }       
        
    
        if(type == 'PEC'){
              var a = contacts.filter((element) => {            
                return element.type.name === 'PEC';              
          }) 
          return (a.length > 0) ? a[0].contact : '-';
            
        }
    
        if(type == 'Phone'){
          var a = contacts.filter((element) => {            
                return element.type.name === 'Phone';              
          })  
          return (a.length > 0) ? a[0].contact : '-';
        }
    }

    return '-';

    
  }

  formatMoney(number){
    console.log(number)
    number = number.toFixed(2);
    console.log(number)
    return '€ ' + new Intl.NumberFormat('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(number);
    //return '€ ' + new Intl.NumberFormat('it-IT').format(number);
  }




}
