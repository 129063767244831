// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon-close {
  font-size: 22px;
  position: absolute;
  right: 15px;
  cursor: pointer;
}

.list-discount {
  overflow-y: scroll;
  display: block;
  height: 160px;
  padding: 2px 3px;
  background: #222222;
}

ion-label.lbl {
  padding: 13px 6px;
  display: block;
}

/* .badge_id_line {
    margin: 0px 6px;

    background: #0097ab;
    padding: 4px 0px;
    border-radius: 50%;
    min-width: 24px;
    min-height: 24px;
    text-align: center;
} */
.approval {
  margin-top: 6px;
  /* background: #ffc409; */
  width: 100%;
  display: block;
  /* color: #1e1e1e; */
  padding: 9px 10px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  border-top: 1px solid #ffc409;
  border-left: 1px solid #ffc409;
  border-right: 1px solid #ffc409;
}

.border-wait {
  /* border-left: 1px solid #ffc409; */
  /* border-right: 1px solid #ffc409; */
  border-bottom: 1px solid #ffc409;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top: 1px solid #ffc409;
  margin-top: 5px;
}

.td-id,
.th-id {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/app/orders/components/discount-line/discount-line.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,kBAAA;EACA,WAAA;EACA,eAAA;AACJ;;AAEA;EACI,kBAAA;EACA,cAAA;EACA,aAAA;EACA,gBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,iBAAA;EACA,cAAA;AACJ;;AAEA;;;;;;;;;GAAA;AAWA;EACI,eAAA;EACA,yBAAA;EACA,WAAA;EACA,cAAA;EACA,oBAAA;EACA,iBAAA;EACA,eAAA;EACA,yBAAA;EACA,gBAAA;EAEA,6BAAA;EACA,8BAAA;EACA,+BAAA;AADJ;;AAIA;EACI,oCAAA;EACA,qCAAA;EACA,gCAAA;EACA,8BAAA;EACA,+BAAA;EACA,6BAAA;EACA,eAAA;AADJ;;AAKA;;EAEI,aAAA;AAFJ","sourcesContent":[".icon-close {\n    font-size: 22px;\n    position: absolute;\n    right: 15px;\n    cursor: pointer;\n}\n\n.list-discount {\n    overflow-y: scroll;\n    display: block;\n    height: 160px;\n    padding: 2px 3px;\n    background: #222222;\n}\n\nion-label.lbl {\n    padding: 13px 6px;\n    display: block;\n}\n\n/* .badge_id_line {\n    margin: 0px 6px;\n\n    background: #0097ab;\n    padding: 4px 0px;\n    border-radius: 50%;\n    min-width: 24px;\n    min-height: 24px;\n    text-align: center;\n} */\n\n.approval {\n    margin-top: 6px;\n    /* background: #ffc409; */\n    width: 100%;\n    display: block;\n    /* color: #1e1e1e; */\n    padding: 9px 10px;\n    font-size: 14px;\n    text-transform: uppercase;\n    font-weight: 600;\n\n    border-top: 1px solid #ffc409;\n    border-left: 1px solid #ffc409;\n    border-right: 1px solid #ffc409;\n}\n\n.border-wait{\n    /* border-left: 1px solid #ffc409; */\n    /* border-right: 1px solid #ffc409; */\n    border-bottom: 1px solid #ffc409;\n    border-bottom-left-radius: 5px;\n    border-bottom-right-radius: 5px;\n    border-top: 1px solid #ffc409;\n    margin-top: 5px;\n\n}\n\n.td-id,\n.th-id{\n    display: none;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
