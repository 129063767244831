/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable object-shorthand */
/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/semi */
/* eslint-disable @typescript-eslint/naming-convention */


import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Platform, ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, map, tap, timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../authentication/authentication.service';
import { NetworkService, ConnectionStatus } from '../network/network.service';
//import { Storage } from '@ionic/storage';
import { OfflineManagerService } from '../network/offline-manager.service';

//import environment from '../../../config.json';
import { Project } from 'src/app/projects/models/projects';
import { Docs } from 'src/app/documents/models/docs';
import { Ddt } from 'src/app/trasport_documents/models/ddt.model';


const API_STORAGE_KEY = 'ddfService';
const TOKEN_KEY = 'access-token';

@Injectable({
    providedIn: 'root'
})
export class DdtService {

    private url: string = 'http';
    private token: any;
    private projects: Project[];
    private documents: Docs[];
    private document: Docs;
    private project_types: any[];
    //public docList = new BehaviorSubject(null);

    constructor(
        private storage: Storage,
        private plt: Platform,
        private http: HttpClient,
        public toastController: ToastController,
        private authService: AuthenticationService,
        private networkService: NetworkService,
        private offlineManager: OfflineManagerService
    ) {

        /**
         * Map url API
         */
        this.url = environment.SSL.active ? this.url + 's' : this.url
        this.url += '://' + environment.API_URI.url + ':' + environment.API_URI.port + environment.API_URI.path

        this.authService.currentToken.subscribe(x => {
            //console.log(x);
            this.token = x //= JSON.parse(localStorage.getItem(TOKEN_KEY))

        })

    }

    /**
     *
     * DDT
     */


    /**
     *
     *
     * @returns list ddt
     */
    mahazin_ddts_list(params, direction): Observable<any> {
        //return this.http.get<any>(`${this.url}mahazin/ddts/${direction}/${params}`)
        return this.http.get<any>(`${this.url}mahazin/ddts/`)
            .pipe(
                timeout(16000),
                tap(data => {
                    console.log(data);
                    this.setLocalData('list-' + direction, data);

                    return data
                }),
                catchError(this.handleError<any[]>('Get ddt list', []))
            );

    }


    /**
     *
     *  LINEE DDT
     *
     */


    /**
     *
     * @param ddt
     * @returns create line ddt
     */

    mahazin_ddtlines_create(form, direction, ddt_id) {
        console.log(form, direction, ddt_id)
        /*return this.http.post<any>(`${this.url}mahazin/flows/${direction}/${ddt_id}/lines/`, form)*/
        return this.http.post<any>(`${this.url}mahazin/ddtlines/`, form)
            .pipe(
                tap(data => {
                    console.log(data);
                }),
                catchError(this.handleError<any[]>('Create line ddt', []))
            );
    }

    /**
     *
     * @param ddt
     * @returns update line ddt
     */

    mahazin_ddtline_update(form, id) {
        // return this.http.put<any>(`${this.url}mahazin/flows/${ddt.type}/${ddt.id}/line/${id}`, form)
        return this.http.put<any>(`${this.url}mahazin/ddtline/${id}/`, form)
            .pipe(
                tap(data => {
                    console.log(data);
                }),
                catchError(this.handleError<any[]>('Update line ddt', []))
            );
    }

        /**
     *
     * @param ddt
     * @returns delete line ddt
     */

        mahazin_ddtline_delete(id) {
            // return this.http.put<any>(`${this.url}mahazin/flows/${ddt.type}/${ddt.id}/line/${id}`, form)
            return this.http.delete<any>(`${this.url}mahazin/ddtline/${id}/`)
                .pipe(
                    tap(data => {
                        console.log(data);
                    }),
                    catchError(this.handleError<any[]>('delete line ddt', []))
                );
        }

    /**
     *
     *
     * @returns list lines ddt
     */
    mahazin_ddt_read(params, id, direction): Observable<any> {
        /*return this.http.get<any>(`${this.url}mahazin/flows/${direction}/${id}/lines/${params}`)*/
        return this.http.get<any>(`${this.url}mahazin/ddt/${id}/`)
            .pipe(
                timeout(16000),
                tap(data => {
                    console.log(data);
                    return data
                }),
                catchError(this.handleError<any[]>('Get ddt by id', []))
            );

    }

    /**
     *
     *
     * @returns Type Ddt Types
     */
    getDdtTypes(params): Observable<any> {
        return this.http.get<any>(`${this.url}mahazin/producttypes/${params}`)
            .pipe(
                timeout(16000),
                tap(data => {
                    console.log(data);
                    this.setLocalData('types', data);
                    return data
                }),
                catchError(this.handleError<any[]>('Get product types', []))
            );
    }

    /**
     *
     *
     * @returns Type Ddt Category
     */
    getDdtCategories(params): Observable<any> {
        return this.http.get<any>(`${this.url}mahazin/productcategories/${params}`)
            .pipe(
                timeout(16000),
                tap(data => {
                    console.log(data);
                    this.setLocalData('categories', data);
                    return data
                }),
                catchError(this.handleError<any[]>('Get product categories', []))
            );
    }

    /**
     *
     *
     * @returns Get all Product autocomplete lines ddt
     */
    getProduct(params): Observable<any> {
        return this.http.get<any>(`${this.url}mahazin/products/${params}`)
            .pipe(
                timeout(16000),
                tap(data => {
                    console.log(data);
                    return data
                }),
                catchError(this.handleError<any[]>('Get products list', []))
            );
    }




    /**
     *
     * @param id id of product
     * @returns product
     */
    getDdtById(id): Observable<any> {

        /*return this.http.get<any>(`${this.url}mahazin/product/${id}/`)*/
        return this.http.get<any>(`${this.url}mahazin/ddtline/${id}/`)
            .pipe(
                timeout(16000),
                tap(data => {
                    console.log(data);
                    return data
                }),
                catchError(this.handleError<any[]>('Get product by id', []))
            );

    }


    /**
     *
     * @param product
     * @returns
     */

    mahazin_ddts_create(ddt) {
        console.log(ddt)
        return this.http.post<any>(`${this.url}mahazin/ddts/`, ddt)
            .pipe(
                tap(data => {
                    console.log(data);
                }),
                catchError(this.handleError<any[]>('Create ddts', []))
            );
    }

    updateDdt(product: Ddt, id) {
        return this.http.put<any>(`${this.url}mahazin/product/${id}/`, product)
            .pipe(
                tap(data => {
                    console.log(data);
                }),
                catchError(this.handleError<any[]>('Update product', []))
            );
    }

    /**
     *
     * @param ddt
     * @returns put status order
     */

    mahazin_ddt_update(ddt, direction, form) {
        //return this.http.put<any>(`${this.url}mahazin/flows/${direction}/${ddt.id}/status/`, form)
        return this.http.put<any>(`${this.url}mahazin/ddt/${ddt.id}/`, form)
            .pipe(
                tap(data => {
                    console.log(data);
                }),
                catchError(this.handleError<any[]>('put status ddt', []))
            );
    }


    /**
     *
     * @param product
     * @returns
     */

    craeteAdvance(line, ddt, obj) {

        return this.http.post<any>(`${this.url}mahazin/flows/${ddt.type}/${ddt.id}/line/${line.id}/`, obj)
            .pipe(
                tap(data => {
                    console.log(data);
                }),
                catchError(this.handleError<any[]>('Update line', []))
            );
    }





    private handleError<T>(operation = 'operation', result?: T) {

        return (error: any): Observable<T> => {

            //console.error(error);
            console.log(result, error);

            console.log(`${operation} failed`, error);
            const status = error == 'Forbidden' ? 403 : 500
            this.presentToast(status, error == 'undefined' ? 'Internal Server Error' : error, operation)
            return of(result as T);
        };
    }


    async presentToast(status, statusText, message) {
        const toast = await this.toastController.create({
            message: status + ' ' + statusText + ': "' + message + '"',
            duration: 2000,
            mode: 'ios',
            cssClass: 'toast',
            color: status != '200' ? 'danger' : 'primary'
        });
        toast.present();
    }

    logSys(src, status, opt?) {
        const debugStyle1 = 'background: linear-gradient(135deg,#471ee9,#3a49b7); border: 1px solid #9a9a9a; color: #ffffff; border-bottom-left-radius: 2px; border-top-left-radius: 2px; padding: 2px 0 2px 4px;';
        const debugStyle2 = 'background: #252b3e; border: 1px solid #9a9a9a; border-top-right-radius: 2px; border-bottom-right-radius: 2px; margin-left: -2px; padding: 2px 4px; color: white;';

        //console.log('ForceRefresh API ', status, this.networkService.getCurrentNetworkStatus(), this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline);
        console.log(`%cApiService %c%s`, debugStyle1, debugStyle2, ' ' + src, status);
    }




    // Save result of API requests
    private setLocalData(key, data) {
        this.storage.set(`${API_STORAGE_KEY}-${key}`, data);
    }

    // Get cached API result
    private getLocalData(key) {
        return this.storage.get(`${API_STORAGE_KEY}-${key}`);
    }




}
