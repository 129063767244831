import {
  AfterContentChecked,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  NavController,
  ModalController,
  IonNav,
  IonRouterOutlet,
  IonInfiniteScroll,
  IonSearchbar,
  IonContent,
  ToastController,
} from '@ionic/angular';
import { VendorsService } from 'src/app/services/vendors/vendors.service';
import { VendorUpdatePage } from '../vendor-update/vendor-update.page';
import {
  animate,
  query,
  sequence,
  stagger,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

import { NewVendorComponent } from '../new-vendor/new-vendor.component';
import { VendorDetailsPage } from '../vendor-details/vendor-details.page';
import { TabsService } from 'src/app/services/tabs/tabs.service';
import { TitleAppService } from 'src/app/services/utils/title-app/title-app.service';
import { Router } from '@angular/router';
import { RoutingStateService } from 'src/app/services/routing-state/routing-state.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';

export class Page {
  limit: number = 25;
  // The number of elements in the page
  size: number = 25;
  // The total number of elements
  totalElements: number = 0;
  // The total number of pages
  totalPages: number = 0;
  // The current page number
  pageNumber: number = 0; // Zero-based index for ngx-datatable
}

const listAnimation = trigger('listAnimation', [
  transition('* <=> *', [
    query(
      ':enter',
      [
        style({ opacity: 0 }),
        stagger('180ms', animate('300ms ease-out', style({ opacity: 1 }))),
      ],
      { optional: true }
    ),
    query(':leave', animate('200ms', style({ opacity: 0 })), {
      optional: true,
    }),
  ]),
]);
@Component({
  selector: 'app-vendors',
  templateUrl: './vendors-list.page.html',
  styleUrls: ['./vendors-list.page.scss'],
  // ✨ New! Defining the animation
  animations: [listAnimation],
  encapsulation: ViewEncapsulation.None
})
export class VendorsListPage {
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  @ViewChild(IonContent) content: IonContent;
  @ViewChild(IonSearchbar) myInput: IonSearchbar;
  @ViewChild('myTable') table: DatatableComponent;
  @ViewChild('customerTemplate', { static: true }) customerTemplate: TemplateRef<any>;
  @ViewChild('vendorTemplate', { static: true }) vendorTemplate: TemplateRef<any>;
  @ViewChild('nameTemplate', { static: true }) nameTemplate: TemplateRef<any>;
  @ViewChild('vatTemplate', { static: true }) vatTemplate: TemplateRef<any>;
  @ViewChild('fiscalTemplate', { static: true }) fiscalTemplate: TemplateRef<any>;
  @ViewChild('actionTemplate', { static: true }) actionTemplate: TemplateRef<any>;

  elementSearch:any

  fab: boolean = false;
  customAlertOptions: any = {
    header: 'List groups',
    //subHeader: 'Select group for user',
    message: 'Select group for user',
    translucent: true,
  };
  vendors: any = [];
  vendorsBackup: any = [];
  columns: any;
  searching = false;
  enableSearch = false;
  res: any = [];
  page = new Page();
  loadingIndicator = true;
  companiesTypes:any = []
  isSearch: boolean = false;

  navMenu = [
    { action: 'openNewVendorModal', label: 'New', icon: 'receipt-outline' },
    { action: 'toggleSearch', label: 'Search' },
    { action: '', label: 'Export' },
  ];

  constructor(
    private navCtrl: NavController,
    private api: VendorsService,
    private modalController: ModalController,
    //private nav: IonNav,
    private titleAppService: TitleAppService,
    private routerOutlet: IonRouterOutlet,
    private tabService: TabsService,
    private router: Router,
    private routingState: RoutingStateService,
    private toastController: ToastController
  ) {
    /*  this.tabService.currentTabSubject.subscribe(e => {
       console.log(e);
       if (e.name == 'Vendor' || e.id == 4) {
         this.total_page = 0;
         this.page_number = 1;
         this.myInput.value = "";
         this.vendors = [];
         this.getVendors(false, '')
       }
 
     }) */
  }

  ionViewWillEnter() {
    this.titleAppService.title.next('Companies');
    this.myInput ? (this.myInput.value = '') : null;
    this.vendors = [];
    this.setupColumns();
    this.getCompanyTypes()
    this.getVendors();
  }

  getVendors() {
    this.isSearch = false;
    this.fab = true;

    this.api.getCompaniesList().subscribe((data) => {
      
      this.page.totalElements = data.meta.total_items;
      this.page.totalPages = data.meta.num_pages
      this.vendors = data.results;
      this.loadingIndicator = false
    });
  }

  getCompanyTypes(){
    this.api.getCompaniesTypes().subscribe(e=>{
      this.companiesTypes = e.results
      console.log(this.companiesTypes);
      
    })
  }

  filterSelected: boolean = false;
  selectedOption: boolean = true
  selectedType: string | null = null;
  selectedFilter(event: any, noFilter?: boolean) {
    this.loadingIndicator = true;  // Imposta l'indicatore di caricamento su true all'inizio

    if (noFilter) {
      // Se noFilter è true, rimuovi il filtro
      this.selectedOption = false

      this.api.getCompaniesList().subscribe((data) => {
        this.page.totalElements = data.meta.total_items;
        this.page.totalPages = data.meta.num_pages;
        this.vendors = data.results;
        this.loadingIndicator = false;
        this.selectedOption = true
        this.filterSelected = false; // Rimuove il filtro
        this.selectedType = null; // Reset the selected type
      });
    } else {
      // Se noFilter è false, applica il filtro
      let type_id = event?.detail?.value;
      let url = "?type_id=" + type_id;
      this.filterSelected = true; // Rimuove il filtro
      
      this.api.getCompaniesList(url).subscribe((data) => {
        this.page.totalElements = data.meta.total_items;
        this.page.totalPages = data.meta.num_pages;
        this.vendors = data.results;
        this.loadingIndicator = false;
      });
    }
  }

  clearFilter() {
    this.selectedFilter(null, true);
  }

  setupColumns() {
    this.columns = [
      {
        prop: 'action',
        name: 'Actions',
        flexGrow: 1,
        sortable: true,
        draggable: false,
        resizeable: true,
        maxWidth: 100,
        minWidth: 100,
        cellTemplate: this.actionTemplate
      },
      {
        prop: 'name',
        name: 'Name',
        flexGrow: 4,
        sortable: true,
        draggable: false,
        resizeable: true,
        maxWidth: 500,
        minWidth: 300,
        cellTemplate: this.nameTemplate
      },
      {
        prop: 'code_vat',
        name: 'VAT Code',
        flexGrow: 4,
        sortable: true,
        draggable: false,
        resizeable: true,
        maxWidth: 300,
        minWidth: 200,
        cellTemplate: this.vatTemplate

      },
      {
        prop: 'code_fiscal',
        name: 'Fiscal code',
        flexGrow: 4,
        sortable: true,
        draggable: false,
        resizeable: true,
        maxWidth: 300,
        minWidth: 200,
        cellTemplate: this.fiscalTemplate

      },
      {
        prop: 'is_customer',
        name: 'Customer',
        flexGrow: 1,
        sortable: true,
        draggable: false,
        resizeable: true,
        maxWidth: 100,
        minWidth: 100,
        cellTemplate: this.customerTemplate
      },
      {
        prop: 'is_vendor',
        name: 'Vendor',
        flexGrow: 1,
        sortable: true,
        draggable: false,
        resizeable: true,
        maxWidth: 100,
        minWidth: 100,
        cellTemplate: this.vendorTemplate
      },
      // {
      //   prop: 'type.name',
      //   name: 'Type name',
      //   flexGrow: 3,
      //   sortable: true,
      //   draggable: false,
      //   resizeable: true,
      //   maxWidth: 300,
      //   minWidth: 100,
        
      // },
      
    ];
  }

  setPage(pageInfo?: any) {
    console.log(pageInfo);

    this.page.pageNumber = pageInfo.offset || 0;


    let url = this.concateQuery();
    console.log(url);

    this.api.getCompaniesList(url).subscribe((data) => {
      console.log(data);
      this.page.totalElements = data.meta.total_items;
      this.page.totalPages = this.page.totalElements / this.page.size;
      this.vendors = data.results; // Set the rows for the current page
      this.vendorsBackup = data.results; // Set the rows for the current page
      this.loadingIndicator = false


    });
  }

  concateQuery() {
    console.log('concateQuery');
    let params = JSON.parse(localStorage.getItem('filter_orders'))
  
    console.log(params);


    let url = '?page=' + (this.page.pageNumber + 1) + '&items=' + this.page.size;


    if (!params) return url
    params.map((param, i) => {
      let query = '';



      if (param && param != '' && param.search_data != '') {
        query += '&' + param.search_by + '=';
        query += param.search_data;

        url += query
      } else {
        console.log('else');

      }


    })
    console.log(url);

    return url
  }
  updateFilter(event:any){
    
    let name = event.detail.value
    this.elementSearch = event
    
    if(name !== ''){
      console.log('name non vuoto');
      let url = '?name='+name
      this.api.getCompaniesList(url).subscribe(data => {
        
        console.log(data);
        this.page.totalElements = data.meta.total_items;
        this.page.totalPages = this.page.totalElements / this.page.size;
        this.vendors = data.results; 
        this.loadingIndicator = false

      });
    }
    else{
      console.log('name vuoto');
      // this.rows = this.backupRows
      this.api.getCompaniesList().subscribe(data => {
        
        console.log(data);
        this.page.totalElements = data.meta.total_items;
        this.page.totalPages = this.page.totalElements / this.page.size;
        this.vendors = data.results; 
        this.loadingIndicator = false


      });
    }
  
    }

  onActivate(event: any) {
    // console.log(event);
    if(event.type === 'click'){
      if(event.cellIndex == 0){
        console.log(event.row);
        
        this.openEditVendorModal(event.row)
      }
      if(event.cellIndex == 2 ){
        this.copyToClipboard(event.row.code_vat)
      }
      if(event.cellIndex == 3){
        this.copyToClipboard(event.row.code_fiscal)
      }
    }
  }
  copyToClipboard(text: string): void {
    if (text) {
      const textToCopy = text;
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          console.log('Testo copiato negli appunti:', textToCopy);

          this.presentToast(textToCopy);
        })
        .catch((err) => {
          console.error('Errore durante la copia negli appunti:', err);
        });
    } else {
      console.log('Nessun testo tra parentesi quadre trovato');
      // Gestisci il caso in cui non ci sono parentesi quadre
    }
  }

  async openEditVendorModal(row:any) {
    console.log(row);
    
    const modal = await this.modalController.create({
      component: VendorUpdatePage,
      canDismiss: true,
      mode: 'md',
      cssClass: 'newOrderModalForm',
      componentProps: {
        vendor: row
      },
      //presentingElement: this.routerOutlet.nativeEl,
    })


    modal.onWillDismiss().then((e) => {
      console.log(e);
      
      // if (e.data && e.data.status != -1) {
        this.setPage(0)
        setTimeout(() => {
          this.updateFilter(this.elementSearch)
        }, 1000);
      // }
    });
    return await modal.present();
  }

  async presentToast(statusText) {
    const toast = await this.toastController.create({
      message: 'Code ' + statusText + ' copiato!',
      duration: 2000,
      mode: 'ios',
      cssClass: 'toast',
      color: 'success',
    });
    toast.present();
  }

  
  async openNewVendorModal() {
    const modal = await this.modalController.create({
      component: NewVendorComponent,
      canDismiss: true,
      cssClass: 'newOrderModalForm',
      mode: 'md',
      componentProps: {
        title: 'New Vendor',
        parent_type: 'root',
      },
      //presentingElement: this.routerOutlet.nativeEl,
    });

    modal.onWillDismiss().then((e) => {
      if (e.data && e.data.status != -1) {
        this.myInput.value = '';
        this.vendors = [];
        this.getVendors();
      }
    });
    return await modal.present();
  }




  fabCallBack(action) {
    console.log;

    if (action) {
      this[action]();
    } else {
      console.log('not call in component, run callback', 'list');
    }
  }

  methodAction(action) {
    try {
      this[action]();
    } catch {
      console.log('not call in parent component');
    }
    //console.log(action)
  }

  goBack() {
    this.router.navigate(['/' + this.routingState.navigateBack()]);
  }
}
