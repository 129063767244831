import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ActionSheetController, ToastController, Platform, LoadingController, PopoverController } from '@ionic/angular';
import { File, FileEntry, Entry, } from '@awesome-cordova-plugins/file/ngx';
import { Storage } from '@ionic/storage-angular';
import { finalize } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api/api.service';
import { SubMenuComponent } from '../../sub-menu/sub-menu.component';

// Nome variabile in db browser
const STORAGE_KEY = 'my_images';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
})
export class UploadComponent implements OnInit {

  // Parametri intestazione menu es:{'name': 'Aggiorna file','icon': 'refresh','method': 'toggleValue'}
  optionsMenu: any;
  // TRUE or FALSE mostra file in griglia o in lista
  showGrid: any;

  images = [];

  // Dichiarazione oggetto File collegato direttamente all'input
  selectedFile: File
  // Lista di tutti i nomi dei file preenti nella directory richiesta
  directories = [];
  // Nome della directory locale del dispositivo se vuota la root directory dell'app
  folder = '';
  // Reset for later copy/move operations
  copyFile: Entry = null;

  @Input() header: any;          // Parametri intestazione es:{title:''}, se vuoto o non dichiarato nascode l'intestazione
  @Input() colGrid: any;         // Prima griglia FORM UPLOAD: impostare valore colonne del template es:array[2,6,12], ['xl','md','xs']
  @Input() colGridFile: any;     // Seconda griglia LIST FILES: impostare valore colonne del template es:array[2,6,12], ['xl','md','xs']
  @Input() showListFile: any;    // Se true abilita in auto integrazione 
  @Input() showOptionsMenu: any; // abilita e disabilita il tasto di apertura del menu


  constructor(
    private api: ApiService,
    private file: File,
    private actionSheetController: ActionSheetController,
    private toastController: ToastController,
    private storage: Storage,
    private plt: Platform,
    private loadingController: LoadingController,
    private ref: ChangeDetectorRef,
    private popoverController: PopoverController,
    public platform: Platform
  ) {
    
    // Definisco le colonne vuote ( perciò  col="12" ) nel costruttore in attesa di eventuali binding dal parent
    this.colGrid = [];
    this.colGridFile = [];
    
    /**
     * Return sb-menu obj
     * @param { name } # Titolo del tasto
     * @param { icon } # Icona del tasto
     * @param { method } # Funzioni di default/custom del tasto collegato ad una funzione esistente
     *  
     */
    this.optionsMenu = [
      {
        'name': 'Aggiorna file',
        'icon': 'refresh',
        'method': 'toggleValue'
      },
      {
        'name': 'View List/Grid',
        'icon': 'swap-horizontal-outline',
        'method': 'toggleView'
      }
    ];
  }


  ngOnInit() {
    //this.api.getFiles().subscribe();
    this.plt.ready().then(() => {
      if(!this.platform.is('ios') || !this.platform.is('android')) return
      // All'avvio carico i dati dal db browser, se esistono
      this.loadStoredImages();
    });
  }
  /**
   * Verifica se esiste la chiave in db ed estrae i dati, vengono assegnati a "images"
   * altrimenti ricarica i documenti della directory fisica del dispositivo
   */
  loadStoredImages() {
    this.storage.get(STORAGE_KEY).then(images => {
      if (images) {
        let arr = JSON.parse(images);
        this.images = [];
        for (let img of arr) {
          let filePath = this.file.dataDirectory + img.file;
          let resPath = this.pathForImage(filePath);
          this.images.push({ name: img.name, path: img.path, filePath: filePath });
        }
      } else {
        this.loadDocuments();
      }
    });
  }

  /**
   * 
   * @param img Immagine url posizione fisica
   * @returns url link steing convertito dalla webView 
   */
  pathForImage(img) {
    if (img === null) {
      return '';
    }
    else{
      return img
    }
  }

  async presentToast(text) {
    const toast = await this.toastController.create({
      message: text,
      position: 'bottom',
      cssClass: 'toast',
      duration: 3000
    });
    toast.present();
  }



  /**
   * Menu actionsheet options load from es: Library, Camera, Directory locale
   */
  async selectImage() {
    const actionSheet = await this.actionSheetController.create({
      header: "Select Image source",
      buttons: [
        {
          text: 'Load from Library',
          handler: () => {
          }
        }/* ,
        {
          text: 'Load from your PC',
          handler: () => {
            //this.takePicture(this.camera.PictureSourceType.PHOTOLIBRARY);
          }
        } */,
        {
          text: 'Use Camera',
          handler: () => {
          }
        },
        {
          text: 'Cancel',
          role: 'cancel'
        },
        {
          text: 'Local phone',
          handler: () => {
            this.loadDocuments();
          }
        },
        {
          text: 'Cancel',
          role: 'cancel'
        }
      ]
    });
    await actionSheet.present();
  }

  /**
   * Carica i documenti presenti della directory fisica e salvo del db browser
   */
  loadDocuments() {
    this.plt.ready().then(() => {
      // Reset for later copy/move operations
      this.copyFile = null;
      //this.shouldMove = false;

      console.log('load docs');

      console.log(this.file.dataDirectory, this.folder);

      this.file.listDir(this.file.dataDirectory, this.folder).then(res => {
        this.directories = res;
        console.log(res);
        let images = []
        res.map((e, i, a) => {
          images.push({ name: e.name, path: this.pathForImage(e.nativeURL), filePath: e.nativeURL });
        })
        this.images = images;
        this.storage.set(STORAGE_KEY, JSON.stringify(this.images));

      });
    });
  }


  /**
   * Se selzionato library or camera, uso la classe "camera" per selezionare un'immagine o scatto dal device e salva una copia nella directory della app
   * @param sourceType 
   */


  /**
   * Funzione d prova per gemerare nomi in base ordine temporale
   * @returns 
   */
  createFileName() {
    var d = new Date(),
      n = d.getTime(),
      newFileName = n + ".jpg";
    return newFileName;
  }

  /**
   * Copia fisicamente l'immagine della memoria del dispositivo, directory della app
   * Chiamo l'aggiornamento del db browser
   * @param namePath 
   * @param currentName 
   * @param newFileName 
   */
  copyFileToLocalDir(namePath, currentName, newFileName) {
    console.log(namePath, currentName, newFileName);

    this.file.copyFile(namePath, currentName, this.file.dataDirectory, newFileName).then(success => {
      console.log(success);

      this.updateStoredImages(newFileName);
    }, error => {
      this.presentToast('Error while storing file.');
    });
  }

  /**
   * Aggiona per il nome passato la chiave in db browser
   * @param name Nome oggetto da aggiornare o aggiungere allo storage db browser
   */
  updateStoredImages(name) {
    this.storage.get(STORAGE_KEY).then(images => {
      let arr = JSON.parse(images);
      if (!arr) {
        let newImages = [name];
        this.storage.set(STORAGE_KEY, JSON.stringify(newImages));
      } else {
        arr.push(name);
        this.storage.set(STORAGE_KEY, JSON.stringify(arr));
      }

      let filePath = this.file.dataDirectory + name;
      let resPath = this.pathForImage(filePath);

      let newEntry = {
        name: name,
        path: resPath,
        filePath: filePath
      };

      this.images = [newEntry, ...this.images];
      this.ref.detectChanges(); // trigger change detection cycle
    });
  }

  /**
   * Per eliminare l'elemento selezionato dal db broser e device folder
   * Ricarico i documenti
   * @param imgEntry 
   * @param position 
   */
  deleteImage(imgEntry, position) {
    this.images.splice(position, 1);

    this.storage.get(STORAGE_KEY).then(images => {
      let arr = JSON.parse(images);
      let filtered = arr.filter(name => name != imgEntry.name);
      this.storage.set(STORAGE_KEY, JSON.stringify(filtered));

      var correctPath = imgEntry.filePath.substr(0, imgEntry.filePath.lastIndexOf('/') + 1);

      console.log(correctPath, imgEntry);

      let path = this.file.dataDirectory + this.folder;
      console.log(path, imgEntry.name);

      this.file.removeFile(path, imgEntry.name).then(() => {
        //this.loadDocuments();
      });
    });
  }

  /**
   * Chiamata dalla vista upload singola immagine
   * @param imgEntry immagine details obj
   */
  startUpload(imgEntry) {
    this.file.resolveLocalFilesystemUrl(imgEntry.filePath)
      .then(entry => {
        console.log(entry);

        //this.readFile(entry);

        // Leggo e invio alla func readFile
        (<FileEntry>entry).file(file => this.readFile(file))
      })
      .catch(err => {
        this.presentToast('Error while reading file.');
      });
  }

  /**
   * Ricevo il file creo in FormData 
   * Chiamo funzione upload
   * @param file singolo file
   */
  readFile(file: any) {
    const reader = new FileReader();
    reader.onload = () => {
      const formData = new FormData();
      const imgBlob = new Blob([reader.result], {
        type: file.type
      });
      formData.append('file', imgBlob, file.name);
      this.uploadImageData(formData);
    };
    reader.readAsArrayBuffer(file);
  }

  /**
   * Apro il loader full-sscreen e passo tutto al servizio per l'upload al server
   * @param formData 
   */
  async uploadImageData(formData: FormData) {
    const loading = await this.loadingController.create({
      message: 'Uploading image...',
    });

    await loading.present();

/*     this.api.uploadFiles(formData)
      .pipe(
        finalize(() => {
          loading.dismiss();
        })
      )
      .subscribe(e => {
        loading.dismiss(); if (e['success']) {

          //this.api.getFiles().subscribe();
          this.presentToast('File upload complete.');
        } else {
          this.presentToast('File upload failed.');

        }

      }); */


  }

  /*  imgFile(str) {
     return str.replace('///', '//');
   }
 
 
   onFileChanged(event) {
     console.log(event.target.value);
     var n = event.target.value.split("\\")
     console.log(n[n.length - 1]);
 
     //var imagePath = 'file:///data/user/0/com.cooltech.revolution/files/';
     //imagePath.substr(imagePath.lastIndexOf('/') + 1);
     //this.copyFileToLocalDir(imagePath, n[n.length - 1], this.createFileName());
     this.selectedFile = event.target.files[0]
   } */



  /**
   * Apre il sub-menu se abilitato passando parametri per le funzioni definite nel "optionsMenu"
   * @param ev event return from hrml on click for presentPopover
   */
  async presentPopover(ev: any) {

    const checkPropmt = await this.popoverController.getTop();
    if (checkPropmt) {
      this.popoverController.dismiss();
    }

    const popover = await this.popoverController.create({

      component: SubMenuComponent,
      cssClass: 'popover_setting',
      event: ev,
      translucent: true,
      mode: 'md',
      side: 'bottom',
      alignment: 'center',
      size: 'auto',
      dismissOnSelect: true,
      backdropDismiss: true,
      //trigger: ev.srcElement.id,
      componentProps: {
        'toggle': this.showGrid,
        data: this.optionsMenu
      }

    });

    popover.present();

    // Alla chiusura ricevo i payload
    popover.onDidDismiss().then((e) => {
      //console.log(e.data);
      if (e.data) {
        //this.toggleSrc = e.data.toggleSrc
        this.showGrid = e.data.toggleView;
      }
      console.log('onDidDismiss resolved with role', e);
    });
  }

}
