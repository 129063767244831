// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.img_card {
  height: 68px;
  margin: 2px 0px 0px 0px;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 20px;
}

ion-label {
  --background: var(--ion-bg-card);
  padding-left: 12px;
  padding-top: 3px;
  padding-bottom: 3px;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}

ion-slide {
  height: calc(100vh - 290px);
}

ion-segment-button ion-item {
  min-width: 360px;
  --background: rgba(0, 0, 0, 0.319);
}
ion-item {
  --ion-border-color: transparent !important;
}

.segment-button-checked {
  background: rgba(0, 188, 212, 0.1019607843) !important;
}

ion-slide {
  /*  background: linear-gradient(0deg, rgba(34, 193, 195, 0.08) 0%, rgb(18 26 54 / 48%) 100%) */
}

.title {
  font-size: 411px;
  color: #555453;
  background-color: rgba(0, 0, 0, 0.8509803922) !important;
  padding-bottom: 120px;
  margin-top: 0px;
  -webkit-backdrop-filter: blur(10px) saturate(1000%);
  backdrop-filter: blur(9px) saturate(350%);
  background: linear-gradient(120deg, rgba(67, 54, 255, 0.15), rgba(255, 255, 255, 0), 70%, rgba(62, 228, 255, 0.15));
  box-shadow: 0px 41px 0px #27231b;
}`, "",{"version":3,"sources":["webpack://./src/app/menu-mobile/menu-mobile.page.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,uBAAA;EACA,2BAAA;EACA,+BAAA;AACJ;;AACA;EACI,gCAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,4BAAA;EACA,+BAAA;AAEJ;;AACA;EACI,2BAAA;AAEJ;;AAKI;EACI,gBAAA;EACA,kCAAA;AAFR;AAQA;EACI,0CAAA;AANJ;;AASA;EACI,sDAAA;AANJ;;AASA;EACG,8FAAA;AANH;;AAUA;EACI,gBAAA;EACA,cAAA;EACA,wDAAA;EACA,qBAAA;EACA,eAAA;EACA,mDAAA;EACA,yCAAA;EACA,mHAAA;EACA,gCAAA;AAPJ","sourcesContent":[".img_card{\n    height: 68px;\n    margin: 2px 0px 0px 0px;\n    border-top-left-radius: 7px;\n    border-bottom-left-radius: 20px;\n}\nion-label {\n    --background: var(--ion-bg-card);\n    padding-left: 12px;\n    padding-top: 3px;\n    padding-bottom: 3px;\n    border-top-right-radius: 7px;\n    border-bottom-right-radius: 7px;\n}\n\nion-slide {\n    height: calc(100vh - 290px); //140\n}\n\nion-segment-button{\n    //min-width: 442px !important;\n    //width: auto! important;\n\n    ion-item {\n        min-width: 360px;\n        --background: rgba(0, 0, 0, 0.319);\n        ion-label{\n        }\n    }\n}\n\nion-item{\n    --ion-border-color: transparent !important;\n}\n\n.segment-button-checked{\n    background: #00bcd41a!important;\n}\n\nion-slide {\n   /*  background: linear-gradient(0deg, rgba(34, 193, 195, 0.08) 0%, rgb(18 26 54 / 48%) 100%) */\n}\n\n\n.title{\n    font-size: 411px;\n    color: #555453;\n    background-color: #000000d9 !important;\n    padding-bottom: 120px;\n    margin-top: 0px;\n    -webkit-backdrop-filter: blur(10px) saturate(1000%);\n    backdrop-filter: blur(9px) saturate(350%);\n    background: linear-gradient(120deg, rgb(67 54 255 / 15%), rgb(255 255 255 / 0%), 70%, rgb(62 228 255 / 15%));\n    box-shadow: 0px 41px 0px #27231b;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
