import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { Storage } from '@ionic/storage';


const API_STORAGE_KEY = 'firebaseService';
const TOKEN_KEY = 'access-token';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  public obj: any = new BehaviorSubject({});
  public fcm:  BehaviorSubject<any>;

  constructor(
    private storage: Storage,
    private platform: Platform,
    
  ) {
    let token = localStorage.getItem('fcm')
    console.log('onInit FirebaseService fcm',token);
    this.fcm = new BehaviorSubject<any>(token || null);
  }


  /**
   * Aggiorna e tiene in memoria il toke fcm l'utente
   * @param token Corrisponde toej fcm user
   */
   publishToken(token: any) {
    //console.log(token);
    this.fcm.next(token);
  }




  // Tracks a custom event in Firebase Analytics
  logEvent(name: string, properties: object) {
    this.platform.ready().then(() => {
      console.log(name, properties);

      // Ex: "select_content", {content_type: "page_view", item_id: "home"}
    }).catch((e) => {
      console.log(e);

    });
  }

  setUserProperty(key: string, value: string) {
    this.platform.ready().then(() => {
      console.log(key, value);
    }).catch((e) => {
      console.log(e);

    });
  }

  // Tracks an 'screen_view' event in Firebase Analytics
  trackScreen(name: string) {
    // this.platform.ready().then(() => {
    //   console.log(name);
    //   this.firebaseX.setScreenName(name).then(e => {
    //     console.log(e);

    //   }).then(err => {
    //     console.log(err);

    //   })
    // }).catch((e) => {
    //   console.log(e);

    // });
  }

  setUserId(uid: string) {
    this.platform.ready().then(() => {
      console.log(uid);
    }).catch((e) => {
      console.log(e);

    });
  }

  logEventError(error, obj) {
    // this.platform.ready().then(() => {
    //   console.log(error, obj);
    //   this.firebaseX.logError(error, obj).catch(err => {
    //     console.log(err)
    //   })
    // })
  }

  getRemoteConfig(key) {
    // this.firebaseX.fetch(10).then(e => {
    //   console.log('firebaseX fetch', e);
    //   this.firebaseX.activateFetched().then(e => {
    //     console.log('firebaseX activate', e);

    //     this.firebaseX.getValue(key).then(res => {
    //       //console.log('remoteConfig ' + key, res);
    //       let obj = this.obj.value
    //       obj[key] = JSON.parse(res)
    //       this.obj.next(obj)
    //       console.log(this.obj.value);
    //       //console.log(obj, key);
    //       //console.log((this.obj.value)[key]);

    //       this.setLocalData('contact', this.obj.value)
    //       return (this.obj.value)[key]
    //     }).catch(err => {
    //       console.log('remoteConfig ' + key + ' ERROR', err);
    //       return 1
    //     })
    //   }).catch(err => {
    //     console.log('catch firebaseX activate', err);
    //     return null
    //   })


    // })
  }



  // Save result of API requests
  private setLocalData(key, data) {
    this.storage.set(`${API_STORAGE_KEY}-${key}`, data);
  }

  // Get cached API result
  public getLocalData(key) {
    return this.storage.get(`${API_STORAGE_KEY}-${key}`);

  }
}
