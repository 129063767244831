
import { AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, PipeTransform, Renderer2, SimpleChanges, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { NavController, ModalController, IonNav, IonInfiniteScroll, IonSearchbar, IonContent, IonSegment, IonSegmentButton, IonMenu, MenuController, LoadingController, AlertController, IonicSafeString } from '@ionic/angular';

import { animate, query, sequence, stagger, state, style, transition, trigger } from '@angular/animations';

import { TabsService } from 'src/app/services/tabs/tabs.service';

import { ColumnChangesService, ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { OrdersService } from 'src/app/services/orders/orders.service';
import { SubMenuService } from 'src/app/services/utils/sub-menu/sub-menu.service';
import { CurrencyPipe } from '@angular/common';
import { PlatformService } from 'src/app/services/utils/platform/plarformservice';
import { Observable, forkJoin } from 'rxjs';
import { ThemeService } from 'src/app/services/theme/theme.service';
import * as XLSX from 'xlsx';
import { FileSaverService } from 'ngx-filesaver';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';


interface PageInfo {
  offset: number;
  pageSize: number;
  limit: number;
  count: number;
}
/**
 * An object used to get page information from the server
 */
export class Page {
  limit: number = 25;
  // The number of elements in the page
  size: number = 25;
  // The total number of elements
  totalElements: number = 0;
  // The total number of pages
  totalPages: number = 0;
  // The current page number
  pageNumber: number = 1;
}

const listAnimation = trigger('listAnimation', [
  transition('* <=> *', [
    query(':enter',
      [style({ opacity: 0 }), stagger('180ms', animate('300ms ease-out', style({ opacity: 1 })))],
      { optional: true }
    ),
    query(':leave',
      animate('200ms', style({ opacity: 0 })),
      { optional: true }
    )
  ])
]);

class CurrencyOnlyPipe extends CurrencyPipe {
  public override transform(value, digitInfo = '1.3-3', locale = 'it'): any {
    return super.transform(value, 'EUR', 'symbol', digitInfo, locale);
  }
}


class LinesDescriptionPipe implements PipeTransform {
  transform(lines: any[], maxChars: number = 70): string {
    if (!lines || lines.length === 0 || !lines[0].description) {
      return '';
    }

    let description = lines[0].description;
    return description.length > maxChars ? description.substr(0, maxChars) + '...' : description;
  }

}

class documentsArray implements PipeTransform {
  transform(lines: any[], maxChars: number = 70): string {
    if (!lines || lines.length === 0 || !lines[0].description) {
      return '';
    }

    let description = lines[0].description;
    return description.length > maxChars ? description.substr(0, maxChars) + '...' : description;
  }

}
@Component({
  selector: 'app-list-orders',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ListOrdersComponent implements OnInit, AfterViewInit, OnChanges {


  @ViewChild('downloadTemplate') downloadTemplate: TemplateRef<any>;
  @ViewChild('attachTemplate') attachTemplate: TemplateRef<any>;
  @ViewChild('descriptionTemplate') descriptionTemplate: TemplateRef<any>;

  private hoverTimer: any;
  n = 0
  public data: any; // Data;
  public columns: any;
  public backupColumns: any;
  public rows = new Array<any>()

  rowIsNotExpanded = true

  editing = {};

  selected = [];

  funder = [];
  calculated = [];
  pending = [];
  ColumnMode: ColumnMode
  expanded: any = {};
  timeout: any;
  total_order = 0;
  currentSegment: string = "all";

  module = 'orders'
  @Input() projectId;
  @Input() jobId;
  @Input() job;
  @Input()jobIDTable;
  @Input() cache;
  @Input() viewListOrder
  @Input() viewListOrderMNT
  @Input() viewListInvoice
  @Input() viewListCPR
  @Input() viewListAck
  @Input() viewListOrderVendor
  @Input() viewListOrderCustomer
  @Input() viewListJob
  @Input() viewListReport
  @Input() vendorName
  @Input() vendorId
  @Input() customerName
  @Input() customerId
  @Input() jobName
  @Input() jobModal
  @Input() childrenJobs = []

  orders;
  @Output() clicked = new EventEmitter<any>();
  @Output() clickedCategory = new EventEmitter<any>();
  @Output() sendOrders = new EventEmitter<any>();
  @Output() switch = new EventEmitter<any>();

  @Output() emitterOpenNewOrder = new EventEmitter<any>();

  @ViewChild('list') list: ElementRef;
  @ViewChild('categories') categories: ElementRef;


  @ViewChild('segment') segment: IonSegment;
  @ViewChild('btn_segment') btn_segment: IonSegmentButton;
  @ViewChild('myTable') table: DatatableComponent;
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  @ViewChild(IonContent) content: IonContent;
  @ViewChild('priorityTemplate') priorityTemplate: TemplateRef<any>;
  @ViewChild('actionTemplate') actionTemplate: TemplateRef<any>;
  @ViewChild('approveTemplate') approveTemplate: TemplateRef<any>;
  @ViewChild('copyTemplate') copyTemplate: TemplateRef<any>;


  fab: boolean = false;
  customAlertOptions: any = {
    header: 'List groups',
    //subHeader: 'Select group for user',
    message: 'Select group for user',
    translucent: true
  };

  searching = false
  res: any = []
  order_status = [];
  order_types = [];
  order_categories = [];

  page = new Page();

  total_page = 0;
  page_number = 1;
  page_limit = 30;

  isSearch: boolean = false;
  isLoad: boolean = false;
  setClosed: boolean

  queryString = [{
    search_by: '',
    search_data: ''
  }];

  queryFilter = {
    search_by: 'sort',
    search_data: [{ prop: 'code', dir: 'desc' }]
  };


  totalElements: number;
  pageNumber: number = 1;

  isLoading = 0;
  typeData = true


  navMenu = [
    { action: 'toggleSearch', label: 'Search' },
    { action: 'openMenu', label: 'Filter' },
  ]


  filterItems: any = [
    {
      search_by: 'status', //name //
      search_data: ''
    },
  ]

  activeStatusOrder
  stringStatusOrderFilter = '--'

  heightTable
  innerHeight

  sortedCategories: any = []
  tableOrder = false
  activeClass = false
  isMobile = false
  filteredData:any
  enableItem = false
  idCreator = 0

  constructor(
    private menu: MenuController,
    private subMenu: SubMenuService,
    private api: OrdersService,
    private modalController: ModalController,
    private tabService: TabsService,
    private renderer: Renderer2,
    private columnChangesService: ColumnChangesService,
    private el: ElementRef,
    private loaderCtrl: LoadingController,
    private utils: PlatformService,
    private themeDetection: ThemeService,
    private alertController: AlertController,
    private _FileSaverService: FileSaverService,
    private auth: AuthenticationService

  ) {

    this.auth.getLocalData('auth-profile').then((e)=>{
      this.idCreator = e.user_data.id
    })

    console.log(this.jobId);
    this.themeDetection.dark.subscribe(e => {
      console.log(e);

      if(e === undefined || e == true){
        this.aggiornaStili(e)
      }
      else{
        this.aggiornaStili(e)
      }

    })

    console.log('list orders constructor');

    this.page.size = 25;
    var width = window.innerWidth
      || document.documentElement.clientWidth
      || document.body.clientWidth;

    this.isMobile = width < 530

    this.utils.autoWidth.subscribe(e => {
      this.isMobile = e < 530
    })

    this.callFilterItem()
  }

  aggiornaStili(attivaClassi: boolean): void {
    // Seleziona gli elementi container
    const containerElements = document.querySelectorAll('.ngx-datatable.material .datatable-body .progress-linear .container');
    containerElements.forEach(el => {
      if (attivaClassi) {
        this.renderer.setStyle(el, 'background-color', '#c0392b');
      } else {
        this.renderer.setStyle(el, 'background-color', '#aad1f9');
      }
    });
  
    // Seleziona gli elementi bar
    const barElements = document.querySelectorAll('.ngx-datatable.material .datatable-body .progress-linear .container .bar');
    barElements.forEach(el => {
      if (attivaClassi) {
        this.renderer.setStyle(el, 'background-color', '#fff');
        // Aggiungere altre proprietà di stile se necessario
      } else {
        this.renderer.setStyle(el, 'background-color', '#106cc8');
        // Rimuovere altre proprietà di stile se necessario
      }
    });
  }
  


  // forza il reset al l'entrata
  callFilterItem() {

    let readFilter = {};

    try {
      readFilter = JSON.parse(localStorage.getItem('filter_orders'))
      console.log(readFilter, this.filterItems);


      /*       if (readFilter == null) {
              localStorage.setItem('filter_orders', JSON.stringify(this.filterItems))
            } else {
              this.filterItems = readFilter;
            } */

      readFilter[0].search_data = ''
      readFilter[1].search_data = ''
      localStorage.setItem('filter_orders', JSON.stringify(readFilter))



    }
    catch {
      console.log('error');
      localStorage.setItem('filter_orders', JSON.stringify(this.filterItems))
      this.filterItems = readFilter;
    }



  }

  createColumns() {
    this.columns = [
      //{ prop: 'id', name: 'ID'/* , frozenLeft: true */, minWidth: 75, maxWidth: 75, flexGrow: 0.3, cellClass: 'td-id', headerClass: 'th-id' },
      /* {
        prop: 'id', width: 75, minWidth: 75
      }, */
      {
        prop: 'attach', name: 'Attachments', flexGrow: 1, minWidth: 90, maxWidth: 90,
        cellTemplate: this.attachTemplate,
      },
      {
        prop: 'print', name: 'Print', flexGrow: 1, minWidth: 90, maxWidth: 90,
        cellTemplate: this.downloadTemplate
      },
      {
        prop: 'priority.name', name: 'Priority', flexGrow: 1, minWidth: 90, maxWidth: 90,
        cellClass: 'td-priority', headerClass: 'th-priority',
        cellTemplate: this.priorityTemplate
      },
      {
        prop: 'status.code', name:'Status', flexGrow: 1, minWidth: 100, maxWidth: 150, cellClass: 'td-code', headerClass: 'th-code'
      },
      {
        prop: 'code', flexGrow: 1, minWidth: 100, maxWidth: 150, cellClass: 'td-code', headerClass: 'th-code'
      },
      {
        prop: 'cost', flexGrow: 1, minWidth: 180, maxWidth: 180, pipe: new CurrencyOnlyPipe('en-US', '€',),
        /*  summaryFunc: () => this.caclulateSumm('€'), */
      },
      { prop: 'category.name', name: 'Category', flexGrow: 1, minWidth: 125, maxWidth: 175 },
      { prop: 'type.name', name: 'Type', minWidth: 150, maxWidth: 200, flexGrow: 1, },

      //{ prop: 'creator.email', name: 'Email', width: 190, minWidth: 190, maxWidth: 230 },
     
      { prop: 'description', name: 'Description', maxWidth: 350, minWidth: 250, flexGrow: 3, cellTemplate: this.descriptionTemplate,  },
      { prop: 'job.name', name: 'Job', maxWidth: 250, minWidth: 150, flexGrow: 2 },
      //{ prop: 'job.code_slug', name: 'Job Code', maxWidth: 250, minWidth: 150, flexGrow: 2 },
      { prop: 'customer.name', name: 'Customer', flexGrow: 1, minWidth: 240, maxWidth: 240, },
      { prop: 'vendor.name', name: 'Vendor', flexGrow: 1, minWidth: 290, maxWidth: 290, },
      //{ prop: 'deleted', maxWidth: 70, cellClass: 'td-deleted', headerClass: 'th-deleted' },pipe: new LinesDescriptionPipe()
      {
        prop: 'copy', name: 'Copy', flexGrow: 1, minWidth: 90, maxWidth: 90,
        cellTemplate: this.copyTemplate
      },
      // {
      //   prop: 'action', name: 'Delete', flexGrow: 1, minWidth: 90, maxWidth: 90,
      //   cellTemplate: this.actionTemplate
      // },


    ]

  }

  resizeColumns(){
    this.columns = [
      //{ prop: 'id', name: 'ID'/* , frozenLeft: true */, minWidth: 75, maxWidth: 75, flexGrow: 0.3, cellClass: 'td-id', headerClass: 'th-id' },
      /* {
        prop: 'id', width: 75, minWidth: 75
      }, */
      {
        prop: 'attach', name: 'Attachments', flexGrow: 1, minWidth: 90, maxWidth: 90,
        cellTemplate: this.attachTemplate
      },
           {
        prop: 'print', name: 'Print', flexGrow: 1, minWidth: 90, maxWidth: 90,
        cellTemplate: this.downloadTemplate
      },
      {
        prop: 'priority.name', name: 'Priority', flexGrow: 1, minWidth: 90, maxWidth: 90,
        cellClass: 'td-priority', headerClass: 'th-priority',
        cellTemplate: this.priorityTemplate
      },
      {
        prop: 'code', flexGrow: 1, minWidth: 100, maxWidth: 150, cellClass: 'td-code', headerClass: 'th-code'
      },
      {
        prop: 'cost', flexGrow: 1, minWidth: 150, maxWidth: 200, pipe: new CurrencyOnlyPipe('en-US', '€'),
        /*  summaryFunc: () => this.caclulateSumm('€'), */
      },
      { prop: 'job.code_slug', name: 'Job Code', maxWidth: 250, minWidth: 100, flexGrow: 2 },
      //{ prop: 'job.name', name: 'Job', maxWidth: 250, minWidth: 100, flexGrow: 2 },
      { prop: 'description', name: 'Description', maxWidth: 350, minWidth: 250, flexGrow: 3, cellTemplate: this.descriptionTemplate, },
      { prop: 'vendor.name', name: 'Vendor', flexGrow: 1, minWidth: 290, maxWidth: 290, },
      { prop: 'customer.name', name: 'Customer', flexGrow: 1, minWidth: 150, maxWidth: 240, },
      //{ prop: 'category.name', name: 'Category', flexGrow: 1, minWidth: 240, maxWidth: 240 },
      { prop: 'type.name', name: 'Type', minWidth: 150, maxWidth: 200, flexGrow: 1, }, 
      //{ prop: 'creator.email', name: 'Email', width: 190, minWidth: 190, maxWidth: 230 }, pipe: new LinesDescriptionPipe(),
      
      //{ prop: 'deleted', maxWidth: 70, cellClass: 'td-deleted', headerClass: 'th-deleted' },
      
      // {
      //   prop: 'copy', name: 'Copy', flexGrow: 1, minWidth: 90, maxWidth: 90,
      //   cellTemplate: this.copyTemplate
      // },
      // {
      //   prop: 'action', name: 'Delete', flexGrow: 1, minWidth: 90, maxWidth: 90,
      //   cellTemplate: this.actionTemplate
      // },


    ]
  }


  invColumns(){
    this.columns = [
      //{ prop: 'id', name: 'ID'/* , frozenLeft: true */, minWidth: 75, maxWidth: 75, flexGrow: 0.3, cellClass: 'td-id', headerClass: 'th-id' },
      /* {
        prop: 'id', width: 75, minWidth: 75
      }, */
      {
        prop: 'attach', name: 'Attachments', flexGrow: 1, minWidth: 90, maxWidth: 90,
        cellTemplate: this.attachTemplate,
      },
      {
        prop: 'print', name: 'Print', flexGrow: 1, minWidth: 90, maxWidth: 90,
        cellTemplate: this.downloadTemplate
      },
      {
        prop: 'priority.name', name: 'Priority', flexGrow: 1, minWidth: 90, maxWidth: 90,
        cellClass: 'td-priority', headerClass: 'th-priority',
        cellTemplate: this.priorityTemplate
      },
      {
        prop: 'code', flexGrow: 1, minWidth: 100, maxWidth: 150, cellClass: 'td-code', headerClass: 'th-code'
      },
      {
        prop: 'status.code', name:'Status code', flexGrow: 1, minWidth: 100, maxWidth: 150, cellClass: 'td-code', headerClass: 'th-code'
      },
      {
        prop: 'cost', flexGrow: 1, minWidth: 150, maxWidth: 200, pipe: new CurrencyOnlyPipe('en-US', '€'),
        /*  summaryFunc: () => this.caclulateSumm('€'), */
      },
      {
        prop: 'invoiced', flexGrow: 1, minWidth: 150, maxWidth: 200,
        /*  summaryFunc: () => this.caclulateSumm('€'), */
      },
      { prop: 'job.code_slug', name: 'Job Code', maxWidth: 250, minWidth: 100, flexGrow: 2 },
      //{ prop: 'job.name', name: 'Job', maxWidth: 250, minWidth: 100, flexGrow: 2 },
      { prop: 'description', name: 'Description', maxWidth: 350, minWidth: 250, flexGrow: 3, cellTemplate: this.descriptionTemplate,  },
      { prop: 'vendor.name', name: 'Vendor', flexGrow: 1, minWidth: 290, maxWidth: 290, },
      { prop: 'customer.name', name: 'Customer', flexGrow: 1, minWidth: 150, maxWidth: 240, },
      //{ prop: 'category.name', name: 'Category', flexGrow: 1, minWidth: 240, maxWidth: 240 },
      { prop: 'type.name', name: 'Type', minWidth: 150, maxWidth: 200, flexGrow: 1, },
  
      //{ prop: 'creator.email', name: 'Email', width: 190, minWidth: 190, maxWidth: 230 }, pipe: new LinesDescriptionPipe(),
      
      //{ prop: 'deleted', maxWidth: 70, cellClass: 'td-deleted', headerClass: 'th-deleted' },
      
      // {
      //   prop: 'copy', name: 'Copy', flexGrow: 1, minWidth: 90, maxWidth: 90,
      //   cellTemplate: this.copyTemplate
      // },
      // {
      //   prop: 'action', name: 'Delete', flexGrow: 1, minWidth: 90, maxWidth: 90,
      //   cellTemplate: this.actionTemplate
      // },


    ]
  }

  cprColumns(){
    this.columns = [
      //{ prop: 'id', name: 'ID'/* , frozenLeft: true */, minWidth: 75, maxWidth: 75, flexGrow: 0.3, cellClass: 'td-id', headerClass: 'th-id' },
      /* {
        prop: 'id', width: 75, minWidth: 75
      }, */
      {
        prop: 'attach', name: 'Attachments', flexGrow: 1, minWidth: 90, maxWidth: 90,
        cellTemplate: this.attachTemplate,
      },
      {
        prop: 'print', name: 'Print', flexGrow: 1, minWidth: 90, maxWidth: 90,
        cellTemplate: this.downloadTemplate
      },
      {
        prop: 'priority.name', name: 'Priority', flexGrow: 1, minWidth: 90, maxWidth: 90,
        cellClass: 'td-priority', headerClass: 'th-priority',
        cellTemplate: this.priorityTemplate
      },
      {
        prop: 'code', flexGrow: 1, minWidth: 100, maxWidth: 150, cellClass: 'td-code', headerClass: 'th-code'
      },
      {
        prop: 'status.code', name:'Status code', flexGrow: 1, minWidth: 100, maxWidth: 150, cellClass: 'td-code', headerClass: 'th-code'
      },
      {
        prop: 'cost', flexGrow: 1, minWidth: 150, maxWidth: 200, pipe: new CurrencyOnlyPipe('en-US', '€'),
        /*  summaryFunc: () => this.caclulateSumm('€'), */
      },
      { prop: 'job.code_slug', name: 'Job Code', maxWidth: 250, minWidth: 100, flexGrow: 2 },
      //{ prop: 'job.name', name: 'Job', maxWidth: 250, minWidth: 100, flexGrow: 2 },

      { prop: 'description', name: 'Description', maxWidth: 350, minWidth: 250, flexGrow: 3, cellTemplate: this.descriptionTemplate,  },
      { prop: 'vendor.name', name: 'Vendor', flexGrow: 1, minWidth: 290, maxWidth: 290, },
      { prop: 'customer.name', name: 'Customer', flexGrow: 1, minWidth: 150, maxWidth: 240, },
      //{ prop: 'category.name', name: 'Category', flexGrow: 1, minWidth: 240, maxWidth: 240 },
      { prop: 'type.name', name: 'Type', minWidth: 150, maxWidth: 200, flexGrow: 1, },
      //{ prop: 'creator.email', name: 'Email', width: 190, minWidth: 190, maxWidth: 230 }, pipe: new LinesDescriptionPipe(),
      
      //{ prop: 'deleted', maxWidth: 70, cellClass: 'td-deleted', headerClass: 'th-deleted' },
      
      // {
      //   prop: 'copy', name: 'Copy', flexGrow: 1, minWidth: 90, maxWidth: 90,
      //   cellTemplate: this.copyTemplate
      // },
      // {
      //   prop: 'action', name: 'Delete', flexGrow: 1, minWidth: 90, maxWidth: 90,
      //   cellTemplate: this.actionTemplate
      // },


    ]
  }


  ackColumns(){
    this.columns = [
      //{ prop: 'id', name: 'ID'/* , frozenLeft: true */, minWidth: 75, maxWidth: 75, flexGrow: 0.3, cellClass: 'td-id', headerClass: 'th-id' },
      /* {
        prop: 'id', width: 75, minWidth: 75
      }, */
      {
        prop: 'attach', name: 'Attachments', flexGrow: 1, minWidth: 90, maxWidth: 90,
        cellTemplate: this.attachTemplate,
      },
      {
        prop: 'print', name: 'Print', flexGrow: 1, minWidth: 90, maxWidth: 90,
        cellTemplate: this.downloadTemplate
      },
      {
        prop: 'action', name: 'Approve', flexGrow: 1, minWidth: 90, maxWidth: 90,
        cellTemplate: this.approveTemplate
      },
      {
        prop: 'priority.name', name: 'Priority', flexGrow: 1, minWidth: 90, maxWidth: 90,
        cellClass: 'td-priority', headerClass: 'th-priority',
        cellTemplate: this.priorityTemplate
      },
      {
        prop: 'code', flexGrow: 1, minWidth: 100, maxWidth: 150, cellClass: 'td-code', headerClass: 'th-code'
      },
      {
        prop: 'cost', flexGrow: 1, minWidth: 150, maxWidth: 200, pipe: new CurrencyOnlyPipe('en-US', '€'),
        /*  summaryFunc: () => this.caclulateSumm('€'), */
      },
      { prop: 'job.code_slug', name: 'Job Code', maxWidth: 250, minWidth: 100, flexGrow: 2 },
      //{ prop: 'job.name', name: 'Job', maxWidth: 250, minWidth: 100, flexGrow: 2 },
      { prop: 'description', name: 'Description', maxWidth: 350, minWidth: 250, flexGrow: 3, cellTemplate: this.descriptionTemplate,  },
      { prop: 'vendor.name', name: 'Vendor', flexGrow: 1, minWidth: 290, maxWidth: 290, },
      { prop: 'customer.name', name: 'Customer', flexGrow: 1, minWidth: 150, maxWidth: 240, },
      //{ prop: 'category.name', name: 'Category', flexGrow: 1, minWidth: 240, maxWidth: 240 },pipe: new LinesDescriptionPipe(),
      { prop: 'type.name', name: 'Type', minWidth: 150, maxWidth: 200, flexGrow: 1, },
      //{ prop: 'creator.email', name: 'Email', width: 190, minWidth: 190, maxWidth: 230 },
      
      //{ prop: 'deleted', maxWidth: 70, cellClass: 'td-deleted', headerClass: 'th-deleted' },
      
      // {
      //   prop: 'copy', name: 'Copy', flexGrow: 1, minWidth: 90, maxWidth: 90,
      //   cellTemplate: this.copyTemplate
      // },
      // {
      //   prop: 'action', name: 'Delete', flexGrow: 1, minWidth: 90, maxWidth: 90,
      //   cellTemplate: this.actionTemplate
      // },


    ]
  }

  reportColumns(){
    this.columns = [
      //{ prop: 'id', name: 'ID'/* , frozenLeft: true */, minWidth: 75, maxWidth: 75, flexGrow: 0.3, cellClass: 'td-id', headerClass: 'th-id' },
      /* {
        prop: 'id', width: 75, minWidth: 75
      }, */
      {
        prop: 'attach', name: 'Attachments', flexGrow: 1, minWidth: 90, maxWidth: 90,
        cellTemplate: this.attachTemplate,
      },
      {
        prop: 'print', name: 'Print', flexGrow: 1, minWidth: 90, maxWidth: 90,
        cellTemplate: this.downloadTemplate
      },
      {
        prop: 'code', flexGrow: 1, minWidth: 100, maxWidth: 150, cellClass: 'td-code', headerClass: 'th-code'
      },
      {
        prop: 'cost', flexGrow: 1, minWidth: 150, maxWidth: 200, pipe: new CurrencyOnlyPipe('en-US', '€'),
        /*  summaryFunc: () => this.caclulateSumm('€'), */
      },
      { prop: 'job.code_slug', name: 'Job Code', maxWidth: 250, minWidth: 100, flexGrow: 2 },
      //{ prop: 'job.name', name: 'Job', maxWidth: 250, minWidth: 100, flexGrow: 2 },
      { prop: 'description || lines[0].description', name: 'Description', maxWidth: 250, minWidth: 150, flexGrow: 3,},
      { prop: 'vendor.name', name: 'Vendor', flexGrow: 1, minWidth: 290, maxWidth: 290, },
      { prop: 'customer.name', name: 'Customer', flexGrow: 1, minWidth: 150, maxWidth: 240, },
       //{ prop: 'category.name', name: 'Category', flexGrow: 1, minWidth: 240, maxWidth: 240 },,
      //{ prop: 'creator.email', name: 'Email', width: 190, minWidth: 190, maxWidth: 230 },
      //{ prop: 'deleted', maxWidth: 70, cellClass: 'td-deleted', headerClass: 'th-deleted' },
      // {
      //   prop: 'copy', name: 'Copy', flexGrow: 1, minWidth: 90, maxWidth: 90,
      //   cellTemplate: this.copyTemplate
      // },
      // {
      //   prop: 'action', name: 'Delete', flexGrow: 1, minWidth: 90, maxWidth: 90,
      //   cellTemplate: this.actionTemplate
      // },


    ]
  }

  

  orderVendorColumns() {
    this.columns = [
      //{ prop: 'id', name: 'ID'/* , frozenLeft: true */, minWidth: 75, maxWidth: 75, flexGrow: 0.3, cellClass: 'td-id', headerClass: 'th-id' },
      /* {
        prop: 'id', width: 75, minWidth: 75
      }, */
      {
        prop: 'attach', name: 'Attachments', flexGrow: 1, minWidth: 90, maxWidth: 90,
        cellTemplate: this.attachTemplate,
      },
      {
        prop: 'print', name: 'Print', flexGrow: 1, minWidth: 90, maxWidth: 90,
        cellTemplate: this.downloadTemplate
      },
      {
        prop: 'priority.name', name: 'Priority', flexGrow: 1, minWidth: 90, maxWidth: 90,
        cellClass: 'td-priority', headerClass: 'th-priority',
        cellTemplate: this.priorityTemplate
      },
      {
        prop: 'code', flexGrow: 1, minWidth: 100, maxWidth: 150, cellClass: 'td-code', headerClass: 'th-code'
      },
      {
        prop: 'cost', flexGrow: 1, minWidth: 180, maxWidth: 180, pipe: new CurrencyOnlyPipe('en-US', '€'),
        /*  summaryFunc: () => this.caclulateSumm('€'), */
      },
      { prop: 'job.code_slug', name: 'Job Code', flexGrow: 1, minWidth: 240, maxWidth: 240 },
      { prop: 'type.name', name: 'Type', minWidth: 150, maxWidth: 200, flexGrow: 1, },

      //{ prop: 'creator.email', name: 'Email', width: 190, minWidth: 190, maxWidth: 230 },
      { prop: 'description', name: 'Description', maxWidth: 350, minWidth: 250, flexGrow: 3, cellTemplate: this.descriptionTemplate,},
      { prop: 'job.name', name: 'Job', maxWidth: 250, minWidth: 150, flexGrow: 2 },
      //{ prop: 'job.code_slug', name: 'Job Code', maxWidth: 250, minWidth: 150, flexGrow: 2 },
      { prop: 'customer.name', name: 'Customer', flexGrow: 1, minWidth: 240, maxWidth: 240, },
      { prop: 'vendor.name', name: 'Vendor', flexGrow: 1, minWidth: 290, maxWidth: 290, },
      //{ prop: 'deleted', maxWidth: 70, cellClass: 'td-deleted', headerClass: 'th-deleted' },


    ]

  }
  tempRows:any
  ngOnInit() {
    console.log('list orders ngOnInit');

    //this.orders = [];
    this.fab = true

    //this.loadProjects(this.queryString);
    //this.getOrders(false, "", this.queryString)


    this.api.getItem().subscribe(e=>{
      if(e){
        this.enableItem = e
        
      }

    })
    console.log('ordersmeta');
    
    this.getOrdersMeta()


  }

  
  jobs$: Observable<any>;

  ngAfterViewInit(): void {
    
    console.log(this.enableSpinner);
    
    console.log('list orders ngAfterViewInit', this.pageNumber);
    console.log(this.orders);
    console.log(this.projectId);
    console.log(this.cache);
    console.log('loading',this.isLoading);
    console.log(this.page?.totalElements);
    console.log(this.jobId);
    setTimeout(() => {
      
      if(((this.isLoading == 0 && this.page?.totalElements == 0) && !this.jobId) || this.jobId){
        console.log('entro');
        
        this.setPage(0)
      }
    if((this.viewListOrder)){
      console.log('viewListOrder');
      this.resizeColumns()
    }
    else if((this.viewListInvoice)){
      console.log('viewListInvoice');
      this.invColumns()
    }
    else if(this.viewListAck){
      console.log('viewListAck');
      this.ackColumns()
    }
    else if(this.viewListOrderVendor | this.viewListOrderCustomer){
      console.log('viewListOrderVendor');
      this.orderVendorColumns()
    }
    else if(this.viewListCPR || this.viewListOrderMNT){
      console.log('viewListCPR');
      this.cprColumns()

    }
    else if(this.viewListReport){
      console.log('viewListReport');
      this.reportColumns()
    }
    else{
      console.log('createColumns');
      this.createColumns()
    }

    this.backupColumns =this.columns
    }, 500);

      
    this.queryString = localStorage.getItem('filter_orders') ? JSON.parse(localStorage.getItem('filter_orders')) : { search_by: '', search_data: 'all' }

    let d = this.queryString.map(e => e.search_by == 'status').indexOf(true)
    console.log(d);

    this.stringStatusOrderFilter = this.queryString[d].search_data


    console.log(this.stringStatusOrderFilter);
    

    const rows = this.el.nativeElement.querySelectorAll('.datatable-body-row');

    // rows.forEach(row => {
    //   this.renderer.listen(row, 'mouseenter', () => this.onMouseEnter(row));
    //   this.renderer.listen(row, 'mouseleave', () => this.onMouseLeave());
    // });
  }

  // ngOnChanges(changes: SimpleChanges): void {
  //   console.log(changes);
  //   // if (changes['orders'] && !changes['orders'].firstChange) {

  //   // } else if (changes['cache'] && !changes['cache'].firstChange) {

  //   // }

  //   // setTimeout(() => {

  //   //   console.log(this.list);
  //   //   console.log(this.list['el'].getBoundingClientRect());
  //   //   let rect = this.list['el'].getBoundingClientRect()
  //   //   this.heightTable = rect.top + 0
  //   //   this.innerHeight = innerHeight;
  //   //   console.log(innerHeight, rect.top);



  //   // }, 200)
    
    
  //   /*    
   
  //        this.total_order = 0;
  //        this.total_page = 0;
  //        this.page_number = 0;
   
  //        this.orders = []
  //        this.cache = {}
        
  //        setTimeout(() => {
  //          this.table.bodyComponent.offsetX = 0;
  //          this.table.bodyComponent.offsetY = 0;
  //          this.table.headerComponent.offsetX = 0;
  //          this.table.recalculateColumns();
  //          this.orders = [...this.orders];
  //        }, 100);
  //        this.loadProjects(this.queryString)
  //      } */
  // }
  openMenu() {
    this.menu.open('admin')
    this.subMenu.params.next({
      title: 'Filter',
      icon: 'funnel-outline',
      accordions: [
        {
          target: '',
          color: '',
          icon: 'list-outline',
          title: 'Status'
        },
        {
          target: '',
          color: '',
          icon: 'list-outline',
          title: 'Priority'
        },
        {
          target: '',
          color: '',
          icon: 'list-outline',
          title: 'Category'
        },
        {
          target: '',
          color: '',
          icon: 'list-outline',
          title: 'Type'
        }
      ]
    })
  }


  segmentChanged(event) {
    console.log(event);
    this.typeData = !this.typeData

  }




  concateQuery() {
    console.log('concateQuery');

    let params = JSON.parse(localStorage.getItem('filter_orders'))
    //if (this.pageNumber <= 1 && index == -1)
    //this.columns.splice(2, 0, { prop: 'status.name', name: 'Status', width: 95, maxWidth: 95, minWidth: 95, cellClass: 'td-status', headerClass: 'th-status' })
    console.log(params);
    console.log(this.page)
    console.log(this.page.pageNumber)

    //this.page.pageNumber = 0
    if(this.page.pageNumber === undefined){
      this.page.pageNumber = 0
    }
    let baseurl = '?page=' + (this.page.pageNumber + 1) + '&items=' + this.page.size;
    let url = this.childJob ? baseurl + '&job__name=' + this.childJob : baseurl;
    

    if (!params) return url
    params.map((param, i) => {
      let query = '';

      /*  console.log('index', i);
       console.log(param);
       console.log(param != '');
       console.log(param.search_data, param.search_data != ''); */


      if (param && param != '' && param.search_data != '') {
        query += '&' + param.search_by + '=';
        query += param.search_data;


        //let filtered = this.columns.filter(obj => obj.name !== 'Status');


        //console.log(filtered);


        //this.columns = [...this.columns]
        url += query
      } else {
        console.log('else');

      }


    })
    console.log(url);

    return url
  }

  /**
   * @description Get order metadata
   */
  getOrdersMeta() {

      this.api.getOrderTypes()
      .subscribe(data => {
        console.log(data);
        this.order_types = data
      })
      /*  this.api.order_categories_list()
      .subscribe(data => {
        console.log(data);
        this.order_categories = data.results.filter((item) => item.parent === null);
        console.log(this.order_categories)
        
      }) */
    // this.api.order_sequences_list('?page=1&items=50')
    // .subscribe(data => {
    //   //console.log(data);
    //   this.order_status = data
    //   /* this.order_status.push(
    //     {
    //       id: 'all',
    //       name: 'All',
    //       code: 'ALL'
    //     }, 
        
    //     )*/
    //   })

      }
      
      // triggerColumnChangeDetection(): void {
      //   this.columnChangesService.onInputChange();
      // }
      
      onDetailToggle(event) {
        console.log('Detail Toggled', event);
      }
      
      // toggleExpandRow(row) {
      //   console.log('Toggled Expand Row!', row);
      //   this.table.rowDetail.collapseAllRows();
      //   this.table.rowDetail.toggleExpandRow(row);
      // }
      
      
      sorted(columnProp: string, direction: string) {
        console.log(columnProp, direction);
        if (columnProp == 'priority.name') {
          columnProp = 'priority.id'
        }
        
        this.queryFilter = { search_by: 'sort', search_data: [{ prop: columnProp, dir: direction }] }
        localStorage.setItem('filter_sort_orders', JSON.stringify({ search_by: 'sort', search_data: [{ prop: columnProp, dir: direction }] }))
        
        console.log(this.queryFilter);
      }
      
      
      onSelect({ selected }) {
        console.log('Select Event', selected, this.selected);
      }
      
      /**
   * @description onClick on row open order details
   * @param event pass obj row clicked
   */
      tempId1 = ''
      tempId2 
  onActivate(event) {
    /* if (this.table.rowDetail && event.type == 'click') {
      this.table.rowDetail.toggleExpandRow(event.row);
      console.log('Activate Event', event);
    } */
    // console.log(event)
    // if(this.tempId1 == '' && event.type == 'mouseenter'){
    //   this.tempId1 = event.row.id
    //   console.log(this.tempId1)
    //   this.onMouseEnter(event.row)
    // }
    // else{
    //   this.tempId1 = ''
    //   this.onMouseLeave()
    // }
    if (event.type == 'click' && event.cellIndex !== 0 && event.cellIndex !== 1 && event.cellIndex !== 8) {
      console.log('Activate Event', event);
      //this.openOrderModal(event.row)
      this.clicked.emit(event);
      }
      else if (event.type == 'click' && event.cellIndex == 0){
        console.log('download Event', event);
        //this.openOrderModal(event.row)
        this.clicked.emit(event);
      }
      else if (event.type == 'click' && event.cellIndex == 1){
        console.log('print Event', event);
        //this.openOrderModal(event.row)
        this.clicked.emit(event);
      }
      else if (event.type == 'click' && event.cellIndex == 2){
        console.log('print Event', event);
        //this.openOrderModal(event.row)
        this.clicked.emit(event);
      }
      else if (event.type == 'click' && event.cellIndex == 8){
        console.log('print Event', event);
        //this.openOrderModal(event.row)
        this.copyDescription(event.row)
      }
      else if (event.type == 'click' && event.cellIndex == 12){
        console.log('copy order Event', event);
        //this.openOrderModal(event.row)
        this.clicked.emit(event);
      }


  }

  onActivate2(event) {
    /* if (this.table.rowDetail && event.type == 'click') {
      this.table.rowDetail.toggleExpandRow(event.row);
      console.log('Activate Event', event);
    } */
    let res = {
      type: 'click',
      row: event
    }
    console.log(event)
    return
    console.log('Activate Event', res);
    //this.openOrderModal(event.row)
    this.clicked.emit(res);

  }

  setExpandStatus(expanded: any): void {
    console.log('setExpandStatus Event', expanded);
  }



  onGetRowClass = (row:any) => {
    if (!row.status)
      return ''
    //console.log(row);
    // id 3 is Delete for Status Order
    if (row.status.id === 4 || row.status.id === 5) {
      return 'deleted';
    } else if (row.status.id === 2) {
      return 'request';
    } else if (row.status.id === 1) {
      return 'draft';
    } else if (row.status.id === 3) {
      return 'order';
    }
    else
      return ''
  }


  ngOnChanges(changes: SimpleChanges) {
    if(this.rows.length!==0){
      this.rows = []
    }
    if (changes['jobId']) {
      const currentJobId = changes['jobId'].currentValue;
      console.log(currentJobId);
      
      // Esegui qui la tua logica basata sul nuovo jobId
      // Ad esempio, potresti voler caricare dati associati a questo nuovo jobId
      // this.loadJobDetails(currentJobId);
    }
  }

  /**
   * @description ritorna la somma delle colonne che gli si assegna questa funzione
   * @param n 
   * @returns somma della colonna in "rowSummary" template
   */
  caclulateSumm(n?) {
    console.log();

    this.total_order = 0;
    this.orders.map((item) => {
      this.total_order += item.cost;
      //this.currencyCode = item.currency;
      //console.log(this.total_order);
    });
    return this.total_order.toFixed(2) + ' ' + n;
  }

  setPage(pageInfo, filter?, jobId?:any, job?:any) {
    console.log(filter)
    console.log(pageInfo, this.projectId);
    console.log('load api list');
    // this.jobId = jobId
    let loader = this.loaderCtrl.create({
      cssClass: 'loading-in-content',
      message: '',
      duration: 9000,
    }).then((response) => {
      //response.present();
    });
    this.page.pageNumber = pageInfo?.offset
    // set the loading flag, which serves two purposes:
    // 1) it prevents the same page from being loaded twice
    // 2) it enables display of the loading indicator
    this.isLoading = 1;

    const index = this.columns?.findIndex(item => item?.name === "Status");
    console.log(index)


    // if (this.page_number <= 1 && index == -1)
    //   this.columns.splice(1, 0, { prop: 'status.name', name: 'Status', flexGrow: 2, minWidth: 150, maxWidth: 150, cellClass: 'td-status', headerClass: 'th-status' })


    let url = this.concateQuery()
    console.log(this.jobId);
    if(this.jobId && this.jobId.length===0)
      this.jobId = undefined
    
    if (this.projectId) {
      this.api.getOrdersByProjectId(this.projectId, url).subscribe(data => {
        console.log(data);

        this.page.totalElements = data?.total_items;
        this.page.totalPages = this.page.totalElements / this.page.size;
        
        if(filter){
          this.rows = data.results.filter(e => e.status.code !== 'CLO')
          this.rows = [...this.rows] 
        }
        else if(!filter){
          this.rows = data.results
          this.rows = [...this.rows]
        }
        else{
          this.rows = data.results.filter(e => e.status.code !== 'CLO')
          this.rows = [...this.rows] 
        }


        console.log(this.rows);

        this.isLoading = 0;


        //this.loaderCtrl.dismiss()

      });
    } else if (this.jobId && !this.viewListJob) {
      console.log(url)
      console.log(this.jobId);
      console.log(this.job);
      if(this.jobId.id){

        this.api.getOrdersByJobId(this.jobId.id, url).subscribe(data => {
          console.log(data);
          this.rows= []
          this.page.totalElements = data.total_items;
          this.page.totalPages = this.page.totalElements / this.page.size;
          if(filter){
            console.log(filter, 'filter true')
            let temp = data.results.filter(e => e.status.code !== 'CLO')
            this.rows = temp
            this.rows = [...this.rows] 
          }
          else if(!filter){
            console.log(filter, 'filter false')
          
            this.rows = data.results
            this.rows = [...this.rows]
          }
          else if(filter === undefined){
            console.log(filter, 'filter null')
            let temp = data.results.filter(e => e.status.code !== 'CLO')
            this.rows = temp
            this.rows = [...this.rows] 
          }
          console.log(this.rows);
          
          this.isLoading = 0;
          
          
          //this.loaderCtrl.dismiss()
          
        });
      }
    } else if(this.viewListOrder){
       this.api.order_orders_by_status_read(url, 'RDA').subscribe(data => {
         console.log(data);
       
         // Assegnare i risultati iniziali a this.rows
         this.rows = data.results;
       
         // Filtrare gli elementi escludendo quelli con category.code === 'CIV' o category.code === 'EXT'
         this.rows = this.rows.filter(obj => (obj.category.code !== 'MNT' && obj.category.code !== 'EXT') && obj.creator !== 45);
       
         // Aggiornare data.total_items in base alla lunghezza di this.rows dopo il filtro
         data.total_items = this.rows.length;
       
         // Calcolare nuovamente totalPages in base al nuovo total_items
         this.page.totalElements = data.total_items;
         this.page.totalPages = this.page.totalElements / this.page.size;
       
         this.rows = [...this.rows];
         console.log(this.rows);
       
         this.sortedCategories = this.rows.map(obj => obj.category.name).sort((a, b) => a.localeCompare(b)).filter((value, index, array) => array.indexOf(value) === index)
         console.log(this.sortedCategories);
       
         this.isLoading = 0;
         
         //this.loaderCtrl.dismiss()
       });
      
      }
      else if(this.viewListOrderMNT){
        this.api.order_orders_by_status_read(url, 'RDA').subscribe(data => {
          console.log(data);
        
          // Assegnare i risultati iniziali a this.rows
          this.rows = data.results;
        
          // Filtrare gli elementi escludendo quelli con category.code === 'CIV' o category.code === 'EXT'
          this.rows = this.rows.filter(obj => (obj.category.code === 'MNT' && obj.category.code !== 'EXT') || obj.creator === 45);
        
          // Aggiornare data.total_items in base alla lunghezza di this.rows dopo il filtro
          data.total_items = this.rows.length;
        
          // Calcolare nuovamente totalPages in base al nuovo total_items
          this.page.totalElements = data.total_items;
          this.page.totalPages = this.page.totalElements / this.page.size;
        
          this.rows = [...this.rows];
          console.log(this.rows);
        
          this.sortedCategories = this.rows.map(obj => obj.category.name).sort((a, b) => a.localeCompare(b)).filter((value, index, array) => array.indexOf(value) === index)
          console.log(this.sortedCategories);
        
          this.isLoading = 0;
          
          //this.loaderCtrl.dismiss()
        });
       
       }          
      else if(this.viewListInvoice){
        url = url + '&causal_id=1'
        this.isLoading = 1
        console.log('INV rows');
        
        this.api.order_orders_by_status_read(url, 'INV').subscribe(data => {
          console.log(data);
          
          this.page.totalElements = data.total_items;
          this.page.totalPages = this.page.totalElements / this.page.size;
          console.log(data.results);
          
            this.rows = data.results
            this.filteredData = this.rows
            this.rows = [...this.rows]
          // .filter(e => e.causal?.code === '002')
          this.sortedCategories = this.rows.map(obj => obj.category.name).sort((a, b) => a.localeCompare(b)).filter((value, index, array) => array.indexOf(value) === index).filter(e => e.casual?.code === '002')
          console.log(this.sortedCategories);
          
          this.isLoading = 0;
          
          this.api.order_orders_by_status_read(url, 'SAL').subscribe(data => {
            console.log(data);
            
            this.page.totalElements += data.total_items;
            this.page.totalPages += this.page.totalElements / this.page.size;
            console.log(data.results);
            

            this.rows = [...this.rows, ...data.results]; 
            // .filter(e => e.causal?.code === '002')
            this.sortedCategories = this.rows.map(obj => obj.category.name).sort((a, b) => a.localeCompare(b)).filter((value, index, array) => array.indexOf(value) === index).filter(e => e.casual?.code === '002')
            console.log(this.sortedCategories);
            
            this.isLoading = 0;
            
            
            //this.loaderCtrl.dismiss()
            
          });
          this.loaderCtrl.dismiss()
          
        });

        // this.api.order_orders_by_status_read(url, 'SAL').subscribe(data => {
        //   console.log(data);
          
        //   this.page.totalElements = data.total_items;
        //   this.page.totalPages = this.page.totalElements / this.page.size;
        //   console.log(data.results);
          
        //     this.rows = data.results
        //     this.filteredData = this.rows
        //     this.rows = [...this.rows]
        //   // .filter(e => e.causal?.code === '002')
        //   this.sortedCategories = this.rows.map(obj => obj.category.name).sort((a, b) => a.localeCompare(b)).filter((value, index, array) => array.indexOf(value) === index).filter(e => e.casual?.code === '002')
        //   console.log(this.sortedCategories);
          
        //   this.isLoading = 0;
          
          
        //   //this.loaderCtrl.dismiss()
          
        // });
        
      }
      else if(this.viewListCPR){
        url = url + '&causal_id=1'
        this.isLoading = 1
        console.log('CPR rows');
        
        this.api.order_orders_by_status_read(url, 'CPR').subscribe(data => {
          console.log(data);
          
          this.page.totalElements = data.total_items;
          this.page.totalPages = this.page.totalElements / this.page.size;
          console.log(data.results);
          
            this.rows = data.results
            this.filteredData = this.rows
            this.rows = [...this.rows]
          // .filter(e => e.causal?.code === '002')
          this.sortedCategories = this.rows.map(obj => obj.category.name).sort((a, b) => a.localeCompare(b)).filter((value, index, array) => array.indexOf(value) === index).filter(e => e.casual?.code === '002')
          console.log(this.sortedCategories);
                   
          
          //this.loaderCtrl.dismiss()
          this.api.order_orders_by_status_read(url, 'ORD').subscribe(data => {
            console.log(data);
            
            this.page.totalElements += data.total_items;
            this.page.totalPages += this.page.totalElements / this.page.size;
            console.log(data.results);
            
              // this.rows = data.results
              // this.filteredData = this.rows
              this.rows = [...this.rows, ...data.results]; 
              console.log(this.rows);
              
            // .filter(e => e.causal?.code === '002')
            this.sortedCategories = this.rows.map(obj => obj.category.name).sort((a, b) => a.localeCompare(b)).filter((value, index, array) => array.indexOf(value) === index).filter(e => e.casual?.code === '002')
            console.log(this.sortedCategories);
            
            this.isLoading = 0;
            
            
            //this.loaderCtrl.dismiss()
            
          });
          
        });

  
      }
      else if(this.viewListAck){
        console.log('entro in ack');
        
        this.api.order_orders_by_status_read(url, 'ACK').subscribe(data => {
          console.log(data);
          
          this.page.totalElements = data.total_items;
          this.page.totalPages = this.page.totalElements / this.page.size;
            this.rows = data.results
            this.rows = [...this.rows]
          
          console.log(this.rows);
          this.sortedCategories = this.rows.map(obj => obj.category.name).sort((a, b) => a.localeCompare(b)).filter((value, index, array) => array.indexOf(value) === index)
          console.log(this.sortedCategories);
          
          this.isLoading = 0;
          
          
          //this.loaderCtrl.dismiss()
          
        });
      }
      else if ((this.viewListOrderVendor && !this.viewListJob)  && !this.jobModal) {
        console.log(filter);
        console.log('order vendor no job');
        
        
        let combineResults = (data) => {
            console.log(data);
            
           this.page.totalElements = data.total_items;
            this.page.totalPages = this.page.totalElements / this.page.size;
            this.rows = data.results.filter(e=> e.status.code !== 'DEL')
            this.rows = [...this.rows]
            if(this.rows.length!==0){
              this.api.setItem(true)
            }
            this.api.setVendors(data)

    
            this.sortedCategories = this.rows.map(obj => obj.category.name)
                                             .sort((a, b) => a.localeCompare(b))
                                             .filter((value, index, array) => array.indexOf(value) === index);
            console.log(this.sortedCategories);
    
            this.isLoading = 0;
            this.tempRows = this.rows
            // this.viewListOrderVendor = false
            // this.viewListJob = false 
        };
    
        // this.page.totalElements = 0;
        // this.rows = [];
        this.isLoading = 1;
    
        let urlVendor = url + '&vendor__company__pk=' + this.vendorName;
        this.api.order_orders_list(urlVendor).subscribe(combineResults);

      }
      else if ((this.viewListOrderVendor && this.viewListJob)  && !this.jobModal) {
        
        console.log(filter);
        console.log('order vendor + job');

        this.isLoading = 1;
    
         let combineResults = (data) => {
          console.log(data);
          
         this.page.totalElements = data.total_items;
          this.page.totalPages = this.page.totalElements / this.page.size;
          this.rows = data.results
          this.rows = [...this.rows]
          if(this.rows.length!==0){
            this.api.setItem(true)
          }

  
          this.sortedCategories = this.rows.map(obj => obj.category.name)
                                           .sort((a, b) => a.localeCompare(b))
                                           .filter((value, index, array) => array.indexOf(value) === index);
          console.log(this.sortedCategories);
          this.tempRows = this.rows
  
          this.isLoading = 0;
          // this.viewListOrderVendor = false
          // this.viewListJob = false 
      };
  
      // this.page.totalElements = 0;
      // this.rows = [];
      this.isLoading = 1;
  
      let urlVendor = url + '&vendor__company__pk=' + this.vendorName + '&job_id='+this.jobId.id;
      this.api.order_orders_list(urlVendor).subscribe(combineResults);
      }

      else if ((this.viewListOrderCustomer && !this.viewListJob)  && !this.jobModal) {
        console.log(filter);
        console.log('order customer no job');
        
        
        let combineResults = (data) => {
            console.log(data);
            
           this.page.totalElements = data.total_items;
            this.page.totalPages = this.page.totalElements / this.page.size;
            this.rows = data.results
            this.rows = [...this.rows]
            if(this.rows.length!==0){
              this.api.setItem(true)
            }
            this.api.setVendors(data)

    
            this.sortedCategories = this.rows.map(obj => obj.category.name)
                                             .sort((a, b) => a.localeCompare(b))
                                             .filter((value, index, array) => array.indexOf(value) === index);
            console.log(this.sortedCategories);
    
            this.isLoading = 0;
            this.tempRows = this.rows
            // this.viewListOrderVendor = false
            // this.viewListJob = false 
        };
    
        // this.page.totalElements = 0;
        // this.rows = [];
        this.isLoading = 1;
    
        let urlCustomer = url + '&customer_id=' + this.customerName;
        this.api.order_orders_list(urlCustomer).subscribe(combineResults);

      }
      else if ((this.viewListOrderCustomer && this.viewListJob)  && !this.jobModal) {
        
        console.log(filter);
        console.log('order customer + job');

        this.isLoading = 1;
    
         let combineResults = (data) => {
          console.log(data);
          
         this.page.totalElements = data.total_items;
          this.page.totalPages = this.page.totalElements / this.page.size;
          this.rows = data.results
          this.rows = [...this.rows]
          if(this.rows.length!==0){
            this.api.setItem(true)
          }

  
          this.sortedCategories = this.rows.map(obj => obj.category.name)
                                           .sort((a, b) => a.localeCompare(b))
                                           .filter((value, index, array) => array.indexOf(value) === index);
          console.log(this.sortedCategories);
          this.tempRows = this.rows
  
          this.isLoading = 0;
          // this.viewListOrderVendor = false
          // this.viewListJob = false 
      };
  
      // this.page.totalElements = 0;
      // this.rows = [];
      this.isLoading = 1;
  
      let urlCustomer = url + '&customer_id=' + this.customerName + '&job_id='+this.jobId.id;
      this.api.order_orders_list(urlCustomer).subscribe(combineResults);
      }
      else if ((this.viewListJob && !this.viewListOrderVendor) && this.jobModal) {
        console.log(url)      
        console.log('order job no vendor');

        this.api.getOrdersByJobId(this.jobId.id, url).subscribe(data => {
          console.log(data);
          this.rows= []
  
          this.page.totalElements = data.total_items;
          this.page.totalPages = this.page.totalElements / this.page.size;

            
          this.rows = data.results
          this.rows = [...this?.rows]
          if(this.rows.length!==0){
            this.api.setItem(true)
          }

          console.log(this.rows);
          this.tempRows = this.rows
          

          this.isLoading = 0;
          // this.viewListOrderVendor = false
          //   this.viewListJob = false 
          
          
          //this.loaderCtrl.dismiss()
          
        });
      }
      else if ((this.viewListJob && this.viewListOrderVendor) && this.jobModal) {
        console.log(filter);
        console.log('order job + vendor');

        this.isLoading = 1;
        console.log(this.jobIDTable);

  
        let urlVendor = url + '&vendor__company__pk=' + this.vendorName + '&job_id='+this.jobIDTable.id;
        this.api.order_orders_list(urlVendor).subscribe(data =>{
            console.log(data);
            
            this.rows = data.results
            this.page.totalElements = this.rows.length;
            this.page.totalPages = this.page.totalElements / this.page.size;
            console.log(this.rows);
            
            this.sortedCategories = this.rows.map(obj => obj.category.name)
            .sort((a, b) => a.localeCompare(b))
            .filter((value, index, array) => array.indexOf(value) === index);
            console.log(this.sortedCategories);
  
            this.tempRows = this.rows
            this.isLoading = 0
            // this.viewListOrderVendor = false
            // this.viewListJob = false 

            this.isLoading = 0;
          });
          
          

  
      // this.page.totalElements = 0;
      // this.rows = [];
  
     
        

      }
      else{
        this.api.order_orders_list(url).subscribe(data => {
          console.log(data);
          
          this.page.totalElements = data.total_items;
          this.page.totalPages = this.page.totalElements / this.page.size;
            this.rows = data.results
            this.rows = [...this.rows]
          
          console.log(this.rows);
          this.sortedCategories = this.rows.map(obj => obj.category.name).sort((a, b) => a.localeCompare(b)).filter((value, index, array) => array.indexOf(value) === index)
          console.log(this.sortedCategories);
          
          this.isLoading = 0;
          
          
          //this.loaderCtrl.dismiss()
          
        });
      }
  }




  getOrdersListById(url) {

    console.log(url);

    if (url && url != '') {
      url = this.concateQuery()
    }
    //this.isLoading++;
    //this.isLoading++
    let loader = this.loaderCtrl.create({
      message: '',
      duration: 13000
    }).then((response) => {
      response.present();
    });

    return
    console.log('load api list by project id');
    this.api.getOrdersByProjectId(this.projectId, url).subscribe(data => {
      this.total_page = data.num_pages
      this.pageNumber++
      const rows = [...this.rows, ...data.results];
      this.rows = rows;
      //this.isLoading--;
      this.loaderCtrl.dismiss()
    });
  }

  getOrderCode(code) {

    let codes = code.split('.')
    let string = ''
    for (var i = 5; i < codes.length; i++) {
      if (i != 5)
        string += '.' + codes[i]
      else
        string += codes[i]
    }
    return string;
  }


  methodAction(action) {

    try {
      this[action]()
    } catch {
      console.log('not call in parent component', action);
      this.emitterOpenNewOrder.emit(action)
    }
  }


  dataChangedReaded(newObj) {
    // here comes the object as parameter
    // console.log('dataChangedReaded', newObj);
    // //this.activeStatusOrder = true
    // this.updateFilterItem('status', newObj)

    // this.setPage({ offset: 0 })

  }

  resetSort(event) {
    let sorted
    console.log('EVENT::resetSort', event);
    event.stopPropagation()
    event.preventDefault()

    this.stringStatusOrderFilter = ''
    this.activeStatusOrder = false

    this.updateFilterItem('status', '')

    //this.setPage({ offset: 0 })

    console.log('EVENT::resetSort::End');

  }


  updateFilterItem(filter, data) {
    this.filterItems = JSON.parse(localStorage.getItem('filter_orders'))
    let foundIndex

    foundIndex = this.filterItems.map(e => e.search_by).indexOf(filter);
    if (foundIndex == -1) {
      this.filterItems.push({ search_by: filter, search_data: data });
    } else {
      this.filterItems[foundIndex] = { search_by: filter, search_data: data };

    }

    localStorage.setItem('filter_orders', JSON.stringify(this.filterItems))
    console.log('updateFilterItem', this.filterItems);


    this.filterItems.map(e => {
      if (e.search_data != '') {
        switch (e.search_by) {
          case 'status':
            this.activeStatusOrder = true
            this.stringStatusOrderFilter = e.search_data
            break;


          default:
            break;
        }
      } else if (e.search_data == '') {
        switch (e.search_by) {
          case 'status':
            this.activeStatusOrder = false
            this.stringStatusOrderFilter = e.search_data
            break;


          default:
            break;
        }
      }
    })



  }

  onActivateCategory(event) {
    console.log(event)
    //this.openOrderModal(event.row)
    this.clickedCategory.emit(event);

    /* this.submenuService.component.next(component.code) */
  }


  onMouseLeave(): void {
    clearTimeout(this.hoverTimer);
  }


  exportToCSV(){
    console.log('aaaaa');
    let page_ = 1;
    let url = '?page=' + page_ + '&items=' + this.page.totalElements;
    console.log(url);
    let count = 0
    let costoTotale = 0
    this.openAlertExport(url)
    // if(this.page.totalElements <= 25){
    //   const filteredData = this.rows.map(item => ({
    //     ID: item.id,
    //     Commessa: item.job?.name,
    //     'Codice commessa': item.code_slug,
    //     Descrizione: item.lines[0]?.description,
    //     Stato: item.status?.name,
    //     Tipo: item.type?.name,
    //     Categoria: item.category?.name,
    //     Cliente: item.customer?.name,
    //     Venditore: item.vendor?.name,
    //     Costo: item.cost,
    //     // Aggiungi qui altre chiavi che vuoi esportare
        
    //   }));
      
    //   filteredData.forEach(element => {
    //     count++
    //     costoTotale += element.Costo
    //   });
    //   console.log(count);
    //   console.log(costoTotale);
      
    //   // Converti i dati in un formato adatto per XLSX
    //   const worksheet = XLSX.utils.json_to_sheet(filteredData);
    //   const workbook = XLSX.utils.book_new();
    //   XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      
    //   // Esporta il file
    //   XLSX.writeFile(workbook, 'data.xlsx');
      
    // }
    // else{
    //   this.openAlertExport(url)
    // }

    
  }
  enableSpinner = false
  confirmedCopy = false
  letInfoJob(){
    let page_ = 1;
    this.enableSpinner = true
    let url = '?page=' + page_ + '&items=' + this.page.totalElements;
    this.api.getOrdersByJobId(this.jobId.id, url).subscribe(data => {
      this.isLoading = 0
      let ordini = 0
      let ordiniChiusi = 0
      let costoTotale = 0
      let costoChiusi = 0
      const filteredData = data.results.map(item => ({
        ID: item.id,
        Commessa: item.job?.name,
        'Codice commessa': item.code_slug,
        Descrizione: item.lines[0]?.description,
        Stato: item.status?.name,
        Tipo: item.type?.name,
        Categoria: item.category?.name,
        Cliente: item.customer?.name,
        Venditore: item.vendor?.name,
        Costo: item.cost,
        // Aggiungi qui altre chiavi che vuoi esportare
      }));

      filteredData.forEach(element => {
        if(element.Stato == 'Chiuso'){
          ordiniChiusi++
          costoChiusi += element.Costo
        }
        ordini++
        costoTotale += element.Costo
      });
      console.log(ordini);
      console.log(costoTotale);
      this.openAlertInfoJob(ordini, costoTotale, ordiniChiusi, costoChiusi)
    })
  }

  filterByStatus(value){
    let page_ = 1;
    let url = '?page=' + page_ + '&items=' + this.page.totalElements + '&job_id=' + this.jobId.id;
    this.api.order_orders_by_status_read(url, value).subscribe(data => {
      console.log(data);
      
      this.page.totalElements = data.total_items;
      this.page.totalPages = this.page.totalElements / this.page.size;
        this.rows = data.results
        this.rows = [...this.rows]          
      console.log(this.rows);
      this.sortedCategories = this.rows.map(obj => obj.category.name).sort((a, b) => a.localeCompare(b)).filter((value, index, array) => array.indexOf(value) === index)
      console.log(this.sortedCategories);
      
      this.isLoading = 0;
      
      
      //this.loaderCtrl.dismiss()
      
    });
  }


  async openAlertInfoJob(count, costoTotale, ordiniChiusi, costoChiusi) {
    // Formatta costoTotale come stringa di valuta
    const formattedCostoTotale = costoTotale.toLocaleString('it-IT', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 1,
      maximumFractionDigits: 3,
    });
    const formattedCostoChiusi = costoChiusi.toLocaleString('it-IT', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 1,
      maximumFractionDigits: 3,
    });
  
    // Disabilita lo spinner prima di mostrare l'alert
    this.enableSpinner = false;
  
    // Crea l'alert senza input, ma con le informazioni nel messaggio
    const alert = await this.alertController.create({
      header: 'Informazioni commessa',
      message: `Numero Ordini: ${count}<br>di cui chiusi: ${ordiniChiusi}<br>Costo Totale: ${formattedCostoTotale}<br>di cui chiusi: ${formattedCostoChiusi}`,
      buttons: [
        {
          text: 'Copia Informazioni',
          handler: () => {
            navigator.clipboard.writeText(`Numero Ordini: ${count}\nDi cui chiusi: ${ordiniChiusi}\nCosto Totale: ${formattedCostoTotale}\nDi cui chiusi: ${formattedCostoChiusi}`).then(() => {
              console.log('Informazioni copiate nel clipboard');
              this.confirmedCopy = true
              setTimeout(() => {
                this.confirmedCopy = false
              }, 500);
            }).catch(err => {
              console.error('Errore nella copia nel clipboard', err);
            });
          }
        },
        {
          text: 'Okay'
        }
      ]
    });
  
    // Mostra l'alert
    await alert.present();
  }

  async openAlertInfoOrder() {
    // Formatta costoTotale come stringa di valuta
    // Crea l'alert senza input, ma con le informazioni nel messaggio
    const alert = await this.alertController.create({
      header: 'Informazioni utili',
      message: new IonicSafeString('<ion-button>Hello!</ion-button>'),

      // message: `
      // Copia commessa: Puoi copiare il codice commessa cliccando in alto a sinistra sul titolo.<br>
      // Copia descrizione: Puoi copiare la descrizione cliccando sull'apposita cella.\n
      // Allegati e stampa: Puoi scaricare e vedere velocemente gli allegati dell'ordine cliccando sulle apposite icone a sinistra.
      // `,
      buttons: [
        {
          text: 'Okay'
        }
      ]
    });
  
    // Mostra l'alert
    await alert.present();
  }

  copyDescription(row:any){
    let text = row.description
    if(text === ""){
      text = row.lines[0].description
    }
    console.log(text);
      navigator.clipboard.writeText(text).then(() => {
        console.log('Testo copiato negli appunti:', text);
        // Mostra un messaggio all'utente, se necessario
        this.openAlertDesc()
      }).catch(err => {
        console.error('Errore durante la copia negli appunti:', err);
      });
  }
  
  async openAlertDesc(){
    const alert = await this.alertController.create({
      message: 'Descrizione copiata con successo!',
      buttons: [
        {
          text: 'Ok',
        },
  
      ],
    });
    await alert.present();
  }

  
  async openAlertExport(url) {
    const alert = await this.alertController.create({
      header: 'Attenzione',
      message: 'Scegli il tipo di export',
      buttons: [
        {
          text: 'Annulla',
          role: 'cancel',
          handler: () => {
            console.log('annullo exports ordine');
          }
        },
        {
          text: 'Tutti gli ordini',
          handler: () => {
            this.isLoading = 1
            this.exportAllOrder(url)
          }
        },
        {
          text: 'F. Attiva',
          handler: () => {
            this.isLoading = 1
            this.exportAttivaOrder(url)
          }
        },
        {
          text: 'F. Passiva',
          handler: () => {
            this.isLoading = 1
            this.exportPassiveOrder(url)
          }
        }
      ],
    });
    await alert.present();

  }

  exportAllOrder(url){
    this.api.getOrdersByJobId(this.jobId.id, url).subscribe(data => {
      this.isLoading = 0
      let count = 0
      let costoTotale = 0
      const filteredData = data.results.map(item => ({
        ID: item.id,
        Commessa: item.job?.name,
        'Codice commessa': item.code_slug,
        Descrizione: item.lines[0]?.description,
        Stato: item.status?.name,
        Tipo: item.type?.name,
        Categoria: item.category?.name,
        Cliente: item.customer?.name,
        Venditore: item.vendor?.name,
        Costo: item.cost,
        // Aggiungi qui altre chiavi che vuoi esportare
      }));

      filteredData.forEach(element => {
        count++
        costoTotale += element.Costo
      });
      let job = filteredData[0]['Codice commessa']
      console.log(count);
      console.log(costoTotale);
      
      // Converti i dati in un formato adatto per XLSX
      const worksheet = XLSX.utils.json_to_sheet(filteredData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      
      // Esporta il file
      XLSX.writeFile(workbook, 'export_'+ job +'.xlsx');
      
    })
  }

  exportAttivaOrder(url){
    this.api.getOrdersByJobId(this.jobId.id, url).subscribe(data => {
      this.isLoading = 0
      let count = 0
      let costoTotale = 0
      const filteredData = data.results.filter(item => item.vendor.name === 'Cool Projects SRL');
      const mappedDate = filteredData.map(item => ({
        ID: item.id,
        Commessa: item.job?.name,
        'Codice commessa': item.code_slug,
        Descrizione: item.lines[0]?.description,
        Stato: item.status?.name,
        Tipo: item.type?.name,
        Categoria: item.category?.name,
        Cliente: item.customer?.name,
        Venditore: item.vendor?.name,
        Costo: item.cost,
        // Aggiungi qui altre chiavi che vuoi esportare
      }));

      mappedDate.forEach(element => {
        count++
        costoTotale += element.Costo
      });
      console.log(count);
      console.log(costoTotale);
      
      let job = mappedDate[0]['Codice commessa']

      // Converti i dati in un formato adatto per XLSX
      const worksheet = XLSX.utils.json_to_sheet(mappedDate);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      
      // Esporta il file
      XLSX.writeFile(workbook, 'exportAttiva_'+ job +'.xlsx');
      
    })
  }

  exportPassiveOrder(url){
    this.api.getOrdersByJobId(this.jobId.id, url).subscribe(data => {
      this.isLoading = 0
      let count = 0
      let costoTotale = 0
      const filteredData = data.results.filter(item => item.customer.name === 'Cool Projects SRL');
      const mappedDate = filteredData.map(item => ({
        ID: item.id,
        Commessa: item.job?.name,
        'Codice commessa': item.code_slug,
        Descrizione: item.lines[0]?.description,
        Stato: item.status?.name,
        Tipo: item.type?.name,
        Categoria: item.category?.name,
        Cliente: item.customer?.name,
        Venditore: item.vendor?.name,
        Costo: item.cost,
        // Aggiungi qui altre chiavi che vuoi esportare
      }));

      mappedDate.forEach(element => {
        count++
        costoTotale += element.Costo
      });
      console.log(count);
      console.log(costoTotale);
      console.log(filteredData);
      
      let job = mappedDate[0]['Codice commessa']

      // Converti i dati in un formato adatto per XLSX
      const worksheet = XLSX.utils.json_to_sheet(mappedDate);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      
      // Esporta il file
      XLSX.writeFile(workbook, 'exportPassiva_'+ job +'.xlsx');
      
    })
  }

  searchListInv(url, keywords) {
    // Se keywords è una stringa, convertila in un array
    this.api.order_orders_by_status_read(url, 'INV').subscribe(data => {
      console.log(data);
      
      this.page.totalElements = data.total_items;
      this.page.totalPages = this.page.totalElements / this.page.size;
      console.log(data.results);
      
        this.rows = data.results
        this.filteredData = this.rows
        this.rows = [...this.rows]
      // .filter(e => e.causal?.code === '002')
      this.sortedCategories = this.rows.map(obj => obj.category.name).sort((a, b) => a.localeCompare(b)).filter((value, index, array) => array.indexOf(value) === index).filter(e => e.casual?.code === '002')
      console.log(this.sortedCategories);
      
      this.isLoading = 0;
      
      this.api.order_orders_by_status_read(url, 'SAL').subscribe(data => {
        console.log(data);
        
        this.page.totalElements += data.total_items;
        this.page.totalPages += this.page.totalElements / this.page.size;
        console.log(data.results);
        

        this.rows = [...this.rows, ...data.results]; 
        // .filter(e => e.causal?.code === '002')
        this.sortedCategories = this.rows.map(obj => obj.category.name).sort((a, b) => a.localeCompare(b)).filter((value, index, array) => array.indexOf(value) === index).filter(e => e.casual?.code === '002')
        console.log(this.sortedCategories);
        
        this.isLoading = 0;
        
        
        //this.loaderCtrl.dismiss()
        
      });
      this.loaderCtrl.dismiss()
      
    });
  }

  searchListCPR(url, keywords) {
    this.api.order_orders_by_status_read(url, 'CPR').subscribe(data => {
      console.log(data);
      
      this.page.totalElements = data.total_items;
      this.page.totalPages = this.page.totalElements / this.page.size;
      console.log(data.results);
      
        this.rows = data.results
        this.filteredData = this.rows
        this.rows = [...this.rows]
      // .filter(e => e.causal?.code === '002')
      this.sortedCategories = this.rows.map(obj => obj.category.name).sort((a, b) => a.localeCompare(b)).filter((value, index, array) => array.indexOf(value) === index).filter(e => e.casual?.code === '002')
      console.log(this.sortedCategories);
               
      
      //this.loaderCtrl.dismiss()
      this.api.order_orders_by_status_read(url, 'ORD').subscribe(data => {
        console.log(data);
        
        this.page.totalElements += data.total_items;
        this.page.totalPages += this.page.totalElements / this.page.size;
        console.log(data.results);
        
          // this.rows = data.results
          // this.filteredData = this.rows
          this.rows = [...this.rows, ...data.results]; 
          console.log(this.rows);
          
        // .filter(e => e.causal?.code === '002')
        this.sortedCategories = this.rows.map(obj => obj.category.name).sort((a, b) => a.localeCompare(b)).filter((value, index, array) => array.indexOf(value) === index).filter(e => e.casual?.code === '002')
        console.log(this.sortedCategories);
        
        this.isLoading = 0;
        
        
        //this.loaderCtrl.dismiss()
        
      });
      
    });
  }
  childJob
  onSelectChild(child){
    console.log(child);
    this.childJob = child.detail.value
    this.rows = this.tempRows
    this.rows = this.rows.filter(e=> e.job.name === this.childJob)
  }


  downloadFile(event){
    this.clicked.emit(event)
  }

  setColumns(value){

    if(value){

      this.columns = [
        //{ prop: 'id', name: 'ID'/* , frozenLeft: true */, minWidth: 75, maxWidth: 75, flexGrow: 0.3, cellClass: 'td-id', headerClass: 'th-id' },
        /* {
          prop: 'id', width: 75, minWidth: 75
        }, */
        {
          prop: 'code', flexGrow: 1, minWidth: 100, maxWidth: 100, cellClass: 'td-code', headerClass: 'th-code',
        },
        {
          prop: 'cost', flexGrow: 1, minWidth: 180, maxWidth: 180, pipe: new CurrencyOnlyPipe('en-US', '€',),
          /*  summaryFunc: () => this.caclulateSumm('€'), */
        },
        { prop: 'job.code_slug', name: 'Job Code', maxWidth: 250, minWidth: 100, flexGrow: 2 },
        { prop: 'description', name: 'Description', maxWidth: 350, minWidth: 250, flexGrow: 3, cellTemplate: this.descriptionTemplate, },
        //{ prop: 'job.code_slug', name: 'Job Code', maxWidth: 250, minWidth: 150, flexGrow: 2 },
        { prop: 'vendor.name', name: 'Vendor', flexGrow: 1, minWidth: 240, maxWidth: 240, },
        { prop: 'customer.name', name: 'Customer', flexGrow: 1, minWidth: 240, maxWidth: 240, },
        //{ prop: 'deleted', maxWidth: 70, cellClass: 'td-deleted', headerClass: 'th-deleted' },pipe: new LinesDescriptionPipe()
        // {
        //   prop: 'copy', name: 'Copy', flexGrow: 1, minWidth: 90, maxWidth: 90,
        //   cellTemplate: this.copyTemplate
        // },
        // {
        //   prop: 'action', name: 'Delete', flexGrow: 1, minWidth: 90, maxWidth: 90,
        //   cellTemplate: this.actionTemplate
        // },
  
  
      ]
    }
    else{
      this.columns = this.backupColumns
    }

  }
  closeInfo(){
    this.confirmedCopy = false
  }
}




