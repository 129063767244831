import { AfterViewInit, Component, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { IonContent, IonNav, NavController, Platform, NavParams, ToastController, ModalController, LoadingController, ToastOptions } from '@ionic/angular';
import { RenderDocumentsPage } from 'src/app/documents/render-documents/render-documents.page';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';

import { BehaviorSubject, interval, Observable, Subscription } from 'rxjs';

//import { Uid } from '@ionic-native/uid/ngx';
//import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { ModalNFCComponent } from 'src/app/components/nfc/nfc.component';
import { duration } from 'moment';
import { TitleAppService } from 'src/app/services/utils/title-app/title-app.service';
import { CustomToastModalComponent } from 'src/app/components/custom-toast-modal/custom-toast-modal.component';
import { Router } from '@angular/router';
import { RoutingStateService } from 'src/app/services/routing-state/routing-state.service';

declare module "@ionic/angular" {
  namespace ToastOptions {
    //@ts-ignore
    interface ToastOptions extends ToastOptions {
      message?: string;
    }
  }
}


@Component({
  selector: 'app-profile-user',
  templateUrl: './profile-user.page.html',
  styleUrls: ['./profile-user.page.scss'],
})
export class ProfileUserPage {

  @Input() data;
  @ViewChild(IonContent) content: IonContent;


  modalNFC = ModalNFCComponent
  username: string;
  fieldsProfile
  fab: boolean = false;


  nfcReading = false;
  readerModeSub: Subscription;
  intervalSub: Subscription;
  discoveredListenerSub: Subscription;
  nfcTag = '';
  loading

  y = new BehaviorSubject(1)
  randomnumber = new BehaviorSubject(Math.floor(Math.random() * (6 - 1 + 1)) + 1)

  timeout

  constructor(
    private navCtrl: NavController,
    //private nav: IonNav,
    private auth: AuthenticationService,
    private plt: Platform,
    //private androidPermissions: AndroidPermissions,
    private toastController: ToastController,
    private modalController: ModalController,
    public platform: Platform,
    private loadingController: LoadingController,
    private titleAppService: TitleAppService,
    private router: Router,
    private routingState: RoutingStateService
    ) {

    this.getUser()

  }

  getObjectKeys(obj) {

    console.log(obj);

    //console.log(obj);

    return Object.keys(JSON.parse(obj))
  }
  parseJSON(str) {
    let obj = JSON.parse(str)
    return obj
  }




  closeModal() {
    setTimeout(() => {

      this.loadingController.getTop().then(v => v ? this.loadingController.dismiss() : null);
      this.modalController.dismiss(undefined, undefined, 'ion-overlay-nfc-modal');
      //this.toastController.dismiss()


    }, 650);
  }


  async openModalNFC(ev?) {
    console.log('openModalNFC');

    this.y.next(1)

    let modal = await this.modalController.create({
      id: 'ion-overlay-nfc-modal',
      component: ModalNFCComponent,
      cssClass: 'popover_setting',
      //event: ev,
      //translucent: true,
      mode: 'md',
      breakpoints: [0.5],
      initialBreakpoint: 0.5,
      componentProps: {
        duration: this.randomnumber,
        status: this.y
      }

    });

    this.presentLoadingWithOptions('Avvicina il telefono al dispositivo...', 4000);
    return await modal.present();


  }


  async presentToastWithOptions(title, msg, icon, duration, status, buttons?) {

    let modal = await this.modalController.create({
      id: 'ion-overlay-toast-modal',
      component: CustomToastModalComponent,
      cssClass: 'custom-modal-page',
      //event: ev,
      //translucent: true,
      mode: 'md',
      componentProps: {
        data: {
          title, msg, icon, duration, status, buttons
        },
        status: this.y
      }
    });

    this.presentLoadingWithOptions('Avvicina il telefono al dispositivo...', 4000);
    return await modal.present();

    let btn = [
      {
        //side: 'start',
        text: 'OK',
        role: 'cancel',
        handler: () => {
          console.log('Cancel clicked');
        }
      }
    ]

    let btns = [
      {
        //icon: 'information-circle-outline',
        text: 'REG',
        handler: () => {
          console.log('info clicked');
        }
      }, {
        //side: 'start',
        text: 'OK',
        role: 'cancel',
        handler: () => {
          console.log('Cancel clicked');
        }
      }
    ]

    const toast = await this.toastController.create({
      header: title,
      duration: duration,
      color: status,
      message: msg,
      icon: icon,
      position: 'bottom',
      buttons: buttons == true ? btn : buttons == false ? btns : btn
    });

    await toast.present();

    const { role } = await toast.onDidDismiss();
    //console.log('onDidDismiss resolved with role', role);
  }




  ionViewWillEnter() {
    console.log('willEnterProfile');
    this.titleAppService.title.next('Profile')


  }

  //goBack() {
  //this.navCtrl.back({ animated: false });
  //this.nav.canGoBack({animated:false, direction: 'forward'})

  //}

  /* toDoc() {
    //nav: this.nav(this.documents, { id: 0 }, { animated: false, direction: 'forward' })
  } */

  logout() {
    this.auth.logout();
  }

  onScroll(event) {

    if (event.detail.currentY >= 100) {
      //console.log(event);
      //console.log(this.fab);
      this.fab = true;
    } else if (event.detail.currentY <= 99) {
      //console.log(event);
      //console.log(this.fab);
      this.fab = false;
    }
  }

  async presentLoadingWithOptions(msg, duration) {
    this.loading = await this.loadingController.create({
      spinner: null,
      duration: duration,
      message: msg,
      translucent: true,
      cssClass: 'custom-class custom-loading'
    });
    return await this.loading.present();
  }

  getUser() {
    this.auth.getLocalData('auth-profile').then(e => {
      console.log("this.auth.getLocalData('auth-profile')", e);
      this.username = e.datas[0]
    }).catch(err => {
      console.log(err);

    })
  }

  
  goBack(){
    this.router.navigate(['/'+ this.routingState.navigateBack()])
  }

}
