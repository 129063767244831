import { TableListComponent } from './../../components/table/list/list.component';
import { TasksService } from './../../services/tasks/tasks.service';
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-inferrable-types */

import { ChangeDetectionStrategy, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';

import { ModalController, IonRouterOutlet, NavController, CheckboxCustomEvent, Platform, IonNav, ToastController, IonSearchbar } from '@ionic/angular';
import { from, Observable, of } from 'rxjs';
import { NewTaskComponent } from '../new-task/new-task.component';
import { DataService } from '../../services/data/data.service';
import { ApiService } from 'src/app/services/api/api.service';

import { TaskDetailsPage } from '../task-details/task-details.page';
import { ProjectsService } from 'src/app/services/projects/projects.service';
//import { ChangeDetectorRef, AfterContentChecked } from '@angular/core';


const TIMEOUT = 5;

@Component({
  //changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-tasks-list',
  templateUrl: './tasks-list.page.html',
  styleUrls: ['./tasks-list.page.scss'],
})
export class TasksListPage /* implements AfterContentChecked */ {
  //task: Observable<Task[]>;
  task: any = [];
  workers_columns = [];

  currentSegment: string = "All";
  color: string;
  disableLink = false;
  selectedItem: any = [];
  selecAllItems: boolean = true;
  timeoutPress = TIMEOUT;

  isLoad = false;

  total_page = 0;
  page_number = 1;
  page_limit = 30;

  toast: any;



  @ViewChild('prj') prjElement: ElementRef;
  @ViewChild('prjs') prjList: ElementRef;
  @ViewChild('star') btnStar: ElementRef;
  @ViewChild(IonSearchbar) myInput: IonSearchbar;
  @ViewChild(TableListComponent) listTable: TableListComponent;

  checkbox: boolean = false;

  constructor(
    //private cdref: ChangeDetectorRef,
    private dataService: DataService,
    private api: TasksService,
    public modalController: ModalController,
    private routerOutlet: IonRouterOutlet,
    private renderer: Renderer2,
    //private nav: IonNav,
    private navCtrl: NavController,
    public plt: Platform,
    private toastController: ToastController
  ) {

    //this.task = this.api.getProjectsList(true);

    this.workers_columns = [
      { prop: 'id', name: 'ID'/* , frozenLeft: true */, minWidth: 75, maxWidth: 75, cellClass: 'td-id', headerClass: 'th-id' },
      {
        prop: 'firstname', name: 'Name', width: 90, minWidth: 90, cellClass: 'td-priority', headerClass: 'th-priority'
      },
      {
        prop: 'code_fiscal', name: 'Code Fiscal', width: 90, minWidth: 90, cellClass: 'td-priority', headerClass: 'th-priority'
      },
      {
        prop: 'gender', name: 'Gender', width: 90, minWidth: 90, cellClass: 'td-priority', headerClass: 'th-priority'
      },
      {
        prop: 'birthcity', width: 90, minWidth: 90, cellClass: 'td-priority', headerClass: 'th-priority'
      },
      {
        prop: 'birthday', width: 220, minWidth: 220, cellClass: 'td-priority', headerClass: 'th-priority'
      }

    ]
  }

  ionViewWillEnter() {
    console.log('Will enter');
    this.isLoad = false;
    this.toast = undefined;

    this.timeoutPress = TIMEOUT;
    this.disableLink = false;
    this.total_page = 0;
    this.page_number = 1;
    this.myInput.value = "";
    this.loadItems(this.currentSegment);

  }

  filterProjects(event: any) {
    const selectedCategory = event.detail.value;
    this.currentSegment = selectedCategory;

    //this.loadItems(selectedCategory);
  }

  loadItems(status: string) {
    let url = '?page=' + this.page_number + '&items=' + this.page_limit;
    //if (status === 'All') { //category
    //this.task = this.dataService.getProjects();
    /* this.api.getProjectsList_rev(url).subscribe(e => {
      if (e && e['results'])
        this.task = e['results']
      console.log(this.task);
    }) */


    this.api.getTasksList().subscribe(data => {


      this.task = data.results
      console.log(this.task);
          
      this.listTable.listItems = this.task
      this.listTable.totalElements = this.task.length;
      this.listTable.total_page = 1;
      this.listTable.page_number = 1;

      setTimeout(() => {
        this.listTable.table.bodyComponent.offsetX = 0;
        this.listTable.table.bodyComponent.offsetY = 0;
        this.listTable.table.headerComponent.offsetX = 0;
        this.listTable.table.recalculateColumns();
        console.log('update list');
        this.listTable.listItems = [...this.listTable.listItems]
      }, 200);

      this.isLoad = true;

      /* } else {
        //this.task = this.dataService.getProjectsByCategory(category);
        //this.task = this.dataService.getProjectsByStatus(status);
        this.isLoad = true;
      } */

    })
  }



  async openNewProjectModal() {
    const modal = await this.modalController.create({
      component: NewTaskComponent,
      canDismiss: true,
      presentingElement: this.routerOutlet.nativeEl,
    });

    modal.onWillDismiss().then((e) => {

      if (e.data && e.data.message != 'OK') {
        console.log('no modify');
        return
      } else {
        this.loadItems(this.currentSegment);

      }
    });

    return await modal.present();
  }

  randomColor() {
    const colors = ["#4caf50", "#ffc107", "#607d8b", "#2196f3", "#e91e63", "#673ab7", "#009688"];
    const indexColor = Math.floor(Math.random() * colors.length);
    const randomColor = colors[indexColor];
    //this.color == randomColor ? ,
    return randomColor; //this.color;
  }


  /*   ngAfterContentChecked() {
      this.cdref.detectChanges();
    } */

  methodAction(action) {
    try {
      this[action]()
    } catch {
      console.log('not call in parent component');
    }
    //console.log(action)
  }

  /**
   * @description onClick on row open order details
   * @param event pass obj row clicked
   */
  onActivate(event) {
    /* if (this.table.rowDetail && event.type == 'click') {
      this.table.rowDetail.toggleExpandRow(event.row);
      console.log('Activate Event', event);
    } */
    console.log(event);

    if (event.type == 'click') {
      console.log('Activate Event', event);
      this.goTo(event.row.id, event.row)
    }


  }




  /**
   * Select item on longpress + multiple select + selecAll
   * @param item Elemento della lista
   */

  activeLine(item) {
    setTimeout(() => {

      this.renderer.addClass(item, 'select');
      this.renderer.setStyle(item.firstChild, 'transform', 'rotate3d(0, 1, 0, 360deg)');
      this.renderer.setProperty(item.firstChild, 'innerHTML', '<ion-icon name="checkmark-outline"></ion-icon>');
      /* let icon = this.renderer.createElement('ion-icon');
      this.renderer.setAttribute(icon, 'name', 'checkmark-outline');
      this.renderer.appendChild(item, icon) */
    }, 100);
  }

  dectiveLine(item) {
    setTimeout(() => {
      this.renderer.removeClass(item, 'select');
      this.renderer.setStyle(item.firstChild, 'transform', 'rotate3d(0, 0, 0, 0deg)');
      let letter = item.innerText.split('')[0]
      this.renderer.setProperty(item.firstChild, 'innerHTML', '<span class="letter_badge">' + letter + '</span>');
    }, 100);
  }

  resetSelect() {
    this.disableLink = false;
    this.selectedItem.map((e, a, i) => {
      this.dectiveLine(e.el);
    });
    this.selectedItem = [];
  }

  selectAll(event, list) {
    let prjs = [];
    for (let e = 0; e <= list.el.children.length - 1; e++) {
      if (!event.detail.checked) {
        this.resetSelect();
      } else {
        this.activeLine(list.el.children[e]);
        prjs.push({
          el: list.el.children[e],
          id: Number(list.el.children[e].id)
        });
      }
    }
    this.checkbox = true;
    this.selectedItem = prjs;
  }

  goTo(index, user) {

    console.log(index, user);

    //console.log(this.nav);;

    //nav: this.nav(TaskDetailsPage, { task: user }, { animated: true, direction: 'forward' })



  }

  onLongPress(event, $event?) {
    console.log("onLongPress", $event);
    this.timeoutPress = TIMEOUT;

  }

  onLongPressing(event, $event?: MouseEvent) {
    if (this.timeoutPress == TIMEOUT) {

      //console.log("onLongPressing", event, $event);

      //console.log('onLongPressing2-2');

      this.renderer.addClass(event.el, 'select');
      this.renderer.setStyle(event.el.firstChild, 'transform', 'rotate3d(0, 1, 0, 360deg)');
      this.renderer.setProperty(event.el.firstChild, 'innerHTML', '<ion-icon name="checkmark-outline"></ion-icon');
      this.disableLink = true;
      this.timeoutPress = this.timeoutPress - 1;
      console.log(this.timeoutPress);
    }

  }



  clickStar(elem, event) {
    event.stopPropagation();
    console.log(elem);
    if (this.selecAllItems) {
      this.renderer.setProperty(elem.el, 'name', 'star')
      this.renderer.setStyle(elem.el, 'color', '#fff')
    } else {
      this.renderer.setProperty(elem.el, 'name', 'star-outline')
      this.renderer.setStyle(elem.el, 'color', '#fff')

    }
    this.selecAllItems = !this.selecAllItems;

  }


  async presentToast(text, color) {
    const toast = await this.toastController.create({
      message: text,
      position: 'bottom',
      duration: 2000,
      cssClass: 'toast',
      color: color
    });
    toast.present();
  }


}

