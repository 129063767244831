// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content .content-col {
  --ion-grid-column-padding: 0px;
}
ion-content .add-photo-wrapper {
  justify-content: center;
}
ion-content .user-image {
  --size: 100px;
  margin: auto;
}

.custom-label {
  /* padding-top: 11px;*/
}

.custom-label ion-icon {
  font-size: 28px;
}

.p_d_t {
  padding-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/projects/update-project/update-project.page.scss"],"names":[],"mappings":"AAGE;EACE,8BAAA;AAFJ;AAKE;EACE,uBAAA;AAHJ;AAME;EACE,aAAA;EACA,YAAA;AAJJ;;AAQA;EAGE,sBAAA;AAPF;;AAUA;EACE,eAAA;AAPF;;AAUA;EACE,iBAAA;AAPF","sourcesContent":["ion-content {\n  //--padding-top: 16px;\n\n  .content-col {\n    --ion-grid-column-padding: 0px;\n  }\n\n  .add-photo-wrapper {\n    justify-content: center;\n  }\n\n  .user-image {\n    --size: 100px;\n    margin: auto;\n  }\n}\n\n.custom-label{\n  //display: contents;\n  //font-size: 10px;\n  /* padding-top: 11px;*/\n}\n\n.custom-label ion-icon{\n  font-size: 28px;\n}\n\n.p_d_t{\n  padding-top: 10px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
