// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.border-dashed {
  border-radius: 2px;
  margin: 0px;
}

/* ion-item-divider {
    background: var(--ion-background-color, #f1f1f1);
    color: #3880ff;
}
 */
ion-item-divider {
  --background: #0000;
  color: --ion-color-secondary;
}

/* ion-item-divider {
    background: var(--ion-color-primary, #3880ff);
    color: var(--ion-color-primary, #3880ff);
} */
input.native-input.sc-ion-input-md {
  font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/media/upload/upload.component.scss"],"names":[],"mappings":"AACA;EAEI,kBAAA;EACA,WAAA;AADJ;;AAIA;;;;EAAA;AAMA;EACI,mBAAA;EACA,4BAAA;AAFJ;;AAKA;;;GAAA;AAIA;EACI,eAAA;AAFJ","sourcesContent":["\n.border-dashed {\n    //border: 1px dashed #444;\n    border-radius: 2px;\n    margin: 0px;    \n}\n\n/* ion-item-divider {\n    background: var(--ion-background-color, #f1f1f1);\n    color: #3880ff;\n}\n */\n\nion-item-divider {\n    --background: #0000;\n    color:--ion-color-secondary;\n}\n\n/* ion-item-divider {\n    background: var(--ion-color-primary, #3880ff);\n    color: var(--ion-color-primary, #3880ff);\n} */\ninput.native-input.sc-ion-input-md {\n    font-size: 12px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
