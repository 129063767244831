import { Component, OnInit, Input, AfterViewChecked } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import * as moment from 'moment';


@Component({
  selector: 'app-modal-content',
  templateUrl: './modal-content.component.html',
  styleUrls: ['./modal-content.component.scss'],
})
export class ModalContentComponent {

  @Input() data: any;
  date
  minDate = new Date();
  maxDate;
  value = ""

  status = false;

  constructor(
    public modalController: ModalController,
    private popoverController: PopoverController
  ) {

  }

  ionViewWillEnter() {
    this.value = "";
    this.date = this.minDate;
    this.minDate = new Date(this.date);
    this.date = (this.minDate).toISOString();
    this.maxDate = new Date((this.minDate.setDate(this.minDate.getDate() + 30))).toISOString()
    this.value = this.maxDate;
  }

  ngAfterViewChecked(): void {

  }

  onChange(event) {
    console.log(event);
    this.value = event.detail.value
  }

  addTimeEnd(time) {

    //return moment(time).add(30, 'minutes').format()

  }

  dismissModal(value?) {
    if (value == 'confirm') {
      this.modalController.dismiss({ value: this.value })
    }else{
      this.modalController.dismiss({ value: 0 })
    }
  }


}
