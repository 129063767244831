import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { ModalController, ToastController } from '@ionic/angular';
import { InvoicesService } from 'src/app/services/invoices/invoices.service';
import { ProductsService } from 'src/app/services/products/products.service';


@Component({
  selector: 'app-modal-invoice-meta',
  templateUrl: './modal-invoice-meta.component.html',
  styleUrls: ['./modal-invoice-meta.component.scss'],
})
export class ModalInvoiceMetaComponent {
  @Input() invoice: any = '';
  @Input() type_meta: any = '';
  @Input() type_form: any = '';
  @Input() data: any = '';
  @ViewChild('createForm') createForm: FormGroupDirective;

  createGroupForm: FormGroup;

  advanceType: any = [];
  types: any = [];
  status: any = [];
  taxes: any = [];
  response = '';

  constructor(
    private invoiceApi: InvoicesService,
    private modalController: ModalController,
    private fb: FormBuilder,
    private productApi: ProductsService,
    private toastController: ToastController
  ) { }


  createBaseForm() {
    // Generete FormGroup Create Invoice 
    this.createGroupForm = this.fb.group({
      advance_type: this.fb.control(this.data.advance_type, Validators.required),
      value: this.fb.control('', Validators.required),
      invoice: this.fb.control('', Validators.required),
      orderline: this.fb.control('', Validators.required),
      tax: this.fb.control('', Validators.required),
      creator: this.fb.control('', Validators.required)
      //created: this.fb.control(moment(Date.now()).format('DD-MM-YYYY'), Validators.required),
      //type_select: this.fb.control('', Validators.required),
    });
    //console.log(this.order_orders_createForm);
  }

  createUpdateForm() {
    console.log(this.data, this.data);

    // Generete FormGroup Create Invoice 
    this.createGroupForm = this.fb.group({
      /*  id: this.fb.control(this.data.id, Validators.required),
       advance_type: this.fb.control(this.data.advance_type, Validators.required),
       value: this.fb.control(this.data.value, Validators.required),
       invoice: this.fb.control(this.data.invoice, Validators.required),
       orderline: this.fb.control(this.data.orderline, Validators.required), */
      tax: this.fb.control(this.data.tax.id, Validators.required),
      //creator: this.fb.control(this.data.creator, Validators.required)
      //type_select: this.fb.control(this.data.created, Validators.required),
    });
    //console.log(this.order_orders_createForm);
  }

  getOrderMeta() {

    let page_product = 1;
    let url = '?page=' + page_product + '&items=' + 25;

    this.invoiceApi.getListTypeAdvance().subscribe(e => {
      console.log(e);
      this.advanceType = e;
    })

    this.invoiceApi.getListType().subscribe(e => {
      console.log(e);
      this.types = e;
    })

    this.invoiceApi.getListStatus().subscribe(e => {
      console.log(e);
      this.status = e;
    })

    this.invoiceApi.getLinesTax().subscribe(e => {
      console.log(e);
      this.taxes = e.results;
      

      this.taxes.sort(function (a, b) {
        return (a.value - b.value);
      });
      console.log(this.taxes);

    })

  }

  ionViewWillEnter() {
    console.log(this.invoice, this.type_form, this.type_meta, this.data);

    this.response = '';
    this.getOrderMeta();

    if (this.type_form == 'create') {
      if (this.type_meta == 'line') {
        this.createBaseForm();
      }
      return

    } else if (this.type_form == 'update') {
      if (this.type_meta == 'line') {
        if (this.data) this.createUpdateForm();
      }
      return


    }
  }


  updateForm(form, type_meta, type_form) {
    // Map Address   
    /* form.address = this.addressMap(form.address)
    // Add New Contact
    this.addNewContact(form)
    // Add New Address
    this.addNewAddress(form) */

    //Call API
    console.log(form, this.invoice, this.type_meta, this.type_form);

    if (this.type_form == 'create') {
      if (this.type_meta == 'line') {
        this.createLine(form)
      } /* else if (this.type_meta == 'legal') {
        this.createLegal(form)
      }  */else {
        return
      }

    } else {
      if (this.type_meta == 'line') {
        this.updateLine(form)
      } /* else if (this.type_meta == 'legal') {
        this.order_orderlegal_update(form)

      }  */else {
        return
      }
    }

  }

  updateLine(data) {
    console.log(data);

    this.invoiceApi.putLinesTax(this.invoice.id, this.data.id, { orderline_id: this.data.orderline, tax_id: data.tax }).subscribe(e => {
      console.log(e);
      if (e && e.message && e.message.includes('OK')) {
        this.presentToast(e.message, 'success')
        this.response = 'OK';
      } else {
        this.response = 'KO';
      }
      this.dismissModal();
    })
  }

  createLine(data) {
    this.invoiceApi.craeteAdvance(this.invoice, data).subscribe(e => {
      console.log(e);
      if (e.message && e.message.includes('OK')) {
        this.presentToast(e.message, 'success')
        this.response = 'OK';
      } else {
        this.response = 'KO';
      }
      this.dismissModal();
    })
  }



  order_orderlegal_update(data) {
    /*  this.orderApi.putDetailsLegalOrder(data, this.invoice).subscribe(e => {
       console.log(e);
       if (e.message && e.message.includes('OK')) {
         this.presentToast(e.message, 'success')
         this.response = 'OK';
       } else {
         this.response = 'KO';
       }
       this.dismissModal();
     }) */
  }

  createLegal(data) {
    /* this.orderApi.createDetailsLegalOrder(data, this.invoice).subscribe(e => {
      console.log(e);
      if (e.message && e.message.includes('OK')) {
        this.presentToast(e.message, 'success')
        this.response = 'OK';
      } else {
        this.response = 'KO';
      }
      this.dismissModal();
    }) */
  }


  dismissModal(event?) {
    this.modalController.dismiss({ message: this.response })

  }


  submitForm() {
    this.createForm.onSubmit(undefined);
  }

  fabCallBack(action) {
    console.log(action);

    if (action) {
      this[action]()
    } else {
      console.log('not call in component, run callback', 'details');


    }
  }


  async presentToast(text, color) {
    const toast = await this.toastController.create({
      message: text,
      position: 'bottom',
      duration: 3000,
      cssClass: 'toast',
      color: color
    });
    return await toast.present();
  }


}
