import { StoresService } from './../../services/stores/stores.service';
import { Store } from './../models/stores.model';
import { map } from 'rxjs/operators';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UploadDesktopComponent } from 'src/app/components/media/upload-desktop/upload-desktop.component';
import { ApiService } from 'src/app/services/api/api.service';
import { VendorsService } from 'src/app/services/vendors/vendors.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-vendor-update',
  templateUrl: './stores-update.page.html',
  styleUrls: ['./stores-update.page.scss'],
})

export class StoresUpdatePage {

  @ViewChild('createForm') createForm: FormGroupDirective;
  /* @ViewChild(UploadDesktopComponent, { static: true }) UploadDesktopComponent: UploadDesktopComponent; */
  @Input() item: Store;
  isDisabled: boolean = true

  

  id: any = '';
  updateStoresForm: FormGroup;
  types: any = [];
  categories: any = [];


  page_number = 1;
  page_limit = 30;



  constructor(
    private route: ActivatedRoute,
    private vendor: VendorsService,
    private fb: FormBuilder,
    private modalController: ModalController,
    private api: StoresService
    
  ) {
    /* this.id = this.route.snapshot.paramMap.get('id') || ''; */
    let url = '?page=' + 0 + '&items=' + 25;

    /*this.api.getStoresTypes(url).subscribe(e => {
      console.log(e)
      this.types = e.results
    }) */     
    
    /*this.api.getStoresCategories(url).subscribe(e => {
      console.log(e)
      this.categories = e.results
    })*/ 
  }


  createBaseForm() {      
    
    // Generete FormGroup Create Vendor 
    this.updateStoresForm = this.fb.group({
      name: this.fb.control(this.item.name, Validators.required),
      code: this.fb.control(this.item.code, Validators.required),
      type: this.fb.control(this.item.type, Validators.required),
      category: this.fb.control(this.item.category, Validators.required),
      description: this.fb.control(this.item.description, Validators.required),
      udm: this.fb.control(this.item.udm, Validators.required),
      default_price: this.fb.control(this.item.default_price, Validators.required),

    });
    console.log(this.updateStoresForm); 
    console.log(this.item);
    

  }


 

  ionViewWillEnter() {
    //this.getStoresById(this.id)
   
    console.log(this.item);
    this.createBaseForm()
    
  }

  getStoresById(id) {
    console.log(this.item);
    /* this.vendor.getVendorById(id).subscribe(e => {
      this.vendorItem = e;

      if (this.UploadDesktopComponent && e.folders.length && e.folders[0].folder.id != undefined) {
        this.UploadDesktopComponent.passItem(e.folders[0].folder.id);
      } else if (this.UploadDesktopComponent) {
        this.UploadDesktopComponent.passItem('');
      }

      this.createBaseForm(e)
      //console.log(this.updateStoresForm)

      if (this.vendorItem.address) {        
        //console.log(this.vendorItem.address);
        this.createAddress(this.vendorItem.address)
        //console.log(this.getAddressGroupByName(this.vendorItem.address));

      }

      if (this.vendorItem.contact) {
        //console.log(this.vendorItem.contact);
        
        //this.updateStoresForm.addControl('contact', this.fb.group({}))
        this.createContact(this.vendorItem.contact)
      }  
    }) */
  }


 /*  getDocuments(id_folder) {
    let url = '?page=' + this.page_number + '&items=' + this.page_limit;
    this.api.getFiles(id_folder, url).subscribe(x => {
      this.page_number++
      return x
    })
  } */




  updateStores(form) {
   
    console.log('Update');
    
    //Call API
    /*
    this.api.updateStores(form,this.item.id).subscribe(e => {
      console.log(e);
    })*/ 

    //console.log(form)
  }

  
/* 
  segmentChanged(ev: any) {
    console.log('Segment changed', ev);
    
  } */

  


  
  onChangeSelectAcc(event) {
    //console.log(event);
  }

  // Call submitForm with click Save
  submitForm() {
    //console.log('onSubmit');
    this.createForm.onSubmit(undefined);
  }

  fabCallBack(action) {
    ////console.log(action);

    if (action) {
      this[action]()
    } else {
      //console.log('not call in component, run callback', 'update');
    }
  }


  dismissModal(event?) {
    this.modalController.dismiss(event)
  }

}


