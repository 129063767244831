import { Product } from './../../products/models/product.model';
import { IonicSelectableComponent } from 'ionic-selectable';
import { Subscription } from 'rxjs';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { ModalController, ToastController } from '@ionic/angular';
import { ProductsService } from 'src/app/services/products/products.service';
import * as moment from 'moment';
import { DdtService } from 'src/app/services/trasport_documents/ddt.service';

@Component({
  selector: 'app-modal-ddt-meta',
  templateUrl: './modal-ddt-meta.component.html',
  styleUrls: ['./modal-ddt-meta.component.scss'],
})
export class ModalDdtMetaComponent {
  @Input() ddt: any = '';
  @Input() type_meta: any = '';
  @Input() type_form: any = '';
  @Input() data: any = '';
  @ViewChild('createForm') createForm: FormGroupDirective;

  createGroupForm: FormGroup;

  products: any = [];
  unit_id: any = [];
  defaultProduct: any;

  response = '';

  /** Selectable Search */
  product: Product;
  productSubscription: Subscription;
  /* */

  constructor(
    private DdtApi: DdtService,
    private modalController: ModalController,
    private fb: FormBuilder,
    private productApi: ProductsService,
    private toastController: ToastController,
  ) { }


  createBaseForm() {
    console.log('createBaseForm')
    // Generete FormGroup Create Linee Ddt 
    this.createGroupForm = this.fb.group({
      product_id: this.fb.control('', Validators.required),
      ddt_id: this.fb.control(this.ddt.id),
      unit_id: this.fb.control(0, Validators.required),
      quantity: this.fb.control('', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.min(1),]),
      description: this.fb.control('', Validators.required),
    });
  }

  updateBaseForm() {
    // Generete FormGroup Create Linee Ddt
    console.log('updateBaseForm')
    this.createGroupForm = this.fb.group({
      product_id: this.fb.control(this.data.product.id, Validators.required),
      ddt_id: this.fb.control(this.ddt.id),
      unit_id: this.fb.control(this.data.units.id, Validators.required),
      quantity: this.fb.control(this.data.quantity, [Validators.required, Validators.pattern("^[0-9]*$"), Validators.min(1),]),
      description: this.fb.control(this.data.description, Validators.required),
    });
    console.log(this.createGroupForm.value)
    this.defaultProduct = { id: this.data.product.id, name: this.data.product.name }; // Replace with the correct values of the default product
  }



  getDdtMeta() {

    let page_product = 1;
    let url = '?page=' + page_product + '&items=' + 25;

    this.productApi.getProductsList(url).subscribe(e => {
      console.log(e);
      this.products = e.results;
      /* if (e.num_pages > 1) {
        for (let i = 2; i <= e.num_pages; i++) {
          console.log(i);
          page_product++
          let url = '?page=' + page_product + '&items=' + 25;
          this.productApi.getProductsList(url).subscribe(e => {
            console.log(e);
            this.products.push(...e.results);

          })
        }
      } */
    })

    this.productApi.shared_units_list(url).subscribe(e =>{
      console.log(e)
      this.unit_id = e.results
      console.log(this.unit_id)
      //console.log(this.data?.product.name)
      //this.createGroupForm.patchValue({product_id: this.data.product.name})
    })
  }

  ionViewWillEnter() {
    console.log(this.data);

    this.response = '';
    this.getDdtMeta();

    if (this.type_form == 'create') {
      if (this.type_meta == 'line') {
        this.createBaseForm();
      }
    } else if (this.type_form == 'update') {
      if (this.type_meta == 'line') {
        this.updateBaseForm();
      }
    }


  }


  updateForm(form, type_meta, type_form) {
    // Map Address   
    /* form.address = this.addressMap(form.address)
    // Add New Contact
    this.addNewContact(form)
    // Add New Address
    this.addNewAddress(form) */

    //Call API
    console.log(form, this.ddt.id, this.type_meta, this.type_form);

    if (this.type_form == 'create') {
      if (this.type_meta == 'line') {
        //form.ddt_id = this.ddt.id
        console.log(form.units_id)
        form.unit_id = Number(form.unit_id)
        this.createLine(form, this.ddt.type, this.ddt.id)
      }
    } else if (this.type_form == 'update') {
      if (this.type_meta == 'line') {
        console.log('update API')
        this.updateLine(form)
      }
    }
  }

  updateLine(data) {
    console.log(data, this.ddt)
    data = {
      ddt_id: data.ddt_id,
      product_id: data.product_id.id,
      quantity: data.quantity,
      units_id: data.units_id,
      description: data.description
    }
     this.DdtApi.mahazin_ddtline_update(data, this.data.id).subscribe(e => {
      console.log(e);
      if (e.message && e.message.includes('OK')) {
        this.presentToast(e.message, 'success')
        this.response = 'OK';
      } else {
        this.response = 'KO';
      }
      this.dismissModal();
    })
  }

  createLine(data, direction, id) {
    console.log(data)
    data = {
      ddt_id: data.ddt_id,
      product_id: data.product_id.id,
      quantity: data.quantity,
      units_id: data.unit_id,
      description: data.description
    }
    //data.product_id = data.product_id.id
    console.log(data)
    

    this.DdtApi.mahazin_ddtlines_create(data, direction, id).subscribe(e => {
      console.log(e);
      if (e.message && e.message.includes('OK')) {
        this.presentToast(e.message, 'success')
        this.response = 'OK';
      } else {
        this.response = 'KO';
      }
      this.dismissModal();
    })
  }



  /* order_orderlegal_update(data) {
    this.DdtApi.putDetailsLegalDdt(data, this.Ddt).subscribe(e => {
      console.log(e);
      if (e.message && e.message.includes('OK')) {
        this.presentToast(e.message, 'success')
        this.response = 'OK';
      } else {
        this.response = 'KO';
      }
      this.dismissModal();
    })
  } */



  dismissModal(event?) {
    this.modalController.dismiss(this.response)

  }


  submitForm() {
    this.createForm.onSubmit(undefined);
  }

  fabCallBack(action) {
    console.log(action);

    if (action) {
      this[action]()
    } else {
      console.log('not call in component, run callback', 'details');


    }
  }


  async presentToast(text, color) {
    const toast = await this.toastController.create({
      message: text,
      position: 'bottom',
      duration: 3000,
      cssClass: 'toast',
      color: color
    });
    return await toast.present();
  }



  /*** Selectable Search  */

  filterPorts(products: Product[], text: string) {
    return products.filter(product => {
      return product.name.toLowerCase().indexOf(text) !== -1; //||
      //port.country.name.toLowerCase().indexOf(text) !== -1 ||
      //port.id.toString().toLowerCase().indexOf(text) !== -1;
    });
  }

  searchPorts(event: {
    component: IonicSelectableComponent,
    text: string
  }) {
    let text = event.text.trim().toLowerCase();
    event.component.startSearch();

    // Close any running subscription.
    if (this.productSubscription) {
      this.productSubscription.unsubscribe();
    }

    if (!text) {
      // Close any running subscription.
      if (this.productSubscription) {
        this.productSubscription.unsubscribe();
      }

      event.component.items = this.products;
      event.component.endSearch();
      return;
    }

    let page_product = 1;
    let url = '?page=' + page_product + '&items=' + 25;
    this.productSubscription = this.productApi.getProductsList(url).subscribe(ports => {
      // Subscription will be closed when unsubscribed manually.
      if (this.productSubscription.closed) {
        return;
      }



      event.component.items = this.filterPorts(ports.results, text);
      event.component.endSearch();
    });
  }

}
