import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IonInput } from '@ionic/angular';

@Component({
  selector: 'app-input-currency',
  templateUrl: './input-currency.component.html',
  styleUrls: ['./input-currency.component.scss'],
})
export class InputCurrencyComponent implements OnInit {


  private static BACKSPACE_KEY = 'Backspace';
  private static BACKSPACE_INPUT_TYPE = 'deleteContentBackward';
  @ViewChild('dummyFacade', { static: false }) private dummyFacade: IonInput;
  @Input() precision: number;
  @Input() newOrder: boolean = false;
  @Input() amount: string;
  @Output() amountEntered = new EventEmitter<any>();
  @Input() disabled: any;
  @Input() payment = false;
  constructor(private currencyPipe: CurrencyPipe) { }

  ngOnInit() {
    console.log('dd');
    
    if (this.amount && this.amount.trim() !== '') {
      this.amountEntered.emit(+this.amount);
    }
  }

  ionViewWillEnter(){
    console.log('enter');
    
    if (this.amount && this.amount.trim() !== '') {
      this.amountEntered.emit(+this.amount);
    }
  }

  handleKeyUp(event: KeyboardEvent) {
    console.log('dasdsa');
    
    // this handles keyboard input for backspace
    if (event.key === InputCurrencyComponent.BACKSPACE_KEY) {
      this.delDigit();
    }
  }

  handleInput(event) {
    this.clearInput();
    // check if digit
    console.log(event);
    if (event.detail.value && !isNaN(event.detail.value)) {
      this.addDigit(event.detail.value);
    } else if (event.detail.inputType === InputCurrencyComponent.BACKSPACE_INPUT_TYPE) {
      // this handles numpad input for delete/backspace
      this.delDigit();
    }
  }

  private addDigit(key: string) {
    this.amount = this.amount + key;
    this.amountEntered.emit(+this.amount);
  }

  private delDigit() {
    console.log(this.amount);
    
    this.amount = this.amount.substring(0, this.amount.length - 1);
    this.amountEntered.emit(+this.amount);
  }

  private clearInput() {
    this.dummyFacade.value = ''; // ensures work for mobile devices
    // ensures work for browser
    this.dummyFacade.getInputElement().then((native: HTMLInputElement) => {
      native.value = '';
    });
  }

  get formattedAmount(): string {
    //console.log(this.formattedAmount)
    return this.currencyPipe.transform(+this.amount / Math.pow(10, this.precision), 'EUR', 'symbol', '1.3-3');
  }

  openInput() {
    console.log('aaa');
    
    this.dummyFacade.setFocus();
  }
}