import { OrdersService } from './../../../services/orders/orders.service';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';

import { HttpEventType, HttpResponse } from '@angular/common/http';
import { from, Observable, of, throwError } from 'rxjs';
import { ApiService } from 'src/app/services/api/api.service';
import { ActionSheetController, AlertController, LoadingController, ModalController, Platform, PopoverController, ToastController } from '@ionic/angular';
import { finalize } from 'rxjs/operators';
import { SubMenuComponent } from '../../sub-menu/sub-menu.component';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { ModalContentComponent } from '../../form/modal-content/modal-content.component';
//import { VendorsService } from 'src/app/services/vendors/vendors.service';
import { DatePipe } from '@angular/common';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';


@Component({
  selector: 'app-upload-desktop',
  templateUrl: './upload-desktop.component.html',
  styleUrls: ['./upload-desktop.component.scss'],
  // providers: [
  //   {
  //     provide: NG_VALUE_ACCESSOR,
  //     useExisting: UploadDesktopComponent,
  //     multi: true
  //   }
  // ]
})
export class UploadDesktopComponent   { //ControlValueAccessor

  // Dichiarazione oggetto FileList collegato direttamente all'input 
  selectedFiles//: FileList;
  // Dichiarazione oggetto relativo la bara avanzamento upload
  progressInfos = [];
  message = '';

  // Parametri intestazione menu es:{'name': 'Aggiorna file','icon': 'refresh','method': 'toggleValue'}
  optionsMenu: any;
  // TRUE or FALSE mostra file in griglia o in lista
  showGrid: any;
  // Copia del selectFiles in Observable
  fileInfos: Observable<any>;
  scrollTop = 0;

  @Input() header: any;          // Parametri intestazione es:{title:''}, se vuoto o non dichiarato nascode l'intestazione
  @Input() colGrid: any;         // Prima griglia FORM UPLOAD: impostare valore colonne del template es:array[2,6,12], ['xl','md','xs']
  @Input() colGridFile: any;     // Seconda griglia LIST FILES: impostare valore colonne del template es:array[2,6,12], ['xl','md','xs']
  @Input() showListFile: any;    // Se true abilita in auto integrazione 
  @Input() showOptionsMenu: any; // abilita e disabilita il tasto di apertura del menu
  @Input() showPresentPromntName: any = true; // abilita e disabilita il presentPromntName

  // @HostListener('change', ['$event.target.files']) emitFiles( event: FileList ) {
  //   const file = event && event.item(0);
  //   this.onChange(file);
  //   this.selectedFiles = file;
  // }

  @Input() files: any;
  @Input() item: any;
  @Input() module: any;
  @Input() isRenew: any;
  @Input() data: any;
  @Input() disabled_expired: any;

  @Input() update: any = false

  @Input() codeFile
  @Input() nameFile

  @Output() emitEventUpload: EventEmitter<any> = new EventEmitter();


  @ViewChild('myInput')
  myInputVariable: ElementRef;

  expires
  page_number = 1;
  page_limit = 30;
  isDisableUpload = true;

  constructor(
    public platform: Platform,
    private cd: ChangeDetectorRef,
    private popoverController: PopoverController,
    //private api: ApiService,
    private orderApi: OrdersService,
    private toastController: ToastController,
    private modalController: ModalController,
    private render: Renderer2,
    private actionSheet: ActionSheetController,
    private datePipe: DatePipe,
    private alertController: AlertController,
  ) {

    // Definisco le colonne vuote ( perciò  col="12" ) nel costruttore in attesa di eventuali binding dal parent
    this.colGrid = [];
    this.colGridFile = [];
    this.expires = ''
    this.data = [];
    this.isRenew = false;
    this.isDisableUpload = true;

    /**
     * Return sb-menu obj
     * @param { name } # Titolo del tasto
     * @param { icon } # Icona del tasto
     * @param { method } # Funzioni di default/custom del tasto collegato ad una funzione esistente
     *  
     */
    this.optionsMenu = [
      {
        'name': 'Aggiorna file',
        'icon': 'refresh',
        'method': 'toggleValue'
      },
      {
        'name': 'View List/Grid',
        'icon': 'swap-horizontal-outline',
        'method': 'toggleView'
      }
    ];
  }
  registerOnTouched(fn: any): void {
    //throw new Error('Method not implemented.');
  }
  setDisabledState?(isDisabled: boolean): void {
    //throw new Error('Method not implemented.');
  }


  updatePage(page) {
    console.log(page);
    //this.page_number = page

  }

  ionViewWillEnter() {
    //if (!this.platform.is('desktop')) return
    //if (!this.item) this.showListFile = false

    this.cd.detectChanges();
    console.log('log');

    console.log(this.item);
    console.log(this.files);

  }

  passItem(item) {
    console.log(item);

    if (item)
      this.item = item

  }

  async presentToast(text, color) {
    const toast = await this.toastController.create({
      message: text,
      position: 'bottom',
      duration: 3000,
      color: color,
      cssClass: 'toast',
    });
    toast.present();
  }

  selectFiles(event): void {
    this.isDisableUpload = true;
    console.log('this.disabled_expired', this.disabled_expired);

    if (!this.disabled_expired) {
      console.log('select files', this.isDisableUpload);

      //if (!this.presentModalExpires()) return
    } else {
      this.isDisableUpload = false;
      //this.presentModalExpires()
      if(this.showPresentPromntName === true)
        this.presentPromntName()
    }
    console.log(event.target.files);

    this.progressInfos = [];
    console.log(this.selectedFiles)
    this.selectedFiles = event?.target?.files;
    console.log(this.selectedFiles)


  }


  progressBar(event, idx, file) {
    console.log(event, idx,file)
    this.progressInfos[idx] = { value: 0, fileName: file.name };
    console.log(event)
    if (HttpEventType.UploadProgress) {
      //if (event.type === HttpEventType.UploadProgress) {
      this.progressInfos[idx].value = Math.round(100 * event.loaded / event.total);
      return false
    } else if (event instanceof HttpResponse) {
      return event
    }
    else
      return ''
  }


  /**
   * Chiamo l'api apuload file aggiungendo altri parametri 
   * @param idx indice
   * @param file fileMedia
   */
  async upload(idx, file?, params?) {


    console.log(file, params, this.item, this.data);

    if (!this.nameFile)
      return

    //console.log(this.item.folders[0].folder.id);
    console.log(file)
    console.log(this.codeFile)
    console.log(this.nameFile)
    console.log(this.module);
    
    var formData = new FormData();
    formData.append('orderfile', file);
    formData.append('code', this.codeFile);
    formData.append('name', this.nameFile);

    formData.forEach((value, key) => {
      console.log(key + ': ' + value);
  });
  
    /* 
    formData.append('parent_id', this.item.folders[0].folder.id)
    formData.append('expires', this.datePipe.transform(this.expires, 'dd-MM-yyyy'));
    */
    /*     const loading = await this.loadingController.create({
          message: 'Uploading image...',
        });
    
        await loading.present(); 
    */


    if (!this.module) {
      this.presentToast('Please pass a module', 'warning');
      return
    }

    {
      // Chiamo l'api update upload 
      console.log(this.module.data.id)
      console.log(formData)
      this.orderApi.order_order_documents_create(this.module.data.id, formData).subscribe({
        next: (res: any) => {
          this.reset()
          console.log('next', res);

          if (!res?.message?.includes('OK')) {
            console.log('errore');

            //return throwError(() => new Error('message'));
          }

          let show = this.showListFile
          if (this.progressBar(event, idx, file)) {

            this.showListFile = !show
            //loading.dismiss();
            this.presentToast(' File upload complete.', 'dark');

            this.cd.detectChanges();
            this.showListFile = show
            this.isDisableUpload = true;


            /*   let url = '?page=1&items=' + this.page_limit;
              this.api.getFiles(this.item.folders[0].folder.id, url).subscribe(e => {
            }); */

            if (this.isRenew) {
              this.dismissModal({ item: this.item, data: this.data });
            }

          }
          
          this.alertController.dismiss(null)

          if (this.update) {
            this.modalController.dismiss({ update: this.update, res: res })
          }

          this.emitEventUpload.emit()
          this.reset()


        },
        error(err) {
          console.log('error', err);
          this.presentToast('File upload failed.', 'danger');
          this.progressInfos[idx].value = 0;
          this.message = 'Could not upload the file:' + file.name;
          //loading.dismiss();
        },
        complete: () => {
          console.log('done')


        },

      })
    }

  }


  /**
   * Ritorna lo split dell'array di file prepararti per il caricamento
   */
  uploadFiles(): void {
    this.message = '';
    console.log('upload-files')
    for (let i = 0; i < this.selectedFiles.length; i++) {
      console.log(i)
      console.log(this.selectedFiles.length)
      console.log(this.selectedFiles)
      console.log(this.selectedFiles[i])
      this.upload(i, this.selectedFiles[i]);
    }

  }

  /**
   * Apre il sub-menu se abilitato passando parametri per le funzioni definite nel "optionsMenu"
   * @param ev event return from hrml on click for presentPopover
   */
  async presentPopover(ev?: any) {

    const checkPropmt = await this.popoverController.getTop();
    if (checkPropmt) {
      this.popoverController.dismiss();
    }

    const popover = await this.popoverController.create({

      side: 'bottom',
      alignment: 'center',
      size: 'auto',
      dismissOnSelect: false,
      backdropDismiss: true,
      //trigger: ev.srcElement.id,
      component: ModalContentComponent,
      cssClass: 'popover_setting',
      //event: ev,
      mode: 'md',
      //trigger: ev.srcElement.id,
      componentProps: {
        //'toggle': this.showGrid,
        item: this.item,
        data: this.optionsMenu
      }


    });

    popover.present();

    // Alla chiusura ricevo i payload
    popover.onDidDismiss().then((e) => {
      //console.log(e.data);
      //console.log(e.data);
      if (e.data) {
        this.expires = e.data.value
        console.log(e.data, this.expires);

        if (this.expires != 0) {
          this.isDisableUpload = false;
        } else {
          this.isDisableUpload = true;
        }

        console.log(this.isDisableUpload);


        /* if (this.expires != 0)
          return this.uploadFiles()

        this.presentToast('Please set expires date', 'warning')
        this.reset() */
        //this.toggleSrc = e.data.toggleSrc
        //this.showGrid = e.data.toggleView;

      }
      console.log('onDidDismiss resolved with role', e);
    });
  }

  reset() {
    console.log(this.myInputVariable);
    this.render.setValue(this.myInputVariable['el'], '')
    console.log(this.myInputVariable);
    this.myInputVariable['el'].value = "";
    this.progressInfos = []

  }


  /**
   * Apre il sub-menu se abilitato passando parametri per le funzioni definite nel "optionsMenu"
   * @param ev event return from hrml on click for presentPopover
   */
  async presentModalExpires(ev?: any) {

    const checkPropmt = await this.modalController.getTop();
    if (checkPropmt) {
      //this.modalController.dismiss();
    }

    const modal = await this.modalController.create({

      component: ModalContentComponent,
      //event: ev,
      mode: 'md',
      canDismiss: true,
      keyboardClose: true,
      breakpoints: [0.2, 0.4, 0.8, 0.9],
      initialBreakpoint: this.platform.is('desktop') ? 0.9 : 0.8,
      //trigger: ev.srcElement.id,
      componentProps: {
        //'toggle': this.showGrid,
        item: this.item,
        data: this.optionsMenu
      }


    });

    modal.present();

    // Alla chiusura ricevo i payload
    modal.onDidDismiss().then((e) => {
      //console.log(e.data);
      if (e.data) {
        this.expires = e.data.value
        console.log(e.data, this.expires);

        if (this.expires != 0) {
          this.isDisableUpload = false;
        } else {
          this.isDisableUpload = true;
        }

        console.log(this.isDisableUpload);


      }
      console.log('onDidDismiss resolved with role', e);
    });
  }/**
   * Apre il sub-menu se abilitato passando parametri per le funzioni definite nel "optionsMenu"
   * @param ev event return from hrml on click for presentPopover
   */

  async presentPromntName(ev?: any) {
    let bool = false
    if(this.item != null){
      bool = true
    }

    const alert = await this.alertController.create({
      cssClass: 'alert-input-modal',
      header: 'Name file',
      //subHeader: 'insert name file',
      //message: '',
      //mode:'ios',
      inputs: [
        {
          placeholder: 'Name (max 100 characters)',
          value: this.item?.name ? this.item?.name : '',
          cssClass: 'input-text-modal',
          attributes: {
            maxlength: 100,
          },
          disabled: bool
        },
      ],
      buttons: [
        /*  {
           text: 'Cancel',
           role: 'cancel',
           cssClass: 'secondary',
           handler: () => {
             console.log('Confirm Cancel');
           }
         },  */
        {
          text: 'Cancel',
          handler: (e) => {
            console.log('Undo');
            //path != 'update' ? this.router.navigateByUrl(path) : this.market.open(environment.appId);
            this.reset()


          }
        },
        {
          text: 'Upload',
          handler: (e) => {
            console.log(e);

            console.log('Confirm Ok', e, e[0]);
            //path != 'update' ? this.router.navigateByUrl(path) : this.market.open(environment.appId);
            this.nameFile = e[0]
            this.codeFile = e[1]
            this.uploadFiles()
          }
        }
      ]
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);


  }



  dismissModal(event?) {
    this.modalController.dismiss(event)
  }

  // writeValue( value: null ) {
  //   // clear file input
  //   this.host.nativeElement.value = '';
  //   //this.selectFiles = null;
  // }

  // registerOnChange( fn: Function ) {
  //   this.onChange = fn;
  // }

  // onChange: Function;
}