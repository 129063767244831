import { NewOrderCategoryComponent } from './../new-category/new-category.component';

import {
  Component,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  ModalController,
  IonInfiniteScroll,
  IonSearchbar,
  IonContent,
  IonSegment,
  IonSegmentButton,
  MenuController,
  PopoverController,
  AlertController,
  ToastController,
  IonModal,
  NavParams,
} from '@ionic/angular';

import {
  animate,
  query,
  sequence,
  stagger,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

import { NewOrderComponent } from '../new-order/new-order.component';
import { OrderDetailsPage } from '../order-details/order-details.page';

import {
  ColumnChangesService,
  ColumnMode,
  DatatableComponent,
} from '@swimlane/ngx-datatable';
import { OrdersService } from 'src/app/services/orders/orders.service';
import { ListOrdersComponent } from '../components/list/list.component';
import { NewOfferComponent } from 'src/app/offers/new-offer/new-offer.component';
import { TitleAppService } from 'src/app/services/utils/title-app/title-app.service';
import { JobsListPage } from 'src/app/jobs/jobs-list/jobs-list.page';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ThemeService } from 'src/app/services/theme/theme.service';
import { VendorsService } from 'src/app/services/vendors/vendors.service';
import { OverlayEventDetail } from '@ionic/core/components';
import { Subscription, filter, map, mergeMap, of } from 'rxjs';
import { JobsService } from 'src/app/services/jobs/jobs.service';
import { FileSaverService } from 'ngx-filesaver';
import { PrintService } from 'src/app/services/utils/print/print.service';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';
import { ListCategoryOrdersComponent } from '../components2/list/list.component';
import { RoutingStateService } from 'src/app/services/routing-state/routing-state.service';
import { CustomersService } from 'src/app/services/customers/customers.service';

interface PageInfo {
  offset: number;
  pageSize: number;
  limit: number;
  count: number;
}
/**
 * An object used to get page information from the server
 */
export class Page {
  // The number of elements in the page
  size: number = 0;
  // The total number of elements
  totalElements: number = 0;
  // The total number of pages
  totalPages: number = 0;
  // The current page number
  pageNumber: number = 0;
}

const listAnimation = trigger('listAnimation', [
  transition('* <=> *', [
    query(
      ':enter',
      [
        style({ opacity: 0 }),
        stagger('180ms', animate('300ms ease-out', style({ opacity: 1 }))),
      ],
      { optional: true }
    ),
    query(':leave', animate('200ms', style({ opacity: 0 })), {
      optional: true,
    }),
  ]),
]);

type NavMenuItem = {
  action: string;
  label: string;
  icon: string;
  code?: string;
  childs?: {
    action: string;
    label: string;
    icon: string;
    class?: string;
    code?: string;
  }[];
  class?: string; // Questo è il nuovo campo per la classe CSS/SCSS
};

@Component({
  selector: 'app-orders',
  templateUrl: './orders-list.page.html',
  styleUrls: ['./orders-list.page.scss'],
  // ✨ New! Defining the animation
  animations: [listAnimation],
  //changeDetection:
})
export class OrdersListPage implements OnInit {
  public data: any; // Data;
  public columns: any;
  public rows: any;
  page = new Page();
  rowIsNotExpanded = true;
  isPopoverOpen = false;
  orderListSearched: any = [];
  category: any;

  editing = {};

  selected = [];

  filterTerm: string;
  enableSearch = false;

  funder = [];
  calculated = [];
  pending = [];
  ColumnMode: ColumnMode;
  expanded: any = {};
  timeout: any;
  total_order = 0;
  currentSegment: string = 'all';
  showSearchVendor = false;
  showSearchCustomer = false;
  showSearchJob = false;
  enableJob = false;
  enableVendor = false;
  enableCustomer = false;
  enableItem = false;
  vendorTable = false;
  customerTable = false;
  jobTable = false;
  userInvLogged = false;
  selectedSegment: string = 'invoice'; // O 'cpr', a seconda di quale lista vuoi mostrare per default

  @ViewChild('segment') segment: IonSegment;
  @ViewChild('btn_segment') btn_segment: IonSegmentButton;
  @ViewChild('myTable') table: DatatableComponent;
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  @ViewChild(IonContent) content: IonContent;
  @ViewChild(IonSearchbar) myInput: IonSearchbar;
  @ViewChild('popover') popover;
  @ViewChild(IonModal) modal: IonModal;

  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    this.setOpen(false); // Chiude il modal
  }

  @ViewChild(ListOrdersComponent) listTable: ListOrdersComponent;
  @ViewChild(ListOrdersComponent) resizeColumns: ListOrdersComponent;
  @ViewChild(ListCategoryOrdersComponent)
  listCategoryTable: ListCategoryOrdersComponent;

  @Input() job: any;
  @Input() reportId;
  @Input() projectId;
  @Input() disableBackButton = false;

  private ctrlPressed = false;
  private mPressed = false;
  // private routerSubscription2: Subscription;
  @HostListener('document:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Control') {
      console.log(event.key);
      this.ctrlPressed = true;
    }
    if (event.key === 'm' || event.key === 'M') {
      console.log(event.key);
      this.mPressed = true;
    }
    if (
      this.ctrlPressed &&
      this.mPressed &&
      (event.key === 'i' || event.key === 'I')
    ) {
      this.presentSearchOrder();
    }
    if (
      this.ctrlPressed &&
      this.mPressed &&
      (event.key === 'y' || event.key === 'Y')
    ) {
      this.showSearchVendor = true;
      this.isModalOrderVendorOpen = true;
    }
  }

  @HostListener('document:keyup', ['$event'])
  onKeyUp(event: KeyboardEvent) {
    if (event.key === 'Control') {
      this.ctrlPressed = false;
    }
    if (event.key === 'm' || event.key === 'M') {
      this.mPressed = false;
    }
  }

  fab: boolean = false;
  customAlertOptions: any = {
    header: 'List groups',
    //subHeader: 'Select group for user',
    message: 'Select group for user',
    translucent: true,
  };
  orders: any = [];
  _orders: any = [];
  searching = false;
  res: any = [];
  order_status = [];
  order_types = [];
  order_categories = [];

  total_page = 0;
  page_number = 1;
  page_limit = 30;

  isSearch: boolean = false;
  isFocus: boolean = false;
  isLoad: boolean = false;

  queryString = {
    search_by: '',
    search_data: '',
  };

  totalElements: number;
  pageNumber: number;
  cache: any = {};
  vendorSubscription: Subscription;
  customerSubscription: Subscription;
  jobSubscription: Subscription;
  companies: any = [];
  jobs: any = [];
  title: any;
  page_vendors = 1;
  numPage_vendors = 0;
  page_customers = 1;
  numPage_customers = 0;
  page_jobs = 1;
  numPage_jobs = 0;
  @Input() ddt_in: any; // per un ipotetico inject se necessita di ereditare delle scelte
  size = 20;
  onSearchSelector = '';
  onSelectVendor;
  onSelectCustomer;
  onPushVendors;
  onPushCustomers;
  onSelectJob;
  onPushJobs;

  loaded1;
  loaded2;
  vendorId;
  customerId;
  vendorName;
  customerName;
  jobId: any = [];
  jobName;

  isLoading = 0;
  testDisabled = false;
  // utentiAbilitati:any[]= ['test@coolprojects.it']
  utentiAbilitatiRDA: any[] = [
    'roberta.bucci@coolprojects.it',
    'vita.decarlo@coolprojects.it',
  ];
  utentiAbilitatiInv: any[] = ['federica.moretti@coolprojects.it'];
  utentiAbilitatiCPR: any[] = ['salvatore.scialdone@coolprojects.it'];
  utentiAbilitatiAck: any[] = [
    'maurizio.lamotta@coolprojects.it',
    'marco.tuscano@coolprojects.it',
    'massimo.tarquini@coolprojects.it',
  ];

  navMenu: NavMenuItem[] = [
    {
      action: 'presentPopover',
      label: 'New',
      icon: 'add-outline',
      code: 'new',
      childs: [
        {
          action: 'openNewOrderModal',
          label: 'New Order',
          icon: 'receipt-outline',
          code: 'new-order',
        },
        {
          action: 'openNewOfferModal',
          label: 'New Offer',
          icon: 'receipt-outline',
          code: 'offer',
        },
      ],
      class: 'custom-chip',
    },

    {
      action: 'toggleSearch',
      label: 'Search',
      icon: 'search-outline',
      code: 'search',
    },
    //{ action: '', label: 'Export', icon: 'download-outline' },
    {
      action: 'openMenu',
      label: 'Filter',
      icon: 'menu-outline',
      code: 'filter',
    },
  ];

  media_query;

  selectedFilter = 'name';
  filterItems: any = [
    /*  {
       search_by: 'name', //name //
       search_data: ''
     }, */
    {
      search_by: 'code',
      search_data: '',
    },
    {
      search_by: 'status',
      search_data: '',
    } /* 
    {
      search_by: 'vendor',
      search_data: ''
    }, */,
  ];
  utentiRoles: any;
  activeFilterOrders; // = false
  stringFilterOrders = '';
  stringStatusOrderFilter = '';
  activeFilterStatusOrder;

  cards = [...new Array(10)];
  emailPermission: any;
  viewListOrder = false;
  viewListOrderVendor = false;
  viewListOrderCustomer = false;
  viewListJob = false;
  viewListInvoice = false;
  viewListCPR = false;
  viewListAck = false;
  reportParam = false;
  viewListReport = false;
  filterParam: boolean = false;
  OrderDetailsPage: OrderDetailsPage;
  order_orders_createForm: FormGroup;
  response: string;
  public showProgressStatus = false;
  isDark = true;
  isModalOrderVendorOpen = false;
  isModalOrderCustomerOpen = false;
  isModalJobOpen = false;
  enableMenu = false;

  constructor(
    private menu: MenuController,
    private api: OrdersService,
    private modalController: ModalController,
    private alertController: AlertController,
    private columnChangesService: ColumnChangesService,
    private titleAppService: TitleAppService,
    private popoverController: PopoverController,
    private auth: AuthenticationService,
    private fb: FormBuilder,
    private toastController: ToastController,
    private themeDetection: ThemeService,
    private vendorApi: VendorsService,
    private jobApi: JobsService,
    private _FileSaverService: FileSaverService,
    private apiPrint: PrintService,
    private apiCustomer: CustomersService,
    private router: Router,
    private routingState: RoutingStateService
  ) {
    this.utentiRoles = ['RDA', 'Inv', 'CPR', 'Ack'];
    console.log(this.utentiRoles);

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        // Access the navigation state
        const currentNavigation = this.router.getCurrentNavigation();
        const state = currentNavigation?.extras.state;
        console.log('Navigation state:', state);
        if (state['job']) {
          console.log(state);
          console.log(state['job']);
          this.job = true;
          this.jobId = state['job'];
          // this.projectId = state['job'].id
        } else {
          this.job = false;
          this.projectId = false;
        }
      });

    this.navMenu = [];
    this.router.events
      .pipe(
        filter(
          (event) =>
            event instanceof NavigationStart || event instanceof NavigationEnd
        )
      )
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          // Codice specifico per NavigationEnd
          this.viewListOrder = false;
          const currentNavigation = this.router.getCurrentNavigation();
          const state = currentNavigation?.extras.state;
          console.log('Navigation state:', state);
          if (state && state['job']) {
            console.log(state);
            console.log(state['job']);
            this.job = true;
            this.jobId = state['job'];
            this.api.setJobs(this.job);
            // this.projectId = state['job'].id
          } else {
            this.job = false;
            this.projectId = false;
          }
        } else if (event instanceof NavigationStart) {
          // Codice specifico per NavigationStart
          console.log('Starting navigation to', event.url);
          this.viewListOrder = true;
          this.jobId = false;
          // Qui puoi fare operazioni specifiche quando la navigazione inizia
        }
      });
  }

  handleRoles(e) {
    console.log('ionChange fired with value: ' + e.detail.value);
    let selectedRole = e.detail.value.toString();
    console.log(selectedRole);
    switch (selectedRole) {
      case 'RDA':
        this.resetRoles();
        this.utentiAbilitatiRDA.push(this.emailPermission);
        break;
      case 'Inv':
        this.resetRoles();
        this.utentiAbilitatiInv.push(this.emailPermission);
        break;
      case 'CPR':
        this.resetRoles();
        this.utentiAbilitatiCPR.push(this.emailPermission);
        break;
      case 'Ack':
        this.resetRoles();
        this.utentiAbilitatiAck.push(this.emailPermission);
        break;
      default:
        console.error('Unknown role:', selectedRole);
        return;
    }
    this.ionViewWillEnter();
  }
  resetRoles() {
    this.utentiAbilitatiRDA = [];
    this.utentiAbilitatiInv = [];
    this.utentiAbilitatiCPR = [];
    this.utentiAbilitatiAck = [];
    this.utentiAbilitatiRDA = [
      'roberta.bucci@coolprojects.it',
      'vita.decarlo@coolprojects.it',
    ];
    this.utentiAbilitatiInv = ['federica.moretti@coolprojects.it'];
    this.utentiAbilitatiCPR = ['salvatore.scialdone@coolprojects.it'];
    this.utentiAbilitatiAck = [
      'maurizio.lamotta@coolprojects.it',
      'marco.tuscano@coolprojects.it',
      'massimo.tarquini@coolprojects.it',
    ];
  }

  ngOnInit(): void {
    console.log(this.reportParam);

    if (this.reportParam) this.viewListReport = true;

    this.api.getItem().subscribe((e) => {
      console.log(this.enableItem);
      this.enableItem = e;
      console.log('AAAAAAAAAAAAAAA');
      console.log('vendorTable', this.vendorTable);
      console.log('vendorTable', this.customerTable);
      console.log('jobTable', this.jobTable);
      console.log('vendorTable', this.vendorTable);
    });

    this.api.getMenu().subscribe((e) => {
      console.log(e);
      console.log(this.job);

      if (e && this.job) {
        this.navMenu = [
          {
            action: 'openNewOrderModal',
            label: 'New',
            icon: 'receipt-outline',
            code: 'new-order',
            /* action: 'presentPopover', label: 'New', icon: 'add-outline', childs: [
              
              //{ action: 'openNewOfferModal', label: 'New Offer', icon: 'receipt-outline' }
            ] */
          },
          // { action: 'toggleSearch', label: 'Search', icon: 'search-outline' },
          // { action: 'filterByCode', label: 'Filtra per stato', icon: 'information-outline'},
          {
            action: 'refershEvent',
            label: 'Reload',
            icon: 'refresh-outline',
            code: 'refresh',
          },
          {
            action: 'hideClosed',
            label: 'Hide Closed Orders',
            icon: 'contract-outline',
            code: 'hide'

          },
          {
            action: 'exportToCsv',
            label: 'Export orders',
            icon: 'download-outline',
            code: 'export'
          },
          {
            action: 'letInfoJob',
            label: 'Info',
            icon: 'information-outline',
            code: 'info',
          },
          // { action: 'letInfoOrder', label: 'Info utili', icon: 'help-outline'},
          //{ action: '', label: 'Export', icon: 'download-outline' },
          //{ action: 'openMenu', label: 'Filter', icon: 'menu-outline' },
        ];
      }
    });
  }

  setOpen(isOpen: boolean) {
    this.isModalOrderVendorOpen = isOpen;
    this.isModalOrderCustomerOpen = isOpen;
    this.isModalJobOpen = isOpen;
    console.log(isOpen);
    this.api.setItem(false);
    this.viewListOrderVendor = false;
    this.viewListOrderCustomer = false;
    this.viewListJob = false;
    if (isOpen === false) {
      this.vendorTable = false;
      this.customerTable = false;
      this.jobTable = false;
      this.job = false;
      this.jobId = false;
      setTimeout(() => {
        this.showSearchVendor = false;
        this.enableVendor = false;
        this.showSearchCustomer = false;
        this.enableCustomer = false;
        this.showSearchJob = false;
        this.enableJob = false;
        this.api.setItem(false);
      }, 100);
    }
  }

  openMenu() {
    console.log(this.menu);

    this.menu.get('end').then((e) => {
      console.log(e);
    });
    this.menu.get('start').then((e) => {
      console.log(e);
    });

    setTimeout(() => {
      //this.menu.enable(true, 'start');
      this.menu.toggle('end');
      console.log('setTimeout');
    }, 500);
  }

  createColumns() {
    this.columns = [
      //{ prop: 'id', name: 'ID'/* , frozenLeft: true */, minWidth: 75, maxWidth: 75, cellClass: 'td-id', headerClass: 'th-id' },
      /* {
        prop: 'id', width: 75, minWidth: 75
      }, */
      {
        prop: 'priority.name',
        name: 'Priority',
        width: 90,
        minWidth: 90,
        cellClass: 'td-priority',
        headerClass: 'th-priority',
      },
      {
        prop: 'code',
        width: 255,
        minWidth: 255,
        maxWidth: 255,
      },
      { prop: 'category.name', name: 'Category', maxWidth: 200 },
      { prop: 'type.name', name: 'Type', maxWidth: 180 },

      //{ prop: 'creator.email', name: 'Email', width: 190, minWidth: 190, maxWidth: 230 },
      { prop: 'project.name', name: 'Project', maxWidth: 240 },
      { prop: 'customer.name', name: 'Customer', maxWidth: 200 },
      { prop: 'vendor.name', name: 'Vendor', maxWidth: 200 },
      //{ prop: 'deleted', maxWidth: 70, cellClass: 'td-deleted', headerClass: 'th-deleted' },

      {
        prop: 'cost',
        minWidth: 155,
        maxWidth: 155 /*  summaryFunc: () => this.caclulateSumm('€'), */,
      },
    ];
  }

  goBack() {
    if (this.job) {
      this.router.navigate(['/' + this.routingState.navigateBack()], {
        state: { category: this.category },
      });
    } else {
      this.router.navigate(['/' + this.routingState.navigateBack()]);
    }
    this.cancelAllRequests();
  }

  cancelAllRequests() {
    this.api.cancelRequests();
  }
  // stato
  // currentNavigation
  copyText = false;
  copyToClipboard(text: string): void {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log('Testo copiato negli appunti:', text);
        // Mostra un messaggio all'utente, se necessario
        this.copyText = true;
        setTimeout(() => {
          this.copyText = false;
        }, 1000);
      })
      .catch((err) => {
        console.error('Errore durante la copia negli appunti:', err);
      });
  }

  ionViewWillEnter() {
    console.log(this.jobId);

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        // Access the navigation state
        const currentNavigation = this.router.getCurrentNavigation();
        const state = currentNavigation?.extras.state;
        console.log('Navigation state:', state);
        if (state && state['job']) {
          console.log(state);
          console.log(state['job']);
          this.job = true;
          this.jobId = state['job'];
          console.log(this.jobId);
          // this.projectId = state['job'].id
        } else {
          this.job = false;
          this.projectId = false;
        }
      });

    // const currentState = this.router.getCurrentNavigation()?.extras.state as {job: any, projectId:any};
    // console.log(currentState);

    this.enableItem = false;
    console.log(this.enableItem);
    console.log(this.reportId);

    let url = '?page=1&items=25';
    console.log(this.isDark);
    this.vendorApi.getCompaniesList().subscribe((e) => {
      console.log(e);
      this.companies = e;
    });
    this.jobApi.getJobsList(url).subscribe((e) => {
      console.log(e);
      this.jobs = e;
    });
    this.themeDetection.dark.subscribe((e) => {
      console.log(e);

      if (e === undefined) {
        this.isDark = true;
      } else {
        this.isDark = e;
      }
    });

    this.auth
      .getLocalData('auth-profile')
      .then((e) => {
        console.log("this.auth.getLocalData('auth-profile')", e);
        this.emailPermission = e.user_data.email;
        console.log(this.emailPermission);
        if (this.emailPermission === 'test1@coolprojects.it') {
          this.viewListOrder = true;
          this.viewListInvoice = true;
          this.viewListAck = true;
          this.viewListCPR = true;
          if (this.job) {
            this.viewListOrder = false;
            this.viewListInvoice = false;
            this.viewListAck = false;
            this.viewListCPR = false;
          }
        }
        if (this.utentiAbilitatiRDA.includes(this.emailPermission)) {
          console.log('utente abilitato');
          this.viewListOrder = true;
          if (this.job) this.viewListOrder = false;
        } else if (this.utentiAbilitatiInv.includes(this.emailPermission)) {
          console.log('utente abilitato');
          this.userInvLogged = true;
          this.viewListInvoice = true;
          this.viewListCPR = true;
          // console.log('order',this.viewListOrder);
          // console.log('cpr',this.viewListCPR);
          // console.log('inv',this.viewListInvoice);
          // console.log('ack',this.viewListAck);
          // console.log('job',this.viewListJob);
          // console.log('report',this.viewListReport);
          // console.log('order vendor',this.viewListOrderVendor);

          if (this.viewListOrder) {
            this.userInvLogged = false;
          }
          if (this.job) {
            this.viewListInvoice = false;
            this.viewListCPR = false;
          }
        } else if (this.utentiAbilitatiCPR.includes(this.emailPermission)) {
          console.log('utente abilitato');
          this.viewListCPR = true;
          this.selectedSegment = 'cpr';
          // console.log('order',this.viewListOrder);
          // console.log('cpr',this.viewListCPR);
          // console.log('inv',this.viewListInvoice);
          // console.log('ack',this.viewListAck);
          // console.log('job',this.viewListJob);
          // console.log('report',this.viewListReport);
          // console.log('order vendor',this.viewListOrderVendor);

          if (this.job) {
            this.viewListCPR = false;
            this.viewListInvoice = false;
          }
        } else if (this.utentiAbilitatiAck.includes(this.emailPermission)) {
          console.log('utente abilitato');
          this.viewListAck = true;
          if (this.job) this.viewListAck = false;
        } else {
          console.log('utente non abilitato');
          this.viewListOrder = false;
          this.viewListInvoice = false;
          this.viewListAck = false;
          this.viewListCPR = false;
        }
      })
      .catch((err) => {
        console.log(err);
      });

    this.myInput ? this.myInput.value : null;
    if (!this.job) {
      this.titleAppService.title.next('Cost Center');

      this.navMenu = [
        // { action: 'openNewOrderCategoryModal', label: 'New Cost Center', icon: 'receipt-outline' },
        // { action: 'openNewOrderFromCostModal', label: 'New Order', icon: 'receipt-outline'},
        {
          action: 'presentSearchOrder',
          label: 'Search by code',
          icon: 'search-outline',
          code: 'code',
        },
        {
          action: 'presentSearchODA',
          label: 'Search by ODA',
          icon: 'search-outline',
          code: 'oda',
        },
        {
          action: 'enableSearchOrderVendor',
          label: 'Search by vendor',
          icon: 'search-outline',
          code: 'vendor',
        },
        {
          action: 'enableSearchOrderCustomer',
          label: 'Search by customer',
          icon: 'search-outline',
          code: 'customer',
        },
        {
          action: 'searchByJob',
          label: 'Search by job',
          icon: 'search-outline',
          code: 'job',
        },
        //{ action: 'goToOrder', label: 'Order Number', icon: 'receipt-outline'}
        //{ action: 'openNewOrderCategoryModal', label: 'All RDA', icon: 'receipt-outline' }
      ];
    } else {
      console.log(this.job);
      console.log(this.orders);
      this.titleAppService.title.next('Orders [' + this.job.code_slug + ']');
      this.navMenu = [
        {
          action: 'openNewOrderModal',
          label: 'New',
          icon: 'receipt-outline',
          code: 'new-order'
          /* action: 'presentPopover', label: 'New', icon: 'add-outline', childs: [
            
            //{ action: 'openNewOfferModal', label: 'New Offer', icon: 'receipt-outline' }
          ] */
        },
        // { action: 'toggleSearch', label: 'Search', icon: 'search-outline' },
        // { action: 'refershEvent', label: 'Reload', icon: 'refresh-outline' },
        // { action: 'hideClosed', label: 'Hide Order Closed', icon: 'contract-outline'},
        // { action: 'exportToCsv', label: 'Esporta ordini', icon: 'download-outline'},
        // { action: 'letInfoJob', label: 'Informazioni generali', icon: 'information-outline'},
        //{ action: '', label: 'Export', icon: 'download-outline' },
        //{ action: 'openMenu', label: 'Filter', icon: 'menu-outline' },
      ];
    }

    this.cache = {};
    //this.orders = [];
    this.fab = true;
    this.isPopoverOpen = false;

    //this.loadProjects(this.queryString)

    this.callFilterItem();
  }

  enableSearchOrderVendor() {
    this.showSearchVendor = true;
    this.isModalOrderVendorOpen = true;
  }

  enableSearchOrderCustomer() {
    this.showSearchCustomer = true;
    this.isModalOrderCustomerOpen = true;
  }

  searchByJob() {
    this.showSearchJob = true;
    this.isModalJobOpen = true;
  }

  ionViewWillLeave() {
    console.log('will leave');
    localStorage.setItem('filter_orders', null);
    localStorage.setItem('filter_sort_orders', null);

    // this.routerSubscription.unsubscribe()
    // if (this.listTable) {
    //   this.listTable.ngOnDestroy();
    // }
  }

  /**
   * @description Get order metadata
   */
  getOrdersMeta() {
    /* this.api.getOrderTypes()
      .subscribe(data => {
        //console.log(data);
        this.order_types = data
      }) */
    /* this.api.order_categories_list()
      .subscribe(data => {
        //console.log(data);
        this.order_categories = data
      }) */
    /* this.api.getOrderStatus()
      .subscribe(data => {
        //console.log(data);
        this.order_status = data
        this.order_status.push(
          {
            id: 'all',
            name: 'All'
          },

        )
      }) */
  }

  returnOrders($event) {
    console.log('returnOrders', $event);
    this.orders = $event;
    console.log(this.orders);
    // if(this.viewListOrder){
    //   this.orders = $event.filter(item => item.status.code === 'DRF');
    // }
  }

  triggerColumnChangeDetection(): void {
    this.columnChangesService.onInputChange();
  }

  /**
   * @description Richiama nomi funzioni passate dalla vista (si può fare anche tutto da .ts)
   * @param action
   */
  fabCallBack(action) {
    console.log;

    if (action) {
      this[action]();
    } else {
      console.log('not call in component, run callback', 'list');
    }
  }

  methodAction(action) {
    console.log(action.action);

    try {
      this[action.action]();
    } catch {
      console.log('not call in parent component');
    }
    //console.log(action)
  }

  refershEvent(event?: any) {
    console.log(this.jobId);
    console.log(event);

    this.listTable?.setPage({ offset: 0 }, this.filterParam, this.jobId, event);
  }
  tableExpanded = false;
  resizeTable(event?: any) {
    this.tableExpanded = !this.tableExpanded;

    this.listTable?.setColumns(this.tableExpanded);
  }

  hideClosed() {
    console.log(this.filterParam);
    this.filterParam = !this.filterParam;
    // this.listTable?.setPage({ offset: 0 }, this.filterParam)
  }

  filterTermEvent(event) {
    this.filterTerm = event;
    this.isFocus = false;
    console.log(event);

    console.log('search', this.selectedFilter, event, this.filterTerm);

    try {
      event = JSON.parse(event);

      //this.queryString = event

      //this.listTable.setPage({ offset: 0 })

      this.updateFilterItem(event.type, event.data);
    } catch {
      //this.loadProjects(this.queryString);
      //this.getOrders(false, event, this.queryString);

      this.updateFilterItem(event.type, event.data);
    }
    //this.listTable.setPage({ offset: 0 })

    setTimeout(() => {
      this.isFocus = true;
    }, 1800);
  }

  typeFilterTerm(event: any) {
    console.log(event);
    this.selectedFilter = event;
    //this.updateFilterItem(event.type, event.value)
  }

  toggleExpandRow(row) {
    console.log('Toggled Expand Row!', row);
    this.table.rowDetail.collapseAllRows();
    this.table.rowDetail.toggleExpandRow(row);
  }

  sorted(columnProp: string, direction: string) {
    console.log(columnProp, direction);
    localStorage.setItem(
      'filter_sort_orders',
      JSON.stringify({
        search_by: 'sort',
        search_data: [{ prop: columnProp, dir: direction }],
      })
    );
  }

  onSelect({ selected }) {
    console.log('Select Event', selected, this.selected);
  }

  onSelectVendorComp(selected) {
    this.enableJob = true;
    this.enableItem = false;
    if (this.enableVendor === true) this.enableItem = true;
    console.log('Select Event', selected);
    console.log(selected.item);
    this.vendorId = selected.item.id;
    this.vendorName = selected.item.name;
    this.viewListOrderVendor = true;
    this.vendorTable = this.viewListOrderVendor;
    if (this.jobTable) {
      console.log('sia job che vendor');
      console.log(this.jobTable, this.viewListOrderVendor);
      this.viewListJob = true;
    } else {
      console.log('solo vendor');
    }
  }

  onSelectCustomerComp(selected) {
    this.enableJob = true;
    this.enableItem = false;
    if (this.enableCustomer === true) this.enableItem = true;
    console.log('Select Event', selected);
    console.log(selected.item);
    this.customerId = selected.item.id;
    this.customerName = selected.item.name;
    this.viewListOrderCustomer = true;
    this.customerTable = this.viewListOrderCustomer;
    if (this.jobTable) {
      console.log('sia job che customer');
      console.log(this.jobTable, this.viewListOrderCustomer);
      this.viewListJob = true;
    } else {
      console.log('solo customer');
    }
  }

  nameChildren: any = [];
  jobsChildren: any = [];
  onSelectJobComp(selected) {
    this.jobId = selected.item;
    this.enableVendor = true;
    this.enableItem = false;
    if (this.enableJob === true) this.enableItem = true;
    console.log('Select Event', selected);
    console.log(selected.item);
    this.jobId = selected.item;
    if (this.jobId.children.length !== 0) {
      this.jobsChildren = this.jobId.children;
      this.jobsChildren.forEach((e) => {
        console.log(e);

        this.nameChildren.push(e.name);
      });
    } else {
      this.nameChildren = [];
    }
    // this.jobName = selected.item.name
    this.viewListJob = true;
    this.jobTable = this.viewListJob;
    if (this.vendorTable) {
      console.log('sia vendor che job');
      console.log(this.vendorTable, this.viewListJob);
      this.viewListOrderVendor = true;
    } else if (this.customerTable) {
      console.log('sia customer che job');
      console.log(this.vendorTable, this.viewListJob);
      this.viewListOrderCustomer = true;
    } else {
      console.log('solo job');
    }
  }
  /**
   * @description onClick on row open order details
   * @param event pass obj row clicked
   */
  onActivate(event, type?: any) {
    /* if (this.table.rowDetail && event.type == 'click') {
      this.table.rowDetail.toggleExpandRow(event.row);
      console.log('Activate Event', event);
    } */
    console.log(event);

    if (
      event.type == 'click' &&
      event.cellIndex !== 0 &&
      event.cellIndex !== 1 &&
      event.cellIndex !== 2 &&
      event.cellIndex !== 12
    ) {
      console.log('Activate Event', event);
      this.openOrderModal(event.row);
    } else if (event.cellIndex == 0) {
      console.log('download', event.row);
      this.downloadFile(event.row);
    } else if (event.cellIndex == 1) {
      console.log('download', event.row);
      this.printOrder(event.row);
    } else if (event.cellIndex == 12) {
      console.log('copia', event.row);
      this.presentCopyAlert(event.row);
    } else if (event.cellIndex == 11) {
      console.log('elimina', event.row);
      this.presentDeleteAlert(event.row);
    } else if (event.cellIndex == 2 && this.viewListAck) {
      console.log('approva', event.row);

      this.presentApproveAlert(event.row);
    } else {
      console.log('onactivate error');
      this.openOrderModal(event.row);
    }
  }

  async presentDeleteAlert(event) {
    if (event.status.name == 'Chiuso') {
      const alert = await this.alertController.create({
        header: 'Attenzione',
        message: 'Non puoi eliminare un ordine chiuso',
        buttons: [
          {
            text: 'Ok',
          },
        ],
      });
      await alert.present();
    } else {
      console.log(event.id);
      const alert = await this.alertController.create({
        header: 'Conferma',
        message: "Vuoi eliminare l'ordine?",
        buttons: [
          {
            text: 'Annulla',
            role: 'cancel',
            handler: () => {
              console.log('Eliminazione annullata.');
            },
          },
          {
            text: 'Elimina',
            handler: () => {
              this.api.deleteOrderById(event.id).subscribe(async (e) => {
                console.log(e);
              });
              console.log('Ordine eliminato.');
            },
          },
        ],
      });

      await alert.present();
    }
  }

  async presentSearchOrder() {
    if (this.orderListSearched.length !== 0) {
      this.presentAlertWithOptions(this.orderListSearched);
    } else {
      const alert = await this.alertController.create({
        header: 'Enter your order',
        buttons: [
          {
            text: 'Annulla',
            role: 'cancel',
            handler: () => {
              console.log('annullo ricerca ordine');
            },
          },
          {
            text: 'Cerca',
            handler: (data) => {
              console.log(data);
              this.searchOrder(data.orderNumber);
            },
          },
        ],
        inputs: [
          {
            name: 'orderNumber',
            type: 'number',
            placeholder: 'Order Number',
          },
        ],
      });
      await alert.present();
      const firstInput: any = document.querySelector('ion-alert input');
      if (firstInput) {
        setTimeout(() => firstInput.focus(), 300);
      }

      firstInput.addEventListener('keyup', (event) => {
        if (event.key === 'Enter') {
          this.searchOrder(firstInput.value);
          alert.dismiss();
        }
      });
    }
  }

  async presentSearchODA() {
    if (this.orderListSearched.length !== 0) {
      this.presentAlertWithOptions(this.orderListSearched);
    } else {
      const alert = await this.alertController.create({
        header: 'Enter ODA number',
        buttons: [
          {
            text: 'Annulla',
            role: 'cancel',
            handler: () => {
              console.log('annullo ricerca ordine');
            },
          },
          {
            text: 'Cerca',
            handler: (data) => {
              console.log(data);
              this.searchODA(data.orderNumber);
            },
          },
        ],
        inputs: [
          {
            name: 'orderNumber',
            type: 'number',
            placeholder: 'ODA Number',
          },
        ],
      });
      await alert.present();
      const firstInput: any = document.querySelector('ion-alert input');
      if (firstInput) {
        setTimeout(() => firstInput.focus(), 300);
      }

      firstInput.addEventListener('keyup', (event) => {
        if (event.key === 'Enter') {
          this.searchODA(firstInput.value);
          alert.dismiss();
        }
      });
    }
  }
  searchOrder(orderNumber: string) {
    console.log(orderNumber);
    const orderSearch = '?page=1&code=' + orderNumber;
    this.api.order_orders_list(orderSearch).subscribe((data) => {
      console.log(data);
      let order = data.results.filter(
        (e) =>
          (e.code === orderNumber || e.code === '0' + orderNumber) &&
          e.status.code !== 'CLO'
      );
      console.log(order);
      if (order.length > 1) {
        console.log(order);
        this.orderListSearched = order;
        this.alertController.dismiss();
        this.presentAlertWithOptions(order);
      } else if (order.length === 1) {
        console.log(order);
        this.openOrderModal(order[0]);
        // this.alertController.dismiss();
      } else {
        return;
      }
    });
    console.log('Ordine approvato.');
  }

  searchODA(ODAnumber: string) {
    console.log(ODAnumber);
    this.api.getOrderByODA().subscribe((data) => {
      console.log(data.results);
      let test = data.results.filter((e) => e.content === ODAnumber);
      console.log(test);
      if (test.length !== 0 && test.length === 1) {
        let url = '?id=' + test[0].order;
        this.api.order_orders_list(url).subscribe((e) => {
          this.openOrderModal(e.results[0]);
        });
      } else if (test.length >= 1) {
        this.presentAlertWithOptionsODA(test);
      }
    });
    console.log('Ordine approvato.');
  }

  clearValues: any;
  cancelButton: any;
  async presentAlertWithOptions(orders) {
    const buttons = orders.map((option) => ({
      text: option.job.name_slug + '\n' + ' - ' + option.vendor.name,
      handler: () => {
        this.openOrderModal(option);
        // Non è necessario chiamare this.alertController.dismiss() qui,
        // poiché cliccando su un pulsante, l'alert verrà chiuso automaticamente.
      },
    }));

    // Aggiungi questo pulsante per gestire l'azione di annullamento
    this.clearValues = {
      cssClass: 'alert-buttons2',
      text: 'Pulisci campi',
      role: 'clear',
      handler: () => {
        this.orderListSearched = [];
        this.presentSearchOrder();
      },
    };
    this.cancelButton = {
      cssClass: 'alert-buttons',
      text: 'Chiudi',
      role: 'cancel',
      handler: () => {
        this.alertController.dismiss();
      },
    };

    const alert = await this.alertController.create({
      header: 'Ci sono più ordini con lo stesso codice',
      subHeader: "Seleziona un'opzione",
      cssClass: 'alert-class alert-header1 alert-header2',
      buttons: [...buttons, this.clearValues, this.cancelButton], // Aggiungi qui il pulsante di annullamento
    });

    await alert.present();
  }

  async presentAlertWithOptionsODA(orders) {
    const buttons = orders.map((option) => ({
      text: option.title + '\n' + ' - ' + option.content,
      handler: () => {
        console.log(option);

        let url = '?id=' + option.order;
        this.api.order_orders_list(url).subscribe((e) => {
          this.openOrderModal(e.results[0]);
        });
        // this.openOrderModal(option.order);
        // Non è necessario chiamare this.alertController.dismiss() qui,
        // poiché cliccando su un pulsante, l'alert verrà chiuso automaticamente.
      },
    }));

    // Aggiungi questo pulsante per gestire l'azione di annullamento
    this.clearValues = {
      cssClass: 'alert-buttons2',
      text: 'Pulisci campi',
      role: 'clear',
      handler: () => {
        this.orderListSearched = [];
        this.presentSearchOrder();
      },
    };
    this.cancelButton = {
      cssClass: 'alert-buttons',
      text: 'Chiudi',
      role: 'cancel',
      handler: () => {
        this.alertController.dismiss();
      },
    };

    const alert = await this.alertController.create({
      header: 'Ci sono più ODA con lo stesso codice',
      subHeader: "Seleziona un'opzione",
      cssClass: 'alert-class alert-header1 alert-header2',
      buttons: [...buttons, this.clearValues, this.cancelButton], // Aggiungi qui il pulsante di annullamento
    });

    await alert.present();
  }

  async presentCopyAlert(event) {
    const alert = await this.alertController.create({
      header: 'Conferma',
      message: "Vuoi copiare l'ordine numero: " + event.code + '?',
      buttons: [
        {
          text: 'Annulla',
          role: 'cancel',
          handler: () => {
            console.log('Copia annullata.');
          },
        },
        {
          text: 'Copia',
          handler: () => {
            this.api.order_orders_copy(event.id).subscribe((e: any) => {
              console.log(e);
              this.refershEvent();
            });
            console.log('Ordine copiato.');
          },
        },
      ],
    });

    await alert.present();
  }

  async presentApproveAlert(event) {
    let order = event;
    console.log(order);
    this.createFormApprove(order);
    console.log(this.order_orders_createForm);
    const alert = await this.alertController.create({
      header: 'Conferma',
      message: "Vuoi approvare quest'ordine?",
      buttons: [
        {
          text: 'Annulla',
          role: 'cancel',
          handler: () => {
            console.log('approvazione annullata.');
          },
        },
        {
          text: 'Approva',
          handler: () => {
            console.log(event);
            console.log(event.id);
            this.updateStatus('next', order.status, order);
            console.log('Ordine approvato.');
          },
        },
      ],
    });

    await alert.present();
  }

  async filterByCode() {
    // Effettua la chiamata API per ottenere le sequenze d'ordine
    console.log('asdasdasd');

    this.api
      .order_sequences_list('?page=1&items=500')
      .subscribe(async (data) => {
        const orderSequences = data.results;
        // Aggiungi un'opzione 'All' se necessario
        orderSequences.push({
          id: 'all',
          name: 'All',
          code: 'ALL',
        });

        // Prepara i bottoni per l'alert basati sulle sequenze d'ordine
        const buttons = orderSequences.map((sequence) => ({
          text: sequence.name,
          handler: () => {
            console.log('Selezione ordine:', sequence);
            this.filterByStatus(sequence.code); // Adatta questa riga se necessario
          },
        }));

        // Aggiungi un bottone per annullare
        buttons.push({
          text: 'Annulla',
          role: 'cancel',
          handler: () => {
            console.log('annullo ricerca ordine');
          },
        });

        // Crea e presenta l'alert
        const alert = await this.alertController.create({
          header: "Seleziona lo stato dell'ordine",
          buttons: buttons,
        });

        await alert.present();
      });
  }

  createFormApprove(order) {
    console.log(order);
    this.order_orders_createForm = this.fb.group({
      type_id: this.fb.control(
        order.type ? order.type.id : null,
        Validators.required
      ),
      category_id: this.fb.control(order.category.id, Validators.required),
      status_id: this.fb.control(order.status.id, Validators.required),
      priority_id: this.fb.control(order.priority.id, Validators.required),
      job_id: this.fb.control(
        order.job ? order.job.id : null,
        Validators.required
      ),
      customer_id: this.fb.control(
        order.customer ? order.customer.id : null,
        Validators.required
      ),
      vendor_id: this.fb.control(
        order.vendor ? order.vendor.id : null,
        Validators.required
      ),
      cost: this.fb.control(order.cost, Validators.required),
      //causal_id: this.fb.control(this.order.casual ? this.order.casual.id : null),
      //extra_type_id: this.fb.control(this.order.extra_type ? this.order.extra_type.id : null),
      code: this.fb.control(order.code, Validators.required),
      //parent_id: this.fb.control(this.order.parent ? this.order.parent.id : null),
      //invoice_term_id: this.fb.control(this.order.invoice_term ? this.order.invoice_term.id : '', Validators.required),
      //payment_term_id: this.fb.control(this.order.payment_term ? this.order.payment_term.id : '', Validators.required),
      //payment_type_id: this.fb.control(this.order.payment_type ? this.order.payment_type.id : '', Validators.required)
    });
  }

  updateStatus(string, status, order) {
    console.log(status);
    let nextStatus = status.post.filter((e) => e.code === 'ORD');
    console.log(nextStatus);
    //this.elementSelectStatus['el'].value = null
    //this.showProgressStatus = true;
    const updatedOrderStatus: any = { status_id: nextStatus[0].id };
    console.log(updatedOrderStatus, string);

    this.order_orders_createForm.controls['status_id'].setValue(
      nextStatus[0].id
    );
    /*const projectUpdated = await */
    this.api
      .order_order_update(order.id, this.order_orders_createForm.value)
      .subscribe((e) => {
        console.log(e);
        if (e.message && e.message.includes('OK')) {
          this.presentToast(e.message, 'success');

          order.status = status;
          this.ionViewWillEnter();
          this.response = 'OK';
        } else {
          this.presentToast(e.message, 'danger');
        }

        this.showProgressStatus = false;
      });
    /* if (projectUpdated != null) {
        this.router.navigate(['/projects-list']);
      } */

    setTimeout(() => {
      this.showProgressStatus = false;
    }, 7000);

    // else if (status.detail.value == 0 && !this.totalInvoiceIn_completed) {
    //   this.goToInvoice()
    // } else if (status.detail.value == 0 && this.totalInvoiceIn_completed) {
    //   //this.presentAlert('Attenzione', 'limite fattutre raggiunto!s', 'ok')
    // }
  }

  async presentToast(text, color) {
    const toast = await this.toastController.create({
      message: text,
      position: 'bottom',
      duration: 3000,
      cssClass: 'toast',
      color: color,
    });
    return await toast.present();
  }

  //   copyOrder(event){
  //     console.log(event)
  //     let copyOrder = {
  //       "priority_id": event.priority?.id,
  //       "type_id": event.type?.id,
  //       "category_id": event.category?.id,
  //       "customer_id": event.customer?.id,
  //       "vendor_id": event.vendor?.id,
  //       "job_id": event.job?.id,
  //       "causal_id": event.causal?.id,
  //       "extra_type_id": event.extra_type || 0,
  //       "cost":event.cost || 0,
  //       "code": event.code,
  //       "parent_id": event.parent
  //   }
  //   this.api.order_orders_create(copyOrder).subscribe(async e => {
  //     console.log(e);
  //   })
  // }
  onActivateCategory(item) {
    /* if (this.table.rowDetail && event.type == 'click') {
      this.table.rowDetail.toggleExpandRow(event.row);
      console.log('Activate Event', event);
    } */

    this.category = item;
    console.log('Activate Event', item);
    let comp = JobsListPage;

    this.router.navigate(['/jobs'], { state: { category: item } });
    // //nav: this.nav(comp, { category: item }, { animated: true, direction: 'forward' });
  }

  setExpandStatus(expanded: any): void {
    console.log('setExpandStatus Event', expanded);
  }

  /**
   * @description Create new order, open modal
   * @returns
   */
  async openNewOrderModal(event?) {
    console.log(this.jobId);
    (await this.popoverController.getTop())
      ? this.popoverController.dismiss()
      : null;
    const modal = await this.modalController.create({
      component: NewOrderComponent,
      cssClass: 'newOrderModalForm',
      backdropDismiss: true,
      mode: 'md',
      componentProps: {
        title: 'New Order Draft',
        project_in: this.jobId,
        category: this.jobId?.category,
        categoryId: this.jobId?.category.id,
      },
      //presentingElement: this.routerOutlet.nativeEl,
    });

    modal.onWillDismiss().then((e) => {
      // Se Ok passo la risposta di conferma dell'api per risparmiare sui servizi http
      console.log('NewOrderComponent to OrderListPage', e);
      if (!e.data) {
        console.log('no modify');
        return;
      } else {
        console.log(e.data);

        // Se esiste refresh brutalmente (si puo rimpiazzare il risultato della singola riga come fatto per la tabella prodotti)
        this.listTable.rows.unshift(e.data);
        this.listTable.page.totalElements += 1;
        this.listTable.rows = [...this.listTable.rows];
        //this.orders = [];
        this.openOrderModal(e?.data);
      }
    });
    return await modal.present();
  }

  async openNewOrderFromCostModal(event?) {
    console.log(this.job);
    (await this.popoverController.getTop())
      ? this.popoverController.dismiss()
      : null;
    const modal = await this.modalController.create({
      component: NewOrderComponent,
      cssClass: 'newOrderModalForm',
      backdropDismiss: true,
      mode: 'md',
      componentProps: {
        title: 'New Order Draft',
        project_in: '',
        category: '',
        categoryId: '',
        fromCostCenter: true,
      },
      //presentingElement: this.routerOutlet.nativeEl,
    });

    modal.onWillDismiss().then((e) => {
      // Se Ok passo la risposta di conferma dell'api per risparmiare sui servizi http
      console.log('NewOrderComponent to OrderListPage', e);
      if (!e.data) {
        console.log('no modify');
        return;
      } else {
        console.log(e.data);

        // Se esiste refresh brutalmente (si puo rimpiazzare il risultato della singola riga come fatto per la tabella prodotti)
        this.listTable.rows.unshift(e.data);
        this.listTable.page.totalElements += 1;
        this.listTable.rows = [...this.listTable.rows];
        //this.orders = [];
      }
    });
    return await modal.present();
  }

  /**
   * @description Create new order, open modal
   * @returns
   */
  async openNewOrderCategoryModal(event?) {
    (await this.popoverController.getTop())
      ? this.popoverController.dismiss()
      : null;
    const modal = await this.modalController.create({
      component: NewOrderCategoryComponent,
      cssClass: 'newOrderModalForm',
      backdropDismiss: true,
      mode: 'md',
      componentProps: {
        title: 'New Cost Center',
      },
      breakpoints: [0, 0.6, 0.7],
      initialBreakpoint: 0.6,
      //presentingElement: this.routerOutlet.nativeEl,
    });

    modal.onWillDismiss().then((e) => {
      // Se Ok passo la risposta di conferma dell'api per risparmiare sui servizi http
      console.log('NewOrderComponent to OrderListPage', e);
      if (!e.data) {
        console.log('no modify');
        return;
      } else {
        console.log(e.data, this.order_categories);

        // Se esiste refresh brutalmente (si puo rimpiazzare il risultato della singola riga come fatto per la tabella prodotti)
        this.listCategoryTable.order_categories.unshift(e.data);

        this.listCategoryTable.order_categories = [
          ...this.listCategoryTable.order_categories,
        ];
        //this.orders = [];
        //this.orders = [];
      }
    });
    return await modal.present();
  }

  /**
   * @description Create new order, open modal
   * @returns
   */
  async openNewOfferModal() {
    await this.popoverController.dismiss();
    ////nav: this.nav(NewOfferComponent, { animate: true }, { direction: 'forward' })

    const modal = await this.modalController.create({
      component: NewOfferComponent,
      cssClass: 'newOrderModalForm',
      backdropDismiss: true,
      mode: 'md',
      componentProps: {
        title: 'New Offer Draft',
      },
      //presentingElement: this.routerOutlet.nativeEl,
    });

    modal.onWillDismiss().then((e) => {
      // Se Ok passo la risposta di conferma dell'api per risparmiare sui servizi http
      console.log('NewOfferComponent to OrderListPage', e);
      if (!e.data) {
        console.log('no modify');
        return;
      } else {
        // Se esiste refresh brutalmente (si puo rimpiazzare il risultato della singola riga come fatto per la tabella prodotti)
        this.listTable.rows.push(e.data);
        this.listTable.page.totalElements += 1;
        this.listTable.rows = [...this.listTable.rows];
        //this.orders = [];
      }
    });
    return await modal.present();
  }

  /**
   * @description Apre il modale per il dettaglio ordine
   * @trigger this.onActivate($row)
   * @param item
   * @returns
   */
  async openOrderModal(item) {
    console.log(item);
    // //console.log(this.nav);
    const modal = await this.modalController.create({
      component: OrderDetailsPage,
      cssClass: 'fullscreen',
      //backdropDismiss: false,
      mode: 'md',
      componentProps: {
        id: item.id,
        title: item.name,
        // //nav: this.nav
      },
      //presentingElement: this.routerOutlet.nativeEl,
    });

    modal.onWillDismiss().then((e) => {
      // Se Ok passo la risposta di conferma dell'api per risparmiare sui servizi http
      console.log('OrderDetailsPage to OrderListPage', e, this.listTable.rows);

      if (!e.data) {
        console.log('no modify');
        this.viewListCPR = false;
        this.viewListInvoice = false;
        setTimeout(() => {
          if (this.userInvLogged) {
            this.viewListCPR = true;
            this.viewListInvoice = true;
          }
          if (this.utentiAbilitatiCPR.includes(this.emailPermission)) {
            this.viewListCPR = true;
          }
        }, 50);
      } else {
        // Se esiste refresh brutalmente (si puo rimpiazzare il risultato della singola riga come fatto per la tabella prodotti)

        this.orders = this.listTable.rows;
        let ordersIndex = this.orders.map((e, i, a) => e.id).indexOf(item.id);
        this.orders[ordersIndex] = e.data;
        console.log(this.orders, ordersIndex);

        this.listTable.rows = this.orders;

        setTimeout(() => {
          this.listTable.table.bodyComponent.offsetX = 0;
          this.listTable.table.bodyComponent.offsetY = 0;
          this.listTable.table.headerComponent.offsetX = 0;
          this.listTable.table.recalculateColumns();
          this.listTable.rows = [...this.listTable.rows];
        }, 100);

        console.log(this.orders);
      }
    });
    return await modal.present();
  }

  async goToOrder(item) {
    this.api.order_orders_list('?page=1&items=25').subscribe(async (data) => {
      console.log(data);
      //this.loaderCtrl.dismiss(
      let a = data.results.filter((e) => {
        console.log(e);
      });
      item = a;
      console.log(item);
      const modal = await this.modalController.create({
        component: OrderDetailsPage,
        cssClass: 'fullscreen',
        //backdropDismiss: false,
        mode: 'md',
        componentProps: {
          id: item.id,
          title: item.name,
          // //nav: this.nav
        },
        //presentingElement: this.routerOutlet.nativeEl,
      });

      modal.onWillDismiss().then((e) => {
        // Se Ok passo la risposta di conferma dell'api per risparmiare sui servizi http
        console.log(
          'OrderDetailsPage to OrderListPage',
          e,
          this.listTable.rows
        );

        if (!e.data) {
          console.log('no modify');
          return;
        } else {
          // Se esiste refresh brutalmente (si puo rimpiazzare il risultato della singola riga come fatto per la tabella prodotti)

          this.orders = this.listTable.rows;
          let ordersIndex = this.orders.map((e, i, a) => e.id).indexOf(item.id);
          this.orders[ordersIndex] = e.data;
          console.log(this.orders, ordersIndex);

          this.listTable.rows = this.orders;

          setTimeout(() => {
            this.listTable.table.bodyComponent.offsetX = 0;
            this.listTable.table.bodyComponent.offsetY = 0;
            this.listTable.table.headerComponent.offsetX = 0;
            this.listTable.table.recalculateColumns();
            this.listTable.rows = [...this.listTable.rows];
          }, 100);

          console.log(this.orders);
        }
      });
      return await modal.present();
    });
  }

  onGetRowClass = (row: any) => {
    //console.log(row);
    // id 3 is Delete for Status Order
    if (row.status.id === 3) {
      return 'deleted';
    } else if (row.status.id === 2) {
      return 'request';
    } else if (row.status.id === 1) {
      return 'draft';
    } else if (row.status.id === 4) {
      return 'order';
    } else return '';
  };

  /**
   * @description ritorna la somma delle colonne che gli si assegna questa funzione
   * @param n
   * @returns somma della colonna in "rowSummary" template
   */
  caclulateSumm(n?) {
    console.log();

    this.total_order = 0;
    this.orders.map((item) => {
      this.total_order += item.cost;
      //this.currencyCode = item.currency;
      //console.log(this.total_order);
    });
    return this.total_order.toFixed(2) + ' ' + n;
  }

  /* 
    onChangeSearchbar(event) {
      console.log('search', event);
      if (event != '') {
        //this.queryString = localStorage.getItem('filter_search_orders') ? JSON.parse(localStorage.getItem('filter_search_orders')) : { search_by: '', search_data: 'all' }
  
        this.page_number = 1;
        let url = this.concateQuery(this.queryString)
  
        this.api.searchOrder(true, url, event.toString().toLowerCase().trim()).subscribe(e => {
          console.log(e);
          if (e) {
            this.listTable.rows = e.results;
            this.listTable.totalElements = e.total_items;
  
            setTimeout(() => {
              this.cache = {}
              this.listTable.table.bodyComponent.offsetX = 0;
              this.listTable.table.bodyComponent.offsetY = 0;
              this.listTable.table.headerComponent.offsetX = 0;
              this.listTable.table.recalculateColumns();
              this.listTable.rows = [...this.listTable.rows]
            }, 100);
          }
        })
  
      } else {
        //this.loadProjects(this.queryString);
        this.getOrders(false, event, this.queryString);
      }
    } */

  onSwichStatusSeg(event) {
    console.log(event, this.myInput);
    if (this.myInput && this.myInput.value != '') {
      document
        .querySelector('ion-searchbar')
        .getInputElement()
        .then((searchInput) => {
          searchInput.value = '';
        });
    }
  }

  toggleSearch() {
    this.enableSearch = !this.enableSearch;
    if (this.enableSearch) {
      setTimeout(() => {
        this.myInput.setFocus();
      }, 150);
    }
  }

  callFilterItem() {
    let readFilter = {};

    try {
      readFilter = JSON.parse(localStorage.getItem('filter_orders'));
      console.log(readFilter, this.filterItems);

      /*       if (readFilter == null) {
              localStorage.setItem('filter_orders', JSON.stringify(this.filterItems))
            } else {
              this.filterItems = readFilter;
            } */

      // forza il reset al l'entrata - viene svolta dal filglio
      /* readFilter[0].search_data = ''
      readFilter[1].search_data = ''
      localStorage.setItem('filter_orders', JSON.stringify(readFilter)) */

      this.filterItems.map((e) => {
        if (e.search_data != '') {
          switch (e.search_by) {
            case 'name':
              this.activeFilterOrders = true;
              this.stringFilterOrders = e.search_data;
              break;
            case 'code':
              this.activeFilterOrders = true;
              this.stringFilterOrders = e.search_data;
              break;
            case 'status':
              this.activeFilterOrders = true;
              this.stringFilterOrders = e.search_data;
              break;

            default:
              break;
          }
        } else if (e.search_data == '') {
          switch (e.search_by) {
            case 'name':
              this.activeFilterOrders = false;
              this.stringFilterOrders = e.search_data;
              break;
            case 'code':
              this.activeFilterOrders = false;
              this.stringFilterOrders = e.search_data;
              break;
            case 'status':
              this.activeFilterOrders = false;
              this.stringFilterOrders = e.search_data;
              break;

            default:
              break;
          }
        }
      });
    } catch {
      console.log('error');
      localStorage.setItem('filter_orders', JSON.stringify(this.filterItems));
      this.filterItems = readFilter;
    }

    console.log('apro list component');
    console.log(this.jobId);

    if (this.jobId.length !== 0) {
      setTimeout(() => {
        this.listTable?.setPage({ offset: 0 }, this.filterParam, this.jobId);
      }, 300);
    }
  }

  updateFilterItem(filter, data) {
    this.filterItems = JSON.parse(localStorage.getItem('filter_orders'));
    let foundIndex;

    foundIndex = this.filterItems.map((e) => e.search_by).indexOf(filter);
    if (foundIndex == -1) {
      this.filterItems.push({ search_by: filter, search_data: data });
    } else {
      this.filterItems[foundIndex] = { search_by: filter, search_data: data };
    }

    localStorage.setItem('filter_orders', JSON.stringify(this.filterItems));
    console.log('updateFilterItem', this.filterItems, filter, data);
    console.log(this.orders);

    this.filterItems.map((e) => {
      if (e.search_data != '') {
        switch (e.search_by) {
          case 'name':
            this.activeFilterOrders = true;
            this.stringFilterOrders = e.search_data;
            break;
          case 'code':
            this.activeFilterOrders = true;
            this.stringFilterOrders = e.search_data;
            break;
          case 'status':
            this.activeFilterOrders = true;
            this.stringFilterOrders = e.search_data;
            break;
          case 'lines':
            this.activeFilterOrders = false;
            this.stringFilterOrders = e.search_data;
            break;

          default:
            break;
        }
      } else if (e.search_data == '') {
        switch (e.search_by) {
          case 'name':
            this.activeFilterOrders = false;
            this.stringFilterOrders = e.search_data;
            break;
          case 'code':
            this.activeFilterOrders = false;
            this.stringFilterOrders = e.search_data;
            break;
          case 'status':
            this.activeFilterOrders = false;
            this.stringFilterOrders = e.search_data;
            break;
          case 'lines':
            this.activeFilterOrders = false;
            this.stringFilterOrders = e.search_data;
            break;
          default:
            break;
        }
      }
    });
  }

  concateQuery() {
    console.log('concateQuery');
    let params = JSON.parse(localStorage.getItem('filter_orders'));
    //if (this.pageNumber <= 1 && index == -1)
    //this.columns.splice(2, 0, { prop: 'status.name', name: 'Status', width: 95, maxWidth: 95, minWidth: 95, cellClass: 'td-status', headerClass: 'th-status' })
    console.log(params);

    let url =
      '?page=' + (this.page.pageNumber + 1) + '&items=' + this.page.size;

    if (!params) return url;
    params.map((param, i) => {
      let query = '';

      /*  console.log('index', i);
       console.log(param);
       console.log(param != '');
       console.log(param.search_data, param.search_data != ''); */

      if (param && param != '' && param.search_data != '') {
        query += '&' + param.search_by + '=';
        query += param.search_data;

        //let filtered = this.columns.filter(obj => obj.name !== 'Status');

        //console.log(filtered);

        //this.columns = [...this.columns]
        url += query;
      } else {
        console.log('else');
      }
    });
    console.log(url);

    return url;
  }

  selectedText;
  searchPorts(
    event /*:  { component: IonicSelectableComponent; text: string }, type */
  ) {
    this.viewListOrderVendor = false;
    this.viewListJob = false;
    console.log(event, event.text);
    this.selectedText = event.text;
    //console.log(event.component._modalComponent);
    //console.log(event.component._modalComponent._content);

    //event.component._modalComponent._content.scrollToBottom(1500);
    //event.component._modalComponent._content.scrollY = false

    let url = '';

    const text = event.text.trim().toLowerCase();
    //event.component.startSearch();

    // Close any running subscription.
    if (this.vendorSubscription) {
      this.vendorSubscription.unsubscribe();
    }

    if (this.jobSubscription) {
      this.jobSubscription.unsubscribe();
    }

    if (!text) {
      // Close any running subscription.
      if (this.vendorSubscription) {
        this.vendorSubscription.unsubscribe();
      }

      if (this.jobSubscription) {
        this.jobSubscription.unsubscribe();
      }

      //event.component.endSearch();
      //event.component.enableInfiniteScroll();
      switch (event['module']) {
        case 'vendor':
          this.page_vendors = 1;

          url = '?page=' + this.page_vendors + '&items=' + this.size;

          this.vendorApi.getCompaniesList(url).subscribe((data) => {
            console.log('!text call more getVendorsList', data);

            //data.results = event.component.items = (data.results);

            //this.numPage_project = data.num_pages;

            //event.component.items = data.results;
            this.onSearchSelector = '';
            this.numPage_vendors = data.num_pages;
            this.companies = data.results;
            //event.component.endInfiniteScroll();
          });
          break;
        case 'customer':
          this.page_customers = 1;

          url = '?page=' + this.page_customers + '&items=' + this.size;

          this.apiCustomer.getCustomersList(url).subscribe((data) => {
            console.log('!text call more getCustomersList', data);

            //data.results = event.component.items = (data.results);

            //this.numPage_project = data.num_pages;

            //event.component.items = data.results;
            this.onSearchSelector = '';
            this.numPage_customers = data.num_pages;
            this.companies = data.results;
            //event.component.endInfiniteScroll();
          });
          break;
        case 'job':
          this.page_jobs = 1;

          url =
            '?page=' + this.page_jobs + '&items=' + this.size + '&parent_id=0';

          this.jobApi.getJobsList(url).subscribe((data) => {
            console.log('!text call more getVendorsList', data);

            //data.results = event.component.items = (data.results);

            //this.numPage_project = data.num_pages;

            //event.component.items = data.results;
            this.onSearchSelector = '';
            this.numPage_jobs = data.num_pages;
            this.jobs = data.results;
            //event.component.endInfiniteScroll();
          });
          break;
      }

      return;
    }

    switch (event['module']) {
      case 'vendor':
        url = '?page=' + this.page_vendors + '&items=25&name=' + text;
        this.vendorSubscription = this.vendorApi
          .getCompaniesList(url)
          .subscribe((data) => {
            console.log('text call from search getVendorsList', data);
            // Subscription will be closed when unsubscribed manually.
            if (this.vendorSubscription.closed) {
              return;
            }
            this.onSearchSelector = text;
            this.numPage_vendors = data.num_pages;
            this.companies = data.results;
          });
        break;
      case 'customer':
        url = '?page=' + this.page_customers + '&items=25&name=' + text;
        this.customerSubscription = this.apiCustomer
          .getCustomersList(url)
          .subscribe((data) => {
            console.log('text call from search getVendorsList', data);
            // Subscription will be closed when unsubscribed manually.
            if (this.customerSubscription.closed) {
              return;
            }
            this.onSearchSelector = text;
            this.numPage_customers = data.num_pages;
            this.companies = data.results;
          });
        break;
      case 'job':
        url = '?page=' + this.page_jobs + '&items=25&parent__name=' + text;
        this.jobSubscription = this.jobApi
          .getJobsList(url)
          .subscribe((data) => {
            console.log('text call from search getVendorsList', data);
            // Subscription will be closed when unsubscribed manually.
            if (this.jobSubscription.closed) {
              return;
            }
            this.onSearchSelector = text;
            this.numPage_jobs = data.num_pages;
            this.jobs = data.results;
            if (this.jobs.length === 0) {
              url = '?page=' + this.page_jobs + '&items=25&name_slug=' + text;
              this.jobSubscription = this.jobApi
                .getJobsList(url)
                .subscribe((data) => {
                  console.log('text call from search getVendorsList', data);
                  // Subscription will be closed when unsubscribed manually.

                  this.onSearchSelector = text;
                  this.numPage_jobs = data.num_pages;
                  this.jobs = [...this.jobs, ...data.results];
                });
              if (this.jobs.length === 0) {
                url = '?page=' + this.page_jobs + '&items=25&name=' + text;
                this.jobSubscription = this.jobApi
                  .getJobsList(url)
                  .subscribe((data) => {
                    console.log('text call from search getVendorsList', data);
                    // Subscription will be closed when unsubscribed manually.

                    this.onSearchSelector = text;
                    this.numPage_jobs = data.num_pages;
                    this.jobs = [...this.jobs, ...data.results];
                  });
              }
            }
          });
    }
  }

  filterPorts(vendors: any, text: string) {
    console.log(vendors);
    return vendors.filter((vendors) => {
      return vendors.name.toLowerCase().indexOf(text) !== -1; //||
      //port.country.name.toLowerCase().indexOf(text) !== -1 ||
      //port.id.toString().toLowerCase().indexOf(text) !== -1;
    });
  }

  onInfiniteScroll(
    /* 
  event: { component: IonicSelectableComponent; text: string },
  type: string */ event
  ) {
    console.log(event);
    console.log(this.page_vendors, this.numPage_vendors);
    console.log(this.title);

    let url;
    let _type = event['type'] ? event['type'] : null;
    console.log('after', _type);

    url = '?page=' + ++this.page_vendors + '&items=' + this.size;

    this.vendorApi.getCompaniesList(url).subscribe((data) => {
      console.log('!text call more getVendorsList', data);

      //data.results = event.component.items = (data.results);

      //this.numPage_project = data.num_pages;

      //event.component.items = data.results;
      this.onSearchSelector = '';
      this.numPage_vendors = data.num_pages;
      this.companies = data.results;
      //event.component.endInfiniteScroll();
    });

    url = '?page=' + ++this.page_jobs + '&items=' + this.size + '&parent_id=0';

    this.jobApi.getJobsList(url).subscribe((data) => {
      console.log('!text call more getVendorsList', data);

      //data.results = event.component.items = (data.results);

      //this.numPage_project = data.num_pages;

      //event.component.items = data.results;
      this.onSearchSelector = '';
      this.numPage_jobs = data.num_pages;
      this.jobs = data.results;
      //event.component.endInfiniteScroll();
    });
  }

  exportToCsv() {
    console.log('ExportCSV');
    this.listTable.exportToCSV();
    // Estrai solo i campi desiderati da ogni oggetto
  }

  letInfoJob() {
    console.log('infoJob');
    this.listTable.letInfoJob();
    // Estrai solo i campi desiderati da ogni oggetto
  }

  letInfoOrder() {
    console.log('infoJob');
    this.listTable.openAlertInfoOrder();
    // Estrai solo i campi desiderati da ogni oggetto
  }

  filterByStatus(value) {
    console.log('infoJob');
    this.listTable.filterByStatus(value);
    // Estrai solo i campi desiderati da ogni oggetto
  }

  searchItemINV(event) {
    console.log(event);

    let name = event.detail.value;

    let url = '?page=1&items=25&causal_id=1&code=' + name;
    console.log(url);
    this.listTable?.searchListInv(url, name);
  }

  searchItemCPR(event) {
    console.log(event);

    let name = event.detail.value;

    let url = '?page=1&items=25&causal_id=1&code=' + name;
    console.log(url);
    this.listTable?.searchListCPR(url, name);
  }

  segmentChanged(event: any) {
    // Opzionalmente, esegui azioni specifiche quando cambia il segmento
    console.log('Segment changed', event.detail.value);
    this.selectedSegment = event.detail.value;
    this.cancelAllRequests();
  }

  downloadFile(item) {
    console.log(item);
    let orderId = item.id;
    let document = item.documents;
    console.log(document);
    console.log(item.documents);

    if (document.length === 1) {
      this.api
        .order_order_document_download_list(orderId, document[0])
        .subscribe((res) => {
          console.log(res, item);

          this._FileSaverService.save(
            res.body,
            document[0].name + this.getFileExtension(document[0].orderfile)
          );

          if (res && res.data.id && item) {
            //this.openFileInfosModal(res.data, item, item);
          } else {
            console.log('else openFileModal');
          }
        });
    } else if (document.length >= 1) {
      this.presentAlertWithList(orderId, document);
    } else {
      return;
    }

    // }
    // else{
    //   return
    // }
  }

  async presentAlertWithList(orderId, documents) {
    const documentButtons = documents.map((doc) => ({
      text: `${doc.name}`,
      handler: () => {
        // Passa l'intero oggetto documento alla funzione di gestione
        this.handleDocumentSelection(orderId, doc);
      },
    }));

    // Definisci un pulsante di annullamento
    const cancelButton = {
      text: 'Chiudi',
      role: 'cancel',
      cssClass: 'alert-buttons',
      handler: () => {
        // Gestisci l'annullamento qui, se necessario
        console.log('Selezione documento annullata');
      },
    };

    const alert = await this.alertController.create({
      header: 'A',
      cssClass: 'alert-class',
      buttons: [...documentButtons, cancelButton], // Aggiungi tutti i pulsanti all'alert
    });

    await alert.present();
  }

  handleDocumentSelection(orderId, selectedId) {
    console.log(selectedId);

    this.api
      .order_order_document_download_list(orderId, selectedId)
      .subscribe((res) => {
        this._FileSaverService.save(
          res.body,
          selectedId.name + this.getFileExtension(selectedId.orderfile)
        );

        if (res && res.data.id && selectedId) {
          //this.openFileInfosModal(res.data, item, item);
        } else {
          console.log('else openFileModal');
        }
      });
  }

  printOrder(item) {
    this.apiPrint.print(item);
  }

  getFileExtension(filename) {
    const lastIndex = filename.lastIndexOf('.');

    // Se un punto è presente nella stringa e non è il primo carattere
    if (lastIndex !== -1 && lastIndex !== 0) {
      return filename.substring(lastIndex); // Restituisce la stringa dal punto in poi
    } else {
      return ''; // Restituisce una stringa vuota se non c'è un'estensione
    }
  }

  closeModal() {
    this.modalController.dismiss();
  }
}
