import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IonNav, IonSearchbar } from '@ionic/angular';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  @ViewChild(IonSearchbar) myInput: IonSearchbar;

  @Input() enableSearch = false;

  @Input() childs: any;

  @Output()
  callBack: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  filterTerm: EventEmitter<string> = new EventEmitter<string>();

  constructor() { }

  ngOnInit() { }

  methodAction(action) {
    try {
      this[action]()
    } catch {
      console.log('not call in component, run callback, fabBtn');
      this.callBack.emit(action)
    }
  }

  toggleSearch() {
    this.enableSearch = !this.enableSearch;
    if (this.enableSearch) {
      setTimeout(() => {
        this.myInput.setFocus()
      }, 150);
    }
  }

  onTyping(event) {
    console.log(event.detail.value);
    this.filterTerm.emit(event.detail.value)

  }
  
}
