import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, OnInit, Renderer2, ViewChild } from '@angular/core';

import { ModalController, IonRouterOutlet, NavController, CheckboxCustomEvent, Platform, IonNav, ToastController, IonSearchbar, LoadingController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { Project } from '../models/projects';
import { NewProjectComponent } from '../new-project/new-project.component';
import { DataService } from '../../services/data/data.service';
import { ApiService } from 'src/app/services/api/api.service';
import { ProjectsService } from 'src/app/services/projects/projects.service';
import { ProjectDetailsPage } from '../project-details/project-details.page';
//import { GanttBarClickEvent, GanttDate, GanttDragEvent, GanttItem, GanttLineClickEvent, GanttLinkDragEvent, GanttSelectedEvent, GanttViewOptions, GanttViewType, NgxGanttComponent } from '@worktile/gantt';
import * as moment from 'moment';
import { TitleAppService } from 'src/app/services/utils/title-app/title-app.service';
// import { PickupLocationPage } from 'src/app/pickup-location/pickup-location.page';
import { ListProjectsComponent } from '../components/list/list.component';
import { Router } from '@angular/router';
import { RoutingStateService } from 'src/app/services/routing-state/routing-state.service';
//import { ChangeDetectorRef, AfterContentChecked } from '@angular/core';

const TIMEOUT = 5;

interface PageInfo {
  offset: number;
  pageSize: number;
  limit: number;
  count: number;
}
/**
 * An object used to get page information from the server
 */
export class Page {
  // The number of elements in the page
  size: number = 0;
  // The total number of elements
  totalElements: number = 0;
  // The total number of pages
  totalPages: number = 0;
  // The current page number
  pageNumber: number = 0;
}


@Component({
  //changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-projects-list',
  templateUrl: './projects-list.page.html',
  styleUrls: ['./projects-list.page.scss'],
})
export class ProjectsListPage /* implements AfterContentChecked */ {
  //projects: Observable<Project[]>;
  projects: any;
  select_project;

  currentSegment: string = "All";
  color: string;
  disableLink = false;
  selectedItem: any = [];
  selecAllItems: boolean = true;
  timeoutPress = TIMEOUT;


  filterTerm: string;
  enableSearch = false;

  total_page = 0;
  page_number = 1;
  page_limit = 30;

  toast: any;


  isSearch: boolean = false;
  isFocus: boolean = false;
  isLoad: boolean = false;

  queryString = {
    search_by: '',
    search_data: ''
  };

  queryFilter = {
    search_by: 'sort',
    search_data: [{ prop: 'priority.id', dir: 'asc' }]
  };


  n = 0
  totalElements: number;
  pageNumber: number;

  isLoading = 0;
  typeData = true

  cache
  columns = [];


  /** Gantt 

  views = [
    {
      name: 'Day',
      value: GanttViewType.day
    },
    {
      name: 'Week',
      value: GanttViewType.week
    },
    {
      name: 'Months',
      value: GanttViewType.month
    },
    {
      name: 'Quarter',
      value: GanttViewType.quarter
    },
    {
      name: 'Year',
      value: GanttViewType.year
    }
  ];

/*   viewType: GanttViewType = GanttViewType.month;

  items: GanttItem[] = [
    
  ];

  options = {
    viewType: GanttViewType.month
  };

  viewOptions: GanttViewOptions = {
    //start: new GanttDate(Date.now()),
    dateFormat: {
      week: 'w',
      month: 'MMMM',
      quarter: 'Q',
      year: 'yyyy',
      yearMonth: 'yyyy/MM',
      yearQuarter: 'yyyy/QQQ'
    }
  };



  @ViewChild('gantt') ganttComponent: NgxGanttComponent; */
  /** Gantt End    */

  @ViewChild('prj') prjElement: ElementRef;
  @ViewChild('prjs') prjList: ElementRef;
  @ViewChild('star') btnStar: ElementRef;
  @ViewChild(IonSearchbar) myInput: IonSearchbar;
  @ViewChild(ListProjectsComponent) listTable: ListProjectsComponent;


  checkbox: boolean = false;
  deregisterBackButtonAction


  navMenu = [
    { action: 'openNewProjectModal', label: 'New', icon: 'add-outline' },
    // { action: 'toggleSearch', label: 'Search', icon: 'search-outline' },
    //{ action: '', label: 'Export', icon: 'download-outline' }
  ]

  selectedFilter = 'name'
  filterItems: any = [
    {
      search_by: 'name', //name //
      search_data: ''
    },
    // {
    //   search_by: 'code',
    //   search_data: ''
    // },

  ]
  activeFilterProjects // = false
  stringFilterProjects = ''


  constructor(
    private platform: Platform,
    //private cdref: ChangeDetectorRef,
    private dataService: DataService,
    private api: ProjectsService,
    public modalController: ModalController,
    private routerOutlet: IonRouterOutlet,
    private renderer: Renderer2,
    //private nav: IonNav,
    private navCtrl: NavController,
    public plt: Platform,
    private toastController: ToastController,
    private titleAppService: TitleAppService,
    private el: ElementRef,
    private loaderCtrl: LoadingController,
    private router: Router,
    private routingState: RoutingStateService
  ) {



  }



  callFilterItem() {

    let readFilter = {};

    try {
      readFilter = JSON.parse(localStorage.getItem('filter_projects'))
      console.log(readFilter, this.filterItems);


      /*       if (readFilter == null) {
              localStorage.setItem('filter_projects', JSON.stringify(this.filterItems))
            } else {
              this.filterItems = readFilter;
            } */
      // forza il reset al l'entrata
      localStorage.setItem('filter_projects', JSON.stringify(this.filterItems))


      this.filterItems.map(e => {
        if (e.search_data != '') {
          switch (e.search_by) {
            case 'name':
              this.activeFilterProjects = true
              this.stringFilterProjects = e.search_data
              break;
            case 'code':
              this.activeFilterProjects = true
              this.stringFilterProjects = e.search_data
              break;

            default:
              break;
          }
        } else if (e.search_data == '') {
          switch (e.search_by) {
            case 'name':
              this.activeFilterProjects = false
              this.stringFilterProjects = e.search_data
              break;
            case 'code':
              this.activeFilterProjects = false
              this.stringFilterProjects = e.search_data
              break;

            default:
              break;
          }
        }
      })

    }
    catch {
      console.log('error');
      localStorage.setItem('filter_projects', JSON.stringify(this.filterItems))
      this.filterItems = readFilter;
    }



    this.listTable.setPage({ offset: 0 })

  }

  updateFilterItem(filter, data) {

    this.filterItems = JSON.parse(localStorage.getItem('filter_projects'))
    let foundIndex

    foundIndex = this.filterItems.map(e => e.search_by).indexOf(filter);
    if (foundIndex == -1) {
      this.filterItems.push({ search_by: filter, search_data: data });
    } else {
      this.filterItems[foundIndex] = { search_by: filter, search_data: data };

    }

    localStorage.setItem('filter_projects', JSON.stringify(this.filterItems))
    console.log('updateFilterItem', this.filterItems, filter, data);


    this.filterItems.map(e => {
      if (e.search_data != '') {
        switch (e.search_by) {
          case 'name':
            this.activeFilterProjects = true
            this.stringFilterProjects = e.search_data
            break;
          case 'code':
            this.activeFilterProjects = true
            this.stringFilterProjects = e.search_data
            break;

          default:
            break;
        }
      } else if (e.search_data == '') {
        switch (e.search_by) {
          case 'name':
            this.activeFilterProjects = false
            this.stringFilterProjects = e.search_data
            break;
          case 'code':
            this.activeFilterProjects = false
            this.stringFilterProjects = e.search_data
            break;

          default:
            break;
        }
      }
    })
  }

  createcolumns() {
    this.columns = [
      { prop: 'id', name: 'ID', frozenLeft: true, width: 45, maxWidth: 45, minWidth: 45, cellClass: 'td-id', headerClass: 'th-id', summaryFunc: () => null },
      { prop: 'code', width: 250, maxWidth: 250, frozenLeft: false },
      { prop: 'name' },
      { prop: 'date_start', name: 'Start', width: 150, maxWidth: 150, frozenLeft: false },
      { prop: 'date_end', name: 'End', width: 150, maxWidth: 150, frozenLeft: false },
      { prop: 'prev_order_in', name: 'PREV SELL ORD', width: 200, maxWidth: 100, frozenLeft: false },
      { prop: 'prev_order_out', name: 'PREV BUY ORD', width: 200, maxWidth: 200, frozenLeft: false },
      { prop: 'total_order_in', name: 'SELL ORD', width: 200, maxWidth: 200, frozenLeft: false },
      { prop: 'total_order_out', name: 'BUY ORD', width: 200, maxWidth: 200, frozenLeft: false },
      { prop: 'prev_invoice_in', name: 'PREV SELL INV', width: 200, maxWidth: 200, frozenLeft: false },
      { prop: 'prev_invoice_out', name: 'PREV BUY INV', width: 200, maxWidth: 200, frozenLeft: false },
      { prop: 'total_invoice_in', name: 'SELL INV', width: 200, maxWidth: 100, frozenLeft: false },
      { prop: 'total_invoice_out', name: 'BUY INV', width: 200, maxWidth: 200, frozenLeft: false }
    ]



  }

  ionViewWillEnter() {
    console.log('Will enter');
    this.titleAppService.title.next('Projects')
    this.isLoad = false;
    this.toast = undefined;

    this.timeoutPress = TIMEOUT;
    this.callFilterItem()

  }

  ionViewDidEnter() {

  }

  filterProjects(event: any) {
    const selectedCategory = event.detail.value;
    this.currentSegment = selectedCategory;

    //this.loadProjects(selectedCategory);
  }

  epochDate(date) {
    var epoch = moment(date).unix();
    return epoch.toString();
  }

  formatDate(date) {
    var epoch = moment(date).format('DD-MM-YYYY');
    return epoch.toString();
  }


  onGetRowClass = (row:any) => {
    //console.log(row);
    // id 3 is Delete for Status Order
    if (row.status.id === 3) {
      return 'deleted';
    } else if (row.status.id === 2) {
      return 'request';
    } else if (row.status.id === 1) {
      return 'draft';
    } else if (row.status.id === 4) {
      return 'order';
    }
    else
      return ''
  }

  async openNewProjectModal(event?) {
    const modal = await this.modalController.create({
      component: NewProjectComponent,
      cssClass: 'newProjectModalForm',
      canDismiss: true,
      componentProps: {
        //nav: this.nav
      },
      presentingElement: this.routerOutlet.nativeEl,
    });

    modal.onWillDismiss().then((e) => {
      console.log(e);

      if (!e.data) {
        console.log('no modify');
        return
      } else {
        //this.loadProjects(this.currentSegment);

      }
    });

    return await modal.present();
  }

  randomColor() {
    const colors = ["#4caf50", "#ffc107", "#607d8b", "#2196f3", "#e91e63", "#673ab7", "#009688"];
    const indexColor = Math.floor(Math.random() * colors.length);
    const randomColor = colors[indexColor];
    //this.color == randomColor ? ,
    return randomColor; //this.color;
  }


  /*   ngAfterContentChecked() {
      this.cdref.detectChanges();
    } */

  methodAction(action) {
    try {
      this[action]()
    } catch {
      console.log('not call in parent component');
    }
    //console.log(action)
  }






  goTo(item, prj?, action?) {

    console.log('goTo', item);

    let ids;
    let i = this.selectedItem.findIndex(e => e.id === Number(item));

    this.router.navigate(['/projects-details'], {state: {id: item.id}})
    //nav: this.nav(ProjectDetailsPage, { id: item.id }, { animated: true });



    ids = this.selectedItem.map(o => o.id)
    this.selectedItem = this.selectedItem.filter(({ id }, index) => !ids.includes(id, index + 1))
    //console.log(this.selectedItem);

  }



  async presentToast(text, color) {
    const toast = await this.toastController.create({
      message: text,
      position: 'bottom',
      duration: 2000,
      cssClass: 'toast',
      color: color
    });
    toast.present();
  }


  refershEvent(event) {
    this.listTable.setPage({ offset: 0 })

  }

  filterTermEvent(event) {
    this.filterTerm = event
    this.isFocus = false
    console.log('search', this.selectedFilter, event, this.filterTerm);

    try {
      event = JSON.parse(event);



      //this.queryString = event

      //this.listTable.setPage({ offset: 0 })
 

      this.updateFilterItem(event.type, event.data)


    }
    catch {
      //this.loadProjects(this.queryString);
      //this.getOrders(false, event, this.queryString);

      this.updateFilterItem(event.type, event.data)

    }
    //this.listTable.setPage({ offset: 0 })

    setTimeout(() => {
      this.isFocus = true
    }, 1800);
  }

  typeFilterTerm(event: any) {
    console.log(event);
    this.selectedFilter = event
    //this.updateFilterItem(event.type, event.value)


  }

  print(name: string) {
    //this.printService.print(name);
  }
  /***  End Gantt */


  displayCheck(row) {
    return row.active !== false;
  }






  /**
   * @description onClick on row open order details
   * @param event pass obj row clicked
   */
  onActivate(event) {
    /* if (this.table.rowDetail && event.type == 'click') {
      this.table.rowDetail.toggleExpandRow(event.row);
      console.log('Activate Event', event);
    } */
    this.goTo(event)
    if (event.type == 'click') {
      console.log('Activate Event', event);
      //this.clicked.emit(event);
    }
  }




  // async openMap() {
  //   const modal = await this.modalController.create({
  //     component: PickupLocationPage,
  //     cssClass: ' ',
  //     canDismiss: true,
  //     presentingElement: this.routerOutlet.nativeEl,
  //   });

  //   modal.onWillDismiss().then((e) => {
  //     console.log(e);

  //     if (!e.data) {
  //       console.log('no modify');
  //       return
  //     } else {
  //       //this.loadProjects(this.currentSegment);

  //     }
  //   });

  //   return await modal.present();
  // }

  onSwichStatusSeg(event) {
    console.log(event, this.myInput);
    if (this.myInput && this.myInput.value != '') {
      /* document.querySelector('ion-searchbar').getInputElement().then((searchInput) => {
        searchInput.value = '';
      }); */
    }

  }


  returnOrders($event) {
    console.log('returnOrders', $event);
    this.projects = $event
  }
  

  searchValue: string = '';
  code: boolean = false

  onSearchInput(event: any) {
    this.searchValue = event.target.value;
    console.log(event.target.value)
  }

  changeFilter(){
    this.code = !this.code
  }

  
  goBack(){
    this.router.navigate(['/' + this.routingState.navigateBack()]);
  }


}

