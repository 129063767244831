import { Component, ElementRef, OnChanges, OnInit, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { IonNav, IonSearchbar, ModalController } from '@ionic/angular';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { ProductDetailsPage } from 'src/app/products/product-details/product-details.page';
import { DdtDetailsPage } from 'src/app/trasport_documents/ddt-details/ddt-details.page';
import { VendorDetailsPage } from 'src/app/vendors/vendor-details/vendor-details.page';

@Component({
  selector: 'app-ddt-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
})
export class DdtTimelineComponent implements OnInit {

  @ViewChild('list') list: ElementRef
  @ViewChild('myInput') myInput: IonSearchbar

  filterTerm: string;
  sumQntAvailable = 0
  enableSearch = true;
  enableCompareQuantity = false

  dir
  val

  
  revertSort = true;
  delayRevertSort = true;

  emitSort = new Subject<boolean>()
  emitNav = new Subject<boolean>()

  timerClick = null
  elements
  keys_elements
  row_elements
  objectKeys = Object.keys;
  /*   config = [
      { type: 'icon', label: 'not started', class: '', shape: 'circle', btns: [{ text: 'Refresh', func: 'refreshList' }], img: '', desc: 'Root CA certificate requested.' },
      { type: 'spinner', label: 'Current', class: '', shape: 'dot-circle', desc: 'Root CA certificate requested. Upload it to the KMS to complete the connection.' },
      { type: 'icon', label: 'In progress', class: '', shape: '', desc: 'Root CA certificate requested. Upload it to the KMS to complete the connection. Third sentence is very long and very long.' },
      { type: 'icon', label: 'Completed', class: 'is-success', shape: 'plus-circle', desc: 'Upload it to the KMS to complete the connection. Thirdsentence.' },
      { type: 'icon', label: 'Error', class: 'is-error', shape: 'minus-circle', desc: 'No. Its not connected.' }
    ] */
  constructor(
    private modalController: ModalController,
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    setTimeout(() => {
      console.log(this.elements);
      this.elements.map(e => {
        this.sumQuantity(e)
        return e
      })      
      this.row_elements = this.groupByMonthWeek(this.elements)
      console.log(this.row_elements);
    }, 300);
  }


  revert() {
    this.revertSort = !this.revertSort
    setTimeout(() => {
      this.delayRevertSort = !this.delayRevertSort
    }, 230);
    //this.elements = this.elements.reverse()
    this.emitSort.next(this.revertSort)
  }

  sumQuantity(e) {
    //console.log(e.ddt.type, e.quantity);
    if (e.ddt.type == 'OUT') {
      this.sumQntAvailable -= Number(e.quantity)
    } else if (e.ddt.type == 'IN') {
      this.sumQntAvailable += Number(e.quantity)
    }
  }

  async clickVendor(e) {
    ////nav: this.nav(StoresDetailsPage, {  store: item }, { animated: true, direction: 'forward' });
    const modal = await this.modalController.create({
      component: VendorDetailsPage,
      canDismiss: true,
      mode: 'md',
      cssClass: 'newOrderModalForm',
      componentProps: {
        id: e,
        modal: true
        ////nav: this.nav  // Pass nav for IonNav from modal if necessary
      },
      //presentingElement: this.routerOutlet.nativeEl
    })


    modal.onWillDismiss().then((e) => {
      console.log(e);

      if (e.data) {

        //this.myInput.value = "";

      }
    });
    return await modal.present();
  }

  async clickProduct(e) {
    ////nav: this.nav(StoresDetailsPage, {  store: item }, { animated: true, direction: 'forward' });
    const modal = await this.modalController.create({
      component: ProductDetailsPage,
      canDismiss: true,
      mode: 'md',
      cssClass: 'newOrderModalForm',
      componentProps: {
        id: e,
        modal: true
        ////nav: this.nav  // Pass nav for IonNav from modal if necessary
      },
      //presentingElement: this.routerOutlet.nativeEl
    })


    modal.onWillDismiss().then((e) => {
      console.log(e);

      if (e.data) {

        //this.myInput.value = "";

      }
    });
    return await modal.present();
  }

  async clickDdt(e) {
    ////nav: this.nav(StoresDetailsPage, {  store: item }, { animated: true, direction: 'forward' });
    const modal = await this.modalController.create({
      component: DdtDetailsPage,
      canDismiss: true,
      mode: 'md',
      cssClass: 'newOrderModalForm',
      componentProps: {
        ddt: e,
        modal: true
        ////nav: this.nav  // Pass nav for IonNav from modal if necessary
      },
      //presentingElement: this.routerOutlet.nativeEl
    })


    modal.onWillDismiss().then((e) => {
      console.log(e);

      if (e.data) {

        //this.myInput.value = "";

      }
    });
    return await modal.present();
  }

  cancelSearch(event) {
    console.log(event);
    //this.elements = this.groupByMonthWeek(this.row_elements)
    this.enableCompareQuantity = false
  }

  onChangeSearch(event) {
    /* console.log(event.which);
    if ((event.which <= 90 && event.which >= 48) || event.which == 8 || event.which == 46 || event.which == 189)  */{
      // If alphanumeric or back or del or / (slash)

      setTimeout(() => {

        /*  console.log(this.list.nativeElement.children);
            console.log(this.list.nativeElement.children[0].children[0].children);
            console.log(this.list.nativeElement.children[0].children[0].children[1]);
            console.log(this.list.nativeElement.children[0].children[0].children[1].children[0].children[0].children[1]);
            console.log(this.list.nativeElement.children[0].children[0].children[1].children[1].children[0].children[1]);
            console.log(this.list.nativeElement.children[0].children[0].children[1].children[2].children[0].children[1]);

            console.log(this.list.nativeElement);

            console.log('this.list.nativeElement.children[0].children[0].children[1].children',
              this.list.nativeElement.children[0].children[0].children[1].children);

            console.log('this.list.nativeElement.children[0].children[1].children[1].children',
              this.list.nativeElement.children[1].children[0].children[1].children); 
        */


        [...this.list.nativeElement.children].forEach((_e, _i) => {
          console.log(_e);

          [..._e.children[0].children[1].children].forEach((e, i) => {

            /*   
              console.log('----------------------------------------start');
              console.log(e, i);
              console.log('-', e.children);
              console.log('--', e.children[0]);
              console.log('---', e.children[0].children[1].children); 
            */

            if (e.children[0].children[1].children.length == 0) {
              this.renderer.setStyle(e.children[0], 'display', 'none')
            } else {
              this.renderer.setStyle(e.children[0], 'display', 'block')
            }
          });
        });

      }, 200);
    }
  }

  getMontName(number) {
    var months = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"];
    return months[Number(number) - 1];
  }

  resolveQuantity(dir, value) {
    //console.log(-value);
    this.elements = this.elements.filter((e:any) => {
      console.log(e, Number(e.quantity));
      if (dir == 'gt') {
        return Number(e.quantity) >= value
      } else if (dir == 'lt') {
        return Number(e.quantity) <= value
      }
      else
        return ''
    })

    this.filterTerm = ''
    //console.log(this.elements);
  }

  groupByMonthWeek(data) {
    var year, month, week
    return data.reduce(function (acc, obj) {

      var b = obj.created.split(/\D/);

      // Get custom week number, zero padded
      var weekNum = '0' + Math.ceil(b[2] / 7);

      // Add year if not already present
      if (!acc[b[0]]) acc[b[0]] = {};
      year = acc[b[0]];

      // Add month if not already present
      if (!year[b[1]]) year[b[1]] = {};
      month = year[b[1]];

      // Add week if not already present
      if (!month[weekNum]) month[weekNum] = [];

      // Format for sinply find on searchbar
      obj.created = moment(new Date(obj.created)).format('DD/MM/YYYY')

      // Add object to  week
      month[weekNum].push(obj);

      //console.log(acc);

      return acc;
    }, Object.create(null));
  }


}
