// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon-close {
  font-size: 22px;
  position: absolute;
  right: 15px;
  cursor: pointer;
}

.list-discount {
  overflow-y: scroll;
  display: block;
  height: 160px;
  padding: 2px 3px;
  background: #222222;
}

ion-label.lbl {
  padding: 13px 6px;
  display: block;
}

.badge_id_line {
  margin: 0px 6px;
  /* color: #000; */
  background: #0097ab;
  padding: 4px 0px;
  border-radius: 50%;
  min-width: 24px;
  min-height: 24px;
  text-align: center;
}

::ng-deep .OUT .td-type span {
  color: #ff518c !important;
}

::ng-deep .IN .td-type span {
  color: #51ff51 !important;
}

/*  */`, "",{"version":3,"sources":["webpack://./src/app/stores/components/modal-product-history/modal-product-history.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,kBAAA;EACA,WAAA;EACA,eAAA;AACJ;;AAEA;EACI,kBAAA;EACA,cAAA;EACA,aAAA;EACA,gBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,iBAAA;EACA,cAAA;AACJ;;AAEA;EACI,eAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;AACJ;;AAKA;EAGI,yBAAA;AAJJ;;AAOA;EAGI,yBAAA;AANJ;;AASA,KAAA","sourcesContent":[".icon-close {\n    font-size: 22px;\n    position: absolute;\n    right: 15px;\n    cursor: pointer;\n}\n\n.list-discount {\n    overflow-y: scroll;\n    display: block;\n    height: 160px;\n    padding: 2px 3px;\n    background: #222222;\n}\n\nion-label.lbl {\n    padding: 13px 6px;\n    display: block;\n}\n\n.badge_id_line {\n    margin: 0px 6px;\n    /* color: #000; */\n    background: #0097ab;\n    padding: 4px 0px;\n    border-radius: 50%;\n    min-width: 24px;\n    min-height: 24px;\n    text-align: center;\n}\n\n\n\n\n::ng-deep .OUT .td-type span{\n    //background: rgb(58 58 58 / 3%);\n    //opacity: 0.3;\n    color: #ff518c !important;\n}\n\n::ng-deep .IN .td-type span{\n    //background: rgb(58 58 58 / 3%);\n    //opacity: 0.3;\n    color: #51ff51 !important;\n}\n\n/*  */"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
