// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title-row {
  --background: var(--ion-background-color) ;
}

.col-card {
  border-radius: 3px;
  border: 3px solid #151c20;
  margin: 10px 5px 10px 0px;
}

::ng-deep ion-item.item_date,
::ng-deep ion-item.item_date::part(native) {
  max-height: 24px !important;
  min-height: 24px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/orders/order-update/date/date.component.scss"],"names":[],"mappings":"AAAA;EACI,0CAAA;AACJ;;AAEA;EACI,kBAAA;EACA,yBAAA;EACA,yBAAA;AACJ;;AAIA;;EAEI,2BAAA;EACA,2BAAA;AADJ","sourcesContent":[".title-row {\n    --background: var(--ion-background-color)\n}\n\n.col-card{\n    border-radius: 3px;\n    border: 3px solid #151c20;\n    margin: 10px 5px 10px 0px;\n\n}\n\n\n::ng-deep ion-item.item_date ,\n::ng-deep ion-item.item_date::part(native) {\n    max-height: 24px !important;\n    min-height: 24px !important;\n  }\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
