/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable object-shorthand */
/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/semi */
/* eslint-disable @typescript-eslint/naming-convention */

import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { Project } from 'src/app/projects/models/projects';


@Injectable({
  providedIn: 'root'
})
export class DataService {

  private projects: Project[];

  private lastId: number = 20;

  constructor(private http: HttpClient) {}

  getProjects(): Observable<Project[]> {
    if (this.projects) {
      return of(this.projects);
    } else {
      // fetch projects
      return this.http.get<Project[]>('./assets/projects.json')
      .pipe(tap(projects => this.projects = projects));
    }
  }

  getProjectsByCategory(category: string): Observable<Project[]> {
    return this.getProjects().pipe(map(projects => projects.filter(project => project.category == category)));
  }
  getProjectsByStatus(status: string): Observable<Project[]> {
    return this.getProjects().pipe(map(projects => projects.filter(project => project.status == status)));
  }
  getProjectById(id: number): Observable<Project> {
    return this.getProjects().pipe(map(projects => projects.find(project => project.id == id)));
  }

  createProject(project: Project) {
    project.id = this.lastId + 1;
    // increment lastId value
    this.lastId = this.lastId + 1;
    this.projects.reverse();
    this.projects.push(project);
    this.projects.reverse();
  }

  updateProject(project: Project): Project {
    const itemIndex = this.projects.findIndex(item => item.id == project.id);
    this.projects[itemIndex] = project;
    return project;
  }

  deleteProject(id: number): Project {
    const itemIndex = this.projects.findIndex(item => item.id == id);
    return this.projects.splice(itemIndex, 1)[0];
  }
}
