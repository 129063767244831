import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TitleAppService {
  title = new Subject<any>();
  params = new Subject<any>();
  
  constructor() { }
}
