import { Inject, Injectable } from '@angular/core';
import { IonNav } from '@ionic/angular';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class SubMenuService {

  component = new Subject<any>();
  params = new Subject<any>();
  out = new  Subject<any>();
  
  open = new Subject<any>();
  constructor(

    ////private nav: IonNav
  ) { }

/*   goTo(url){
    //nav: this.nav(url)
  } */


}