
import { AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnChanges, OnInit, Output, Renderer2, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { NavController, ModalController, IonNav, IonInfiniteScroll, IonSearchbar, IonContent, IonSegment, IonSegmentButton, IonMenu, MenuController, PopoverController } from '@ionic/angular';

import { animate, query, sequence, stagger, state, style, transition, trigger } from '@angular/animations';

import { TabsService } from 'src/app/services/tabs/tabs.service';

import { ColumnChangesService, ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { SubMenuService } from 'src/app/services/utils/sub-menu/sub-menu.service';

import { InvoicesService } from 'src/app/services/invoices/invoices.service';
import { PaymentInvoiceLineComponent } from '../payment-invoice-line/payment-invoice-line.component';
import { CurrencyPipe, DatePipe } from '@angular/common';

interface PageInfo {
  offset: number;
  pageSize: number;
  limit: number;
  count: number;
}
/**
 * An object used to get page information from the server
 */
export class Page {
  // The number of elements in the page
  size: number = 0;
  // The total number of elements
  totalElements: number = 0;
  // The total number of pages
  totalPages: number = 0;
  // The current page number
  pageNumber: number = 0;
}

class DateOnlyPipe extends DatePipe {
   public override transform(value): any {
    return super.transform(value, 'dd-MM-y');
  }
}
class CurrencyOnlyPipe extends CurrencyPipe {
   public override transform(value, digitInfo, locale): any {
    return super.transform(value, 'EUR', 'symbol', digitInfo, locale);
  }
}

const listAnimation = trigger('listAnimation', [
  transition('* <=> *', [
    query(':enter',
      [style({ opacity: 0 }), stagger('180ms', animate('300ms ease-out', style({ opacity: 1 })))],
      { optional: true }
    ),
    query(':leave',
      animate('200ms', style({ opacity: 0 })),
      { optional: true }
    )
  ])
]);

@Component({
  selector: 'app-list-invoices',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListInvoiceComponent implements OnInit, AfterViewInit, OnChanges {


  n = 0
  public data: any; // Data;
  public columns: any;
  public rows: any;

  rowIsNotExpanded = true

  editing = {};

  selected = [];

  funder = [];
  calculated = [];
  pending = [];
  ColumnMode: ColumnMode
  expanded: any = {};
  timeout: any;
  total_order = 0;
  currentSegment: string = "all";
  filterTerm

  @Input() projectId;
  @Input() cache;
  @Input() listItems;
  filterListItems
  @Output() clicked = new EventEmitter<any>();


  @ViewChild('segment') segment: IonSegment;
  @ViewChild('btn_segment') btn_segment: IonSegmentButton;
  @ViewChild('myTable') table: DatatableComponent;
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  @ViewChild(IonContent) content: IonContent;
  @ViewChild('invoicedTemplate') invoicedTemplate: TemplateRef<any>;

  SelectionType = SelectionType;

  fab: boolean = false;
  customAlertOptions: any = {
    header: 'List groups',
    //subHeader: 'Select group for user',
    message: 'Select group for user',
    translucent: true
  };

  searching = false
  res: any = []
  order_status = [];
  order_types = [];
  order_categories = [];

  total_page = 0;
  page_number = 1;
  tmp_page_number = 1;
  page_limit = 30;
  tmp_offset = 0;
  tmp_count = 0;
  isSearch: boolean = false;
  isLoad: boolean = false;

  queryString = {
    search_by: '',
    search_data: ''
  };

  queryFilter = {
    search_by: 'sort',
    search_data: [{ prop: 'priority.id', dir: 'asc' }]
  };


  totalElements: number;
  pageNumber: number;

  isLoading = 0;

  loadTable = false

  constructor(
    private menu: MenuController,
    private subMenu: SubMenuService,
    private cd: ChangeDetectorRef,
    private navCtrl: NavController,
    private api: InvoicesService,
    private modalController: ModalController,
    //private nav: IonNav,
    private tabService: TabsService,
    private popoverController: PopoverController,
    private renderer: Renderer2,
    private columnChangesService: ColumnChangesService,
    private el: ElementRef
  ) {

    console.log('list this.listItems constructor');

    this.columns = [
      { prop: 'status.name', name: 'Status', sortable: true, width: 120, minWidth: 120, maxWidth: 120, cellClass: 'td-status', headerClass: 'th-status' },
      { prop: 'code', width: 195, minWidth: 195, sortable: true },
      {
        prop: 'cost',
        pipe: new CurrencyOnlyPipe('en-US', '€'), sortable: true, minWidth: 155, maxWidth: 155,/*  summaryFunc: () => this.caclulateSumm('€'), */
      },
      { prop: 'type.name', sortable: true, name: 'Type', maxWidth: 180 },
      //{ prop: 'creator.email', name: 'Email', width: 190, minWidth: 190, maxWidth: 230 },
      { prop: 'effective_date', sortable: true, name: 'Date', maxWidth: 240, minWidth: 240, width: 240, pipe: new DateOnlyPipe('en-US') },
      { prop: 'fiscal_year', sortable: true, name: 'Fiscal year', maxWidth: 200, summaryFunc: () => null },
      { prop: 'extra', name: 'extra', maxWidth: 200 },
      { prop: 'customer.name', sortable: true, name: 'Customer', maxWidth: 200 },
      { prop: 'vendor.name', sortable: true, name: 'Vendor', maxWidth: 200 },
      //{ prop: 'deleted', maxWidth: 70, cellClass: 'td-deleted', headerClass: 'th-deleted' },
    ]

  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(event) {
    /**
    * @description la migliore soluzione per risolvere il problema dopo aver scrollato in una sezione
    * @fix https://github.com/swimlane/ngx-datatable/issues/861/#issuecomment-733851318
    */
    console.log(event);

    //    setTimeout(() => {
    //this.table._innerWidth = 0; //event.currentTarget.innerWidth;
    //this.table.bodyComponent.offsetY = 0;
    this.table.recalculate()
    //    }, 200);
  }

  displayCheck(row) {
    return row.active !== false;
  }

  ngOnInit() {
    console.log('list this.listItems ngOnInit');

    //this.listItems = [];
    this.fab = true

    //this.loadProjects(this.queryString);
    //this.getthis.listItems(false, "", this.queryString)

    this.queryString = localStorage.getItem('filter_search_invoices') ? JSON.parse(localStorage.getItem('filter_search_invoices')) : { search_by: '', search_data: 'all' }

    this.total_order = 0;
    this.total_page = 0;
    this.page_number = 1;

    this.listItems = []
    this.cache = {}


    this.getInvoicesMeta()
    setTimeout(() => {
      this.onScroll(0, null)
    }, 1000);
  }

  ngAfterViewInit(): void {

    console.log('list listItems ngAfterViewInit', this.pageNumber);
    console.log(this.listItems);
    console.log(this.projectId);
    console.log(this.cache);

  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);

  }

  openMenu() {
    this.menu.open('admin')
    this.subMenu.params.next({
      title: 'Filter',
      icon: 'funnel-outline',
      accordions: [
        {
          target: '',
          color: '',
          icon: 'list-outline',
          title: 'Status'
        },
        {
          target: '',
          color: '',
          icon: 'list-outline',
          title: 'Priority'
        },
        {
          target: '',
          color: '',
          icon: 'list-outline',
          title: 'Category'
        },
        {
          target: '',
          color: '',
          icon: 'list-outline',
          title: 'Type'
        }
      ]
    })
  }

  checkedSegment(event) {
    //console.log(this.queryString.search_data == event.id, this.queryString.search_data, event.id);
    //if (this.queryString.search_data == event.id)

    return this.queryString.search_data == event.id
  }


  /**
   * @description after click segment switch for selected status
   * @param data = this.queryString LocalStorage
   */
  loadProjects(data: any) {

    if (!this.page_number)
      return

    if (this.projectId)
      this.getInvoices(data)
    else
      this.getInvoices(data)

    return
    if (data.search_data === 'all') { //category

      if (this.projectId)
        this.getInvoices(data)
      else
        this.getInvoices(data)


    } else {

      if (this.projectId)
        this.getInvoices(data)
      else
        this.getInvoices(data)

    }
  }


  concateQuery(search) {
    console.log(search);

    let url = '?page=' + this.page_number + '&items=' + this.page_limit;
    let query = '';


    if (search && search != '' && search.search_data != 'all') {
      query += '&' + search.search_by + '=';
      query += search.search_data;
      url += query

    } else {

    }

    console.log(url);
    return url
  }

  /**
   * @description Tigger on click on segment filter on top view 
   * @param search_by 
   * @param search_data 
   * @param btn_segment template ref of element in segment
   */
  filterInvoices(search_by, search_data, btn_segment) {


    this.total_order = 0;
    this.total_page = 0;
    this.page_number = 1;

    this.listItems = []
    this.cache = {}
    const selectedStatus = search_data.value;
    this.currentSegment = selectedStatus;

    this.queryString = { search_by, search_data: this.currentSegment }
    localStorage.setItem('filter_search_invoices', JSON.stringify(this.queryString))
    this.loadProjects(this.queryString);

  }

  /**
   * @description Get order metadata
   */
  getInvoicesMeta() {



  }


  triggerColumnChangeDetection(): void {
    this.columnChangesService.onInputChange();
  }




  onDetailToggle(event) {
    console.log('Detail Toggled', event);
  }

  toggleExpandRow(row) {
    console.log('Toggled Expand Row!', row);
    this.table.rowDetail.collapseAllRows();
    this.table.rowDetail.toggleExpandRow(row);
  }

  /**
   * @description Aggiunge elementi allo scroll incrementando le pagina per l'api 
   * @param pageInfo ritorna dall'evento default con interfaccia dichiarata in alto PageInfo
   * @returns La paginazione fino al confronto pagine totati pagina attuale interrogata
   */
  setPage(pageInfo: PageInfo) {
    //console.log('pageInfo', this.n);
    //console.log(pageInfo, this.filterTerm);
    //console.log(this.cache);
    this.n++;
    if (this.filterTerm != '')
      return

    // Current page number is determined by last call to setPage
    // This is the page the UI is currently displaying
    // The current page is based on the UI pagesize and scroll position
    // Pagesize can change depending on browser size
    this.pageNumber = pageInfo.offset;

    // Calculate row offset in the UI using pageInfo
    // This is the scroll position in rows
    const rowOffset = pageInfo.offset * pageInfo.pageSize;

    // When calling the server, we keep page size fixed
    // This should be the max UI pagesize or larger
    // This is not necessary but helps simplify caching since the UI page size can change

    const page = new Page();
    page.size = this.page_limit;
    page.pageNumber = Math.floor(rowOffset / page.size);

    //console.log(page.pageNumber);
    //console.log(this.cache);

    // We keep a index of server loaded pages so we don't load same data twice
    // This is based on the server page not the UI

    /* if (this.cache[page.pageNumber]) return;
    this.cache[page.pageNumber] = true;

    if (this.n <= 1) { this.cache = {} }; // esclude l'errore del conteggio pagina in cache

    // Chiamo l'api vericicando il tot elementi, le pagine e i risultati per pagina
    this.loadProjects(this.queryString) */


  }


  onScroll(offsetY: number, event) {
    //console.log(event);

    if (event && event.offsetY == 0) return

    // total height of all rows in the viewport
    const viewHeight = this.el.nativeElement.getBoundingClientRect().height - 50;


    // check if we scrolled to the end of the viewport
    if (this.isLoading <= 0 && offsetY + viewHeight >= this.listItems.length * 35) {
      // total number of results to load
      let limits = this.page_limit;

      // check if we haven't fetched any results yet
      if (this.listItems.length === 0) {
        // calculate the number of rows that fit within viewport
        const pageSize = Math.ceil(viewHeight / 35);

        // change the limit to pageSize such that we fill the first page entirely
        // (otherwise, we won't be able to scroll past it)
        limits = Math.max(pageSize, this.page_limit);
      }

      if (this.cache[this.page_number]) return;
      this.cache[this.page_number] = true;

      this.loadProjects(this.queryString)
    }
  }


  sorted(columnProp: string, direction: string) {
    console.log(columnProp, direction);
    if (columnProp == 'priority.name') {
      columnProp = 'priority.id'
    }

    this.queryFilter = { search_by: 'sort', search_data: [{ prop: columnProp, dir: direction }] }
    localStorage.setItem('filter_sort_invoices', JSON.stringify({ search_by: 'sort', search_data: [{ prop: columnProp, dir: direction }] }))

    console.log(this.queryFilter);
  }


  onSelect({ selected }) {
    console.log('Select Event', selected, this.selected);
  }

  /**
   * @description onClick on row open order details
   * @param event pass obj row clicked
   */
  onActivate(line) {
    /* if (this.table.rowDetail && event.type == 'click') {
      this.table.rowDetail.toggleExpandRow(event.row);
      console.log('Activate Event', event);
    } */

    /*  if (event.type == 'click') {
       console.log('Activate line', event);
       //this.openOrderModal(event.row) 
       this.clicked.emit(event);
     } */
    if (line) {
      console.log('Activate line', line);
      //this.openOrderModal(event.row) 
      this.clicked.emit({ row: line, type: 'click' });
    }


  }

  setExpandStatus(expanded: any): void {
    console.log('setExpandStatus Event', expanded);
  }



  async openPopoverAdvanceLine(row, event) {
    console.log(row, event);


    const popover = await this.popoverController.create({
      component: PaymentInvoiceLineComponent,
      //event: event,
      backdropDismiss: true,
      cssClass: 'popover-setting',
      componentProps: {
        event: event,
        line: row,
        type: 'payment'
      },
      translucent: true
    });

    popover.onDidDismiss().then((e) => {
      console.log(e);
      if (!e.data) {
        console.log('no modify');
        return
      } else {

        console.log(e.data,);



      }
    });

    return await popover.present();
    /** Sync event from popover component */
  }


  onGetRowClass = (row) => {
    //console.log(row);
    // id 3 is Delete for Status Order
    if (row.status.id === 3) {
      return 'deleted';
    } else if (row.status.id === 2) {
      return 'request';
    } else if (row.status.id === 1) {
      return 'draft';
    } else if (row.status.id === 4) {
      return 'order';
    }
    else
      return ''
  }

  onChangeSearch(event, item?) {

    console.log(this.filterTerm, event, item, this.table);
    setTimeout(() => {
      this.table.bodyComponent.offsetY = 0
      this.table.recalculateColumns()

      /*       this.pageNumber = pageInfo.offset;
      
            // Calculate row offset in the UI using pageInfo
            // This is the scroll position in rows
            const rowOffset = pageInfo.offset * pageInfo.pageSize;
      
            // When calling the server, we keep page size fixed
            // This should be the max UI pagesize or larger
            // This is not necessary but helps simplify caching since the UI page size can change
            const page = new Page();
            page.size = this.page_limit;
            page.pageNumber = Math.floor(rowOffset / page.size); */


    }, 150);



  }
  updateFilter(event) {
    return
    const val = event.detail.value.toLowerCase();

    console.log(event);

    // filter our data
    const temp = this.listItems.filter(function (d) {
      console.log(d.code, val);

      return d.code.toLowerCase().indexOf(val) !== -1 || !val;
    });

    console.log(val, temp);

    if (val == '') {
      // update the rows
      this.filterListItems = [...this.listItems];
      this.table.offset = this.tmp_offset;
      this.table.count = this.tmp_count;
      this.totalElements = this.table.count;
      this.page_number = this.tmp_page_number;
      this.pageNumber = this.page_number;
    } else {
      // update the rows
      this.filterListItems = [...temp];
      this.tmp_offset = this.table.offset;
      this.table.offset = 0;
      this.tmp_count = this.table.count;
      this.table.count = this.filterListItems.length;
      this.totalElements = this.filterListItems.length;
      this.tmp_page_number = this.page_number;
      this.page_number = null;
      this.pageNumber = 1;     // Whenever the filter changes, always go back to the first page
    }
  }

  /**
   * @description ritorna la somma delle colonne che gli si assegna questa funzione
   * @param n 
   * @returns somma della colonna in "rowSummary" template
   */
  caclulateSumm(n?) {
    console.log();

    this.total_order = 0;
    this.listItems.map((item) => {
      this.total_order += item.cost;
      //this.currencyCode = item.currency;
      //console.log(this.total_order);
    });
    return this.total_order.toFixed(2) + ' ' + n;
  }




  getInvoices(url) {


    if (url && url != '') {
      url = this.concateQuery(url)
    }

    console.log('load api list');
    this.isLoading++
    this.api.getInvoicesList(url).subscribe(data => {
      console.log(data, data.results)
      console.log(data.total_items, data.num_pages, (this.page_number));

      this.totalElements = data.total_items;

      if (data.num_pages >= (this.page_number)) {
        // Update total count

        // Create array to store data if missing
        // The array should have the correct number of with "holes" for missing data
        if (!this.listItems) {
          this.listItems = new Array<any>(this.totalElements || 0);
        }

        // Calc starting row offset
        // This is the position to insert the new data
        const start = this.page_number * Number(data.items_per_page);

        // Copy existing data
        const rows = [...this.listItems];

        // Insert new rows into correct position
        rows.splice(start, Number(data.items_per_page), ...data.results);

        this.listItems = [...rows];

        if (this.page_number == 1) {
          /**
          * @description la migliore soluzione per risolvere il problema dopo aver scrollato in una sezione
          * @fix https://github.com/swimlane/ngx-datatable/issues/861/#issuecomment-733851318
          */
          setTimeout(() => {
            this.table.bodyComponent.offsetX = 0;
            this.table.bodyComponent.offsetY = 0;
            this.table.headerComponent.offsetX = 0;
            this.table.recalculateColumns();
            this.listItems = [...this.listItems];
          }, 100);
        }
        // Decrement the counter of pending API calls
        this.page_number++;
        setTimeout(() => {
          this.isLoading--;
        }, 900);
      } else {
        console.log('return if n_page >= page_number', this.page_number);
        this.isLoading--;

      }
    });
  }

  getInvoicesListById(url) {


    if (url && url != '') {
      url = this.concateQuery(url)
    }

    console.log('load api list by project id');
    this.isLoading++
    this.api.getInvoiceById(this.projectId).subscribe(data => {
      console.log(data, data.results)
      console.log(data.total_items, data.num_pages, (this.page_number));

      this.totalElements = data.total_items;

      if (data.num_pages >= (this.page_number)) {

        // Update total count

        // Create array to store data if missing
        // The array should have the correct number of with "holes" for missing data
        if (!this.listItems) {
          this.listItems = new Array<any>(this.totalElements || 0);
        }

        // Calc starting row offset
        // This is the position to insert the new data
        const start = this.page_number * Number(data.items_per_page);

        // Copy existing data
        const rows = [...this.listItems];

        // Insert new rows into correct position
        rows.splice(start, Number(data.items_per_page), ...data.results);

        this.listItems = [...rows];

        console.log(this.page_number);

        if (this.page_number == 1) {
          /**
           * @description la migliore soluzione per risolvere il problema dopo aver scrollato in una sezione
           * @fix https://github.com/swimlane/ngx-datatable/issues/861/#issuecomment-733851318
           */
          setTimeout(() => {
            this.table.bodyComponent.offsetX = 0;
            this.table.bodyComponent.offsetY = 0;
            this.table.headerComponent.offsetX = 0;
            this.table.recalculateColumns();
            this.listItems = [...rows];
          }, 100);
        }
        // Decrement the counter of pending API calls
        this.page_number++;
        setTimeout(() => {
          this.isLoading--;
        }, 900);
      } else {
        console.log('return if n_page >= page_number', this.page_number);
        this.isLoading--;

      }
    });
  }



  _getInvoices(url) {


    if (url && url != '') {
      url = this.concateQuery(url)
    }

    console.log('load api list');

    this.isLoading++;
    this.api.getInvoicesList(url).subscribe(data => {
      this.totalElements = data.total_items;

      if (data.num_pages >= (this.page_number)) {

        console.log(data, data.results)
        console.log(data.total_items, data.num_pages, (this.page_number));

        const rows = [...this.listItems, ...data.results];
        console.log(rows);

        setTimeout(() => {
          this.table.bodyComponent.offsetX = 0;
          this.table.bodyComponent.offsetY = 0;
          this.table.headerComponent.offsetX = 0;
          this.table.recalculateColumns();
          this.listItems = [...rows];
        }, 200);


        this.isLoading--
        this.page_number++;

      }
    });
  }

  _getInvoicesListById(url) {


    if (url && url != '') {
      url = this.concateQuery(url)
    }

    console.log('load api list by project id');
    this.api.getInvoiceById(this.projectId).subscribe(data => {
      console.log(data, data.results)
      console.log(data.total_items, data.num_pages, (this.page_number));


      const rows = [...this.listItems, ...data.results];
      setTimeout(() => {
        this.table.bodyComponent.offsetX = 0;
        this.table.bodyComponent.offsetY = 0;
        this.table.headerComponent.offsetX = 0;
        this.table.recalculateColumns();
        this.listItems = rows;
        this.listItems = [...this.listItems];
      }, 100);


      this.isLoading--
      this.page_number++;

    });
  }
}



