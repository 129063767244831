/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

(function(root, factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define(['exports', 'echarts'], factory);
    } else if (
        typeof exports === 'object' &&
        typeof exports.nodeName !== 'string'
    ) {
        // CommonJS
        factory(exports, require('echarts/lib/echarts'));
    } else {
        // Browser globals
        factory({}, root.echarts);
    }
})(this, function(exports, echarts) {
    var log = function(msg) {
        if (typeof console !== 'undefined') {
            console && console.error && console.error(msg);
        }
    };
    if (!echarts) {
        log('ECharts is not Loaded');
        return;
    }

    var theme = 
    
{
    "color": [
        "#3fb1e3",
        "#6be6c1",
        "#626c91",
        "#a0a7e6",
        "#c4ebad",
        "#96dee8"
    ],
    "backgroundColor": "rgba(0,0,0,0)",
    "textStyle": {},
    "title": {
        "textStyle": {
            "color": "#666666"
        },
        "subtextStyle": {
            "color": "#999999"
        }
    },
    "line": {
        "itemStyle": {
            "borderWidth": "2"
        },
        "lineStyle": {
            "width": "3"
        },
        "symbolSize": "8",
        "symbol": "emptyCircle",
        "smooth": false
    },
    "radar": {
        "itemStyle": {
            "borderWidth": "2"
        },
        "lineStyle": {
            "width": "3"
        },
        "symbolSize": "8",
        "symbol": "emptyCircle",
        "smooth": false
    },
    "bar": {
        "itemStyle": {
            "barBorderWidth": 0,
            "barBorderColor": "rgba(204,204,204,0.1)"
        }
    },
    "pie": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "rgba(204,204,204,0.1)"
        }
    },
    "scatter": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "rgba(204,204,204,0.1)"
        }
    },
    "boxplot": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "rgba(204,204,204,0.1)"
        }
    },
    "parallel": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "rgba(204,204,204,0.1)"
        }
    },
    "sankey": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "rgba(204,204,204,0.1)"
        }
    },
    "funnel": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "rgba(204,204,204,0.1)"
        }
    },
    "gauge": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "rgba(204,204,204,0.1)"
        }
    },
    "candlestick": {
        "itemStyle": {
            "color": "#e6a0d2",
            "color0": "transparent",
            "borderColor": "#e6a0d2",
            "borderColor0": "#3fb1e3",
            "borderWidth": "2"
        }
    },
    "graph": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "rgba(204,204,204,0.1)"
        },
        "lineStyle": {
            "width": "1",
            "color": "#cccccc"
        },
        "symbolSize": "8",
        "symbol": "emptyCircle",
        "smooth": false,
        "color": [
            "#3fb1e3",
            "#6be6c1",
            "#626c91",
            "#a0a7e6",
            "#c4ebad",
            "#96dee8"
        ],
        "label": {
            "color": "#ffffff"
        }
    },
    "map": {
        "itemStyle": {
            "areaColor": "#eeeeee",
            "borderColor": "#aaaaaa",
            "borderWidth": 0.5
        },
        "label": {
            "color": "#ffffff"
        },
        "emphasis": {
            "itemStyle": {
                "areaColor": "rgba(63,177,227,0.25)",
                "borderColor": "#3fb1e3",
                "borderWidth": 1
            },
            "label": {
                "color": "#3fb1e3"
            }
        }
    },
    "geo": {
        "itemStyle": {
            "areaColor": "#eeeeee",
            "borderColor": "#aaaaaa",
            "borderWidth": 0.5
        },
        "label": {
            "color": "#ffffff"
        },
        "emphasis": {
            "itemStyle": {
                "areaColor": "rgba(63,177,227,0.25)",
                "borderColor": "#3fb1e3",
                "borderWidth": 1
            },
            "label": {
                "color": "#3fb1e3"
            }
        }
    },
    "categoryAxis": {
        "axisLine": {
            "show": true,
            "lineStyle": {
                "color": "rgba(204,204,204,0.01)"
            }
        },
        "axisTick": {
            "show": false,
            "lineStyle": {
                "color": "#333"
            }
        },
        "axisLabel": {
            "show": true,
            "color": "#999999"
        },
        "splitLine": {
            "show": true,
            "lineStyle": {
                "color": [
                    "rgba(255,255,255,0.04)"
                ]
            }
        },
        "splitArea": {
            "show": false,
            "areaStyle": {
                "color": [
                    "rgba(250,250,250,0.05)",
                    "rgba(200,200,200,0.02)"
                ]
            }
        }
    },
    "valueAxis": {
        "axisLine": {
            "show": true,
            "lineStyle": {
                "color": "rgba(204,204,204,0.01)"
            }
        },
        "axisTick": {
            "show": false,
            "lineStyle": {
                "color": "#333"
            }
        },
        "axisLabel": {
            "show": true,
            "color": "#999999"
        },
        "splitLine": {
            "show": true,
            "lineStyle": {
                "color": [
                    "rgba(255,255,255,0.04)"
                ]
            }
        },
        "splitArea": {
            "show": false,
            "areaStyle": {
                "color": [
                    "rgba(250,250,250,0.05)",
                    "rgba(200,200,200,0.02)"
                ]
            }
        }
    },
    "logAxis": {
        "axisLine": {
            "show": true,
            "lineStyle": {
                "color": "rgba(204,204,204,0.01)"
            }
        },
        "axisTick": {
            "show": false,
            "lineStyle": {
                "color": "#333"
            }
        },
        "axisLabel": {
            "show": true,
            "color": "#999999"
        },
        "splitLine": {
            "show": true,
            "lineStyle": {
                "color": [
                    "rgba(255,255,255,0.04)"
                ]
            }
        },
        "splitArea": {
            "show": false,
            "areaStyle": {
                "color": [
                    "rgba(250,250,250,0.05)",
                    "rgba(200,200,200,0.02)"
                ]
            }
        }
    },
    "timeAxis": {
        "axisLine": {
            "show": true,
            "lineStyle": {
                "color": "rgba(204,204,204,0.01)"
            }
        },
        "axisTick": {
            "show": false,
            "lineStyle": {
                "color": "#333"
            }
        },
        "axisLabel": {
            "show": true,
            "color": "#999999"
        },
        "splitLine": {
            "show": true,
            "lineStyle": {
                "color": [
                    "rgba(255,255,255,0.04)"
                ]
            }
        },
        "splitArea": {
            "show": false,
            "areaStyle": {
                "color": [
                    "rgba(250,250,250,0.05)",
                    "rgba(200,200,200,0.02)"
                ]
            }
        }
    },
    "toolbox": {
        "iconStyle": {
            "borderColor": "#999999"
        },
        "emphasis": {
            "iconStyle": {
                "borderColor": "#666666"
            }
        }
    },
    "legend": {
        "textStyle": {
            "color": "#999999"
        }
    },
    "tooltip": {
        "axisPointer": {
            "lineStyle": {
                "color": "#cccccc",
                "width": 1
            },
            "crossStyle": {
                "color": "#cccccc",
                "width": 1
            }
        }
    },
    "timeline": {
        "lineStyle": {
            "color": "#626c91",
            "width": 1
        },
        "itemStyle": {
            "color": "#626c91",
            "borderWidth": 1
        },
        "controlStyle": {
            "color": "#626c91",
            "borderColor": "#626c91",
            "borderWidth": 0.5
        },
        "checkpointStyle": {
            "color": "#3fb1e3",
            "borderColor": "#3fb1e3"
        },
        "label": {
            "color": "#626c91"
        },
        "emphasis": {
            "itemStyle": {
                "color": "#626c91"
            },
            "controlStyle": {
                "color": "#626c91",
                "borderColor": "#626c91",
                "borderWidth": 0.5
            },
            "label": {
                "color": "#626c91"
            }
        }
    },
    "visualMap": {
        "color": [
            "#2a99c9",
            "#afe8ff"
        ]
    },
    "dataZoom": {
        "backgroundColor": "rgba(255,255,255,0)",
        "dataBackgroundColor": "rgba(222,222,222,1)",
        "fillerColor": "rgba(114,230,212,0.25)",
        "handleColor": "#cccccc",
        "handleSize": "100%",
        "textStyle": {
            "color": "#999999"
        }
    },
    "markPoint": {
        "label": {
            "color": "#ffffff"
        },
        "emphasis": {
            "label": {
                "color": "#ffffff"
            }
        }
    }
}

    

    echarts.registerTheme('cpj', theme);
});