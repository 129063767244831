// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-steps-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px;
  margin-top: 32px;
}

.step-bubble {
  padding: 10px;
  border: 1px solid #3880ff;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: white;
  font-size: 1em;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}

.step-divider {
  height: 2px;
  background: #3880ff;
  flex: 1;
  margin: 0px 10px;
}

.step-current, .step-complete {
  background: #3880ff;
  color: white;
}

.step-incomplete {
  cursor: not-allowed;
  pointer-events: none;
}`, "",{"version":3,"sources":["webpack://./src/app/components/steps/steps.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,aAAA;EACA,gBAAA;AACJ;;AAEA;EACI,aAAA;EACA,yBAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;EACA,YAAA;EACA,cAAA;EACA,eAAA;EACA,yBAAA;UAAA,iBAAA;AACJ;;AAEA;EACI,WAAA;EACA,mBAAA;EACA,OAAA;EACA,gBAAA;AACJ;;AAEA;EACI,mBAAA;EACA,YAAA;AACJ;;AAEA;EACI,mBAAA;EACA,oBAAA;AACJ","sourcesContent":[".form-steps-container {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 32px;\n    margin-top: 32px;\n}\n\n.step-bubble {\n    padding: 10px;\n    border: 1px solid #3880ff;\n    width: 35px;\n    height: 35px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    border-radius: 50%;\n    color: white;\n    font-size: 1em;\n    cursor: pointer;\n    user-select: none;\n}\n\n.step-divider {\n    height: 2px;\n    background: #3880ff;\n    flex: 1;\n    margin: 0px 10px;\n}\n\n.step-current, .step-complete {\n    background: #3880ff;\n    color: white;\n}\n\n.step-incomplete {\n    cursor: not-allowed;\n    pointer-events: none;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
