import { Component, Input, HostBinding, OnInit } from '@angular/core';

@Component({
  selector: 'app-image-skeleton',
  templateUrl: './image-skeleton.component.html',
  styleUrls: ['./image-skeleton.component.scss']
})
export class ImageSkeletonComponent {

  _src = '';
  _alt = '';

  @HostBinding('class.img-loaded') imageLoaded = false;

  @Input()
  set src(val: string) {
    this._src = (val !== undefined && val !== null) ? val : '';
  }

  @Input()
  set alt(val: string) {
    this._alt = (val !== undefined && val !== null) ? val : '';
  }

  constructor() { }

  _imageLoaded() {
    this.imageLoaded = true;
  }
}