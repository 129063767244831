import { map } from 'rxjs/operators';
import { IonicSelectableComponent } from 'ionic-selectable';
import { Subscription } from 'rxjs';
import { Product } from 'src/app/products/models/product.model';
import { Component, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { ModalController, ToastController } from '@ionic/angular';
import { OrdersService } from 'src/app/services/orders/orders.service';
import { ProductsService } from 'src/app/services/products/products.service';
import { NewProductComponent } from 'src/app/products/new-product/new-product.component';
import { NewServiceComponent } from 'src/app/products/new-service/new-service.component';

@Component({
  selector: 'app-modal-order-meta',
  templateUrl: './modal-order-meta.component.html',
  styleUrls: ['./modal-order-meta.component.scss'],
})
export class ModalOrderCreateUpdateComponent {
  @Input() order: any = '';
  @Input() type_meta: any = '';
  @Input() type_form: any = '';
  @Input() data: any = '';
  @Input() canEdit: any = false;
  @ViewChild('createForm') createForm: FormGroupDirective;

  createGroupForm: FormGroup;

  products: any = [];
  size = 20;
  page_products: any;
  numPage_products: any;
  onSearchSelector: any;
  onSelectProduct: any;
  onPushProducts: any;

  services: any = [];
  page_services: any;
  numPage_services: any;
  onSelectServices: any;
  onPushServices: any;
  response = '';
  selectedSegment: any;
  actualForm: any;
  /** Selectable Search */
  product: Product;
  productSubscription: Subscription;
  serviceSubscription: Subscription;
  isIconSize = false;
  /* */
  chgToolbar = '';
  iconName = 'chevron-up-circle-outline';
  showConfirm = true;
  editModal = true;

  constructor(
    private orderApi: OrdersService,
    private modalController: ModalController,
    private fb: FormBuilder,
    private productApi: ProductsService,
    private toastController: ToastController,
    private renderer: Renderer2
  ) {
    let page_product = 1;
    let url = '?page=' + page_product + '&items=' + 20;

    // this.productApi.getProductsList(url).subscribe(e => {
    //   console.log(e);
    //   this.products = e.results;
    // })
    // this.productApi.getServicesList(url).subscribe(e => {
    //   console.log(e);
    //   this.services = e.results;
    // })
  }

  onChangeProduct(event) {
    console.log(event.detail.value);
    let product = this.products.filter((e) => e.id === event.detail.value);
    console.log(product);

    if (product.length > 0) {
      this.createGroupForm.controls['description'].setValue(
        product[0].description
      );
      this.createGroupForm.controls['cost'].setValue(product[0].default_price);
    }

    console.log(this.createGroupForm);
    console.log(this.createGroupForm.valid);
  }

  createBaseForm() {
    // Generete FormGroup Create Order
    this.createGroupForm = this.fb.group({
      order_id: this.fb.control(
        this.order ? this.order.id : 0,
        Validators.required
      ),
      product_id: this.fb.control('', Validators.required),
      service_id: this.fb.control('', Validators.required),
      cost: this.fb.control(0, Validators.required),
      description: this.fb.control(''),
      udm_id: this.fb.control(1),
      qty: this.fb.control(1, [
        Validators.required,
        Validators.pattern('^[0-9]*$'),
        Validators.min(1),
      ]),
      //created: this.fb.control(moment(Date.now()).format('DD-MM-YYYY'), Validators.required),
      //type_select: this.fb.control('', Validators.required),
    });
    console.log(this.createGroupForm);

    this.createGroupForm.valueChanges.subscribe((values) => {
      this.onFormValuesChanged(values);
    });
  }

  onFormValuesChanged(values) {
    console.log(values);
    this.actualForm = values;
  }

  createUpdateForm() {
    // Generete FormGroup Create Order
    this.editModal = false;
    if (this.data?.product) {
      this.selectedSegment = 'Prodotto';
    } else if (this.data?.service) {
      this.selectedSegment = 'Servizio';
    } else {
      this.selectedSegment = '';
    }

    this.createGroupForm = this.fb.group({
      id: this.fb.control(this.data.id, Validators.required),
      order_id: this.fb.control(this.order.id, Validators.required),
      product_id: this.fb.control(this.data.product?.id || 0),
      service_id: this.fb.control(this.data.service?.id || 0),
      cost: this.fb.control(this.data.cost, Validators.required),
      description: this.fb.control(this.data.description),
      udm_id: this.fb.control(this.data.udm ? this.data.udm.id : 1),
      qty: this.fb.control(this.data.qty, [
        Validators.required,
        Validators.pattern('^[0-9]*$'),
        Validators.min(1),
      ]),

      //type_select: this.fb.control(this.data.created, Validators.required),
    });
    if (this.data?.product) {
      this.createGroupForm.controls['product_id'].setValue(
        this.data.product?.id
      );
    } else if (this.data?.service) {
      this.createGroupForm.get('qty')?.disable();
      this.createGroupForm.controls['service_id'].setValue(
        this.data.service?.id
      );
    }

    console.log(this.createGroupForm.controls['value']);

    this.createGroupForm.valueChanges.subscribe((values) => {
      this.onFormValuesChanged(values);
    });

    //console.log(this.order_orders_createForm);
  }

  createBaseForm2() {
    // Generete FormGroup Create Order
    this.createGroupForm = this.fb.group({
      order_id: this.fb.control(this.order.id, Validators.required),
      content: this.fb.control('', Validators.required),
    });
    //console.log(this.order_orders_createForm);
  }

  createUpdateForm2() {
    // Generete FormGroup Create Order
    this.createGroupForm = this.fb.group({
      id: this.fb.control(this.data.id, Validators.required),
      content: this.fb.control(this.data.content, Validators.required),
    });
    //console.log(this.order_orders_createForm);
  }

  getOrderMeta() {
    let page_product = 1;
    let url = '?page=' + page_product + '&items=' + 20;

    this.productApi.getProductsList(url).subscribe((e) => {
      console.log(e);
      this.products = e.results;
    });
  }

  ionViewWillEnter() {
    console.log(this.order, this.type_form, this.type_meta, this.data);
    console.log(this.data);

    this.response = '';
    this.getOrderMeta();

    if (this.type_form == 'create' || !this.type_form) {
      if (this.type_meta == 'line' || !this.type_meta) {
        this.createBaseForm();
      } else if (this.type_meta == 'legal') {
        this.createBaseForm2();
      } else {
        return;
      }
    } else {
      if (this.type_meta == 'line') {
        if (this.data) this.createUpdateForm();
      } else if (this.type_meta == 'legal') {
        if (this.data) this.createUpdateForm2();
      } else {
        return;
      }
    }
  }

  Nsubmit(form, type_meta, type_form) {
    // Map Address
    /* form.address = this.addressMap(form.address)
    // Add New Contact
    this.addNewContact(form)
    // Add New Address
    this.addNewAddress(form) */

    //Call API
    console.log(form, this.order, this.type_meta, this.type_form);
    //form.product_id = this.product.id

    if (this.type_form == 'create') {
      if (this.type_meta == 'line') {
        this.createLine(form);
      } else if (this.type_meta == 'legal') {
        this.createLegal(form);
      } else {
        return;
      }
    } else {
      if (this.type_meta == 'line') {
        this.updateLine(form);
      } else if (this.type_meta == 'legal') {
        this.order_orderlegal_update(form);
      } else {
        return;
      }
    }
  }

  updateLine(data) {
    console.log(data);
    console.log(this.order);

    if (this.selectedSegment == 'Prodotto') {
      this.data.service = 0;
      this.createGroupForm
        .get('product_id')
        .setValidators([Validators.required]);
      this.createGroupForm.get('service_id').clearValidators();
    } else if (this.selectedSegment == 'Servizio') {
      this.data.product = 0;
      this.createGroupForm
        .get('service_id')
        .setValidators([Validators.required]);
      this.createGroupForm.get('product_id').clearValidators();
    }
    this.createGroupForm.get('service_id').updateValueAndValidity();
    this.createGroupForm.get('product_id').updateValueAndValidity();
    if (data.service_id === '') {
      data.service_id = 0;
    } else if (data.product_id === '') {
      data.product_id = 0;
    }
    this.orderApi.putDetailsLinesOrder(data).subscribe((e) => {
      console.log(e);
      if (e.message && e.message.includes('OK')) {
        this.presentToast(e.message, 'success');
        this.response = 'OK';
        this.dismissModal();
      } else {
        this.response = 'KO';
      }
    });
  }

  createLine(data) {
    //data['product_id'] = this.product.id
    data.qty = Number(data.qty);
    data.cost = Number(data.cost);

    console.log(data, this.order);
    this.orderApi.order_orderlines_create(data, this.order).subscribe((e) => {
      console.log(e);
      if (e.message && e.message.includes('OK')) {
        this.presentToast(e.message, 'success');
        this.response = 'OK';
        this.dismissModal();
      } else {
        this.response = 'KO';
      }
    });
  }

  order_orderlegal_update(data) {
    this.orderApi.putDetailsLegalOrder(data, this.order).subscribe((e) => {
      console.log(e);
      if (e.message && e.message.includes('OK')) {
        this.presentToast(e.message, 'success');
        this.response = 'OK';
        this.dismissModal();
      } else {
        this.response = 'KO';
      }
    });
  }

  createLegal(data) {
    this.orderApi.createDetailsLegalOrder(data, this.order).subscribe((e) => {
      console.log(e);
      if (e.message && e.message.includes('OK')) {
        this.presentToast(e.message, 'success');
        this.response = 'OK';
        this.dismissModal();
      } else {
        this.response = 'KO';
      }
    });
  }

  get errorControl() {
    return this.createGroupForm.controls;
  }

  dismissModal(event?) {
    this.modalController.dismiss(this.response);
  }

  submitForm() {
    this.createForm.onSubmit(undefined);
  }

  fabCallBack(action) {
    console.log(action);

    if (action) {
      this[action]();
    } else {
      console.log('not call in component, run callback', 'details');
    }
  }

  async presentToast(text, color) {
    const toast = await this.toastController.create({
      message: text,
      position: 'bottom',
      duration: 3000,
      cssClass: 'toast',
      color: color,
    });
    return await toast.present();
  }

  onSelectProductComp(selected) {
    console.log('Select Event', selected);
    console.log(selected.item);
    this.createGroupForm.controls['product_id'].setValue(selected.item.id);
    this.createGroupForm.controls['service_id'].setValue('');
    this.createGroupForm.get('product_id').setValidators([Validators.required]);
    this.createGroupForm.get('service_id').clearValidators();
    // this.viewListOrderVendor = true
  }

  onSelectServiceComp(selected) {
    console.log('Select Event', selected);
    console.log(selected.item);
    this.createGroupForm.controls['service_id'].setValue(selected.item.id);
    this.createGroupForm.controls['product_id'].setValue('');
    this.createGroupForm.get('service_id').setValidators([Validators.required]);
    this.createGroupForm.get('product_id').clearValidators();
    // this.viewListOrderVendor = true
  }
  searchPorts(
    event /*:  { component: IonicSelectableComponent; text: string }, type */
  ) {
    console.log(event, event.text);
    //console.log(event.component._modalComponent);
    //console.log(event.component._modalComponent._content);

    //event.component._modalComponent._content.scrollToBottom(1500);
    //event.component._modalComponent._content.scrollY = false

    let url = '';

    const text = event.text.trim().toLowerCase();
    //event.component.startSearch();

    // Close any running subscription.
    if (this.productSubscription) {
      this.productSubscription.unsubscribe();
    }

    if (!text) {
      // Close any running subscription.
      if (this.productSubscription) {
        this.productSubscription.unsubscribe();
      }

      if (this.serviceSubscription) {
        this.serviceSubscription.unsubscribe();
      }

      console.log('entro');

      this.page_products = 1;

      url = '?page=' + this.page_products + '&items=' + this.size;

      this.productApi.getProductsList(url).subscribe((data) => {
        console.log('!text call more getProductsList', data);

        //data.results = event.component.items = (data.results);

        //this.numPage_project = data.num_pages;

        //event.component.items = data.results;
        this.onSearchSelector = '';
        this.numPage_products = data.num_pages;
        this.products = data.results;
        //event.component.endInfiniteScroll();
      });

      this.page_services = 1;

      url = '?page=' + this.page_services + '&items=' + this.size;

      this.productApi.getServicesList(url).subscribe((data) => {
        console.log('!text call more getServicesList', data);

        //data.results = event.component.items = (data.results);

        //this.numPage_project = data.num_pages;

        //event.component.items = data.results;
        this.onSearchSelector = '';
        this.numPage_services = data.num_pages;
        this.services = data.results;
        //event.component.endInfiniteScroll();
      });

      //event.component.endSearch();
      //event.component.enableInfiniteScroll();
    } else {
      switch (event['module']) {
        case 'product':
          this.page_products = 1;
          url = '?page=' + this.page_products + '&items=20&name_slug=' + text;
          console.log(url);

          this.productSubscription = this.productApi
            .getProductsList(url)
            .subscribe((data) => {
              console.log('text call from search getProductsList', data);
              // Subscription will be closed when unsubscribed manually.
              // if (this.productSubscription.closed) {
              //   return;
              // }
              this.onSearchSelector = text;
              this.numPage_products = data.num_pages;
              this.products = data.results;
            });

          break;
        case 'service':
          url = '?page=' + this.page_services + '&items=20&name=' + text;
          this.serviceSubscription = this.productApi
            .getServicesList(url)
            .subscribe((data) => {
              console.log('text call from search getServicesList', data);
              // Subscription will be closed when unsubscribed manually.
              if (this.serviceSubscription.closed) {
                return;
              }
              this.onSearchSelector = text;
              this.numPage_services = data.num_pages;
              this.services = data.results;
            });
          break;
      }
    }
  }

  // filterPorts(vendors: any, text: string) {
  //   console.log(vendors)
  //   return vendors.filter(vendors => {
  //     return vendors.name.toLowerCase().indexOf(text) !== -1; //||
  //     //port.country.name.toLowerCase().indexOf(text) !== -1 ||
  //     //port.id.toString().toLowerCase().indexOf(text) !== -1;
  //   });
  // }

  onInfiniteScroll(
    /* 
  event: { component: IonicSelectableComponent; text: string },
  type: string */ event
  ) {
    console.log(event);
    console.log(this.page_products, this.numPage_products);

    let url;
    let _type = event['type'] ? event['type'] : null;
    console.log('after', _type);

    switch (event['module']) {
      case 'product':
        url = '?page=' + ++this.page_products + '&items=' + this.size;

        this.productApi.getProductsList(url).subscribe((data) => {
          console.log('!text call more getProductsList', data);

          //data.results = event.component.items = (data.results);

          //this.numPage_project = data.num_pages;

          //event.component.items = data.results;
          this.onSearchSelector = '';
          this.numPage_products = data.num_pages;
          this.products = data.results;
          //event.component.endInfiniteScroll();
        });
        break;
      case 'service':
        url = '?page=' + ++this.page_services + '&items=' + this.size;

        this.productApi.getServicesList(url).subscribe((data) => {
          console.log('!text call more getServicesList', data);

          //data.results = event.component.items = (data.results);

          //this.numPage_project = data.num_pages;

          //event.component.items = data.results;
          this.onSearchSelector = '';
          this.numPage_services = data.num_pages;
          this.services = data.results;
          //event.component.endInfiniteScroll();
        });
        break;

      default:
        break;
    }
  }

  onSelect(event: any) {
    console.log(event);
    if (event.isSelected /*  && !this.project_in */) {
      if (event.module == 'service') {
        this.onSelectServices = event.item;
        this.createGroupForm.controls['service_id'].setValue(event.item.id);
      }
    } else if (!event.isSelected /* && !this.project_in */) {
      if (event.module == 'product') {
        this.onSelectProduct = '';
        this.createGroupForm.controls['product_id'].setValue(0);
      }
    }
  }

  amountChanged(event) {
    console.log('event', event);

    this.createGroupForm.controls['cost'].setValue(event / 1000);
  }

  changeModalSize() {
    // Seleziona tutti gli ion-modal
    const modals = document.querySelectorAll('ion-modal');

    // Controlla se ci sono modali aperti
    if (modals.length > 0) {
      // Prendi l'ultimo modale aperto che dovrebbe essere quello attivo
      const modalElement = modals[modals.length - 1];

      // Controlla se la classe 'small-icon' è già applicata
      if (modalElement.classList.contains('small-icon')) {
        // Se 'small-icon' è applicata, rimuovila per tornare alla dimensione originale
        this.renderer.removeClass(modalElement, 'small-icon');
        // Aggiungi qui la classe per la dimensione originale se la conosci
        this.iconName = 'chevron-up-circle-outline';
        this.showConfirm = true;
        this.chgToolbar = '';
      } else {
        // Se 'small-icon' non è applicata, aggiungila per ridurre le dimensioni del modale
        this.renderer.addClass(modalElement, 'small-icon');
        this.iconName = 'chevron-down-circle-outline';
        this.showConfirm = false;
        this.chgToolbar = 'toolbar-background';
      }
    }
  }

  async openNewProductModal() {
    const modal = await this.modalController.create({
      component: NewProductComponent,
      canDismiss: true,
      componentProps: {
        title: 'Add New Product',
        fromOrder: true,
      },
      //presentingElement: this.routerOutlet.nativeEl,
    });

    modal.onWillDismiss().then((e) => {
      if (e.data && e.data.message != 'OK') {
        console.log('no modify');
        return;
      } else {
        this.response = 'OK';
        let url = '';
        this.page_products = 1;

        url = '?page=' + this.page_products + '&items=' + this.size;

        this.productApi.getProductsList(url).subscribe((data) => {
          console.log('!text call more getProductsList', data);

          //data.results = event.component.items = (data.results);

          //this.numPage_project = data.num_pages;

          //event.component.items = data.results;
          this.onSearchSelector = '';
          this.numPage_products = data.num_pages;
          this.products = data.results;
          //event.component.endInfiniteScroll();
        });
      }
    });
    return await modal.present();
  }

  async openNewServiceModal() {
    const modal = await this.modalController.create({
      component: NewServiceComponent,
      canDismiss: true,
      componentProps: {
        title: 'Add New Service',
        fromOrder: true,
      },
      //presentingElement: this.routerOutlet.nativeEl,
    });

    modal.onWillDismiss().then((e) => {
      if (e.data && e.data.message != 'OK') {
        console.log('no modify');
        return;
      } else {
        this.response = 'OK';
        let url = '';
        this.page_services = 1;

        url = '?page=' + this.page_services + '&items=' + this.size;

        this.productApi.getServicesList(url).subscribe((data) => {
          console.log('!text call more getServicesList', data);

          //data.results = event.component.items = (data.results);

          //this.numPage_project = data.num_pages;

          //event.component.items = data.results;
          this.onSearchSelector = '';
          this.numPage_services = data.num_pages;
          this.services = data.results;
          //event.component.endInfiniteScroll();
        });
      }
    });
    return await modal.present();
  }

  enableEdit(item) {
    this.editModal = true;
    let control = '';
    console.log(this.selectedSegment);
    if (item == 'Prodotto') control = 'product_id';
    else {
      control = 'service_id';
    }
    this.createGroupForm.controls[control].setValue('');
  }

  changeSegment(segment) {
    console.log(segment);
    console.log(this.actualForm);

    if (segment === 'Prodotto') {
      this.selectedSegment = 'Prodotto';
      this.createGroupForm
        .get('product_id')
        .setValidators([Validators.required]);
      this.createGroupForm.get('service_id').clearValidators();

      this.createGroupForm.get('service_id').updateValueAndValidity();
      this.createGroupForm.get('product_id').updateValueAndValidity();
    } else {
      this.selectedSegment = 'Servizio';
      this.createGroupForm
        .get('service_id')
        .setValidators([Validators.required]);
      this.createGroupForm.get('product_id').clearValidators();

      this.createGroupForm.get('service_id').updateValueAndValidity();
      this.createGroupForm.get('product_id').updateValueAndValidity();
    }
  }
}
