import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { IonInput, IonSegment, ModalController, PopoverController, ToastController } from '@ionic/angular';
import { InvoicesService } from 'src/app/services/invoices/invoices.service';
import { OrdersService } from 'src/app/services/orders/orders.service';


@Component({
  selector: 'app-payment-invoice-line',
  templateUrl: './payment-invoice-line.component.html',
  styleUrls: ['./payment-invoice-line.component.scss'],
})
export class PaymentInvoiceLineComponent implements OnInit {

  site;
  response;
  @Input() invoice;
  @Input() line;
  @Input() type;
  @Input() discount;

  @ViewChild('inputEl', { static: false }) inputEl: IonInput;
  @ViewChild('createForm') createForm: FormGroupDirective;
  @ViewChild('seg') seg: IonSegment;
  createLine: FormGroup;
  payment_type: any = []


  constructor(
    private fb: FormBuilder,
    private toastController: ToastController,
    private popoverController: PopoverController,
    private modalController: ModalController,
    private invoiceApi: InvoicesService,
    private orderApi: OrdersService
  ) {


  }

   ngOnInit() {
    this.orderApi.getOrderPaymenttypes().subscribe(e=>{
      this.payment_type = e
    })
  }

  get_f(control, prop) {
    if (!this.createLine)
      return

    return this.createLine.controls[control][prop];
  }


  createBaseForm() {

    // Generete FormGroup Create Vendor 
    this.createLine = this.fb.group({
      invoice_id: this.fb.control(this.line.id, Validators.required),
      value: this.fb.control('', Validators.required),
      type: this.fb.control('Bonifico', Validators.required),
    })



    this.createLine.valueChanges.subscribe(val => {
      console.log(val);
      console.log(this.createLine);
      console.log(this.createLine.controls);

    })

  }

  submitForm() {
    console.log('onSubmit');

    this.createLine.controls['type'].enable()
    this.createForm.onSubmit(undefined);
  }


  createFormLineInvoice(values: any) {
    // copy all the form values into the new vendor
    const newDiscount = { ...values };
    //newDiscount.created = moment(newDiscount.created).format('YYYY-MM-DD')

    console.log(newDiscount);

    this.response = newDiscount
    this.dismiss();
    return
    this.invoiceApi.craeteAdvance(this.line, newDiscount).subscribe(e => {
      console.log(e);

      if (e.message && e.message.includes('OK')) {
        this.presentToast(e.message, 'success')
        e.data['type'] = e.data.advance_type;
        console.log(e.data);
      } else {
        console.log('no modify');

      }

    })

    console.log(newDiscount);
  }




  ionViewWillEnter(response) {

    console.log('input invoice', this.invoice);
    console.log('input type', this.type);
    console.log('input line', this.line);

    this.createBaseForm();

    console.log('-----------------line-------------------');

    setTimeout(() => {
      this.inputEl.setFocus()
    }, 500);

    //let filter = max.filter(e => e.approved)

    //this.discounts.push(...this.line.discounts)

    //this.approved = filter;

    //let a = this.groupArrayOfObjects(this.discounts, 'vendor')
    //let b = this.groupArrayOfObjects(this.discounts, 'version')

    //console.log(a);
    //console.log(b);



  }
  ionViewDidEnter(response) {
  }


  dismiss() {
    this.popoverController.dismiss(this.response);
  }

  dismissModal() {
    this.modalController.dismiss(this.response);
  }



  async presentToast(text, color) {
    const toast = await this.toastController.create({
      message: text,
      position: 'bottom',
      duration: 3000,
      cssClass: 'toast',
      color: color
    });
    return await toast.present();
  }

}
