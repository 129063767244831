import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { AlertController, IonNav, ModalController } from '@ionic/angular';
import { VendorsService } from 'src/app/services/vendors/vendors.service';

import { Vendor } from '../models/vendor.model';
import { NewLineAddress } from 'src/app/components/new-line-address/new-line-address.component';
import { HrService } from 'src/app/services/hr/hr.service';

@Component({
  selector: 'app-new-vendor',
  templateUrl: './new-vendor.component.html',
  styleUrls: ['./new-vendor.component.scss'],
})
export class NewVendorComponent {
  openUpdateAddressModal($event: {
    action: string;
    item: any;
    module: string;
  }) {
    throw new Error('Method not implemented.');
  }

  @Input() title: string;
  @Input() name: string = '';
  @ViewChild('createForm') createForm: FormGroupDirective;
  @ViewChild('wrapperAddress', { read: ViewContainerRef })
  target: ViewContainerRef;
  private componentRef: ComponentRef<any>;
  private emailRegex = '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$';
  private maxRowAddress = 5;
  private maxRowPhone = 5;
  private maxRowEmail = 5;
  size = 20;
  // group form general
  createVendorForm: FormGroup;
  createAddressForm: FormGroup;
  portsSubscription;
  types: any = [];
  contactTypes:any = []
  addresses: any = [];
  response;
  onPushAddress;
  page_address = 1;
  numPage_address = 0;
  onSearchSelector = '';
  onSelectAddress;
  vendor;

  selectedSegment: any = 'newAddress';
  selectedSegmentContact: any = 'newContact';
  segments = [{ code: 'newAddress', name: 'New Address' }];
  segmentsContact = [{ code: 'newContact', name: 'New Contact' }];

  locationtypes: any = [];
  selectedLocation: any = [];
  filteredCountries: any = [];
  noCountries = false;
  filteredLocations: any = [];
  selectedCountry: any = [];
  selectedStreet: any = [];
  isEditing: boolean = false; // Variabile per il toggle
  isEditingAddresses: boolean = false; // Variabile per il toggle
  isDirty: boolean = false; // Variabile per monitorare le modifiche del form
  isEditButton = false;
  addressToEdit: any = [];

  constructor(
    private modalController: ModalController,
    private fb: FormBuilder,
    private api: VendorsService,
    private apiHr: HrService,
    private vendorApi: VendorsService,
    private apiVendor: VendorsService,
    private alertController: AlertController //private nav: IonNav,
  ) {
    this.apiVendor.getTypesList().subscribe((e) => {
      this.types = e['results'];
      console.log(this.types);
    });

    this.apiVendor.getContactsTypesList().subscribe((e) => {
      this.contactTypes = e['results'];
      console.log(this.contactTypes);
    });
    this.apiHr.getLocationTypes().subscribe((e) => {
      console.log('locationTypes', e);
      this.locationtypes = e.results;
      console.log(this.locationtypes);
    });

    // Generete FormGroup Create Vendor
    this.createVendorForm = this.fb.group({
      name: this.fb.control(this.name, Validators.required),
      code: this.fb.control('', Validators.required),
      type_id: this.fb.control('', Validators.required),
      code_vat: this.fb.control('', Validators.required),
      code_iban: this.fb.control(''),
      code_unique: this.fb.control(''),
      address_id: this.fb.control(''),
      code_fiscal: this.fb.control(''),
      is_customer: this.fb.control(false),
      is_vendor: this.fb.control(true),
      address: this.fb.group({
        country_id: ['', Validators.required],
        type_id: ['', Validators.required],
        street: ['', Validators.required],
        number: ['', Validators.required],
        cap: ['', Validators.required],
      }),
      contacts: this.fb.array([this.createContacts()]),
    });

    this.createVendorForm.valueChanges.subscribe(e=>{
      console.log(e);
      
    })
  }

  createContacts(): FormGroup {
    return this.fb.group({
      name: [''],
      type_id: [''],
      contact: [''],
    });
  }

  get contacts(): FormArray {
    return this.createVendorForm.get('contacts') as FormArray;
  }

  addContact(): void {
    this.contacts.push(this.createContacts());
  }

  removeContact(index: number): void {
    this.contacts.removeAt(index);
  }
  dismissModal() {
    this.modalController.dismiss(this.response);
  }

  ionViewWillEnter() {}

  // Call submitForm with click Save
  submitForm() {
    console.log('onSubmit');
    this.createForm.onSubmit(undefined);
  }

  addRowAddress() {
    console.log(this.createVendorForm);

    let addressArray = this.createVendorForm.controls['addresses'] as FormArray;
    let arraylen = addressArray.length;
    // max Row added is five
    if (arraylen < this.maxRowAddress) {
      let newAddressgroup: FormGroup = this.fb.group({
        name: [''],
        street: [''],
        number: [''],
        city: [''],
        cap: [''],
        region: [''],
        province: [''],
        nation: [''],
      });
      addressArray.insert(arraylen, newAddressgroup);
    }
  }

  addRowPhone() {
    // Handling dinamic add row phones
    let phoneArray = this.createVendorForm.controls['phones'] as FormArray;
    let arraylen = phoneArray.length;
    // max Row added is five
    if (arraylen < this.maxRowPhone) {
      let newPhonegroup: FormGroup = this.fb.group({
        phone: [''],
      });
      phoneArray.insert(arraylen, newPhonegroup);
    }
  }

  addRowEmail() {
    // Handling dinamic add row email
    let emailArray = this.createVendorForm.controls['emails'] as FormArray;
    let arraylen = emailArray.length;
    // max Row added is five
    if (arraylen < this.maxRowPhone) {
      let newEmailgroup: FormGroup = this.fb.group({
        email: [''],
      });
      emailArray.insert(arraylen, newEmailgroup);
    }
  }

  searchPorts(
    event /*:  { component: IonicSelectableComponent; text: string }, type */
  ) {
    console.log(event, event.text);
    //console.log(event.component._modalComponent);
    //console.log(event.component._modalComponent._content);

    //event.component._modalComponent._content.scrollToBottom(1500);
    //event.component._modalComponent._content.scrollY = false

    let url = '';

    const text = event.text.trim().toLowerCase();
    //event.component.startSearch();

    // Close any running subscription.
    if (this.portsSubscription) {
      this.portsSubscription.unsubscribe();
    }

    if (!text) {
      // Close any running subscription.
      if (this.portsSubscription) {
        this.portsSubscription.unsubscribe();
      }

      //event.component.endSearch();
      //event.component.enableInfiniteScroll();
      switch (event['module']) {
        case 'address':
          this.page_address = 1;

          url = '?page=' + this.page_address + '&items=' + this.size;

          this.api.getAddressesList(url).subscribe((data) => {
            console.log('!text call more getCustomersList', data);

            //data.results = event.component.items = (data.results);

            //this.numPage_project = data.num_pages;

            //event.component.items = data.results;
            this.onSearchSelector = '';
            this.numPage_address = data.num_pages;
            this.addresses = data.results;
            //event.component.endInfiniteScroll();
          });
          break;

        default:
          console.log('Default');

          break;
      }

      return;
    }
  }

  onSelect(event) {
    console.log(event);
    if (event.isSelected /*  && !this.project_in */) {
      if (event.module == 'address') {
        this.onSelectAddress = event.item;
        this.createAddressForm.controls['address_id'].setValue(event.item.id);
      }
    } else if (!event.isSelected /* && !this.project_in */) {
      if (event.module == 'address') {
        this.onSelectAddress = '';
        this.createAddressForm.controls['address_id'].setValue(0);
      }
    }
  }

  onInfiniteScroll(
    /* 
  event: { component: IonicSelectableComponent; text: string },
  type: string */ event
  ) {
    console.log(event);
    console.log(this.page_address, this.numPage_address);
    console.log(this.title);

    let url;
    let _type = event['type'] ? event['type'] : null;
    console.log('after', _type);

    switch (_type) {
      case 'address':
        url = '?page=' + ++this.page_address + '&items=' + this.size;
        console.log('entro address');
        this.api.getAddressesList(url).subscribe((data) => {
          console.log('!text call more getAddressList', data);

          //data.results = event.component.items = (data.results);

          //this.numPage_project = data.num_pages;

          //event.component.items = data.results;
          this.onSearchSelector = '';
          this.numPage_address = data.num_pages;
          this.addresses = data.results;
          console.log(this.addresses);
          //event.component.endInfiniteScroll();
        });
        break;

      default:
        console.log('Default');

        break;

      //.... more code case
    }
  }

  async openNewCustomerModal() {
    const modal = await this.modalController.create({
      component: NewVendorComponent,
      //cssClass: 'newOrderModalForm',
      canDismiss: true,
      componentProps: {
        // //nav: this.nav,
        title: 'Add Company',
        redirectAfterPost: false,
      },
    });

    modal.onWillDismiss().then(async (e) => {
      console.log(e);

      if (!e.data) {
        console.log('no modify');
        return;
      } else {
        //this.loadProjects(this.currentSegment);
        //this.projects = await this.project.getProjectsList_rev('?page=' + this.page_project + '&items=' + this.size).toPromise().then(e => e.results)
        // this.page_customer = 1
        // this.page_vendor = 1
        //this.onSelectProject = [e.data]
        // this.onPushVendor = e.data
        // this.onPushCustomer = e.data
      }
    });

    return await modal.present();
  }
  async openNewAddressModal() {
    const modal = await this.modalController.create({
      component: NewLineAddress,
      //cssClass: 'newOrderModalForm',
      canDismiss: true,
      componentProps: {
        //nav: this.nav,
        title: 'Add Address',
        redirectAfterPost: false,
      },
    });

    modal.onWillDismiss().then(async (e) => {
      console.log(e);

      if (!e.data) {
        console.log('no modify');
        return;
      } else {
        console.log('updateListAddress');
        //this.loadProjects(this.currentSegment);
        this.addresses = await this.api
          .getAddressesList()
          .toPromise()
          .then((e) => e.results);
        //this.addresses = await this.project.getProjectsList_rev('?page=' + this.page_project + '&items=' + this.size).toPromise().then(e => e.results)

        this.page_address = 1;
        this.onSelectAddress = [e.data];
        this.onPushAddress = e.data;
      }
    });

    return await modal.present();
  }

  searchCountriesList(event: any) {
    const query = event.target.value;
    if (query.length > 2) {
      this.apiHr.getCountriesList(event.detail.value).subscribe((e) => {
        console.log(e);
        this.filteredCountries = e.results;
        if (e.results.length === 0) {
          this.noCountries = true;
        } else {
          this.noCountries = false;
        }
      });
    } else {
      this.filteredCountries = [];
    }
  }

  getLocation(event: any) {
    const query = event.target.value;
    if (query.length > 2) {
      this.apiHr
        .getLocations(
          this.selectedCountry.id,
          this.selectedLocation.id,
          event.detail.value
        )
        .subscribe((e) => {
          console.log(e);
          this.filteredLocations = e.results;
          if (e.results.length === 0) {
            this.noCountries = true;
          } else {
            this.noCountries = false;
          }
        });
    } else {
      this.filteredLocations = [];
    }
  }

  getLocationClearNumber(event: any) {
    const query = event.target.value;
    console.log(this.selectedStreet);

    if (query.length > 0) {
      this.apiHr
        .getLocationsClear(
          this.selectedCountry.id,
          this.selectedLocation.id,
          this.selectedStreet.street,
          event.detail.value,
          this.selectedStreet.cap
        )
        .subscribe((e) => {
          console.log(e);
          this.filteredLocations = e.results;
          if (e.results.length === 0) {
            this.noCountries = true;
            const addresses = this.createVendorForm.get(
              'addresses'
            ) as FormArray;
            const address = addresses.at(0) as FormGroup;

            if (address) {
              address.patchValue({
                number: event.detail.value,
              });
            }

            console.log(this.createVendorForm.value);
          } else {
            this.noCountries = false;
          }
        });
    } else {
      this.filteredLocations = [];
    }
  }

  createAddress(): FormGroup {
    return this.fb.group({
      id: ['', Validators.required],
      name: ['', Validators.required],
      city: ['', Validators.required],
      country_id: ['', Validators.required],
      location_id: [0, Validators.required],
      type_id: ['', Validators.required],
      street: ['', Validators.required],
      number: ['', Validators.required],
      cap: ['', Validators.required],
    });
  }

  getLocationClearCAP(event: any) {
    const query = event.target.value;
    console.log(this.selectedStreet);
    if (query.length > 0) {
      this.apiHr
        .getLocationsClear(
          this.selectedCountry.id,
          this.selectedLocation.id,
          this.selectedStreet.street,
          this.selectedStreet.number,
          event.detail.value
        )
        .subscribe((e) => {
          console.log(e);
          this.filteredLocations = e.results;
          if (e.results.length === 0) {
            this.noCountries = true;
            const address = this.createVendorForm.get('address') as FormGroup;

            if (address) {
              address.patchValue({
                cap: event.detail.value,
              });
            }
            console.log(this.createVendorForm.value);
          } else {
            this.noCountries = false;
          }
        });
    } else {
      this.filteredLocations = [];
    }
  }

  selectCountry(country: any, addressIndex?: any) {
    this.selectedCountry = country;
    console.log(this.selectedCountry);
    const address = this.createVendorForm.get('address') as FormGroup;
    console.log(address);

    if (address) {
      address.patchValue({
        country_id: country.id,
      });
    }
    this.filteredCountries = [];
  }

  selectCountryLegal(country: any) {
    this.selectedCountry = country;

    setTimeout(() => {
      const addresses = this.createVendorForm.get('address') as FormGroup;
      const address = addresses;
      console.log(address);

      if (address) {
        address.patchValue({
          country_id: country.id,
        });
      }

      console.log(this.selectedCountry);
      this.filteredCountries = [];
    }, 100);
  }

  selectLocation(location: any, addressIndex?: number) {
    this.selectedStreet = location;

    setTimeout(() => {
      const address = this.createVendorForm.get('address') as FormGroup;

      if (address) {
        address.patchValue({
          location_id: location.id,
          type_id: location.type.id,
          country_id: location.country.id,
          street: location.street,
          number: location.number,
          cap: location.cap,
        });
      }

      console.log(this.createVendorForm.get('address'));

      console.log(this.selectedStreet);
      this.filteredLocations = [];
    }, 100);
  }

  selectLocationLegal(location: any) {
    this.selectedStreet = location;

    setTimeout(() => {
      const addresses = this.createVendorForm.get('address') as FormGroup;
      const address = addresses;

      if (address) {
        address.patchValue({
          location_id: location.id,
          type_id: location.type.id,
          street: location.street,
          number: location.number,
          cap: location.cap,
        });
      }

      console.log(this.selectedStreet);
      this.filteredLocations = [];
    }, 100);
  }

  handleLocationTypes(event: any, addressIndex?: any) {
    console.log(event);

    this.selectedLocation = this.locationtypes.find(
      (e) => e.id === event.detail.value
    );
    console.log(this.selectedLocation);
    this.noCountries = false;
    console.log(this.selectedCountry);
    this.filteredCountries = [];
  }

  handleLocationTypesLegal(event: any) {
    console.log(event);

    this.selectedLocation = this.locationtypes.find(
      (e) => e.id === event.detail.value
    );
    console.log(this.selectedLocation);
    this.noCountries = false;
    setTimeout(() => {
      const addresses = this.createVendorForm.get('address') as FormGroup;
      const address = addresses;

      if (address) {
        address.patchValue({
          street: '',
        });
      }

      console.log(this.selectedCountry);
      this.filteredCountries = [];
    }, 100);
  }

  addAddresses() {
    const addressArray = this.createVendorForm.get('addresses') as FormArray;
    const newAddress = addressArray.at(addressArray.length - 1).value;

    console.log(newAddress);

    this.apiHr.addLocation(newAddress).subscribe((e: any) => {
      console.log(e);

      let form = {
        company_id: this.vendor.id,
        address_id: e.data.id,
        name: newAddress.name,
      };
      this.apiHr.addLocationToCompany(form).subscribe((e: any) => {
        console.log(e);
        this.vendorApi.getVendorById(this.vendor.id).subscribe((e) => {
          console.log(e);

          this.vendor = e;
          this.segments = [];
          this.segments = [{ code: 'legal', name: 'Legale' }];
          this.vendor.addresses.forEach((e, index) => {
            this.segments.push({
              code: `address_${index}`,
              name: e.name,
            });
          });
          this.segments.push({ code: 'new', name: '+' });

          this.segmentChanged('legal');
        });
      });
    });
  }

  addAddress() {
    const addressArray = this.createVendorForm.get('address') as FormGroup;
    const newAddress = addressArray.value;

    console.log(newAddress);

    this.apiHr.addLocation(newAddress).subscribe((e: any) => {
      console.log(e);

      let form = {
        company_id: this.vendor.id,
        address_id: e.data.id,
        name: newAddress.name,
      };
      this.apiHr.addAddressToCompany(form).subscribe((e: any) => {
        console.log(e);
        this.vendorApi.getVendorById(this.vendor.id).subscribe((e) => {
          console.log(e);

          this.vendor = e;
          this.segmentChanged('legal');
        });
      });
    });
  }

  editAddress() {
    const addressArray = this.createVendorForm.get('address') as FormGroup;
    const newAddress = addressArray.value;

    console.log(newAddress);

    this.apiHr.addLocation(newAddress).subscribe((e: any) => {
      console.log(e);
      this.deleteLegalById(this.addressToEdit);
      let form = {
        address_id: e.data.id,
      };

      this.apiVendor.updateVendor(form, this.vendor.id).subscribe((e) => {
        console.log(e);
        this.response = e.data;
        this.vendorApi.getVendorById(this.vendor.id).subscribe((e) => {
          console.log(e);

          this.vendor = e;
          this.segmentChanged('legal');
        });
      });
    });
  }

  editAddresses() {
    const addressArray = this.createVendorForm.get('addresses') as FormArray;
    const newAddress = addressArray.at(addressArray.length - 1).value;

    console.log(newAddress);

    this.apiHr.addLocation(newAddress).subscribe((e: any) => {
      console.log(e);
      console.log(this.addressToEdit);

      this.deleteAddressById(this.addressToEdit);

      let form = {
        company_id: this.vendor.id,
        address_id: e.data.id,
        name: newAddress.name,
      };
      this.apiHr.addLocationToCompany(form).subscribe((e: any) => {
        console.log(e);
        this.vendorApi.getVendorById(this.vendor.id).subscribe((e) => {
          console.log(e);

          this.vendor = e;
          this.segments = [];
          this.segments = [{ code: 'legal', name: 'Legale' }];
          this.vendor.addresses.forEach((e, index) => {
            this.segments.push({
              code: `address_${index}`,
              name: e.name,
            });
          });
          this.segments.push({ code: 'new', name: '+' });

          this.segmentChanged('legal');
        });
      });
    });
  }

  findAddressIdByAddressId(addressId: number): number | null {
    const foundAddress = this.vendor.addresses.find(
      (addr) => addr.address.id === addressId
    );
    return foundAddress ? foundAddress.id : null;
  }

  deleteAddress(address) {
    console.log(address);
    let id = this.findAddressIdByAddressId(address.value.id);
    console.log(id);
    this.apiHr.deleteLocationCompany(id).subscribe((e: any) => {
      console.log(e);
      this.vendorApi.getVendorById(this.vendor.id).subscribe((e) => {
        console.log(e);

        this.vendor = e;
        this.segments = [];
        this.segments = [{ code: 'legal', name: 'Legale' }];
        this.vendor.addresses.forEach((e, index) => {
          this.segments.push({
            code: `address_${index}`,
            name: e.name,
          });
        });
        this.segments.push({ code: 'new', name: '+' });

        this.segmentChanged('legal');
      });
    });
  }

  deleteAddressById(address) {
    console.log(address);
    let id = address;
    console.log(id);
    this.apiHr.deleteLocationCompany(id).subscribe((e: any) => {
      console.log(e);
      this.vendorApi.getVendorById(this.vendor.id).subscribe((e) => {
        console.log(e);

        this.vendor = e;
        this.segments = [];
        this.segments = [{ code: 'legal', name: 'Legale' }];
        this.vendor.addresses.forEach((e, index) => {
          this.segments.push({
            code: `address_${index}`,
            name: e.name,
          });
        });
        this.segments.push({ code: 'new', name: '+' });

        this.segmentChanged('legal');
      });
    });
  }

  deleteLegalById(address) {
    console.log(address);
    let id = address;
    console.log(id);
    this.apiHr.deleteLocationCompany(id).subscribe((e: any) => {
      console.log(e);
      this.vendorApi.getVendorById(this.vendor.id).subscribe((e) => {
        console.log(e);

        this.vendor = e;
        this.segments = [];
        this.segments = [{ code: 'legal', name: 'Legale' }];

        this.vendor.addresses.forEach((e, index) => {
          this.segments.push({
            code: `address_${index}`,
            name: e.name,
          });
        });
        this.segments.push({ code: 'new', name: '+' });

        this.segmentChanged('legal');
      });
    });
  }

  setTypeId(event) {
    let type = event.detail.value;
    console.log(type);
  }
  onToggleEdit() {
    // this.toggleAddressControls(this.isEditing);
    this.addressToEdit = this.vendor.address.id;
    console.log(this.addressToEdit);

    this.presentAlertAddress();
  }

  onToggleEditAddresses(address: any) {
    // this.toggleAddressesControls(this.isEditingAddresses);
    this.addressToEdit = address.value.id;
    console.log(this.addressToEdit);

    this.presentAlertAddresses();
  }

  async presentAlertAddress() {
    const alert = await this.alertController.create({
      header: 'Attenzione',
      message:
        'Abilitando la modifica dovrai creare un indirizzo nuovo. Continuare?',
      buttons: [
        {
          text: 'Annulla',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          },
        },
        {
          text: 'Confermo',
          handler: () => {
            this.toggleAddressControls(true);
            this.isEditButton = true;
            this.segmentChanged('newLegal');
          },
        },
      ],
    });

    await alert.present();
  }
  async presentAlertAddresses() {
    const alert = await this.alertController.create({
      header: 'Attenzione',
      message:
        'Abilitando la modifica dovrai creare un indirizzo nuovo. Continuare?',
      buttons: [
        {
          text: 'Annulla',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          },
        },
        {
          text: 'Confermo',
          handler: () => {
            this.toggleAddressesControls(true);
            this.isEditButton = true;
            this.segmentChanged('new');
          },
        },
      ],
    });

    await alert.present();
  }

  getSegmentClass(segmentCode: string) {
    const match = segmentCode.match(/^address_(\d+)$/);
    if (match) {
      const index = parseInt(match[1], 10);
      return `background-address-${index}`;
    }
    return `background-${segmentCode}`;
  }

  segmentChanged(event: any) {
    console.log(event);
    // this.populateForm(this.vendor);

    if (event !== 0) {
      if (event?.detail) {
        this.addressToEdit = [];
        this.toggleAddressControls(false);
        this.toggleAddressesControls(false);
        console.log(event.detail.value);
        this.selectedSegment = event.detail.value;
        console.log(this.selectedSegment);
        this.filteredCountries = [];
        this.selectedCountry = [];
        const match = event.detail.value.match(/^address_(\d+)$/);
        if (match) {
          const index = parseInt(match[1], 10);
          this.patchAddressValues(index);
        }
        if (this.selectedSegment === 'new') {
          this.toggleAddressControls(true);
          this.toggleAddressesControls(true);
          const addressesArray = this.createVendorForm.get(
            'addresses'
          ) as FormArray;
          addressesArray.controls.forEach((group) => {
            (group as FormGroup).reset();
          });
          const addressArray = this.createVendorForm.get(
            'address'
          ) as FormGroup;
          addressArray.reset();
        }
        if (this.selectedSegment === 'legal') {
          this.createVendorForm.get('address').patchValue({
            country_id: this.vendor.address.country.name,
            type_id: this.vendor.address.type.name,
            street: this.vendor.address.street,
            number: this.vendor.address.number,
            cap: this.vendor.address.cap,
          });
        }
      } else {
        this.selectedSegment = event;
        console.log(this.selectedSegment);
        this.filteredCountries = [];
        this.selectedCountry = [];

        if (event === 'new') {
          const addressArray = this.createVendorForm.get(
            'addresses'
          ) as FormArray;
          addressArray.reset();
        }
        if (event === 'newLegal') {
          const addressArray = this.createVendorForm.get(
            'address'
          ) as FormGroup;
          addressArray.reset();
        }
        if (event === 'legal') {
          this.createVendorForm.get('address').patchValue({
            country_id: this.vendor.address.country.name,
            type_id: this.vendor.address.type.name,
            street: this.vendor.address.street,
            number: this.vendor.address.number,
            cap: this.vendor.address.cap,
          });
        }
      }
    }
  }

  createVendor() {
    //Call API
    console.log(this.vendor);
    console.log(this.createVendorForm.value);

    if (this.noCountries && this.filteredLocations.length === 0) {
      const address = this.createVendorForm.get('address') as FormGroup;
      this.apiVendor.createAddress(address.value).subscribe((e) => {
        console.log(e);
        this.response = e.data;
        if (e.message.includes('OK')) {
          this.createVendorForm.patchValue({ address_id: e.data.id });
          this.createVendorForm.removeControl('address');
          this.apiVendor
            .createVendor(this.createVendorForm.value)
            .subscribe((e) => {
              console.log(e);
              this.response = e.data;
              if (e.message.includes('OK')) {
                const contacts = this.createVendorForm.get(
                  'contacts'
                ) as FormArray;
                console.log(contacts);
                
                contacts.value.forEach((value) => {
              console.log(value);

                  let form = {
                    company_id: e.data.id,
                    contact: value.contact,
                    name: value.name,
                    type_id: value.type_id
                  }
                  this.apiVendor
                    .createContactVendor(form)
                    .subscribe((e) => {
                      console.log(e);
                    });
                });
              }
              this.dismissModal();
            });
        }
      });
    } else if (this.selectedStreet.length !== 0) {
      const contacts = this.createVendorForm.get('contacts') as FormArray;
      console.log(contacts);
      this.createVendorForm.removeControl('address');
      this.createVendorForm.patchValue({ address_id: this.selectedStreet.id });
      this.apiVendor
        .createVendor(this.createVendorForm.value)
        .subscribe((e) => {
          console.log(e);
          this.response = e.data;
          if (e.message.includes('OK')) {
            const contacts = this.createVendorForm.get('contacts') as FormArray;
            console.log(contacts);
            contacts.value.forEach((value) => {
              console.log(value);
              
              let form = {
                company_id: e.data.id,
                contact: value.contact,
                name: value.name,
                type_id: value.type_id
              }
              this.apiVendor
                .createContactVendor(form)
                .subscribe((e) => {
                  console.log(e);
                });
            });
          }
          this.dismissModal();
        });
    }

    return;
    this.apiVendor.createVendor(this.createVendorForm.value).subscribe((e) => {
      console.log(e);
      this.response = e.data;
      if (e.message.includes('OK')) this.dismissModal();
    });

    //console.log(form)
  }

  toggleAddressControls(enable: boolean) {
    const addressGroup = this.createVendorForm.get('address') as FormGroup;
    Object.keys(addressGroup.controls).forEach((control) => {
      const formControl = addressGroup.get(control) as FormControl;
      if (enable) {
        formControl.enable();
      } else {
        formControl.disable();
      }
    });
  }

  toggleAddressesControls(enable: boolean) {
    const addressGroup = this.createVendorForm.get('addresses') as FormGroup;
    Object.keys(addressGroup.controls).forEach((control) => {
      const formControl = addressGroup.get(control) as FormControl;
      if (enable) {
        formControl.enable();
      } else {
        formControl.disable();
      }
    });
  }

  patchAddressValues(index: number) {
    const address = this.vendor.addresses[index];
    console.log(address);

    if (address) {
      const addressFormArray = this.createVendorForm.get(
        'addresses'
      ) as FormArray;
      const addressFormGroup = addressFormArray.at(0) as FormGroup;

      addressFormGroup.patchValue({
        id: address.address.id,
        city: address.address.country.name,
        type_id: address.address.type.name,
        street: address.address.street,
        number: address.address.number,
        cap: address.address.cap,
      });
    }
  }
}
