import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ClarityModule } from "@clr/angular";
import { IonicModule, IonNav } from "@ionic/angular";

import { StepperComponent } from "../components/clarity/stepper/stepper.component";
import { TimelineComponent } from "../components/clarity/timeline/timeline.component";
import { WizardComponent } from "../components/clarity/wizard/wizard.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ClarityModule,
    IonicModule,

    
  ],
  declarations: [
    StepperComponent,
    TimelineComponent,
    WizardComponent
  ],
  exports: [
    ClarityModule,
    StepperComponent,
    TimelineComponent,
    WizardComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ClaritySharedModule { }
