// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[6].rules[0].oneOf[1].use[0]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[6].rules[0].oneOf[1].use[1]!../../../assets/styles/tailwind.css");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.transparent {
  --background: #0000;
}

.img_profile {
  max-width: 150px;
}

ion-toolbar,
ion-toolbar ion-item {
  --background: #0000;
  background: rgba(0, 0, 0, 0);
}

ion-card {
  text-align: center;
  transform: translateY(-56px);
  padding-top: 90px;
  --background: #e91e63;
}

ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff)) ;
}

ion-card-title,
ion-card-subtitle {
  color: #fff;
}

.bg-auto {
  background: var(--ion-color-super-tabs, #e1e1e1) !important;
}

.color-auto {
  color: var(--ion-color-super-tabs, #e1e1e1) !important;
}

.bg-card {
  background: var(--ion-bg-card, #f5f5f5);
}

.bg-card * {
  color: var(--color);
}

/* .bg-card h3, .bg-card .font-bold{

} */
.color-line {
  border-color: var(--ion-color-super-tabs, #e1e1e1) !important;
}

.h-350-px {
  height: 350px;
}`, "",{"version":3,"sources":["webpack://./src/app/users/profile-user/profile-user.page.scss"],"names":[],"mappings":"AAEA;EACI,mBAAA;AAAJ;;AAGA;EACI,gBAAA;AAAJ;;AAGA;;EAEI,mBAAA;EACA,4BAAA;AAAJ;;AAGA;EACI,kBAAA;EACA,4BAAA;EACA,iBAAA;EACA,qBAAA;AAAJ;;AAIA;EACI,4EAAA;AADJ;;AAIA;;EAEI,WAAA;AADJ;;AAIA;EACI,2DAAA;AADJ;;AAIA;EACI,sDAAA;AADJ;;AAIA;EACI,uCAAA;AADJ;;AAIA;EACI,mBAAA;AADJ;;AAIA;;GAAA;AAIA;EACI,6DAAA;AAFJ;;AAMA;EACI,aAAA;AAHJ","sourcesContent":["@import \"../../../assets/styles/tailwind.css\";\n\n.transparent {\n    --background: #0000;\n}\n\n.img_profile {\n    max-width: 150px;\n}\n\nion-toolbar,\nion-toolbar ion-item {\n    --background: #0000;\n    background: #0000;\n}\n\nion-card {\n    text-align: center;\n    transform: translateY(-56px);\n    padding-top: 90px;\n    --background: #e91e63;\n    //background: #0000;\n}\n\nion-content {\n    --background: var(--ion-item-background, var(--ion-background-color, #fff))\n}\n\nion-card-title,\nion-card-subtitle {\n    color: #fff;\n}\n\n.bg-auto {\n    background: var(--ion-color-super-tabs, #e1e1e1) !important;\n}\n\n.color-auto {\n    color: var(--ion-color-super-tabs, #e1e1e1) !important;\n}\n\n.bg-card {\n    background: var(--ion-bg-card, #f5f5f5);\n}\n\n.bg-card * {\n    color: var(--color)\n}\n\n/* .bg-card h3, .bg-card .font-bold{\n\n} */\n\n.color-line {\n    border-color: var(--ion-color-super-tabs, #e1e1e1) !important;\n\n}\n\n.h-350-px {\n    height: 350px;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
