import { ChangeDetectionStrategy, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';

import { ModalController, IonRouterOutlet, NavController, CheckboxCustomEvent, Platform, IonNav, ToastController, IonSearchbar } from '@ionic/angular';
import { from, Observable, of } from 'rxjs';
import { User } from '../models/users';
import { NewUserComponent } from '../new-user/new-user.component';
import { DataService } from '../../services/data/data.service';
import { ApiService } from 'src/app/services/api/api.service';

import { UserDetailsPage } from '../user-details/user-details.page';
import { ProjectsService } from 'src/app/services/projects/projects.service';
import { TitleAppService } from 'src/app/services/utils/title-app/title-app.service';
//import { ChangeDetectorRef, AfterContentChecked } from '@angular/core';

const TIMEOUT = 5;

@Component({
  //changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-users-list',
  templateUrl: './users-list.page.html',
  styleUrls: ['./users-list.page.scss'],
})
export class UsersListPage /* implements AfterContentChecked */ {
  //users: Observable<User[]>;
  users

  currentSegment: string = "All";
  color: string;
  disableLink = false;
  selectedItem: any = [];
  selecAllItems: boolean = true;
  timeoutPress = TIMEOUT;

  isLoad = false;

  total_page = 0;
  page_number = 1;
  page_limit = 30;

  toast: any;



  @ViewChild('prj') prjElement: ElementRef;
  @ViewChild('prjs') prjList: ElementRef;
  @ViewChild('star') btnStar: ElementRef;
  @ViewChild(IonSearchbar) myInput: IonSearchbar;

  checkbox: boolean = false;

  constructor(
    //private cdref: ChangeDetectorRef,
    private dataService: DataService,
    private api: ProjectsService,
    public modalController: ModalController,
    private routerOutlet: IonRouterOutlet,
    private renderer: Renderer2,
    //private nav: IonNav,
    private navCtrl: NavController,
    public plt: Platform,
    private toastController: ToastController,
    private titleAppService: TitleAppService

  ) {
    //this.users = this.dataService.getProjects();
    //this.users = this.api.getProjectsList(true);
  }

  ionViewWillEnter() {
    console.log('Will enter');
    this.titleAppService.title.next('Users')

    this.isLoad = false;
    this.toast = undefined;

    this.timeoutPress = TIMEOUT;
    this.disableLink = false;
    this.total_page = 0;
    this.page_number = 1;
    this.myInput.value = "";

    this.loadProjects(this.currentSegment);
  }

  filterProjects(event: any) {
    const selectedCategory = event.detail.value;
    this.currentSegment = selectedCategory;

    //this.loadProjects(selectedCategory);
  }

  loadProjects(status: string) {
    let url = '?page=' + this.page_number + '&items=' + this.page_limit;
    if (status === 'All') { //category
      //this.users = this.dataService.getProjects();
      /* this.api.getProjectsList_rev(url).subscribe(e => {
        if (e && e['results'])
          this.users = e['results']
        console.log(this.users);

      }) */
      return
      this.users =  [
        {
          "_id": "6275910f3b408b2c8e5d9430",
          "index": 0,
          "isActive": false,
          "balance": "$3,451.72",
          "picture": "http://placehold.it/32x32",
          "firstName": "Jenifer Santana",
          "lastName": "Georgia Allison",
          "company": "CABLAM",
          "email": "georgiaallison@cablam.com",
          "email_pec": "georgiaallison@cablam.com",
          "password": "<ReferenceError: password is not defined>",
          "phone": "+1 (953) 554-3136",
          "ragione_sociale": "(933) 565-3821",
          "sdi": "(918) 581-2014",
          "fiscal_code": "+1 (870) 448-2944",
          "address": "194 Stillwell Avenue, 8516",
          "postcode": "(881) 428-2434",
          "country": "140 Ireland",
          "state": "243 New Jersey ",
          "city": "603 Townsend ",
          "registered": "2018-10-11T11:31:32 -02:00",
          "latitude": 20.281459,
          "longitude": 79.62413,
          "brands": [
            "nulla",
            "esse",
            "nostrud",
            "ipsum",
            "sit",
            "consequat",
            "commodo"
          ]
        },
        {
          "_id": "6275910fec6ad813b5609c7e",
          "index": 1,
          "isActive": true,
          "balance": "$2,919.95",
          "picture": "http://placehold.it/32x32",
          "firstName": "Ellen Everett",
          "lastName": "Cline Patterson",
          "company": "COMVEY",
          "email": "clinepatterson@comvey.com",
          "email_pec": "clinepatterson@comvey.com",
          "password": "<ReferenceError: password is not defined>",
          "phone": "+1 (871) 442-2651",
          "ragione_sociale": "(829) 429-2485",
          "sdi": "(984) 481-3777",
          "fiscal_code": "+1 (891) 437-2087",
          "address": "796 Exeter Street, 3277",
          "postcode": "(896) 430-3459",
          "country": "293 India",
          "state": "792 Illinois ",
          "city": "326 Edgewater ",
          "registered": "2018-02-13T06:13:39 -01:00",
          "latitude": 85.922308,
          "longitude": -79.65119,
          "brands": [
            "id",
            "quis",
            "et",
            "id",
            "ea",
            "ut",
            "laboris"
          ]
        },
        {
          "_id": "6275910f62857dee63769881",
          "index": 2,
          "isActive": true,
          "balance": "$3,118.65",
          "picture": "http://placehold.it/32x32",
          "firstName": "Clemons Francis",
          "lastName": "Brandy Johns",
          "company": "ZILCH",
          "email": "brandyjohns@zilch.com",
          "email_pec": "brandyjohns@zilch.com",
          "password": "<ReferenceError: password is not defined>",
          "phone": "+1 (946) 583-3700",
          "ragione_sociale": "(944) 538-2289",
          "sdi": "(842) 446-3667",
          "fiscal_code": "+1 (917) 578-3762",
          "address": "174 Montauk Avenue, 7367",
          "postcode": "(986) 572-3381",
          "country": "977 Svalbard and Jan Mayen Islands",
          "state": "947 Wyoming ",
          "city": "987 Downsville ",
          "registered": "2015-05-17T03:20:33 -02:00",
          "latitude": 23.527317,
          "longitude": 27.51625,
          "brands": [
            "occaecat",
            "quis",
            "tempor",
            "ad",
            "ullamco",
            "commodo",
            "Lorem"
          ]
        },
        {
          "_id": "6275910f45c6d26df5969324",
          "index": 3,
          "isActive": true,
          "balance": "$2,168.15",
          "picture": "http://placehold.it/32x32",
          "firstName": "Flores Palmer",
          "lastName": "Blankenship Spears",
          "company": "ENTOGROK",
          "email": "blankenshipspears@entogrok.com",
          "email_pec": "blankenshipspears@entogrok.com",
          "password": "<ReferenceError: password is not defined>",
          "phone": "+1 (836) 413-3236",
          "ragione_sociale": "(811) 553-2621",
          "sdi": "(852) 421-2389",
          "fiscal_code": "+1 (941) 426-2933",
          "address": "841 Railroad Avenue, 1841",
          "postcode": "(892) 531-2033",
          "country": "603 Pitcairn",
          "state": "401 West Virginia ",
          "city": "769 Witmer ",
          "registered": "2021-08-15T02:35:24 -02:00",
          "latitude": -87.44415,
          "longitude": -46.367511,
          "brands": [
            "aliquip",
            "consectetur",
            "ut",
            "eiusmod",
            "id",
            "excepteur",
            "consectetur"
          ]
        },
        {
          "_id": "6275910fd45cda8b4632fb63",
          "index": 4,
          "isActive": true,
          "balance": "$3,465.35",
          "picture": "http://placehold.it/32x32",
          "firstName": "Geraldine Preston",
          "lastName": "Christian Coleman",
          "company": "DADABASE",
          "email": "christiancoleman@dadabase.com",
          "email_pec": "christiancoleman@dadabase.com",
          "password": "<ReferenceError: password is not defined>",
          "phone": "+1 (966) 480-2306",
          "ragione_sociale": "(984) 479-3501",
          "sdi": "(996) 547-3570",
          "fiscal_code": "+1 (861) 411-3399",
          "address": "583 Blake Avenue, 2968",
          "postcode": "(905) 480-2650",
          "country": "206 China",
          "state": "736 Massachusetts ",
          "city": "763 Haena ",
          "registered": "2015-06-08T09:56:32 -02:00",
          "latitude": -59.309592,
          "longitude": -38.335617,
          "brands": [
            "sunt",
            "consequat",
            "voluptate",
            "ea",
            "tempor",
            "consequat",
            "et"
          ]
        },
        {
          "_id": "6275910fd4a7b0dae3e3bad1",
          "index": 5,
          "isActive": true,
          "balance": "$2,531.64",
          "picture": "http://placehold.it/32x32",
          "firstName": "Alyssa Barnes",
          "lastName": "Vicky Tucker",
          "company": "IMPERIUM",
          "email": "vickytucker@imperium.com",
          "email_pec": "vickytucker@imperium.com",
          "password": "<ReferenceError: password is not defined>",
          "phone": "+1 (979) 538-3958",
          "ragione_sociale": "(917) 519-3960",
          "sdi": "(862) 470-2977",
          "fiscal_code": "+1 (930) 451-2632",
          "address": "689 Gold Street, 569",
          "postcode": "(849) 452-2268",
          "country": "498 Hungary",
          "state": "638 South Carolina ",
          "city": "696 Cascades ",
          "registered": "2017-12-06T04:35:27 -01:00",
          "latitude": -64.893968,
          "longitude": -145.740585,
          "brands": [
            "aliquip",
            "ut",
            "eu",
            "eu",
            "officia",
            "sunt",
            "cillum"
          ]
        },
        {
          "_id": "6275910facb08ea1dde12a12",
          "index": 6,
          "isActive": false,
          "balance": "$3,756.77",
          "picture": "http://placehold.it/32x32",
          "firstName": "Campbell Reeves",
          "lastName": "Levy Hewitt",
          "company": "QUIZKA",
          "email": "levyhewitt@quizka.com",
          "email_pec": "levyhewitt@quizka.com",
          "password": "<ReferenceError: password is not defined>",
          "phone": "+1 (940) 446-3604",
          "ragione_sociale": "(817) 454-2924",
          "sdi": "(850) 597-3603",
          "fiscal_code": "+1 (935) 513-3656",
          "address": "527 Thames Street, 4055",
          "postcode": "(943) 403-2429",
          "country": "137 Sri Lanka",
          "state": "606 Iowa ",
          "city": "586 Ronco ",
          "registered": "2020-08-18T08:33:24 -02:00",
          "latitude": -50.975502,
          "longitude": 14.763027,
          "brands": [
            "amet",
            "culpa",
            "sunt",
            "sint",
            "do",
            "do",
            "proident"
          ]
        },
        {
          "_id": "6275910f5361655c269361da",
          "index": 7,
          "isActive": true,
          "balance": "$3,304.56",
          "picture": "http://placehold.it/32x32",
          "firstName": "Russo Parker",
          "lastName": "Underwood Hendrix",
          "company": "TELEQUIET",
          "email": "underwoodhendrix@telequiet.com",
          "email_pec": "underwoodhendrix@telequiet.com",
          "password": "<ReferenceError: password is not defined>",
          "phone": "+1 (906) 535-3917",
          "ragione_sociale": "(926) 430-3227",
          "sdi": "(979) 573-3331",
          "fiscal_code": "+1 (995) 600-3054",
          "address": "561 Prince Street, 369",
          "postcode": "(933) 566-3293",
          "country": "640 Romania",
          "state": "498 Northern Mariana Islands ",
          "city": "339 Manila ",
          "registered": "2022-01-08T06:35:50 -01:00",
          "latitude": 24.522687,
          "longitude": -86.928846,
          "brands": [
            "sint",
            "sunt",
            "non",
            "do",
            "anim",
            "fugiat",
            "esse"
          ]
        },
        {
          "_id": "6275910f3e5f628254e9730a",
          "index": 8,
          "isActive": false,
          "balance": "$3,124.20",
          "picture": "http://placehold.it/32x32",
          "firstName": "Fischer Simpson",
          "lastName": "Turner Parsons",
          "company": "SONIQUE",
          "email": "turnerparsons@sonique.com",
          "email_pec": "turnerparsons@sonique.com",
          "password": "<ReferenceError: password is not defined>",
          "phone": "+1 (972) 521-3235",
          "ragione_sociale": "(996) 568-3977",
          "sdi": "(945) 545-3825",
          "fiscal_code": "+1 (837) 486-3237",
          "address": "920 Vandervoort Place, 4687",
          "postcode": "(976) 489-3739",
          "country": "631 Mayotte",
          "state": "913 Georgia ",
          "city": "293 Herald ",
          "registered": "2014-05-16T04:09:43 -02:00",
          "latitude": -8.292634,
          "longitude": -80.991889,
          "brands": [
            "do",
            "reprehenderit",
            "tempor",
            "sint",
            "ex",
            "cupidatat",
            "laboris"
          ]
        },
        {
          "_id": "6275910fe8848e06af5cd4e0",
          "index": 9,
          "isActive": true,
          "balance": "$3,390.82",
          "picture": "http://placehold.it/32x32",
          "firstName": "Fanny Zamora",
          "lastName": "Casandra Vazquez",
          "company": "PLAYCE",
          "email": "casandravazquez@playce.com",
          "email_pec": "casandravazquez@playce.com",
          "password": "<ReferenceError: password is not defined>",
          "phone": "+1 (852) 595-3987",
          "ragione_sociale": "(956) 442-3997",
          "sdi": "(935) 579-2707",
          "fiscal_code": "+1 (969) 506-2372",
          "address": "208 Seba Avenue, 5847",
          "postcode": "(963) 492-3753",
          "country": "236 Comoros",
          "state": "641 Federated States Of Micronesia ",
          "city": "412 Allentown ",
          "registered": "2019-09-29T12:13:54 -02:00",
          "latitude": -21.066938,
          "longitude": 140.173711,
          "brands": [
            "cupidatat",
            "sint",
            "reprehenderit",
            "elit",
            "duis",
            "incididunt",
            "aliquip"
          ]
        }
      ]

      this.isLoad = true;
    } else {
      //this.users = this.dataService.getProjectsByCategory(category);
      //this.users = this.dataService.getProjectsByStatus(status);
      this.isLoad = true;
    }

  }

  async openNewProjectModal() {
    const modal = await this.modalController.create({
      component: NewUserComponent,
      canDismiss: true,
      presentingElement: this.routerOutlet.nativeEl,
    });

    modal.onWillDismiss().then((e) => {

      if (e.data && e.data.message != 'OK') {
        console.log('no modify');
        return
      } else {
        this.loadProjects(this.currentSegment);

      }
    });

    return await modal.present();
  }

  randomColor() {
    const colors = ["#4caf50", "#ffc107", "#607d8b", "#2196f3", "#e91e63", "#673ab7", "#009688"];
    const indexColor = Math.floor(Math.random() * colors.length);
    const randomColor = colors[indexColor];
    //this.color == randomColor ? ,
    return randomColor; //this.color;
  }


  /*   ngAfterContentChecked() {
      this.cdref.detectChanges();
    } */

  methodAction(action) {
    try {
      this[action]()
    } catch {
      console.log('not call in parent component');
    }
    //console.log(action)
  }

  /**
   * @description onClick on row open order details
   * @param event pass obj row clicked
   */
  onActivate(event) {
    /* if (this.table.rowDetail && event.type == 'click') {
      this.table.rowDetail.toggleExpandRow(event.row);
      console.log('Activate Event', event);
    } */
    console.log(event);

    if (event.type == 'click') {
      console.log('Activate Event', event);
      this.goTo(event.row.id, event.row, 1)
    }


  }




  /**
   * Select item on longpress + multiple select + selecAll
   * @param item Elemento della lista
   */

  activeLine(item) {
    setTimeout(() => {

      this.renderer.addClass(item, 'select');
      this.renderer.setStyle(item.firstChild, 'transform', 'rotate3d(0, 1, 0, 360deg)');
      this.renderer.setProperty(item.firstChild, 'innerHTML', '<ion-icon name="checkmark-outline"></ion-icon>');
      /* let icon = this.renderer.createElement('ion-icon');
      this.renderer.setAttribute(icon, 'name', 'checkmark-outline');
      this.renderer.appendChild(item, icon) */
    }, 100);
  }

  dectiveLine(item) {
    setTimeout(() => {
      this.renderer.removeClass(item, 'select');
      this.renderer.setStyle(item.firstChild, 'transform', 'rotate3d(0, 0, 0, 0deg)');
      let letter = item.innerText.split('')[0]
      this.renderer.setProperty(item.firstChild, 'innerHTML', '<span class="letter_badge">' + letter + '</span>');
    }, 100);
  }

  resetSelect() {
    this.disableLink = false;
    this.selectedItem.map((e, a, i) => {
      this.dectiveLine(e.el);
    });
    this.selectedItem = [];
  }

  selectAll(event, list) {
    let prjs = [];
    for (let e = 0; e <= list.el.children.length - 1; e++) {
      if (!event.detail.checked) {
        this.resetSelect();
      } else {
        this.activeLine(list.el.children[e]);
        prjs.push({
          el: list.el.children[e],
          id: Number(list.el.children[e].id)
        });
      }
    }
    this.checkbox = true;
    this.selectedItem = prjs;
  }

  goTo(item, prj, action) {

    //console.log(this.nav);;
    
    let ids;
    let i = this.selectedItem.findIndex(e => e.id === Number(item));


    {
      //console.log('this.selectedItem ID', i);
      //console.log('action', action);
      //console.log('disableLink', this.disableLink);


      if (action == 1 && !this.disableLink) {
        //console.log('goTo', item);
        //console.log('goTo', this.selectedItem);

        //nav: this.nav(UserDetailsPage, { id: item }, { animated: true });

      } else if (action == 2 && this.disableLink) {
        //console.log('addTo', item);
        //console.log('addTo', this.selectedItem);

        if (i == -1) {
          this.activeLine(prj.el)
          prj.id = Number(item);
          this.selectedItem.push(prj);
          this.toast ? this.toastController.dismiss() : null;

        } else {
          this.dectiveLine(this.selectedItem[i].el);
          this.selectedItem.splice(i, 1);
          this.checkbox = false;

          this.toast ? this.toastController.dismiss() : null;

          if (this.selectedItem.length == 0) {
            this.disableLink = false;
          }

        }

        this.toast = this.presentToast(this.selectedItem.length + ' Elementi selezionati', 'dark');

      }

    }

    ids = this.selectedItem.map(o => o.id)
    this.selectedItem = this.selectedItem.filter(({ id }, index) => !ids.includes(id, index + 1))
    //console.log(this.selectedItem);

  }

  onLongPress(event, $event?) {
    console.log("onLongPress", $event);
    this.timeoutPress = TIMEOUT;

  }

  onLongPressing(event, $event?: MouseEvent) {
    if (this.timeoutPress == TIMEOUT) {

      //console.log("onLongPressing", event, $event);

      //console.log('onLongPressing2-2');

      this.renderer.addClass(event.el, 'select');
      this.renderer.setStyle(event.el.firstChild, 'transform', 'rotate3d(0, 1, 0, 360deg)');
      this.renderer.setProperty(event.el.firstChild, 'innerHTML', '<ion-icon name="checkmark-outline"></ion-icon');
      this.disableLink = true;
      this.timeoutPress = this.timeoutPress - 1;
      console.log(this.timeoutPress);
    }

  }



  clickStar(elem, event) {
    event.stopPropagation();
    console.log(elem);
    if (this.selecAllItems) {
      this.renderer.setProperty(elem.el, 'name', 'star')
      this.renderer.setStyle(elem.el, 'color', '#fff')
    } else {
      this.renderer.setProperty(elem.el, 'name', 'star-outline')
      this.renderer.setStyle(elem.el, 'color', '#fff')

    }
    this.selecAllItems = !this.selecAllItems;

  }


  async presentToast(text, color) {
    const toast = await this.toastController.create({
      message: text,
      position: 'bottom',
      duration: 2000,
      cssClass: 'toast',
      color: color
    });
    toast.present();
  }


}

