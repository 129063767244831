import { APP_INITIALIZER, LOCALE_ID, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HttpClientXsrfModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { IonicModule, IonicRouteStrategy} from '@ionic/angular';
//import { MomentModule } from 'ngx-moment';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { appInitializer } from './helpers/app.inizializer';
import { AuthenticationService } from './services/authentication/authentication.service';
import { IonicStorageModule } from '@ionic/storage-angular';
import { Storage, Drivers } from '@ionic/storage';
import { ApiService } from './services/api/api.service';
import { OfflineManagerService } from './services/network/offline-manager.service';
import { NetworkService } from './services/network/network.service';
import { DataService } from './services/data/data.service';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { SharedModule } from './shared/shared.module';



import player from 'lottie-web';

// The translate loader needs to know where to load i18n files
// in Ionic's static asset pipeline.
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
import { NgxEchartsModule } from 'ngx-echarts';
import * as echarts from 'echarts';
import { CurrencyPipe, DatePipe, registerLocaleData } from '@angular/common';
import localeDeAt from '@angular/common/locales/it';
import * as moment from 'moment';
import { NewProjectComponent } from './projects/new-project/new-project.component';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { environment } from 'src/environments/environment';
import { NumberSuffixPipe } from './pipes/number-suffix.pipe';

import { ThemeService } from './services/theme/theme.service';

import { FormatFileSizePipe } from './pipes/format-file-size.pipe';
import { IonLoaderService } from './services/ion-loader/ion-loader.service';
import { CacheInterceptor } from './helpers/check.interceptor';
import { ConfigService, configServiceFactory } from './services/googleapis/config.service';
import { OauthService } from './services/googleapis/oauth.service';
import { OauthAuthorizationService } from './services/googleapis/oauth-auth.service';

import { File } from '@awesome-cordova-plugins/file/ngx';

import { NewVendorComponent } from './vendors/new-vendor/new-vendor.component';
import { ModalContentComponent } from './components/form/modal-content/modal-content.component';
import { TabsService } from './services/tabs/tabs.service';


//import { Uid } from '@ionic-native/uid/ngx';
//import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';

// Import ng-circle-progress
import { NgCircleProgressModule } from 'ng-circle-progress';
import { SubMenuService } from './services/utils/sub-menu/sub-menu.service';
import { SubMenuFilterComponent } from './components/sub-menu-filter/sub-menu-filter.component';

import { CurrencySymbolPipe } from './pipes/currency/currency-symbol.pipe';
import { ClarityModule } from "@clr/angular";


// modules
import { GeoLocationService } from './services/geolocation/geo-location.service';
import { NgxPaginationModule } from 'ngx-pagination';
import { LinesDescriptionPipe } from './pipes/lines-description.pipe';
import { ModalOrderDiscountLineComponent } from './orders/components/discount-line/modal/modal.component';
import { ModalJobDiscountLineComponent } from './jobs/components/discount-line/modal/modal.component';
import { InvoiceJobLineComponent } from './jobs/job-details/invoice-line/invoice-line.component';
import { StandardComponent } from './standard/standard.component';
import { TabBarPage } from './tab-bar/tab-bar.page';
import { DiscountLineComponent } from './orders/components/discount-line/discount-line.component';
import { WorkHoursPipe } from './pipes/work-hours.pipe';

const token_auth = localStorage.getItem('access-token');

//This function should be above the @ngModel decorator
/* export  function getTokenAuth(storage: Storage) {
  return async (): Promise<any>  => token_auth ? null : token_auth = await storage.get('authService-auth-token');
} */

export function getToken() {
    return token_auth;
}

moment.locale('it');
registerLocaleData(localeDeAt);


// Note we need a separate function as it's required
// by the AOT compiler.
export function playerFactory() {
    return player;
}

@NgModule({
    declarations: [AppComponent, SubMenuFilterComponent, DiscountLineComponent, LinesDescriptionPipe,  ModalOrderDiscountLineComponent, ModalJobDiscountLineComponent, InvoiceJobLineComponent, StandardComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        // IonicModule.forRoot()
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        IonicModule.forRoot({
            rippleEffect: true,
            mode: 'md',
            innerHTMLTemplatesEnabled: true 
            //backButtonText: 'Go Back'
        },
        ),
        IonicStorageModule.forRoot({
            // Ensure name is the same here
            name: '__mydb',
            driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
        }),
        // Specify ng-circle-progress as an import
        NgCircleProgressModule.forRoot({
            "showBackground": false
        }),
        AppRoutingModule,
        ClarityModule,
        NgxPaginationModule,
        FormsModule,
        ReactiveFormsModule,
        //SharedModule,
        HttpClientModule,
        HttpClientXsrfModule.withOptions({
            cookieName: 'XSRF-TOKEN',
            headerName: 'X-XSRF-TOKEN',
        }),
        NgxEchartsModule.forRoot({
            echarts
        }),
        //DragulaModule.forRoot(),
        SharedModule,
    ],
    providers: [
        ThemeService,
        DataService,
        SubMenuService,
        Storage,
        Geolocation,
        GeoLocationService,
        NetworkService,
        OfflineManagerService,
        AuthenticationService,
        ConfigService,
        OauthService,
        OauthAuthorizationService,
        ApiService,
        IonLoaderService,
        File,
        TabsService,
        DatePipe,
        CurrencySymbolPipe,
        CurrencyPipe,
        LinesDescriptionPipe,
        WorkHoursPipe,
        StandardComponent,
        TabBarPage,
        //AndroidPermissions,
        { provide: LOCALE_ID, useValue: 'it-IT' },
        { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [Storage, AuthenticationService] },
        {
            provide: APP_INITIALIZER,
            useFactory: configServiceFactory,
            deps: [ConfigService],
            multi: true
        },
        //{ provide: APP_INITIALIZER, useFactory: getTokenAuth, deps: [Storage], multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
//        { provide: HTTP_INTERCEPTORS, useClass: RetryInterceptor, multi: true },
//        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        //{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        //{ provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    ],
    schemas:[CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent,]
})
export class AppModule { }
