/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable object-shorthand */
/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/semi */


import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { LoadingController, MenuController, ModalController, NavController, Platform, ToastController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { AnimationOptions } from 'ngx-lottie';
import { AnimationItem } from 'lottie-web';


@Component({
    selector: 'app-modal-nfc',
    templateUrl: './nfc.component.html',
    styleUrls: ['./nfc.component.scss'],
})
export class ModalNFCComponent {

    @Input() status
    @Input() duration
    y = 0;
    count = 0;
    opacity = 1;
    opacity2 = false;
    loading
    typePreogress
    time
    percent


    private animationItem!: AnimationItem;

    options: AnimationOptions = {
        name: 'test',
        path: '/assets/lottie/sensor-scan.json',
        autoplay: true,
        loop: false,

    };

    constructor(
        private authService: AuthenticationService,
        private router: Router,
        private navCrtl: NavController,
        private menu: MenuController,
        private platform: Platform,
        public modalController: ModalController,
        private toastController: ToastController,
        private loadingController: LoadingController
    ) {
        console.log('NFC Modal')
    }



    animationCreated(animationItem: AnimationItem): void {
        console.log('animationItem', animationItem);
        if (animationItem) {
            this.animationItem = animationItem;
            this.animationItem.stop()
            //this.animationItem.goToAndStop()
            ////this.animationItem.play()
            ////this.animationItem.setSpeed(2 )
        }
    }

    ionViewDidEnter() {

        this.time = undefined;
        this.typePreogress = '';
        this.opacity = 1;
        this.opacity2 = false;
        this.count = 0;
        this.percent = 0;

        this.time = setInterval(() => {
            this.count = this.count + 0.004

            this.status.value == 0 ? this.percent = 100 : this.percent = Number(this.count.toFixed(2)) * 100
            //console.log(this.count);
            if (this.count >= 1) {
                //this.opacity = 0;
                //this.opacity2 = true;

                //console.log(this.count);
                clearInterval(this.time)
                //this.typePreogress = 'indeterminate';
            } else if (this.count == 0.9080000000000007) {

                this.loadingController.getTop().then(v => v ? this.loadingController.dismiss() : null);
                this.presentLoadingWithOptions('Attendere...', 2000);
                this.opacity = 0;
            }
        }, 10)
    }

    /* closeModal() {
        this.modalController.dismiss()
        this.toastController.dismiss()
    } */

    ionViewDidLeave() {
        //this.loadingController.dismiss()
        clearInterval(this.time)
        this.loadingController.getTop().then(v => v ? this.loadingController.dismiss() : null);
    }


    async presentToastWithOptions(msg) {
        const toast = await this.toastController.create({
            //header: 'NFC Reader Tag',
            duration: 1000,
            color: this.y != 1 ? 'dark' : 'dark',
            message: msg,
            //icon: this.y != 1 ? 'checkmark-circle-outline' : 'close-circle-outline',
            position: 'bottom',
            /*    buttons: [
           {
             side: 'start',
             icon: 'star',
             text: 'TAG',
             handler: () => {
               console.log('Favorite clicked');
             }
           },  {
                       text: 'OK',
                       role: 'cancel',
                       handler: () => {
                           console.log('Cancel clicked');
                       }
                   }
               ]*/
        });
        await toast.present();

        const { role } = await toast.onDidDismiss();
        console.log('onDidDismiss resolved with role', role);
    }

    async presentLoadingWithOptions(msg, duration) {
        this.loading = await this.loadingController.create({
            spinner: null,
            duration: duration,
            message: msg,
            translucent: true,
            cssClass: 'custom-class custom-loading'
        });
        return await this.loading.present();
    }

}