/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable max-len */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Platform, ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { from, Observable, of } from 'rxjs';
import { catchError, delay, distinctUntilChanged, map, retryWhen, scan, tap, timeout } from 'rxjs/operators';
import { Project } from 'src/app/projects/models/projects';
import { environment } from 'src/environments/environment';
//import environment from '../../../config.json';
import { AuthenticationService } from '../authentication/authentication.service';
import { NetworkService, ConnectionStatus } from '../network/network.service';
//import { Storage } from '@ionic/storage';
import { OfflineManagerService } from '../network/offline-manager.service';



const API_STORAGE_KEY = 'prjService';
const TOKEN_KEY = 'auth-token';

@Injectable({
  providedIn: 'root'
})
export class ProjectsService {

  private url: string = 'http'
  private token: any;

  constructor(
    private storage: Storage,
    private plt: Platform,
    private http: HttpClient,
    public toastController: ToastController,
    private authService: AuthenticationService,
    private networkService: NetworkService,
    private offlineManager: OfflineManagerService
  ) {

    /**
     * Map url API
    */
    this.url = environment.SSL.active ? this.url + 's' : this.url
    this.url += '://' + environment.API_URI.url + ':' + environment.API_URI.port + environment.API_URI.path

    this.authService.currentToken.subscribe(x => {
      //console.log(x);
      this.token = x //= JSON.parse(localStorage.getItem(TOKEN_KEY))

    })

  }



  /**
   * 
   * @param forceRefresh true or false for force refresh api req
   * @returns lista brands fgb
   */
  searchProjectsList_rev(forceRefresh: boolean = true, url): Observable<any> {

    this.logSys('getSearchProjects', { status: true })

    return this.http.get<any>(`${this.url}project/projects/${url}`)
      .pipe(
        timeout(46000),
        delay(new Date(Date.now() + 500)),
        tap(data => {

          console.log(data);
          this.setLocalData('list', data);
          return data
        }),
        catchError(this.handleError<any[]>('Get search projects rev', []))
      );

  }


  /**
   * 
   * @param forceRefresh true or false for force refresh api req
   * @returns lista brands fgb
   */
  getProjectsList_fgb(forceRefresh: boolean = true): Observable<any> {
    //return of(mokService);
    this.logSys(forceRefresh, { status: true })

    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline || !forceRefresh) {
      // Return the cached data from Storage
      return from(this.getLocalData('brands'));
    } else {
      return this.http.post<any>(`${this.url}brands`, { accessToken: this.token })
        .pipe(
          timeout(16000),
          tap(data => {
            console.log(data);
            this.setLocalData('brands', data);
            return data
          }),
          catchError(this.handleError<any[]>('Get brands fgb', []))
        );
    }
  }

  /**
   * 
   * @param forceRefresh true or false for force refresh api req
   * @returns lista brands rev
   */
  getProjectsList_rev(params?): Observable<any> {
    //return of(mokService);

    params == undefined ? params = '' : null
    this.logSys('getProjects', { status: true }, params)

    return this.http.get<any>(`${this.url}project/project/committer_by_project/`)
      .pipe(
        timeout(46000),
        // delay(new Date(Date.now() + 500)),
        tap(data => {

          //console.log(data);
          this.setLocalData('list', data);
          return data
        }),
        catchError(this.handleError<any[]>('Get projects rev', []))
      );

  }

  /**
   * 
   * @param forceRefresh true or false for force refresh api req
   * @returns lista dei progettoi parent brand fgb
   */
  getLocationList_fgb(id, forceRefresh: boolean = true): Observable<any> {
    //return of(mokService.brands.find(prj => prj.id == id));

    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline || !forceRefresh) {
      // Return the cached data from Storage
      return from(this.getLocalData('projects_' + id));
    } else {
      return this.http.post<any>(`${this.url}project`, { accessToken: this.token, brand: Number(id) })
        .pipe(
          tap(data => {
            //console.log(data);
            this.setLocalData('projects_' + id, data);
            return data
          }),
          catchError(this.handleError<any[]>('Get projects fgb', []))
        );
    }
  }

  /**
   * 
   * @param forceRefresh true or false for force refresh api req
   * @returns lista dei progettoi parent brand rev
   */
  getLocationList_rev(id, forceRefresh: boolean = true): Observable<any> {
    //return of(mokService.brands.find(prj => prj.id == id));
    //this.logSys(forceRefresh)
    this.logSys('searchProjects', { forceRefresh, status: true })


    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline || !forceRefresh) {
      // Return the cached data from Storage
      return from(this.getLocalData('projects_' + id));
    } else {
      return this.http.get<any>(`${this.url}project/project/${id}/`)
        .pipe(
          tap(data => {
            //console.log(data);
            this.setLocalData('list-childs' + id, data);
            return data
          }),
          catchError(this.handleError<any[]>('Get projects rev', []))
        );
    }
  }

  createStakehoder(project, form): Observable<any> {
    return this.http.post<any>(`${this.url}project/project/${project.id}/stakeholders/`, form)
      .pipe(
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Create stakeholder for projects rev', []))
      );
  }


  /*   getTypeStakehoder(): Observable<any> {
      return this.http.post<any>(`${this.url}project/project/${project.id}/stakeholders/`, form)
        .pipe(
          tap(data => {
            console.log(data);
            return data
          }),
          retryWhen(errors => errors.pipe( 
            scan((acc, error) => {
              if (error.status == 500) throw error;
  if (acc > 2) throw error;
              console.log("attempt " + acc);
              return acc + 1;
            }, 1),
            delay(3000),
            tap(data => {
              console.log(data);
              return data
            }),
  
          )),
          catchError(this.handleError<any[]>('Create stakeholder for projects rev', []))
        );
    } */


  addTask(form): Observable<any> {
    return this.http.post<any>(`${this.url}project/tasks/`, form)
      .pipe(
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Create stakeholder for projects rev', []))
      );
  }

  getStakehoder(project): Observable<any> {
    return this.http.get<any>(`${this.url}project/project/${project.id}/stakeholders/`)
      .pipe(
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Get stakeholder for projects rev', []))
      );
  }

  createProject(project: Project) {
    return this.http.post<any>(`${this.url}project/projects/`, project)
      .pipe(
        tap(data => {
          console.log(data);
        }),
        catchError(this.handleError<any[]>('Create project', []))
      );
  }

  createInfoProject(id, fields) {
    return this.http.post<any>(`${this.url}project/project/${id}/info/create`, fields)
      .pipe(
        tap(data => {
          console.log(data);
        }),
        catchError(this.handleError<any[]>('Create info project', []))
      );
  }

  getTypesList(): Observable<any[]> {
    return this.http.get<any>(`${this.url}project/types/`)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          this.setLocalData('types', data);
          return data;
        }),
        catchError(this.handleError<any[]>('Get project types list', []))
      );
  }

  getSequencesList(): Observable<any[]> {
    return this.http.get<any>(`${this.url}project/sequences/`)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          this.setLocalData('sequences', data);
          return data;
        }),
        catchError(this.handleError<any[]>('Get project sequences list', []))
      );
  }

  getProjectById(id): Observable<any> {

    return this.http.get<any>(`${this.url}project/project/${id}/`)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          this.setLocalData('project-details', data);
          return data;
        }),
        catchError(this.handleError<any[]>('Get project by id', []))
      );
  }

  getProjectByCommiterId(id): Observable<any> {

    return this.http.get<any>(`${this.url}project/projects/?committer_id=${id}`)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          this.setLocalData('project-details', data);
          return data;
        }),
        catchError(this.handleError<any[]>('Get project by id', []))
      );
  }

    /**
* 
*
* @returns list companies
*/
getCompaniesList(params?): Observable<any> {
  params == undefined ? params = '' : null
  //return this.http.get<any>(url)
  return this.http.get<any>(`${this.url}company/companies/`)
    .pipe(
      timeout(50000),
      tap(data => {
        console.log(data);
        this.setLocalData('list', data);
        return data
      }),
      catchError(this.handleError<any[]>('Get vendors list', []))
    );

}






  private handleError<T>(operation = 'operation', result?: T) {

    return (error: any): Observable<T> => {

      console.error(error);
      console.log(result, error);

      console.log(`${operation} failed: ${error}`);
      let status = error == 'Forbidden' ? 403 : 500
      this.presentToast(status, error == 'undefined' ? 'Internal Server Error' : error, operation)
      return of(error.error as T);
    };
  }


  async presentToast(status, statusText, message) {
    const toast = await this.toastController.create({
      message: status + ' ' + statusText + ': "' + message + '"',
      duration: 2000,
      mode: 'ios',
      color: status != '200' ? 'danger' : 'primary'
    });
    toast.present();
  }


  logSys(src, status, opt?) {
    const debugStyle1 = 'background: linear-gradient(135deg,#471ee9,#3a49b7); border: 1px solid #9a9a9a; color: #ffffff; border-bottom-left-radius: 2px; border-top-left-radius: 2px; padding: 2px 0 2px 4px;';
    const debugStyle2 = 'background: #252b3e; border: 1px solid #9a9a9a; border-top-right-radius: 2px; border-bottom-right-radius: 2px; margin-left: -2px; padding: 2px 4px; color: white;';

    //console.log('ForceRefresh API ', status, this.networkService.getCurrentNetworkStatus(), this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline);
    console.log(`%cApiService %c%s`, debugStyle1, debugStyle2, ' ' + src, status, opt);
  }



  // Save result of API requests
  private setLocalData(key, data) {
    this.storage.set(`${API_STORAGE_KEY}-${key}`, data);
  }

  // Get cached API result
  private getLocalData(key) {
    return this.storage.get(`${API_STORAGE_KEY}-${key}`);
  }
}
