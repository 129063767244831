import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Platform, ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import {
  catchError,
  delay,
  distinctUntilChanged,
  map,
  retryWhen,
  scan,
  tap,
  timeout,
} from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../authentication/authentication.service';
import { NetworkService, ConnectionStatus } from '../network/network.service';
//import { Storage } from '@ionic/storage';
import { OfflineManagerService } from '../network/offline-manager.service';

//import environment from '../../../config.json';
import { Project } from 'src/app/projects/models/projects';
import { Docs } from 'src/app/documents/models/docs';
//import { Customer } from 'src/app/customers/models/customer.model';

const API_STORAGE_KEY = 'wokrerservice';
const TOKEN_KEY = 'access-token';

@Injectable({
  providedIn: 'root',
})
export class HrService {
  private url: string = 'http';
  private token: any;
  private projects: Project[];
  private documents: Docs[];
  private document: Docs;
  private project_types: any[];
  //public docList = new BehaviorSubject(null);

  constructor(
    private storage: Storage,
    private plt: Platform,
    private http: HttpClient,
    public toastController: ToastController,
    private authService: AuthenticationService,
    private networkService: NetworkService,
    private offlineManager: OfflineManagerService
  ) {
    /**
     * Map url API
     */
    this.url = environment.SSL.active ? this.url + 's' : this.url;
    this.url +=
      '://' +
      environment.API_URI.url +
      ':' +
      environment.API_URI.port +
      environment.API_URI.path;

    this.authService.currentToken.subscribe((x) => {
      //console.log(x);
      this.token = x; //= JSON.parse(localStorage.getItem(TOKEN_KEY))
    });
  }

  /**
   *
   * Wokrers
   */

  /**
   *
   *
   * @returns list tasks
   */
  getApplicantList(params?): Observable<any> {
    params == undefined ? (params = '') : null;
    //return this.http.get<any>(`${this.url}task/tasks/?employee_id=${params}`)
    return this.http.get<any>(`${this.url}person/applicant/`).pipe(
      timeout(16000),
      tap((data) => {
        console.log(data);
        return data;
      }),
      catchError(this.handleError<any[]>('Get person list', []))
    );
  }

  getAddressId(id?): Observable<any> {
    //return this.http.get<any>(`${this.url}task/tasks/?employee_id=${params}`)
    return this.http.get<any>(`${this.url}person/address/${id}/`).pipe(
      timeout(16000),
      tap((data) => {
        console.log(data);
        return data;
      }),
      catchError(this.handleError<any[]>('Get person list', []))
    );
  }

  newApplicant(form?): Observable<any> {
    //return this.http.get<any>(`${this.url}task/tasks/?employee_id=${params}`)
    return this.http.post<any>(`${this.url}person/applicant/`, form).pipe(
      timeout(16000),
      tap((data) => {
        console.log(data);
        return data;
      }),
      catchError(this.handleError<any[]>('Get person list', []))
    );
  }

  newResearch(form?): Observable<any> {
    //return this.http.get<any>(`${this.url}task/tasks/?employee_id=${params}`)
    return this.http.post<any>(`${this.url}person/researches/`, form).pipe(
      timeout(16000),
      tap((data) => {
        console.log(data);
        return data;
      }),
      catchError(this.handleError<any[]>('Get person list', []))
    );
  }


  updateResearch(id?:any, form?): Observable<any> {
    //return this.http.get<any>(`${this.url}task/tasks/?employee_id=${params}`)
    return this.http.put<any>(`${this.url}person/research/${id}/`, form).pipe(
      timeout(16000),
      tap((data) => {
        console.log(data);
        return data;
      }),
      catchError(this.handleError<any[]>('put updateResearch', []))
    );
  }

  updateContact(id?:any, form?): Observable<any> {
    //return this.http.get<any>(`${this.url}task/tasks/?employee_id=${params}`)
    return this.http.put<any>(`${this.url}person/contact/${id}/`, form).pipe(
      timeout(16000),
      tap((data) => {
        console.log(data);
        return data;
      }),
      catchError(this.handleError<any[]>('Get person list', []))
    );
  }

  getLocationTypes(): Observable<any> {
    //return this.http.get<any>(`${this.url}task/tasks/?employee_id=${params}`)
    return this.http.get<any>(`${this.url}geo/locationtypes/`).pipe(
      timeout(16000),
      tap((data) => {
        console.log(data);
        return data;
      }),
      catchError(this.handleError<any[]>('Post upload cv', []))
    );
  }

  addLocation(form:any): Observable<any> {
    //return this.http.get<any>(`${this.url}task/tasks/?employee_id=${params}`)
    return this.http.post<any>(`${this.url}geo/locations/`, form).pipe(
      timeout(16000),
      tap((data) => {
        console.log(data);
        return data;
      }),
      catchError(this.handleError<any[]>('Post location', []))
    );
  }

  addLocationToCompany(form:any): Observable<any> {
    //return this.http.get<any>(`${this.url}task/tasks/?employee_id=${params}`)
    return this.http.post<any>(`${this.url}company/addresses/`, form).pipe(
      timeout(16000),
      tap((data) => {
        console.log(data);
        return data;
      }),
      catchError(this.handleError<any[]>('Post location to company', []))
    );
  }
  addAddressToCompany(form:any): Observable<any> {
    //return this.http.get<any>(`${this.url}task/tasks/?employee_id=${params}`)
    return this.http.post<any>(`${this.url}company/address/`, form).pipe(
      timeout(16000),
      tap((data) => {
        console.log(data);
        return data;
      }),
      catchError(this.handleError<any[]>('Post location to company', []))
    );
  }


  
  deleteLocationCompany(id:any): Observable<any> {
    //return this.http.get<any>(`${this.url}task/tasks/?employee_id=${params}`)
    return this.http.delete<any>(`${this.url}company/address/${id}`).pipe(
      timeout(16000),
      tap((data) => {
        console.log(data);
        return data;
      }),
      catchError(this.handleError<any[]>('Delete location to company', []))
    );
  }

  // ?page=1&items=25
  getCountriesList(name:any): Observable<any> {
    //return this.http.get<any>(`${this.url}task/tasks/?employee_id=${params}`)
    return this.http.get<any>(`${this.url}geo/countries/?name=${name}&items=100`).pipe(
      timeout(16000),
      tap((data) => {
        console.log(data);
        return data;
      }),
      catchError(this.handleError<any[]>('Get person list', []))
    );
  }

  getCompaniesList(name:any): Observable<any> {
    //return this.http.get<any>(`${this.url}task/tasks/?employee_id=${params}`)
    return this.http.get<any>(`${this.url}company/companies/?name=${name}`).pipe(
      timeout(16000),
      tap((data) => {
        console.log(data);
        return data;
      }),
      catchError(this.handleError<any[]>('Get companies list', []))
    );
  }


  uploadCV(id?:any, form?): Observable<any> {
    //return this.http.get<any>(`${this.url}task/tasks/?employee_id=${params}`)
    return this.http.post<any>(`${this.url}person/applicant/${id}/curriculum/`, form).pipe(
      timeout(16000),
      tap((data) => {
        console.log(data);
        return data;
      }),
      catchError(this.handleError<any[]>('Post upload cv', []))
    );
  }

  uploadAnagrafica(id?:any, form?): Observable<any> {
    //return this.http.get<any>(`${this.url}task/tasks/?employee_id=${params}`)
    return this.http.post<any>(`${this.url}person/applicant/${id}/anagrafica/`, form).pipe(
      timeout(16000),
      tap((data) => {
        console.log(data);
        return data;
      }),
      catchError(this.handleError<any[]>('Post upload cv', []))
    );
  }

  uploadGDPR(id?:any, form?): Observable<any> {
    //return this.http.get<any>(`${this.url}task/tasks/?employee_id=${params}`)
    return this.http.post<any>(`${this.url}person/applicant/${id}/privacy/`, form).pipe(
      timeout(16000),
      tap((data) => {
        console.log(data);
        return data;
      }),
      catchError(this.handleError<any[]>('Post upload privacy', []))
    );
  }
  
  editMail(id?:any, form?): Observable<any> {
    //return this.http.get<any>(`${this.url}task/tasks/?employee_id=${params}`)
    return this.http.post<any>(`${this.url}person/applicant/${id}/mail/`, form).pipe(
      timeout(16000),
      tap((data) => {
        console.log(data);
        return data;
      }),
      catchError(this.handleError<any[]>('Post upload mail', []))
    );
  }

  editPhone(id?:any, form?): Observable<any> {
    //return this.http.get<any>(`${this.url}task/tasks/?employee_id=${params}`)
    return this.http.post<any>(`${this.url}person/applicant/${id}/phone/`, form).pipe(
      timeout(16000),
      tap((data) => {
        console.log(data);
        return data;
      }),
      catchError(this.handleError<any[]>('Post upload phone', []))
    );
  }


  downloadDocuments(id?:any, doc_id?:any): Observable<any> {
    //return this.http.get<any>(`${this.url}task/tasks/?employee_id=${params}`)
    return this.http.get(`${this.url}person/person/${id}/document/${doc_id}/download/`, {
      observe: 'response',
      responseType: 'blob'
    })
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Download doc HR by id e doc_id', []))
      );

  }





  getApplicantPerson(params?): Observable<any> {
    params == undefined ? (params = '') : null;
    //return this.http.get<any>(`${this.url}task/tasks/?employee_id=${params}`)
    return this.http.get<any>(`${this.url}person/applicant/?research_id=${params}`).pipe(
      timeout(16000),
      tap((data) => {
        console.log(data);
        return data;
      }),
      catchError(this.handleError<any[]>('Get person list', []))
    );
  }

  getResearchSequences(params?): Observable<any> {
    params == undefined ? (params = '') : null;
    //return this.http.get<any>(`${this.url}task/tasks/?employee_id=${params}`)
    return this.http.get<any>(`${this.url}person/research/sequences/`).pipe(
      timeout(16000),
      tap((data) => {
        console.log(data);
        return data;
      }),
      catchError(this.handleError<any[]>('Get research sequences list', []))
    );
  }

  getResearchList(params?): Observable<any> {
    params == undefined ? (params = '') : null;
    //return this.http.get<any>(`${this.url}task/tasks/?employee_id=${params}`)
    return this.http.get<any>(`${this.url}person/researches/`).pipe(
      timeout(16000),
      tap((data) => {
        console.log(data);
        return data;
      }),
      catchError(this.handleError<any[]>('Get researches list', []))
    );
  }


  getRegion(params?): Observable<any> {
    params == undefined ? (params = '') : null;
    //return this.http.get<any>(`${this.url}task/tasks/?employee_id=${params}`)
    return this.http.get<any>(`${this.url}geo/regions/?nation_id=IT`).pipe(
      timeout(16000),
      tap((data) => {
        console.log(data);
        return data;
      }),
      catchError(this.handleError<any[]>('Get region list', []))
    );
  }

  getCountries(id?): Observable<any> {
    //return this.http.get<any>(`${this.url}task/tasks/?employee_id=${params}`)
    return this.http.get<any>(`${this.url}geo/countries/?region_id=${id}&is_province=1`).pipe(
      timeout(16000),
      tap((data) => {
        console.log(data);
        return data;
      }),
      catchError(this.handleError<any[]>('Get countries list', []))
    );
  }

  getLocation(id?): Observable<any> {
    //return this.http.get<any>(`${this.url}task/tasks/?employee_id=${params}`)
    return this.http.get<any>(`${this.url}geo/country/${id}/`).pipe(
      timeout(16000),
      tap((data) => {
        console.log(data);
        return data;
      }),
      catchError(this.handleError<any[]>('Get location id', []))
    );
  }

  getLocations(country_id?:any, type?:any, name?:any): Observable<any> {
    //return this.http.get<any>(`${this.url}task/tasks/?employee_id=${params}`)
    return this.http.get<any>(`${this.url}geo/locations/?country_id=${country_id}&type_id=${type}&street=${name}`).pipe(
      timeout(16000),
      tap((data) => {
        console.log(data);
        return data;
      }),
      catchError(this.handleError<any[]>('Get location id', []))
    );
  }

  getLocationsClear(country_id?:any, type?:any, name?:any, number?:any, cap?:any): Observable<any> {
    //return this.http.get<any>(`${this.url}task/tasks/?employee_id=${params}`)
    return this.http.get<any>(`${this.url}geo/locations/?country_id=${country_id}&type_id=${type}&street=${name}&number=${number}&cap=${cap}`).pipe(
      timeout(16000),
      tap((data) => {
        console.log(data);
        return data;
      }),
      catchError(this.handleError<any[]>('Get location id', []))
    );
  }


  getPersonList(params?): Observable<any> {
    params == undefined ? (params = '') : null;
    //return this.http.get<any>(`${this.url}task/tasks/?employee_id=${params}`)
    return this.http.get<any>(`${this.url}person/people/${params}`).pipe(
      timeout(16000),
      tap((data) => {
        console.log(data);
        return data;
      }),
      catchError(this.handleError<any[]>('Get person list', []))
    );
  }


  
  /**
   *
   *
   * @returns list tasks
   */
  getPersonId(id?): Observable<any> {
    //return this.http.get<any>(`${this.url}task/tasks/?employee_id=${params}`)
    return this.http.get<any>(`${this.url}person/person/${id}/`).pipe(
      timeout(16000),
      tap((data) => {
        console.log(data);
        return data;
      }),
      catchError(this.handleError<any[]>('Get task list', []))
    );
  }


  /**
   *
   *
   * @returns list tasks
   */
  updateDataInterview(form?): Observable<any> {
    //return this.http.get<any>(`${this.url}task/tasks/?employee_id=${params}`)
    console.log(form);
    
    return this.http.put<any>(`${this.url}person/interview/object/${form.id}/`, form).pipe(
      timeout(16000),
      tap((data) => {
        console.log(data);
        return data;
      }),
      catchError(this.handleError<any[]>('Get task list', []))
    );
  }

  updateDataPerson(form?, id?:any): Observable<any> {
    //return this.http.get<any>(`${this.url}task/tasks/?employee_id=${params}`)
    console.log(form);
    
    return this.http.put<any>(`${this.url}person/person/${id}/`, form).pipe(
      timeout(16000),
      tap((data) => {
        console.log(data);
        return data;
      }),
      catchError(this.handleError<any[]>('Get task list', []))
    );
  }
  

  getInterviewsTypes(id?): Observable<any> {
    //return this.http.get<any>(`${this.url}task/tasks/?employee_id=${params}`)
    return this.http.get<any>(`${this.url}person/interviewtypes/`).pipe(
      timeout(16000),
      tap((data) => {
        console.log(data);
        return data;
      }),
      catchError(this.handleError<any[]>('Get task list', []))
    );
  }

  getInterviewsSequences(id?): Observable<any> {
    //return this.http.get<any>(`${this.url}task/tasks/?employee_id=${params}`)
    return this.http.get<any>(`${this.url}person/interviewsequences/`).pipe(
      timeout(16000),
      tap((data) => {
        console.log(data);
        return data;
      }),
      catchError(this.handleError<any[]>('Get interviewsequences list', []))
    );
  }

  updateStatusInterview(id?:any, form?:any): Observable<any> {
    //return this.http.get<any>(`${this.url}task/tasks/?employee_id=${params}`)
    return this.http.put<any>(`${this.url}person/interview_status/${id}/`,form).pipe(
      timeout(16000),
      tap((data) => {
        console.log(data);
        return data;
      }),
      catchError(this.handleError<any[]>('Put update status_id', []))
    );
  }

  // getInterviewsSequences(id?): Observable<any> {
  //   return this.http.get<any>(`${this.url}task/tasks/?employee_id=${params}`)
  //   return this.http.get<any>(`${this.url}person/interviewsequences/`).pipe(
  //     timeout(16000),
  //     tap((data) => {
  //       console.log(data);
  //       return data;
  //     }),
  //     catchError(this.handleError<any[]>('Get task list', []))
  //   );
  // }

  getPersonInterview(id?): Observable<any> {
    //return this.http.get<any>(`${this.url}task/tasks/?employee_id=${params}`)
    return this.http.get<any>(`${this.url}person/interview/${id}/` ).pipe(
      timeout(16000),
      tap((data) => {
        console.log(data);
        return data;
      }),
      catchError(this.handleError<any[]>('Get person interviews', []))
    );
  }

  searchPersonDetail(query?): Observable<any> {
    //return this.http.get<any>(`${this.url}task/tasks/?employee_id=${params}`)
    return this.http.get<any>(`${this.url}person/applicant/?${query}`).pipe(
      timeout(16000),
      tap((data) => {
        console.log(data);
        return data;
      }),
      catchError(this.handleError<any[]>('Get person interviews', []))
    );
  }
    /**
   *
   *
   * @returns list tasks
   */
    updateTask(form?): Observable<any> {
      //return this.http.get<any>(`${this.url}task/tasks/?employee_id=${params}`)
      return this.http.put<any>(`${this.url}task/task/${form.task_id}/`, form).pipe(
        timeout(16000),
        tap((data) => {
          console.log(data);
          return data;
        }),
        catchError(this.handleError<any[]>('Get update Task list', []))
      );
    }

  /**
   *
   *
   * @returns list resourcestasks
   */
  getResourcesTasksList(params?): Observable<any> {
    params == undefined ? (params = '') : null;
    return this.http.get<any>(`${this.url}task/taskresources/${params}`).pipe(
      timeout(16000),
      tap((data) => {
        console.log(data);
        return data;
      }),
      catchError(this.handleError<any[]>('Get task list', []))
    );
  }

  /**
   *
   *
   * @returns get timesheet
   */
  getTimesheet(params?:any): Observable<any> {
    params == undefined ? (params = '') : null;
    return (
      this.http
        .get<any>(`${this.url}task/timesheet/${params}/`)
        //return this.http.get<any>(`${this.url}task/timesheet/?resource_id=${params}`)
        .pipe(
          timeout(16000),
          map((data) => {
            console.log(data);
            let newData = data.filter((e) => e.task_status.code !== 'END');
            console.log(newData);
            return newData;
          }),
          catchError(this.handleError<any[]>('Get task list', []))
        )
    );
  }

  getJobsByResource(params?:any): Observable<any> {
    params == undefined ? (params = '') : null;
    return (
      this.http
        .get<any>(`${this.url}task/jobs_by_resource/${params}/`)
        //return this.http.get<any>(`${this.url}task/timesheet/?resource_id=${params}`)
        .pipe(
          timeout(16000),
          map((data) => {
            console.log(data);
            return data;
          }),
          catchError(this.handleError<any[]>('Get task list', []))
        )
    );
  }

  getTimesheetSearch(params?:any): Observable<any> {
    params == undefined ? (params = '') : null;
    return (
      this.http
        .get<any>(`${this.url}task/timesheet/${params}`)
        //return this.http.get<any>(`${this.url}task/timesheet/?resource_id=${params}`)
        .pipe(
          timeout(16000),
          map((data) => {
            console.log(data);
            let newData = data.filter((e) => e.task_status.code !== 'END');
            console.log(newData);
            return newData;
          }),
          catchError(this.handleError<any[]>('Get task list', []))
        )
    );
  }


  /**
   *
   *
   * @returns get timesheet
   */
  getTimesheetEnd(params?): Observable<any> {
    params == undefined ? (params = '') : null;
    return (
      this.http
        .get<any>(`${this.url}task/timesheet/${params}/`)
        //return this.http.get<any>(`${this.url}task/timesheet/?resource_id=${params}`)
        .pipe(
          timeout(16000),
          tap((data) => {
            console.log(data);
            return data;
          }),
          catchError(this.handleError<any[]>('Get task list', []))
        )
    );
  }

  getTimesheetWork(params?): Observable<any> {
    params == undefined ? (params = '') : null;
    return (
      this.http
        .get<any>(`${this.url}employee/sheet/${params}/`)
        //return this.http.get<any>(`${this.url}task/timesheet/?resource_id=${params}`)
        .pipe(
          timeout(16000),
          map((data) => {
            console.log(data);
            return data;
          }),
          catchError(this.handleError<any[]>('Get task list', []))
        )
    );
  }

  /**
   *
   *
   * @returns list resourcestasks
   */
  addHours(form?): Observable<any> {
    return (
      this.http
        .post<any>(`${this.url}task/taskworkhistories/`, form)
        //return this.http.get<any>(`${this.url}task/timesheet/?resource_id=${params}`)
        .pipe(
          timeout(16000),
          tap((data) => {
            console.log(data);
            return data;
          }),
          catchError(this.handleError<any[]>('Add hours task list', []))
        )
    );
  }

  addHoursAttendance(form?): Observable<any> {
    return (
      this.http
        .post<any>(`${this.url}employee/attendances/`, form)
        //return this.http.get<any>(`${this.url}task/timesheet/?resource_id=${params}`)
        .pipe(
          timeout(16000),
          tap((data) => {
            console.log(data);
            return data;
          }),
          catchError(this.handleError<any[]>('Add hours task list', []))
        )
    );
  }

  /**
  * 


        /**
  * 
  *
  * @returns list resourcestasks
  */
  editHours(form?): Observable<any> {
    console.log(form);

    return (
      this.http
        .put<any>(
          `${this.url}task/taskworkhistory/${form.workhistory_id}/`,
          form
        )
        //return this.http.get<any>(`${this.url}task/timesheet/?resource_id=${params}`)
        .pipe(
          timeout(16000),
          tap((data) => {
            console.log(data);
            return data;
          }),
          catchError(this.handleError<any[]>('Put edit hours list', []))
        )
    );
  }

  deleteHours(form?): Observable<any> {
    console.log(form);
    return (
      this.http
        .delete<any>(
          `${this.url}task/taskworkhistory/${form.workhistory_id}/`,
        )
        //return this.http.get<any>(`${this.url}task/timesheet/?resource_id=${params}`)
        .pipe(
          timeout(16000),
          tap((data) => {
            console.log(data);
            return data;
          }),
          catchError(this.handleError<any[]>('Put edit hours list', []))
        )
    );
  }

  editHoursAttendance(form?): Observable<any> {
    console.log(form);

    return (
      this.http
        .put<any>(
          `${this.url}employee/attendance/${form.history_id}/`,
          form
        )
        //return this.http.get<any>(`${this.url}task/timesheet/?resource_id=${params}`)
        .pipe(
          timeout(16000),
          tap((data) => {
            console.log(data);
            return data;
          }),
          catchError(this.handleError<any[]>('Put edit hours list', []))
        )
    );
  }
  
  deleteHoursAttendance(form?): Observable<any> {
    return (
      this.http
        .delete<any>(
          `${this.url}employee/attendance/${form.history_id}/`,
        )
        //return this.http.get<any>(`${this.url}task/timesheet/?resource_id=${params}`)
        .pipe(
          timeout(16000),
          tap((data) => {
            console.log(data);
            return data;
          }),
          catchError(this.handleError<any[]>('Put edit hours list', []))
        )
    );
  }
  /**
  * 
  * 
  * 
        /**
  * 
  *
  * @returns list resourcestasks
  */
  approveHour(id?: any, form?: any): Observable<any> {
    return (
      this.http
        .put<any>(`${this.url}task/taskworkhistory/${id}/`, form)
        //return this.http.get<any>(`${this.url}task/timesheet/?resource_id=${params}`)
        .pipe(
          timeout(16000),
          tap((data) => {
            console.log(data);
            return data;
          }),
          catchError(this.handleError<any[]>('Put edit hours list', []))
        )
    );
  }

  /**
  * 

  * @returns list task histories
  */
  getHistoriesTasksList(params?): Observable<any> {
    params == undefined ? (params = '') : null;
    return this.http
      .get<any>(`${this.url}task/taskworkhistories/${params}`)
      .pipe(
        timeout(16000),
        tap((data) => {
          console.log(data);
          return data;
        }),
        catchError(this.handleError<any[]>('Get task list', []))
      );
  }

  /**
   *
   *
   * @returns by id wokrers
   */
  getWorkerById(id, params?): Observable<any> {
    params == undefined ? (params = '') : null;
    return this.http.get<any>(`${this.url}tasks/task/${id}/${params}`).pipe(
      timeout(16000),
      tap((data) => {
        console.log(data);
        return data;
      }),
      catchError(this.handleError<any[]>('Get wokrers list', []))
    );
  }

  /**
   * Create Task
   *
   */

  createTask(task: any) {
    return this.http.post<any>(`${this.url}task/tasks/`, task).pipe(
      tap((data) => {
        console.log(data);
      }),
      catchError(this.handleError<any[]>('Create task', []))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      //console.error(error);
      console.log(result, error);

      console.log(`${operation} failed`, error);
      const status = error == 'Forbidden' ? 403 : 500;
      this.presentToast(
        status,
        error == 'undefined' ? 'Internal Server Error' : error,
        operation
      );
      return of(result as T);
    };
  }

  async presentToast(status, statusText, message) {
    const toast = await this.toastController.create({
      message: status + ' ' + statusText + ': "' + message + '"',
      duration: 2000,
      mode: 'ios',
      cssClass: 'toast',
      color: status != '200' ? 'danger' : 'primary',
    });
    toast.present();
  }

  logSys(src, status, opt?) {
    const debugStyle1 =
      'background: linear-gradient(135deg,#471ee9,#3a49b7); border: 1px solid #9a9a9a; color: #ffffff; border-bottom-left-radius: 2px; border-top-left-radius: 2px; padding: 2px 0 2px 4px;';
    const debugStyle2 =
      'background: #252b3e; border: 1px solid #9a9a9a; border-top-right-radius: 2px; border-bottom-right-radius: 2px; margin-left: -2px; padding: 2px 4px; color: white;';

    //console.log('ForceRefresh API ', status, this.networkService.getCurrentNetworkStatus(), this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline);
    console.log(
      `%cApiService %c%s`,
      debugStyle1,
      debugStyle2,
      ' ' + src,
      status
    );
  }

  // Save result of API requests
  private setLocalData(key, data) {
    this.storage.set(`${API_STORAGE_KEY}-${key}`, data);
  }

  // Get cached API result
  private getLocalData(key) {
    return this.storage.get(`${API_STORAGE_KEY}-${key}`);
  }
}
