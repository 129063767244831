// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep ion-modal.custom-modal-page {
  height: 96px;
  position: absolute;
  top: calc(100% - 104px);
  width: 96%;
  margin: 0 auto;
  border-radius: 5px;
  z-index: 9999999999 !important;
  /*  ion-content {
       background-color: #333;
       color: #eee;
   } */
}`, "",{"version":3,"sources":["webpack://./src/app/components/custom-toast-modal/custom-toast-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,kBAAA;EACA,uBAAA;EACA,UAAA;EACA,cAAA;EACA,kBAAA;EACA,8BAAA;EAED;;;MAAA;AAGH","sourcesContent":["::ng-deep ion-modal.custom-modal-page {\n    height: 96px;\n    position: absolute;\n    top: calc(100% - 104px);\n    width: 96%;\n    margin: 0 auto;\n    border-radius: 5px;\n    z-index: 9999999999 !important;\n\n   /*  ion-content {\n        background-color: #333;\n        color: #eee;\n    } */\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
