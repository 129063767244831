/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable object-shorthand */
/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/semi */


import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { MenuController, ModalController, NavController, Platform, ToastController } from '@ionic/angular';


@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent {

  isLoading: any = true;
  auth: any = false;
  dark: any = true;

  title = 'FGB Studio - Forgot password';

  validationsForm: FormGroup;
  emailForm: FormGroup;
  passForm: FormGroup;
  error;
  validationMessages = {
    email: [
      { type: 'required', message: 'Email is required.' },
      { type: 'pattern', message: 'Please enter a valid email.' },
    ]
  };

  modal

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private router: Router,
    private navCrtl: NavController,
    private menu: MenuController,
    private platform: Platform,
    public modalController: ModalController,
    private toastController: ToastController
  ) {

    this.emailForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
    this.validationsForm = this.formBuilder.group({
      email: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
        ])
      )
    });
  }


  ionViewWillEnter() {
    this.menu.enable(true);
    console.log('Enter modal forgot pass');
    this.title = 'Forgot pass'


  }
  ionViewWillLeave() {
    this.menu.enable(true);
    console.log('Exit modal forgot pass');
    this.title = ''

  }

  ionViewDidEnter() {

    console.log('Is Enter modal forgot pass');
    this.title = 'Forgot pass'

    this.auth = this.authService.isAuthenticated();

    this.isLoading = !this.auth
    if (this.authService.isAuthenticated()) {
      setTimeout(() => {
        this.router.navigate(['']);
      }, 100);
    } else {
      this.isLoading = false;
    }
  }


  onSubmit() {
    console.log('/login', this.validationsForm.invalid);
    // stop here if form is invalid
    if (this.validationsForm.invalid) {
      console.log('Campi errati');
      //this.error = 'Campi vuoti o errati';
      return false;
    } else {
      //console.log(this.emailForm);

      //this.login(this.validationsForm.controls.email.value, this.validationsForm.controls.password.value);

      //if (!this.plt.is('ios')) {
        //this.showFingerprintAuthDlg()
      /* } else */ {

        //this.login(this.validationsForm.controls.email.value, this.validationsForm.controls.password.value);


        //this.send(this.validationsForm.controls.email.value);
      }
    }
    return false
  }

  send(email) {
    this.authService.forgot(email).subscribe(e => {
      console.log(e);
      //this.navCrtl.navigateForward(['/home']);
      if (e.length == 0) {
      }

      this.presentToast()
      setTimeout(() => {
        this.closeModal();
      }, 4100);

    })
  }



  async presentModal() {
    this.modal = await this.modalController.create({
      component: ModalComponent,
      cssClass: 'modal-forgot-pass',
      mode: 'ios',
      canDismiss: false,
      backdropDismiss: false
    });
    return await this.modal.present();
  }

  closeModal() {
    this.modal.dismiss()
  }


  async presentToast() {
    const toast = await this.toastController.create({
      message: 'Thanks, request reset corfirm! Await and check your email. ',
      position: 'bottom',
      color: 'primary',
      cssClass: 'toast',
      duration: 4000

    })
    await toast.present();

    const { role } = await toast.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
  }


}
