import { ActivatedRoute, Router } from '@angular/router';

import { Platform, AnimationController, IonNav, ModalController, ToastController } from '@ionic/angular';
import { Component, OnInit, ElementRef, ViewChild, Input } from '@angular/core';
import { Order } from '../models/order.model';
import { NewOrderComponent } from '../new-order/new-order.component';
import { ApiService } from 'src/app/services/api/api.service';
import { UploadDesktopComponent } from 'src/app/components/media/upload-desktop/upload-desktop.component';
import { OrdersService } from 'src/app/services/orders/orders.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { ProjectsService } from 'src/app/services/projects/projects.service';
import { CustomersService } from 'src/app/services/customers/customers.service';
import { VendorsService } from 'src/app/services/vendors/vendors.service';
import { IonicSelectableComponent } from 'ionic-selectable';
import { NewVendorComponent } from 'src/app/vendors/new-vendor/new-vendor.component';
import { VendorUpdatePage } from 'src/app/vendors/vendor-update/vendor-update.page';
import { NewProjectComponent } from 'src/app/projects/new-project/new-project.component';
import { UpdateProjectPage } from 'src/app/projects/update-project/update-project.page';
import { JobsService } from 'src/app/services/jobs/jobs.service';
import { NewDateComponent } from './date/new-date/new-date.component';

@Component({
  selector: 'app-order-update',
  templateUrl: './order-update.page.html',
  styleUrls: ['./order-update.page.scss'],
})

export class OrderUpdatePage {
  @ViewChild('favIcon', { read: ElementRef }) favIcon: ElementRef;
  @ViewChild(UploadDesktopComponent, { static: true }) UploadDesktopComponent: UploadDesktopComponent;
  @ViewChild('myTable') table: DatatableComponent;
  @ViewChild('createForm') createForm: FormGroupDirective;

  @Input() id: any = '';
  public showProgressStatus = false;
  public order: Order;
  public favState = false;
  public favOnAnimation: Animation;
  public favOffAnimation: Animation;

  page_number = 1;
  page_limit = 30;

  filteredAddress = [];
  selectedSegment


  order_orders_createForm: FormGroup;


  response;

  types: any = [];
  categories: any = [];
  status: any = [];
  priorities: any = [];
  causals: any = [];
  projects: any = [];
  customers: any = [];
  vendors: any = [];
  invoice_term: any = [];
  payment_term: any = [];
  payment_type: any = [];

  /**
 * PER GESTIRE LA LISTA, RICERCA, MODIFICA E AGGIUNTA ENTITY
 */
  size = 20

  page_project = 1
  page_customer = 1
  page_vendor = 1

  numPage_project = 0
  numPage_customer = 0
  numPage_vendor = 0

  onSelectProject
  onSelectCustomer
  onSelectVendor

  onPushProject
  onPushCustomer
  onPushVendor

  onSearchSelector = ''

  portsSubscription


  constructor(
    public platform: Platform,
    private orderApi: OrdersService,
    private projectApi: ProjectsService,
    private customerApi: CustomersService,
    private vendorApi: VendorsService,
    private jobService: JobsService,
    private api: ApiService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
    private animationCtrl: AnimationController,
    //private nav: IonNav,
    private modalController: ModalController,
    private toastController: ToastController
  ) {

    this.id = this.route.snapshot.paramMap.get('id');
  }

  createBaseForm() {
    let id = '';
    //let id = 'ORD.'+ (new Date()).getTime().toString().substring(0,13);
    //let id = 'ORD.';
    console.log(this.order)
    console.log(this.order.category.id);
    

    // Generete FormGroup Create Order 
    this.order_orders_createForm = this.fb.group({
      type_id: this.fb.control(this.order.type ? this.order.type.id : null, Validators.required),
      category_id: this.fb.control(this.order.category ? this.order.category.id : null, Validators.required),
      status_id: this.fb.control(this.order.status.id, Validators.required),
      priority_id: this.fb.control(this.order.priority.id, Validators.required),
      job_id: this.fb.control(this.order.job ? this.order.job.id : null, Validators.required),
      customer_id: this.fb.control(this.order.customer ? this.order.customer.id : null, Validators.required),
      vendor_id: this.fb.control(this.order.vendor ? this.order.vendor.id : null, Validators.required),
      cost: this.fb.control(this.order.cost),
      causal_id: this.fb.control(this.order.causal ? this.order.causal.id : null),
      description: this.fb.control(this.order?.description),
      //extra_type_id: this.fb.control(this.order.extra_type ? this.order.extra_type.id : null),
      code: this.fb.control(this.order.code ? this.order.code : id),
      //parent_id: this.fb.control(this.order.parent ? this.order.parent.id : null),
      //invoice_term_id: this.fb.control(this.order.invoice_term ? this.order.invoice_term.id : '', Validators.required),
      //payment_term_id: this.fb.control(this.order.payment_term ? this.order.payment_term.id : '', Validators.required),
      //payment_type_id: this.fb.control(this.order.payment_type ? this.order.payment_type.id : '', Validators.required)


    });
    console.log(this.order_orders_createForm);

    this.order_orders_createForm.valueChanges.subscribe(e => {
      console.log(e);

    })

  }

  get errorControl() {

    return this.order_orders_createForm.controls;
  }


  async getOrderMeta() {

    let page_category = 1;
    let page_project = 1;
    let page_customer = 1;
    let page_vendor = 1;

    this.types = await this.orderApi.getOrderTypes().toPromise()
    this.categories = await this.orderApi.order_categories_list('?page=' + page_category + '&items=' + 40).toPromise().then(e => e.results)
    this.priorities = await this.orderApi.getPrioritiesOrder().toPromise()
    this.causals = await this.orderApi.getCausalsList().toPromise()
    this.projects = await this.jobService.getJobsList('?page=' + this.page_project + '&items=' + this.size).toPromise().then(e => e.results)
    this.customers = await this.customerApi.getCustomersList('?page=' + page_customer + '&items=' + 10).toPromise().then(e => e.results)
    this.vendors = await this.vendorApi.getVendorsListNoActive('?page=' + page_vendor + '&items=' + 10).toPromise().then(e => e.results)
    //this.invoice_term = await this.orderApi.getOrderInvoiceterms().toPromise()
    //this.payment_term = await this.orderApi.getOrderPaymentterms().toPromise()
    //this.payment_type = await this.orderApi.getOrderPaymenttypes().toPromise()

    console.log(this.types)
    console.log(this.categories)
    let res = await Promise.all([this.priorities, this.types, this.categories, this.projects, this.customers, this.vendors, this.causals]);
    return res

  }

  ionViewWillEnter() {
    console.log('ionViewWillEnter', this.id);


    this.response = '';
    this.orderApi.getOrderById(parseInt(this.id, 10), '')
      .subscribe(async (e:any) => {
        // if the project doesn't exists, return to home page
        if (!e) {
          //this.router.navigate(['/order-list']);
        }
        else {
          this.order = e;
          this.selectedSegment = ''
          let meta = await this.getOrderMeta();
          console.log(meta);
          if (meta['0'].length == 0)/*  || meta[1].length == 0 */
            this.order_orders_createForm = this.fb.group({})
          else
            console.log(meta);
            
          this.createBaseForm()
          /* if (this.UploadDesktopComponent && e.folders.length && e.folders[0].folder.id != undefined) {
            this.UploadDesktopComponent.passItem(e);
          } else {
            
          } */
        }
      });


  }


  getDocuments(id_folder) {
    let url = '?page=' + this.page_number + '&items=' + this.page_limit;
    this.api.getFiles(id_folder, url).subscribe(x => {
      this.page_number++
      return x
    })
  }



  fav(): void {
    if (this.favState) {
      // this.favOnAnimation.stop();
      this.favOffAnimation.play();
    } else {
      // this.favOffAnimation.stop();
      this.favOnAnimation.play();
    }

    this.favState = !this.favState;
  }

  onChangeSelectAcc(event) {
    console.log(event);
  }


  editOrder() {
    //this.navCtrl.navigateForward(['/members/order-details/', item.id]);    
    //nav: this.nav(OrderUpdatePage, { id: this.id }, { animated: true, direction: 'forward' });
  }


  async openNewOrderModal() {
    const modal = await this.modalController.create({
      component: NewOrderComponent,
      canDismiss: true,
      componentProps: {
        title: 'Add New Order',
        parent_type: 'root'
      }
      //presentingElement: this.routerOutlet.nativeEl,
    })


    modal.onWillDismiss().then((e) => {
      if (e.data && e.data.status != -1) {
        /* this.page_number = 1;
        this.myInput.value = "";
        this.orders = [];
        this.getOrders(false, '') */
      }
    });
    return await modal.present();
  }


  submitForm() {
    this.createForm.onSubmit(undefined);
  }

  fabCallBack(action) {
    console.log(action);

    if (action) {
      this[action]()
    } else {
      console.log('not call in component, run callback', 'details');


    }
  }


  removeDuplicate(types) {
    if (types && types.lenght) {
      const names = types.map(o => o.name)
      const filtered = types.filter(({ name }, index) => !names.includes(name, index + 1))
      return filtered
    }
  }


  filterAddress(address, event) {

    if (event) {
      console.log(address, event);
      this.filteredAddress = address.filter(function (type) {
        return type.name == event.detail.value;
      });
    }

  }



  async openModalUpload() {
    const modal = await this.modalController.create({
      component: UploadDesktopComponent,
      canDismiss: true,
      componentProps: {
        //title: 'Add New Order',
        item: this.order,
        header: { ion_header: true },
        files: [],
        parent_type: 'root'
      }
      //presentingElement: this.routerOutlet.nativeEl,
    })


    modal.onWillDismiss().then((e) => {
      if (e.data && e.data.status != -1) {
        /* this.page_number = 1;
        this.myInput.value = "";
        this.orders = [];
        this.getOrders(false, '') */
      }
    });
    return await modal.present();
  }


  dismissModal(event?) {
    this.modalController.dismiss(this.response)
  }

  onActivate(event) {
    if (this.table.rowDetail && event.type == 'click') {
      this.table.rowDetail.toggleExpandRow(event.row);
      console.log('Activate Event', event);
    }
  }



  updateOrder(form) {

    //Call API
    console.log(form, this.order);

    Object.keys(form).forEach(key => form[key] == null && delete form[key]);
    console.log(this.order_orders_createForm.value);
    
    this.orderApi.order_order_update(this.order.id, this.order_orders_createForm.value).subscribe(e => {
      console.log(e);
      if (e.message && e.message.includes('OK')) {
        this.presentToast(e.message, 'success')
        this.response = e.data;
        this.dismissModal();
      } else {
        this.response = 'KO';
      }
    })

    //console.log(form)
  }


  async presentToast(text, color) {
    const toast = await this.toastController.create({
      message: text,
      position: 'bottom',
      duration: 3000,
      cssClass: 'toast',
      color: color
    });
    return await toast.present();
  }


  async openUpdateCustomerModal(data?) {
    console.log(data);

    const modal = await this.modalController.create({
      component: VendorUpdatePage,
      canDismiss: true,
      mode: 'md',
      //cssClass: 'fullscreen',
      componentProps: {
        id: data.item.id,
        ////nav: this.nav  // Pass nav for IonNav from modal if necessary
      },
    })


    modal.onWillDismiss().then((e) => {
      console.log(e);

      if (!e.data) {
        console.log('no modify');
        return
      } else {
        //this.page_customer = 1
        this.onSelectCustomer = e.data
        this.onSelectVendor = e.data

      }
    });
    return await modal.present();
  }

  async openNewCustomerModal() {
    const modal = await this.modalController.create({
      component: NewVendorComponent,
      //cssClass: 'newOrderModalForm',
      canDismiss: true,
      componentProps: {
        //nav: this.nav,
        title: 'Add Company',
        redirectAfterPost: false
      },

    });

    modal.onWillDismiss().then(async (e) => {
      console.log(e);

      if (!e.data) {
        console.log('no modify');
        return
      } else {
        //this.loadProjects(this.currentSegment);
        //this.projects = await this.project.getProjectsList_rev('?page=' + this.page_project + '&items=' + this.size).toPromise().then(e => e.results)

        this.page_customer = 1
        this.page_vendor = 1
        //this.onSelectProject = [e.data]
        this.onPushVendor = e.data
        this.onPushCustomer = e.data
      }
    });

    return await modal.present();
  }

  onAddPort(event: {
    component: IonicSelectableComponent
  }) {
    // Clean form.
    this.order_orders_createForm.reset();

    console.log('onAddPort', event);
    // Copy search text to port name field, so
    // user doesn't have to type again.
    //this.createFormCommitter.controls['name'].setValue(event.component.searchText);

    // Show form.
    //event.component.showAddItemTemplate();
    this.openNewCustomerModal()
  }

  onSavePort(event: {
    component: IonicSelectableComponent,
    item//: Port
  }) {
    console.log('onSavePort', event);

    // Fill form.
    //this.createFormCommitter.setValue(event.item);
    //this.portCountryControl.setValue(event.item.country);

    // Show form.
    //event.component.showAddItemTemplate();

    //this.openUpdateProjectModal(event.item)
  }

  onDeletePort(event: {
    component: IonicSelectableComponent,
    item//: Port
  }) {
    // Delete port from storage.
    //this.portService.deletePort(event.item);

    // Delete port from list.
    event.component.deleteItem(event.item);
  }

  searchPorts(event/*:  { component: IonicSelectableComponent; text: string }, type */) {

    console.log(event, event.text);
    //console.log(event.component._modalComponent);
    //console.log(event.component._modalComponent._content);

    //event.component._modalComponent._content.scrollToBottom(1500);
    //event.component._modalComponent._content.scrollY = false

    let url = ''

    const text = event.text.trim().toLowerCase();
    //event.component.startSearch();


    // Close any running subscription.
    if (this.portsSubscription) {
      this.portsSubscription.unsubscribe();
    }


    if (!text) {

      // Close any running subscription.
      if (this.portsSubscription) {
        this.portsSubscription.unsubscribe();
      }


      //event.component.endSearch();
      //event.component.enableInfiniteScroll();
      switch (event['module']) {
        /*  case 'project':
           this.page_project = 1
 
           url = '?page=' + this.page_project + '&items=' + this.size;
 
           this.projectApi.getProjectsList_rev(url).subscribe((data) => {
             console.log('!text call more getProjectsList', data);
 
             //data.results = event.component.items = (data.results);
 
             //this.numPage_project = data.num_pages;
 
             //event.component.items = data.results;
             this.onSearchSelector = ''
             this.numPage_project = data.num_pages;
             this.projects = data.results
             //event.component.endInfiniteScroll();
           })
           break;
  */
        case 'job':
          this.page_project = 1

          url = '?page=' + this.page_project + '&items=' + this.size;

          this.jobService.getJobsList(url).subscribe((data) => {
            console.log('!text call more getProjectsList', data);

            //data.results = event.component.items = (data.results);

            //this.numPage_project = data.num_pages;

            //event.component.items = data.results;
            this.onSearchSelector = ''
            this.numPage_project = data.num_pages;
            this.projects = data.results
            //event.component.endInfiniteScroll();
          })
          break;

        case 'customer':
          this.page_customer = 1

          url = '?page=' + this.page_customer + '&items=' + this.size;

          this.customerApi.getCustomersList(url).subscribe((data) => {
            console.log('!text call more getCustomersList', data);

            //data.results = event.component.items = (data.results);

            //this.numPage_project = data.num_pages;

            //event.component.items = data.results;
            this.onSearchSelector = ''
            this.numPage_customer = data.num_pages;
            this.customers = data.results
            //event.component.endInfiniteScroll();
          })
          break;

        case 'vendor':
          this.page_vendor = 1

          url = '?page=' + this.page_vendor + '&items=' + this.size;

          this.vendorApi.getVendorsListNoActive(url).subscribe((data) => {
            console.log('!text call more getVendorsListNoActive', data);

            //data.results = event.component.items = (data.results);

            //this.numPage_project = data.num_pages;

            //event.component.items = data.results;
            this.onSearchSelector = ''
            this.numPage_vendor = data.num_pages;
            this.vendors = data.results
            //event.component.endInfiniteScroll();
          })
          break;

        default:
          console.log('Default');

          break;
      }

      return

    }


    switch (event['module']) {
      /* case 'project':

        url = '?page=' + this.page_project + '&items=25&name=' + text;
        this.portsSubscription = this.projectApi.getProjectsList_rev(url).subscribe((data) => {
          console.log('text call from search getProjectsList', data);
          // Subscription will be closed when unsubscribed manually.
          if (this.portsSubscription.closed) {
            return;
          }
          this.onSearchSelector = text
          this.numPage_project = data.num_pages;
          this.projects = data.results

        })

        break;
 */
      case 'job':

        url = text;
        this.portsSubscription = this.jobService.getJobs_SearchByNoCategoryId(url).subscribe((data) => {
          console.log('text call from search getProjectsList', data);
          // Subscription will be closed when unsubscribed manually.
          if (this.portsSubscription.closed) {
            return;
          }
          this.onSearchSelector = text
          this.numPage_project = data.num_pages;
          this.projects = data.results

        })

        break;

      case 'customer':

        url = '?page=' + this.page_customer + '&items=25&name=' + text;
        this.portsSubscription = this.customerApi.getCustomersList(url).subscribe((data) => {
          console.log('text call from search getCustomersList', data);
          // Subscription will be closed when unsubscribed manually.
          if (this.portsSubscription.closed) {
            return;
          }
          this.onSearchSelector = text
          this.numPage_customer = data.num_pages;
          this.customers = data.results

        })

        break;

      case 'vendor':

        url = '?page=' + this.page_vendor + '&items=25&name=' + text;
        this.portsSubscription = this.vendorApi.getVendorsListNoActive(url).subscribe((data) => {
          console.log('text call from search getVendorsList', data);
          // Subscription will be closed when unsubscribed manually.
          if (this.portsSubscription.closed) {
            return;
          }
          this.onSearchSelector = text
          this.numPage_vendor = data.num_pages;
          this.vendors = data.results

        })

        break;

      default:
        break;
    }








  }


  // Metodo para usar con Infinity Scroll en ionic-selectable
  onInfiniteScroll(/* 
    event: { component: IonicSelectableComponent; text: string },
    type: string */ event) {

    console.log(event);
    console.log(this.page_project, this.numPage_project);


    console.log();

    let url
    let _type = event['type'] ? event['type'] : null;
    console.log('after', _type);

    switch (_type) {

      /*  case 'project': {
 
         console.log('page', this.numPage_project);
 
         url = '?page=' + (++this.page_project) + '&items=' + this.size;
 
         this.projectApi.getProjectsList_rev(url).subscribe((data) => {
           console.log('onInfiniteScroll', data);
           //event.component.endInfiniteScroll();
           //data.results = event.component.items.concat(data.results);
           this.onSearchSelector = '';
           this.numPage_project = data.num_pages;
           this.projects = data.results;
           //event.component._modalComponent._content.scrollY = true
           //event.component.items = data.results;
         });
 
         break;
       }
  */
      case 'job': {

        console.log('page', this.numPage_project);

        url = '?page=' + (++this.page_project) + '&items=' + this.size;

        this.jobService.getJobsByNoCategoryId(url).subscribe((data) => {
          console.log('onInfiniteScroll', data);
          //event.component.endInfiniteScroll();
          //data.results = event.component.items.concat(data.results);
          this.onSearchSelector = '';
          this.numPage_project = data.num_pages;
          this.projects = data.results;
          //event.component._modalComponent._content.scrollY = true
          //event.component.items = data.results;
        });

        break;
      }

      case 'customer':

        url = '?page=' + (++this.page_customer) + '&items=' + this.size;

        this.customerApi.getCustomersList(url).subscribe((data) => {
          console.log('!text call more getCustomersList', data);

          //data.results = event.component.items = (data.results);

          //this.numPage_project = data.num_pages;

          //event.component.items = data.results;
          this.onSearchSelector = ''
          this.numPage_customer = data.num_pages;
          this.customers = data.results
          //event.component.endInfiniteScroll();
        })
        break;


      case 'vendor':

        url = '?page=' + (++this.page_vendor) + '&items=' + this.size;

        this.vendorApi.getVendorsListNoActive(url).subscribe((data) => {
          console.log('!text call more getVendorsListNoActive', data);

          //data.results = event.component.items = (data.results);

          //this.numPage_project = data.num_pages;

          //event.component.items = data.results;
          this.onSearchSelector = ''
          this.numPage_vendor = data.num_pages;
          this.vendors = data.results
          //event.component.endInfiniteScroll();
        })
        break;

      default:
        console.log('Default');

        break;

      //.... more code case
    }



  }



  filterPorts(ports: any[], text: string) {
    return ports.filter(port => {
      return port.name.toLowerCase().indexOf(text) !== -1 ||
        port.code.toLowerCase().indexOf(text) !== -1;
    });
  }



  onSelect(event) {
    console.log(event, this.id);
    if (event.isSelected) {
      if (event.module == 'job') {
        this.onSelectProject = event.item
        console.log(this.onSelectProject);
        this.order_orders_createForm.patchValue({job_id : event.item.id})
        console.log(this.order_orders_createForm.value);

      } 
      else if (event.module == 'customer') {
        this.onSelectCustomer = event.item
        this.order_orders_createForm.patchValue({customer_id : event.item.id})
        console.log(this.order_orders_createForm.value);


      } else if (event.module == 'vendor') {
        this.onSelectVendor = event.item
        this.order_orders_createForm.patchValue({vendor_id : event.item.id})
        console.log(this.order_orders_createForm.value);

      }
    } else if (!event.isSelected) {
      if (event.module == 'job') {
        this.onSelectProject = ''
      } else if (event.module == 'customer') {
        this.onSelectCustomer = ''

      } else if (event.module == 'vendor') {
        this.onSelectVendor = ''

      }
    }
  }


  async openUpdateProjectModal(data?) {

    const modal = await this.modalController.create({
      component: UpdateProjectPage,
      canDismiss: true,
      mode: 'md',
      //cssClass: 'fullscreen',
      componentProps: {
        item: this.order.project,
        ////nav: this.nav  // Pass nav for IonNav from modal if necessary
      },
    })


    modal.onWillDismiss().then((e) => {
      console.log(e);

      if (e.data && e.data.message != 'OK') {
        console.log('no modify');
        return
      } else {
        /*  let url = this.concateQuery(this.queryString)
         this.order.getOrdersByProjectId(parseInt(this.id, 10), url)
           .subscribe(orders => {
             console.log(orders);
 
             // if the orders doesn't exists, return to home page
             if (!orders) {
               //this.router.navigate(['/orders-list']);
             } else {
               this.orders = orders.results;
             }
           }); */

      }
    });
    return await modal.present();
  }

  async openNewProjectModal() {
    const modal = await this.modalController.create({
      component: NewProjectComponent,
      cssClass: 'newProjectModalForm',
      canDismiss: true,
      componentProps: {
        //nav: this.nav,
        redirectAfterPost: false
      },

    });

    modal.onWillDismiss().then(async (e) => {
      console.log(e.data)

      if (!e.data) {
        console.log('no modify');
        return
      } else {
        //this.loadProjects(this.currentSegment);
        //this.projects = await this.project.getProjectsList_rev('?page=' + this.page_project + '&items=' + this.size).toPromise().then(e => e.results)

        this.page_project = 1
        //this.onSelectProject = [e.data]
        this.onPushProject = e.data

      }
    });

    return await modal.present();
  }


  
}
