
import { AfterContentChecked, ChangeDetectorRef, Component, Input, OnChanges, OnInit, Renderer2, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { NavController, ModalController, IonNav, IonRouterOutlet, IonInfiniteScroll, IonSearchbar, IonContent, IonSegment, IonSegmentButton, IonMenu, MenuController } from '@ionic/angular';

import { animate, query, sequence, stagger, state, style, transition, trigger } from '@angular/animations';

import { TabsService } from 'src/app/services/tabs/tabs.service';

import { ColumnChangesService, ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { SubMenuService } from 'src/app/services/utils/sub-menu/sub-menu.service';
import { NewInvoiceComponent } from '../new-invoice/new-invoice.component';
import { InvoicesService } from 'src/app/services/invoices/invoices.service';
import { InvoiceDetailsPage } from '../invoice-details/invoice-details.page';
import { ActivatedRoute } from '@angular/router';
import { TitleAppService } from 'src/app/services/utils/title-app/title-app.service';
import { ListInvoiceComponent } from '../components/list/list.component';

interface PageInfo {
  offset: number;
  pageSize: number;
  limit: number;
  count: number;
}
/**
 * An object used to get page information from the server
 */
export class Page {
  // The number of elements in the page
  size: number = 0;
  // The total number of elements
  totalElements: number = 0;
  // The total number of pages
  totalPages: number = 0;
  // The current page number
  pageNumber: number = 0;
}

const listAnimation = trigger('listAnimation', [
  transition('* <=> *', [
    query(':enter',
      [style({ opacity: 0 }), stagger('180ms', animate('300ms ease-out', style({ opacity: 1 })))],
      { optional: true }
    ),
    query(':leave',
      animate('200ms', style({ opacity: 0 })),
      { optional: true }
    )
  ])
]);

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices-list.page.html',
  styleUrls: ['./invoices-list.page.scss'],
  // ✨ New! Defining the animation
  animations: [listAnimation],
  //changeDetection: 
})
export class InvoicesListPage {
  public data: any; // Data;
  public columns: any;
  public rows: any;

  rowIsNotExpanded = true

  editing = {};

  selected = [];


  enableSearch = false;
  funder = [];
  calculated = [];
  pending = [];
  ColumnMode: ColumnMode
  expanded: any = {};
  timeout: any;
  total_order = 0;
  currentSegment: string = "all";

  navMenu = [
    {
      label: 'New', icon: 'add-outline', action: 'openNewInvoiceModal'
    },
    { action: 'toggleSearch', label: 'Search', icon: 'search-outline' },
    /*     { action: '', label: 'Export', icon: 'download-outline' },
        { action: 'openMenu', label: 'Filter', icon: 'menu-outline' }, */
  ]


  @ViewChild('segment') segment: IonSegment;
  @ViewChild('btn_segment') btn_segment: IonSegmentButton;
  @ViewChild('table') table: ListInvoiceComponent;
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  @ViewChild(IonContent) content: IonContent;
  @ViewChild(IonSearchbar) myInput: IonSearchbar;


  fab: boolean = false;
  customAlertOptions: any = {
    header: 'List groups',
    //subHeader: 'Select group for user',
    message: 'Select group for user',
    translucent: true
  };
  invoices: any = []
  _orders: any = []
  searching = false
  res: any = []
  order_status = [];
  order_types = [];
  order_categories = [];

  total_page = 0;
  page_number = 1;
  page_limit = 30;

  isSearch: boolean = false;
  isLoad: boolean = false;

  queryString = {
    search_by: '',
    search_data: ''
  };

  order_id

  totalElements: number;
  pageNumber: number;
  cache: any = {};


  isLoading = 0;

  constructor(
    private menu: MenuController,
    private subMenu: SubMenuService,
    private cd: ChangeDetectorRef,
    private navCtrl: NavController,
    private api: InvoicesService,
    private modalController: ModalController,
    //private nav: IonNav,
    private routerOutlet: IonRouterOutlet,
    private route: ActivatedRoute,
    private tabService: TabsService,
    private renderer: Renderer2,
    private columnChangesService: ColumnChangesService,
    private titleAppService: TitleAppService
  ) {

    this.order_id = this.route.snapshot.paramMap.get('order_id');

    this.columns = [
      //{ prop: 'id', name: 'ID'/* , frozenLeft: true */, minWidth: 75, maxWidth: 75, cellClass: 'td-id', headerClass: 'th-id' },
      /* {
        prop: 'id', width: 75, minWidth: 75
      }, */
      {
        prop: 'status.name', name: 'Status', width: 150, minWidth: 150, maxWidth: 150, cellClass: 'td-status', headerClass: 'th-status'
      },
      {
        prop: 'code', width: 195, minWidth: 195
      },
      { prop: 'category.name', name: 'Category', maxWidth: 200 },
      { prop: 'type.name', name: 'Type', maxWidth: 180 },

      //{ prop: 'creator.email', name: 'Email', width: 190, minWidth: 190, maxWidth: 230 },
      { prop: 'project.name', name: 'Project', maxWidth: 240 },
      { prop: 'customer.name', name: 'Customer', maxWidth: 200 },
      { prop: 'vendor.name', name: 'Vendor', maxWidth: 200 },
      //{ prop: 'deleted', maxWidth: 70, cellClass: 'td-deleted', headerClass: 'th-deleted' },

      {
        prop: 'cost', minWidth: 155, maxWidth: 155,/*  summaryFunc: () => this.caclulateSumm('€'), */
      },

    ]
  }

  openMenu() {
    this.menu.open('admin')
    this.subMenu.params.next({
      title: 'Filter',
      icon: 'funnel-outline',
      accordions: [
        {
          target: '',
          color: '',
          icon: 'list-outline',
          title: 'Status'
        },
        {
          target: '',
          color: '',
          icon: 'list-outline',
          title: 'Priority'
        },
        {
          target: '',
          color: '',
          icon: 'list-outline',
          title: 'Category'
        },
        {
          target: '',
          color: '',
          icon: 'list-outline',
          title: 'Type'
        }
      ]
    })
  }




  ionViewWillEnter() {

    console.log();

    this.myInput ? this.myInput.value = "" : null;
    this.titleAppService.title.next('Invoices')

    this.cache = {}
    //this.invoices = [];
    this.fab = true
    this.queryString = localStorage.getItem('filter_search_invoices') ? JSON.parse(localStorage.getItem('filter_search_invoices')) : { search_by: '', search_data: 'all' }

    //this.getInvoicesMeta()

    this.getInvoicesList();

  }


  /**
   * @description after click segment switch for selected status
   * @param data = this.queryString LocalStorage
   */
  /*   loadProjects(data: any) {
  
      if (data.search_data === 'all') { 
        this.getInvoices(false, "")
        this.isLoad = true;
      } else {     
        this.getInvoices(false, "", data)
        this.isLoad = true;
      }
  
    } */



  /**
   * @description Get list invoices
   */
  getInvoicesList() {
    let url = ''

    this.api.getInvoicesList(url)
      .subscribe(data => {

        console.log(data, data.results)

        this.invoices = data.results;

        console.log('refresh list') // metodo per aggiornare la lista

        // Completo il ciclio di refresh passando event e terminandolo con event.target.complete()

        // Incremento la pagina in attesa della successiva chiamata 
        this.page_number = 1;
        // Resetto la "cache" per permettere di interrogare di nuovo da pagina 1
        this.cache = {};


      }, error => {
        console.log(error);
      })



  }


  concateQuery(search) {
    console.log(search);
    let url = '?page=' + this.page_number + '&items=' + this.page_limit;
    let query = '';

    if (search && search != '' && search.search_data != 'all') {
      query += '&' + search.search_by + '=';
      query += search.search_data;
      url += query
      let filtered = this.columns.filter(obj => obj.name !== 'Status');

      console.log(filtered);

      this.columns = [...filtered]

    } else {
      this.columns = [...this.columns]
    }

    console.log(url);
    return url
  }

  /**
   * @description Load invoices on init and load preference filter if exist
   * @param isFirstLoad if true reload all list / if false push and increment page to scroll (in old func now in datatable paginator)
   * @param event if isFirstLoad true "event" is the event trigged
   * @param search if pass query for search by filter status now
   */
  getInvoices(isFirstLoad, event, search?) {
    this.isSearch = false;

    const index = this.columns.findIndex(item => item.name === "Status");
    console.log(index)


    if (this.page_number <= 1 && index == -1)
      this.columns.splice(2, 0, { prop: 'status.name', name: 'Status', width: 95, cellClass: 'td-status', headerClass: 'th-status' })

    let url = '';

    if (search && search != '' && search.search_data != 'all') {
      url = this.concateQuery(this.queryString)
    }


    this.api.getInvoicesList(url)
      .subscribe(data => {

        console.log(data, data.results)

        this.invoices = data.results;

        console.log('refresh list') // metodo per aggiornare la lista

        // Completo il ciclio di refresh passando event e terminandolo con event.target.complete()
        if (isFirstLoad)
          event.target.complete();

        // Incremento la pagina in attesa della successiva chiamata 
        this.page_number = 1;
        // Resetto la "cache" per permettere di interrogare di nuovo da pagina 1
        this.cache = {};


      }, error => {
        console.log(error);
      })



  }


  triggerColumnChangeDetection(): void {
    this.columnChangesService.onInputChange();
  }

  goTo(item) {
    //this.navCtrl.navigateForward(['/members/vendor-details/', item.id]);    
    //nav: this.nav(InvoicesListPage, { id: item.id }, { animated: true, direction: 'forward' });

  }

  /**
   * @description pervius func now use databatable paginator
   * @param event 
   * @param search 
   */
  doInfinite(event, search?) {
    console.log(event, search);
    this.getInvoices(true, event, this.queryString);
    if (this.isSearch) {
      //this.getInvoices(true, event, 0, search.value)
    } else {

    }
  }

  /**
   * @description quando scrolli dall'alto verso il basso a scoll 0px refresh la lista
   * @param event evento default dalla vista
   */
  doRefresh(event) {
    console.log('Begin async operation');

    setTimeout(() => {
      console.log('Async operation has ended');

      this.total_page = 0;
      this.page_number = 1;
      this.myInput.value = "";
      this.invoices = [];
      //this.getInvoices(false, '')
      // this.loadProjects(this.queryString);


      event.target.complete();
    }, 1000);
  }

  /**
   * @description Richiama nomi funzioni passate dalla vista (si può fare anche tutto da .ts)
   * @param action 
   */
  fabCallBack(action) {
    console.log;

    if (action) {
      this[action]()
    } else {
      console.log('not call in component, run callback', 'list');
    }
  }


  toggleExpandRow(row) {
    console.log('Toggled Expand Row!', row);
    /* this.table.rowDetail.collapseAllRows();
    this.table.rowDetail.toggleExpandRow(row); */
  }


  sorted(columnProp: string, direction: string) {
    console.log(columnProp, direction);
    localStorage.setItem('filter_sort_invoices', JSON.stringify({ search_by: 'sort', search_data: [{ prop: columnProp, dir: direction }] }))
  }


  onSelect({ selected }) {
    console.log('Select Event', selected, this.selected);
  }

  /**
   * @description onClick on row open order details
   * @param event pass obj row clicked
   */
  onActivate(event) {
    /* if (this.table.rowDetail && event.type == 'click') {
      this.table.rowDetail.toggleExpandRow(event.row);
      console.log('Activate Event', event);
    } */
    console.log(event);

    if (event.type == 'click') {
      console.log('Activate Event', event);
      this.openInvoiceModal(event.row)
    }


  }

  setExpandStatus(expanded: any): void {
    console.log('setExpandStatus Event', expanded);
  }



  /**
   * @description Create new order, open modal
   * @returns 
   */
  async openNewInvoiceModal() {
    const modal = await this.modalController.create({
      component: NewInvoiceComponent,
      //backdropDismiss: false,
      mode: 'md',
      componentProps: {
        title: 'New order draft',
      }
      //presentingElement: this.routerOutlet.nativeEl,
    })


    modal.onWillDismiss().then((e) => {
      // Se Ok passo la risposta di conferma dell'api per risparmiare sui servizi http
      console.log(e);
      if (e.data && e.data.message != 'OK' || e.role == 'backdrop') {
        console.log('no modify');
        return
      } else {
        // Se esiste refresh brutalmente (si puo rimpiazzare il risultato della singola riga come fatto per la tabella prodotti)
        this.total_page = 0;
        this.page_number = 1;
        this.myInput.value = "";
        this.invoices = [];

        this.getInvoices(false, '', this.queryString)
      }
    });
    return await modal.present();
  }


  /**
   * @description Apre il modale per il dettaglio fattura 
   * @trigger this.onActivate($row)
   * @param item 
   * @returns 
   */
  async openInvoiceModal(item) {
    const modal = await this.modalController.create({
      component: InvoiceDetailsPage,
      cssClass: 'fullscreen',
      backdropDismiss: false,
      mode: 'md',
      componentProps: {
        id: item.id,
        title: item.name,
        parent_type: 'root'
      }
      //presentingElement: this.routerOutlet.nativeEl,
    })


    modal.onWillDismiss().then((e) => {
      // Se Ok passo la risposta di conferma dell'api per risparmiare sui servizi http
      console.log(e);

      if (e.data && e.data.message != 'OK') {
        console.log('no modify');
        return
      } else {
        // Se esiste refresh brutalmente (si puo rimpiazzare il risultato della singola riga come fatto per la tabella prodotti)
        this.total_page = 0;
        this.page_number = 1;
        this.myInput.value = "";
        this.invoices = [];

        this.getInvoices(false, '', this.queryString)
      }
    });
    return await modal.present();
  }



  onGetRowClass = (row) => {
    //console.log(row);
    // id 3 is Delete for Status Order
    if (row.status.id === 3) {
      return 'deleted';
    } else if (row.status.id === 2) {
      return 'request';
    } else if (row.status.id === 1) {
      return 'draft';
    } else if (row.status.id === 4) {
      return 'order';
    }
    else
      return ''
  }


  /**
   * @description ritorna la somma delle colonne che gli si assegna questa funzione
   * @param n 
   * @returns somma della colonna in "rowSummary" template
   */
  caclulateSumm(n?) {
    console.log();

    this.total_order = 0;
    this.invoices.map((item) => {
      this.total_order += item.cost;
      //this.currencyCode = item.currency;
      //console.log(this.total_order);
    });
    return this.total_order.toFixed(2) + ' ' + n;
  }


  onChangeSearchbar(event) {
    console.log(event);
    event = JSON.parse(event)
    if (event && event.data && event.data != '') {
      this.api.searchInvoice(true, '?page=1&items=25', event.data.toString()).subscribe(e => {
        console.log(e);
        if (e)
          this.invoices = e.results;
        this.table.listItems = e.results
      })
    } else {
      // this.loadProjects(this.queryString);
    }
  }


  toggleSearch() {
    this.enableSearch = !this.enableSearch;
    if (this.enableSearch) {
      setTimeout(() => {
        this.myInput.setFocus()
      }, 150);

    }
  }


  methodAction(action) {
    try {
      this[action]()
    } catch {
      console.log('not call in parent component');
    }
    //console.log(action)
  }



}
