/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable object-shorthand */
/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/semi */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable quote-props */


import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController, Platform, ToastController } from '@ionic/angular';
import { FormGroup, FormControl, Validators, FormGroupDirective } from '@angular/forms';
import { DataService } from '../../services/data/data.service';
import * as moment from 'moment';
import { ApiService } from 'src/app/services/api/api.service';
import { IonDatetime } from '@ionic/angular';
import { format, parseISO } from 'date-fns';
import { ProjectsService } from 'src/app/services/projects/projects.service';




@Component({
  selector: 'app-new-worker',
  templateUrl: './new-worker.component.html',
  styleUrls: ['./new-worker.component.scss'],
})
export class NewWorkerComponent {

  minDate = moment().format();
  maxDate = moment().add(1, 'd').format();

  createProjectForm: FormGroup;
  types_category: any = [];
  subscription

  @ViewChild('createForm') createForm: FormGroupDirective;  

  constructor(
    private platform: Platform,
    private modalController: ModalController,
    private toastController: ToastController,
    private dataService: DataService,
    private api: ProjectsService
  ) { }

  dismissModal(status = '') {
    this.modalController.dismiss({ data: { message: status } });
  }

  ionViewDidEnter() {
    this.subscription = this.platform.backButton.subscribe((e) => {
      console.log(e);
      this.dismissModal()
    });
  }

  ionViewWillLeave() {
    this.subscription.unsubscribe();
  }

  ionViewWillEnter(): void {   

    this.createProjectForm = new FormGroup({
      'name': new FormControl('', Validators.required),
      'lastname': new FormControl('', Validators.required),
      'team': new FormControl('', Validators.required),
      'company': new FormControl('', Validators.required),       
      'email': new FormControl('', Validators.required),
      'phone': new FormControl('', Validators.required),
      'city': new FormControl('', Validators.required)
    });
  }


  submitForm() {
    this.createForm.onSubmit(undefined);
  }

  async presentToast(text, title, color) {
    const toast = await this.toastController.create({
      message: title + ': ' + (text.message),
      position: 'bottom',
      duration: 2000,
      cssClass: 'toast',
      color: color
    });
    toast.present();
  }

  createProject(values: any) {
    // copy all the form values into the new worker
    const newProject: any = { ...values };
    //this.dataService.createProject(newProject);
    //newProject.start_date = moment(newProject.start_date).format().split('+')[0];
    //newProject.end_date = moment(newProject.end_date).format().split('+')[0];

    console.log(newProject); 

    /* this.api.createProject(newProject).subscribe(e => {
      console.log(e);
      if (e[0].status != 'ko')
        this.dismissModal('OK')
      else
        this.presentToast(e[0], 'Error', 'danger')
    }) */
  }

  /* onChangeStartDate(event) {
    this.maxDate = moment(event.detail.value).add(1, 'd').format();
    this.createProjectForm.controls.end_date.setValue(this.maxDate)
  } */

  /* formatDate(value: string) {
    return format(parseISO(value), 'MMM dd yyyy');
  } */
}
