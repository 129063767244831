import { Component, QueryList, ViewChildren } from '@angular/core';
import { CardComponent } from '../card/card.component';

const CARDS = 10;
const MAX_VISIBILITY = 3;

@Component({
  selector: 'app-carousel',
  template: `
    <div class="carousel">
      <ion-button class="nav left" (click)="setActive(active - 1)" *ngIf="active > 0">
        <ion-icon name="chevron-back-outline"></ion-icon>      
      </ion-button>
      <!-- <div
        class="card-container"
        *ngFor="let child of children; let i = index"
        [ngStyle]="{
          '--active': i === active ? 1 : 0,
          '--offset': (active - i) / 3,
          '--direction': Math.sign(active - i),
          '--abs-offset': Math.abs(active - i) / 3,
          'pointer-events': active === i ? 'auto' : 'none',
          'opacity': Math.abs(active - i) >= maxVisibility ? '0' : '1',
          'display': Math.abs(active - i) > maxVisibility ? 'none' : 'block'
        }"
      >
        <template [ngTemplateOutlet]="child"></template>
      </div> -->
      <button class="nav right" (click)="setActive(active + 1)" *ngIf="active < count - 1">
        <ion-icon name="chevron-forward-outline"></ion-icon>
      </button>
    </div>
  `,
})
export class CarouselComponent {
  active = 2;
  count: number;
  maxVisibility = MAX_VISIBILITY;

  @ViewChildren(CardComponent) children: QueryList<CardComponent>;

  setActive(index: number): void {
    this.active = index;
  }
}