
import { AfterContentChecked, ChangeDetectorRef, Component, OnChanges, OnInit, Renderer2, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { NavController, ModalController, IonNav, IonRouterOutlet, IonInfiniteScroll, IonSearchbar, IonContent, IonSegment, IonSegmentButton, IonMenu, MenuController } from '@ionic/angular';

import { OfferUpdatePage } from '../offer-update/offer-update.page';
import { animate, query, sequence, stagger, state, style, transition, trigger } from '@angular/animations';

import { OfferDetailsPage } from '../offer-details/offer-details.page';
import { TabsService } from 'src/app/services/tabs/tabs.service';

import { ColumnChangesService, ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { OffersService } from 'src/app/services/offers/offers.service';
import { SubMenuService } from 'src/app/services/utils/sub-menu/sub-menu.service';
import { ListOffersComponent } from '../components/list/list.component';
import { NewOfferComponent } from '../new-offer/new-offer.component';

interface PageInfo {
  offset: number;
  pageSize: number;
  limit: number;
  count: number;
}
/**
 * An object used to get page information from the server
 */
export class Page {
  // The number of elements in the page
  size: number = 0;
  // The total number of elements
  totalElements: number = 0;
  // The total number of pages
  totalPages: number = 0;
  // The current page number
  pageNumber: number = 0;
}

const listAnimation = trigger('listAnimation', [
  transition('* <=> *', [
    query(':enter',
      [style({ opacity: 0 }), stagger('180ms', animate('300ms ease-out', style({ opacity: 1 })))],
      { optional: true }
    ),
    query(':leave',
      animate('200ms', style({ opacity: 0 })),
      { optional: true }
    )
  ])
]);

@Component({
  selector: 'app-offers',
  templateUrl: './offers-list.page.html',
  styleUrls: ['./offers-list.page.scss'],
  // ✨ New! Defining the animation
  animations: [listAnimation],
  //changeDetection: 
})
export class OffersListPage {
  public data: any; // Data;
  public columns: any;
  public rows: any;

  rowIsNotExpanded = true

  editing = {};

  selected = [];

  toggleSrc: boolean;

  funder = [];
  calculated = [];
  pending = [];
  ColumnMode: ColumnMode
  expanded: any = {};
  timeout: any;
  total_offer = 0;
  currentSegment: string = "all";


  @ViewChild('segment') segment: IonSegment;
  @ViewChild('btn_segment') btn_segment: IonSegmentButton;
  @ViewChild('myTable') table: DatatableComponent;
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  @ViewChild(IonContent) content: IonContent;
  @ViewChild(IonSearchbar) myInput: IonSearchbar;


  @ViewChild(ListOffersComponent) listTable: ListOffersComponent;

  fab: boolean = false;
  customAlertOptions: any = {
    header: 'List groups',
    //subHeader: 'Select group for user',
    message: 'Select group for user',
    translucent: true
  };
  offers: any = []
  _offers: any = []
  searching = false
  res: any = []
  offer_status = [];
  offer_types = [];
  offer_categories = [];

  total_page = 0;
  page_number = 1;
  page_limit = 30;

  isSearch: boolean = false;
  isLoad: boolean = false;

  queryString = {
    search_by: '',
    search_data: ''
  };


  totalElements: number;
  pageNumber: number;
  cache: any = {};


  isLoading = 0;

  constructor(
    private menu: MenuController,
    private subMenu: SubMenuService,
    private cd: ChangeDetectorRef,
    private navCtrl: NavController,
    private api: OffersService,
    private modalController: ModalController,
    //private nav: IonNav,
    private routerOutlet: IonRouterOutlet,
    private tabService: TabsService,
    private renderer: Renderer2,
    private columnChangesService: ColumnChangesService
  ) {
    this.toggleSrc = true;

    this.columns = [
      //{ prop: 'id', name: 'ID'/* , frozenLeft: true */, minWidth: 75, maxWidth: 75, cellClass: 'td-id', headerClass: 'th-id' },
      /* {
        prop: 'id', width: 75, minWidth: 75
      }, */
      {
        prop: 'priority.name', name: 'Priority', width: 90, minWidth: 90, cellClass: 'td-priority', headerClass: 'th-priority'
      },
      {
        prop: 'code', width: 195, minWidth: 195
      },
      { prop: 'category.name', name: 'Category', maxWidth: 200 },
      { prop: 'type.name', name: 'Type', maxWidth: 180 },

      //{ prop: 'creator.email', name: 'Email', width: 190, minWidth: 190, maxWidth: 230 },
      { prop: 'project.name', name: 'Project', maxWidth: 240 },
      { prop: 'customer.name', name: 'Customer', maxWidth: 200 },
      { prop: 'vendor.name', name: 'Vendor', maxWidth: 200 },
      //{ prop: 'deleted', maxWidth: 70, cellClass: 'td-deleted', headerClass: 'th-deleted' },

      {
        prop: 'cost', minWidth: 155, maxWidth: 155,/*  summaryFunc: () => this.caclulateSumm('€'), */
      },

    ]
  }

  openMenu() {
    this.menu.open('admin')
    this.subMenu.params.next({
      title: 'Filter',
      icon: 'funnel-outline',
      accordions: [
        {
          target: '',
          color: '',
          icon: 'list-outline',
          title: 'Status'
        },
        {
          target: '',
          color: '',
          icon: 'list-outline',
          title: 'Priority'
        },
        {
          target: '',
          color: '',
          icon: 'list-outline',
          title: 'Category'
        },
        {
          target: '',
          color: '',
          icon: 'list-outline',
          title: 'Type'
        }
      ]
    })
  }




  ionViewWillEnter() {

    console.log();

    this.myInput.value = "";

    this.cache = {}
    //this.offers = [];
    this.fab = true
    this.queryString = localStorage.getItem('filter_search_offers') ? JSON.parse(localStorage.getItem('filter_search_offers')) : { search_by: '', search_data: 'all' }

    //this.loadProjects(this.queryString)
    this.getOffersMeta()

  }


  /**
   * @description after click segment switch for selected status
   * @param data = this.queryString LocalStorage
   */
  loadProjects(data: any) {

    if (data.search_data === 'all') { //category
      //this.projects = this.dataService.getProjects();

      this.getOffers(false, "")
      this.isLoad = true;
    } else {
      //this.projects = this.dataService.getProjectsByCategory(category);
      //this.projects = this.dataService.getProjectsByStatus(status);
      this.getOffers(false, "", data)
      this.isLoad = true;
    }


  }



  /**
   * @description Get offer metadata
   */
  getOffersMeta() {


  }


  concateQuery(search) {
    console.log(search);
    let url = '?page=' + this.page_number + '&items=' + this.page_limit;
    let query = '';

    if (search && search != '' && search.search_data != 'all') {
      query += '&' + search.search_by + '=';
      query += search.search_data;
      url += query

    } else {
    }

    console.log(url);
    return url
  }

  /**
   * @description Load offers on init and load preference filter if exist
   * @param isFirstLoad if true reload all list / if false push and increment page to scroll (in old func now in datatable paginator)
   * @param event if isFirstLoad true "event" is the event trigged
   * @param search if pass query for search by filter status now
   */
  getOffers(isFirstLoad, event, search?) {
    this.isSearch = false;

    const index = this.columns.findIndex(item => item.name === "Status");
    console.log(index)


    if (this.page_number <= 1 && index == -1)
      this.columns.splice(2, 0, { prop: 'status.name', name: 'Status', width: 95, cellClass: 'td-status', headerClass: 'th-status' })

    let url = '';

    if (search && search != '' && search.search_data != 'all') {
      url = this.concateQuery(this.queryString)
    }


    this.api.getOffersList(url)
      .subscribe(data => {

        console.log(data, data.results)

        this.listTable.offers = data.results;
        this.listTable.totalElements = data.total_items;
        this.listTable.total_page = data.num_pages;
        this.listTable.page_number = 1;

        setTimeout(() => {
          this.listTable.table.bodyComponent.offsetX = 0;
          this.listTable.table.bodyComponent.offsetY = 0;
          this.listTable.table.headerComponent.offsetX = 0;
          this.listTable.table.recalculateColumns();
          this.listTable.offers = [...this.listTable.offers]
        }, 100);

        console.log('refresh list') // metodo per aggiornare la lista

        // Completo il ciclio di refresh passando event e terminandolo con event.target.complete()
        if (isFirstLoad)
          event.target.complete();

        // Incremento la pagina in attesa della successiva chiamata 
        this.page_number = 1;
        // Resetto la "cache" per permettere di interrogare di nuovo da pagina 1
        this.cache = {};


      }, error => {
        console.log(error);
      })



  }


  triggerColumnChangeDetection(): void {
    this.columnChangesService.onInputChange();
  }

  /**
   * @description pervius func now use databatable paginator
   * @param event 
   * @param search 
   */
  doInfinite(event, search?) {
    console.log(event, search);
    this.getOffers(true, event, this.queryString);
    if (this.isSearch) {
      //this.getOffers(true, event, 0, search.value)
    } else {

    }
  }

  /**
   * @description quando scrolli dall'alto verso il basso a scoll 0px refresh la lista
   * @param event evento default dalla vista
   */
  doRefresh(event) {
    console.log('Begin async operation');

    setTimeout(() => {
      console.log('Async operation has ended');

      this.total_page = 0;
      this.page_number = 1;
      this.myInput.value = "";
      this.offers = [];
      //this.getOffers(false, '')
      this.loadProjects(this.queryString);


      event.target.complete();
    }, 1000);
  }

  /**
   * @description Richiama nomi funzioni passate dalla vista (si può fare anche tutto da .ts)
   * @param action 
   */
  fabCallBack(action) {
    console.log;

    if (action) {
      this[action]()
    } else {
      console.log('not call in component, run callback', 'list');
    }
  }




  toggleExpandRow(row) {
    console.log('Toggled Expand Row!', row);
    this.table.rowDetail.collapseAllRows();
    this.table.rowDetail.toggleExpandRow(row);
  }


  sorted(columnProp: string, direction: string) {
    console.log(columnProp, direction);
    localStorage.setItem('filter_sort_offers', JSON.stringify({ search_by: 'sort', search_data: [{ prop: columnProp, dir: direction }] }))
  }


  onSelect({ selected }) {
    console.log('Select Event', selected, this.selected);
  }

  /**
   * @description onClick on row open offer details
   * @param event pass obj row clicked
   */
  onActivate(event) {
    /* if (this.table.rowDetail && event.type == 'click') {
      this.table.rowDetail.toggleExpandRow(event.row);
      console.log('Activate Event', event);
    } */
    console.log(event);

    if (event.type == 'click') {
      console.log('Activate Event', event);
      this.openOfferModal(event.row)
    }


  }

  setExpandStatus(expanded: any): void {
    console.log('setExpandStatus Event', expanded);
  }



  /**
   * @description Create new offer, open modal
   * @returns 
   */
 /*  async openNewOfferModal() {
    const modal = await this.modalController.create({
      component: NewOfferComponent,
      cssClass: 'newOrderModalForm',
      backdropDismiss: true,
      mode: 'md',
      componentProps: {
        title: 'New Offer Draft',
      }
      //presentingElement: this.routerOutlet.nativeEl,
    })


    modal.onWillDismiss().then((e) => {
      // Se Ok passo la risposta di conferma dell'api per risparmiare sui servizi http
      console.log('NewOfferComponent to OfferListPage', e);
      if (!e.data) {
        console.log('no modify');
        return
      } else {
        // Se esiste refresh brutalmente (si puo rimpiazzare il risultato della singola riga come fatto per la tabella prodotti)
        this.listTable.offers.push(e.data)
        this.listTable.totalElements = this.listTable.offers.length
        this.listTable.offers = [...this.listTable.offers]
        //this.offers = [];

      }
    });
    return await modal.present();
  }


 */

  /**
   * @description Create new offer, open modal
   * @returns 
   */
  async openNewOfferModal() {
    //nav: this.nav(NewOfferComponent, { animate: true }, { direction: 'forward' })
  }

  /**
   * @description Apre il modale per il dettaglio ordine 
   * @trigger this.onActivate($row)
   * @param item 
   * @returns 
   */
  async openOfferModal(item) {
    const modal = await this.modalController.create({
      component: OfferDetailsPage,
      cssClass: 'fullscreen',
      backdropDismiss: false,
      mode: 'md',
      componentProps: {
        id: item.id,
        title: item.name,
        //nav: this.nav
      }
      //presentingElement: this.routerOutlet.nativeEl,
    })


    modal.onWillDismiss().then((e) => {
      // Se Ok passo la risposta di conferma dell'api per risparmiare sui servizi http
      console.log('OfferDetailsPage to OfferListPage', e, this.listTable.offers);

      if (!e.data) {
        console.log('no modify');
        return
      } else {
        // Se esiste refresh brutalmente (si puo rimpiazzare il risultato della singola riga come fatto per la tabella prodotti)

        this.offers = this.listTable.offers
        let offersIndex = this.offers.map((e, i, a) => e.id).indexOf(item.id)
        this.offers[offersIndex] = e.data;
        console.log(this.offers, offersIndex);

        this.listTable.offers = this.offers;

        setTimeout(() => {
          this.listTable.table.bodyComponent.offsetX = 0;
          this.listTable.table.bodyComponent.offsetY = 0;
          this.listTable.table.headerComponent.offsetX = 0;
          this.listTable.table.recalculateColumns();
          this.listTable.offers = [...this.listTable.offers]
        }, 100);

        console.log(this.offers);


      }
    });
    return await modal.present();
  }



  onGetRowClass = (row:any) => {
    //console.log(row);
    // id 3 is Delete for Status Offer
    if (row.status.id === 3) {
      return 'deleted';
    } else if (row.status.id === 2) {
      return 'request';
    } else if (row.status.id === 1) {
      return 'draft';
    } else if (row.status.id === 4) {
      return 'offer';
    }
    else
      return ''
  }


  /**
   * @description ritorna la somma delle colonne che gli si assegna questa funzione
   * @param n 
   * @returns somma della colonna in "rowSummary" template
   */
  caclulateSumm(n?) {
    console.log();

    this.total_offer = 0;
    this.offers.map((item) => {
      this.total_offer += item.cost;
      //this.currencyCode = item.currency;
      //console.log(this.total_offer);
    });
    return this.total_offer.toFixed(2) + ' ' + n;
  }



  onChangeSearchbar(event) {
    console.log(event);
    if (event && event.detail && event.detail.value != '') {
      this.queryString = localStorage.getItem('filter_search_offers') ? JSON.parse(localStorage.getItem('filter_search_offers')) : { search_by: '', search_data: 'all' }

      this.page_number = 1;
      let url = this.concateQuery(this.queryString)

      this.api.searchOffers(true, url, event.detail.value.toString()).subscribe(e => {
        console.log(e);
        if (e) {
          this.listTable.offers = e.results;
          this.listTable.totalElements = e.total_items;

          setTimeout(() => {
            this.listTable.table.bodyComponent.offsetX = 0;
            this.listTable.table.bodyComponent.offsetY = 0;
            this.listTable.table.headerComponent.offsetX = 0;
            this.listTable.table.recalculateColumns();
            this.listTable.offers = [...this.listTable.offers]
          }, 100);
        }
      })

    } else {
      //this.loadProjects(this.queryString);
      this.getOffers(false, event, this.queryString);
    }
  }

  onClearSearchbar(event) {
    console.log(event);
    this.loadProjects(this.queryString)
  }

  onSwichStatusSeg(event) {
    console.log(event, this.myInput);
    if (this.myInput.value != '') {
      document.querySelector('ion-searchbar').getInputElement().then((searchInput) => {
        searchInput.value = '';
      });
    }

  }




}
