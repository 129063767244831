import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-text-skeleton',
  templateUrl: './text-skeleton.component.html',
  styleUrls: ['./text-skeleton.component.scss'],
})
export class TextSkeletonComponent {
  _data: '';

  @HostBinding('class.text-loaded') textLoaded = false;

  @Input() set data(val: any) {
    setTimeout(()=>{
      
    this._data = (val !== undefined && val !== null) ? val : '';

    if (this._data && this._data !== '') {
      this.textLoaded = true;
    } else {
      this.textLoaded = false;
    }
    }, 250)
  }

  constructor() { }
}