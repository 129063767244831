// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-card-header {
  position: absolute;
  bottom: 30%;
  z-index: 12;
  width: 100%;
  text-align: center;
  top: 35%;
}

ion-card-header * { /* color: #000 !important; */
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;
  color: #fff !important;
  letter-spacing: 6px;
}

ion-card {
  position: relative;
}

ion-row div {
  display: contents;
}

ion-row.myHeight {
  overflow: hidden;
}
ion-row.myHeight ion-card {
  height: 110px;
  max-height: 110px;
  min-height: 110px;
}`, "",{"version":3,"sources":["webpack://./src/app/home/home.page.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,WAAA;EACA,WAAA;EACA,WAAA;EACA,kBAAA;EACA,QAAA;AACJ;;AACA,oBAAA,4BAAA;EACI,eAAA;EACA,yBAAA;EACA,gBAAA;EACA,sBAAA;EACA,mBAAA;AAEJ;;AAAA;EAEI,kBAAA;AAEJ;;AAAA;EACI,iBAAA;AAGJ;;AAGA;EAEI,gBAAA;AADJ;AAGI;EACI,aAAA;EACA,iBAAA;EACA,iBAAA;AADR","sourcesContent":["ion-card-header {\n    position: absolute;\n    bottom: 30%;\n    z-index: 12;\n    width: 100%;\n    text-align: center;\n    top: 35%;\n}\nion-card-header * {    /* color: #000 !important; */\n    font-size: 13px;\n    text-transform: uppercase; \n    font-weight: 600;\n    color: #fff !important;\n    letter-spacing: 6px;\n}\nion-card { \n \n    position: relative; \n}\nion-row div {\n    display: contents;\n}\n\n\n\n\nion-row.myHeight{\n    //height: 140px;\n    overflow: hidden;\n    \n    ion-card{\n        height: 110px;\n        max-height: 110px;\n        min-height: 110px;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
