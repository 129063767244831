import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Platform, ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { BehaviorSubject, from, Observable, of, Subject } from 'rxjs';
import { takeUntil, catchError, delay, distinctUntilChanged, map, retryWhen, scan, tap, timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../authentication/authentication.service';
import { NetworkService, ConnectionStatus } from '../network/network.service';
//import { Storage } from '@ionic/storage';
import { OfflineManagerService } from '../network/offline-manager.service';


import { Project } from 'src/app/projects/models/projects';
import { Docs } from 'src/app/documents/models/docs';
import { Order } from 'src/app/orders/models/order.model';


const API_STORAGE_KEY = 'ordersService';
const TOKEN_KEY = 'access-token';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  private url: string = 'http';
  private token: any;
  private projects: Project[];
  private documents: Docs[];
  private document: Docs;
  private project_types: any[];
  private cancelPendingRequests$ = new Subject<void>();

  //public docList = new BehaviorSubject(null);

  enableMenu = new BehaviorSubject<any>({}) ;
  saveVendors = new BehaviorSubject<any>({});
  saveJobs = new BehaviorSubject<any>({});
  saveItem = new BehaviorSubject<any>({});


  newDiscountOrder = new Subject()

  constructor(
    private storage: Storage,
    private plt: Platform,
    private http: HttpClient,
    public toastController: ToastController,
    private authService: AuthenticationService,
    private networkService: NetworkService,
    private offlineManager: OfflineManagerService
  ) {

    /**
     * Map url API
     */
    this.url = environment.SSL.active ? this.url + 's' : this.url
    this.url += '://' + environment.API_URI.url + ':' + environment.API_URI.port + environment.API_URI.path

    this.authService.currentToken.subscribe(x => {
      //console.log(x);
      this.token = x //= JSON.parse(localStorage.getItem(TOKEN_KEY))

    })

  }

  /**
   * 
   * ORDERS
  */


  searchOrder(forceRefresh: boolean = true, url: string, string: string): Observable<any> {

    this.logSys('searchOrder', { forceRefresh, url, string })

    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline || !forceRefresh) {
      // Return the cached data from Storage
      return from(this.getLocalData('search-order'));
    } else {
      let body = {
        txt: string.toString()
      };

      return this.http.get<any>(`${this.url}order/orders/${url}&search=${string.toString()}`)
        .pipe(
          timeout(20000),
          tap(data => {
            console.log(data);
            this.setLocalData('search-order', data);
            return data
          }),
          catchError(this.handleError<any[]>('Search order', [])),
        takeUntil(this.cancelPendingRequests$)

        );
    }

  }

  tmp_rangePrice(forceRefresh: boolean = true, string: number, string2: number): Observable<any> {

    return this.http.get<any>(`${this.url}order/orders/?page=1&items=25&cost_min=${string.toString()}&cost_max=${string2.toString()}`)
      .pipe(
        timeout(12000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Search Range Cost Order', []))
      );


  }


  /**
  * 
  *
  * @returns list orders
  */
  order_orders_list(params?): Observable<any> {
    console.log(params)
    return this.http.get<any>(`${this.url}order/orders/${params}`)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          this.setLocalData('list', data);
          return data
        }),
        catchError(this.handleError<any[]>('Get orders list', [])),
        takeUntil(this.cancelPendingRequests$)

      );

  }


  order_orders_by_status_read(params?, code?): Observable<any> {
    console.log(params)
    return this.http.get<any>(`${this.url}order/orders_by_status/${code}/${params}`)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data)
          this.setLocalData('list', data);
          return data
        }),
        catchError(this.handleError<any[]>('Get orders by status list', [])),
        takeUntil(this.cancelPendingRequests$)
      );

  }


  /**
  * 
  *
  * @returns list documents orders by id
  */
  order_order_documents_list(order_id): Observable<any> {

    return this.http.get<any>(`${this.url}order/order/${order_id}/documents/`)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Get orders docs list', []))
      );

  }


  /**
  * 
  *
  * @returns upload document order by order_id
  */
  order_order_documents_create(order_id, form): Observable<any> {

    return this.http.post<any>(`${this.url}order/order/${order_id}/documents/`, form)
      .pipe(
        timeout(80000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Upload doc order by id', []))
      );

  }


  /**
  * 
  *
  * @returns get/download document order by order_id e doc_id
  */
  order_order_document_download_list(order_id, form): Observable<any> {
    //{order_id}/document/{doc_id}/download/ 
    return this.http.get(`${this.url}order/order/${order_id}/document/${form?.id}/download/`, {
      observe: 'response',
      responseType: 'blob'
    })
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Download doc order by order_id e doc_id', []))
      );

  }



  /**
  * 
  *
  * @returns delete document order by order_id e doc_id
  */
  order_order_document_delete(order_id, form): Observable<any> {
    //{order_id}/document/{doc_id}/download/ 
    return this.http.delete(`${this.url}order/order/${order_id}/document/${form?.id}/`)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Delete doc order by order_id e doc_id', []))
      );

  }

  /**
  * 
  *
  * @returns file infos document order by id
  */
  order_order_document_read(order_id, doc_id): Observable<any> {

    return this.http.get<any>(`${this.url}order/order/${order_id}/document/${doc_id}/`)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('fileInfos doc order by id', []))
      );

  }

  /**
 * 
 * @param id id of order
 * @returns get order by project_id?
 */
  getOrdersByProjectId(project_id, params?): Observable<any> {
    if(!params){
      params = '?page=1&items=25'
    }
    return this.http.get<any>(`${this.url}order/orders/${params}&vendor_id=${project_id}`)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Get orders by project_id', [])),
        takeUntil(this.cancelPendingRequests$)

      );

  }


  getOrdersByVendorIdNull(project_id, params?): Observable<any> {
    if(!params){
      params = '?page=1&items=25'
    }
    return this.http.get<any>(`${this.url}order/orders/${params}&vendor_id=${null}`)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Get orders by project_id', [])),
        takeUntil(this.cancelPendingRequests$)

      );

  }

  /**
 * 
 * @param id id of order
 * @returns get order by job_id?
 */
  getOrdersByJobId(job_id, params?): Observable<any> {
    if(!params){
      params = '?page=1&items=25'
    }
    return this.http.get<any>(`${this.url}order/orders/${params}&job_id=${job_id}`)
      .pipe(
        timeout(50000),
        tap(data => {
          console.log(data);
          if(data.length !== 0){
            this.setMenu(true)
            console.log('lodjd n');
          }
          return data
        }),
        catchError(this.handleError<any[]>('Get orders by job_id', [])),
        takeUntil(this.cancelPendingRequests$)

      );

  }

  getOrderByODA(job_id?, params?): Observable<any> {
    if(!params){
      params = '?page=1&items=1000&title=ODA'
    }
    return this.http.get<any>(`${this.url}order/orderextras/${params}`)
      .pipe(
        timeout(50000),
        tap(data => {
          console.log(data);
          if(data.length !== 0){
            this.setMenu(true)
            console.log('lodjd n');
          }
          return data
        }),
        catchError(this.handleError<any[]>('Get orders by job_id', [])),
        takeUntil(this.cancelPendingRequests$)

      );

  }


  /**
  * 
  *
  * @returns list order dates
  */
  order_orderdates_create(form): Observable<any> {

    return this.http.post<any>(`${this.url}order/orderdates/`, form)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Get order date create', []))
      );

  }

  /**
  * 
  *
  * @returns list upadate order dates
  */
  order_orderdate_update(form, order_id): Observable<any> {

    return this.http.put<any>(`${this.url}order/orderdate/${order_id}/`, form)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Get order date update', []))
      );

  }

  /**
  * 
  *
  * @returns list delete order dates
  */
  order_orderdate_delete(order_id): Observable<any> {

    return this.http.delete<any>(`${this.url}order/orderdate/${order_id}/`)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Delete order date update', [])),
        takeUntil(this.cancelPendingRequests$)
      );

  }
  /**
  * 
  *
  * @returns list order dates by order_id
  */
  order_orderdates_list_by_order_id(params, order_id): Observable<any> {

    return this.http.get<any>(`${this.url}order/orderdates/?order_id=${order_id}&${params}`)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Get order date list', [])),
        takeUntil(this.cancelPendingRequests$)
      );

  }

  /**
  * 
  *
  * @returns list order dates by order_id
  */
  order_orderdates_list(): Observable<any> {

    return this.http.get<any>(`${this.url}order/datetypes/`)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Get order date types list', []))
      );

  }


  /**
  * 
  *
  * @returns list order sequences
  */
  order_sequences_list(params): Observable<any> {

    return this.http.get<any>(`${this.url}order/sequences/${params}`)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Get order sequences list', [])),
        takeUntil(this.cancelPendingRequests$)

      );

  }


  /**
  * 
  *
  * @returns read order sequences
  */
  order_sequence_read(seq_id): Observable<any> {

    return this.http.get<any>(`${this.url}order/sequence/${seq_id}/`)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Get order sequences by id', [])),
        takeUntil(this.cancelPendingRequests$)

      );

  }



  /**
  * 
  *
  * @returns update order by order_id
  */
  order_order_update(order_id, form): Observable<any> {
    let error = 'Put order by order_id'
    console.log(order_id);
    if(form.customer_id == null || form.vendor_id == null)
      error = 'Inserisci il venditore e/o il cliente'
    console.log(form)
    return this.http.put<any>(`${this.url}order/order/${order_id}/`, form)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>(error, []))
      );

  }

  /**
 * 
 * @param id id of order
 * @returns get orde count by project_id?
 */
  getOrdersByProjectIdCount(project_id): Observable<any> {
    return this.http.get<any>(`${this.url}order/orders/project_id=${project_id}`)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Get orders count by project_id', [])),
        takeUntil(this.cancelPendingRequests$)

      );

  }
  /**
  * 
  *
  * @returns Type Order Types
  */
  order_categories_create(form): Observable<any> {
    console.log(this.url)
    return this.http.post<any>(`${this.url}order/categories/`, form)///${params}
      .pipe(
        timeout(16000),
        map(data => {
          console.log(data);

          return data
        }),
        catchError(this.handleError<any[]>('Post new order category', []))
      );
  }

  /**
  * 
  *
  * @returns Type Order Types
  */
  getOrderTypes(params?): Observable<any> {
    console.log(this.url)
    return this.http.get<any>(`${this.url}order/types/`)///${params}
      .pipe(
        timeout(16000),
        map(data => {
          console.log(data);
          this.setLocalData('types', data.results);
          return data.results
        }),
        catchError(this.handleError<any[]>('Get order types', [])),
        takeUntil(this.cancelPendingRequests$)

      );
  }

    /**
  * 
  *
  * @returns Type Order Types
  */
    getCausalsList(): Observable<any> {
      return this.http.get<any>(`${this.url}order/causals/`)
        .pipe(
          timeout(16000),
          map(data => {
            console.log(data);
            this.setLocalData('causals', data.results);
            return data.results
          }),
          catchError(this.handleError<any[]>('Get causals list ', []))
        );
    }

  /**
  * 
  *
  * @returns Type Order Extra Types
  */
  getOrderExtraTypes(params?): Observable<any> {
    return this.http.get<any>(`${this.url}order/extratypes/`)///${params}
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Get order extratypes', []))
      );
  }

  /**
  * 
  *
  * @returns Type Order Invoiceterms
  */
  getOrderInvoiceterms(): Observable<any> {
    return this.http.get<any>(`${this.url}order/invoiceterms/`)///${params}
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Get order invoiceterms', []))
      );
  }

  /**
  * 
  *
  * @returns Type Order Paymentterms
  */
  getOrderPaymentterms(): Observable<any> {
    return this.http.get<any>(`${this.url}order/paymentterms/`)///${params}
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Get order paymentterms', []))
      );
  }


  /**
  * 
  *
  * @returns Type Order Paymenttypes
  */
  getOrderPaymenttypes(): Observable<any> {
    return this.http.get<any>(`${this.url}order/paymenttypes/`)///${params}
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Get order paymenttypes', []))
      );
  }

  /**
  * 
  *
  * @returns Type Order Category
  */
  order_categories_list(params?): Observable<any> {
    return this.http.get<any>(`${this.url}order/categories/${params}`)//
      .pipe(
        timeout(16000),
        map(data => {
          console.log(data);
          this.setLocalData('categories', data.results);
          return data
        }),
        catchError(this.handleError<any[]>('Get order categories', [])),
        takeUntil(this.cancelPendingRequests$)

      );
  }



  /**
 * 
 * @param id id of order
 * @returns get order by id?
 */
  getOrderById(id, mode?): Observable<any> {

    return this.http.get<any>(`${this.url}order/order/${id}/${mode}`)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Get order by id', [])),
        takeUntil(this.cancelPendingRequests$)

      );

  }



  /**
 * 
 * @param id id of order
 * @returns delete order by id?
 */
  deleteOrderById(order): Observable<any> {

    return this.http.delete<any>(`${this.url}order/order/${order.id}`)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Delete order by id', []))
      );

  }


  /**
   * 
   * @param order 
   * @returns create order by project_id
   */

  order_jobs_create(order) {
    console.log(order);
    return this.http.post<any>(`${this.url}order/jobs/`, order)
      .pipe(
        tap(data => {
          console.log(data);
        }),
        catchError(this.handleError<any[]>('Create job by order_id', []))
      );
  }

  /**
   * 
   * @param order 
   * @returns create order by project_id
   */

  order_orders_create(order) {
    console.log(order);
    return this.http.post<any>(`${this.url}order/orders/`, order)
      .pipe(
        tap(data => {
          console.log(data);
        }),
        catchError(this.handleError<any[]>('Create order by job id', []))

      );
  }


  order_orders_copy(id) {
    return this.http.post<any>(`${this.url}order/ordercopy/${id}/`,id)
      .pipe(
        tap(data => {
          console.log(data);
        }),
        catchError(this.handleError<any[]>('Copy order', []))

      );
  }


  /**
   * 
   * @param offer 
   * @returns create offer 
   */

  createOffer(order) {

    console.log(order);
    return ''

    return this.http.post<any>(`${this.url}order/orders/`, order)
      .pipe(
        tap(data => {
          console.log(data);
        }),
        catchError(this.handleError<any[]>('Create order by project id', []))
      );
  }



  /**
   * 
   * @param order 
   * @returns create line order
   */

  order_orderlines_create(form, order) {

    return this.http.post<any>(`${this.url}order/orderlines/`, form)
      .pipe(
        tap(data => {
          console.log(data);
        }),
        catchError(this.handleError<any[]>('Create line order', [])),
        takeUntil(this.cancelPendingRequests$)

      );
  }

  /**
   * 
   * @param order 
   * @returns delete line order
   */

  order_orderlines_delete(item) {

    return this.http.delete<any>(`${this.url}order/orderline/${item}/`)
      .pipe(
        tap(data => {
          console.log(data);
        }),
        catchError(this.handleError<any[]>('Delete line order', []))
      );
  }

  /**
   * 
   * @param order 
   * @returns put details line order
   */

  putDetailsLinesOrder(form) {
    return this.http.put<any>(`${this.url}order/orderline/${form.id}/`, form)
      .pipe(
        tap(data => {
          console.log(data);
        }),
        catchError(this.handleError<any[]>('put detail line order', []))
      );
  }


  /**
   * 
   * @param order 
   * @returns get lines order
   */

  getLinesOrder(order_id) {

    return this.http.get<any>(`${this.url}order/order/${order_id}/lines/`)
      .pipe(
        tap(data => {
          console.log(data);
        }),
        catchError(this.handleError<any[]>('get lines order', []))
      );
  }


  /**
   * 
   * @param order 
   * @returns get details line order
   */

  getDetailsLinesOrder(order_id) {

    return this.http.get<any>(`${this.url}order/order/${order_id}/line/`)
      .pipe(
        tap(data => {
          console.log(data);
        }),
        catchError(this.handleError<any[]>('get detail line order', []))
      );
  }




  /**
   * 
   * @param order 
   * @returns get legal note order
   */

  getLegalOrder(order_id: Order) {

    return this.http.get<any>(`${this.url}order/orderlegal/${order_id}/`)
      .pipe(
        tap(data => {
          console.log(data);
        }),
        catchError(this.handleError<any[]>('get legals order', []))
      );
  }



  /**
   * 
   * @param order 
   * @returns get details legal order
   */

  getDetailsLegalOrder(order_id: Order) {

    return this.http.get<any>(`${this.url}order/order/${order_id}/legal/`)
      .pipe(
        tap(data => {
          console.log(data);
        }),
        catchError(this.handleError<any[]>('get detail legal order', []))
      );
  }


  /**
   * 
   * @param order 
   * @returns create details legal order
   */

  createDetailsLegalOrder(form, order: Order) {

    return this.http.post<any>(`${this.url}order/order/${order.id}/legal/`, order)
      .pipe(
        tap(data => {
          console.log(data);
        }),
        catchError(this.handleError<any[]>('put detail legal order', []))
      );
  }


  /**
   * 
   * @param order 
   * @returns put details legal order
   */

  putDetailsLegalOrder(form, order: Order) {

    return this.http.put<any>(`${this.url}order/order/${order.id}/legal/${form.id}/`, form)
      .pipe(
        tap(data => {
          console.log(data);
        }),
        catchError(this.handleError<any[]>('put detail legal order', []))
      );
  }


  /**
   * 
   * @param order 
   * @returns get priorities order
   */

  getPrioritiesOrder() {

    return this.http.get<any>(`${this.url}order/priorities/`)
      .pipe(
        map(data => {
          console.log(data);
          this.setLocalData('priorities', data.results);
          return data.results
        }),
        catchError(this.handleError<any[]>('get priorities order', []))
      );
  }


  /**
   * 
   * @param order 
   * @returns get list status order
   */

  getOrderStatus() {

    return this.http.get<any>(`${this.url}order/status/`)
      .pipe(
        tap(data => {
          console.log(data);
          this.setLocalData('status', data);
        }),
        catchError(this.handleError<any[]>('get status order', []))
      );
  }


  /**
   * 
   * @param order 
   * @returns put status order
   */

  putOrderStatus(order, form) {
    console.log(form)
    return this.http.put<any>(`${this.url}order/order/${order.id}/status/`, form)
      .pipe(
        tap(data => {
          console.log(data);
        }),
        catchError(this.handleError<any[]>('put status order', []))
      );
  }


  /**
   * 
   * SCONTI
  */

  /**
  * @description Visualizza le tipoligie di sconto  order
  * @returns [{"code":"P","label":"Percentage"},{"code":"S","label":"Subtraction"},{"code":"D","label":"Last Cost"}]
  */
  getOrdersDiscountType(): Observable<any> {
    console.log('get type discount');

    return this.http.get<any>(`${this.url}order/discount/types/`)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Get types discount order list', []))
      );

  }

  /**
  * @description Visualizza gli sconti di un ordine
  * @order order obj
  * @returns {"order_id":9,"global_discounts":[{"id":1,"discount_type":"P","value":10.0,"approved":false,"version":0,"deleted":false,"created":"2022-04-25T08:53:49Z","order":9,"vendor":1}],"lines":[{"line_id":1,"discounts":[]},{"line_id":2,"discounts":[]}]}
  */
  getOrdersGlobalDiscount(order): Observable<any> {
    return this.http.get<any>(`${this.url}order/order/${order.id}/discounts/`)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Get discount list', []))
      );

  }

  /**
  * @description crea uno sconto sull'ordine (una linea)
  * @order_id
  * @form {"discount_category":"line","line_id":2,"vendor_id":1,"discount_type":"P","value":20,"version":1}
  * @returns {"message":"OK: Global discount inserted","data":{"id":3,"discount_type":"P","value":20.0,"approved":false,"version":1,"deleted":false,"created":"2022-04-25T13:02:47.517974Z","order":9,"vendor":1}}
  */
  craeteOrdersDiscount(order_id, form): Observable<any> {

    return this.http.post<any>(`${this.url}order/order/${order_id}/discounts/`, form)
      .pipe(
        timeout(16000),
        tap(e => {
          console.log(e);
          this.newDiscountOrder.next(e.data)
          return e
        }),
        catchError(this.handleError<any[]>('Create single line discount', []))
      );

  }

  /**
  * 
  *
  * @returns aggiorna il numbering tra il local_id e id mysql 
  */
  updateNumberingIdLocal(order, form): Observable<any> {

    return this.http.put<any>(`${this.url}order/order/${order.id}/numbering/`, form)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          //this.setLocalData('list', data);
          return data
        }),
        catchError(this.handleError<any[]>('Set line order numbering linew order', []))
      );

  }

  /**
  * 
  *
  * @returns aggiorna il numbering tra il local_id e id mysql 
  */
  updateAllNumberingIdLocal(order, form): Observable<any> {

    return this.http.post<any>(`${this.url}order/${order.id}/numbering/`, form)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          //this.setLocalData('list', data);
          return data
        }),
        catchError(this.handleError<any[]>('Set line order numbering linew order', []))
      );

  }



  /**
  * 
  *
  * @returns print orders
  */
  getOrdersPrint(order): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    const requestOptions: Object = {
      headers: headers,
      responseType: 'text'
    }
    return this.http.get<any>(`${this.url}order/order/${order.id}/print/`, requestOptions)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Print orders ', []))
      );

  }




  private handleError<T>(operation = 'operation', result?: T) {

    return (error: any): Observable<T> => {

      //console.error(error);
      console.log(result, error);

      console.log(`${operation} failed`, error);
      const status = error.status; //error == 'Forbidden' ? 403 : 500
      this.presentToast(status, error == 'undefined' ? 'Internal Server Error' : error, operation)
      return of(error.error as T);
    };
  }


  async presentToast(status, statusText, message) {
    const toast = await this.toastController.create({
      message: status + ' ' + statusText + ': "' + message + '"',
      duration: 2000,
      mode: 'ios',
      cssClass: 'toast',
      color: status != '200' ? 'danger' : 'primary'
    });
    toast.present();
  }

  logSys(src, status, opt?) {
    const debugStyle1 = 'background: linear-gradient(135deg,#471ee9,#3a49b7); border: 1px solid #9a9a9a; color: #ffffff; border-bottom-left-radius: 2px; border-top-left-radius: 2px; padding: 2px 0 2px 4px;';
    const debugStyle2 = 'background: #252b3e; border: 1px solid #9a9a9a; border-top-right-radius: 2px; border-bottom-right-radius: 2px; margin-left: -2px; padding: 2px 4px; color: white;';

    //console.log('ForceRefresh API ', status, this.networkService.getCurrentNetworkStatus(), this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline);
    console.log(`%cApiService %c%s`, debugStyle1, debugStyle2, ' ' + src, status);
  }




  // Save result of API requests
  private setLocalData(key, data) {
    console.log(API_STORAGE_KEY)
    this.storage.set(`${API_STORAGE_KEY}-${key}`, data);
  }

  // Get cached API result
  private getLocalData(key) {
    console.log(API_STORAGE_KEY)
    return this.storage.get(`${API_STORAGE_KEY}-${key}`);
  }


  /**
   * 
   * @param date
   * @param order_id 
   * @returns update date expected stop 
   */
  updateDateExpected(date, order_id): Observable<any> {
    let form = {
      expected_date_stop: date
    }
    return this.http.put<any>(`${this.url}order/order/${order_id}/`, form)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Update order by id', []))
      );

  }
  /**
   * Create New Legal 
   * @param order_id 
   * @param form 
   * @returns 
   */



  createLegal(form, order_id): Observable<any> {
    console.log(form, order_id);
    //return;

    return this.http.post<any>(`${this.url}order/orderlegals/`, form)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Create single legal', []))
      );

  }

    /**
   * Create New Legal 
   * @param order_id 
   * @param form 
   * @returns 
   */



    order_orderlegal_delete(item): Observable<any> {
      console.log(item);
      //return;
  
      return this.http.delete<any>(`${this.url}order/orderlegal/${item}/`, )
        .pipe(
          timeout(16000),
          tap(data => {
            console.log(data);
            return data
          }),
          catchError(this.handleError<any[]>('Delete single legal', []))
        );
  
    }
  
  /**
  * Create New Extra 
  * @param order_id 
  * @param form 
  * @returns 
  */
  order_orderextras_create(form, order_id): Observable<any> {
    console.log(form, order_id);
    //return;

    return this.http.post<any>(`${this.url}order/orderextras/`, form)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Create single extra ', []))
      );

  }


  /**
   * 
   * @param order 
   * @returns delete extra order
   */

  order_orderextras_delete(item) {

    return this.http.delete<any>(`${this.url}order/orderextra/${item}/`)
      .pipe(
        tap(data => {
          console.log(data);
        }),
        catchError(this.handleError<any[]>('Delete extra order', []))
      );
  }


  /**
 * 
 * @param date
 * @param order_id 
 * @returns update date expected stop 
 */
  order_orderlegal_update(form, line_id, order_id): Observable<any> {
    return this.http.put<any>(`${this.url}order/orderlegal/${line_id}/`, form)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Update order legal by id', []))
      );

  }


  /**
  * Create New Extra 
  * @param order_id 
  * @param form 
  * @returns 
  */
  order_orderpayment_create(form, order_id): Observable<any> {
    console.log(form, order_id);
    //return;

    return this.http.post<any>(`${this.url}order/payments/`, form)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Create single payment ', []))
      );

  }


  /**
  * Delete Payament 
  * @param order_id 
  * @param form 
  * @returns 
  */
  order_orderpayment_delete(item): Observable<any> {
    console.log(item);
    //return;

    return this.http.delete<any>(`${this.url}order/payment/${item}/`)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Delete single payment ', []))
      );

  }


  /**
 * 
 * @param date
 * @param order_id 
 * @returns update date expected stop 
 */
  order_orderpayment_update(form, line_id, order_id): Observable<any> {
    return this.http.put<any>(`${this.url}order/payment/${line_id}/`, form)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Update order payment by id', []))
      );

  }
  /**
* 
* @param date
* @param order_id 
* @returns update date expected stop 
*/
  order_orderextra_update(form, line_id, order_id): Observable<any> {
    return this.http.put<any>(`${this.url}order/orderextra/${line_id}/`, form)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Update order extra by id', []))
      );

  }




  /**
* 
* @param 
* @param id, order_id
* @returns Choose Vendor Discount
*/
  chooseDiscountVendor(form, order_id, id): Observable<any> {
    console.log(form, order_id, id);

    return this.http.put<any>(`${this.url}order/order/${order_id}/discount/${id}/`, form)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Update order by id', []))
      );

  }

  setMenu(data: any) {
    return this.enableMenu.next(data);
  }

  getMenu(): Observable<any> {    
    return this.enableMenu.asObservable();
  }

  setVendors(data: any) {
    return this.saveVendors.next(data);
  }

  getVendors(): Observable<any> {
    return this.saveVendors.asObservable();
  }

  setJobs(data: any) {
    return this.saveJobs.next(data);
  }

  getJobs(): Observable<any> {
    return this.saveJobs.asObservable();
  }
  
  setItem(data: any) {
    return this.saveItem.next(data);
  }

  getItem(): Observable<any> {
    return this.saveItem.asObservable();
  }


  public cancelRequests() {
    this.cancelPendingRequests$.next();
  }


}
