// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h2 {
  font-size: 19px !important;
}

ion-chip {
  border-radius: 5px;
  color: #fff;
  border: 1px solid;
  /* background: #ff418200; */
  border-color: rgba(255, 65, 130, 0.368627451);
  /* box-shadow: 0 0 0 0 #f7f7f7;*/
}`, "",{"version":3,"sources":["webpack://./src/app/vendors/vendor-details/vendor-details.page.scss"],"names":[],"mappings":"AAIA;EACI,0BAAA;AAHJ;;AAOA;EACI,kBAAA;EACA,WAAA;EACA,iBAAA;EACA,2BAAA;EACA,6CAAA;EACA,gCAAA;AAJJ","sourcesContent":["ion-item{\n    //--background: transparent !important;\n}\n\nh2 {\n    font-size: 19px !important;\n}\n\n\nion-chip {\n    border-radius: 5px;\n    color: #fff;\n    border: 1px solid;\n    /* background: #ff418200; */\n    border-color: #ff41825e;\n    /* box-shadow: 0 0 0 0 #f7f7f7;*/\n  }\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
