// translate.pipe.ts
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'translate'
})
export class TranslatePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return value;

    const translations = {
      'Code': 'codice',
      'Name': 'nome'
    };

    return translations[value] || value;
  }
}
