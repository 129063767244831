// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-datetime {
  width: 100%;
  min-width: 100%;
  height: 100%;
}

::ng-deep .popover-setting .popover-wrapper {
  --min-width: 350px !important;
}

ion-title {
  font-family: "Source Sans Pro";
  font-size: 16px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/form/modal-content/modal-content.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,eAAA;EACA,YAAA;AACJ;;AAEA;EACI,6BAAA;AACJ;;AAGA;EACI,8BAAA;EACA,eAAA;AAAJ","sourcesContent":["ion-datetime{\n    width: 100%;\n    min-width: 100%;\n    height: 100%;\n}\n\n::ng-deep .popover-setting .popover-wrapper{\n    --min-width: 350px !important;\n}\n\n\nion-title{\n    font-family: 'Source Sans Pro';\n    font-size: 16px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
