import { Component, OnInit, Output, ViewChild, Input, EventEmitter, AfterViewInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { LoadingController, ModalController, PopoverController, ToastController } from '@ionic/angular';
import * as moment from 'moment';
import { OrdersService } from 'src/app/services/orders/orders.service';

@Component({
  selector: 'app-new-date',
  templateUrl: './new-date.component.html',
  styleUrls: ['./new-date.component.css']
})
export class NewDateComponent implements OnInit, AfterViewInit {
  name: string;


  order_orders_createForm: FormGroup;

  minDate = moment("2020-01-01").add(1, 'd').format()
  maxDate = moment("2020-01-01").format();
  @ViewChild('createForm') createForm: FormGroupDirective;


  @Input() prevent: any;
  @Input() date: any;
  @Input() listStatus: any;
  @Input() order: any;
  @Output() date_exp = new EventEmitter();

  listTypeStatus = []
  isIconSize = false;
  /* */
  chgToolbar = ''
  iconName = 'chevron-up-circle-outline'
  showConfirm = true


  constructor(private modalCtrl: ModalController,
    private apiOrder: OrdersService,
    private fb: FormBuilder,
    private toastController: ToastController,
    private popCtrl: PopoverController,
    private loadCtrl: LoadingController,
    private renderer: Renderer2) {



  }

  ngAfterViewInit(): void {




    if (this.order) {
      this.apiOrder.order_orderdates_list_by_order_id('page=1&items=25', this.order.id).subscribe(x => {
        if (x.results.length > 0) {
          this.date = x.results
        }
      })

      this.apiOrder.order_orderdates_list().subscribe(x => {
        if (x.results.length > 0) {
          this.listTypeStatus = x.results
        }
      })
    }


  }

  ngOnInit(): void {
    this.createBaseForm()
  }

  dismissModal() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  confirm() {
    return this.modalCtrl.dismiss(this.name, 'confirm');
  }


  // Call submitForm with click Save
  submitForm() {
    console.log('onSubmit');
    this.createForm.onSubmit(undefined);
  }

  onChangeStartDate(event) {
    console.log(event);

    if (!this.prevent)
      return

    this.maxDate = moment(event.detail.value)/* .add(1, 'd') */.format();

    this.apiOrder.updateDateExpected(this.date, this.order.id).subscribe(x => {
      return x
    })
    //this.date_exp.emit(moment(this.date).format('YYYY-MM-DD'))
  }



  async order_orders_create(values: any) {
    console.log(values);

    // copy all the form values into the new vendor
    const newOrder: any = this.order_orders_createForm.value;
    //newOrder.created = moment(newOrder.created).format('YYYY-MM-DD')


    console.log(newOrder);

    Object.keys(newOrder).forEach(key => newOrder[key] == null ? delete newOrder[key] : '');
    this.apiOrder.order_orderdates_create(newOrder).subscribe(e => {
      console.log(e);

      if (e && e.data) {
        this.presentToast(e.message, 'success')

        this.modalCtrl.dismiss(e.data)

      } else {
        console.log('no modify');

      }
      //this.dismissModal(e.message);
    })
  }



  createBaseForm() {

    this.order_orders_createForm = this.fb.group({
      order_id: this.fb.control(this.order.id, Validators.required),
      type_id: this.fb.control('', [Validators.required, Validators.minLength(1)]),
      date_start: this.fb.control(moment(new Date()).format('YYYY-MM-DD'), Validators.required),
      date_end: this.fb.control(moment(new Date()).format('YYYY-MM-DD'),Validators.required),
    });


    this.order_orders_createForm.valueChanges.subscribe(e => {
      console.log(e);

    })




  }

  onChangeNewDate(event, action) {
    console.log(event, action);

    this.popCtrl.getTop().then(v => v ? this.popCtrl.dismiss() : null);

    /*  if (!this.prevent)
       return */

    this.maxDate = moment(event.detail.value)/* .add(1, 'd') */.format();



    if (action == 'start')
      this.order_orders_createForm.controls['date_start'].setValue(moment(event.detail.value).format('YYYY-MM-DD'))

    if (action == 'end') {
      this.order_orders_createForm.controls['date_end'].setValue(moment(event.detail.value).format('YYYY-MM-DD'))
    }
    /* if (action === 'start') {
      this.order_orders_createForm.controls['date_start'].setValue(moment(event.detail.value).format('YYYY-MM-DD'))
    } else {
      this.order_orders_createForm.controls['date_end'].setValue(moment(event.detail.value).format('YYYY-MM-DD'))
    } */



    //this.date_exp.emit(moment(this.date).format('YYYY-MM-DD'))
  }



  formatDate(value: string) {
    return moment(value).format('DD-MM-YYYY');
  }


  async presentToast(text, color) {
    const toast = await this.toastController.create({
      message: text,
      position: 'bottom',
      duration: 3000,
      cssClass: 'toast',
      color: color
    });
    return await toast.present();
  }


  changeModalSize() {
    // Seleziona tutti gli ion-modal
    const modals = document.querySelectorAll('ion-modal');
    
    // Controlla se ci sono modali aperti
    if (modals.length > 0) {
      // Prendi l'ultimo modale aperto che dovrebbe essere quello attivo
      const modalElement = modals[modals.length - 1];
  
      // Controlla se la classe 'small-icon' è già applicata
      if (modalElement.classList.contains('small-icon')) {
        // Se 'small-icon' è applicata, rimuovila per tornare alla dimensione originale
        this.renderer.removeClass(modalElement, 'small-icon');
        // Aggiungi qui la classe per la dimensione originale se la conosci
        this.iconName = 'chevron-up-circle-outline'
        this.showConfirm = true
        this.chgToolbar = ''
      } else {
        // Se 'small-icon' non è applicata, aggiungila per ridurre le dimensioni del modale
        this.renderer.addClass(modalElement, 'small-icon');
        this.iconName = 'chevron-down-circle-outline'
        this.showConfirm = false
        this.chgToolbar = 'toolbar-background'
      }
    }
  }
}
