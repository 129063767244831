// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content {
  display: block !important;
  width: 100% !important;
  height: 100% !important;
}`, "",{"version":3,"sources":["webpack://./src/app/standard/standard.component.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,sBAAA;EACA,uBAAA;AACJ","sourcesContent":["ion-content {\n    display: block !important;\n    width: 100%  !important;\n    height: 100%  !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
