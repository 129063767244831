import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { MenuController, NavController, ToastController } from '@ionic/angular';

@Component({
  selector: 'app-register',
  templateUrl: './register.page.html',
  styleUrls: ['./register.page.scss'],
})
export class RegisterPage {
  isLoading: any = true;
  auth: any = false;

  validationsForm: FormGroup;
  emailForm: FormGroup;
  passForm: FormGroup;
  error;
  validationMessages = {
    email: [
      { type: 'required', message: 'Email is required.' },
      { type: 'pattern', message: 'Please enter a valid email.' },
    ],
    password: [
      { type: 'required', message: 'Password is required.' },
      { type: 'minlength', message: 'At least 6 characters long.' },
    ],
    firstName: [
      { type: 'required', message: 'First Name is required.' },
      { type: 'minlength', message: 'At least 4 characters long.' },
    ],
    lastName: [
      { type: 'required', message: 'Last Name is required.' },
      { type: 'minlength', message: 'At least 4 characters long.' },
    ],
  };

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private router: Router,
    private menu: MenuController,
    private toastController: ToastController,
    private navCtrl: NavController
  ) {
    this.emailForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
    });
    this.validationsForm = this.formBuilder.group({
      email: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
        ])
      ),
      password: new FormControl(
        '',
        Validators.compose([Validators.minLength(6), Validators.required])
      ),
      firstName: new FormControl(
        '',
        Validators.compose([Validators.minLength(4), Validators.required])
      ),
      lastName: new FormControl(
        '',
        Validators.compose([Validators.minLength(4), Validators.required])
      ),
    });

  }


  ionViewWillEnter() {
    this.menu.enable(false);
  }
  ionViewWillLeave() {
    this.menu.enable(true);
  }

  ionViewDidEnter() {
    this.auth = this.authService.isAuthenticated();

    if (this.auth) {
      setTimeout(() => {
        this.router.navigate(['']);
      }, 100);
    }
  }


  onSubmit() {
    console.log('/register', this.validationsForm.invalid);
    // stop here if form is invalid
    if (this.validationsForm.invalid) {
      console.log('Campi errati');
      //this.error = 'Campi vuoti o errati';

      return;
    } else {
      //console.log(this.emailForm);

      this.register(this.validationsForm.controls['email'].value, this.validationsForm.controls['password'].value, this.validationsForm.controls['firstName'].value, this.validationsForm.controls['lastName'].value);
    }
  }


   register(email, pass, firstName, lastName) {
    this.authService.doRegister(email, pass, firstName, lastName).subscribe(e => {
      console.log('res reg', e);

      this.presentToast()
      setTimeout(() => {
        this.navCtrl.back()
      }, 4100);
    })
  }

  async presentToast(){
    const toast = await this.toastController.create({
      message: 'Thanks, sign corfirm! Your registration is pending verification. ',
      position: 'bottom',
      color: 'primary',
      duration: 4000

    })
    await toast.present();

    const { role } = await toast.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
  }


}
