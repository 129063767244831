// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content .add-photo-wrapper {
  justify-content: center;
}
ion-content .user-image {
  --size: 50px;
  margin: auto;
}

ion-title {
  padding-inline-start: 0;
  padding-inline-end: 0;
  text-align: center;
}

.size-14 {
  font-size: 1.4em;
  /*margin-right: 20px;*/
}

.custom-label {
  /*  display: contents;
   font-size: 13px;
   padding-top: 11px; */
}

.text-label ion-icon {
  font-size: 28px;
}

.p_d_t {
  border-radius: 13px;
}

ion-item {
  /*border-radius: 13px; */
  --background: transparent;
}

.dot-class {
  position: relative;
  top: 30px;
  width: 40px;
}`, "",{"version":3,"sources":["webpack://./src/app/projects/new-project/new-project.component.scss"],"names":[],"mappings":"AAGE;EACE,uBAAA;AAFJ;AAKE;EACE,YAAA;EACA,YAAA;AAHJ;;AAOA;EACE,uBAAA;EACA,qBAAA;EACA,kBAAA;AAJF;;AAOA;EACE,gBAAA;EACD,sBAAA;AAJD;;AAOA;EACC;;uBAAA;AAFD;;AAOA;EACE,eAAA;AAJF;;AAOA;EAEE,mBAAA;AALF;;AAOA;EAEE,wBAAA;EACE,yBAAA;AALJ;;AAQA;EACE,kBAAA;EACA,SAAA;EACA,WAAA;AALF","sourcesContent":["ion-content {\n // --padding-top: 16px;\n\n  .add-photo-wrapper {\n    justify-content: center;\n  }\n\n  .user-image {\n    --size: 50px;\n    margin: auto;\n  }\n}\n\nion-title {\n  padding-inline-start: 0;\n  padding-inline-end: 0;\n  text-align: center;\n\n}\n.size-14{\n  font-size: 1.4em;\n /*margin-right: 20px;*/\n}\n\n.custom-label{\n /*  display: contents;\n  font-size: 13px;\n  padding-top: 11px; */\n}\n\n.text-label ion-icon{\n  font-size: 28px;\n}\n\n.p_d_t{\n  //margin-top: 10px;\n  border-radius: 13px;\n}\nion-item{\n  //margin-top: 10px;\n  /*border-radius: 13px; */\n    --background: transparent;\n}\n\n.dot-class{\n  position: relative;\n  top: 30px;\n  width: 40px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
