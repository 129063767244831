import { Component, ViewChild } from '@angular/core';
import { ModalController, Platform, ToastController } from '@ionic/angular';
import { FormGroup, FormControl, Validators, FormGroupDirective } from '@angular/forms';
import { DataService } from '../../services/data/data.service';
import { User } from '../models/users';
import * as moment from 'moment';
import { IonDatetime } from '@ionic/angular';
import { format, parseISO } from 'date-fns';
import { ProjectsService } from 'src/app/services/projects/projects.service';




@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.scss'],
})
export class NewUserComponent {

  minDate = moment().format();
  maxDate = moment().add(1, 'd').format();

  createProjectForm: FormGroup;
  types_category: any = [];
  subscription

  @ViewChild('createForm') createForm: FormGroupDirective;
  @ViewChild(IonDatetime, { static: true }) datetime: IonDatetime;
  @ViewChild(IonDatetime, { static: true }) datetime2: IonDatetime;

  constructor(
    private platform: Platform,
    private modalController: ModalController,
    private toastController: ToastController,
    private dataService: DataService,
    private api: ProjectsService
  ) { }

  dismissModal(status = '') {
    this.modalController.dismiss({ data: { message: status } });
  }

  ionViewDidEnter() {
    this.subscription = this.platform.backButton.subscribe((e) => {
      console.log(e);
      this.dismissModal()
    });
  }

  ionViewWillLeave() {
    this.subscription.unsubscribe();
  }

  ionViewWillEnter(): void {

    this.api.getTypesList().subscribe(e => {
      this.types_category = e
    })

    this.createProjectForm = new FormGroup({
      'name': new FormControl('', Validators.required),
      'code': new FormControl('', Validators.required),
      //'email': new FormControl(''),
      'date_pre_start': new FormControl(this.minDate, Validators.required),
      'date_pre_end': new FormControl(this.maxDate, Validators.required),
      'cost_pre': new FormControl('', Validators.required),
      'type_id': new FormControl('', Validators.required),
      //'category': new FormControl('', Validators.required)
    });
  }


  submitForm() {
    this.createForm.onSubmit(undefined);
  }

  async presentToast(text, title, color) {
    const toast = await this.toastController.create({
      message: title + ': ' + (text.message),
      position: 'bottom',
      duration: 2000,
      cssClass: 'toast',
      color: color
    });
    toast.present();
  }

  createProject(values: any) {
    // copy all the form values into the new user
    const newProject: User = { ...values };
    //this.dataService.createProject(newProject);
    //newProject.start_date = moment(newProject.start_date).format().split('+')[0];
    //newProject.end_date = moment(newProject.end_date).format().split('+')[0];

    console.log(newProject);

    this.api.createProject(newProject).subscribe(e => {
      console.log(e);
      if (e[0].status != 'ko')
        this.dismissModal('OK')
      else
        this.presentToast(e[0], 'Error', 'danger')
    })
  }

  onChangeStartDate(event) {
    this.maxDate = moment(event.detail.value).add(1, 'd').format();
    this.createProjectForm.controls['end_date'].setValue(this.maxDate)
  }

  formatDate(value: string) {
    return format(parseISO(value), 'MMM dd yyyy');
  }
}
