import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'linesDescription'
})
export class LinesDescriptionPipe implements PipeTransform {
  
  transform(lines: any[]): string {
    console.log(lines)
    return lines && lines.length > 0 && lines[0].description ? lines[0].description : '';
  }

}
