import { AbstractControl, FormArray, FormBuilder, ValidationErrors } from '@angular/forms';

import { Component, OnInit, ViewChild, Input } from '@angular/core';
import {
  IonNav,
  ModalController,
  Platform,
  ToastController,
} from '@ionic/angular';
import {
  FormGroup,
  FormControl,
  Validators,
  FormGroupDirective,
} from '@angular/forms';
import { DataService } from '../../services/data/data.service';
import { Project } from '../models/projects';
import * as moment from 'moment';
import { ApiService } from 'src/app/services/api/api.service';
import { IonDatetime } from '@ionic/angular';
import { format, parseISO } from 'date-fns';
import { ProjectsService } from 'src/app/services/projects/projects.service';
import { CustomersService } from 'src/app/services/customers/customers.service';
import { VendorsService } from 'src/app/services/vendors/vendors.service';
import { IonicSelectableComponent } from 'ionic-selectable';
import { VendorUpdatePage } from 'src/app/vendors/vendor-update/vendor-update.page';
import { NewVendorComponent } from 'src/app/vendors/new-vendor/new-vendor.component';
import { ProjectDetailsPage } from '../project-details/project-details.page';
import { HrService } from 'src/app/services/hr/hr.service';

@Component({
  selector: 'app-new-project',
  templateUrl: './new-project.component.html',
  styleUrls: ['./new-project.component.scss'],
})
export class NewProjectComponent {
  _now = moment().format();
  minDate = moment('01/01/1990').format();
  maxDate = moment().add(1, 'd').format();

  createProjectForm: FormGroup;
  createCodeJobForm: FormGroup;
  types_category: any = [];
  subscription;
  isSubmitted = false;
  customers = [];
  committers = [];
  stakeholders = [];
  selectedValueCommitter;
  selectedValueCustomer;
  portsSubscription;
  newApplicant: FormGroup;


  @ViewChild('createForm') createForm: FormGroupDirective;

  @ViewChild(IonDatetime, { static: true }) datetime: IonDatetime;
  @ViewChild(IonDatetime, { static: true }) datetime2: IonDatetime;
  @ViewChild('committerModel') committerModel: IonicSelectableComponent;
  @ViewChild('customerModel') customerModel: IonicSelectableComponent;

  @Input() nav: IonNav;
  @Input() nameProject: string = '';
  @Input() redirectAfterPost: boolean = true;

  /* Set param component add-item-select-option */
  createFormCommitter: FormGroup;
  items_committer = [
    { label: 'Name', formControlName: 'name', inputMode: 'text' },
    { label: 'Code', formControlName: 'code', inputMode: 'text' },
    { label: 'Address', formControlName: 'address', inputMode: 'text' },
    { label: 'Street Number', formControlName: 'number', inputMode: 'text' },
    { label: 'Phone', formControlName: 'phone' },
    { label: 'Email', formControlName: 'email' },
    { label: 'PIVA', formControlName: 'code_vat' },
  ];
  name_comm = 'Committer';
  submitButtonParam = {
    label: 'Add Committer',
    function: 'submitFormCommitterCustomer()',
  };
  /* End set */
  /** Set param */
  createFormCustomer: FormGroup;
  items_customer = [
    { label: 'Name', formControlName: 'name', inputMode: 'text' },
    { label: 'Code', formControlName: 'code', inputMode: 'text' },
    { label: 'Address', formControlName: 'address', inputMode: 'text' },
    { label: 'Street Number', formControlName: 'number', inputMode: 'text' },
    { label: 'Phone', formControlName: 'phone' },
    { label: 'Email', formControlName: 'email' },
    { label: 'PIVA', formControlName: 'code_vat' },
  ];
  name_customer = 'Customer';
  submitButtonParamCustomer = {
    label: 'Add Customer',
    function: 'submitFormCommitterCustomer()',
  };
  /** End set */

  totalItems = 0;
  pageNumber = 1;
  numPages;

  size = 20;
  project_in
  page_project = 1;
  page_customer = 1;
  page_committer = 1;
  page_stakeholder = 1;

  numPage_project = 0;
  numPage_customer = 0;
  numPage_committer = 0;
  numPage_stakeholder = 0;

  onSelectProject;
  onSelectCustomer;
  onSelectCommitter;
  onSelectStakeholder;

  onPushProject;
  onPushCustomer;
  onPushCommitter;
  onPushStakeholder;

  onSearchSelector = '';

  response;

  locationtypes: any = [];
  selectedLocation: any = [];
  filteredCountries: any = [];
  noCountries = false;
  filteredLocations: any = [];
  selectedCountry: any = [];
  selectedStreet: any = [];

  

  constructor(
    private platform: Platform,
    private modalController: ModalController,
    private toastController: ToastController,
    private dataService: DataService,
    private customer: CustomersService,
    private api: ProjectsService,
    private apiVendor: VendorsService,
    private apiCustomer: CustomersService,
    private fb: FormBuilder,
    private apiHr: HrService
  ) {
    this.getMetaProject();
    this.apiHr.getLocationTypes().subscribe((e) => {
      console.log('locationTypes', e);
      this.locationtypes = e.results;
    });
  }

  getMetaProject(event?) {
    console.log('getMetaProject');
    let url = '?page=' + this.pageNumber + '&items=' + this.size;

    this.api.getCompaniesList(url).subscribe((e) => {
      console.log(e);

      this.numPages = e.num_pages;
      this.totalItems = e.totalItems;
      this.customers = e.results;
    });
  }

  dismissModal() {
    this.modalController.dismiss(this.response);
  }

  ionViewDidEnter() {
    this.subscription = this.platform.backButton.subscribe((e) => {
      console.log(e);
      this.dismissModal();
    });
  }

  ionViewWillLeave() {
    this.subscription.unsubscribe();
  }

  ionViewWillEnter(): void {
    console.log(this.project_in);

    this.isSubmitted = false;
    this.api.getTypesList().subscribe((e: any) => {
      console.log(this.types_category);

      this.types_category = e.results;
    });

    this.createProjectForm = new FormGroup({
      name: new FormControl(this.nameProject, [
        Validators.minLength(5),
        Validators.maxLength(31),
        Validators.required,
      ]),
      code: new FormControl('', Validators.required),
      //'email': new FormControl(''),
      committer_id: new FormControl(''),
      customer_id: new FormControl(''),
      //'budget': new FormControl(''),
      type_id: new FormControl(''),
      //'category': new FormControl('', Validators.required)
    });
    this.createCodeJobForm = new FormGroup({
      nation: new FormControl('UNK', Validators.required),
      cap: new FormControl('UNK', Validators.required),
      committer: new FormControl(this.project_in ? this.project_in[0].committer.code : 'UNK'),
      type: new FormControl('UNK'),
      name: new FormControl('UNK',[Validators.required, this.customNameValidator]),
    });
    this.newApplicant = this.fb.group({
      addresses: this.fb.array([this.createAddress()]),
    });

    if(this.project_in){
      this.createProjectForm.patchValue({committer_id: this.project_in[0].committer.id}) 
    }
    // Form Committer

    this.createFormCommitter = this.fb.group({
      name: this.fb.control('', Validators.required),
      code_vat: this.fb.control('', Validators.required),
      code: this.fb.control('', Validators.required),
      address: this.fb.control('', Validators.required),
      phone: this.fb.control('', Validators.required),
      email: this.fb.control('', Validators.required),
      number: this.fb.control('', Validators.required),
    });

    // Form Customer
    this.createFormCustomer = this.fb.group({
      name: this.fb.control('', Validators.required),
      code_vat: this.fb.control('', Validators.required),
      code: this.fb.control('', Validators.required),
      address: this.fb.control('', Validators.required),
      phone: this.fb.control('', Validators.required),
      email: this.fb.control('', Validators.required),
      number: this.fb.control('', Validators.required),
    });

    console.log(this.errorControl['name'].errors);
  }

  get errorControl() {
    return this.createProjectForm.controls;
  }

  customNameValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    const maxLength = 10;
    const hashRegex = /^[a-zA-Z0-9]*$/; // Solo caratteri (lettere, numeri)
  
    if (value.length > maxLength) {
      return { maxLength: true };
    }
  
    if (!hashRegex.test(value)) {
      return { invalidCharacters: true };
    }
  
    return null;
  }

  submitForm() {
    this.createForm.onSubmit(undefined);
    this.isSubmitted = true;
  }

  async presentToast(text, title, color) {
    const toast = await this.toastController.create({
      message: title + ': ' + text.message,
      position: 'bottom',
      duration: 2000,
      cssClass: 'toast',
      color: color,
    });
    toast.present();
  }

  createProject(values: any) {
    if (!this.createProjectForm.valid) return;

    // copy all the form values into the new project
    const newProject: Project = { ...values };
    //this.dataService.createProject(newProject);
    //newProject.start_date = moment(newProject.start_date).format().split('+')[0];
    //newProject.end_date = moment(newProject.end_date).format().split('+')[0];

    newProject.start_date = moment(newProject.start_date).format('YYYY-MM-DD');
    newProject.end_date = moment(newProject.end_date).format('YYYY-MM-DD');

    console.log(newProject);

    this.api.createProject(newProject).subscribe((e) => {
      console.log(e);
      if (e.message.includes('OK')) {
        this.response = e.data;
        this.dismissModal();
        //this.presentToast({message:'Insert project successufly! Opening project details.'}, 'Ok', 'dark')

        this.redirectAfterPost ? this.goTo({ id: e.data.id }) : null;
      } else {
        this.presentToast(e[0], 'Error', 'danger');
      }
    });
  }

  onChangeStartDate(event) {
    this.maxDate = moment(event.detail.value).add(1, 'd').format();
    this.createProjectForm.controls['end_date'].setValue(this.maxDate);
  }

  formatDate(value: string) {
    return format(parseISO(value), 'MMM dd yyyy');
  }

  /**
   * Add new Committer
   */

  submitFormCommitterCustomer(event, type) {
    console.log('Submit Committer', event);
    this.createNewCommitterCustomer(event, event.form, type);
  }

  createNewCommitterCustomer(event, values: any, type) {
    const newCommitter: any = { ...values };
    newCommitter['code_fiscal'] = newCommitter['code_vat'];

    // Handling Address for API
    let adr = [];
    let position = {};
    position['street'] = newCommitter.address;
    position['number'] = newCommitter.number;
    adr.push({
      name: 'work',
      position: position,
    });

    newCommitter['address'] = adr;

    // Handling Phones for API
    let contacts = [];
    let contact = {};
    contact['name'] = 'phone';
    contact['data'] = newCommitter.phone;
    contacts.push(contact);
    delete newCommitter.phone;

    // Handling Email for API
    contact = {};
    contact['name'] = 'email';
    contact['data'] = newCommitter.email;
    contacts.push(contact);
    delete newCommitter.email;

    newCommitter['contact'] = contacts;

    console.log('createNewCommitterCustomer', type);

    this.apiCustomer.createCustomer(newCommitter).subscribe((e) => {
      this.customers.push(e.data);

      if (type == 'committer') {
        this.selectedValueCommitter = '';
        this.createProjectForm.controls['committer_id'].setValue(e.data.id);

        this.committerModel.search(event.form.name);

        // Clean form.
        this.createFormCommitter.reset();
        this.committerModel.hideAddItemTemplate();
      } else {
        this.selectedValueCustomer = '';
        this.createProjectForm.controls['customer_id'].setValue(e.data.id);

        this.customerModel.search(event.form.name);

        // Clean form.
        this.createFormCustomer.reset();
        this.customerModel.hideAddItemTemplate();
      }
    });
  }

  goTo(event) {
    //nav: this.nav(ProjectDetailsPage, { id: event.id }, { animated: true })
  }

  onAddPort(event: { component: IonicSelectableComponent }) {
    // Clean form.
    this.createFormCommitter.reset();

    console.log('onAddPort', event);
    // Copy search text to port name field, so
    // user doesn't have to type again.
    //this.createFormCommitter.controls['name'].setValue(event.component.searchText);

    // Show form.
    //event.component.showAddItemTemplate();
    this.openNewVendorModal(event.component.searchText);
  }

  onSavePort(event: {
    component: IonicSelectableComponent;
    item; //: Port
  }) {
    console.log('onSavePort', event);

    // Fill form.
    //this.createFormCommitter.setValue(event.item);
    //this.portCountryControl.setValue(event.item.country);

    // Show form.
    //event.component.showAddItemTemplate();

    this.openEditVendorModal(event.item);
  }

  onDeletePort(event: {
    component: IonicSelectableComponent;
    item; //: Port
  }) {
    // Delete port from storage.
    //this.portService.deletePort(event.item);

    // Delete port from list.
    event.component.deleteItem(event.item);
  }

  Cancel() {
    console.log(this.committerModel);
    this.committerModel.hideAddItemTemplate();
  }

  getMorePorts(
    event: {
      component: IonicSelectableComponent;
      text: string;
    },
    type
  ) {
    let url;

    event.component._modalComponent._content.scrollToBottom(300); // <---- Fixed position
    const page = Math.round(
      event.component.items ? event.component.items.length / this.size : 1
    );

    const text = (event.text || '').trim().toLowerCase();
    console.log('pageNumber', this.pageNumber, this.numPages);

    // There're no more ports - disable infinite scroll.
    if (this.pageNumber > this.numPages) {
      event.component.disableInfiniteScroll();
      return;
    }

    if (!text) {
      url = '?page=' + ++this.pageNumber + '&items=' + this.size;

      this.api.getCompaniesList(url).subscribe((data) => {
        console.log('get getCustomersList', data);

        data.results = event.component.items.concat(data.results);
        //event.component.items.concat(data.results);

        this.numPages = data.num_pages;

        event.component.items = data.results;
        event.component.endInfiniteScroll();

        console.log(event.component.items);
      });
    }

    // There're no more ports - disable infinite scroll.
  }

  filterPorts(ports: any[], text: string) {
    return ports.filter((port) => {
      return (
        port.name.toLowerCase().indexOf(text) !== -1 ||
        port.code.toLowerCase().indexOf(text) !== -1
      );
    });
  }

  async openEditVendorModal(item) {
    const modal = await this.modalController.create({
      component: VendorUpdatePage,
      canDismiss: true,
      mode: 'md',
      cssClass: 'newOrderModalForm',
      componentProps: {
        id: item.id,
      },
      //presentingElement: this.routerOutlet.nativeEl,
    });

    modal.onWillDismiss().then((e) => {
      console.log(e);

      if (e.data && e.data.status != -1) {
        /* this.page_number = 1;
        this.myInput.value = "";
        this.vendors = [];
        this.getVendors(false, '') */
        console.log(this.committerModel.items);

        let index = this.committerModel.items
          .map((i) => i.id)
          .indexOf(e.data.id);
        console.log(index, e.data.id);

        this.committerModel.items[index] = e.data;
        this.committerModel.search(e.data.name);
      }
    });

    return await modal.present();
  }

  async openNewVendorModal(name?) {
    const modal = await this.modalController.create({
      component: NewVendorComponent,
      canDismiss: true,
      cssClass: 'newOrderModalForm',
      mode: 'md',
      componentProps: {
        title: 'New Vendor',
        name: name,
        parent_type: 'root',
      },
      //presentingElement: this.routerOutlet.nativeEl,
    });

    modal.onWillDismiss().then((e) => {
      if (e.data && e.data.status != -1) {
        /* this.total_page = 0;
        this.page_number = 1;
        this.myInput.value = "";
        this.vendors = [];
        this.getVendors(false, '') */
        console.log(this.committerModel.items, e.data);
        if (e.data)
          // Add port to the top of list.
          this.committerModel.addItem(e.data).then(() => {
            this.committerModel.search(e.data.name);
          });

        console.log(this.committerModel.items, e.data);
      }
    });
    return await modal.present();
  }

  searchPorts(
    event /*:  { component: IonicSelectableComponent; text: string }, type */
  ) {
    console.log(event, event.text);
    //console.log(event.component._modalComponent);
    //console.log(event.component._modalComponent._content);

    //event.component._modalComponent._content.scrollToBottom(1500);
    //event.component._modalComponent._content.scrollY = false

    let url = '';

    const text = event.text.trim().toLowerCase();
    //event.component.startSearch();

    // Close any running subscription.
    if (this.portsSubscription) {
      this.portsSubscription.unsubscribe();
    }

    if (!text) {
      // Close any running subscription.
      if (this.portsSubscription) {
        this.portsSubscription.unsubscribe();
      }

      //event.component.endSearch();
      //event.component.enableInfiniteScroll();
      switch (event['module']) {
        case 'customer':
          this.page_customer = 1;

          url = '?page=' + this.page_customer + '&items=' + this.size;

          this.api.getCompaniesList(url).subscribe((data) => {
            console.log('!text call more getCustomersList', data);

            //data.results = event.component.items = (data.results);

            //this.numPage_project = data.num_pages;

            //event.component.items = data.results;
            this.onSearchSelector = '';
            this.numPage_customer = data.num_pages;
            this.customers = data.results;
            //event.component.endInfiniteScroll();
          });
          break;

        case 'committer':
          this.page_committer = 1;

          url = '?page=' + this.page_committer + '&items=' + this.size;

          this.api.getCompaniesList(url).subscribe((data) => {
            console.log('!text call more getCommitterList', data);

            //data.results = event.component.items = (data.results);

            //this.numPage_project = data.num_pages;

            //event.component.items = data.results;
            this.onSearchSelector = '';
            this.numPage_committer = data.num_pages;
            this.committers = data.results;
            //event.component.endInfiniteScroll();
          });
          break;

        case 'stakeholder':
          this.page_stakeholder = 1;

          url = '?page=' + this.page_stakeholder + '&items=' + this.size;

          this.api.getCompaniesList(url).subscribe((data) => {
            console.log('!text call more getStakeholder', data);

            //data.results = event.component.items = (data.results);

            //this.numPage_project = data.num_pages;

            //event.component.items = data.results;
            this.onSearchSelector = '';
            this.numPage_stakeholder = data.num_pages;
            this.stakeholders = data.results;
            //event.component.endInfiniteScroll();
          });
          break;

        default:
          console.log('Default');

          break;
      }

      return;
    }

    switch (event['module']) {
      case 'customer':
        url = '?page=' + this.page_customer + '&items=25&name=' + text;
        this.portsSubscription = this.api
          .getCompaniesList(url)
          .subscribe((data) => {
            console.log('text call from search getCustomersList', data);
            // Subscription will be closed when unsubscribed manually.
            if (this.portsSubscription.closed) {
              return;
            }
            this.onSearchSelector = text;
            this.numPage_customer = data.num_pages;
            this.customers = data.results;
          });

        break;
      case 'committer':
        url = '?page=' + this.page_committer + '&items=25&name=' + text;
        this.portsSubscription = this.api
          .getCompaniesList(url)
          .subscribe((data) => {
            console.log('text call from search get', data);
            // Subscription will be closed when unsubscribed manually.
            if (this.portsSubscription.closed) {
              return;
            }
            this.onSearchSelector = text;
            this.numPage_committer = data.num_pages;
            this.committers = data.results;
          });

        break;
      case 'stakeholder':
        url = '?page=' + this.page_stakeholder + '&items=25&name=' + text;
        this.portsSubscription = this.api
          .getCompaniesList(url)
          .subscribe((data) => {
            console.log('text call from search getStakeholderlist', data);
            // Subscription will be closed when unsubscribed manually.
            if (this.portsSubscription.closed) {
              return;
            }
            this.onSearchSelector = text;
            this.numPage_stakeholder = data.num_pages;
            this.stakeholders = data.results;
          });

        break;

      default:
        break;
    }
  }

  // Metodo para usar con Infinity Scroll en ionic-selectable
  onInfiniteScroll(
    /* 
    event: { component: IonicSelectableComponent; text: string },
    type: string */ event
  ) {
    let url;
    console.log(event);
    console.log(this.page_project, this.numPage_project);

    //console.log(this.title);

    let _type = event['type'] ? event['type'] : null;
    console.log('after', _type);

    switch (_type) {
      case 'customer':
        url = '?page=' + ++this.page_customer + '&items=' + this.size;

        this.api.getCompaniesList(url).subscribe((data) => {
          console.log('!text call more getCustomersList', data);

          //data.results = event.component.items = (data.results);

          //this.numPage_project = data.num_pages;

          //event.component.items = data.results;
          this.onSearchSelector = '';
          this.numPage_customer = data.num_pages;
          this.customers = data.results;
          //event.component.endInfiniteScroll();
        });
        break;

      case 'committer':
        url = '?page=' + ++this.page_committer + '&items=' + this.size;

        this.api.getCompaniesList(url).subscribe((data) => {
          console.log('!text call more getCommitterList', data);

          //data.results = event.component.items = (data.results);

          //this.numPage_project = data.num_pages;

          //event.component.items = data.results;
          this.onSearchSelector = '';
          this.numPage_committer = data.num_pages;
          this.committers = data.results;
          //event.component.endInfiniteScroll();
        });
        break;

      case 'stakeholder':
        url = '?page=' + ++this.page_stakeholder + '&items=' + this.size;

        this.api.getCompaniesList(url).subscribe((data) => {
          console.log('!text call more getStakeholder', data);

          //data.results = event.component.items = (data.results);

          //this.numPage_project = data.num_pages;

          //event.component.items = data.results;
          this.onSearchSelector = '';
          this.numPage_stakeholder = data.num_pages;
          this.stakeholders = data.results;
          //event.component.endInfiniteScroll();
        });
        break;

      default:
        console.log('Default');

        break;

      //.... more code case
    }
  }

  async openUpdateCustomerModal(data?) {
    console.log(this.customer, data);

    const modal = await this.modalController.create({
      component: VendorUpdatePage,
      canDismiss: true,
      mode: 'md',
      //cssClass: 'fullscreen',
      componentProps: {
        id: data.item.id,
        ////nav: this.nav  // Pass nav for IonNav from modal if necessary
      },
    });

    modal.onWillDismiss().then((e) => {
      console.log(e);

      if (!e.data) {
        console.log('no modify');
        return;
      } else {
        this.page_customer = 1;
        this.page_committer = 1;
        this.onSelectCustomer = e.data;
        this.onSelectCommitter = e.data;
        //this.onSelectVendor = e.data
      }
    });
    return await modal.present();
  }

  async openNewCustomerModal() {
    const modal = await this.modalController.create({
      component: NewVendorComponent,
      //cssClass: 'newOrderModalForm',
      canDismiss: true,
      componentProps: {
        //nav: this.nav,
        title: 'Add Company',
        redirectAfterPost: false,
      },
    });

    modal.onWillDismiss().then(async (e) => {
      console.log(e);

      if (!e.data) {
        console.log('no modify');
        return;
      } else {
        //this.loadProjects(this.currentSegment);
        //this.projects = await this.project.getProjectsList_rev('?page=' + this.page_project + '&items=' + this.size).toPromise().then(e => e.results)

        this.page_customer = 1;
        this.page_committer = 1;
        //this.page_vendor = 1
        //this.onSelectProject = [e.data]
        //this.onPushVendor = e.data
        this.onPushCustomer = e.data;
        this.onPushCommitter = e.data;
      }
    });

    return await modal.present();
  }

  onSelect(event) {
    console.log(event);
    if (event.isSelected) {
      if (event.module == 'customer') {
        this.onSelectCustomer = event.item;
      } else if (event.module == 'committer') {
        this.onSelectCommitter = event.item;
        this.createCodeJobForm.patchValue({committer: event.item.code})
      } else if (event.module == 'stakeholder') {
        this.onSelectStakeholder = event.item;
      }
    } else {
      if (event.module == 'customer') {
        this.onSelectCustomer = '';
      } else if (event.module == 'committer') {
        this.onSelectCommitter = '';
      } else if (event.module == 'stakeholder') {
        this.onSelectStakeholder = '';
      }
    }
  }

  setProjectName(event: any) {
    this.createCodeJobForm.patchValue({ name: event.detail.value.toUpperCase() });
  }
  
  searchCountriesList(event: any) {
    const query = event.target.value;
    if (query.length > 2) {
      this.apiHr.getCountriesList(event.detail.value).subscribe((e) => {
        console.log(e);
        this.filteredCountries = e.results;
        if (e.results.length === 0) {
          this.noCountries = true;
        } else {
          this.noCountries = false;
        }
      });
    } else {
      this.filteredCountries = [];
    }
  }

  getLocation(event: any) {
    const query = event.target.value;
    if (query.length > 2) {
      this.apiHr
        .getLocations(
          this.selectedCountry.id,
          this.selectedLocation.id,
          event.detail.value
        )
        .subscribe((e) => {
          console.log(e);
          this.filteredLocations = e.results;
          if (e.results.length === 0) {
            this.noCountries = true;
          } else {
            this.noCountries = false;
          }
        });
    } else {
      this.filteredLocations = [];
    }
  }

  getLocationClearNumber(event: any) {
    const query = event.target.value;
    console.log(this.selectedStreet);

    if (query.length > 0) {
      this.apiHr
        .getLocationsClear(
          this.selectedCountry.id,
          this.selectedLocation.id,
          this.selectedStreet.street,
          event.detail.value,
          this.selectedStreet.cap
        )
        .subscribe((e) => {
          console.log(e);
          this.filteredLocations = e.results;
          if (e.results.length === 0) {
            this.noCountries = true;
            const addresses = this.newApplicant.get('addresses') as FormArray;
            const address = addresses.at(0) as FormGroup;

            if (address) {
              address.patchValue({
                number: event.detail.value,
              });
            }

            console.log(this.newApplicant.value);
          } else {
            this.noCountries = false;
          }
        });
    } else {
      this.filteredLocations = [];
    }
  }

  createAddress(): FormGroup {
    return this.fb.group({
      country_id: ['', Validators.required],
      location_id: [0, Validators.required],
      type_id: ['', Validators.required],
      street: ['', Validators.required],
      number: ['', Validators.required],
      cap: ['', Validators.required],
    });
  }

  get addresses() {
    return this.newApplicant.get('addresses') as FormArray;
  }

  getLocationClearCAP(event: any) {
    const query = event.target.value;
    console.log(this.selectedStreet);
    if (query.length > 0) {
      this.apiHr
        .getLocationsClear(
          this.selectedCountry.id,
          this.selectedLocation.id,
          this.selectedStreet.street,
          this.selectedStreet.number,
          event.detail.value
        )
        .subscribe((e) => {
          console.log(e);
          this.filteredLocations = e.results;
          if (e.results.length === 0) {
            this.noCountries = true;
            const addresses = this.newApplicant.get('addresses') as FormArray;
            const address = addresses.at(0) as FormGroup;

            if (address) {
              address.patchValue({
                cap: event.detail.value,
              });
            }
            this.createCodeJobForm.patchValue({cap: event.detail.value})
            console.log(this.newApplicant.value);
          } else {
            this.noCountries = false;
          }
        });
    } else {
      this.filteredLocations = [];
    }
  }

  selectCountry(country: any, addressIndex: any) {
    this.selectedCountry = country;

    setTimeout(() => {
      const addresses = this.newApplicant.get('addresses') as FormArray;
      const address = addresses.at(addressIndex) as FormGroup;

      if (address) {
        address.patchValue({
          country_id: country.id,
        });
      }

      console.log(this.selectedCountry);
      this.createCodeJobForm.patchValue({nation: this.selectedCountry.region.nation.iso})
      this.filteredCountries = [];
    }, 100);
  }

  selectLocation(location: any, addressIndex: number) {
    this.selectedStreet = location;

    setTimeout(() => {
      const addresses = this.newApplicant.get('addresses') as FormArray;
      const address = addresses.at(addressIndex) as FormGroup;

      if (address) {
        address.patchValue({
          location_id: location.id,
          type_id: location.type.id,
          street: location.street,
          number: location.number,
          cap: location.cap,
        });
      }

      console.log(this.selectedStreet);
      this.createCodeJobForm.patchValue({cap: this.selectedStreet.cap})
      this.filteredLocations = [];
    }, 100);
  }

  handleLocationTypes(event: any, addressIndex: any) {
    console.log(event);

    this.selectedLocation = this.locationtypes.find(
      (e) => e.id === event.detail.value
    );
    console.log(this.selectedLocation);
    this.noCountries = false;
    setTimeout(() => {
      const addresses = this.newApplicant.get('addresses') as FormArray;
      const address = addresses.at(addressIndex) as FormGroup;

      if (address) {
        address.patchValue({
          street: '',
        });
      }

      console.log(this.selectedCountry);
      this.filteredCountries = [];
    }, 100);
  }
  setTypeId(event){
    let selectedName = event.detail.value
    console.log(selectedName);
    
    let selectedType = this.types_category.find(type => type.name === selectedName);

    if (selectedType) {
      // Una volta trovato l'oggetto, aggiorna i form con i valori di .name e .code
      this.createProjectForm.patchValue({ type_id: selectedType.name });
      this.createCodeJobForm.patchValue({ type: selectedType.code });
    } else {
      console.error("Type not found");
    }

  }
}
