

import { AfterViewChecked, AfterViewInit, Component, ElementRef, Input, OnInit, Renderer2, Sanitizer, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController, ModalController, NavController, Platform, ToastController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api/api.service';
import { IonLoaderService } from 'src/app/services/ion-loader/ion-loader.service';
import { UploadDesktopComponent } from '../upload-desktop/upload-desktop.component';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { FileSaverService } from 'ngx-filesaver';

@Component({
  selector: 'app-modal-info-file',
  templateUrl: './modal-info-file.component.html',
  styleUrls: ['./modal-info-file.component.scss'],
})
export class ModalInfoFileComponent implements OnInit {

  constructor(
    private router: Router,
    private navCrtl: NavController,
    private menu: MenuController,
    private platform: Platform,
    public modalController: ModalController,
    private toastController: ToastController,
    private ionLoaderService: IonLoaderService,
    private api: ApiService,
    private renderer: Renderer2,
    private datePipe: DatePipe,
    private _FileSaverService: FileSaverService
  ) { }

  @ViewChild('download', { static: false }) download: ElementRef;
  @Input() module: any;
  @Input() item: any;
  @Input() parent: any;
  @Input() file: any;
  @Input() data: any;
  selectOptions = [];
  listVersion = [];
  customActionSheetOptions: any = {
    /*  header: 'List versions', */
    cssClass: 'mycomponent-wider-popover'
  };
  ext: string;
  isPending = false;
  isExpired = false;


  ngOnInit() {
   /*  this.data.sort(function (a, b) {
      return a.version_number - b.version_number;
    }); */
    console.log(this.data);
    //console.log(this.data.slice(-1).pop(), this.item);
    if (this.data/*  && this.data.slice(-1).pop() */ && this.item) {
      this.ionLoaderService.dismissLoader();
      this.listVersion = this.data
      //this.data = this.data.slice(-1).pop()
      //this.ext = this.data.orderfile.subString(); 

      this.ext = this.data.orderfile.substring(this.data.orderfile.lastIndexOf('.') + 1)
      console.log(this.ext);
      

    } else {
      this.ionLoaderService.dismissLoader();
      setTimeout(() => {
        this.presentToast('Error get file.', 'danger');
        this.modalController.dismiss();
      }, 100);
    }
  }

  downloadFile(event, element) {
    /*  console.log('url ', url);
     if (url) {
       window.open(url, '_blank');
     } */

    this.renderer.addClass(element.el, 'disabled');

    if (this.module && this.module.type == 'orders') {

      this.api.downloadFile(this.module.data.id, event.detail.value.id).subscribe(e => {
        console.log(e);
        this._FileSaverService.save(e.body, 'test');


        //window.location.href = e['  fileposition'];
        setTimeout(() => {
          this.renderer.removeClass(element.el, 'disabled');
        }, 1200);
      })

      return
    }

    this.api.getDownload(event.detail.value.element, event.detail.value.id).subscribe(e => {
      console.log(e);
      window.open(e['fileposition'], '_blank');

      //window.location.href = e['fileposition'];
      setTimeout(() => {
        this.renderer.removeClass(element.el, 'disabled');
      }, 1200);
    })
  }

  dismissModal() {
    this.modalController.dismiss()
  }

  async refreshExpires(infos, item) {
    console.log('refreshExpires func', this.parent, this.data);


    const checkPropmt = await this.modalController.getTop();
    if (checkPropmt) {
      //this.modalController.dismiss();
    }

    const modal = await this.modalController.create({
      component: UploadDesktopComponent,
      canDismiss: true,
      keyboardClose: true,
      breakpoints: [0, 0.2, 0.3],
      initialBreakpoint: this.platform.is('desktop') ? 0.2 : 0.3,
      componentProps: {
        header: {
          ion_header: true
        },
        showListFile: false,
        item: this.parent,
        data: this.data,
        isRenew: true
      }
      //presentingElement: this.routerOutlet.nativeEl,
    });

    modal.onWillDismiss().then((e) => {
      if (e.data /* && e.data.status != -1 */) {
        console.log(e);
        this.data = e.data.data

        // Chiamo l'api fileInfos
        this.api.getDocumentInfo(true, '', this.data.element).subscribe(data => {
          console.log(data, item);

          this.ionLoaderService.startLoader();
          this.data = data;
          this.data.sort(function (a, b) {
            return a.version_number - b.version_number;
          });
          console.log(this.data.slice(-1).pop(), this.item);
          if (this.data.length > 0 && this.data.slice(-1).pop() && this.item) {
            this.ionLoaderService.dismissLoader();
            this.isPending = false;
            this.isExpired = false;
            this.listVersion = this.data
            this.data = this.data.slice(-1).pop()
            this.ext = this.data.type;

          } else {
            this.ionLoaderService.dismissLoader();
            setTimeout(() => {
              console.log('else on renew get file');
              this.presentToast('Error get file.', 'danger');
              this.modalController.dismiss();
            }, 100);
          }

        })
        //this.item = e.data.item
        //this.canClick = true;
        //this.loadProjects(this.currentSegment);
      }
    });


    //this.ionLoaderService.dismissLoader();
    return modal.present().finally(() => {
    })


  }

  async presentToast(text, color) {
    const toast = await this.toastController.create({
      message: text,
      position: 'bottom',
      cssClass: 'toast',
      duration: 3000,
      color: color
    });
    toast.present();
  }

  checkExpires(event) {
    var now = moment(new Date()); //todays date
    var end = moment(event.expires); // another date
    var duration = moment.duration(end.diff(now));
    var days = duration.asDays();
    //console.log(days, event.expires)

    /*  days = 0;
     if(days >= 20){
       this.isPending = true
       this.isExpired = false
     }
   
     if(!event.expired){
       this.isPending = false
       this.isExpired = true
     } */

    if (days <= 20) {
      this.isPending = true
      this.isExpired = false
    }

    if (event.expired) {
      this.isPending = false
      this.isExpired = true
    }

    //const difference = moment(moment(), "DD/MM/YYYY HH:mm:ss").diff(moment(appointment, "DD/MM/YYYY HH:mm:ss"))

    return this.datePipe.transform(event.expires, 'dd/MM/yyyy')
  }

  returnSortVersion(versions) {
    versions.sort(function (a, b) {
      return a.version_number - b.version_number;
    });
    return versions.reverse()
  }

}
