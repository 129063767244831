import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-fab-btn',
  templateUrl: './fab-btn.component.html',
  styleUrls: ['./fab-btn.component.scss'],
})
export class FabBtnComponent implements OnInit {

  @Input() vertical: string;
  @Input() horizontal: string;
  @Input() slot: string;
  @Input() childs: any;
  @Input() parent: any;
  @Input() content: any;
  colors = ['secondary','bank-green', 'medium', 'tertiary','danger'];


  @Output()
  callBack: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    //private cd: ChangeDetectorRef
    private platform: Platform
  ) { }

  ngOnInit() {
    //console.log('this.vertical, this.horizontal, this.slot, this.childs');
    //console.log(this.vertical, this.horizontal, this.slot, this.childs, this.parent, this.content);

    //this.cd.detectChanges()
  }

  /*   ngAfterViewInit() {
      console.log('this.vertical, this.horizontal, this.slot, this.childs');
      console.log(this.vertical, this.horizontal, this.slot, this.childs, this.content, this.parent, this.childs.length);
    } */

  scrollToTop() {
    //console.log('scroll');
    this.content.scrollToTop(400);
  }
  scrollToBottom() {
    //console.log('scroll');
    this.content.scrollToBottom(400);
  }

  showSettings() {
    //console.log('settings');
  }

  methodAction(action) {
    try {
      this[action]()
    } catch {
      console.log('not call in component, run callback, fabBtn');
      this.callBack.emit(action)
    }
  }


}
