import { StoresService } from './../../services/stores/stores.service';
import { ProductsService } from './../../services/products/products.service';
import { Product } from './../../products/models/product.model';
import { DdtService } from './../../services/trasport_documents/ddt.service';
import { AfterViewInit, ChangeDetectorRef, Component, ComponentFactoryResolver, ComponentRef, ElementRef, Input, OnInit, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';

import { VendorsService } from 'src/app/services/vendors/vendors.service';
import { NewVendorComponent } from 'src/app/vendors/new-vendor/new-vendor.component';

import { Ddt } from '../models/ddt.model';
import { CustomersService } from 'src/app/services/customers/customers.service';
import { IonicSelectableComponent } from 'ionic-selectable';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-new-ddt',
  templateUrl: './new-ddt.component.html',
  styleUrls: ['./new-ddt.component.scss'],
})
export class NewDdtComponent {

  @Input() title: string;
  @Input() type: string;
  @ViewChild('createForm') createForm: FormGroupDirective;
  @ViewChild('createFormStore') createFormStore: FormGroupDirective;
  @ViewChild('wrapperAddress', { read: ViewContainerRef }) target: ViewContainerRef;
  private componentRef: ComponentRef<any>;
  private emailRegex = "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$";

  // group form general
  createDdtForm: FormGroup;
  createStoreForm: FormGroup;
  types: any = [];
  status: any = [];
  stores: any = [];
  products: any = [];
  companies: any = [];
  _flag_prd = 0;
  store_name = ''
  store_idPassed= 0
  defaultStore: any
  inputValue = ''
  isLoading = false

  selectedValue


  isItemAvailable = false;
  items = [];
  selectProduct: any = {};
  ddtSubscription: Subscription;

  page_vendors = 1
  numPage_vendors =  0
  @Input() ddt_in: any; // per un ipotetico inject se necessita di ereditare delle scelte
  size = 20
  onSearchSelector = ''
  onSelectVendor
  onPushVendors




  

  constructor(
    private modalController: ModalController,
    private fb: FormBuilder,
    private api: DdtService,
    private storeApi: StoresService,
    private productApi: ProductsService,
    private vendorApi: VendorsService,
    private customerApi: CustomersService,

  ) {

    let url = '?page=' + 0 + '&items=' + 1000;

    /*  this.api.getDdtTypes(url).subscribe(e => {
       console.log(e)
       this.types = e.results
     })     */
     
    this.defaultStore = { id: this.store_idPassed, name: this.store_name }; 
    console.log(this.defaultStore)
    this.storeApi.getStores(url).subscribe(e => {
      console.log(e)
      this.stores = e.results
    })

    // Generete FormGroup Create Vendor 
    this.createDdtForm = this.fb.group({
      reference_id: this.fb.control('', Validators.required),
      store_id: this.fb.control('', Validators.required),
      date_request: this.fb.control('', Validators.required),
      code: this.fb.control(''),
      type: this.fb.control('',Validators.required),
      external_code: this.fb.control(''),
      description: this.fb.control('',Validators.required),
      // file: this.fb.control('',Validators.required),
      //unload_desc: this.fb.control('')
    });

    // Form Store

    this.createStoreForm = this.fb.group({
      name: this.fb.control('', Validators.required),
      description: this.fb.control('', Validators.required)
    });

    this.createDdtForm.get('type').valueChanges.subscribe((value) => {
      if (value === 'OUT') {
        // Se il valore è 'OUT', rendi 'unload_desc' required e 'description' non required
        //this.createDdtForm.get('unload_desc').setValidators([Validators.required]);
        this.createDdtForm.get('description').setValue('');
        //this.createDdtForm.get('description').clearValidators();
      } else {
        // Altrimenti, rendi 'description' required e 'unload_desc' non required
        //this.createDdtForm.get('description').setValidators([Validators.required]);
        this.createDdtForm.get('description').setValue('');
        //this.createDdtForm.get('unload_desc').clearValidators();
      }

      // Esegui la validazione dei campi
      //this.createDdtForm.get('unload_desc').updateValueAndValidity();
      this.createDdtForm.get('description').updateValueAndValidity();
    });


  }


  dismissModal(status = -1) {
    this.modalController.dismiss({ data: { status: status } });
  }



  ionViewWillEnter() {
    this._flag_prd = 0,
      // this.getDdtMeta();
    this.getVendorCustomerList();
    this.createDdtForm.patchValue({store_id: this.store_idPassed})
  }

  // getDdtMeta() {

  //   let page_product = 1;
  //   let url = '?page=' + page_product + '&items=' + 25;

  //   this.productApi.getProductsList(url).subscribe(e => {
  //     console.log(e);
  //     this.products = e.results;
  //     /* if (e.num_pages > 1) {
  //       for (let i = 2; i <= e.num_pages; i++) {
  //         console.log(i);
  //         page_product++
  //         let url = '?page=' + page_product + '&items=' + 25;
  //         this.productApi.getProductsList(url).subscribe(e => {
  //           console.log(e);
  //           this.products.push(...e.results);
            
  //         })

  //       }

  //     } */
  //   })
  // }


  getVendorCustomerList() {
    // let page_ = 1;
    // let url = '?page=' + page_ + '&items=' + 25;
    // console.log('entro')
    // this.vendorApi.getVendorsList(url).subscribe(e => {
    //   console.log(e);
    //   this.companies = e.results;
    //   this.vendorApi.setVendors(this.companies)
    // })
    let page_ = 1;
    let url = '?page=' + page_ + '&items=' + 200000;

    this.vendorApi.getVendorsList(url).subscribe(e => {
      console.log(e);
      this.companies = e.results;
      this.vendorApi.setVendors(this.companies)
      /* if (e.num_pages > 1) {
        for (let i = 2; i <= e.num_pages; i++) {
          console.log(i);
          page_++
          let url = '?page=' + page_ + '&items=' + 25;
          this.vendorApi.getVendorsList(url).subscribe(e => {
            console.log(e);
            this.companies.push(...e.results);
            
          })

        }

      } */
    })
  
    // this.vendorApi.getVendors().subscribe(e=>{
    //   console.log(e)
    //   this.companies = e
    // })

    // let page_ = 1;
    // let url = '?page=' + page_ + '&items=' + 25;

    // if (this.type == 'IN') {
    //   //Vendor
    //   this.vendorApi.getVendorsList(url).subscribe(e => {
    //     console.log(e);
    //     this.companies = e.results;
    //     /* if (e.num_pages > 1) {
    //       for (let i = 2; i <= e.num_pages; i++) {
    //         console.log(i);
    //         page_++
    //         let url = '?page=' + page_ + '&items=' + 25;
    //         this.vendorApi.getVendorsList(url).subscribe(e => {
    //           console.log(e);
    //           this.companies.push(...e.results);
              
    //         })
  
    //       }
  
    //     } */
    //   })
    // } else {
    //   //Customer
    //   this.customerApi.getCustomersList(url).subscribe(e => {
    //     console.log(e);
    //     this.companies = e.results;
    //     /* if (e.num_pages > 1) {
    //       for (let i = 2; i <= e.num_pages; i++) {
    //         console.log(i);
    //         page_++
    //         let url = '?page=' + page_ + '&items=' + 25;
    //         this.customerApi.getCustomersList(url).subscribe(e => {
    //           console.log(e);
    //           this.companies.push(...e.results);
              
    //         })
  
    //       }
  
    //     } */
    //   })

    // }


  }

  onChange(event) {
    this.selectProduct = event.detail.value;
    console.log(this.selectProduct);
  }


  // Call submitForm with click Save
  submitForm() {
    console.log('onSubmit');
    this.createForm.onSubmit(undefined);
  }

  submitFormStore() {
    console.log('Submit Store');
    this.createFormStore.onSubmit(undefined);
  }

  createStore(values: any) {
    const newStore: any = { ...values };
    this.storeApi.createStore(newStore).subscribe(e => {
      this.stores.push(e.data);
      this.selectedValue = '';
      this.createDdtForm.controls['store_id'].setValue(e.data.id);
    })

  }


  createDdt(values: any) {
    values = {
      "reference_id": values.reference_id,
      "store_id": values.store_id,
      "date_request": values.date_request,
      "code": values.code,
      "type": values.type,
      "external_code": values.external_code,
      "description": values.description,
      //"file": values.file
  };
    // copy all the form values into the new vendor
    const newDdt: Ddt = { ...values };
    //newDdt['type'] = this.type;
    newDdt['status'] = 'WAIT';

    console.log(newDdt)
    
    this.api.mahazin_ddts_create(newDdt).subscribe(e => {
      console.log(e);
      this.dismissModal(e);
    })
  }





  async openNewVendorModal() {
    const modal = await this.modalController.create({
      component: NewVendorComponent,
      backdropDismiss: true,
      componentProps: {
        title: 'Ddt - Add New Vendor',
        parent_type: 'root'
      }
      //presentingElement: this.routerOutlet.nativeEl,
    })


    modal.onWillDismiss().then((e) => {
      /*
      if (e.data && e.data.status != -1) {
      this.total_page = 0;
      this.page_number = 1;
      this.myInput.value = "";
      this.ddt = [];
      this.columns = [];
      this.getDdts(false, '')
    } */
    });
    return await modal.present();
  }

  richiedenteChange(event: any) {
    this.inputValue = event.detail.value;
    console.log(this.inputValue);
  }

  searchPorts(event/*:  { component: IonicSelectableComponent; text: string }, type */) {

    console.log(event, event.text);
    //console.log(event.component._modalComponent);
    //console.log(event.component._modalComponent._content);

    //event.component._modalComponent._content.scrollToBottom(1500);
    //event.component._modalComponent._content.scrollY = false

    let url = ''

    const text = event.text.trim().toLowerCase();
    //event.component.startSearch();


    // Close any running subscription.
    if (this.ddtSubscription) {
      this.ddtSubscription.unsubscribe();
    }


    if (!text) {

      // Close any running subscription.
      if (this.ddtSubscription) {
        this.ddtSubscription.unsubscribe();
      }


      //event.component.endSearch();
      //event.component.enableInfiniteScroll();
      switch (event['module']) {

        case 'vendor':
          this.page_vendors = 1

          url = '?page=' + this.page_vendors + '&items=' + this.size;

          this.vendorApi.getVendorsList(url).subscribe((data) => {
            console.log('!text call more getVendorsList', data);

            //data.results = event.component.items = (data.results);

            //this.numPage_project = data.num_pages;

            //event.component.items = data.results;
            this.onSearchSelector = ''
            this.numPage_vendors = data.num_pages;
            this.companies = data.results
            //event.component.endInfiniteScroll();
          })
          break;

      }

      return

    }



        url = '?page=' + this.page_vendors + '&items=25&name=' + text;
        this.ddtSubscription = this.vendorApi.getVendorsList(url).subscribe((data) => {
          console.log('text call from search getVendorsList', data);
          // Subscription will be closed when unsubscribed manually.
          if (this.ddtSubscription.closed) {
            return;
          }
          this.onSearchSelector = text
          this.numPage_vendors = data.num_pages;
          this.companies = data.results

        })





  }

  filterPorts(vendors: any, text: string) {
    console.log(vendors)
    return vendors.filter(vendors => {
      return vendors.name.toLowerCase().indexOf(text) !== -1; //||
      //port.country.name.toLowerCase().indexOf(text) !== -1 ||
      //port.id.toString().toLowerCase().indexOf(text) !== -1;
    });
  }

  onSelect(event) {
    console.log(event);
    if (event.isSelected/*  && !this.project_in */) {
        console.log(event.item.id)
        this.createDdtForm.controls['reference_id'].setValue(event.item.id)
   }
  }

  onInfiniteScroll(/* 
  event: { component: IonicSelectableComponent; text: string },
  type: string */ event) {

  console.log(event);
  console.log(this.page_vendors, this.numPage_vendors);
  console.log(this.title);

  let url
  let _type = event['type'] ? event['type'] : null;
  console.log('after', _type);


      url = '?page=' + (++this.page_vendors) + '&items=' + this.size;

      this.vendorApi.getVendorsList(url).subscribe((data) => {
        console.log('!text call more getVendorsList', data);

        //data.results = event.component.items = (data.results);

        //this.numPage_project = data.num_pages;

        //event.component.items = data.results;
        this.onSearchSelector = ''
        this.numPage_vendors = data.num_pages;
        this.companies = data.results
        //event.component.endInfiniteScroll();
      })
  }
}
