import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class OauthAuthorizationService {
  constructor(private router: Router) {
    const params = new HttpParams({ fromString: location.hash.replace('#', '') });
    const accessToken = params.get('access_token') || params.get('id_token');

    if (accessToken && this.currentSession == 'Oauth') {
      this.accessToken = decodeURIComponent(accessToken);
    }
  }

  login() {
    this.currentSession = 'Oauth';

    const redirectUrl = encodeURIComponent(location.href.replace(this.router.url, '/'));

    location.replace(`https://accounts.google.com/o/oauth2/auth?client_id=699518348942-cslqgega4ftgdv67vg9uio86n1ikn882.apps.googleusercontent.com&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fdrive&response_type=token&redirect_uri=${redirectUrl}`);
  }

  get accessToken(): string {
    return sessionStorage.getItem('OAUTH_ACCESS_TOKEN');
  }

  set accessToken(value: string) {
    sessionStorage.setItem('OAUTH_ACCESS_TOKEN', value);
  }

  private get currentSession(): string {
    return sessionStorage.getItem('OAUTH_SESSION');
  }

  private set currentSession(value: string) {
    sessionStorage.setItem('OAUTH_SESSION', value);
  }
}
