import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { ModalController, PopoverController, ToastController } from '@ionic/angular';
import { IonicSelectableComponent } from 'ionic-selectable';
import { CustomersService } from 'src/app/services/customers/customers.service';
import { OrdersService } from 'src/app/services/orders/orders.service';
import { VendorsService } from 'src/app/services/vendors/vendors.service';
import { Vendor } from 'src/app/vendors/models/vendor.model';
import { NewVendorComponent } from 'src/app/vendors/new-vendor/new-vendor.component';
import { VendorUpdatePage } from 'src/app/vendors/vendor-update/vendor-update.page';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalOrderDiscountLineComponent {

  /**
   * PER GESTIRE LA LISTA, RICERCA, MODIFICA E AGGIUNTA ENTITY
   */
   size = 20

   page_vendor = 1
 
   numPage_vendor = 0
 
   onSelectVendor
 
   onPushVendor
 
   onSearchSelector = ''
   portsSubscription
  
 
  site
  response;
  @Input() title
  @Input() line
  @Input() type
  @Input() discount
  @Input() order_
  
  modal: HTMLIonModalElement;
  @ViewChild('createForm') createForm: FormGroupDirective;
  createDiscount: FormGroup;

  types: any = [];
  vendors: any = [];
  discounts: any = [];


  constructor(
    private popoverController: PopoverController,
    private toastController: ToastController,
    private fb: FormBuilder,
    private order: OrdersService,
    private vendor: VendorsService,
    private modalController: ModalController,
  ) { }

  getDiscountMeta() {

    let url = '?page=' + 0 + '&items=' + 25;
    this.order.getOrdersDiscountType().subscribe(e => {
      console.log(e);
      this.types = e

    })

    this.vendor.getVendorsListNoActive(url).subscribe(e => {
      console.log(e);
      this.vendors = e.results
    })

    

  }

  createBaseForm() {

    // Generete FormGroup Create Vendor 
    this.createDiscount = this.fb.group({
      discount_category: this.fb.control(this.type, Validators.required),
      vendor_id: this.fb.control('', Validators.required),
      discount_type: this.fb.control('', Validators.required),
      value: this.fb.control('', Validators.required),
      //version: this.fb.control(''),
    })

    if (this.type == 'line') {
      this.createDiscount.addControl('line_id', new FormControl(this.line.id, Validators.required))
    }

  }

  ionViewWillEnter() {
    console.log('enter modal');

    this.response = '';
    console.log(this.discount);
    console.log(this.type);
    console.log(this.line);
    this.getDiscountMeta();
    this.createBaseForm();
  }

  dismissModal() {
    this.modalController.dismiss( this.response );
  }


  submitForm() {
    console.log('onSubmit');
    this.createForm.onSubmit(undefined);
  }


  createFormDiscount(values: any) {
    // copy all the form values into the new vendor
    const newDiscount = { ...values };
    //newDiscount.created = moment(newDiscount.created).format('YYYY-MM-DD')

    console.log(newDiscount);
    let order_id = this.order_.id
    if(newDiscount.discount_category == 'line'){
      order_id = this.line.order
    }   
    
    this.order.craeteOrdersDiscount(order_id, newDiscount).subscribe(e => {
      console.log(e);

      if (e.message && e.message.includes('OK')) {
        this.presentToast(e.message, 'success')
        this.response = e.data
        this.dismissModal();
      } else {
        console.log('no modify');

      }

    })

    console.log(newDiscount);
  }




  async presentToast(text, color) {
    const toast = await this.toastController.create({
      message: text,
      position: 'bottom',
      duration: 1000,
      cssClass: 'toast',
      color: color
    });
    return await toast.present();
  }




  
  onAddPort(event: {
    component: IonicSelectableComponent
  }) {
    // Clean form.
   

    console.log('onAddPort', event);
    // Copy search text to port name field, so
    // user doesn't have to type again.
    //this.createFormCommitter.controls['name'].setValue(event.component.searchText);

    // Show form.
    //event.component.showAddItemTemplate();
    //this.openNewProjectModal()
  }

  onSavePort(event: {
    component: IonicSelectableComponent,
    item//: Port
  }) {
    console.log('onSavePort', event);

    // Fill form.
    //this.createFormCommitter.setValue(event.item);
    //this.portCountryControl.setValue(event.item.country);

    // Show form.
    //event.component.showAddItemTemplate();

    //this.openUpdateProjectModal(event.item)
  }

  onDeletePort(event: {
    component: IonicSelectableComponent,
    item//: Port
  }) {
    // Delete port from storage.
    //this.portService.deletePort(event.item);

    // Delete port from list.
    event.component.deleteItem(event.item);
  }

  searchPorts(event/*:  { component: IonicSelectableComponent; text: string }, type */) {

    console.log(event, event.text);
    //console.log(event.component._modalComponent);
    //console.log(event.component._modalComponent._content);

    //event.component._modalComponent._content.scrollToBottom(1500);
    //event.component._modalComponent._content.scrollY = false

    let url = ''

    const text = event.text.trim().toLowerCase();
    //event.component.startSearch();


    // Close any running subscription.
    if (this.portsSubscription) {
      this.portsSubscription.unsubscribe();
    }


    if (!text) {

      // Close any running subscription.
      if (this.portsSubscription) {
        this.portsSubscription.unsubscribe();
      }


      //event.component.endSearch();
      //event.component.enableInfiniteScroll();
      switch (event['module']) {
    
        case 'vendor':
          this.page_vendor = 1

          url = '?page=' + this.page_vendor + '&items=' + this.size;

          this.vendor.getVendorsList(url).subscribe((data) => {
            console.log('!text call more getVendorsList', data);

            //data.results = event.component.items = (data.results);

            //this.numPage_project = data.num_pages;

            //event.component.items = data.results;
            this.onSearchSelector = ''
            this.numPage_vendor = data.num_pages;
            this.vendors = data.results
            //event.component.endInfiniteScroll();
          })
          break;

        default:
          console.log('Default');

          break;
      }

      return

    }


    switch (event['module']) {

      case 'vendor':

        url = '?page=' + this.page_vendor + '&items=25&name=' + text;
        this.portsSubscription = this.vendor.getVendorsList(url).subscribe((data) => {
          console.log('text call from search getVendorsList', data);
          // Subscription will be closed when unsubscribed manually.
          if (this.portsSubscription.closed) {
            return;
          }
          this.onSearchSelector = text
          this.numPage_vendor = data.num_pages;
          this.vendors = data.results

        })

        break;

      default:
        break;
    }








  }


  // Metodo para usar con Infinity Scroll en ionic-selectable
  onInfiniteScroll(/* 
    event: { component: IonicSelectableComponent; text: string },
    type: string */ event) {

    console.log(event);
    console.log(this.page_vendor, this.numPage_vendor);
    console.log(this.title);

    let url
    let _type = event['type'] ? event['type'] : null;
    console.log('after', _type);

    switch (_type) {

      case 'vendor':

        url = '?page=' + (++this.page_vendor) + '&items=' + this.size;

        this.vendor.getVendorsList(url).subscribe((data) => {
          console.log('!text call more getCustomersList', data);

          //data.results = event.component.items = (data.results);

          //this.numPage_project = data.num_pages;

          //event.component.items = data.results;
          this.onSearchSelector = ''
          this.numPage_vendor = data.num_pages;
          this.vendors = data.results
          //event.component.endInfiniteScroll();
        })
        break;

      default:
        console.log('Default');

        break;

      //.... more code case
    }



  }

  async openUpdateCustomerModal(data?) {
    console.log(data);
    const modal = await this.modalController.create({
      component: VendorUpdatePage,
      canDismiss: true,
      mode: 'md',
      //cssClass: 'fullscreen',
      componentProps: {
        id: data.item.id,
        ////nav: this.nav  // Pass nav for IonNav from modal if necessary
      },
    })


    modal.onWillDismiss().then((e) => {
      console.log(e);

      if (!e.data) {
        console.log('no modify');
        return
      } else {
        this.onSelectVendor = e.data

      }
    });
    return await modal.present();
  }

  async openNewCustomerModal() {
    const modal = await this.modalController.create({
      component: NewVendorComponent,
      //cssClass: 'newOrderModalForm',
      canDismiss: true,
      componentProps: {
        ////nav: this.nav,
        title: 'Add Company',
        redirectAfterPost: false
      },

    });

    modal.onWillDismiss().then(async (e) => {
      console.log(e);

      if (!e.data) {
        console.log('no modify');
        return
      } else {
        //this.loadProjects(this.currentSegment);
        //this.projects = await this.project.getProjectsList_rev('?page=' + this.page_project + '&items=' + this.size).toPromise().then(e => e.results)

        this.page_vendor = 1
        //this.onSelectProject = [e.data]
        this.onPushVendor = e.data
      }
    });

    return await modal.present();
  }


  onSelect(event) {
    console.log(event);
    if (event.isSelected) {
     if (event.module == 'vendor') {
        this.onSelectVendor = event.item

      }
    } else if (!event.isSelected) {
      if (event.module == 'vendor') {
        this.onSelectVendor = ''

      }
    }
  }

}
