import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { IonInput, IonSegment, ModalController, PopoverController, ToastController } from '@ionic/angular';
import { InvoicesService } from 'src/app/services/invoices/invoices.service';


@Component({
  selector: 'app-invoice-line',
  templateUrl: './invoice-line.component.html',
  styleUrls: ['./invoice-line.component.scss'],
})
export class InvoiceLineComponent implements OnInit {

  site;
  response;
  @Input() order;
  @Input() line;
  @Input() type;
  @Input() discount;

  @ViewChild('inputEl', { static: false }) inputEl: IonInput;
  @ViewChild('createForm') createForm: FormGroupDirective;
  @ViewChild('seg') seg: IonSegment;
  createLine: FormGroup;
  types: any = [];

  constructor(
    private fb: FormBuilder,
    private toastController: ToastController,
    private popoverController: PopoverController,
    private modalController: ModalController,
    private invoiceApi: InvoicesService
  ) { }

  ngOnInit() { }

  get_f(control, prop) {
    if (!this.createLine)
      return

    return this.createLine.controls[control][prop];
  }

  getMin() {
    let min = 1;
    if (this.line.complete.value >= 1) {
      min = this.line.complete.value + 1;
    } else {
      min = 1;

    }
    console.log(min);

    return min
  }

  getMax(type?) {

    let max = 100;
    if ( type == '' || type == 'P')
      max = 100
    else
      max = this.line.qty;

    if (!type)
      return max



    if (this.createLine)
      this.createLine.controls['value'].setValue(max)

    console.log(max);
    return max


  }

  createBaseForm() {

    // Generete FormGroup Create Vendor 
    this.createLine = this.fb.group({
      id: this.fb.control(this.line.id, Validators.required),
      order_id: this.fb.control(this.order.id, Validators.required),
      value: this.fb.control('', [Validators.min(this.getMin()), Validators.max(this.getMax(this.line['complete']['type'])), Validators.required]),
      type: this.fb.control(this.line['complete']['type'], Validators.required),
    })

    if (this.type == 'line')
      this.createLine.addControl('line_id', new FormControl(this.line.id, Validators.required))



    this.createLine.controls['id'].disable()
    this.createLine.controls['order_id'].disable()

    if (this.line['complete']['type'])
      this.createLine.controls['type'].disable()
    else
      this.createLine.controls['type'].setValue('P')


      this.createLine.controls['value'].valueChanges.subscribe(e => {

      })

    this.createLine.controls['type'].valueChanges.subscribe(e => {
      console.log(e);

      this.createLine.controls['value'].removeValidators([Validators.min(this.getMin()), Validators.max(this.getMax())])

      setTimeout(() => {
        this.createLine.controls['value'].clearValidators()
        this.createLine.controls['value'].addValidators([Validators.min(this.getMin()), Validators.max(this.getMax(e))])

      }, 100);


    })

    this.createLine.valueChanges.subscribe(val => {
      console.log(val);
      console.log(this.createLine);
      console.log(this.createLine.controls);
    })

  }

  submitForm() {
    console.log('onSubmit');

    this.createLine.controls['type'].enable()
    this.createForm.onSubmit(undefined);
  }


  createFormLineInvoice(values: any) {
    // copy all the form values into the new vendor
    const newDiscount = { ...values };
    //newDiscount.created = moment(newDiscount.created).format('YYYY-MM-DD')

    console.log(newDiscount);
    this.invoiceApi.craeteAdvance(this.line, newDiscount).subscribe(e => {
      console.log(e);

      if (e.message && e.message.includes('OK')) {
        this.presentToast(e.message, 'success')
        e.data['type'] = e.data.advance_type;
        console.log(e.data);

        this.response = e.data
        this.dismissModal();
      } else {
        console.log('no modify');

      }

    })

    console.log(newDiscount);
  }




  ionViewWillEnter(response) {

    console.log('input order', this.order);
    console.log('input type', this.type);
    console.log('input line', this.line);

    this.createBaseForm();

    this.invoiceApi.getListTypeAdvance().subscribe(e => {
      this.types = e;
    })

    if (this.type == 'line') {

      console.log('-----------------line-------------------');

      setTimeout(() => {
        this.inputEl.setFocus()
      }, 500);

      //let filter = max.filter(e => e.approved)

      //this.discounts.push(...this.line.discounts)

      //this.approved = filter;

      //let a = this.groupArrayOfObjects(this.discounts, 'vendor')
      //let b = this.groupArrayOfObjects(this.discounts, 'version')

      //console.log(a);
      //console.log(b);



    } else if (this.type == 'global') {



    }


  }
  ionViewDidEnter(response) {
  }


  dismiss() {
    this.popoverController.dismiss(this.response);
  }

  dismissModal() {
    this.modalController.dismiss(this.response);
  }



  async presentToast(text, color) {
    const toast = await this.toastController.create({
      message: text,
      position: 'bottom',
      duration: 3000,
      cssClass: 'toast',
      color: color
    });
    return await toast.present();
  }

}
