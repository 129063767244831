import { Component, ElementRef, OnChanges, Renderer2, ViewChild } from '@angular/core';

import { IonMenu, IonSplitPane, MenuController, Platform, PopoverController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { AuthenticationService } from './services/authentication/authentication.service';
import { ConnectionStatus, NetworkService } from './services/network/network.service';
import { OfflineManagerService } from './services/network/offline-manager.service';
import { TranslateService } from '@ngx-translate/core'; // add this
import { AlertController } from '@ionic/angular';
import { FirebaseService } from './services/firebase/firebase.service';
import { ThemeService } from './services/theme/theme.service';

import { IonLoaderService } from './services/ion-loader/ion-loader.service';
import { Location, LocationStrategy } from '@angular/common';
import { SubMenuService } from './services/utils/sub-menu/sub-menu.service';
import { TooltipComponent } from './components/tooltip/tooltip.component';

//import { Uid } from '@ionic-native/uid/ngx';
//import { BLE } from '@awesome-cordova-plugins/ble/ngx';

import { ActivatedRoute, Router } from '@angular/router';
import { PlatformService } from './services/utils/platform/plarformservice';
import { TitleAppService } from './services/utils/title-app/title-app.service';
import { pairwise } from 'rxjs';
import { RoutingStateService } from './services/routing-state/routing-state.service';



@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnChanges {
  appPages:any = []
  // public appPages = [
  //   { title: 'Dashboard', text: 'Dashboard principale', target: 'home', icon: 'home', subPages: [], active: false, disable: false, hover: false },
  //   {
  //     title: 'Commesse', text: 'Lista progetti', target: 'projects', icon: 'flask',
  //     isOpen: 0, subPages: [], active: false, disable: false, hover: false
  //   },
  //   /* {
  //     title: 'Tasks', text: 'Lista attività', target: 'tasks', icon: 'flask',
  //     isOpen: 0, subPages: [], active: false, disable: false
  //   }, */
  //   /* {
  //     title: 'Anagrafica', target: '', icon: 'archive',
  //     isOpen: 0,
  //     subPages: [ */
  //   //{ title: 'Locations', url: 'loctions', icon: 'business', active: false, disable: true },
  //   /*  ], active: false, disable: false
  //  }, */
  //   { title: 'Stores', text: 'Lista stores', target: 'stores', icon: 'storefront', active: false, disable: false, hover: false },

  //   { title: 'Prodotti', text: 'Lista prodotti e servizi', target: 'mahazin', icon: 'cube', active: false, disable: false, hover: false },
  //   //#Ric commentato per non filtrare i tipi di prodotti{ title: 'Prodotti', text: 'Lista prodotti e servizi', target: 'mahazin:1', icon: 'cube', active: false, disable: false },
  //   //{ title: 'Servizi', text: 'Lista prodotti e servizi', target: 'mahazin:2', icon: 'cube', active: false, disable: false },
  //   //{ title: 'Listino', text: 'Lista prodotti e servizi', target: 'mahazin:3', icon: 'cube', active: false, disable: false },
  //   { title: 'Ordini', text: 'Lista ordini', target: 'order', icon: 'prism', active: false, disable: false, hover: false, },
  //   // { title: 'Ordini', text: 'Lista ordini', target: 'totalorders', icon: 'prism', active: false, disable: false, hover: false, },
  //   { title: 'Timesheet', text: 'Timesheet', target: 'timesheet', icon: 'time', active: false, disable: false, hover: false, },
  //   { title: 'Recruitment', text: 'Recruitment', target: 'ecruitment', icon: 'person', active: false, disable: false, hover: false, },
  //   // { title: 'TEST', text: 'Lista ordini', target: '/test', icon: 'prism', active: false, disable: false, hover: false, },
  //   { title: 'Fatture', text: 'Lista fatture', target: 'invoice', icon: 'document-text', active: false, disable: true, hover: false },
  //   { title: 'Reports', text: 'Genera reports', target: 'reports', icon: 'stats-chart', active: false, disable: true, hover: false },

  //   { title: 'Documenti', text: 'Lista documnti', target: 'documan', icon: 'folder', active: false, disable: true, hover: false },
  //   //{ title: 'Registrazione', target: 'register', icon: 'wallet', active: false, disable: false },

  //   { title: 'Anagrafiche', text: 'Lista fornitori', target: 'vendor', icon: 'pricetags', active: false, disable: false, hover: false },
  //   // { title: 'Clienti', text: 'Lista clienti', target: 'customer', icon: 'bookmarks', active: false, disable: true },
  //   { title: 'Dipendenti', text: 'Lista dipendenti', target: 'worker', icon: 'people', active: false, disable: true, hover: false },
  //   { title: 'Utenti', text: 'Lista Utenti', target: 'users', icon: 'person', active: false, disable: true, hover: false },

  //   { title: 'Profilo', text: 'Profilo utente', target: 'profile', icon: 'happy', active: false, disable: false, hover: false },
  //   /*{ title: 'Outbox', target: '/folder/Outbox', icon: 'paper-plane' },
  //     { title: 'Favorites', target: '/folder/Favorites', icon: 'heart' }, */
  //   /*{ title: 'Projects', target: '/folder/Projects', icon: 'archive' }, */
  //   //{ title: 'Projects List', target: '/tabs/tab1/projects-list', icon: 'archive' },
  //   //{ title: 'Documents', target: '/render-documents/0', icon: 'archive' },
  //   //{ title: 'Profile', target: '/tabs/tab3/profile-user', icon: 'person' },
  //   /*{ title: 'Trash', target: '/folder/Trash', icon: 'trash' },
  //     { title: 'Spam', target: '/folder/Spam', icon: 'warning' }, */
  // ];

  public labels = ['Active', 'Disable', 'On Hold', 'Reminders'];

  platform_os = false;

  online;
  active = '';
  isSwipeEnabled: any = true;
  dark = true;
  toggle: any;
  selectedIndex = 0;

  title = '';
  icon = '';
  pages = [];
  accordions = [];

  clickToggleMenu = true;
  toggleImg = true;
  subscription
  autoWidth
  state
  enableView
  auth
  @ViewChild('menuPages') menuPages: IonMenu
  @ViewChild('menuFeatures') featurePages: IonMenu
  @ViewChild('splitPane') splitPane: IonSplitPane
  @ViewChild('tooltip') tooltip: ElementRef
  @ViewChild('tooltip2') tooltip2: ElementRef
  canGoBack = false;
  animateHeader = true;
  iconName = ''
  showIcon = false
  copyText = false
  prev



  constructor(
    private platform: Platform,
    private platformService: PlatformService,
    //private ble: BLE,
    private locationStrategy: LocationStrategy,
    private authService: AuthenticationService,
    private router: Router,
    private storage: Storage,
    public networkService: NetworkService,
    private offlineManager: OfflineManagerService,
    private translate: TranslateService, // add this
    private firebaseService: FirebaseService,
    public alertController: AlertController,
    private theme: ThemeService,
    private ionLoaderService: IonLoaderService,
    private route: ActivatedRoute,
    private location: Location,
    public submenuService: SubMenuService,
    private menu: MenuController,
    private renderer: Renderer2,
    private popoverController: PopoverController,
    private TitleAppService: TitleAppService,
    private routingState: RoutingStateService
  ) {

    // if (this.authService.isAuthenticated()) {
    //   setTimeout(() => {
    //     this.router.navigate(['/home']);
    //   }, 100);
    // } else {
    //   this.router.navigate(['/login']);
    // }
    //this.appPages = []
    this.clickToggleMenu = false
    console.log(this.state);

    this.authService.getLocalData('auth-profile').then(e => {
      console.log(e);
      this.appPages = e?.user_data?.menu
      if(e?.user_data?.email === 'alessia.branca@coolprojects.it') {
        this.dark = false
        this.clickToggleMenu = false
      }
      else{
        this.dark = true
        // this.clickToggleMenu = true
        document.body.classList.toggle('hide', true);
        document.body.setAttribute('data-theme', 'dark');
        document.body.classList.toggle('dark', this.dark);
      }

    } );   
    setTimeout(() => {
      console.log(this.authService.isAuthenticated());
      if(this.authService.isAuthenticated()){
        console.log('home');
        this.state = true
        this.router.navigate(['/home'])
      }else{      
        this.router.navigate(['/login'])
      }
    }, 100);

    this.authService.checkRemoteToken('auth-profile').subscribe(e=>{
      console.log(e);
      
    })
    // document.body.classList.toggle('hide', true);
    // document.body.setAttribute('data-theme', 'dark');
    // document.body.classList.toggle('dark', this.dark);
    // const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
    // prefersDark.addEventListener('change', (mediaQuery) => {
    //   console.log('prefersDark.addEventListener', mediaQuery);
      
    //   this.dark = mediaQuery.matches;
    //   this.updateDarkMode();
    // });
    this.submenuService.params.subscribe(e => {
      console.log('AppComponent', e);
      this.title = e.title
      this.icon = e.icon
      this.pages = e.pages
      this.accordions = e.accordions

    })

    this.submenuService.open.subscribe(e => {
      console.log('AppComponent', e);



    })

  }


  setActive(item) {
    this.appPages.map((e) => {
      if (e.target == item.target) {
        e.active = true
        if (e.isOpen != undefined) {
          e.isOpen = 1
        }
      }
      else {
        e.active = false
        if (e.isOpen != undefined) {
          e.isOpen = 0
        }
      }
    })
  }

  goTo2(target) {
    this.submenuService.out.next(target)
  }

  toggleMenu() {
    console.log('toggle splitPane')

    this.platformService.setDimensionWidthDisplay()

    if(this.authService.isAuthenticated())
      this.state = true
    this.clickToggleMenu = !this.clickToggleMenu
    //this.submenuService.open.next(this.clickToggleMenu)
  }

  ngAfterViewInit(){
    if(this.authService.isAuthenticated())
    this.state = true
  }

  goTo(component) {
    ////nav: this.nav(component, { routerDirection: 'forward' })
    console.log(component);
    console.log(component.target);
    this.router.navigate[('/'+component.target)]
    this.setActive(component)
    setTimeout(() => {
    this.submenuService.component.next(component.target)
      
    }, 300);
  }


  ngOnChanges(){
    console.log('entro');
    
    if(this.authService.isAuthenticated()){
      this.state = true
    }
  }

  networkSubscriber(): void {
    this.online = this.networkService.getCurrentNetworkStatus()
    this.networkService.onNetworkChange().subscribe((status: ConnectionStatus) => {
      if (status == ConnectionStatus.Online) {
        this.online = 0
        this.offlineManager.checkForEvents().subscribe();
      } else {
        this.online = 1
      }
    });
  }


  


  updateDarkMode() {
    //console.log(this.dark);

    // this.theme.publishStatus(this.dark)
    console.log(this.dark)
    if (!this.dark) {
      console.log(1);
      
      document.body.classList.toggle('hide', true);
      document.body.setAttribute('data-theme', 'dark');
      document.body.classList.toggle('dark', this.dark);

      if (!this.platform.is("desktop")) {

        //this.statusBar.overlaysWebView(true);
        //this.statusBar.backgroundColorByHexString('#33000000');
      }

      setTimeout(() => {
        document.body.classList.toggle('hide', false);
      }, 150);

    } else {
      console.log(2);

      document.body.classList.toggle('hide', true);
      document.body.setAttribute('data-theme', 'light');
      document.body.classList.toggle('dark', this.dark);

      if (!this.platform.is("desktop")) {


        //this.statusBar.overlaysWebView(true);
        //this.statusBar.backgroundColorByHexString('#33000000');
      }

      setTimeout(() => {
        document.body.classList.toggle('hide', false);
      }, 150);

    }

    if (this.platform.is('desktop')) {
      console.log(3);
      if(this.dark){
        const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
        prefersDark.addEventListener('change', (mediaQuery) => {
          console.log('prefersDark.addEventListener', mediaQuery);
          
          this.dark = mediaQuery.matches;
          this.updateDarkMode();
        });
      }
      else{
      console.log(4);
        const prefersDark = window.matchMedia('(prefers-color-scheme: light)');
        prefersDark.addEventListener('change', (mediaQuery) => {
          console.log('prefersDark.addEventListener', mediaQuery);
          
          this.dark = mediaQuery.matches;
          this.updateDarkMode();
        });
      }
    } else {
      
      console.log('this.platform.is != desktop');

    }

  }

  async presentAlert(header, subHeader, message, path?) {
    const alert = await this.alertController.create({
      cssClass: 'alert-modal-appcomponent',
      header: header,
      subHeader: subHeader,
      message: message,
      //mode:'ios',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel');
          }
        }, {
          text: 'Ok',
          handler: () => {
            console.log('Confirm Ok');
            //path != 'update' ? this.router.navigateByUrl(path) : this.market.open(environment.appId);
          }
        }
      ]
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);


  }


  mouseenter(event, item) {
    //console.log(event, item);

    if (item.nativeElement)
      item = item.nativeElement
    else if (item.el)
      item = item.el


    this.renderer.setStyle(item, 'opacity', '1')
    this.renderer.setStyle(item, 'bottom', '0')
    setTimeout(() => {
      this.renderer.addClass(item, 'my_tooltip')
      this.renderer.setStyle(item, 'display', 'block')
    }, 200);

    setTimeout(() => {
      this.mouseleave(event, item)
    }, 1500);

  }

  async mouseleave(event, item) {

    if (item.nativeElement)
      item = item.nativeElement
    else if (item.el)
      item = item.el

    this.renderer.setStyle(item, 'opacity', '0')
    this.renderer.setStyle(item, 'bottom', '-40px')
    setTimeout(() => {
      this.renderer.removeClass(item, 'my_tooltip')
      this.renderer.setStyle(item, 'display', 'none')
    }, 200);

  }



  /**
   * @param ev event return from hrml on click for presentPopover
   */
  async presentPopover(ev: any, item) {

    const checkPropmt = await this.popoverController.getTop();
    if (checkPropmt) {
      //this.popoverController.dismiss();
      return
    }

    const popover = await this.popoverController.create({

      component: TooltipComponent,
      cssClass: 'popover_setting',
      event: ev,
      translucent: true,
      mode: 'md',
      side: 'bottom',
      alignment: 'center',
      size: 'auto',
      dismissOnSelect: true,
      backdropDismiss: true,
      //trigger: ev.srcElement.id,
      componentProps: {
        text: item
      }

    });

    popover.present();

    // Alla chiusura ricevo i payload
    popover.onDidDismiss().then((e) => {
      //console.log(e.data);
      if (e.data) {
        //this.toggleSrc = e.data.toggleSrc
      }
      console.log('onDidDismiss resolved with role', e);
    });
  }

  onMouseEnter(item: any) {
    item.hover = true;
}

onMouseLeave(item: any) {
    item.hover = false;
}

goBack() {
  console.log('goBack', this.canGoBack);

  let disable = false;

  if (this.canGoBack) {
    this.TitleAppService.title.pipe(pairwise()).subscribe(([previous, current]) => {
      console.log(previous, current);

      if (previous !== current) {
        // Some code to process if the new value was different.

      }

    })



  } else {
    this.prev = ''
    this.menu.toggle('pages')

    // this.st.selectTab(this.tabs[0])
  }

}
  copyToClipboard(text: string): void {
    const match = text.match(/\[(.*?)\]/);
    if (match && match[1]) {
      const textToCopy = match[1];
      navigator.clipboard.writeText(textToCopy).then(() => {
        console.log('Testo copiato negli appunti:', textToCopy);
        // Mostra un messaggio all'utente, se necessario
        this.copyText = true
        setTimeout(() => {
          this.copyText = false
        }, 1000);
      }).catch(err => {
        console.error('Errore durante la copia negli appunti:', err);
      });
    } else {
      console.log('Nessun testo tra parentesi quadre trovato');
      // Gestisci il caso in cui non ci sono parentesi quadre
    }
  }



}
