
import { AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, Renderer2, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { NavController, ModalController, IonInfiniteScroll, IonSearchbar, IonContent, IonSegment, IonSegmentButton, IonMenu, MenuController } from '@ionic/angular';

import { animate, query, sequence, stagger, state, style, transition, trigger } from '@angular/animations';

import { TabsService } from 'src/app/services/tabs/tabs.service';

import { ColumnChangesService, ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { SubMenuService } from 'src/app/services/utils/sub-menu/sub-menu.service';
import { ProjectsService } from 'src/app/services/projects/projects.service';

interface PageInfo {
  offset: number;
  pageSize: number;
  limit: number;
  count: number;
}
/**
 * An object used to get page information from the server
 */
export class Page {
  // The number of elements in the page
  size: number = 0;
  // The total number of elements
  totalElements: number = 0;
  // The total number of pages
  totalPages: number = 0;
  // The current page number
  pageNumber: number = 0;
}

const listAnimation = trigger('listAnimation', [
  transition('* <=> *', [
    query(':enter',
      [style({ opacity: 0 }), stagger('180ms', animate('300ms ease-out', style({ opacity: 1 })))],
      { optional: true }
    ),
    query(':leave',
      animate('200ms', style({ opacity: 0 })),
      { optional: true }
    )
  ])
]);


@Component({
  selector: 'app-table-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class TableListComponent implements OnInit, AfterViewInit, OnChanges {


  n = 0
  public data: any; // Data;
  public rows: any;

  rowIsNotExpanded = true

  editing = {};

  selected = [];

  funder = [];
  calculated = [];
  pending = [];
  ColumnMode: ColumnMode
  expanded: any = {};
  timeout: any;
  total_order = 0;
  currentSegment: string = "all";

  @Input() columns;
  @Input() listItems;
  @Input() cache;


  //item;
  @Output() clicked = new EventEmitter<any>();
  @Output() switch = new EventEmitter<any>();


  @ViewChild('segment') segment: IonSegment;
  @ViewChild('btn_segment') btn_segment: IonSegmentButton;
  @ViewChild('myTable') table: DatatableComponent;
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  @ViewChild(IonContent) content: IonContent;


  fab: boolean = false;
  customAlertOptions: any = {
    header: 'List groups',
    //subHeader: 'Select group for item',
    message: 'Select group for item',
    translucent: true
  };

  searching = false
  res: any = []

  total_page = 0;
  page_number = 1;
  page_limit = 30;

  isSearch: boolean = false;
  isLoad: boolean = false;

  queryString = {
    search_by: '',
    search_data: ''
  };

  queryFilter = {
    search_by: 'sort',
    search_data: [{ prop: 'priority.id', dir: 'asc' }]
  };


  totalElements: number;
  pageNumber: number;

  isLoading = 0;

  constructor(
    private menu: MenuController,
    private subMenu: SubMenuService,
  ) {

    console.log('list item constructor');

    setTimeout(() => {
      this.isLoad = true
    }, 9000);
  }

  ngOnInit() {
    console.log('list item ngOnInit');

    //this.item = [];
    this.fab = true
    //this.loadProjects(this.queryString);
    //this.getOrders(false, "", this.queryString)

    this.queryString = localStorage.getItem('filter_search_item') ? JSON.parse(localStorage.getItem('filter_search_item')) : { search_by: '', search_data: 'all' }

    this.total_order = 0;
    this.total_page = 0;
    this.page_number = 1;
    
    this.listItems = []


    console.log(this.listItems, this.columns);

    //this.getOrdersMeta()

  }


  ngAfterViewInit(): void {

    console.log('list item ngAfterViewInit', this.pageNumber);
    console.log(this.listItems, this.columns);

  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);

  }



  openMenu() {
    this.menu.open('admin')
    this.subMenu.params.next({
      title: 'Filter',
      icon: 'funnel-outline',
      accordions: [
        {
          target: '',
          color: '',
          icon: 'list-outline',
          title: 'Status'
        },
        {
          target: '',
          color: '',
          icon: 'list-outline',
          title: 'Priority'
        },
        {
          target: '',
          color: '',
          icon: 'list-outline',
          title: 'Category'
        },
        {
          target: '',
          color: '',
          icon: 'list-outline',
          title: 'Type'
        }
      ]
    })
  }


  concateQuery(search) {
    console.log(search);


    /*     const index = this.columns.findIndex(item => item.name === "Status");
        console.log(index)
    
    
        if (this.page_number <= 1 && index == -1)
          this.columns.splice(2, 0, { prop: 'isActive', name: 'Status', width: 95, maxWidth: 95, minWidth: 95, cellClass: 'td-status', headerClass: 'th-status' }) */


    let url = '?page=' + this.page_number + '&items=' + this.page_limit;
    let query = '';


    if (search && search != '' && search.search_data != 'all') {
      query += '&' + search.search_by + '=';
      query += search.search_data;
      url += query
      let filtered = this.columns.filter(obj => obj.name !== 'Status');


      console.log(filtered);

      this.columns = [...filtered]

    } else {
      this.columns = [...this.columns]
    }

    console.log(url);
    return url
  }





  onDetailToggle(event) {
    console.log('Detail Toggled', event);
  }

  toggleExpandRow(row) {
    console.log('Toggled Expand Row!', row);
    this.table.rowDetail.collapseAllRows();
    this.table.rowDetail.toggleExpandRow(row);
  }

  /**
   * @description Aggiunge elementi allo scroll incrementando le pagina per l'api 
   * @param pageInfo ritorna dall'evento default con interfaccia dichiarata in alto PageInfo
   * @returns La paginazione fino al confronto pagine totati pagina attuale interrogata
   */
  setPage(pageInfo: PageInfo) {
    //console.log('pageInfo', this.n);
    //console.log(pageInfo);
    //console.log(this.cache);
    this.n++;

    // Current page number is determined by last call to setPage
    // This is the page the UI is currently displaying
    // The current page is based on the UI pagesize and scroll position
    // Pagesize can change depending on browser size
    this.pageNumber = pageInfo.offset;

    // Calculate row offset in the UI using pageInfo
    // This is the scroll position in rows
    const rowOffset = pageInfo.offset * pageInfo.pageSize;

    // When calling the server, we keep page size fixed
    // This should be the max UI pagesize or larger
    // This is not necessary but helps simplify caching since the UI page size can change
    const page = new Page();
    page.size = this.page_limit;
    page.pageNumber = Math.floor(rowOffset / page.size);

    //console.log(page.pageNumber);
    //console.log(this.cache);

    // We keep a index of server loaded pages so we don't load same data twice
    // This is based on the server page not the UI

    if (this.cache[page.pageNumber]) return;
    this.cache[page.pageNumber] = true;

    if (this.n <= 1) { this.cache = {} }; // esclude l'errore del conteggio pagina in cache

    // Chiamo l'api vericicando il tot elementi, le pagine e i risultati per pagina
    //this.loadProjects(this.queryString)
    // Output emitter to parent for call api and add element on list
  }


  sorted(columnProp: string, direction: string) {
    console.log(columnProp, direction);
    if (columnProp == 'priority.name') {
      columnProp = 'priority.id'
    }

    this.queryFilter = { search_by: 'sort', search_data: [{ prop: columnProp, dir: direction }] }
    localStorage.setItem('filter_sort_item', JSON.stringify({ search_by: 'sort', search_data: [{ prop: columnProp, dir: direction }] }))

    console.log(this.queryFilter);
  }


  onSelect({ selected }) {
    console.log('Select Event', selected, this.selected);
  }

  /**
   * @description onClick on row open order details
   * @param event pass obj row clicked
   */
  onActivate(event) {
    /* if (this.table.rowDetail && event.type == 'click') {
      this.table.rowDetail.toggleExpandRow(event.row);
      console.log('Activate Event', event);
    } */

    if (event.type == 'click') {
      console.log('Activate Event', event);
      //this.openOrderModal(event.row)
      this.clicked.emit(event);
    }


  }

  setExpandStatus(expanded: any): void {
    console.log('setExpandStatus Event', expanded);
  }



  onGetRowClass = (row) => {
    //console.log(row);
    // id 3 is Delete for Status Order
    /*   if (row.status.id === 3) {
        return 'deleted';
      } else if (row.status.id === 2) {
        return 'request';
      } else if (row.status.id === 1) {
        return 'draft';
      } else if (row.status.id === 4) {
        return 'order';
      } */
  }



}




