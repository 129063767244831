import { ActivatedRoute, Router } from '@angular/router';

import { Platform, AnimationController, IonNav, ModalController, ToastController } from '@ionic/angular';
import { Component, OnInit, ElementRef, ViewChild, Input } from '@angular/core';
import { LineInvoice } from '../models/line_invoice.model';
import { ApiService } from 'src/app/services/api/api.service';
import { UploadDesktopComponent } from 'src/app/components/media/upload-desktop/upload-desktop.component';
import { InvoicesService } from 'src/app/services/invoices/invoices.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { ProjectsService } from 'src/app/services/projects/projects.service';
import { CustomersService } from 'src/app/services/customers/customers.service';
import { VendorsService } from 'src/app/services/vendors/vendors.service';
import { OrdersService } from 'src/app/services/orders/orders.service';
import { NewInvoiceComponent } from '../new-invoice/new-invoice.component';
import { Invoice } from '../models/invoice.model';
import * as moment from 'moment';
import { IonDatetime } from '@ionic/angular';
import { format, parseISO } from 'date-fns';



@Component({
  selector: 'app-invoice-update',
  templateUrl: './invoice-update.page.html',
  styleUrls: ['./invoice-update.page.scss'],
})

export class InvoiceUpdatePage {
  @ViewChild('favIcon', { read: ElementRef }) favIcon: ElementRef;
  @ViewChild(UploadDesktopComponent, { static: true }) UploadDesktopComponent: UploadDesktopComponent;
  @ViewChild('myTable') table: DatatableComponent;
  @ViewChild('createForm') createForm: FormGroupDirective;

  @Input() id: any = '';
  public showProgressStatus = false;
  public invoice: Invoice;
  public favState = false;
  public favOnAnimation: Animation;
  public favOffAnimation: Animation;

  page_number = 1;
  page_limit = 30;

  filteredAddress = [];
  selectedSegment


  order_orders_createForm: FormGroup;

  minDate = moment().format();
  maxDate = moment().add(1, 'd').format();


  response;

  types: any = [];
  categories: any = [];
  status: any = [];
  priorities: any = [];
  //projects: any = [];
  customers: any = [];
  vendors: any = [];



  constructor(
    public platform: Platform,
    private invoiceApi: InvoicesService,
    private orderApi: OrdersService,
    private projectApi: ProjectsService,
    private customerApi: CustomersService,
    private vendorApi: VendorsService,
    private api: ApiService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
    private animationCtrl: AnimationController,
    //private nav: IonNav,
    private modalController: ModalController,
    private toastController: ToastController
  ) {

    this.id = this.route.snapshot.paramMap.get('id');
  }

  createBaseForm() {

    // Generete FormGroup Create Invoice 
    this.order_orders_createForm = this.fb.group({
      id: this.fb.control(this.invoice.id, Validators.required),
      code: this.fb.control(this.invoice.code, Validators.required),
      type_id: this.fb.control(this.invoice.type.id, Validators.required),
      cost: this.fb.control(this.invoice.cost, Validators.required),
      customer_id: this.fb.control(this.invoice.customer.id, Validators.required),
      vendor_id: this.fb.control(this.invoice.vendor.id, Validators.required),
      fiscal_year: this.fb.control(this.invoice.fiscal_year, Validators.required),
      effective_date: this.fb.control(this.invoice.effective_date, Validators.required),
      extra: this.fb.control(this.invoice.extra, Validators.required)
    }); 
    //console.log(this.order_orders_createForm);

  }

  getOrderMeta() {

    let page_project = 1;
    let page_customer = 1;
    let page = 1;


    this.invoiceApi.getListType().subscribe(e => {
      console.log(e);
      this.types = e;
    })

    this.vendorApi.getVendorsList('?page=' + page + '&items=' + 25).subscribe(e => {
      console.log(e);
      this.vendors = e.results;
    })

    this.customerApi.getCustomersList('?page=' + page + '&items=' + 25).subscribe(e => {
      console.log(e);
      this.customers = e.results;
    })


  }

  ionViewWillEnter() {
    console.log('ionViewWillEnter', this.id);


    this.response = '';
    this.getOrderMeta();

    this.invoiceApi.getInvoiceById(parseInt(this.id, 10))
      .subscribe(e => {
        // if the project doesn't exists, return to home page
        if (!e) {
          //this.router.navigate(['/invoice-list']);
        } else {
          this.invoice = e;
          this.selectedSegment = ''

          this.createBaseForm()
          /* if (this.UploadDesktopComponent && e.folders.length && e.folders[0].folder.id != undefined) {
            this.UploadDesktopComponent.passItem(e);
          } else {
            
          } */
        }
      });


  }


  getDocuments(id_folder) {
    let url = '?page=' + this.page_number + '&items=' + this.page_limit;
    this.api.getFiles(id_folder, url).subscribe(x => {
      this.page_number++
      return x
    })
  }



  fav(): void {
    if (this.favState) {
      // this.favOnAnimation.stop();
      this.favOffAnimation.play();
    } else {
      // this.favOffAnimation.stop();
      this.favOnAnimation.play();
    }

    this.favState = !this.favState;
  }

  onChangeSelectAcc(event) {
    console.log(event);
  }


  editOrder() {
    //this.navCtrl.navigateForward(['/members/invoice-details/', item.id]);    
    //nav: this.nav(InvoiceUpdatePage, { id: this.id }, { animated: true, direction: 'forward' });
  }


  async openNewOrderModal() {
    const modal = await this.modalController.create({
      component: NewInvoiceComponent,
      canDismiss: true,
      componentProps: {
        title: 'Add New Invoice',
        parent_type: 'root'
      }
      //presentingElement: this.routerOutlet.nativeEl,
    })


    modal.onWillDismiss().then((e) => {
      if (e.data && e.data.status != -1) {
        /* this.page_number = 1;
        this.myInput.value = "";
        this.invoices = [];
        this.getOrders(false, '') */
      }
    });
    return await modal.present();
  }


  submitForm() {
    this.createForm.onSubmit(undefined);
  }

  fabCallBack(action) {
    console.log(action);

    if (action) {
      this[action]()
    } else {
      console.log('not call in component, run callback', 'details');


    }
  }


  removeDuplicate(types) {
    if (types && types.lenght) {
      const names = types.map(o => o.name)
      const filtered = types.filter(({ name }, index) => !names.includes(name, index + 1))
      return filtered
    }
  }


  filterAddress(address, event) {

    if (event) {
      console.log(address, event);
      this.filteredAddress = address.filter(function (type) {
        return type.name == event.detail.value;
      });
    }

  }



  async openModalUpload() {
    const modal = await this.modalController.create({
      component: UploadDesktopComponent,
      canDismiss: true,
      componentProps: {
        //title: 'Add New Invoice',
        item: this.invoice,
        header: { ion_header: true },
        files: [],
        parent_type: 'root'
      }
      //presentingElement: this.routerOutlet.nativeEl,
    })


    modal.onWillDismiss().then((e) => {
      if (e.data && e.data.status != -1) {
        /* this.page_number = 1;
        this.myInput.value = "";
        this.invoices = [];
        this.getOrders(false, '') */
      }
    });
    return await modal.present();
  }


  dismissModal(event?) {
    this.modalController.dismiss(this.response)
  }

  onActivate(event) {
    if (this.table.rowDetail && event.type == 'click') {
      this.table.rowDetail.toggleExpandRow(event.row);
      console.log('Activate Event', event);
    }
  }



  updateOrder(form) {
    // Map Address   

    //Call API
    console.log(form, this.invoice);

    this.invoiceApi.updateInvoiceById(this.invoice, form).subscribe(e => {
      console.log(e);
      if (e.message && e.message.includes('OK')) {
        this.presentToast(e.message, 'success')
        this.response = { res: 'OK', data: e.data };
        this.dismissModal();
      } else {
        this.response = 'KO';
      }
    })

    //console.log(form)
  }


  async presentToast(text, color) {
    const toast = await this.toastController.create({
      message: text,
      position: 'bottom',
      duration: 3000,
      cssClass: 'toast',
      color: color
    });
    return await toast.present();
  }


  onChangeStartDate(event) {
    this.maxDate = moment(event.detail.value).add(1, 'd').format();
  }

  formatDate(value: string) {
    return format(parseISO(value), 'MMM dd yyyy');
  }


}
