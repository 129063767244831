import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
// import { AuthVerifyRedirectGuard } from './guard/auth-verify-redirect.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tab-bar/tab-bar.module').then(m => m.TabBarPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./menu-desktop/menu-desktop.module').then(m => m.MenuDesktopPageModule)
  },
  {
    path: 'order',
    loadChildren: () => import('./orders/orders-list/orders-list.module').then(m => m.OrdersListPageModule)
  },
  // {
  //   path: 'orderlist',
  //   loadChildren: () => import('./totalorders/orders-list/orders-list.module').then(m => m.OrdersListPageModule)
  // },
  {
    path: 'jobs',
    loadChildren: () => import('./jobs/jobs-list/jobs-list.module').then(m => m.JobsPageModule)
  },
  {
    path: 'projects',
    loadChildren: () => import('./projects/projects-list/projects-list.module').then(m => m.ProjectsListPageModule)
  },
  {
    path: 'mahazin',
    loadChildren: () => import('./products/products-list/products-list.module').then(m => m.ProductsPageModule)
  },
  {
    path: 'vendor',
    loadChildren: () => import('./vendors/vendors-list/vendors-list.module').then(m => m.VendorsPageModule)
  },
  {
    path: 'reports',
    loadChildren: () => import('./reports/reports-list/reports-list.module').then(m => m.ReportsPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./users/profile-user/profile-user.module').then(m => m.ProfileUserPageModule)
  },
  {
    path: 'timesheet',
    loadChildren: () => import('./timesheet/timesheet.module').then(m => m.TimesheetPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./public/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'stores',
    loadChildren: () => import('./stores/stores-list/stores-list.module').then( m => m.StoresPageModule)
  },

  {
    path: 'orders-details',
    loadChildren: () => import('./orders/order-details/order-details.module').then( m => m.OrderDetailsPageModule)
  },
  {
    path: 'projects-details',
    loadChildren: () => import('./projects/project-details/project-details.module').then(m => m.ProjectDetailsPageModule)
  },
  {
    path: 'hr-management',
    loadChildren: () => import('./hr-management/hr-management.module').then( m => m.HRManagementPageModule)
  },
  {
    path: 'totalorders',
    loadChildren: () => import('./orders/totalorders/totalorders.module').then( m => m.TotalordersPageModule)
  },
  {
    path: 'tenders',
    loadChildren: () => import('./tenders/tenders-list/tenders-list.module').then( m => m.TendersListPageModule)
  },


  // {
  //   path: 'vendors-details',
  //   loadChildren: () => import('./pages/vendors/vendors-details/vendors-details.module').then( m => m.VendorsDetailsPageModule)
  // },
  // {
  //   path: 'users-details',
  //   loadChildren: () => import('./pages/users/users-details/users-details.module').then( m => m.UsersDetailsPageModule)
  // },

  // {
  //   path: 'permissions',
  //   loadChildren: () => import('./pages/users/permissions/permissions.module').then( m => m.PermissionsPageModule)
  // },



];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
