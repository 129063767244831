import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { IonNav, IonSegment, Platform } from '@ionic/angular';
import { RenderDocumentsPage } from '../documents/render-documents/render-documents.page';
import { OrdersListPage } from '../orders/orders-list/orders-list.page';
import { ProductsListPage } from '../products/products-list/products-list.page';
import { ProjectsListPage } from '../projects/projects-list/projects-list.page';
import { VendorsListPage } from '../vendors/vendors-list/vendors-list.page';

@Component({
  selector: 'app-menu-mobile',
  templateUrl: './menu-mobile.page.html',
  styleUrls: ['./menu-mobile.page.scss'],
})
export class MenuMobilePage {


  documents = RenderDocumentsPage;
  vendors = VendorsListPage;
  products = ProductsListPage;
  orders = OrdersListPage;
  projects = ProjectsListPage;



  listLiks = [
    { id: 5, target: this.projects, name: 'Projects', code: 'project', title: 'Projects', sub: 'Gestione progetti', desc: 'Gestione progetti', image: 'https://img.freepik.com/free-vector/podium-wallpaper-with-geometrical-3d-shapes_52683-61182.jpg?t=st=1645923397~exp=1645923997~hmac=193e027e0b73108ebaf73fda5bf1ce3a56414ea37edb4bef7695c622ede3666b&w=1380' },
    { id: 4, target: this.orders, name: 'Orders', code: 'order', title: 'Orders', sub: 'Gestione ordini', desc: 'Gestione ordini e fatture', image: 'https://img.freepik.com/free-vector/podium-wallpaper-with-geometrical-3d-shapes_52683-61178.jpg?t=st=1646955179~exp=1646955779~hmac=e934a45e1263bb9d6a172b0a41c753f6f602901ffa3b9398735ab3632c7edeb6&w=1380' },
    { id: 2, target: this.vendors, name: 'Vendors', code: 'vendor', title: 'Vendors', sub: 'Gestione fornitori', desc: 'Gestione fornitori e anagrafica', image: 'https://img.freepik.com/free-vector/podium-wallpaper-with-geometrical-3d-shapes_52683-61180.jpg?t=st=1645923405~exp=1645924005~hmac=af931250b39cd02f20e6e87d0eed01ecb73bc82a752fb3e4bd314a7fe842b812&w=1380' },
    { id: 3, target: this.products, name: 'Products', code: 'mahazin', title: 'Products', sub: 'Gestione prodotti', desc: 'Gestione prodotti e magazzino', image: 'https://img.freepik.com/free-vector/podium-wallpaper-with-geometrical-3d-shapes_52683-61177.jpg?w=1060' },
    {
      id: 1,
      target: this.documents,
      name: 'Documents',
      code: 'documan',
      title: 'Documenti',
      sub: 'Gestione documentale',
      desc: 'Gestione documenti e archivio',
      image: 'https://img.freepik.com/free-vector/podium-wallpaper-with-geometrical-3d-shapes_52683-61182.jpg?t=st=1645923397~exp=1645923997~hmac=193e027e0b73108ebaf73fda5bf1ce3a56414ea37edb4bef7695c622ede3666b&w=1380'
    },]


  segment = 0;


  @ViewChild('seg', { static: true }) seg: IonSegment;

  constructor(
    //private nav: IonNav,
    private renderer2: Renderer2,
    private platform: Platform
  ) {
    this.segment = 0;
  }


  goTo(target?) {
    //nav: this.nav(target)
  }


  async segmentChanged(event) {

    /* await this.slider.slideTo(this.segment);
    let prev = await this.slider.getPreviousIndex();

    if (prev > Number(this.segment)) {
      let n = 0;
      let interval = setInterval(() => {
        n += 60
        this.renderer2.setProperty(this.seg['el'], 'scrollLeft', ((Number(this.segment) + 1) * 360) - n)
        if (n >= 360) clearInterval(interval)
      }, 20)
    } else {
      let n = 0;
      let interval = setInterval(() => {
        n += 60
        this.renderer2.setProperty(this.seg['el'], 'scrollLeft', ((Number(this.segment) - 1) * 360) + n)
        if (n >= 360) clearInterval(interval)
      }, 20)
    } */

  }


  async slideChanged() {
    //this.segment = await this.slider.getActiveIndex();
  }

}
