// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-fab {
  position: fixed;
  z-index: 1000000000000000000000000000;
}

.pulse-button {
  transform: translateX(100%);
  -webkit-transform: translateX(100%);
}

.slide-in {
  animation: slide-in 0.5s forwards;
  -webkit-animation: slide-in 0.5s forwards;
}

.slide-out {
  animation: slide-out 0.5s forwards;
  -webkit-animation: slide-out 0.5s forwards;
}

@keyframes slide-in {
  100% {
    transform: translateX(0%);
  }
}
@keyframes slide-out {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/fab-btn/fab-btn.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EAAoB,qCAAA;AAExB;;AAEA;EACI,2BAAA;EACA,mCAAA;AACJ;;AAEA;EACI,iCAAA;EACA,yCAAA;AACJ;;AAEA;EACI,kCAAA;EACA,0CAAA;AACJ;;AAEA;EACI;IAAO,yBAAA;EAET;AACF;AAIA;EACI;IAAK,yBAAA;EAIP;EAHE;IAAO,4BAAA;EAMT;AACF","sourcesContent":["ion-fab {\n    position: fixed;    z-index: 999999999999999999999999999;\n}\n\n\n.pulse-button {\n    transform: translateX(+100%);\n    -webkit-transform: translateX(+100%);\n}\n\n.slide-in {\n    animation: slide-in 0.5s forwards;\n    -webkit-animation: slide-in 0.5s forwards;\n}\n\n.slide-out {\n    animation: slide-out 0.5s forwards;\n    -webkit-animation: slide-out 0.5s forwards;\n}\n    \n@keyframes slide-in {\n    100% { transform: translateX(0%); }\n}\n\n@-webkit-keyframes slide-in {\n    100% { -webkit-transform: translateX(0%); }\n}\n    \n@keyframes slide-out {\n    0% { transform: translateX(0%); }\n    100% { transform: translateX(-100%); }\n}\n\n@-webkit-keyframes slide-out {\n    0% { -webkit-transform: translateX(0%); }\n    100% { -webkit-transform: translateX(-100%); }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
