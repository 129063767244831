import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Platform, ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { from, Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, map, tap, timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as XLSX from 'xlsx'; // Usa xlsx-style al posto di xlsx


//import environment from '../../../config.json';
import { AuthenticationService } from '../authentication/authentication.service';
import { NetworkService, ConnectionStatus } from '../network/network.service';
//import { Storage } from '@ionic/storage';
import { OfflineManagerService } from '../network/offline-manager.service';



const API_STORAGE_KEY = 'prjService';
const TOKEN_KEY = 'auth-token';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  private url: string = 'http'
  private token: any;

  constructor(
    private storage: Storage,
    private plt: Platform,
    private http: HttpClient,
    public toastController: ToastController,
    private authService: AuthenticationService,
    private networkService: NetworkService,
    private offlineManager: OfflineManagerService
  ) {

    /**
     * Map url API
    */
    this.url = environment.SSL.active ? this.url + 's' : this.url
    this.url += '://' + environment.API_URI.url + ':' + environment.API_URI.port + environment.API_URI.path

    this.authService.currentToken.subscribe(x => {
      //console.log(x);
      this.token = x //= JSON.parse(localStorage.getItem(TOKEN_KEY))

    })

  }



  /**
   * 
   * @param forceRefresh true or false for force refresh api req
   * @returns lista brands fgb
   */
  getReportByProjectId(project_id): Observable<any> {
    //return of(mokService);
    return this.http.get<any>(`${this.url}reports/project/${project_id}/totals/`)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);

          return data
        }),
        catchError(this.handleError<any[]>('Get total report from project by id', []))
      );

  }


  /**
   * 
   * @param forceRefresh true or false for force refresh api req
   * @returns lista brands fgb
   */
  getReportOrdersByProjectId(project_id): Observable<any> {
    console.log(project_id);

    //return of(mokService);
    return this.http.get<any>(`${this.url}reports/project/${project_id}/orders/`)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);

          return data
        }),
        catchError(this.handleError<any[]>('Get order total report from project by id', []))
      );

  }
  generateExcel(data: any[], month: number, year: number) {
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    const wsData = this.prepareSheetData(data, month, year);
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(wsData.sheetData);
    this.applyStyles(ws, wsData.styles);
    XLSX.utils.book_append_sheet(wb, ws, 'Timesheet');

    XLSX.writeFile(wb, 'Timesheet.xlsx');
  }

  prepareSheetData(data: any[], month: number, year: number): { sheetData: any[][], styles: any[] } {
    const wsData: any[][] = [];
    const styles: any[] = [];
    const daysInMonth = new Date(year, month, 0).getDate();
    const dateHeaders = Array.from({ length: daysInMonth }, (_, i) => i + 1);

    // Header
    const header = ['Data', ...dateHeaders, 'Totale'];
    wsData.push(header);

    data.forEach(user => {
      let userTotal = 0;
      const rowData = [user.ref_name];

      for (let day = 1; day <= daysInMonth; day++) {
        const dayString = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
        const dayOfWeek = new Date(dayString).getDay();

        if (dayOfWeek === 6 || dayOfWeek === 0) {
          // Sabato o Domenica
          rowData.push('');
        } else {
          const hours = user.meta.reduce((acc, task) => {
            const dayHours = task.timesheet.reduce((dayAcc, entry) => {
              if (entry.workhistory_date === dayString) {
                return dayAcc + entry.workhistory_hour;
              }
              return dayAcc;
            }, 0);
            return acc + dayHours;
          }, 0);
          userTotal += hours;
          rowData.push(hours.toString()); // Convertire a stringa
          if (hours > 0) {
            styles.push({ cell: `${String.fromCharCode(65 + rowData.length - 1)}${wsData.length + 1}`, color: '00FF00' });
          }
        }
      }

      rowData.push(userTotal.toString()); // Convertire a stringa
      wsData.push(rowData);
    });

    // Legenda
    wsData.push([]);
    wsData.push(['Legenda']);
    wsData.push(['Lavorato', '', '00FF00']);
    wsData.push(['Permesso', '', 'FFA500']);
    wsData.push(['Ferie', '', 'FF0000']);
    wsData.push(['Esame', '', 'FF00FF']);
    wsData.push(['Malattia', '', '800080']);
    wsData.push(['Donazione', '', '0000FF']);
    wsData.push(['Permesso 104', '', 'FFFF00']);

    return { sheetData: wsData, styles };
  }

  applyStyles(ws: XLSX.WorkSheet, styles: any[]) {
    styles.forEach(style => {
      const cell = ws[style.cell];
      if (cell) {
        cell.s = {
          fill: {
            fgColor: { rgb: style.color }
          }
        };
      }
    });
  }




  private handleError<T>(operation = 'operation', result?: T) {

    return (error: any): Observable<T> => {

      console.error(error);
      console.log(result, error);

      console.log(`${operation} failed: ${error}`);
      let status = error == 'Forbidden' ? 403 : 500
      this.presentToast(status, error == 'undefined' ? 'Internal Server Error' : error, operation)
      return of(error.error as T);
    };
  }


  async presentToast(status, statusText, message) {
    const toast = await this.toastController.create({
      message: status + ' ' + statusText + ': "' + message + '"',
      duration: 2000,
      mode: 'ios',
      color: status != '200' ? 'danger' : 'primary'
    });
    toast.present();
  }

  logSys(status) {
    console.log('ForceRefresh API ', status, this.networkService.getCurrentNetworkStatus(), this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline);

  }


  // Save result of API requests
  private setLocalData(key, data) {
    this.storage.set(`${API_STORAGE_KEY}-${key}`, data);
  }

  // Get cached API result
  private getLocalData(key) {
    return this.storage.get(`${API_STORAGE_KEY}-${key}`);
  }
}
