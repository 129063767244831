import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'workHours'
})
export class WorkHoursPipe implements PipeTransform {
  transform(timesheet: any[], day: string): string {
    const entry = timesheet.find(item => item.workhistory_date === day);
    return entry ? entry.workhistory_hour.toString() : '+';
  }
}
