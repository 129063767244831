import { map } from 'rxjs/operators';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UploadDesktopComponent } from 'src/app/components/media/upload-desktop/upload-desktop.component';
import { ApiService } from 'src/app/services/api/api.service';
import { VendorsService } from 'src/app/services/vendors/vendors.service';
import { Docs } from '../models/vendor.model';
import { AlertController, ModalController } from '@ionic/angular';
import { HrService } from 'src/app/services/hr/hr.service';

@Component({
  selector: 'app-vendor-update',
  templateUrl: './vendor-update.page.html',
  styleUrls: ['./vendor-update.page.scss'],
})
export class VendorUpdatePage {
  @ViewChild('createForm') createForm: FormGroupDirective;
  @ViewChild(UploadDesktopComponent, { static: true })
  UploadDesktopComponent: UploadDesktopComponent;

  isDisabled: boolean = true;

  arraySegment = [
    { id: 0, name: 'work', active: false },
    { id: 1, name: 'legal', active: true },
  ];

  id: any = '';
  types;
  address: number = 0;
  vendorItem: any = {};
  createVendorForm: FormGroup;
  addressesGroup: any = [];
  files: any = [];

  page_number = 1;
  page_limit = 30;
  vendor: any = [];
  response;
  selectedSegment: any = 'legal';
  selectedSegmentContact: any = 'contact';
  segments = [{ code: 'legal', name: 'Legale' }];
  segmentsContacts = [{ code: 'contact', name: 'contact' }];

  locationtypes: any = [];
  selectedLocation: any = [];
  filteredCountries: any = [];
  noCountries = false;
  filteredLocations: any = [];
  selectedCountry: any = [];
  selectedStreet: any = [];

  constructor(
    private route: ActivatedRoute,
    private api: ApiService,
    private vendorApi: VendorsService,
    private fb: FormBuilder,
    private apiVendor: VendorsService,
    private apiHr: HrService,
    private modalController: ModalController,
    private alertController: AlertController
  ) {
    this.id = this.route.snapshot.paramMap.get('id') || '';
    this.apiVendor.getTypesList().subscribe((e) => {
      this.types = e['results'];
      console.log(this.types);
    });
    this.apiHr.getLocationTypes().subscribe((e) => {
      console.log('locationTypes', e);
      this.locationtypes = e.results;
      console.log(this.locationtypes);
    });

    this.createVendorForm = this.fb.group({
      name: ['', Validators.required],
      code: ['', Validators.required],
      type_id: ['', Validators.required],
      code_fiscal: [''],
      code_iban: [''],
      code_unique: [''],
      code_vat: ['', Validators.required],
      contacts: this.fb.array([this.createAddress()]),
      address: this.fb.group({
        country_id: ['', Validators.required],
        type_id: ['', Validators.required],
        street: ['', Validators.required],
        number: ['', Validators.required],
        cap: ['', Validators.required],
      }),
      addresses: this.fb.array([this.createAddress()]),
      is_customer: [false],
      is_vendor: [false],
    });

    this.createVendorForm.valueChanges.subscribe((e) => {
      console.log(e);
      console.log(this.createVendorForm);
    });

    this.toggleAddressControls(false);
    this.toggleAddressesControls(false);
  }

  toggleAddressControls(enable: boolean) {
    const addressGroup = this.createVendorForm.get('address') as FormGroup;
    Object.keys(addressGroup.controls).forEach((control) => {
      const formControl = addressGroup.get(control) as FormControl;
      if (enable) {
        formControl.enable();
      } else {
        formControl.disable();
      }
    });
  }

  toggleAddressesControls(enable: boolean) {
    const addressGroup = this.createVendorForm.get('addresses') as FormGroup;
    Object.keys(addressGroup.controls).forEach((control) => {
      const formControl = addressGroup.get(control) as FormControl;
      if (enable) {
        formControl.enable();
      } else {
        formControl.disable();
      }
    });
  }

  ngOnInit() {
    // this.createBaseForm();
    console.log(this.vendor);

    if (this.vendor.addresses.length !== 0) {
      this.vendor.addresses.forEach((e, index) => {
        this.segments.push({
          code: `address_${index}`,
          name: e.name,
        });
      });
    }
    
    if (this.vendor.contacts.length !== 0) {
      this.vendor.contacts.forEach((e, index) => {
        this.segmentsContacts.push({
          code: `contact_${index}`,
          name: e.name,
        });
      });
    }
    this.segments.push({ code: 'new', name: '+' });
    this.segmentsContacts.push({ code: 'new', name: '+' });

    console.log(this.segments);

    this.populateForm(this.vendor);
  }

  get addresses() {
    return this.createVendorForm.get('addresses') as FormArray;
  }

  populateForm(vendor) {
    this.createVendorForm.patchValue({
      name: vendor.name,
      code: vendor.code,
      type_id: vendor.type ? vendor.type.id : 0,
      code_vat: vendor.code_vat,
      code_fiscal: vendor.code_fiscal,
      code_iban: vendor.code_iban,
      code_unique: vendor.code_unique,
      is_customer: vendor.is_customer,
      is_vendor: vendor.is_vendor,
    });

    // Aggiorna il gruppo address separatamente
    this.createVendorForm.get('address').patchValue({
      country_id: vendor.address.country.name,
      type_id: vendor.address.type.name,
      street: vendor.address.street,
      number: vendor.address.number,
      cap: vendor.address.cap,
    });

    const addressArray = this.createVendorForm.get('addresses') as FormArray;
    // addressArray.clear();
    console.log(vendor);
    console.log(addressArray);
  }

  addRowAddress() {
    // Handling dinamic add row address (name,street and city)
    let addressArray = this.createVendorForm.controls['addresses'] as FormArray;
    let arraylen = addressArray.length;
    // max Row added is five
    if (arraylen < 5) {
      let newAddressgroup: FormGroup = this.fb.group({
        name: [''],
        street: [''],
        number: [''],
        city: [''],
        cap: [''],
        region: [''],
        province: [''],
        nation: [''],
      });
      addressArray.insert(arraylen, newAddressgroup);
    }
  }

  createContact(contact) {
    //console.log(contact);

    for (let i = 0; i < contact.length; i++) {
      let single_contact = this.fb.group({
        name: this.fb.control(contact[i].name),
        data: this.fb.control(contact[i].data),
      });
      let contactArray = this.createVendorForm.controls['contact'] as FormArray;
      let arraylen = contactArray.length;
      contactArray.insert(arraylen, single_contact);
    }
    //console.log(this.createVendorForm.controls.contact['controls'])
  }

  addRowPhone() {
    // Handling dinamic add row phones
    let phoneArray = this.createVendorForm.controls['phones'] as FormArray;
    let arraylen = phoneArray.length;
    // max Row added is five
    if (arraylen < 5) {
      let newPhonegroup: FormGroup = this.fb.group({
        phone: [''],
      });
      phoneArray.insert(arraylen, newPhonegroup);
    }
  }

  addRowEmail() {
    // Handling dinamic add row email
    let emailArray = this.createVendorForm.controls['emails'] as FormArray;
    let arraylen = emailArray.length;
    // max Row added is five
    if (arraylen < 5) {
      let newEmailgroup: FormGroup = this.fb.group({
        email: [''],
      });
      emailArray.insert(arraylen, newEmailgroup);
    }
  }

  onchange(event) {
    //console.log(event);
    this.address = event.detail.value;
  }

  getSegmentClass(segmentCode: string) {
    const match = segmentCode.match(/^address_(\d+)$/);
    if (match) {
      const index = parseInt(match[1], 10);
      return `background-address-${index}`;
    }
    return `background-${segmentCode}`;
  }

  getSegmentClassContact(segmentCode: string) {
    const match = segmentCode.match(/^contact_(\d+)$/);
    if (match) {
      const index = parseInt(match[1], 10);
      return `background-contact-${index}`;
    }
    return `background-${segmentCode}`;
  }
  // ionViewWillEnter() {
  //   console.log(this.vendor);

  //   this.createVendorForm = this.fb.group({
  //     name: this.fb.control(this.vendor.name, Validators.required),
  //     code: this.fb.control(this.vendor.code, Validators.required),
  //     type: this.fb.control(
  //       this.vendor.type ? this.vendor.type.name : 1,
  //       Validators.required
  //     ),
  //     code_vat: this.fb.control(this.vendor.code_vat, Validators.required),
  //     code_fiscal: this.fb.control(this.vendor.code_fiscal),
  //     code_iban: this.fb.control(this.vendor.code_iban),
  //     code_unique: this.fb.control(this.vendor.code_unique),
  //     emails: this.fb.array([]),
  //     contacts: this.fb.group({
  //       phone: ['', Validators.required],
  //       email: ['', [Validators.required, Validators.email]],
  //     }),
  //     address: this.fb.array([this.createAddress()]),
  //     is_customer: this.fb.control(this.vendor.is_customer),
  //     is_vendor: this.fb.control(this.vendor.is_vendor),
  //   });

  //   this.createVendorForm.valueChanges.subscribe((e) => {
  //     console.log(e);
  //     console.log(this.createVendorForm);
  //   });
  // }

  getVendorById(id) {
    this.vendorApi.getVendorById(id).subscribe((e) => {
      this.vendorItem = e;
      console.log(e);

      if (
        this.UploadDesktopComponent &&
        e.folders.length &&
        e.folders[0].folder.id != undefined
      ) {
        this.UploadDesktopComponent.passItem(e.folders[0].folder.id);
      } else if (this.UploadDesktopComponent) {
        this.UploadDesktopComponent.passItem('');
      }

      //console.log(this.createVendorForm)

      // if (this.vendorItem.address) {
      //   //console.log(this.vendorItem.address);
      //   this.createAddress(this.vendorItem.addresses)
      //   //console.log(this.getAddressGroupByName(this.vendorItem.address));

      // }

      // if (this.vendorItem.contact) {
      //   //console.log(this.vendorItem.contact);

      //   //this.createVendorForm.addControl('contact', this.fb.group({}))
      //   this.createContact(this.vendorItem.contact)
      // }
    });
  }

  getDocuments(id_folder) {
    let url = '?page=' + this.page_number + '&items=' + this.page_limit;
    this.api.getFiles(id_folder, url).subscribe((x) => {
      this.page_number++;
      return x;
    });
  }

  getAddressGroupByName(addrs: any) {
    let array = [];
    var addressGroupName = addrs;

    array.push(
      addressGroupName.reduce(function (r, a) {
        r[a.name] = r[a.name] || [];
        r[a.name].push(a);
        return r;
      }, Object.create(null))
    );

    return array;
  }

  updateVendor() {
    // Map Address
    // form.address = this.addressMap(form.address)
    // // Add New Contact
    // this.addNewContact(form)
    // // Add New Address
    // this.addNewAddress(form)

    //Call API
    console.log(this.vendor);
    console.log(this.createVendorForm.value);

    // Crea una copia dell'oggetto form value
    const formValueWithoutAddresses = { ...this.createVendorForm.value };

    // Rimuovi l'oggetto addresses dalla copia
    delete formValueWithoutAddresses.addresses;

    // Esegui la chiamata API con l'oggetto modificato

    this.apiVendor
      .updateVendor(formValueWithoutAddresses, this.vendor.id)
      .subscribe((e) => {
        console.log(e);
        this.response = e.data;
        if (e.message.includes('OK')) this.dismissModal();
      });

    //console.log(form)
  }

  addressMap(address) {
    if (Array.isArray(address))
      return address.map((adr) => {
        return {
          name: adr.name,
          position: {
            cap: adr.cap,
            city: adr.city,
            nation: adr.nation,
            number: adr.number,
            province: adr.province,
            region: adr.region,
            street: adr.street,
          },
        };
      });
    else
      return {
        name: address.name,
        position: {
          cap: address.cap,
          city: address.city,
          nation: address.nation,
          number: address.number,
          province: address.province,
          region: address.region,
          street: address.street,
        },
      };
  }

  segmentChanged(event: any) {
    console.log(event);
    // this.populateForm(this.vendor);

    if (event !== 0) {
      if (event?.detail) {
        this.addressToEdit = [];
        this.toggleAddressControls(false);
        this.toggleAddressesControls(false);
        console.log(event.detail.value);
        this.selectedSegment = event.detail.value;
        console.log(this.selectedSegment);
        this.filteredCountries = [];
        this.selectedCountry = [];
        const match = event.detail.value.match(/^address_(\d+)$/);
        if (match) {
          const index = parseInt(match[1], 10);
          this.patchAddressValues(index);
        }
        if (this.selectedSegment === 'new') {
          this.toggleAddressControls(true);
          this.toggleAddressesControls(true);
          const addressesArray = this.createVendorForm.get(
            'addresses'
          ) as FormArray;
          addressesArray.controls.forEach((group) => {
            (group as FormGroup).reset();
          });
          const addressArray = this.createVendorForm.get(
            'address'
          ) as FormGroup;
          addressArray.reset();
        }
        if (this.selectedSegment === 'legal') {
          this.createVendorForm.get('address').patchValue({
            country_id: this.vendor.address.country.name,
            type_id: this.vendor.address.type.name,
            street: this.vendor.address.street,
            number: this.vendor.address.number,
            cap: this.vendor.address.cap,
          });
        }
      } else {
        this.selectedSegment = event;
        console.log(this.selectedSegment);
        this.filteredCountries = [];
        this.selectedCountry = [];

        if (event === 'new') {
          const addressArray = this.createVendorForm.get(
            'addresses'
          ) as FormArray;
          addressArray.reset();
        }
        if (event === 'newLegal') {
          const addressArray = this.createVendorForm.get(
            'address'
          ) as FormGroup;
          addressArray.reset();
        }
        if (event === 'legal') {
          this.createVendorForm.get('address').patchValue({
            country_id: this.vendor.address.country.name,
            type_id: this.vendor.address.type.name,
            street: this.vendor.address.street,
            number: this.vendor.address.number,
            cap: this.vendor.address.cap,
          });
        }
      }
    }
  }
  patchAddressValues(index: number) {
    const address = this.vendor.addresses[index];
    console.log(address);

    if (address) {
      const addressFormArray = this.createVendorForm.get(
        'addresses'
      ) as FormArray;
      const addressFormGroup = addressFormArray.at(0) as FormGroup;

      addressFormGroup.patchValue({
        id: address.address.id,
        city: address.address.country.name,
        type_id: address.address.type.name,
        street: address.address.street,
        number: address.address.number,
        cap: address.address.cap,
      });
    }
  }

  addNewAddress(form) {
    if (form.addresses) {
      form.addresses.forEach((element) => {
        let address = this.addressMap(element);
        console.log(element, address);
        if (element.name != '') form.address.push(address);
      });
    }
    delete form.addresses;
  }

  addNewContact(form) {
    if (form.phones) {
      form.phones.forEach((element) => {
        console.log(element);
        let phone = {
          name: 'phone',
          data: element.phone,
        };
        if (phone.data != '') form.contact.push(phone);
      });
    }
    if (form.emails) {
      form.emails.forEach((element) => {
        console.log(element);
        let email = {
          name: 'email',
          data: element.email,
        };
        if (email.data != '') form.contact.push(email);
      });
    }
    delete form.phones;
    delete form.emails;
  }

  onChangeSelectAcc(event) {
    //console.log(event);
  }

  // Call submitForm with click Save
  submitForm() {
    //console.log('onSubmit');
    this.createForm.onSubmit(undefined);
  }

  fabCallBack(action) {
    ////console.log(action);

    if (action) {
      this[action]();
    } else {
      //console.log('not call in component, run callback', 'update');
    }
  }

  dismissModal(status?: any) {
    this.modalController.dismiss(this.response, 'ok');
  }

  searchCountriesList(event: any) {
    const query = event.target.value;
    if (query.length > 2) {
      this.apiHr.getCountriesList(event.detail.value).subscribe((e) => {
        console.log(e);
        this.filteredCountries = e.results;
        if (e.results.length === 0) {
          this.noCountries = true;
        } else {
          this.noCountries = false;
        }
      });
    } else {
      this.filteredCountries = [];
    }
  }

  getLocation(event: any) {
    const query = event.target.value;
    if (query.length > 2) {
      this.apiHr
        .getLocations(
          this.selectedCountry.id,
          this.selectedLocation.id,
          event.detail.value
        )
        .subscribe((e) => {
          console.log(e);
          this.filteredLocations = e.results;
          if (e.results.length === 0) {
            this.noCountries = true;
          } else {
            this.noCountries = false;
          }
        });
    } else {
      this.filteredLocations = [];
    }
  }

  getLocationClearNumber(event: any) {
    const query = event.target.value;
    console.log(this.selectedStreet);

    if (query.length > 0) {
      this.apiHr
        .getLocationsClear(
          this.selectedCountry.id,
          this.selectedLocation.id,
          this.selectedStreet.street,
          event.detail.value,
          this.selectedStreet.cap
        )
        .subscribe((e) => {
          console.log(e);
          this.filteredLocations = e.results;
          if (e.results.length === 0) {
            this.noCountries = true;
            const addresses = this.createVendorForm.get(
              'addresses'
            ) as FormArray;
            const address = addresses.at(0) as FormGroup;

            if (address) {
              address.patchValue({
                number: event.detail.value,
              });
            }

            console.log(this.createVendorForm.value);
          } else {
            this.noCountries = false;
          }
        });
    } else {
      this.filteredLocations = [];
    }
  }

  createAddress(): FormGroup {
    return this.fb.group({
      id: ['', Validators.required],
      name: ['', Validators.required],
      city: ['', Validators.required],
      country_id: ['', Validators.required],
      location_id: [0, Validators.required],
      type_id: ['', Validators.required],
      street: ['', Validators.required],
      number: ['', Validators.required],
      cap: ['', Validators.required],
    });
  }

  createContacts(): FormGroup {
    return this.fb.group({
      name: [''],
      contact: [''],
    });
  }

  getLocationClearCAP(event: any) {
    const query = event.target.value;
    console.log(this.selectedStreet);
    if (query.length > 0) {
      this.apiHr
        .getLocationsClear(
          this.selectedCountry.id,
          this.selectedLocation.id,
          this.selectedStreet.street,
          this.selectedStreet.number,
          event.detail.value
        )
        .subscribe((e) => {
          console.log(e);
          this.filteredLocations = e.results;
          if (e.results.length === 0) {
            this.noCountries = true;
            const addresses = this.createVendorForm.get(
              'addresses'
            ) as FormArray;
            const address = addresses.at(0) as FormGroup;

            if (address) {
              address.patchValue({
                cap: event.detail.value,
              });
            }
            console.log(this.createVendorForm.value);
          } else {
            this.noCountries = false;
          }
        });
    } else {
      this.filteredLocations = [];
    }
  }

  selectCountry(country: any, addressIndex: any) {
    this.selectedCountry = country;

    setTimeout(() => {
      const addresses = this.createVendorForm.get('addresses') as FormArray;
      const address = addresses.at(addressIndex) as FormGroup;

      if (address) {
        address.patchValue({
          country_id: country.id,
        });
      }

      console.log(this.selectedCountry);
      this.filteredCountries = [];
    }, 100);
  }

  selectCountryLegal(country: any) {
    this.selectedCountry = country;

    setTimeout(() => {
      const addresses = this.createVendorForm.get('address') as FormGroup;
      const address = addresses;
      console.log(address);

      if (address) {
        address.patchValue({
          country_id: country.id,
        });
      }

      console.log(this.selectedCountry);
      this.filteredCountries = [];
    }, 100);
  }

  selectLocation(location: any, addressIndex: number) {
    this.selectedStreet = location;

    setTimeout(() => {
      const addresses = this.createVendorForm.get('addresses') as FormArray;
      const address = addresses.at(addressIndex) as FormGroup;

      if (address) {
        address.patchValue({
          location_id: location.id,
          type_id: location.type.id,
          street: location.street,
          number: location.number,
          cap: location.cap,
        });
      }

      console.log(this.selectedStreet);
      this.filteredLocations = [];
    }, 100);
  }

  selectLocationLegal(location: any) {
    this.selectedStreet = location;

    setTimeout(() => {
      const addresses = this.createVendorForm.get('address') as FormGroup;
      const address = addresses;

      if (address) {
        address.patchValue({
          location_id: location.id,
          type_id: location.type.id,
          street: location.street,
          number: location.number,
          cap: location.cap,
        });
      }

      console.log(this.selectedStreet);
      this.filteredLocations = [];
    }, 100);
  }

  handleLocationTypes(event: any, addressIndex: any) {
    console.log(event);

    this.selectedLocation = this.locationtypes.find(
      (e) => e.id === event.detail.value
    );
    console.log(this.selectedLocation);
    this.noCountries = false;
    setTimeout(() => {
      const addresses = this.createVendorForm.get('addresses') as FormArray;
      const address = addresses.at(addressIndex) as FormGroup;

      if (address) {
        address.patchValue({
          street: '',
        });
      }

      console.log(this.selectedCountry);
      this.filteredCountries = [];
    }, 100);
  }

  handleLocationTypesLegal(event: any) {
    console.log(event);

    this.selectedLocation = this.locationtypes.find(
      (e) => e.id === event.detail.value
    );
    console.log(this.selectedLocation);
    this.noCountries = false;
    setTimeout(() => {
      const addresses = this.createVendorForm.get('address') as FormGroup;
      const address = addresses;

      if (address) {
        address.patchValue({
          street: '',
        });
      }

      console.log(this.selectedCountry);
      this.filteredCountries = [];
    }, 100);
  }

  addAddresses() {
    const addressArray = this.createVendorForm.get('addresses') as FormArray;
    const newAddress = addressArray.at(addressArray.length - 1).value;

    console.log(newAddress);

    this.apiHr.addLocation(newAddress).subscribe((e: any) => {
      console.log(e);

      let form = {
        company_id: this.vendor.id,
        address_id: e.data.id,
        name: newAddress.name,
      };
      this.apiHr.addLocationToCompany(form).subscribe((e: any) => {
        console.log(e);
        this.vendorApi.getVendorById(this.vendor.id).subscribe((e) => {
          console.log(e);

          this.vendor = e;
          this.segments = [];
          this.segments = [{ code: 'legal', name: 'Legale' }];
          this.vendor.addresses.forEach((e, index) => {
            this.segments.push({
              code: `address_${index}`,
              name: e.name,
            });
          });
          this.segments.push({ code: 'new', name: '+' });

          this.segmentChanged('legal');
        });
      });
    });
  }

  addAddress() {
    const addressArray = this.createVendorForm.get('address') as FormGroup;
    const newAddress = addressArray.value;

    console.log(newAddress);

    this.apiHr.addLocation(newAddress).subscribe((e: any) => {
      console.log(e);

      let form = {
        company_id: this.vendor.id,
        address_id: e.data.id,
        name: newAddress.name,
      };
      this.apiHr.addAddressToCompany(form).subscribe((e: any) => {
        console.log(e);
        this.vendorApi.getVendorById(this.vendor.id).subscribe((e) => {
          console.log(e);

          this.vendor = e;
          this.segmentChanged('legal');
        });
      });
    });
  }

  editAddress() {
    const addressArray = this.createVendorForm.get('address') as FormGroup;
    const newAddress = addressArray.value;

    console.log(newAddress);

    this.apiHr.addLocation(newAddress).subscribe((e: any) => {
      console.log(e);
      this.deleteLegalById(this.addressToEdit);
      let form = {
        address_id: e.data.id,
      };

      this.apiVendor.updateVendor(form, this.vendor.id).subscribe((e) => {
        console.log(e);
        this.response = e.data;
        this.vendorApi.getVendorById(this.vendor.id).subscribe((e) => {
          console.log(e);
  
          this.vendor = e;
          this.segmentChanged('legal');
        });
      });
      });
      
  }

  editAddresses() {
    const addressArray = this.createVendorForm.get('addresses') as FormArray;
    const newAddress = addressArray.at(addressArray.length - 1).value;

    console.log(newAddress);

    this.apiHr.addLocation(newAddress).subscribe((e: any) => {
      console.log(e);
      console.log(this.addressToEdit);

      this.deleteAddressById(this.addressToEdit);

      let form = {
        company_id: this.vendor.id,
        address_id: e.data.id,
        name: newAddress.name,
      };
      this.apiHr.addLocationToCompany(form).subscribe((e: any) => {
        console.log(e);
        this.vendorApi.getVendorById(this.vendor.id).subscribe((e) => {
          console.log(e);

          this.vendor = e;
          this.segments = [];
          this.segments = [{ code: 'legal', name: 'Legale' }];
          this.vendor.addresses.forEach((e, index) => {
            this.segments.push({
              code: `address_${index}`,
              name: e.name,
            });
          });
          this.segments.push({ code: 'new', name: '+' });

          this.segmentChanged('legal');
        });
      });
    });
  }

  findAddressIdByAddressId(addressId: number): number | null {
    const foundAddress = this.vendor.addresses.find(
      (addr) => addr.address.id === addressId
    );
    return foundAddress ? foundAddress.id : null;
  }

  deleteAddress(address) {
    console.log(address);
    let id = this.findAddressIdByAddressId(address.value.id);
    console.log(id);
    this.apiHr.deleteLocationCompany(id).subscribe((e: any) => {
      console.log(e);
      this.vendorApi.getVendorById(this.vendor.id).subscribe((e) => {
        console.log(e);

        this.vendor = e;
        this.segments = [];
        this.segments = [{ code: 'legal', name: 'Legale' }];
        this.vendor.addresses.forEach((e, index) => {
          this.segments.push({
            code: `address_${index}`,
            name: e.name,
          });
        });
        this.segments.push({ code: 'new', name: '+' });

        this.segmentChanged('legal');
      });
    });
  }

  deleteAddressById(address) {
    console.log(address);
    let id = address;
    console.log(id);
    this.apiHr.deleteLocationCompany(id).subscribe((e: any) => {
      console.log(e);
      this.vendorApi.getVendorById(this.vendor.id).subscribe((e) => {
        console.log(e);

        this.vendor = e;
        this.segments = [];
        this.segments = [{ code: 'legal', name: 'Legale' }];
        this.vendor.addresses.forEach((e, index) => {
          this.segments.push({
            code: `address_${index}`,
            name: e.name,
          });
        });
        this.segments.push({ code: 'new', name: '+' });

        this.segmentChanged('legal');
      });
    });
  }

  deleteLegalById(address) {
    console.log(address);
    let id = address;
    console.log(id);
    this.apiHr.deleteLocationCompany(id).subscribe((e: any) => {
      console.log(e);
      this.vendorApi.getVendorById(this.vendor.id).subscribe((e) => {
        console.log(e);

        this.vendor = e;
        this.segments = [];
        this.segments = [{ code: 'legal', name: 'Legale' }];

        this.vendor.addresses.forEach((e, index) => {
          this.segments.push({
            code: `address_${index}`,
            name: e.name,
          });
        });
        this.segments.push({ code: 'new', name: '+' });

        this.segmentChanged('legal');
      });
    });
  }

  setTypeId(event) {
    let type = event.detail.value;
    console.log(type);
  }
  isEditing: boolean = false; // Variabile per il toggle
  isEditingAddresses: boolean = false; // Variabile per il toggle
  isDirty: boolean = false; // Variabile per monitorare le modifiche del form
  isEditButton = false;
  addressToEdit: any = [];
  onToggleEdit() {
    // this.toggleAddressControls(this.isEditing);
    this.addressToEdit = this.vendor.address.id;
    console.log(this.addressToEdit);

    this.presentAlertAddress();
  }

  onToggleEditAddresses(address: any) {
    // this.toggleAddressesControls(this.isEditingAddresses);
    this.addressToEdit = address.value.id;
    console.log(this.addressToEdit);

    this.presentAlertAddresses();
  }

  async presentAlertAddress() {
    const alert = await this.alertController.create({
      header: 'Attenzione',
      message:
        'Abilitando la modifica dovrai creare un indirizzo nuovo. Continuare?',
      buttons: [
        {
          text: 'Annulla',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          },
        },
        {
          text: 'Confermo',
          handler: () => {
            this.toggleAddressControls(true);
            this.isEditButton = true;
            this.segmentChanged('newLegal');
          },
        },
      ],
    });

    await alert.present();
  }
  async presentAlertAddresses() {
    const alert = await this.alertController.create({
      header: 'Attenzione',
      message:
        'Abilitando la modifica dovrai creare un indirizzo nuovo. Continuare?',
      buttons: [
        {
          text: 'Annulla',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          },
        },
        {
          text: 'Confermo',
          handler: () => {
            this.toggleAddressesControls(true);
            this.isEditButton = true;
            this.segmentChanged('new');
          },
        },
      ],
    });

    await alert.present();
  }
}
