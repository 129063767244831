// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper-item {
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: start;
  background: var(--ion-item-background, var(--ion-background-color, #fff));
}

/* Swiper container stylng */
::ng-deep .swiper {
  right: 0.3rem;
  width: 100%;
  height: 100%;
}

::ng-deep .swiper-slide {
  max-width: 124px !important;
  min-width: 100px !important;
  width: 102px !important;
  /*  border: 1px solid #151c20;
   border-radius: 3px;
   background-color: #182126; */
  display: contents !important;
}
::ng-deep .swiper-slide ion-button {
  margin-left: 7px;
  margin-top: 7px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/sub-menu/sub-menu.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,mBAAA;EACA,iBAAA;EACA,sBAAA;EACA,yEAAA;AACJ;;AAGA,4BAAA;AACA;EACI,aAAA;EACA,WAAA;EACA,YAAA;AAAJ;;AAIA;EAQI,2BAAA;EACA,2BAAA;EACA,uBAAA;EACD;;+BAAA;EAGC,4BAAA;AARJ;AAJI;EACI,gBAAA;EACA,eAAA;AAMR","sourcesContent":[".wrapper-item {\n    width: 100%;\n    flex-direction: row;\n    flex-wrap: nowrap;\n    justify-content: start;\n    background: var(--ion-item-background, var(--ion-background-color, #fff));\n}\n\n\n/* Swiper container stylng */\n::ng-deep .swiper {\n    right: .3rem;\n    width: 100%;\n    height: 100%;\n}\n\n\n::ng-deep .swiper-slide {\n\n    ion-button {\n        margin-left: 7px;\n        margin-top: 7px;\n    }\n    \n    //height: 40px !important;\n    max-width: 124px !important;\n    min-width: 100px !important;\n    width: 102px !important;\n   /*  border: 1px solid #151c20;\n    border-radius: 3px;\n    background-color: #182126; */\n    display: contents !important; // aggiunge 10px di padding sia a sinistra che a destra\n\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
