import { Component, Input, EventEmitter, OnInit, Output } from '@angular/core';


@Component({
  selector: 'app-empty-notification-third',
  templateUrl: './empty-notification-third.component.html',
  styleUrls: ['./empty-notification-third.component.scss'],
})
export class EmptyNotificationThirdComponent implements OnInit {

  @Input() text;
  @Input() nameImage;
  @Input() button;
  
  @Output()
  callBack: EventEmitter<string> = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
  }

  
  methodAction(action) {
    try {
      this[action]()
    } catch {
      console.log('not call in component, run callback, empty notification', action);
      this.callBack.emit(action)
    }
  }

}
