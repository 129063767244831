import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-sub-menu-filter',
  templateUrl: './sub-menu-filter.component.html',
  styleUrls: ['./sub-menu-filter.component.scss'],
})
export class SubMenuFilterComponent {

  @Input() accordions;
  constructor() { }

  ngOnInit() { }

}
