import { OrdersService } from 'src/app/services/orders/orders.service';
import { ToastController, ModalController } from '@ionic/angular';
import { Validators, FormBuilder, FormGroup, FormGroupDirective } from '@angular/forms';
import { Component, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-modal-order-upload-file',
  templateUrl: './modal-order-upload-file.component.html',
  styleUrls: ['./modal-order-upload-file.component.scss'],
})
export class ModalOrderUploadFileComponent {

  @Input() data: any = '';
  @Input() type: any = '';
  @Input() form: any = '';
  @Input() order_id: any = '';
  
  @ViewChild('createForm') createForm: FormGroupDirective;

  GroupForm: FormGroup;
  response = '';

  constructor(
    private fb: FormBuilder,
    private modalController: ModalController,
    private api: OrdersService,
    private toastController: ToastController
  ) { }

  ionViewWillEnter() {
    console.log(this.data);
    this.response = '';
    this.createBaseForm();
    
  }

  createBaseForm() {
    // Generete FormGroup Update Legal 
    this.GroupForm = this.fb.group({
      title: this.fb.control('', Validators.required),                  
    });    
  }
 

  submitForm() {
    this.createForm.onSubmit(undefined);
  }

  subForm(form) {
    //CallApi   
    if(this.type == 'legal'){
      if(this.form == 'update'){
        this.order_orderlegal_update(form.value,this.data.id, this.data.order);
      }
      if(this.form == 'create'){
        this.createLegal(form.value, this.order_id)
      }        
    }

  }

  createLegal(form,order) {
    this.api.createLegal(form, order).subscribe(e => {
      console.log(e);
      if (e.message && e.message.includes('OK')) {
        this.presentToast(e.message, 'success')
        this.response = 'OK';
        this.dismissModal();
      } else {
        this.response = 'KO';
      }
    })
  }

  order_orderlegal_update(form,id,order) {
    this.api.order_orderlegal_update(form, id, order).subscribe(e => {
      console.log(e);
      if (e.message && e.message.includes('OK')) {
        this.presentToast(e.message, 'success')
        this.response = 'OK';
        this.dismissModal();
      } else {
        this.response = 'KO';
      }
    })
  }

 /*  upload( item, formData, data) {
    this.api.upload(form, id, order).subscribe(e => {

    })

    let url = `${this.url}documan/upload`;

    if (isRenew) {

      console.log(formData, item, data);

      let element_id = data.element
      let file_id = data.id
      url = `${this.url}documan/element/${element_id}/renew/${file_id}/`

      console.log(url);

    }

    const req = new HttpRequest('POST', url, formData, {
      reportProgress: true,
      responseType: 'json'
    });

    return this.http.request(req);

  } */

  dismissModal(event?) {
    this.modalController.dismiss(this.response)

  }
  fabCallBack(action) {
    console.log(action);

    if (action) {
      this[action]()
    } else {
      console.log('not call in component, run callback', 'details');


    }
  }

  async presentToast(text, color) {
    const toast = await this.toastController.create({
      message: text,
      position: 'bottom',
      duration: 3000,
      cssClass: 'toast',
      color: color
    });
    return await toast.present();
  }


}
