/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable object-shorthand */
/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/semi */
/* eslint-disable @typescript-eslint/naming-convention */


import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Platform, ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { catchError, delay, distinctUntilChanged, map, retryWhen, scan, tap, timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../authentication/authentication.service';
import { NetworkService, ConnectionStatus } from '../network/network.service';
//import { Storage } from '@ionic/storage';
import { OfflineManagerService } from '../network/offline-manager.service';

//import environment from '../../../config.json';
import { Project } from 'src/app/projects/models/projects';
import { Docs } from 'src/app/documents/models/docs';
import { Vendor } from 'src/app/vendors/models/vendor.model';


const API_STORAGE_KEY = 'vendorsService';
const TOKEN_KEY = 'access-token';

@Injectable({
  providedIn: 'root'
})
export class VendorsService {


  vendors = new BehaviorSubject<any>({});
  private url: string = 'http';
  private token: any;
  private projects: Project[];
  private documents: Docs[];
  private document: Docs;
  private project_types: any[];
  //public docList = new BehaviorSubject(null);

  constructor(
    private storage: Storage,
    private plt: Platform,
    private http: HttpClient,
    public toastController: ToastController,
    private authService: AuthenticationService,
    private networkService: NetworkService,
    private offlineManager: OfflineManagerService
  ) {

    /**
     * Map url API
     */
    this.url = environment.SSL.active ? this.url + 's' : this.url
    this.url += '://' + environment.API_URI.url + ':' + environment.API_URI.port + environment.API_URI.path

    this.authService.currentToken.subscribe(x => {
      //console.log(x);
      this.token = x //= JSON.parse(localStorage.getItem(TOKEN_KEY))

    })
  }

  /**
   * 
   * VENDORS
  */


  searchVendor(forceRefresh: boolean = true, url: string, string: string): Observable<any> {

    this.logSys('searchVendor', { forceRefresh, url, string })

    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline || !forceRefresh) {
      // Return the cached data from Storage
      return from(this.getLocalData('search-vendor'));
    } else {
      let body = {
        txt: string.toString()
      };

      let params = JSON.parse(string)
      return this.http.get<any>(`${this.url}company/companies/?name=${params.data}`)
        //return this.http.get<any>(`${this.url}external/companies/${url}&name=${string.toString()}`)
        .pipe(
          timeout(12000),
          tap(data => {
            console.log(data);
            this.setLocalData('search-vendor', data);
            return data
          }),
          catchError(this.handleError<any[]>('Search vendor', []))
        );
    }

  }




  /**
  * 
  *
  * @returns list vendors
  */
  getVendorsList(params?): Observable<any> {
    params == undefined ? params = '' : null
    //return this.http.get<any>(url)
    return this.http.get<any>(`${this.url}order/vendors/${params}&is_active=all`)
      .pipe(
        timeout(50000),
        tap(data => {
          console.log(data);
          this.setLocalData('list', data);
          return data
        }),
        catchError(this.handleError<any[]>('Get vendors list', []))
      );

  }

  getVendorsListNoActive(params?): Observable<any> {
    params == undefined ? params = '' : null
    //return this.http.get<any>(url)
    return this.http.get<any>(`${this.url}order/vendors/${params}`)
      .pipe(
        timeout(50000),
        tap(data => {
          console.log(data);
          this.setLocalData('list', data);
          return data
        }),
        catchError(this.handleError<any[]>('Get vendors list', []))
      );

  }

  /**
* 
*
* @returns list companies
*/
  getCompaniesList(params?): Observable<any> {
    params == undefined ? params = '' : null
    //return this.http.get<any>(url)
    return this.http.get<any>(`${this.url}company/companies/${params}`)
      .pipe(
        timeout(50000),
        tap(data => {
          console.log(data);
          this.setLocalData('list', data);
          return data
        }),
        catchError(this.handleError<any[]>('Get vendors list', []))
      );

  }


  getCompaniesTypes(params?): Observable<any> {
    params == undefined ? params = '' : null
    //return this.http.get<any>(url)
    return this.http.get<any>(`${this.url}company/types/${params}`)
      .pipe(
        timeout(50000),
        tap(data => {
          console.log(data);
          this.setLocalData('list', data);
          return data
        }),
        catchError(this.handleError<any[]>('Get companies types list', []))
      );

  }


  /**
* 
*
* @returns list address
*/
  getAddressesList(params?): Observable<any> {
    params == undefined ? params = '' : null
    //return this.http.get<any>(url)
    return this.http.get<any>(`${this.url}geo/locations/`)
      .pipe(
        timeout(50000),
        tap(data => {
          console.log(data);
          this.setLocalData('list', data);
          return data
        }),
        catchError(this.handleError<any[]>('Get addresses list', []))
      );

  }


  /**
* 
*
* @returns list address types
*/
  getTypeAddressesList(params?): Observable<any> {
    params == undefined ? params = '' : null
    //return this.http.get<any>(url)
    return this.http.get<any>(`${this.url}geo/locationtypes/`)
      .pipe(
        timeout(50000),
        tap(data => {
          console.log(data);
          this.setLocalData('list', data);
          return data
        }),
        catchError(this.handleError<any[]>('Get addresses types list', []))
      );

  }


  /**
* 
*
* @returns list address types
*/
  getCountriesList(): Observable<any> {
    //return this.http.get<any>(url)
    return this.http.get<any>(`${this.url}geo/countries/`)
      .pipe(
        timeout(50000),
        tap(data => {
          console.log(data);
          this.setLocalData('list', data);
          return data
        }),
        catchError(this.handleError<any[]>('Get countries list', []))
      );

  }

    /**
   * 
   * @param address 
   * @returns 
   */

    createAddress(address) {
      console.log(address)
      return this.http.post<any>(`${this.url}geo/locations/`, address)
        .pipe(
          tap(data => {
            console.log(data);
          }),
          catchError(this.handleError<any[]>('Create address', []))
        );
    }



  /**
 * 
 * @param id id of vendor
 * @returns vendor
 */
  getVendorById(id): Observable<any> {
    console.log(id)
    return this.http.get<any>(`${this.url}company/company/${id}/`)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Get vendor by id', []))
      );

  }


  /**
   * 
   * @param vendor 
   * @returns 
   */

  createVendor(vendor: Vendor) {
    console.log(vendor)
    return this.http.post<any>(`${this.url}company/companies/`, vendor)
      .pipe(
        tap(data => {
          console.log(data);
        }),
        catchError(this.handleError<any[]>('Create vendor', []))
      );
  }

  createContactVendor(contact:any) {
    console.log(contact)
    return this.http.post<any>(`${this.url}company/contacts/`, contact)
      .pipe(
        tap(data => {
          console.log(data);
        }),
        catchError(this.handleError<any[]>('Create vendor', []))
      );
  }

  updateVendor(vendor: any, id) {
    console.log(vendor)
    return this.http.put<any>(`${this.url}company/company/${id}/`, vendor)
      .pipe(
        tap(data => {
          console.log(data);
        }),
        catchError(this.handleError<any[]>('Update vendor', []))
      );
  }

  updateVendorAddress(form: any, id) {
    console.log(form)
    return this.http.put<any>(`${this.url}company/address/${id}/`, form)
      .pipe(
        tap(data => {
          console.log(data);
        }),
        catchError(this.handleError<any[]>('Update vendor address', []))
      );
  }



  getTypesList(): Observable<any[]> {

    //return this.http.get<any>(url)
    return this.http.get<any>(`${this.url}company/types/`)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          this.setLocalData('types', data);
          return data
          //this.project_types = data;
        }),
        catchError(this.handleError<any[]>('Get vendor types list', []))
      );
  }


  getContactsTypesList(): Observable<any[]> {

    //return this.http.get<any>(url)
    return this.http.get<any>(`${this.url}company/contacttypes/`)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          // this.setLocalData('types', data);
          return data
          //this.project_types = data;
        }),
        catchError(this.handleError<any[]>('Get vendor types list', []))
      );
  }



  private handleError<T>(operation = 'operation', result?: T) {

    return (error: any): Observable<T> => {

      //console.error(error);
      console.log(result, error);

      console.log(`${operation} failed`, error);
      const status = error == 'Forbidden' ? 403 : 500
      this.presentToast(status, error == 'undefined' ? 'Internal Server Error' : error, operation)
      return of(result as T);
    };
  }


  async presentToast(status, statusText, message) {
    const toast = await this.toastController.create({
      message: status + ' ' + statusText + ': "' + message + '"',
      duration: 2000,
      mode: 'ios',
      cssClass: 'toast',
      color: status != '200' ? 'danger' : 'primary'
    });
    toast.present();
  }

  logSys(src, status, opt?) {
    const debugStyle1 = 'background: linear-gradient(135deg,#471ee9,#3a49b7); border: 1px solid #9a9a9a; color: #ffffff; border-bottom-left-radius: 2px; border-top-left-radius: 2px; padding: 2px 0 2px 4px;';
    const debugStyle2 = 'background: #252b3e; border: 1px solid #9a9a9a; border-top-right-radius: 2px; border-bottom-right-radius: 2px; margin-left: -2px; padding: 2px 4px; color: white;';

    //console.log('ForceRefresh API ', status, this.networkService.getCurrentNetworkStatus(), this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline);
    console.log(`%cApiService %c%s`, debugStyle1, debugStyle2, ' ' + src, status);
  }




  // Save result of API requests
  private setLocalData(key, data) {
    this.storage.set(`${API_STORAGE_KEY}-${key}`, data);
  }

  // Get cached API result
  private getLocalData(key) {
    return this.storage.get(`${API_STORAGE_KEY}-${key}`);
  }

  setVendors(data: any) {
    return this.vendors.next(data);
  }

  getVendors(): Observable<any> {
    return this.vendors.asObservable();
  }

}
