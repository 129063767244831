import { Component, ElementRef, OnInit } from '@angular/core';
import { IonRange } from '@ionic/angular';
import { debounceTime } from 'rxjs/operators';
import { OrdersService } from 'src/app/services/orders/orders.service';

@Component({
  selector: 'app-range',
  templateUrl: './range.component.html',
  styleUrls: ['./range.component.scss'],
})
export class RangeComponent implements OnInit {

  constructor(
    private orderApi: OrdersService
  ) {

  }
  min = 10
  max = 150
  value = 500

  upperValue: number;
  lowerValue: number;
  changedKnob: string;
  timer = undefined

  ngOnInit() {
    this.lowerValue = 33;
    this.upperValue = 60;
    const dualRange = document.querySelector('#dual-range') as HTMLIonRangeElement;
    dualRange.value = { lower: this.lowerValue, upper: this.upperValue };
  }

  knobChange(range: any) {
    console.log(range);
    this.timer ? clearTimeout(this.timer) : null

    if (range.detail.value['lower'] !== this.lowerValue) {
      this.lowerValue = range.detail.value['lower'];
      this.changedKnob = 'lower';
    } else if (range.detail.value['upper'] !== this.upperValue) {
      this.upperValue = range.detail.value['upper'];
      this.changedKnob = 'upper';
    }

    this.timer = setTimeout(() => {
      this.orderApi.tmp_rangePrice(false, this.lowerValue, this.upperValue).subscribe(e => {
        console.log(e);

      })
    }, 700);
  }

  afterChange(event) {
    console.log(event);

    if (this.changedKnob === 'upper') {
      console.log('upper is changed. value : ' + this.upperValue);
    } else {
      console.log('lower is changed. value : ' + this.lowerValue);
    }


  }

}
