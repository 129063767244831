import { Component, ElementRef, OnChanges, Input, OnInit, Renderer2, SimpleChanges, ViewChild, HostListener, AfterViewInit } from '@angular/core';
import { IonSearchbar, ModalController, Platform } from '@ionic/angular';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { Order } from 'src/app/orders/models/order.model';
import { ProductDetailsPage } from 'src/app/products/product-details/product-details.page';
import { PlatformService } from 'src/app/services/utils/platform/plarformservice';
import { DdtDetailsPage } from 'src/app/trasport_documents/ddt-details/ddt-details.page';
import { VendorDetailsPage } from 'src/app/vendors/vendor-details/vendor-details.page';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
})
export class TimelineComponent implements OnInit, OnChanges {

  @ViewChild('list') list: ElementRef
  @ViewChild('timeline') timeline: ElementRef
  @ViewChild('myInput') myInput: IonSearchbar
  @Input() status
  @Input() listStatus

  filterTerm: string;
  sumQntAvailable = 0
  enableSearch = true;
  enableCompareQuantity = false

  dir
  val


  revertSort = true;
  delayRevertSort = true;

  emitSort = new Subject<boolean>()
  emitNav = new Subject<boolean>()

  timerClick = null
  elements
  keys_elements
  row_elements
  objectKeys = Object.keys;
  /*   config = [
      { type: 'icon', label: 'not started', class: '', shape: 'circle', btns: [{ text: 'Refresh', func: 'refreshList' }], img: '', desc: 'Root CA certificate requested.' },
      { type: 'spinner', label: 'Current', class: '', shape: 'dot-circle', desc: 'Root CA certificate requested. Upload it to the KMS to complete the connection.' },
      { type: 'icon', label: 'In progress', class: '', shape: '', desc: 'Root CA certificate requested. Upload it to the KMS to complete the connection. Third sentence is very long and very long.' },
      { type: 'icon', label: 'Completed', class: 'is-success', shape: 'plus-circle', desc: 'Upload it to the KMS to complete the connection. Thirdsentence.' },
      { type: 'icon', label: 'Error', class: 'is-error', shape: 'minus-circle', desc: 'No. Its not connected.' }
    ] */

  downloadingFile = false
  isMobile

  constructor(
    private modalController: ModalController,
    private renderer: Renderer2,
    private plt: Platform,
    private utils: PlatformService
  ) {

    var width = window.innerWidth
      || document.documentElement.clientWidth
      || document.body.clientWidth;

    this.isMobile = width < 530

    this.utils.autoWidth.subscribe(e => {
      this.isMobile = e < 530
    })

    
  }

  @HostListener('wheel', ['$event'])
  onMouseWheel(event: WheelEvent) {
    const delta = Math.sign(event.deltaY);
    this.timeline.nativeElement.scrollLeft += delta * 50;

  }


  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);
    if (changes['listStatus'] && changes['listStatus'].currentValue) {
      this.listStatus = []
      this.listStatus = changes['listStatus'].currentValue
    }
  }

  ngOnInit() {
    this.elements = [] /* [{
      "id": 2,
      "ddt": {
        "id": 1,
        "type": "IN",
        "status": "NEXT",
        "store": {
          "id": 1,
          "name": "Magazzino ",
          "description": "",
          "locations": ".",
          "deleted": false,
          "created": "2022-09-27T17:08:15.317993+02:00",
          "parent": null
        },
        "reference": {
          "id": 1678,
          "type": {
            "id": 3,
            "name": "SocietÃ  per Azioni",
            "code": "SPA",
            "created": "2022-05-30T13:15:23+02:00"
          },
          "name": " THALES DIS ITALIA S.p.A.",
          "code": "104",
          "code_vat": "09502100150",
          "code_fiscal": "09502100150",
          "code_unique": "",
          "code_iban": "direzione.thales.dis-italia@pec.it",
          "address": [
            {
              "name": "legal",
              "position": {
                "street": "Via Pio Emanuelli",
                "number": " 1 – Edificio B",
                "city": "ROMA",
                "cap": "00143",
                "region": "",
                "province": "RM",
                "nation": ""
              }
            }
          ],
          "contact": [],
          "is_customer": true,
          "is_vendor": false
        },
        "code": "IN00000000001",
        "external_code": "0001",
        "description": "tet",
        "date_request": "2022-12-01T19:43:40.495660+01:00",
        "date_execute": "2022-12-01T19:43:40.495666+01:00",
        "actor": 1
      },
      "product": {
        "id": 1,
        "type": 1,
        "category": 1,
        "name": "Sonda Temp Dallas",
        "code": "1",
        "description": ".",
        "udm": "pz",
        "default_price": "3",
        "brands": [
          {
            "id": 1,
            "name": "RS Componets",
            "code": "1",
            "deleted": false,
            "created": "2022-06-27T10:40:02+02:00"
          }
        ],
        "vendors": [
          {
            "id": 1,
            "name": "Cool Projects SRL.",
            "code": "CP",
            "code_vat": "10577971004",
            "code_fiscal": "10577971004",
            "code_unique": "SUBM70N",
            "code_iban": "IT",
            "address": [],
            "contact": [],
            "is_customer": true,
            "is_vendor": true,
            "created": "2022-05-31T05:56:34+02:00",
            "type": 1
          }
        ]
      },
      "quantity": "50",
      "created": "10/12/2022"
    }, {
      "id": 3,
      "ddt": {
        "id": 1,
        "type": "IN",
        "status": "NEXT",
        "store": {
          "id": 1,
          "name": "Magazzino ",
          "description": "",
          "locations": ".",
          "deleted": false,
          "created": "2022-09-27T17:08:15.317993+02:00",
          "parent": null
        },
        "reference": {
          "id": 1678,
          "type": {
            "id": 3,
            "name": "SocietÃ  per Azioni",
            "code": "SPA",
            "created": "2022-05-30T13:15:23+02:00"
          },
          "name": " THALES DIS ITALIA S.p.A.",
          "code": "104",
          "code_vat": "09502100150",
          "code_fiscal": "09502100150",
          "code_unique": "",
          "code_iban": "direzione.thales.dis-italia@pec.it",
          "address": [
            {
              "name": "legal",
              "position": {
                "street": "Via Pio Emanuelli",
                "number": " 1 – Edificio B",
                "city": "ROMA",
                "cap": "00143",
                "region": "",
                "province": "RM",
                "nation": ""
              }
            }
          ],
          "contact": [],
          "is_customer": true,
          "is_vendor": false
        },
        "code": "IN00000000001",
        "external_code": "0001",
        "description": "tet",
        "date_request": "2022-12-01T19:43:40.495660+01:00",
        "date_execute": "2022-12-01T19:43:40.495666+01:00",
        "actor": 1
      },
      "product": {
        "id": 1,
        "type": 1,
        "category": 1,
        "name": "Sonda Temp Dallas",
        "code": "1",
        "description": ".",
        "udm": "pz",
        "default_price": "3",
        "brands": [
          {
            "id": 1,
            "name": "RS Componets",
            "code": "1",
            "deleted": false,
            "created": "2022-06-27T10:40:02+02:00"
          }
        ],
        "vendors": [
          {
            "id": 1,
            "name": "Cool Projects SRL.",
            "code": "CP",
            "code_vat": "10577971004",
            "code_fiscal": "10577971004",
            "code_unique": "SUBM70N",
            "code_iban": "IT",
            "address": [],
            "contact": [],
            "is_customer": true,
            "is_vendor": true,
            "created": "2022-05-31T05:56:34+02:00",
            "type": 1
          }
        ]
      },
      "quantity": "150",
      "created": "13/12/2022"
    }] */
    setTimeout(() => {
      
      console.log(this.listStatus);
      

    }, 1300);
  }


  revert() {
    this.revertSort = !this.revertSort
    setTimeout(() => {
      this.delayRevertSort = !this.delayRevertSort
    }, 230);
    //this.elements = this.elements.reverse()
    this.emitSort.next(this.revertSort)
  }

  sumQuantity(e) {
    //console.log(e.ddt.type, e.quantity);
    if (e.ddt.type == 'OUT') {
      this.sumQntAvailable -= Number(e.quantity)
    } else if (e.ddt.type == 'IN') {
      this.sumQntAvailable += Number(e.quantity)
    }
  }

  async clickVendor(e) {
    ////nav: this.nav(StoresDetailsPage, {  store: item }, { animated: true, direction: 'forward' });
    const modal = await this.modalController.create({
      component: VendorDetailsPage,
      canDismiss: true,
      mode: 'md',
      cssClass: 'newOrderModalForm',
      componentProps: {
        id: e,
        modal: true
        ////nav: this.nav  // Pass nav for IonNav from modal if necessary
      },
      //presentingElement: this.routerOutlet.nativeEl
    })


    modal.onWillDismiss().then((e) => {
      console.log(e);

      if (e.data) {

        //this.myInput.value = "";

      }
    });
    return await modal.present();
  }

  async clickProduct(e) {
    ////nav: this.nav(StoresDetailsPage, {  store: item }, { animated: true, direction: 'forward' });
    const modal = await this.modalController.create({
      component: ProductDetailsPage,
      canDismiss: true,
      mode: 'md',
      cssClass: 'newOrderModalForm',
      componentProps: {
        id: e,
        modal: true
        ////nav: this.nav  // Pass nav for IonNav from modal if necessary
      },
      //presentingElement: this.routerOutlet.nativeEl
    })


    modal.onWillDismiss().then((e) => {
      console.log(e);

      if (e.data) {

        //this.myInput.value = "";

      }
    });
    return await modal.present();
  }

  async clickDdt(e) {
    ////nav: this.nav(StoresDetailsPage, {  store: item }, { animated: true, direction: 'forward' });
    const modal = await this.modalController.create({
      component: DdtDetailsPage,
      canDismiss: true,
      mode: 'md',
      cssClass: 'newOrderModalForm',
      componentProps: {
        ddt: e,
        modal: true
        ////nav: this.nav  // Pass nav for IonNav from modal if necessary
      },
      //presentingElement: this.routerOutlet.nativeEl
    })


    modal.onWillDismiss().then((e) => {
      console.log(e);

      if (e.data) {

        //this.myInput.value = "";

      }
    });
    return await modal.present();
  }

  cancelSearch(event) {
    console.log(event);
    //this.elements = this.groupByMonthWeek(this.row_elements)
    this.enableCompareQuantity = false
  }

  onChangeSearch(event) {
    /* console.log(event.which);
    if ((event.which <= 90 && event.which >= 48) || event.which == 8 || event.which == 46 || event.which == 189)  */{
      // If alphanumeric or back or del or / (slash)

      setTimeout(() => {

        /*  console.log(this.list.nativeElement.children);
            console.log(this.list.nativeElement.children[0].children[0].children);
            console.log(this.list.nativeElement.children[0].children[0].children[1]);
            console.log(this.list.nativeElement.children[0].children[0].children[1].children[0].children[0].children[1]);
            console.log(this.list.nativeElement.children[0].children[0].children[1].children[1].children[0].children[1]);
            console.log(this.list.nativeElement.children[0].children[0].children[1].children[2].children[0].children[1]);
  
            console.log(this.list.nativeElement);
  
            console.log('this.list.nativeElement.children[0].children[0].children[1].children',
              this.list.nativeElement.children[0].children[0].children[1].children);
  
            console.log('this.list.nativeElement.children[0].children[1].children[1].children',
              this.list.nativeElement.children[1].children[0].children[1].children); 
        */


        [...this.list.nativeElement.children].forEach((_e, _i) => {
          console.log(_e);

          [..._e.children[0].children[1].children].forEach((e, i) => {

            /*   
              console.log('----------------------------------------start');
              console.log(e, i);
              console.log('-', e.children);
              console.log('--', e.children[0]);
              console.log('---', e.children[0].children[1].children); 
            */

            if (e.children[0].children[1].children.length == 0) {
              this.renderer.setStyle(e.children[0], 'display', 'none')
            } else {
              this.renderer.setStyle(e.children[0], 'display', 'block')
            }
          });
        });

      }, 200);
    }
  }

  getMontName(number) {
    var months = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"];
    return months[Number(number) - 1];
  }

  resolveQuantity(dir, value) {
    //console.log(-value);
    this.elements = this.elements.filter((e:any) => {
      console.log(e, Number(e.quantity));
      if (dir == 'gt') {
        return Number(e.quantity) >= value
      } else if (dir == 'lt') {
        return Number(e.quantity) <= value
      }
      else
        return value
    })

    this.filterTerm = ''
    //console.log(this.elements);
  }

  groupByMonthWeek(data) {
    var year, month, week
    return data.reduce(function (acc, obj) {

      var b = obj.created.split(/\D/);

      // Get custom week number, zero padded
      var weekNum = '0' + Math.ceil(b[2] / 7);

      // Add year if not already present
      if (!acc[b[0]]) acc[b[0]] = {};
      year = acc[b[0]];

      // Add month if not already present
      if (!year[b[1]]) year[b[1]] = {};
      month = year[b[1]];

      // Add week if not already present
      if (!month[weekNum]) month[weekNum] = [];

      // Format for sinply find on searchbar
      obj.created = moment(new Date(obj.created)).format('DD/MM/YYYY')

      // Add object to  week
      month[weekNum].push(obj);

      //console.log(acc);

      return acc;
    }, Object.create(null));
  }


}
