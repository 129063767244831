// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.step-content-label {
  margin-bottom: 16px;
}

.step-complete-btn {
  padding: 10px 20px 10px 10px;
  border-radius: 50px;
  border: none !important;
  color: black;
  background: #99ffa9;
  display: flex;
  align-items: center;
}

.completed-step-check {
  margin-right: 12px;
  color: white !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/steps/step-template/step-template.component.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;AACJ;;AAEA;EACI,4BAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,uBAAA;AACJ","sourcesContent":[".step-content-label {\n    margin-bottom: 16px;\n}\n\n.step-complete-btn {\n    padding: 10px 20px 10px 10px;\n    border-radius: 50px;\n    border: none !important;\n    color: black;\n    background: #99ffa9;\n    display: flex;\n    align-items: center;\n}\n\n.completed-step-check {\n    margin-right: 12px;\n    color: white !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
