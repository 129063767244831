import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { IonInput, IonSegment, ModalController, PopoverController, ToastController } from '@ionic/angular';
import { InvoicesService } from 'src/app/services/invoices/invoices.service';
import { DdtService } from 'src/app/services/trasport_documents/ddt.service';


@Component({
  selector: 'app-ddt-line',
  templateUrl: './ddt-line.component.html',
  styleUrls: ['./ddt-line.component.scss'],
})
export class DdtLineComponent implements OnInit {

  site;
  response;
  @Input() ddt;
  @Input() line;
  @Input() type;
  @Input() discount;

  @ViewChild('inputEl', { static: false }) inputEl: IonInput;
  @ViewChild('createForm') createForm: FormGroupDirective;
  @ViewChild('seg') seg: IonSegment;
  createLine: FormGroup;
  types: any = [];

  constructor(
    private fb: FormBuilder,
    private toastController: ToastController,
    private popoverController: PopoverController,
    private modalController: ModalController,
    private ddtApi: DdtService
  ) { }

  ngOnInit() { }

  get_f(control, prop) {
    if (!this.createLine)
      return

    return this.createLine.controls[control][prop];
  }

  getMin() {
    let min = 1;   
    return min
  }

  getMax() {      
    let max = this.line.quantity - this.line.completed    
    return max
  }

  getQtyHypotheticallyArrived(){
    return (this.line.quantity - this.line.completed < 0 ) ? 0 : this.line.quantity - this.line.completed;
  }

  createBaseForm() {

    // Generete FormGroup Create Vendor 
    this.createLine = this.fb.group({
      id: this.fb.control(this.line.id, Validators.required),
      ddt_id: this.fb.control(this.ddt.id, Validators.required),
      value: this.fb.control(this.getQtyHypotheticallyArrived(), [Validators.min(this.getMin()), Validators.max(this.getMax()), Validators.required]),      
    })

    console.log(this.line.quantity);
    console.log(this.line.completed);
    

    if (this.type == 'line')
      this.createLine.addControl('line_id', new FormControl(this.line.id, Validators.required))



    this.createLine.controls['id'].disable()
    this.createLine.controls['ddt_id'].disable()

    /* if (this.line['complete']['type'])
      this.createLine.controls['type'].disable()
    else
      this.createLine.controls['type'].setValue('P') */


      this.createLine.controls['value'].valueChanges.subscribe(e => {

      })

    /* this.createLine.controls['type'].valueChanges.subscribe(e => {
      console.log(e);

      this.createLine.controls['value'].removeValidators([Validators.min(this.getMin()), Validators.max(this.getMax())])

      setTimeout(() => {
        this.createLine.controls['value'].clearValidators()
        this.createLine.controls['value'].addValidators([Validators.min(this.getMin()), Validators.max(this.getMax(e))])

      }, 100);


    }) */

    this.createLine.valueChanges.subscribe(val => {
      console.log(val);
      if(this.getQtyHypotheticallyArrived() != 0 && parseInt(val.value)){
        console.log('ERRORE');
        
      }

      console.log(this.createLine);
      console.log(this.createLine.controls);


    })

  }

  submitForm() {
    console.log('onSubmit');

    //this.createLine.controls['type'].enable()
    this.createForm.onSubmit(undefined);
  }


  createFormLineDdt(values: any) {
    // copy all the form values into the new vendor
    const obj = { ...values };
    //newDiscount.created = moment(newDiscount.created).format('YYYY-MM-DD')

    console.log(this.line, this.ddt, obj);
    const obj_ = {}
    obj_['quantity'] = obj.value;
    this.ddtApi.craeteAdvance(this.line, this.ddt, obj_).subscribe(e => {
      console.log(e);

      if (e.message && e.message.includes('OK')) {
        this.presentToast(e.message, 'success')
        //e.data['type'] = e.data.advance_type;
        //console.log(e.data);

        this.response = e.data
        this.dismissModal();
      } else {
        console.log('no modify');

      }

    }) 

    console.log(obj);
  }




  ionViewWillEnter(response) {

    //console.log('input ddt', this.ddt);
    //console.log('input type', this.type);
    //console.log('input line', this.line);

    this.getMax();

    this.createBaseForm();

    /* this.ddtApi.getListTypeAdvance().subscribe(e => {
      this.types = e;
    }) */

    if (this.type == 'line') {

      console.log('-----------------line-------------------');

      setTimeout(() => {
        this.inputEl.setFocus()
      }, 500);

      //let filter = max.filter(e => e.approved)

      //this.discounts.push(...this.line.discounts)

      //this.approved = filter;

      //let a = this.groupArrayOfObjects(this.discounts, 'vendor')
      //let b = this.groupArrayOfObjects(this.discounts, 'version')

      //console.log(a);
      //console.log(b);



    } else if (this.type == 'global') {



    }


  }
  ionViewDidEnter(response) {
  }


  dismissModal() {
    this.modalController.dismiss(this.response);
  }



  async presentToast(text, color) {
    const toast = await this.toastController.create({
      message: text,
      position: 'bottom',
      duration: 3000,
      cssClass: 'toast',
      color: color
    });
    return await toast.present();
  }

}
