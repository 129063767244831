import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { AlertController, IonContent, IonImg, IonInfiniteScroll, ModalController, Platform, ToastButton, ToastController } from '@ionic/angular';
import { Observable, of } from 'rxjs';
import { ModalInfoFileComponent } from 'src/app/components/media/modal-info-file/modal-info-file.component';
import { ApiService } from 'src/app/services/api/api.service';
import { IonLoaderService } from 'src/app/services/ion-loader/ion-loader.service';
import { OrdersService } from 'src/app/services/orders/orders.service';
import { UploadDesktopComponent } from '../upload-desktop/upload-desktop.component';
import { FileSaverService } from 'ngx-filesaver';

@Component({
  selector: 'app-list-file',
  templateUrl: './list-file.component.html',
  styleUrls: ['./list-file.component.scss'],
})
export class ListFileComponent implements AfterViewChecked, AfterViewInit {

  // Lista 
  @Input() fileInfos
  @ViewChildren('itemElement', { read: ElementRef }) itemElement: QueryList<ElementRef>

  // TRUE or FALSE mostra file in griglia o in lista
  @Input() showGrid;
  //@Input() 
  @Input() module: any; // il modulo che accede e chiamo al componente
  @Input() item: any;
  @Input() scrollTop: any;
  @Output() page = new EventEmitter();
  @ViewChild('divToScroll') divToScroll: ElementRef;

  total_page = 0;
  page_number = 1;
  page_limit = 30;
  files: any = []
  isSearch: boolean = false;

  constructor(
    private api: ApiService,
    private apiOrders: OrdersService,
    private cd: ChangeDetectorRef,
    private renderer: Renderer2,
    private platform: Platform,
    private alertController: AlertController,
    private modalController: ModalController,
    private ionLoaderService: IonLoaderService,
    private toastController: ToastController,
    private _FileSaverService: FileSaverService
  ) {
    this.showGrid = false;
  }



  ngAfterViewInit(): void {
    console.log('List ngAfterViewInit');

    console.log(this.item);
    console.log(this.files);
    this.page_number = 1;
    this.total_page = 0;

    if (this.platform.is('desktop')) this.getFiles(false, '');


  }
  ionViewWillEnter() {
    console.log('List ionViewWillEnter');
    console.log(this.item);
    console.log(this.files);

    //
  }

  ionViewDidEnter() {

  }
  ngAfterViewChecked() {
    //this.fileInfos = this.files
    //console.log('List ngAfterViewChecked');
    //this.cd.detectChanges();
  }


  getFiles(isFirstLoad, event) {
    console.log(this.item, event);


    let url = '?page=' + this.page_number + '&items=' + this.page_limit;

    this.apiOrders.order_order_documents_list(this.module.data.id).subscribe(e => {

      /*   console.log(e);
 
        this.total_page = e.num_pages;
        if (e.num_pages >= (this.page_number)) {
          for (let i = 0; i < e.results.length; i++) { */
      this.fileInfos = [...e.data];
      /*    }
         */

      //event.target.disabled = true;

      /* 
            this.page_number++;
            //this.cd.detectChanges();
  
          } else {
            console.log('last page');
            event.target ? event.target.complete() : null;
  
          }
          console.log(this.files); */

      /* 
            this.files = e
            this.page_number++ */
    });


  }

  doInfinite(event, search?) {

    event.target.complete();
    // console.log(event, search);
    if (this.isSearch) {
      //this.getVendors(true, event, 0, search.value)
    } else {

    }
  }

  onError(event: Event) {
    //console.log(event['src']);
    //let src = event['src'].substring(event['src'].length - 5, event['src'].length)

    if (event['src']) { }


    /* this.renderer.removeChild(this.itemElement.first.nativeElement, event['el'])
    
    console.log(event['el']);
    console.log(this.itemElement);
    

    let col = this.renderer.createElement('ion-col');
    let img = this.renderer.createElement('ion-img');
    //let span = this.renderer.createElement('span');
    //let text = this.renderer.createText('Child');
    //this.renderer.appendChild(span, text);
    this.renderer.appendChild(col, img);
    this.itemElement['_result'].push(img)
    this.renderer.appendChild(this.itemElement.last.nativeElement, img) */
  }


  selectItem(item) {
    this.ionLoaderService.simpleLoader();

    console.log('list-file getdoc', item);
    console.log(this.module);

    if (this.module.type == 'orders') {

      // Chiamo l'api docs by order_id e doc_id
      this.apiOrders.order_order_document_read(this.module.data.id, item.id).subscribe(res => {

        console.log(res, item);
        this.fileInfos = res
        this.ionLoaderService.dismissLoader();

        if (res.data && res.data.id && item) {


          //this.openFileInfosModal(res.data, item, item);

        } else {

          console.log('else openFileModal');
          this.ionLoaderService.dismissLoader();
          this.presentToast('Error get file.', 'danger');

        }
      })
    } else {

      // Chiamo l'api fileInfos
      this.api.getDocumentInfo(true, '', item.id).subscribe(data => {
        console.log(data, item);

        if (data.length > 0 && item) {

          this.openFileInfosModal(data, item, item);

        } else {

          console.log('else openFileModal');
          this.ionLoaderService.dismissLoader();
          this.presentToast('Error get file.', 'danger');

        }
      })
    }


    this.ionLoaderService.dismissLoader();
  }


  downloadFile(item) {
    this.ionLoaderService.simpleLoader();

    console.log('list-file getdoc', item);
    console.log(this.module);

    if (this.module.type == 'orders') {

      // Chiamo l'api docs download  by order_id e doc_id
      this.apiOrders.order_order_document_download_list(this.module.data.id, item).subscribe(res => {

        console.log(res, item);
        this.ionLoaderService.dismissLoader();

        this._FileSaverService.save(res.body, item.name+this.getFileExtension(item.orderfile));



        if (res && res.data.id && item) {

          //this.openFileInfosModal(res.data, item, item);

        } else {

          console.log('else openFileModal');
          this.ionLoaderService.dismissLoader();
          this.presentToast('Error get file.', 'danger');

        }
      })
    } else {
      this.ionLoaderService.dismissLoader();


    }
    this.ionLoaderService.dismissLoader();

  }

  getFileExtension(filename) {
    const lastIndex = filename.lastIndexOf('.');
    
    // Se un punto è presente nella stringa e non è il primo carattere
    if (lastIndex !== -1 && lastIndex !== 0) {
      return filename.substring(lastIndex); // Restituisce la stringa dal punto in poi
    } else {
      return ''; // Restituisce una stringa vuota se non c'è un'estensione
    }
  }


  async presentPromntName(ev?: any) {


    const alert = await this.alertController.create({
      cssClass: 'alert-input-modal',
      header: 'Name file',
      //subHeader: 'insert name file',
      //message: '',
      //mode:'ios',
      inputs: [
        {
          placeholder: 'Name ',
          cssClass: 'input-text-modal',
          attributes: {
            maxlength: 25,
          },
        },
        {
          placeholder: 'Code ',
          cssClass: 'input-text-modal',
          attributes: {
            maxlength: 25,
          },
        }
      ],
      buttons: [
        /*  {
           text: 'Cancel',
           role: 'cancel',
           cssClass: 'secondary',
           handler: () => {
             console.log('Confirm Cancel');
           }
         },  */
        {
          text: 'Cancel',
          handler: (e) => {
            console.log('Undo');
            //path != 'update' ? this.router.navigateByUrl(path) : this.market.open(environment.appId);


          }
        },
        {
          text: 'Upload',
          handler: (e) => {
            console.log(e);

            console.log('Confirm Ok', e, e[0]);
            //path != 'update' ? this.router.navigateByUrl(path) : this.market.open(environment.appId);

            //this.nameFile = e[0]
            //this.codeFile = e[1]

            //this.uploadFiles()

          }
        }
      ]
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);


  }
  async openFileDeleteModal(infos, item?, file?) {
    this.apiOrders.order_order_document_delete(this.module.data.id, infos).subscribe(res => {

      console.log(res, infos);
      this.getFiles(true, {})

    })
  }

  async openFileUpdateModal(infos, item?, file?) {

    /* const checkPropmt = await this.modalController.getTop();
    if (checkPropmt) {
      this.modalController.dismiss();
    } */


    const modal = await this.modalController.create({
      component: UploadDesktopComponent,
      canDismiss: true,
      keyboardClose: true,
      breakpoints: [0, 0.2],
      initialBreakpoint: this.platform.is('desktop') ? 0.1 : 0.1,
      componentProps: {
        nameFile: infos.name,
        codeFile: infos.code,
        item: infos,
        update: true,
        header: {},
        module: this.module,
        showListFile: false,
        disabled_expired: true,
        colGridFile: [12, 12, 12],

      }
      //presentingElement: this.routerOutlet.nativeEl,
    });

    modal.onWillDismiss().then((e) => {
      console.log(e);


      if (e.data && e.data.res) {
        //this.canClick = true;
        //this.loadProjects(this.currentSegment);
        this.getFiles(true, {})
      }
    });


    //this.ionLoaderService.dismissLoader();
    return modal.present().finally(() => {

    })
  }


  async openFileInfosModal(infos, item, file) {

    /* const checkPropmt = await this.modalController.getTop();
    if (checkPropmt) {
      this.modalController.dismiss();
    } */

    const modal = await this.modalController.create({
      component: ModalInfoFileComponent,
      canDismiss: true,
      keyboardClose: true,
      breakpoints: [0, 0.4, 0.5, 0.6, 0.7],
      initialBreakpoint: this.platform.is('desktop') ? 0.6 : 0.5,
      componentProps: {
        item: item,
        module: this.module,
        file: file,
        parent: this.item,
        data: infos
      }
      //presentingElement: this.routerOutlet.nativeEl,
    });

    modal.onWillDismiss().then((e) => {
      if (e.data && e.data.status != -1) {
        //this.canClick = true;
        //this.loadProjects(this.currentSegment);
      }
    });


    //this.ionLoaderService.dismissLoader();
    return modal.present().finally(() => {
    })
  }


  async presentToast(text, color) {
    const toast = await this.toastController.create({
      message: text,
      position: 'bottom',
      duration: 3000,
      cssClass: 'toast',
      color: color
    });
    toast.present();
  }

}
