// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content {
  --padding-top: 16px;
}
ion-content .add-photo-wrapper {
  justify-content: center;
}
ion-content .user-image {
  width: 100%;
  margin: auto;
  height: 75px;
  overflow: hidden;
  text-transform: uppercase;
  padding: 0px 18px;
}
ion-content .user-image h2 {
  font-weight: 600;
}

ion-title {
  padding-inline-start: 0;
  padding-inline-end: 0;
  text-align: center;
}

.size-14 {
  font-size: 1.4em;
  /*margin-right: 20px;*/
}

.custom-label {
  /*  display: contents;
    font-size: 13px;
    padding-top: 11px; */
}

.custom-label ion-icon {
  font-size: 28px;
}

.p_d_t {
  padding-top: 10px;
}

::ng-deep app-new-project {
  border-radius: 4px;
}

ion-segment.ios {
  width: 100%;
}

.low .segment-button-checked {
  background: #ff9800 !important;
  --indicator-color: #ff9800;
}

.normal .segment-button-checked {
  background: #00d458 !important;
  --indicator-color: #00d458;
}

.high .segment-button-checked {
  background: #d92f46 !important;
  --indicator-color: #d92f46;
}`, "",{"version":3,"sources":["webpack://./src/app/offers/new-offer/new-offer.component.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;AACE;EACE,uBAAA;AACJ;AAEE;EACE,WAAA;EACA,YAAA;EACA,YAAA;EACA,gBAAA;EACA,yBAAA;EACA,iBAAA;AAAJ;AAEI;EAEE,gBAAA;AADN;;AAMA;EACE,uBAAA;EACA,qBAAA;EACA,kBAAA;AAHF;;AAOA;EACE,gBAAA;EACD,sBAAA;AAJD;;AAOA;EACE;;wBAAA;AAFF;;AAOA;EACE,eAAA;AAJF;;AAOA;EACE,iBAAA;AAJF;;AAOA;EACE,kBAAA;AAJF;;AAOA;EACE,WAAA;AAJF;;AAQE;EACE,8BAAA;EACA,0BAAA;AALJ;;AAUE;EACE,8BAAA;EACA,0BAAA;AAPJ;;AAYE;EACE,8BAAA;EACA,0BAAA;AATJ","sourcesContent":["ion-content {\n  --padding-top: 16px;\n\n  .add-photo-wrapper {\n    justify-content: center;\n  }\n\n  .user-image {\n    width: 100%;\n    margin: auto;\n    height: 75px;\n    overflow: hidden;\n    text-transform: uppercase;\n    padding: 0px 18px;\n    \n    h2{\n      //color: var(--ion-color-primary);\n      font-weight: 600;\n    }\n  }\n}\n\nion-title {\n  padding-inline-start: 0;\n  padding-inline-end: 0;\n  text-align: center;\n\n}\n\n.size-14 {\n  font-size: 1.4em;\n /*margin-right: 20px;*/\n}\n\n.custom-label {\n  /*  display: contents;\n    font-size: 13px;\n    padding-top: 11px; */\n}\n\n.custom-label ion-icon {\n  font-size: 28px;\n}\n\n.p_d_t {\n  padding-top: 10px;\n}\n\n::ng-deep app-new-project {\n  border-radius: 4px;\n}\n\nion-segment.ios {\n  width: 100%;\n}\n\n.low {\n  .segment-button-checked {\n    background: #ff9800 !important;\n    --indicator-color: #ff9800;\n  }\n}\n\n.normal {\n  .segment-button-checked {\n    background: #00d458 !important;\n    --indicator-color: #00d458;\n  }\n}\n\n.high {\n  .segment-button-checked {\n    background: #d92f46 !important;\n    --indicator-color: #d92f46;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
