import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Platform, ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { BehaviorSubject, from, Observable, of, Subject } from 'rxjs';
import { catchError, delay, distinctUntilChanged, map, retryWhen, scan, tap, timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../authentication/authentication.service';
import { NetworkService, ConnectionStatus } from '../network/network.service';
//import { Storage } from '@ionic/storage';
import { OfflineManagerService } from '../network/offline-manager.service';


import { Project } from 'src/app/projects/models/projects';
import { Docs } from 'src/app/documents/models/docs';
import { Job } from 'src/app/jobs/models/job.model';


const API_STORAGE_KEY = 'jobsService';
const TOKEN_KEY = 'access-token';

@Injectable({
  providedIn: 'root'
})
export class JobsService {

  private url: string = 'http';
  private token: any;
  private projects: Project[];
  private documents: Docs[];
  private document: Docs;
  private project_types: any[];
  //public docList = new BehaviorSubject(null);

  newDiscountJob = new Subject()

  constructor(
    private storage: Storage,
    private plt: Platform,
    private http: HttpClient,
    public toastController: ToastController,
    private authService: AuthenticationService,
    private networkService: NetworkService,
    private offlineManager: OfflineManagerService
  ) {

    /**
     * Map url API
     */
    this.url = environment.SSL.active ? this.url + 's' : this.url
    this.url += '://' + environment.API_URI.url + ':' + environment.API_URI.port + environment.API_URI.path

    this.authService.currentToken.subscribe(x => {
      //console.log(x);
      this.token = x //= JSON.parse(localStorage.getItem(TOKEN_KEY))

    })

  }

  /**
   * 
   * ORDERS
  */


  searchJob(forceRefresh: boolean = true, url: string, string: string): Observable<any> {

    this.logSys('searchJob', { forceRefresh, url, string })

    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline || !forceRefresh) {
      // Return the cached data from Storage
      return from(this.getLocalData('search-job'));
    } else {
      let body = {
        txt: string.toString()
      };

      return this.http.get<any>(`${this.url}jobs/jobs/${url}&search=${string.toString()}`)
        .pipe(
          timeout(20000),
          tap(data => {
            console.log(data);
            this.setLocalData('search-job', data);
            return data
          }),
          catchError(this.handleError<any[]>('Search job', []))
        );
    }

  }

  findJob(queryParams, category_id): Observable<any> {
    console.log(`${this.url}order/jobs/${queryParams}&category_id=${category_id}`)
    return this.http.get<any>(`${this.url}order/jobs/${queryParams}&category_id=${category_id}`)
        .pipe(
            timeout(16000),
            tap(data => {
                console.log(data);
                return data
            }),
            catchError(this.handleError<any[]>('Get jobs list', []))
        );
  }


  tmp_rangePrice(forceRefresh: boolean = true, string: number, string2: number): Observable<any> {

    return this.http.get<any>(`${this.url}jobs/jobs/?page=1&items=25&cost_min=${string.toString()}&cost_max=${string2.toString()}`)
      .pipe(
        timeout(12000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Search Range Cost Job', []))
      );


  }


  /**
  * 
  *
  * @returns list jobs
  */
  getJobsList(params?): Observable<any> {
    return this.http.get<any>(`${this.url}order/jobs/${params}`)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          this.setLocalData('list', data);
          return data
        }),
        catchError(this.handleError<any[]>('Get jobs list', []))
      );

  }

  getJobsList_1(params): Observable<any> {
    params = ''
    return this.http.get<any>(`${this.url}order/jobs/${params}`)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          this.setLocalData('list', data);
          return data
        }),
        catchError(this.handleError<any[]>('Get jobs list', []))
      );
  }



  /**
 * 
 * @param id id of job
 * @returns get job by project_id?
 */
  getJobsByCategoryId(category_id, params): Observable<any> {

    //return this.http.get<any>(`${this.url}order/jobs/?category_id=${category_id}`)
    //return this.http.get<any>(string)
    return this.http.get<any>(`${this.url}order/jobs/${params}&category_id=${category_id}`)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Get jobs by category', []))
      );

  }
  

  getJobs_SearchByNoCategoryId(params): Observable<any> {

    //return this.http.get<any>(`${this.url}order/jobs/?category_id=${category_id}`)
    //return this.http.get<any>(string)
    return this.http.get<any>(`${this.url}order/job_search/${params}/`)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Get jobs by category', []))
      );

  }

  
  getJobsByNoCategoryId(params): Observable<any> {

    //return this.http.get<any>(`${this.url}order/jobs/?category_id=${category_id}`)
    //return this.http.get<any>(string)
    return this.http.get<any>(`${this.url}order/jobs/${params}`)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Get jobs by category', []))
      );

  }
  
  /**
 * 
 * @param id id of job
 * @returns get job by project_id?
 */
  getJobsByProjectId(project_id, params): Observable<any> {

    //return this.http.get<any>(`${this.url}order/jobs/?category_id=${category_id}`)
    //return this.http.get<any>(string)
    return this.http.get<any>(`${this.url}order/jobs/${params}&project_id=${project_id}`)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Get jobs by category', []))
      );

  }


  /**
 * 
 * @param id id of job
 * @returns get orde count by project_id?
 */
  getJobsByProjectIdCount(project_id): Observable<any> {
    return this.http.get<any>(`${this.url}order/jobs/project_id=${project_id}`)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Get jobs count by project_id', []))
      );

  }

  /**
  * 
  *
  * @returns Type Job Types
  */
  getJobTypes(params?): Observable<any> {
    return this.http.get<any>(`${this.url}jobs/types/`)///${params}
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          this.setLocalData('types', data);
          return data
        }),
        catchError(this.handleError<any[]>('Get job types', []))
      );
  }

  /**
  * 
  *
  * @returns Type Job Extra Types
  */
  getJobExtraTypes(params?): Observable<any> {
    return this.http.get<any>(`${this.url}jobs/extratypes/`)///${params}
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Get job extratypes', []))
      );
  }

  /**
  * 
  *
  * @returns Type Job Invoiceterms
  */
  getJobInvoiceterms(): Observable<any> {
    return this.http.get<any>(`${this.url}jobs/invoiceterms/`)///${params}
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Get job invoiceterms', []))
      );
  }

  /**
  * 
  *
  * @returns Type Job Paymentterms
  */
  getJobPaymentterms(): Observable<any> {
    return this.http.get<any>(`${this.url}jobs/paymentterms/`)///${params}
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Get job paymentterms', []))
      );
  }


  /**
  * 
  *
  * @returns Type Job Paymenttypes
  */
  getJobPaymenttypes(): Observable<any> {
    return this.http.get<any>(`${this.url}jobs/paymenttypes/`)///${params}
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Get job paymenttypes', []))
      );
  }

  /**
  * 
  *
  * @returns Type Job Category
  */
  getJobCategories(params?): Observable<any> {
    let url = "https://65.108.95.255:9001/api/v1/"
    console.log(url)
    return this.http.get<any>('http://65.108.95.255:9001/api/v1/order/jobs/')//${params}
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          this.setLocalData('categories', data);
          return data
        }),
        catchError(this.handleError<any[]>('Get job categories', []))
      );
  }



  /**
 * 
 * @param id id of job
 * @returns get job by id?
 */
  getJobById(id, mode): Observable<any> {

    return this.http.get<any>(`${this.url}jobs/job/${id}/${mode}`)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Get job by id', []))
      );

  }



  /**
 * 
 * @param id id of job
 * @returns put job by id?
 */
  putJobById(form, job): Observable<any> {

    return this.http.put<any>(`${this.url}jobs/job/${job.id}/`, form)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Update job by id', []))
      );

  }

  /**
 * 
 * @param id id of job
 * @returns delete job by id?
 */
  deleteJobById(job): Observable<any> {

    return this.http.delete<any>(`${this.url}jobs/job/${job.id}`)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Delete job by id', []))
      );

  }


  /**
   * 
   * @param job 
   * @returns create job by project_id
   */

  createJob(job) {
    console.log(job);


    return this.http.post<any>(`${this.url}jobs/jobs/`, job)
      .pipe(
        tap(data => {
          console.log(data);
        }),
        catchError(this.handleError<any[]>('Create job by project id', []))
      );
  }



  /**
   * 
   * @param offer 
   * @returns create offer 
   */

  createOffer(job) {

    console.log(job);
    return ''

    return this.http.post<any>(`${this.url}jobs/jobs/`, job)
      .pipe(
        tap(data => {
          console.log(data);
        }),
        catchError(this.handleError<any[]>('Create job by project id', []))
      );
  }



  /**
   * 
   * @param job 
   * @returns create line job
   */

  createLineJob(form, job) {

    return this.http.post<any>(`${this.url}jobs/job/${job.id}/lines/`, form)
      .pipe(
        tap(data => {
          console.log(data);
        }),
        catchError(this.handleError<any[]>('Create line job', []))
      );
  }

  /**
   * 
   * @param job 
   * @returns put details line job
   */

  putDetailsLinesJob(form, job: Job) {

    return this.http.put<any>(`${this.url}jobs/job/${job.id}/line/${form.id}/`, form)
      .pipe(
        tap(data => {
          console.log(data);
        }),
        catchError(this.handleError<any[]>('put detail line job', []))
      );
  }


  /**
   * 
   * @param job 
   * @returns get lines job
   */

  getLinesJob(job_id) {

    return this.http.get<any>(`${this.url}jobs/job/${job_id}/lines/`)
      .pipe(
        tap(data => {
          console.log(data);
        }),
        catchError(this.handleError<any[]>('get lines job', []))
      );
  }


  /**
   * 
   * @param job 
   * @returns get details line job
   */

  getDetailsLinesJob(job_id) {

    return this.http.get<any>(`${this.url}jobs/job/${job_id}/line/`)
      .pipe(
        tap(data => {
          console.log(data);
        }),
        catchError(this.handleError<any[]>('get detail line job', []))
      );
  }




  /**
   * 
   * @param job 
   * @returns get legal note job
   */

  getLegalJob(job_id: Job) {

    return this.http.get<any>(`${this.url}jobs/job/${job_id}/legals/`)
      .pipe(
        tap(data => {
          console.log(data);
        }),
        catchError(this.handleError<any[]>('get legals job', []))
      );
  }



  /**
   * 
   * @param job 
   * @returns get details legal job
   */

  getDetailsLegalJob(job_id: Job) {

    return this.http.get<any>(`${this.url}jobs/job/${job_id}/legal/`)
      .pipe(
        tap(data => {
          console.log(data);
        }),
        catchError(this.handleError<any[]>('get detail legal job', []))
      );
  }


  /**
   * 
   * @param job 
   * @returns create details legal job
   */

  createDetailsLegalJob(form, job: Job) {

    return this.http.post<any>(`${this.url}jobs/job/${job.id}/legal/`, job)
      .pipe(
        tap(data => {
          console.log(data);
        }),
        catchError(this.handleError<any[]>('put detail legal job', []))
      );
  }


  /**
   * 
   * @param job 
   * @returns put details legal job
   */

  putDetailsLegalJob(form, job: Job) {

    return this.http.put<any>(`${this.url}jobs/job/${job.id}/legal/${form.id}/`, form)
      .pipe(
        tap(data => {
          console.log(data);
        }),
        catchError(this.handleError<any[]>('put detail legal job', []))
      );
  }


  /**
   * 
   * @param job 
   * @returns get priorities job
   */

  getPrioritiesJob() {

    return this.http.get<any>(`${this.url}jobs/priorities/`)
      .pipe(
        tap(data => {
          console.log(data);
          this.setLocalData('priorities', data);
        }),
        catchError(this.handleError<any[]>('get priorities job', []))
      );
  }


  /**
   * 
   * @param job 
   * @returns get list status job
   */

  getJobStatus() {

    return this.http.get<any>(`${this.url}jobs/status/`)
      .pipe(
        tap(data => {
          console.log(data);
          this.setLocalData('status', data);
        }),
        catchError(this.handleError<any[]>('get status job', []))
      );
  }


  /**
   * 
   * @param job 
   * @returns put status job
   */

  putJobStatus(job, form) {
    return this.http.put<any>(`${this.url}jobs/job/${job.id}/status/`, form)
      .pipe(
        tap(data => {
          console.log(data);
        }),
        catchError(this.handleError<any[]>('put status job', []))
      );
  }


  /**
   * 
   * SCONTI
  */

  /**
  * @description Visualizza le tipoligie di sconto  job
  * @returns [{"code":"P","label":"Percentage"},{"code":"S","label":"Subtraction"},{"code":"D","label":"Last Cost"}]
  */
  getJobsDiscountType(): Observable<any> {
    console.log('get type discount');

    return this.http.get<any>(`${this.url}jobs/discount/types/`)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Get types discount job list', []))
      );

  }

  /**
  * @description Visualizza gli sconti di un ordine
  * @job job obj
  * @returns {"job_id":9,"global_discounts":[{"id":1,"discount_type":"P","value":10.0,"approved":false,"version":0,"deleted":false,"created":"2022-04-25T08:53:49Z","job":9,"vendor":1}],"lines":[{"line_id":1,"discounts":[]},{"line_id":2,"discounts":[]}]}
  */
  getJobsGlobalDiscount(job): Observable<any> {
    return this.http.get<any>(`${this.url}jobs/job/${job.id}/discounts/`)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Get discount list', []))
      );

  }

  /**
  * @description crea uno sconto sull'ordine (una linea)
  * @job_id
  * @form {"discount_category":"line","line_id":2,"vendor_id":1,"discount_type":"P","value":20,"version":1}
  * @returns {"message":"OK: Global discount inserted","data":{"id":3,"discount_type":"P","value":20.0,"approved":false,"version":1,"deleted":false,"created":"2022-04-25T13:02:47.517974Z","job":9,"vendor":1}}
  */
  craeteJobsDiscount(job_id, form): Observable<any> {

    return this.http.post<any>(`${this.url}jobs/job/${job_id}/discounts/`, form)
      .pipe(
        timeout(16000),
        tap(e => {
          console.log(e);
          this.newDiscountJob.next(e.data)
          return e
        }),
        catchError(this.handleError<any[]>('Create single line discount', []))
      );

  }

  /**
  * 
  *
  * @returns aggiorna il numbering tra il local_id e id mysql 
  */
  updateNumberingIdLocal(job, form): Observable<any> {

    return this.http.put<any>(`${this.url}jobs/job/${job.id}/numbering/`, form)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          //this.setLocalData('list', data);
          return data
        }),
        catchError(this.handleError<any[]>('Set line job numbering linew job', []))
      );

  }

  /**
  * 
  *
  * @returns aggiorna il numbering tra il local_id e id mysql 
  */
  updateAllNumberingIdLocal(job, form): Observable<any> {

    return this.http.post<any>(`${this.url}jobs/${job.id}/numbering/`, form)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          //this.setLocalData('list', data);
          return data
        }),
        catchError(this.handleError<any[]>('Set line job numbering linew job', []))
      );

  }



  /**
  * 
  *
  * @returns print jobs
  */
  getJobsPrint(job): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    const requestOptions: Object = {
      headers: headers,
      responseType: 'text'
    }
    return this.http.get<any>(`${this.url}jobs/job/${job.id}/print/`, requestOptions)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Print jobs ', []))
      );

  }




  private handleError<T>(operation = 'operation', result?: T) {

    return (error: any): Observable<T> => {

      //console.error(error);
      console.log(result, error);

      console.log(`${operation} failed`, error);
      const status = error.status; //error == 'Forbidden' ? 403 : 500
      this.presentToast(status, error == 'undefined' ? 'Internal Server Error' : error, operation)
      return of(error.error as T);
    };
  }


  async presentToast(status, statusText, message) {
    const toast = await this.toastController.create({
      message: status + ' ' + statusText + ': "' + message + '"',
      duration: 2000,
      mode: 'ios',
      cssClass: 'toast',
      color: status != '200' ? 'danger' : 'primary'
    });
    toast.present();
  }

  logSys(src, status, opt?) {
    const debugStyle1 = 'background: linear-gradient(135deg,#471ee9,#3a49b7); bjob: 1px solid #9a9a9a; color: #ffffff; bjob-bottom-left-radius: 2px; bjob-top-left-radius: 2px; padding: 2px 0 2px 4px;';
    const debugStyle2 = 'background: #252b3e; bjob: 1px solid #9a9a9a; bjob-top-right-radius: 2px; bjob-bottom-right-radius: 2px; margin-left: -2px; padding: 2px 4px; color: white;';

    //console.log('ForceRefresh API ', status, this.networkService.getCurrentNetworkStatus(), this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline);
    console.log(`%cApiService %c%s`, debugStyle1, debugStyle2, ' ' + src, status);
  }




  // Save result of API requests
  private setLocalData(key, data) {
    this.storage.set(`${API_STORAGE_KEY}-${key}`, data);
  }

  // Get cached API result
  private getLocalData(key) {
    return this.storage.get(`${API_STORAGE_KEY}-${key}`);
  }


  /**
   * 
   * @param date
   * @param job_id 
   * @returns update date expected stop 
   */
  updateDateExpected(date, job_id): Observable<any> {
    let form = {
      expected_date_stop: date
    }
    return this.http.put<any>(`${this.url}jobs/job/${job_id}/`, form)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Update job by id', []))
      );

  }
  /**
   * Create New Legal 
   * @param job_id 
   * @param form 
   * @returns 
   */



  createLegal(form, job_id): Observable<any> {
    console.log(form, job_id);
    //return;

    return this.http.post<any>(`${this.url}jobs/job/${job_id}/legals/`, form)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Create single legal', []))
      );

  }

  /**
  * Create New Extra 
  * @param job_id 
  * @param form 
  * @returns 
  */
  createExtra(form, job_id): Observable<any> {
    console.log(form, job_id);
    //return;

    return this.http.post<any>(`${this.url}jobs/job/${job_id}/extras/`, form)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Create single extra ', []))
      );

  }


  /**
 * 
 * @param date
 * @param job_id 
 * @returns update date expected stop 
 */
  order_orderlegal_update(form, line_id, job_id): Observable<any> {
    return this.http.put<any>(`${this.url}jobs/job/${job_id}/legal/${line_id}/`, form)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Update job legal by id', []))
      );

  }
  /**
* 
* @param date
* @param job_id 
* @returns update date expected stop 
*/
  updateExtra(form, line_id, job_id): Observable<any> {
    return this.http.put<any>(`${this.url}jobs/job/${job_id}/extra/${line_id}/`, form)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Update job extra by id', []))
      );

  }




  /**
* 
* @param 
* @param id, job_id
* @returns Choose Vendor Discount
*/
  chooseDiscountVendor(form, job_id, id): Observable<any> {
    console.log(form, job_id, id);

    return this.http.put<any>(`${this.url}jobs/job/${job_id}/discount/${id}/`, form)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('Update job by id', []))
      );

  }









}
