import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Injector, OnInit, Renderer2, ViewChild, ChangeDetectorRef, Input, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AnimationController, IonContent, IonInfiniteScroll, IonNav, IonRouterOutlet, IonSearchbar, ModalController, NavController, PopoverController } from '@ionic/angular';
import { Observable, of } from 'rxjs';
import { SubMenuComponent } from 'src/app/components/sub-menu/sub-menu.component';
import { ApiService } from 'src/app/services/api/api.service';
import { DataService } from 'src/app/services/data/data.service';
import { OauthService } from 'src/app/services/googleapis/oauth.service';
import { IonLoaderService } from 'src/app/services/ion-loader/ion-loader.service';
import { Docs } from '../models/docs';

import { ActionSheetController, ToastController, Platform, LoadingController } from '@ionic/angular';
import { File, FileEntry } from '@awesome-cordova-plugins/file/ngx';
import { Storage } from '@ionic/storage-angular';
import { finalize } from 'rxjs/operators';
import { ModalInfoFileComponent } from '../../components/media/modal-info-file/modal-info-file.component';
import { TabsService } from 'src/app/services/tabs/tabs.service';
import { TitleAppService } from 'src/app/services/utils/title-app/title-app.service';

const STORAGE_KEY = 'my_images';


@Component({
  selector: 'app-render-documents',
  templateUrl: './render-documents.page.html',
  styleUrls: ['./render-documents.page.scss'],
  animations: [
    trigger(
      'enterAnimation', [
      transition(':enter', [
        style({ transform: 'translateY(-65px)', opacity: 0 }),
        animate('250ms', style({ transform: 'translateY(-65px)', opacity: 1 }))
      ]),
      transition(':leave', [
        style({ transform: 'translateY(0)', opacity: 1 }),
        animate('250ms', style({ transform: 'translateY(-25px)', opacity: 0 }))
      ])
    ]
    ),
    trigger(
      'enterAnimation2', [
      transition(':enter', [
        //style({transform: 'translateY(-65px)', opacity: 0}),
        animate('250ms', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        //style({transform: 'translateY(0)', opacity: 1}),
        animate('250ms', style({ opacity: 0 }))
      ])
    ]
    )
  ],
})
export class RenderDocumentsPage {

  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  @ViewChild(IonContent) content: IonContent;
  @ViewChild(IonSearchbar) myInput: IonSearchbar;
  //@ViewChild('modalInfoFile', { static: false }) modalInfoFile: TemplateRef<any>;
  @Input() id: any;

  id_document: number;
  toggleSrc: boolean;
  renderDocs: Observable<Docs[]>;
  renderRecentDocs: Observable<Docs[]>;
  public showProgressStatus = false;
  //public documets: Docs;
  public favState = false;
  public favOnAnimation: Animation;
  public favOffAnimation: Animation;

  public show_bread = true;

  oauth: OauthService;
  files: any;

  images = [];

  url: string;
  itemListData = [];
  total_page = 0;
  page_number = 1;
  page_limit = 30;

  isSearch: boolean = false;
  canClick: boolean = true;

  fab: boolean = false;

  constructor(
    private actionSheetController: ActionSheetController,
    private toastController: ToastController,
    private storage: Storage,
    private plt: Platform,
    private loadingController: LoadingController,
    private ref: ChangeDetectorRef,
    private ionLoaderService: IonLoaderService,
    private dataService: DataService,
    private api: ApiService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private animationCtrl: AnimationController,
    private navCtrl: NavController,
    private injector: Injector,
    private renderer: Renderer2,
    public popoverController: PopoverController,
    private modalController: ModalController,
    private routerOutlet: IonRouterOutlet,
    //private nav: IonNav,
    private tabService: TabsService,
    private titleAppService: TitleAppService
  ) {
    this.toggleSrc = true;

    /* this.tabService.currentTabSubject.subscribe(e => {
      console.log(e);
      if (e.name == 'Documenti' || e.id == 3) {
        this.total_page = 0;
        this.page_number = 1;
        this.itemListData = [];
        this.getListItem(false, "", this.id_document);
      }

    }) */

    /* let active2 = this.tabService.currentTabSubject.subscribe(e => {
      console.log('ACTIVE', e);
      //if (e == 'Documenti')
        //this.getListItem(false, "", 0);
    }) */


    /*     if (this.nav) {
          this.nav.getActive().then(e => {
            console.log('getActive', e.nav.views[e.nav.views.length-1].element.nodeName);
    
            
          })
        } */
  }
  maxBreadcrumbs = 4;
  autocomplete: any;


  updateSearchResults() {
    console.log(this.autocomplete)    //search input will display
    this.oauth.searchFiles(this.autocomplete, '', 'name', 'asc').subscribe(result => {
      console.log(result);
      this.files = result;

      if (this.files.files.length == 0) {
        console.log('IM HERE RenderDocumentsPage');
        this.files.files[0] = {
          "name": 'empty'
        }
      }
    })
  }



  expandBreadcrumbs() {
    this.maxBreadcrumbs = undefined;
    this.show_bread = true;
  }

  slideOpts = {
    initialSlide: 0,
    slidesPerView: 1,
    autoplay: false,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      renderBullet: function (index, className) {
        return '<span class="' + className + '">' + (index + 1) + '</span>';
      },
    }
  };

  slideOpts2 = {
    initialSlide: 0,
    slidesPerView: 1,
    autoplay: false,

  };

  onSlideMoved(event) {
    /** isEnd true when slides reach at end slide */
    event.target.isEnd().then(isEnd => {
      console.log('End of slide', isEnd);
    });

    event.target.isBeginning().then((istrue) => {
      console.log('End of slide', istrue);
    });
  }

  load() {
    this.oauth.getFiles()
      .subscribe((result: any) => {
        this.files = result;
      }, (result: any) => {

      });
  }

  ionViewWillEnter() {


    this.myInput.value = ""
    this.titleAppService.title.next('Documents')

    this.canClick = true;

    this.id_document = Number(this.activatedRoute.snapshot.paramMap.get('id')) || this.id;
    console.log('id_document', this.id_document);

    //this.dataService.getProjectById(parseInt(id, 10))
    //this.renderDocs = this.api.getDocumentsList(true, this.url, id)
    //this.renderRecentDocs = this.api.getRecentDocumentsList(true)

    this.page_number = 1;
    this.itemListData = [];
    this.getListItem(false, "", this.id_document);
    //this.oauth = this.injector.get(OauthService);

    //this.load();

    setTimeout(() => {

      //this.hideLoader();
    }, 1000);

    /* .subscribe(documents => {
      // if the project doesn't exists, return to home page
      if (!documents) {
        this.router.navigate(['/projects-list']);
      } else {
        documents;
      }
    }); */
  }

  onChangeSearchbar(event) {
    console.log(event);
    if (event && event.detail && event.detail.value != '') {
      /* this.api.searchDocument(true, event.detail.value.toString()).subscribe(e => {
        console.log(e);
        this.renderDocs = of(e); 
    })*/
      this.total_page = 0;
      this.itemListData = [];
      this.getItemSearch(false, "", 0, event.detail.value);

    } else {
      this.page_number = 1;
      this.itemListData = [];
      //this.renderDocs = this.api.getDocumentsList(true, this.url, 0)
      this.getListItem(false, "", 0);
    }
  }

  async openFileInfosModal(infos, item) {
    const checkPropmt = await this.modalController.getTop();
    if (checkPropmt) {
      this.modalController.dismiss();
    }

    const modal = await this.modalController.create({
      component: ModalInfoFileComponent,
      canDismiss: true,
      keyboardClose: true,
      breakpoints: [0, 0.4, 0.5, 0.6, 0.7],
      initialBreakpoint: this.plt.is('desktop') ? 0.6 : 0.5,
      componentProps: {
        item: item,
        parent: { folders: [{ folder: { id: this.id } }] },
        data: infos
      }
      //presentingElement: this.routerOutlet.nativeEl,
    });

    modal.onWillDismiss().then((e) => {
      if (e.data && e.data.status != -1) {
        this.canClick = true;
        //this.loadProjects(this.currentSegment);
      }
    });


    //this.ionLoaderService.dismissLoader();
    return modal.present().finally(() => {
    })
  }

  navigateToPath(url, force?) {
    //this.showLoader();
    this.expandBreadcrumbs()
    //this.navCtrl.navigateForward(url, { animated: false });
    this.maxBreadcrumbs = 4;
  }

  navigateTo(item: Docs, id?) {

    console.log('renderDocuments navigateTo', item);

    if (this.canClick) {
      this.url = ''//'?page=' + this.page_number + '&items=' + this.page_limit;

      if (item && item.type != 'DIR') {

        this.ionLoaderService.simpleLoader();

        this.api.getDocumentInfo(true, this.url, item.id).subscribe(data => {
          console.log(data, item);

          if (data.length > 0 && item) {

            this.openFileInfosModal(data, item);

          } else {

            console.log('else openFileModal');
            this.presentToast('Error get file.', 'danger');
            this.ionLoaderService.dismissLoader();

          }

        })

      } else if (item && item.type) {
        //this.showLoader();
        this.show_bread = false;
        this.page_number = 1;
        //this.statusBar.backgroundColorByHexString('#357dc3');
        // //nav: this.nav(RenderDocumentsPage, { id: item.id }, { animated: false, direction: 'forward' });
        //this.navCtrl.navigateForward('/tabs/tab2/render-documents/' + item.id, { animated: false });
        /* this.maxBreadcrumbs = 4;
        if (item.indexOf('https') == 0) {
          location.replace(item)
        }
        this.oauth.getFilesFolder(item, id).subscribe(result => {
          console.log(result);
          this.files = result
          if (this.files.files.length == 0) {
            console.log('IM HERE');
            this.files.files[0] = {
              "name": 'empty'
            }
          }
        }) */
      }
    }
  }

  goBack() {
    // this.nav.pop();
    //this.navCtrl.back({ animated: false });
  }


  async presentPopover(ev: any) {

    const checkPropmt = await this.popoverController.getTop();
    if (checkPropmt) {
      this.popoverController.dismiss();
    }

    const popover = await this.popoverController.create({

      component: SubMenuComponent,
      cssClass: 'popover_setting',
      event: ev,
      translucent: true,
      mode: 'md',
      side: 'bottom',
      alignment: 'center',
      size: 'auto',
      dismissOnSelect: true,
      backdropDismiss: true,
      //trigger: ev.srcElement.id,
      componentProps: {
        'toggle': this.toggleSrc,
        'data': [
          {
            'name': 'Search',
            'icon': 'search',
            'method': 'toggleValue'
          },

        ]
      }

    });

    popover.present();
    popover.onDidDismiss().then((e) => {
      //console.log(e.data);
      if (e.data) {
        this.toggleSrc = e.data.toggleSrc
      }
    });
    console.log('onDidDismiss resolved with role');
  }


  getListItem(isFirstLoad, event, id = 0) {
    this.isSearch = false;

    this.url = '?page=' + this.page_number + '&items=' + this.page_limit;

    this.api.getDocumentsList(true, this.url, id)
      .subscribe((data: any) => {

        if (data.num_pages >= (this.page_number)) {

          for (let i = 0; i < data.results.length; i++) {
            this.itemListData.push(data.results[i]);
          }

          if (isFirstLoad)
            event.target.complete();

          this.page_number++;

        } else {
          console.log('last page');
          event.target ? event.target.complete() : null;

        }
      }, error => {
        console.log(error);
      })
  }

  getItemSearch(isFirstLoad, event, id = 0, string) {
    this.isSearch = true;

    this.url = '?page=' + this.page_number; // + '&_limit=' + this.page_limit;

    this.api.searchDocument(true, this.url, string)
      .subscribe((data: any) => {
        //console.log(data);

        //console.log(data, data.num_pages , this.page_number);
        if (data.num_pages >= (this.page_number)) {



          for (let i = 0; i < data.results.length; i++) {
            this.itemListData.push(data.results[i]);
          }

          if (isFirstLoad)
            event.target.complete();

          this.page_number++;
        } else {
          console.log('last page');
          event.target ? event.target.complete() : null;

        }
      }, error => {
        console.log(error);
      })
  }


  doInfinite(event, search?) {
    console.log(event, search);
    if (this.isSearch) {
      this.getItemSearch(true, event, 0, search.value)
    } else {
      this.getListItem(true, event, this.id_document);
    }
  }


  onLongPress() {
    console.log('onLongPress');
  }


  onLongPressing(item) {
    this.canClick = false;
    console.log('onLongPressing', item);
    this.ionLoaderService.simpleLoader();
    this.api.getDocumentInfo(true, this.url, item.id).subscribe(data => {
      this.openFileInfosModal(data[0], item);

    })
  }


  doRefresh(event) {
    console.log('Begin async operation');

    setTimeout(() => {
      console.log('Async operation has ended');

      this.itemListData = [];
      //this.getListItem(true, '', this.id_document);


      event.target.complete();
    }, 1000);
  }
  onScroll(event) {

    if (event.detail.currentY >= 100) {
      //console.log(event);
      //console.log(this.fab);
      this.fab = true;
    } else if (event.detail.currentY <= 99) {
      //console.log(event);
      //console.log(this.fab);
      this.fab = false;
    }
  }

  async presentToast(text, color) {
    const toast = await this.toastController.create({
      message: text,
      position: 'bottom',
      duration: 3000,
      cssClass: 'toast',
      color: color
    });
    toast.present();
  }

}
