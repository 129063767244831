
import { AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, Renderer2, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { NavController, ModalController, IonNav, IonInfiniteScroll, IonSearchbar, IonContent, IonSegment, IonSegmentButton, IonMenu, MenuController } from '@ionic/angular';

import { animate, query, sequence, stagger, state, style, transition, trigger } from '@angular/animations';

import { TabsService } from 'src/app/services/tabs/tabs.service';

import { HttpClient } from '@angular/common/http';
import { ColumnChangesService, ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { SubMenuService } from 'src/app/services/utils/sub-menu/sub-menu.service';
import { ProjectsService } from 'src/app/services/projects/projects.service';

interface PageInfo {
  offset: number;
  pageSize: number;
  limit: number;
  count: number;
}
/**
 * An object used to get page information from the server
 */
export class Page {
  // The number of elements in the page
  size: number = 0;
  // The total number of elements
  totalElements: number = 0;
  // The total number of pages
  totalPages: number = 0;
  // The current page number
  pageNumber: number = 0;
}

const listAnimation = trigger('listAnimation', [
  transition('* <=> *', [
    query(':enter',
      [style({ opacity: 0 }), stagger('180ms', animate('300ms ease-out', style({ opacity: 1 })))],
      { optional: true }
    ),
    query(':leave',
      animate('200ms', style({ opacity: 0 })),
      { optional: true }
    )
  ])
]);
const arrayMok = [
  {
    "_id": "6275910f3b408b2c8e5d9430",
    "index": 0,
    "isActive": false,
    "balance": "$3,451.72",
    "picture": "http://placehold.it/32x32",
    "firstName": "Jenifer Santana",
    "lastName": "Georgia Allison",
    "company": "CABLAM",
    "email": "georgiaallison@cablam.com",
    "email_pec": "georgiaallison@cablam.com",
    "password": "<ReferenceError: password is not defined>",
    "phone": "+1 (953) 554-3136",
    "ragione_sociale": "(933) 565-3821",
    "sdi": "(918) 581-2014",
    "fiscal_code": "+1 (870) 448-2944",
    "address": "194 Stillwell Avenue, 8516",
    "postcode": "(881) 428-2434",
    "country": "140 Ireland",
    "state": "243 New Jersey ",
    "city": "603 Townsend ",
    "registered": "2018-10-11T11:31:32 -02:00",
    "latitude": 20.281459,
    "longitude": 79.62413,
    "brands": [
      "nulla",
      "esse",
      "nostrud",
      "ipsum",
      "sit",
      "consequat",
      "commodo"
    ]
  },
  {
    "_id": "6275910fec6ad813b5609c7e",
    "index": 1,
    "isActive": true,
    "balance": "$2,919.95",
    "picture": "http://placehold.it/32x32",
    "firstName": "Ellen Everett",
    "lastName": "Cline Patterson",
    "company": "COMVEY",
    "email": "clinepatterson@comvey.com",
    "email_pec": "clinepatterson@comvey.com",
    "password": "<ReferenceError: password is not defined>",
    "phone": "+1 (871) 442-2651",
    "ragione_sociale": "(829) 429-2485",
    "sdi": "(984) 481-3777",
    "fiscal_code": "+1 (891) 437-2087",
    "address": "796 Exeter Street, 3277",
    "postcode": "(896) 430-3459",
    "country": "293 India",
    "state": "792 Illinois ",
    "city": "326 Edgewater ",
    "registered": "2018-02-13T06:13:39 -01:00",
    "latitude": 85.922308,
    "longitude": -79.65119,
    "brands": [
      "id",
      "quis",
      "et",
      "id",
      "ea",
      "ut",
      "laboris"
    ]
  },
  {
    "_id": "6275910f62857dee63769881",
    "index": 2,
    "isActive": true,
    "balance": "$3,118.65",
    "picture": "http://placehold.it/32x32",
    "firstName": "Clemons Francis",
    "lastName": "Brandy Johns",
    "company": "ZILCH",
    "email": "brandyjohns@zilch.com",
    "email_pec": "brandyjohns@zilch.com",
    "password": "<ReferenceError: password is not defined>",
    "phone": "+1 (946) 583-3700",
    "ragione_sociale": "(944) 538-2289",
    "sdi": "(842) 446-3667",
    "fiscal_code": "+1 (917) 578-3762",
    "address": "174 Montauk Avenue, 7367",
    "postcode": "(986) 572-3381",
    "country": "977 Svalbard and Jan Mayen Islands",
    "state": "947 Wyoming ",
    "city": "987 Downsville ",
    "registered": "2015-05-17T03:20:33 -02:00",
    "latitude": 23.527317,
    "longitude": 27.51625,
    "brands": [
      "occaecat",
      "quis",
      "tempor",
      "ad",
      "ullamco",
      "commodo",
      "Lorem"
    ]
  },
  {
    "_id": "6275910f45c6d26df5969324",
    "index": 3,
    "isActive": true,
    "balance": "$2,168.15",
    "picture": "http://placehold.it/32x32",
    "firstName": "Flores Palmer",
    "lastName": "Blankenship Spears",
    "company": "ENTOGROK",
    "email": "blankenshipspears@entogrok.com",
    "email_pec": "blankenshipspears@entogrok.com",
    "password": "<ReferenceError: password is not defined>",
    "phone": "+1 (836) 413-3236",
    "ragione_sociale": "(811) 553-2621",
    "sdi": "(852) 421-2389",
    "fiscal_code": "+1 (941) 426-2933",
    "address": "841 Railroad Avenue, 1841",
    "postcode": "(892) 531-2033",
    "country": "603 Pitcairn",
    "state": "401 West Virginia ",
    "city": "769 Witmer ",
    "registered": "2021-08-15T02:35:24 -02:00",
    "latitude": -87.44415,
    "longitude": -46.367511,
    "brands": [
      "aliquip",
      "consectetur",
      "ut",
      "eiusmod",
      "id",
      "excepteur",
      "consectetur"
    ]
  },
  {
    "_id": "6275910fd45cda8b4632fb63",
    "index": 4,
    "isActive": true,
    "balance": "$3,465.35",
    "picture": "http://placehold.it/32x32",
    "firstName": "Geraldine Preston",
    "lastName": "Christian Coleman",
    "company": "DADABASE",
    "email": "christiancoleman@dadabase.com",
    "email_pec": "christiancoleman@dadabase.com",
    "password": "<ReferenceError: password is not defined>",
    "phone": "+1 (966) 480-2306",
    "ragione_sociale": "(984) 479-3501",
    "sdi": "(996) 547-3570",
    "fiscal_code": "+1 (861) 411-3399",
    "address": "583 Blake Avenue, 2968",
    "postcode": "(905) 480-2650",
    "country": "206 China",
    "state": "736 Massachusetts ",
    "city": "763 Haena ",
    "registered": "2015-06-08T09:56:32 -02:00",
    "latitude": -59.309592,
    "longitude": -38.335617,
    "brands": [
      "sunt",
      "consequat",
      "voluptate",
      "ea",
      "tempor",
      "consequat",
      "et"
    ]
  },
  {
    "_id": "6275910fd4a7b0dae3e3bad1",
    "index": 5,
    "isActive": true,
    "balance": "$2,531.64",
    "picture": "http://placehold.it/32x32",
    "firstName": "Alyssa Barnes",
    "lastName": "Vicky Tucker",
    "company": "IMPERIUM",
    "email": "vickytucker@imperium.com",
    "email_pec": "vickytucker@imperium.com",
    "password": "<ReferenceError: password is not defined>",
    "phone": "+1 (979) 538-3958",
    "ragione_sociale": "(917) 519-3960",
    "sdi": "(862) 470-2977",
    "fiscal_code": "+1 (930) 451-2632",
    "address": "689 Gold Street, 569",
    "postcode": "(849) 452-2268",
    "country": "498 Hungary",
    "state": "638 South Carolina ",
    "city": "696 Cascades ",
    "registered": "2017-12-06T04:35:27 -01:00",
    "latitude": -64.893968,
    "longitude": -145.740585,
    "brands": [
      "aliquip",
      "ut",
      "eu",
      "eu",
      "officia",
      "sunt",
      "cillum"
    ]
  },
  {
    "_id": "6275910facb08ea1dde12a12",
    "index": 6,
    "isActive": false,
    "balance": "$3,756.77",
    "picture": "http://placehold.it/32x32",
    "firstName": "Campbell Reeves",
    "lastName": "Levy Hewitt",
    "company": "QUIZKA",
    "email": "levyhewitt@quizka.com",
    "email_pec": "levyhewitt@quizka.com",
    "password": "<ReferenceError: password is not defined>",
    "phone": "+1 (940) 446-3604",
    "ragione_sociale": "(817) 454-2924",
    "sdi": "(850) 597-3603",
    "fiscal_code": "+1 (935) 513-3656",
    "address": "527 Thames Street, 4055",
    "postcode": "(943) 403-2429",
    "country": "137 Sri Lanka",
    "state": "606 Iowa ",
    "city": "586 Ronco ",
    "registered": "2020-08-18T08:33:24 -02:00",
    "latitude": -50.975502,
    "longitude": 14.763027,
    "brands": [
      "amet",
      "culpa",
      "sunt",
      "sint",
      "do",
      "do",
      "proident"
    ]
  },
  {
    "_id": "6275910f5361655c269361da",
    "index": 7,
    "isActive": true,
    "balance": "$3,304.56",
    "picture": "http://placehold.it/32x32",
    "firstName": "Russo Parker",
    "lastName": "Underwood Hendrix",
    "company": "TELEQUIET",
    "email": "underwoodhendrix@telequiet.com",
    "email_pec": "underwoodhendrix@telequiet.com",
    "password": "<ReferenceError: password is not defined>",
    "phone": "+1 (906) 535-3917",
    "ragione_sociale": "(926) 430-3227",
    "sdi": "(979) 573-3331",
    "fiscal_code": "+1 (995) 600-3054",
    "address": "561 Prince Street, 369",
    "postcode": "(933) 566-3293",
    "country": "640 Romania",
    "state": "498 Northern Mariana Islands ",
    "city": "339 Manila ",
    "registered": "2022-01-08T06:35:50 -01:00",
    "latitude": 24.522687,
    "longitude": -86.928846,
    "brands": [
      "sint",
      "sunt",
      "non",
      "do",
      "anim",
      "fugiat",
      "esse"
    ]
  },
  {
    "_id": "6275910f3e5f628254e9730a",
    "index": 8,
    "isActive": false,
    "balance": "$3,124.20",
    "picture": "http://placehold.it/32x32",
    "firstName": "Fischer Simpson",
    "lastName": "Turner Parsons",
    "company": "SONIQUE",
    "email": "turnerparsons@sonique.com",
    "email_pec": "turnerparsons@sonique.com",
    "password": "<ReferenceError: password is not defined>",
    "phone": "+1 (972) 521-3235",
    "ragione_sociale": "(996) 568-3977",
    "sdi": "(945) 545-3825",
    "fiscal_code": "+1 (837) 486-3237",
    "address": "920 Vandervoort Place, 4687",
    "postcode": "(976) 489-3739",
    "country": "631 Mayotte",
    "state": "913 Georgia ",
    "city": "293 Herald ",
    "registered": "2014-05-16T04:09:43 -02:00",
    "latitude": -8.292634,
    "longitude": -80.991889,
    "brands": [
      "do",
      "reprehenderit",
      "tempor",
      "sint",
      "ex",
      "cupidatat",
      "laboris"
    ]
  },
  {
    "_id": "6275910fe8848e06af5cd4e0",
    "index": 9,
    "isActive": true,
    "balance": "$3,390.82",
    "picture": "http://placehold.it/32x32",
    "firstName": "Fanny Zamora",
    "lastName": "Casandra Vazquez",
    "company": "PLAYCE",
    "email": "casandravazquez@playce.com",
    "email_pec": "casandravazquez@playce.com",
    "password": "<ReferenceError: password is not defined>",
    "phone": "+1 (852) 595-3987",
    "ragione_sociale": "(956) 442-3997",
    "sdi": "(935) 579-2707",
    "fiscal_code": "+1 (969) 506-2372",
    "address": "208 Seba Avenue, 5847",
    "postcode": "(963) 492-3753",
    "country": "236 Comoros",
    "state": "641 Federated States Of Micronesia ",
    "city": "412 Allentown ",
    "registered": "2019-09-29T12:13:54 -02:00",
    "latitude": -21.066938,
    "longitude": 140.173711,
    "brands": [
      "cupidatat",
      "sint",
      "reprehenderit",
      "elit",
      "duis",
      "incididunt",
      "aliquip"
    ]
  }
]

@Component({
  selector: 'app-list-users',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListUsersComponent implements OnInit, AfterViewInit, OnChanges {


  n = 0
  public data: any; // Data;
  public columns: any;
  public rows: any;

  rowIsNotExpanded = true

  editing = {};

  selected = [];

  funder = [];
  calculated = [];
  pending = [];
  ColumnMode: ColumnMode
  expanded: any = {};
  timeout: any;
  total_order = 0;
  currentSegment: string = "all";

  @Input() projectId;
  @Input() cache;

  users;
  @Output() clicked = new EventEmitter<any>();
  @Output() switch = new EventEmitter<any>();


  @ViewChild('segment') segment: IonSegment;
  @ViewChild('btn_segment') btn_segment: IonSegmentButton;
  @ViewChild('myTable') table: DatatableComponent;
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  @ViewChild(IonContent) content: IonContent;


  fab: boolean = false;
  customAlertOptions: any = {
    header: 'List groups',
    //subHeader: 'Select group for user',
    message: 'Select group for user',
    translucent: true
  };

  searching = false
  res: any = []
  order_status = [];
  order_types = [];
  order_categories = [];

  total_page = 0;
  page_number = 1;
  page_limit = 30;

  isSearch: boolean = false;
  isLoad: boolean = false;

  queryString = {
    search_by: '',
    search_data: ''
  };

  queryFilter = {
    search_by: 'sort',
    search_data: [{ prop: 'priority.id', dir: 'asc' }]
  };


  totalElements: number;
  pageNumber: number;

  isLoading = 0;

  constructor(
    private menu: MenuController,
    private subMenu: SubMenuService,
    private http: HttpClient,
    private cd: ChangeDetectorRef,
    private navCtrl: NavController,
    private modalController: ModalController,
    private tabService: TabsService,
    private renderer: Renderer2,
    private columnChangesService: ColumnChangesService,
    private projectApi: ProjectsService
  ) {

    console.log('list users constructor');

    this.columns = [
      { prop: '_id', name: 'ID'/* , frozenLeft: true */, minWidth: 75, maxWidth: 75, cellClass: 'td-id', headerClass: 'th-id' },
      /* {
        prop: 'id', width: 75, minWidth: 75
      }, */
     
      
      {
        prop: 'company', width: 90, minWidth: 90, cellClass: 'td-priority', headerClass: 'th-priority'
      },
      {
        prop: 'email', width: 90, minWidth: 90, cellClass: 'td-priority', headerClass: 'th-priority'
      },
      {
        prop: 'email_pec', width: 90, minWidth: 90, cellClass: 'td-priority', headerClass: 'th-priority'
      },
      {
        prop: 'phone', width: 90, minWidth: 90, cellClass: 'td-priority', headerClass: 'th-priority'
      },
      {
        prop: 'sdi', width: 90, minWidth: 90, cellClass: 'td-priority', headerClass: 'th-priority'
      },
      {
        prop: 'fiscal_code', width: 90, minWidth: 90, cellClass: 'td-priority', headerClass: 'th-priority'
      },
      {
        prop: 'country', width: 90, minWidth: 90, cellClass: 'td-priority', headerClass: 'th-priority'
      },
      {
        prop: 'state', width: 90, minWidth: 90, cellClass: 'td-priority', headerClass: 'th-priority'
      },
      {
        prop: 'city', width: 90, minWidth: 90, cellClass: 'td-priority', headerClass: 'th-priority'
      },
      {
        prop: 'brands', width: 90, minWidth: 90, cellClass: 'td-priority', headerClass: 'th-priority'
      },
    
    ]


  }

  ngOnInit() {
    console.log('list users ngOnInit');

    //this.users = [];
    this.fab = true

    //this.loadProjects(this.queryString);
    //this.getOrders(false, "", this.queryString)

    this.queryString = localStorage.getItem('filter_search_users') ? JSON.parse(localStorage.getItem('filter_search_users')) : { search_by: '', search_data: 'all' }

    this.total_order = 0;
    this.total_page = 0;
    this.page_number = 1;

    this.users = []
    this.cache = {}



    //this.getOrdersMeta()

  }


  ngAfterViewInit(): void {

    console.log('list users ngAfterViewInit', this.pageNumber);
    console.log(this.users);
    console.log(this.projectId);
    console.log(this.cache);


  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);
    if (changes['users'] && !changes['users'].firstChange) {

    } else if (changes['cache'] && !changes['cache'].firstChange) {

    }
    /*    
   
         this.total_order = 0;
         this.total_page = 0;
         this.page_number = 0;
   
         this.users = []
         this.cache = {}
        
         setTimeout(() => {
           this.table.bodyComponent.offsetX = 0;
           this.table.bodyComponent.offsetY = 0;
           this.table.headerComponent.offsetX = 0;
           this.table.recalculateColumns();
           this.users = [...this.users];
         }, 100);
         this.loadProjects(this.queryString)
       } */
  }

  openMenu() {
    this.menu.open('admin')
    this.subMenu.params.next({
      title: 'Filter',
      icon: 'funnel-outline',
      accordions: [
        {
          target: '',
          color: '',
          icon: 'list-outline',
          title: 'Status'
        },
        {
          target: '',
          color: '',
          icon: 'list-outline',
          title: 'Priority'
        },
        {
          target: '',
          color: '',
          icon: 'list-outline',
          title: 'Category'
        },
        {
          target: '',
          color: '',
          icon: 'list-outline',
          title: 'Type'
        }
      ]
    })
  }

  checkedSegment(event) {
    //console.log(this.queryString.search_data == event.id, this.queryString.search_data, event.id);
    //if (this.queryString.search_data == event.id)

    return this.queryString.search_data == event.id
  }


  /**
   * @description after click segment switch for selected status
   * @param data = this.queryString LocalStorage
   */
  loadProjects(data: any) {


    this.isLoading++;
    if (data.search_data === 'all') { //category

      if (this.projectId)
        this.getOrdersListById(data)
      else
        this.getOrders(data)


    } else {

      if (this.projectId)
        this.getOrdersListById(data)
      else
        this.getOrders(data)

    }
  }

  concateQuery(search) {
    console.log(search);


    const index = this.columns.findIndex(item => item.name === "Status");
    console.log(index)


    if (this.page_number <= 1 && index == -1)
      this.columns.splice(2, 0, { prop: 'isActive', name: 'Status', width: 95, maxWidth: 95, minWidth: 95, cellClass: 'td-status', headerClass: 'th-status' })


    let url = '?page=' + this.page_number + '&items=' + this.page_limit;
    let query = '';


    if (search && search != '' && search.search_data != 'all') {
      query += '&' + search.search_by + '=';
      query += search.search_data;
      url += query
      let filtered = this.columns.filter(obj => obj.name !== 'Status');


      console.log(filtered);

      this.columns = [...filtered]

    } else {
      this.columns = [...this.columns]
    }

    console.log(url);
    return url
  }

  /**
   * @description Tigger on click on segment filter on top view 
   * @param search_by 
   * @param search_data 
   * @param btn_segment template ref of element in segment
   */
  filterOrders(search_by, search_data, btn_segment, event) {
    if (this.queryString.search_data != '-') {
      console.log(event);

      this.total_order = 0;
      this.total_page = 0;
      this.page_number = 1;

      this.users = []
      this.cache = {}
      const selectedStatus = search_data.value;
      this.currentSegment = selectedStatus;

      this.queryString = { search_by, search_data: this.currentSegment }
      localStorage.setItem('filter_search_users', JSON.stringify(this.queryString))
      this.switch.emit()
      this.loadProjects(this.queryString);
    }

  }

  /**
   * @description Get order metadata
   */
  getOrdersMeta() {

    /* 
        this.api.getOrderTypes()
          .subscribe(data => {
            //console.log(data);
            this.order_types = data
          })
        this.api.order_categories_list()
          .subscribe(data => {
            //console.log(data);
            this.order_categories = data
          })
        this.api.getOrderStatus()
          .subscribe(data => {
            //console.log(data);
            this.order_status = data
            this.order_status.push(
              {
                id: 'all',
                name: 'All'
              },
    
            )
          }) */

  }




  triggerColumnChangeDetection(): void {
    this.columnChangesService.onInputChange();
  }




  onDetailToggle(event) {
    console.log('Detail Toggled', event);
  }

  toggleExpandRow(row) {
    console.log('Toggled Expand Row!', row);
    this.table.rowDetail.collapseAllRows();
    this.table.rowDetail.toggleExpandRow(row);
  }

  /**
   * @description Aggiunge elementi allo scroll incrementando le pagina per l'api 
   * @param pageInfo ritorna dall'evento default con interfaccia dichiarata in alto PageInfo
   * @returns La paginazione fino al confronto pagine totati pagina attuale interrogata
   */
  setPage(pageInfo: PageInfo) {
    //console.log('pageInfo', this.n);
    //console.log(pageInfo);
    //console.log(this.cache);
    this.n++;

    // Current page number is determined by last call to setPage
    // This is the page the UI is currently displaying
    // The current page is based on the UI pagesize and scroll position
    // Pagesize can change depending on browser size
    this.pageNumber = pageInfo.offset;

    // Calculate row offset in the UI using pageInfo
    // This is the scroll position in rows
    const rowOffset = pageInfo.offset * pageInfo.pageSize;

    // When calling the server, we keep page size fixed
    // This should be the max UI pagesize or larger
    // This is not necessary but helps simplify caching since the UI page size can change
    const page = new Page();
    page.size = this.page_limit;
    page.pageNumber = Math.floor(rowOffset / page.size);

    //console.log(page.pageNumber);
    //console.log(this.cache);

    // We keep a index of server loaded pages so we don't load same data twice
    // This is based on the server page not the UI

    if (this.cache[page.pageNumber]) return;
    this.cache[page.pageNumber] = true;

    if (this.n <= 1) { this.cache = {} }; // esclude l'errore del conteggio pagina in cache

    // Chiamo l'api vericicando il tot elementi, le pagine e i risultati per pagina
    this.loadProjects(this.queryString)

  }


  sorted(columnProp: string, direction: string) {
    console.log(columnProp, direction);
    if (columnProp == 'priority.name') {
      columnProp = 'priority.id'
    }

    this.queryFilter = { search_by: 'sort', search_data: [{ prop: columnProp, dir: direction }] }
    localStorage.setItem('filter_sort_users', JSON.stringify({ search_by: 'sort', search_data: [{ prop: columnProp, dir: direction }] }))

    console.log(this.queryFilter);
  }


  onSelect({ selected }) {
    console.log('Select Event', selected, this.selected);
  }

  /**
   * @description onClick on row open order details
   * @param event pass obj row clicked
   */
  onActivate(event) {
    /* if (this.table.rowDetail && event.type == 'click') {
      this.table.rowDetail.toggleExpandRow(event.row);
      console.log('Activate Event', event);
    } */

    if (event.type == 'click') {
      console.log('Activate Event', event);
      //this.openOrderModal(event.row)
      this.clicked.emit(event);
    }


  }

  setExpandStatus(expanded: any): void {
    console.log('setExpandStatus Event', expanded);
  }



  onGetRowClass = (row) => {
    //console.log(row);
    // id 3 is Delete for Status Order
    /*   if (row.status.id === 3) {
        return 'deleted';
      } else if (row.status.id === 2) {
        return 'request';
      } else if (row.status.id === 1) {
        return 'draft';
      } else if (row.status.id === 4) {
        return 'order';
      } */
  }


  /**
   * @description ritorna la somma delle colonne che gli si assegna questa funzione
   * @param n 
   * @returns somma della colonna in "rowSummary" template
   */
  caclulateSumm(n?) {
    console.log();

    this.total_order = 0;
    this.users.map((item) => {
      this.total_order += item.cost;
      //this.currencyCode = item.currency;
      //console.log(this.total_order);
    });
    return this.total_order.toFixed(2) + ' ' + n;
  }

  getOrders(url) {


    if (url && url != '') {
      url = this.concateQuery(url)
    }

    console.log('load api list');

    this.users = arrayMok
    this.totalElements = this.users.length
     /**
          * @description la migliore soluzione per risolvere il problema dopo aver scrollato in una sezione
          * @fix https://github.com/swimlane/ngx-datatable/issues/861/#issuecomment-733851318
          */
      setTimeout(() => {
        this.table.bodyComponent.offsetX = 0;
        this.table.bodyComponent.offsetY = 0;
        this.table.headerComponent.offsetX = 0;
        this.table.recalculateColumns();
        this.users = [...this.users]
      }, 100);
      this.isLoading--;
    
      
    return
    this.projectApi.getLocationList_rev(url).subscribe(data => {
      console.log(data, data.results)
      console.log(data.total_items, data.num_pages, (this.page_number));

      //this.totalElements = data.total_items;
      this.totalElements = 15;

      if (data.num_pages >= (this.page_number)) {

        // Update total count

        // Create array to store data if missing
        // The array should have the correct number of with "holes" for missing data
        if (!this.users) {
          this.users = new Array<any>(this.totalElements || 0);
        }

        // Calc starting row offset
        // This is the position to insert the new data
        const start = this.page_number * 25

        let arr = arrayMok
        // Copy existing data
        const rows = [...this.users];

        // Insert new rows into correct position
        rows.splice(start, 25, ...arr);

        this.users = [...rows];

        if (this.page_number == 1) {
          /**
          * @description la migliore soluzione per risolvere il problema dopo aver scrollato in una sezione
          * @fix https://github.com/swimlane/ngx-datatable/issues/861/#issuecomment-733851318
          */
          setTimeout(() => {
            this.table.bodyComponent.offsetX = 0;
            this.table.bodyComponent.offsetY = 0;
            this.table.headerComponent.offsetX = 0;
            this.table.recalculateColumns();
            this.users = [...this.users]
          }, 100);
        }
        // Decrement the counter of pending API calls
        this.page_number++;
        this.isLoading--;
      } else {
        console.log('return if n_page >= page_number', this.page_number);
        this.isLoading--;

      }
    });
  }



  getOrdersListById(url) {


    if (url && url != '') {
      url = this.concateQuery(url)
    }

    console.log('load api list by project id');

   
    this.users = arrayMok
    this.totalElements = this.users.length
     /**
          * @description la migliore soluzione per risolvere il problema dopo aver scrollato in una sezione
          * @fix https://github.com/swimlane/ngx-datatable/issues/861/#issuecomment-733851318
          */
      setTimeout(() => {
        this.table.bodyComponent.offsetX = 0;
        this.table.bodyComponent.offsetY = 0;
        this.table.headerComponent.offsetX = 0;
        this.table.recalculateColumns();
        this.users = [...this.users]
      }, 100);
      this.isLoading--;
    
    return
    this.projectApi.getProjectById(this.projectId).subscribe(data => {
      console.log(data, data.results)
      console.log(data.total_items, data.num_pages, (this.page_number));

      this.totalElements = data.total_items;

      if (data.num_pages >= (this.page_number)) {

        // Update total count

        // Create array to store data if missing
        // The array should have the correct number of with "holes" for missing data
        if (!this.users) {
          this.users = new Array<any>(this.totalElements || 0);
        }

        // Calc starting row offset
        // This is the position to insert the new data
        const start = this.page_number * Number(data.items_per_page);


        let arr = arrayMok

        // Copy existing data
        const rows = [...this.users];

        // Insert new rows into correct position
        rows.splice(start, Number(data.items_per_page), ...arr);


        console.log(this.page_number);

        if (this.page_number == 1) {
          /**
           * @description la migliore soluzione per risolvere il problema dopo aver scrollato in una sezione
           * @fix https://github.com/swimlane/ngx-datatable/issues/861/#issuecomment-733851318
           */
          setTimeout(() => {
            this.table.bodyComponent.offsetX = 0;
            this.table.bodyComponent.offsetY = 0;
            this.table.headerComponent.offsetX = 0;
            this.table.recalculateColumns();
            this.users = [...this.users]
          }, 100);
        }
        // Decrement the counter of pending API calls
        this.page_number++;
        this.isLoading--;
      } else {
        console.log('return if n_page >= page_number', this.page_number);
        this.isLoading--;

      }
    });
  }

}




