import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Platform, ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, map, tap, timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../authentication/authentication.service';
import { NetworkService, ConnectionStatus } from '../network/network.service';
//import { Storage } from '@ionic/storage';
import { OfflineManagerService } from '../network/offline-manager.service';

//import environment from '../../../config.json';
import { Project } from 'src/app/projects/models/projects';
import { Docs } from 'src/app/documents/models/docs';


const mokService2 = [
  {
    "id": 1,
    "type": "DIR",
    "code": "001",

    "name": "project 01",
    "path": "/animals",

  },
  {
    "id": 2,
    "type": "DIR",
    "code": "002",

    "name": "hibiscus",
    "path": "/home",

  }

]

const API_STORAGE_KEY = 'prjService';
const TOKEN_KEY = 'access-token';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private url: string = 'http';
  private token: any;
  private projects: Project[];
  private documents: Docs[];
  private document: Docs;
  private project_types: any[];
  public docList = new BehaviorSubject(null);

  constructor(
    private storage: Storage,
    private plt: Platform,
    private http: HttpClient,
    public toastController: ToastController,
    private authService: AuthenticationService,
    private networkService: NetworkService,
    private offlineManager: OfflineManagerService
  ) {

    /**
     * Map url API
     */
    this.url = environment.SSL.active ? this.url + 's' : this.url
    this.url += '://' + environment.API_URI.url + ':' + environment.API_URI.port + environment.API_URI.path

    this.authService.currentToken.subscribe(x => {
      //console.log(x);
      this.token = x //= JSON.parse(localStorage.getItem(TOKEN_KEY))

    })

  }


  getProjectsList(forceRefresh: boolean = true): Observable<Project[]> {
    //return of(mokService);
    this.logSys('getProjectsList', forceRefresh)

    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline || !forceRefresh) {
      // Return the cached data from Storage
      return from(this.getLocalData('projects'));
    } else {
      return this.http.get<any>(`${this.url}project/projects/`)
        .pipe(
          timeout(16000),
          tap(data => {
            console.log(data);
            this.setLocalData('projects', data);
            this.projects = data;
          }),
          catchError(this.handleError<any[]>('Get projects', []))
        );
    }
  }

  getProjectsByCategory(category: string): Observable<Project[]> {
    return this.getProjectsList().pipe(map(projects => projects.filter(project => project.category == category)));
  }
  getProjectsByStatus(status: string): Observable<Project[]> {
    return this.getProjectsList().pipe(map(projects => projects.filter(project => project.status == status)));
  }
  old_getProjectById(id: number): Observable<Project> {
    return this.getProjectsList().pipe(map(projects => projects.find(project => project.id == id)));
  }

  getProjectById(id: number): Observable<Project> {
    return this.http.get<any>(`${this.url}project/project/${id}/`)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
        }),
        catchError(this.handleError<any[]>('Get project by id', []))
      );
  }


  getTypesList(): Observable<any[]> {
    return this.http.get<any>(`${this.url}project/types/`)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
          this.setLocalData('project-types-list', data);
          this.project_types = data;
        }),
        catchError(this.handleError<any[]>('Get project types list', []))
      );
  }

  createProject(project: Project) {
    return this.http.post<any>(`${this.url}project/project/create/`, project)
      .pipe(
        tap(data => {
          console.log(data);
        }),
        catchError(this.handleError<any[]>('Create project', []))
      );
  }

  updateProject(project: Project): Observable<Project> {
    //const itemIndex = this.projects.findIndex(item => item.id == project.id);
    //this.projects[itemIndex] = project;
    //this.setLocalData('projects', this.projects);
    return this.http.put<any>(`${this.url}project/project/${project.id}/`, project)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
        }),
        catchError(this.handleError<any[]>('Put update project by id', []))
      );
  }

  updateStatusProject(project: Project): Observable<any> {
    //const itemIndex = this.projects.findIndex(item => item.id == project.id);
    //this.projects[itemIndex] = project;
    //this.setLocalData('projects', this.projects);
    const prj = {
      id: project.id,
      status: project.status
    }

    return this.http.put<any>(`${this.url}project/project/status/`, prj)
      .pipe(
        timeout(16000),
        tap(data => {
          console.log(data);
        }),
        catchError(this.handleError<any[]>('Put update status project by id', []))
      );
  }

  deleteProject(id: number): Project {
    const itemIndex = this.projects.findIndex(item => item.id == id);
    return this.projects.splice(itemIndex, 1)[0];
  }



  private handleError<T>(operation = 'operation', result?: T) {

    return (error: any): Observable<T> => {

      //console.error(error);
      console.log(result, error);

      console.log(`${operation} failed`, error);
      const status = error == 'Forbidden' ? 403 : 500
      this.presentToast(status, error == 'undefined' ? 'Internal Server Error' : error, operation)
      return of(result as T);
    };
  }


  async presentToast(status, statusText, message) {
    const toast = await this.toastController.create({
      message: status + ' ' + statusText + ': "' + message + '"',
      duration: 2000,
      mode: 'ios',
      cssClass: 'toast',
      color: status != '200' ? 'danger' : 'primary'
    });
    toast.present();
  }

  logSys(src, status, opt?) {
    const debugStyle1 = 'background: linear-gradient(135deg,#471ee9,#3a49b7); border: 1px solid #9a9a9a; color: #ffffff; border-bottom-left-radius: 2px; border-top-left-radius: 2px; padding: 2px 0 2px 4px;';
    const debugStyle2 = 'background: #252b3e; border: 1px solid #9a9a9a; border-top-right-radius: 2px; border-bottom-right-radius: 2px; margin-left: -2px; padding: 2px 4px; color: white;';

    //console.log('ForceRefresh API ', status, this.networkService.getCurrentNetworkStatus(), this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline);
    console.log(`%cApiService %c%s`, debugStyle1, debugStyle2, ' ' + src, status);
  }




  // Save result of API requests
  private setLocalData(key, data) {
    this.storage.set(`${API_STORAGE_KEY}-${key}`, data);
  }

  // Get cached API result
  private getLocalData(key) {
    return this.storage.get(`${API_STORAGE_KEY}-${key}`);
  }




  /**
   * Documents
   */

  getRecentDocumentsList(forceRefresh: boolean = true): Observable<Docs[]> {


    return of(mokService2);

  }

  getDocumentsList(forceRefresh: boolean = true, url: string, id: number): Observable<Docs[]> {

    this.logSys('getDocumentsList', { forceRefresh, url, id })
    //console.log(id == 0, Number.isNaN(id), url, id);

    console.log(`${this.url}documan/element${id == 0 ? ('s/' + url) : ('/' + id)}/`);

    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline || !forceRefresh) {
      // Return the cached data from Storage
      return from(this.getLocalData('documents'));
    } else {
      return this.http.get<any>(`${this.url}documan/element${id == 0 || Number.isNaN(id) ? ('s/' + url) : '/' + id + '/' + url}`)
        .pipe(
          timeout(8000),
          tap(data => {
            console.log(data);
            this.setLocalData('documents', data);
            return this.documents = data;
          }),
          catchError(this.handleError<any[]>('Get documents', []))
        );
    }

  }

  getDocumentInfo(forceRefresh: boolean = true, url: string, id: number): Observable<Docs[]> {

    this.logSys('getDocumentInfo', { forceRefresh, url, id })

    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline || !forceRefresh) {
      // Return the cached data from Storage
      return from(this.getLocalData('info-document'));
    } else {
      return this.http.get<any>(`${this.url}documan/fileinfo/${id}/`)
        .pipe(
          timeout(8000),
          tap(data => {
            console.log(data);
            this.setLocalData('info-document', data);
            return this.document = data;
          }),
          catchError(this.handleError<any[]>('Get info document', []))
        );
    }
  }

  getDownload(element_id: number, file_id): Observable<any[]> {

    this.logSys('getDownload', element_id, file_id)

    return this.http.get<any>(`${this.url}documan/element/${element_id}/download/${file_id}/`)
      .pipe(
        timeout(8000),
        tap(data => {
          console.log(data);
          return data;
        }),
        catchError(this.handleError<any[]>('Get dowload document', []))
      );
  }


  searchDocument(forceRefresh: boolean = true, url: string, string: string): Observable<Docs[]> {

    this.logSys('searchDocument', { forceRefresh, url, string })

    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline || !forceRefresh) {
      // Return the cached data from Storage
      return from(this.getLocalData('search-document'));
    } else {
      let body = {
        txt: string.toString()
      };

      return this.http.post<any>(`${this.url}documan/element/search/${url}`, body)
        .pipe(
          timeout(8000),
          tap(data => {
            console.log(data);
            this.setLocalData('search-document', data);
            this.documents = data;
          }),
          catchError(this.handleError<any[]>('Search document', []))
        );
    }

  }


  _upload(formData): Observable<HttpEvent<any>> {
    //const formData: FormData = new FormData();

    //formData.append('file', file);

    const req = new HttpRequest('POST', `https://192.168.1.26/php/upload.php`, formData, {
      reportProgress: true,
      responseType: 'json'
    });

    return this.http.request(req);
  }

  _getFiles(): Observable<any[]> {
    return this.http.get<any>(`https://192.168.1.26/php/files.php`).pipe(
      timeout(16000),
      tap(data => {
        this.docList.next(of(data))
        this.docList.value
      }),
      catchError(this.handleError<any[]>('Get documents', []))
    );
  }


  _uploadFiles(file): Observable<any> {
    console.log(file);
    // Create form data
    const formData = new FormData();
    // Store form name as "file" with file data
    //formData.append("file", file, file.name);

    // Make http post request over api
    // with formData as req
    return this.http.post(`https://192.168.1.110/php/upload.php`, file);
  }


  upload(isRenew = false, item, formData, data): Observable<HttpEvent<any>> {

    let url = `${this.url}documan/upload`;

    if (isRenew) {

      console.log(formData, item, data);

      let element_id = data.element
      let file_id = data.id
      url = `${this.url}documan/element/${element_id}/renew/${file_id}/`

      console.log(url);

    }

    const req = new HttpRequest('POST', url, formData, {
      reportProgress: true,
      responseType: 'json'
    });

    return this.http.request(req);

  }

  getFiles(folder_id, url): Observable<any> {
    console.log('get files ', folder_id);


    let _url = `${this.url}documan/element/${folder_id}/${url}`
    return this.http.get<any>(_url).pipe(
      timeout(16000),
      tap(data => {
        //console.log(data);
        this.docList.next(data)
        //this.docList.value
        return data
      }),
      catchError(this.handleError<any[]>('Get documents', []))
    );

  }



  uploadFiles(id, item: any) {
    console.log(id, item);
    
    return this.http.post<any>(`${this.url}order/order/${id}/upload/`, item)
      .pipe(
        tap(data => {
          console.log(data);
        }),
        catchError(this.handleError<any[]>('Upload file orders', []))
      );
  }

  getFiles2(id) {
    return this.http.get<any>(`${this.url}order/order/${id}/upload/`,)
      .pipe(
        tap(data => {
          console.log(data);
        }),
        catchError(this.handleError<any[]>('get file orders', []))
      );
  }

  downloadFile(order_id, doc_id): Observable<any> {

    return this.http.get(`${this.url}order/order/${order_id}/download/${doc_id}/`, {
      observe: 'response',
      responseType: 'blob'
    })
      .pipe(
        tap(data => {
          console.log(data);
          return data
        }),
        catchError(this.handleError<any[]>('download file orders', []))
      );
  }

  orderDocumentInfos(order_id, doc_id) {

    return this.http.get<any>(`${this.url}order/order/${order_id}/fileinfo/${doc_id}/`)
      .pipe(
        tap(data => {
          console.log(data);
        }),
        catchError(this.handleError<any[]>('infos file orders', []))
      );
  }








}
