import { Component, Input, OnInit } from '@angular/core';
import { ECharts, EChartsOption } from 'echarts';
import { ThemeService } from 'src/app/services/theme/theme.service';

@Component({
  selector: 'app-graphic-stroke-animation',
  templateUrl: './graphic-stroke-animation.component.html',
  styleUrls: ['./graphic-stroke-animation.component.scss'],
})
export class GraphicStrokeAnimationComponent implements OnInit {

  @Input() pie
  options: EChartsOption;
  echartsInstance: ECharts;
  loadingGraph = true;
  theme: string = 'cpj'
  loadingOpts
  dark: any = true

  constructor(
    private themeDetection: ThemeService
  ) {
    this.loadingOpts = {
      text: 'loading',
      color: 'rgb(0, 145, 147)',
      textColor: !this.dark ? 'rgba(18,18,18,0.9)' : 'rgba(255,255,255,0.9)',
      maskColor: this.dark ? 'rgba(18,18,18,0.9)' : 'rgba(255,255,255,0.9)',
      zlevel: 0
    }

    this.dark = this.themeDetection.observStatus()


    this.themeDetection.dark.subscribe(e => {
      console.log(e);

      if (!e)
        return

      this.dark = e
      this.loadingOpts = {
        text: 'loading',
        color: 'rgb(0, 145, 147)',
        textColor: !this.dark ? 'rgba(18,18,18,0.9)' : 'rgba(255,255,255,0.9)',
        maskColor: this.dark ? 'rgba(18,18,18,0.9)' : 'rgba(255,255,255,0.9)',
        zlevel: 0
      }
    })
    this.loadingGraph = true;

  }

  ngOnInit() {
    console.log('start graph');

    setTimeout(() => {
      this.theme = !this.pie ? 'fgb' : 'cpj'
      this.options = {
        graphic: {
          elements: [
            {
              type: 'text',
              left: 'center',
              top: 'center',
              style: {
                text: 'CoolProjects',
                fontSize: 80,
                fontWeight: 'bold',
                lineDash: [0, 200],
                lineDashOffset: 0,
                fill: 'transparent',
                stroke: '#000',
                lineWidth: 1
              },
              keyframeAnimation: {
                duration: 3000,
                loop: true,
                keyframes: [
                  {
                    percent: 0.7,
                    style: {
                      fill: 'transparent',
                      lineDashOffset: 200,
                      lineDash: [200, 0]
                    }
                  },
                  {
                    // Stop for a while.
                    percent: 0.8,
                    style: {
                      fill: 'transparent'
                    }
                  },
                  {
                    percent: 1,
                    style: {
                      fill: 'black'
                    }
                  }
                ]
              }
            }
          ]
        }
      };

      this.loadingGraph = false;

    }, 650)
  }


  onChartInit(ec) {
    this.echartsInstance = ec;
    console.log(ec);

  }

  onChartEvent(e, ele) {
    console.log(e, ele);
  }

  onChartLegend(e, ele) {
    console.log(e, ele);
  }
  onChartScroll(e, ele) {
    console.log(e, ele);
  }
}
