import { TasksService } from './../../../services/tasks/tasks.service';
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable object-shorthand */
/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/semi */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable quote-props */


import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ModalController, Platform, ToastController } from '@ionic/angular';
import { FormGroup, FormControl, Validators, FormGroupDirective } from '@angular/forms';
import { DataService } from '../../../services/data/data.service';
import * as moment from 'moment';
import { ApiService } from 'src/app/services/api/api.service';
import { IonDatetime } from '@ionic/angular';
import { format, parseISO } from 'date-fns';





@Component({
  selector: 'app-new-timeregistry',
  templateUrl: './new-timeregistry.component.html',
  styleUrls: ['./new-timeregistry.component.scss'],
})
export class NewTimeregistryComponent {


  @Input() work_hours: any;
  @Input() task: any;

  minDate = moment().format();
  maxDate = moment().add(1, 'd').format();

  
  

  createTimeregistryForm: FormGroup;
  types_category: any = [];
  subscription

  @ViewChild('createForm') createForm: FormGroupDirective;  

  constructor(
    private platform: Platform,
    private modalController: ModalController,
    private toastController: ToastController,
    private dataService: DataService,
    private api: TasksService
  ) { }

  dismissModal(status = '') {
    this.modalController.dismiss(status);
  }

  ionViewDidEnter() {
    this.subscription = this.platform.backButton.subscribe((e) => {
      console.log(e);
      this.dismissModal()
    });
  }

  ionViewWillLeave() {
    this.subscription.unsubscribe();
  }

  ionViewWillEnter(): void {   
    console.log(this.task);
    

    this.createTimeregistryForm = new FormGroup({
      'hours': new FormControl('', Validators.required),
      'description': new FormControl('', Validators.required)       
    });

   
  }


  submitForm() {
    this.createForm.onSubmit(undefined);
  }

  async presentToast(text, title, color) {
    const toast = await this.toastController.create({
      message: title + ': ' + (text.message),
      position: 'bottom',
      duration: 2000,
      cssClass: 'toast',
      color: color
    });
    toast.present();
  }

  createTimeregistry(values: any) {
    // copy all the form values into the new timeregistry
    const newTimeregistry: any = {};    
    newTimeregistry['id'] = (parseInt(this.work_hours[this.work_hours.length -1].id.replace('0', "")) + 1).toString().padStart(7, "0");         
    newTimeregistry['task'] = this.task[this.task.length - 1].title  
    newTimeregistry['description'] = values.description
    newTimeregistry['hours'] = parseInt(values.hours)

    
    //this.work_hours.push(newTimeregistry);
    this.dismissModal(newTimeregistry);
    console.log(this.work_hours, newTimeregistry);
  
  }

  epochDate(date){
    var epoch = moment(date).unix();
    return epoch.toString();
  }

  /* onChangeStartDate(event) {
    this.maxDate = moment(event.detail.value).add(1, 'd').format();
    this.createTimeregistryForm.controls.end_date.setValue(this.maxDate)
  } */

  /* formatDate(value: string) {
    return format(parseISO(value), 'MMM dd yyyy');
  } */
  onChangeStartDate(event) {
    this.maxDate = moment(event.detail.value).add(1, 'd').format();
    //this.createTimeregistryForm.controls.date_pre_end.setValue(this.maxDate)
  }

  formatDate(value: string) {
    return format(parseISO(value), 'MMM dd yyyy');
  }
}
