// routing-state.service.ts
import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RoutingStateService {
  private history: string[] = [];

  constructor(private router: Router, private location: Location) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(({urlAfterRedirects}: NavigationEnd) => {
        this.history.push(urlAfterRedirects);
      });
  }

  public getHistory(): string[] {
    return this.history;
  }

  public getLastUrl(): string | undefined {
    return this.history.length > 1 ? this.history[this.history.length - 2] : undefined;
  }

  public navigateBack(): void {
    if (this.history.length > 1) {
      this.location.back(); // Naviga indietro
      this.history.pop(); // Rimuove l'ultimo segmento visitato
    }
  }

  public clearHistory(): void {
    this.history = [];
  }
}
