// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .item-input.sc-ion-label-md-h,
.item-input .sc-ion-label-md-h {
  flex: auto;
  max-width: 100%;
  pointer-events: none;
}

ion-input.custom {
  /*  --background: #373737;
   --color: #fff;
   --placeholder-color: #ddd;
   --placeholder-opacity: 0.8;

   --padding-bottom: 10px;
   --padding-end: 10px;
   --padding-start: 10px;
   --padding-top: 10px; */
  text-align: right;
}

ion-toolbar.toolbar-background {
  --background: #a11313 !important;
}
ion-toolbar.toolbar-background ion-button {
  margin-left: 18px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/orders/components/modal-order-legal/modal-order-legal.component.scss"],"names":[],"mappings":"AAAA;;EAGI,UAAA;EACA,eAAA;EACA,oBAAA;AACJ;;AAEA;EACG;;;;;;;;yBAAA;EASC,iBAAA;AACJ;;AAEA;EACI,gCAAA;AACJ;AAAI;EACI,4BAAA;AAER","sourcesContent":["::ng-deep .item-input.sc-ion-label-md-h,\n.item-input .sc-ion-label-md-h {\n    -ms-flex: initial;\n    flex: auto;\n    max-width: 100%;\n    pointer-events: none;\n}\n\nion-input.custom {\n   /*  --background: #373737;\n    --color: #fff;\n    --placeholder-color: #ddd;\n    --placeholder-opacity: 0.8;\n\n    --padding-bottom: 10px;\n    --padding-end: 10px;\n    --padding-start: 10px;\n    --padding-top: 10px; */\n    text-align: right;\n}\n\nion-toolbar.toolbar-background {\n    --background: #a11313 !important;\n    ion-button{\n        margin-left: 18px !important;\n    }\n   }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
