// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-message {
  font-size: 10pt;
  /* text-align: center; */
  padding: 4pt;
  color: red;
}

.form {
  width: 100%;
}

.validation-errors {
  position: absolute;
  display: flex;
  margin-bottom: 5px;
}

ion-button {
  text-transform: capitalize;
  height: 45px;
  font-size: 1em;
  margin-bottom: 50px;
  margin-top: 15px;
}`, "",{"version":3,"sources":["webpack://./src/app/public/register/register.page.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,wBAAA;EACA,YAAA;EACA,UAAA;AACJ;;AAEA;EACI,WAAA;AACJ;;AAEA;EACI,kBAAA;EACA,aAAA;EACA,kBAAA;AACJ;;AAEA;EACI,0BAAA;EACA,YAAA;EACA,cAAA;EACA,mBAAA;EACA,gBAAA;AACJ","sourcesContent":[".error-message {\n    font-size: 10pt;\n    /* text-align: center; */\n    padding: 4pt;\n    color: red;\n}\n\n.form {\n    width: 100%;\n}\n\n.validation-errors {\n    position: absolute;\n    display: flex;\n    margin-bottom: 5px;\n}\n\nion-button {\n    text-transform: capitalize;\n    height: 45px;\n    font-size: 1em;\n    margin-bottom: 50px;\n    margin-top: 15px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
