import { AfterViewInit, ChangeDetectorRef, Component, ComponentFactoryResolver, ComponentRef, ElementRef, Input, OnInit, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { IonSelect, ModalController, Platform, ToastController } from '@ionic/angular';
import { ProductsService } from 'src/app/services/products/products.service';
import { VendorsService } from 'src/app/services/vendors/vendors.service';
import { NewVendorComponent } from 'src/app/vendors/new-vendor/new-vendor.component';

import { Product } from '../models/product.model';

@Component({
  selector: 'app-new-service',
  templateUrl: './new-service.component.html',
  styleUrls: ['./new-service.component.scss'],
})
export class NewServiceComponent {
createProduct(arg0: any) {
throw new Error('Method not implemented.');
}
  @Input() title: string;
  @Input() fromOrder: boolean;
  @Input() dataProps: string;
  @ViewChild('createForm') createForm: FormGroupDirective;
  @ViewChild('wrapperAddress', { read: ViewContainerRef }) target: ViewContainerRef;
  @ViewChild('selectRef') selectRef: IonSelect;

  
  amount
  selectedValueModel
  selectedValueFeature
  fvalue_id
  checkChanges
  featureName
  featureStatus

  
  /* Set param component add-item-select-option */
  createFormModel: FormGroup;
  createFormFeature: FormGroup;
  indices = [1, 2, 3, 4, 5, 6];
  
  items_model = [
    { 'label': 'Name', 'formControlName': 'name', 'inputMode': 'text' },
    { 'label': 'Code', 'formControlName': 'code', 'inputMode': 'text' },
  ]
  
  items_feature = [
    { 'label': 'name', 'formControlName': 'name', 'inputMode': 'text' },
  ]
  submitButtonParamModel = { 'label': 'Add Model', 'function': 'submitFormModel()' }
  // group form general
  createServiceForm: FormGroup;
  types: any = [];
  models: any = [];
  categories: any = [];
  features:any = [];
  subscription
  response
  checkModel = false
submitButtonParam: any;
selectedValueCategory: any;
  
  constructor(
    private platform: Platform,
    private modalController: ModalController,
    private fb: FormBuilder,
    private api: ProductsService,
    private toastController: ToastController
    
    ) {
      this.response = ''
      
    }
    
    
    
    submitFormModel(event, type?) {
      if(this.fromOrder){
        this.createNewServiceFromOrder(event.form)
      }
      else{
    console.log('Submit Model', event);
    this.createNewModel(event.form)
  }
}
    
    submitFormFeature(event, type?) {
      console.log('Submit Model', event);
      this.createNewFeature(event.form, type)
    }
    
    createNewModel(values: any, type?) {
      const newModel: any = { ...values };
      this.api.product_models_create(newModel).subscribe(e => {
        this.models.push(e.data);
        
        this.selectedValueModel = e.data.id;
        this.createServiceForm.controls['product_id'].setValue(e.data.id);
      })
    }

    createNewServiceFromOrder(values: any, type?) {
      const newModel: any = { ...values };
      this.api.service_create_from_orders(newModel).subscribe(e => {
        this.models.push(e.data);
        
        this.selectedValueModel = e.data.id;
        this.createServiceForm.controls['service_id'].setValue(e.data.id);
      })
      this.modalController.dismiss()
    }
    
    createNewFeature(values: any, type?:any) {
      const newFeature: any = { ...values };
      this.api.product_featurevalues_create(newFeature).subscribe(e => {
        const feature = this.features.find(item => item.name === type);
        feature.values.push(e.data);
        this.features = [...this.features]; // Assegnazione per aggiornare l'array
        let control = this.fvalue_id
        this.createServiceForm.controls[control].setValue(e.data.id);
        this.checkChanges = false 
      })
    }
    
    getProductMeta() {
      
      let url = '?page=' + 0 + '&items=' + 25;
      
      /* this.api.getProductTypes(url).subscribe(e => {
        this.types = e.results
        //this.createServiceForm.controls['type'].setValue(this.types[0].id)
      }) */ /* #Ric Service type list non pervenuta */
      
      this.api.product_categories_list(url).subscribe(e => {
        console.log(e)
        this.categories = e.results
      })
      
      this.api.product_models_list(url).subscribe(e => {
        console.log(e)
        this.models = e.results
      })
      
      
      this.api.product_features_list(url).subscribe(e => {
        console.log(e)
        this.features = e.results
      })
      
    }
    
    dismissModal(message?) {
      if (message)
      this.response = message;
      this.modalController.dismiss({ message: this.response });
    }
    
    
    ionViewDidEnter() {
      this.subscription = this.platform.backButton.subscribe((e) => {
        console.log(e, this.response);
        this.dismissModal()
      });
    }
    
    ionViewWillLeave() {
      this.subscription.unsubscribe();
    }
    
    
    
    ionViewWillEnter() {
      console.log('new service');
      this.response = '';
      
      
      
      // Generete FormGroup Create Vendor 
      this.createServiceForm = this.fb.group({
        service_id: this.fb.control('', Validators.required), //model
        name: this.fb.control('', [Validators.minLength(5), Validators.maxLength(31), Validators.required]),
        code: this.fb.control('', [Validators.minLength(3), Validators.maxLength(31), Validators.required]),
        //type: this.fb.control(this.dataProps, Validators.required), //#Ric Producy type list api non pervenuta
        description: this.fb.control('', [Validators.minLength(1), Validators.maxLength(50), Validators.required]),
        udm: this.fb.control('pz', Validators.required),
       /* default_price: this.fb.control('', Validators.required),*/
        /* #Ric inizializzo a null perchè il database accetta o numerici o null */
        fvalue_1_id: this.fb.control(null),
        fvalue_2_id: this.fb.control(null),
        fvalue_3_id: this.fb.control(null),
        fvalue_4_id: this.fb.control(null),
        fvalue_5_id: this.fb.control(null),
        fvalue_6_id: this.fb.control(null),
      });
      
      
      this.createFormFeature = this.fb.group({
        'feature_id': this.fb.control('', Validators.required),
        'name': this.fb.control('', Validators.required),
      });
      
      
      
      
      this.getProductMeta();
      
    }
    
    onModelChange(event: any) {
      const selectedModelId = event.detail.value;
      const selectedItem = this.models.find(item => item.id === selectedModelId);
      console.log('Item selezionato:', selectedItem);
      if (selectedItem == undefined) {
        this.selectedValueModel = 'new';
        this.createFormModel.patchValue({ service_id: '' })
      }
      else {
        this.selectedValueModel = event.detail.value
        this.createServiceForm.patchValue({service_id: selectedItem.id})
      }
    }
    
    onFeatureChange(event: any, featureId:any, fvalue_id:any) {
      console.log('change')
      this.fvalue_id = fvalue_id
      this.checkChanges = true
      const selectedFeatureId = event.detail.value;
      const selectedItem = this.features.find(item => item.id === featureId);
      console.log('Item selezionato:', selectedItem);
      const selectedFeature = selectedItem.values.find(item => item.id === selectedFeatureId);
      console.log('Item selezionato:', selectedFeature);
      this.featureStatus = selectedFeature
      this.featureName = selectedItem.name
      this.createFormFeature.reset()
      if (selectedFeature == undefined) {
        this.selectedValueFeature = 'new';
        this.createFormFeature.patchValue({ feature_id: featureId })       
      } else {
        this.selectedValueFeature = event.detail.value
      }
    }
    

    clearSelection(item: any) {
      console.log('dismiss')
      //this.selectedValueFeature = null;
       if(this.featureStatus == undefined){

        this.createServiceForm.controls['fvalue_' + item?.id + '_id'].setValue(null);
      }
    }
    
    // Call submitForm with click Save
    submitForm() {
      console.log('onSubmit');
      this.createForm.onSubmit(undefined);
    }
    
    
    createService(values: any) {
      // copy all the form values into the new vendor
      const newProduct: Product = { ...values };
      //newVendor.created = moment(newVendor.created).format('YYYY-MM-DD')
      
      console.log(newProduct);
      
      /*#Ric inizializzando gli fvalue_{{id}}_id = null, il database va in crisi quindi
      controllo ogni chiave fvalue_{{id}}_id ed elimino quelle nulle. 
      Poi chiamo l'api e faccio la post.   */
      
      Object.keys(newProduct).forEach((key) => {
        if (newProduct[key] === null) {
          delete newProduct[key];
        }
      });
      
      console.log(newProduct);
      
      this.api.product_products_create(newProduct).subscribe(e => {
        console.log(e);
        
        if (e.message != 'KO')
        this.response = { res: 'OK', data: e.data };
        else
        this.presentToast(e[0], 'Error', 'danger')
        
        this.dismissModal();
      })
    }
    
    
    async presentToast(text, title, color) {
      const toast = await this.toastController.create({
        message: title + ': ' + (text.message),
        position: 'bottom',
        duration: 2000,
        cssClass: 'toast',
        color: color
      });
      toast.present();
    }
    
    
    
    async openNewVendorModal() {
      const modal = await this.modalController.create({
        component: NewVendorComponent,
        canDismiss: true,
        componentProps: {
          title: 'Service - Add New Vendor',
          parent_type: 'root'
        }
        //presentingElement: this.routerOutlet.nativeEl,
      })
      
      
      modal.onWillDismiss().then((e) => {
        /*
        if (e.data && e.data.status != -1) {
          this.total_page = 0;
          this.page_number = 1;
          this.myInput.value = "";
          this.products = [];
          this.columns = [];
          this.getProducts(false, '')
        } */
      });
      return await modal.present();
    }
    errorControl:any
    get errorControl1() {
      
      return this.createServiceForm.controls;
    }
    
    /*amountChanged(event) {
      console.log('event', event);
      let range
      this.amount = event;
      this.createServiceForm.controls.default_price.setValue(event)
    }*/
    
    setTitle() {
      switch (this.dataProps) {
        case '1':
        this.title = 'Service'
        break;
        case '2':
        this.title = 'Service'
        break;
        case '3':
        this.title = 'Listino'
        break;
        
        default:
        break;
      }
    }
    
    
  }
  