// This file can be replaced during build by using the `fileReplacements` array.
// `ng build--prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  version: '0.0.2',
  production: false,
  GAPI_CLIENT_ID: '699518348942-cslqgega4ftgdv67vg9uio86n1ikn882.apps.googleusercontent.com',
  appId: 'com.cooltech.revolution',
  log: true,
  /* MQTT: {
    hostname: '95.217.176.255',
    port: 8080,
    path: '/mqtt',
    username: 'datastore',
    password: 'S3cr37_!!!',
    topic: 'FGB'
  }, */
  SSL: {
    active: true
  },
  API_URI_VPN: {
    url: "10.10.0.205",
    port: "8000",
    path: "/api/v1/"
  },
  API_URI: {
    url_old: "api.revolution.coolprojects.it",
    port_old: "80",
    url: "api.revolution.coolprojects.it",
    port: "443",
    path: "/api/v1/"
  },
  http: {
    headers: {
      options: {
        withCredentials: false,
        headers: {}
      }
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
