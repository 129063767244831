import { AfterViewInit, ChangeDetectorRef, Component, ComponentFactoryResolver, ComponentRef, ElementRef, Input, OnInit, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { VendorsService } from 'src/app/services/vendors/vendors.service';


@Component({
  selector: 'app-line-address',
  templateUrl: './new-line-address.component.html',
  styleUrls: ['./new-line-address.component.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class NewLineAddress {

  @Input() title: string;
  @Input() name: string = ''
  @ViewChild('createForm') createForm: FormGroupDirective;
  @ViewChild('wrapperAddress', { read: ViewContainerRef }) target: ViewContainerRef;
  private componentRef: ComponentRef<any>;
  private emailRegex = "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$";
  private maxRowAddress = 5;
  private maxRowPhone = 5;
  private maxRowEmail = 5;
  size = 20
  // group form general
  createAddressForm: FormGroup;
  portsSubscription
  types: any = [];
  addresses: any = [];
  typesAddress: any = []
  countries: any = []
  response 
  onPushAddress
  page_address = 1
  numPage_address = 0
  onSearchSelector = ''
  onSelectAddress
  filteredCountries: string[] = [];
  searchQuery: string = '';



  constructor(private modalController: ModalController,
    private fb: FormBuilder,
    private api: VendorsService,

  ) {

    this.api.getTypesList().subscribe(e => {
      console.log(e["results"])
      //this.types = e["results"]
      this.types = e["results"]
    })

    this.createAddressForm = this.fb.group({
        type_id: ['', Validators.required],
        country_id:['', Validators.required],
        street: ['', Validators.required],
        number: ['', Validators.required],
        cap: ['', Validators.required],
      })

    this.api.getTypeAddressesList().subscribe((data) => {
      console.log('!text call more getAddressTypesList', data);
      this.typesAddress = data.results
      console.log(this.typesAddress)
      //event.component.endInfiniteScroll();
    })

    this.api.getCountriesList().subscribe((data) => {
      console.log('!text call more countriesList', data);
      this.countries = data.results
      //event.component.endInfiniteScroll();
    })
  }

  

  dismissModal() {
    this.modalController.dismiss(this.response);
  }



  ionViewWillEnter() {
  }


  // Call submitForm with click Save
  submitForm() {
    console.log('onSubmit');
    this.createForm.onSubmit(undefined);
  }

  createAddress(values){

    console.log(values)
    const newAddress = { ...values };
    console.log(newAddress, values)

    this.api.createAddress(newAddress).subscribe(e => {
      console.log(e);
      if (e && e.data)
        this.response = e.data
      this.dismissModal();
    })
  }


  searchPorts(event/*:  { component: IonicSelectableComponent; text: string }, type */) {

    console.log(event, event.text);
    //console.log(event.component._modalComponent);
    //console.log(event.component._modalComponent._content);

    //event.component._modalComponent._content.scrollToBottom(1500);
    //event.component._modalComponent._content.scrollY = false

    let url = ''

    const text = event.text.trim().toLowerCase();
    //event.component.startSearch();


    // Close any running subscription.
    if (this.portsSubscription) {
      this.portsSubscription.unsubscribe();
    }


    if (!text) {

      // Close any running subscription.
      if (this.portsSubscription) {
        this.portsSubscription.unsubscribe();
      }


      //event.component.endSearch();
      //event.component.enableInfiniteScroll();
      switch (event['module']) {

        case 'address':
          this.page_address = 1

          url = '?page=' + this.page_address + '&items=' + this.size;

          this.api.getAddressesList(url).subscribe((data) => {
            console.log('!text call more getCustomersList', data);

            //data.results = event.component.items = (data.results);

            //this.numPage_project = data.num_pages;

            //event.component.items = data.results;
            this.onSearchSelector = ''
            this.numPage_address = data.num_pages;
            this.addresses = data.results
            //event.component.endInfiniteScroll();
          })
          break;

        default:
          console.log('Default');

          break;
      }

      return

    }

  }

  onSelect(event) {
    console.log(event);
    if (event.isSelected/*  && !this.project_in */) {
     if (event.module == 'address') {
        this.onSelectAddress = event.item
        this.createAddressForm.controls['address_id'].setValue(event.item.id)
      }
    } else if (!event.isSelected /* && !this.project_in */) {
      if (event.module == 'address') {
        this.onSelectAddress = ''
        this.createAddressForm.controls['address_id'].setValue(0)
      }
    }
  }

  onInfiniteScroll(/* 
  event: { component: IonicSelectableComponent; text: string },
  type: string */ event) {

  console.log(event);
  console.log(this.page_address, this.numPage_address);
  console.log(this.title);

  let url
  let _type = event['type'] ? event['type'] : null;
  console.log('after', _type);

  switch (_type) {

    case 'address':

      url = '?page=' + (++this.page_address) + '&items=' + this.size;
      console.log('entro address')
      this.api.getAddressesList(url).subscribe((data) => {
        console.log('!text call more getAddressList', data);

        //data.results = event.component.items = (data.results);

        //this.numPage_project = data.num_pages;

        //event.component.items = data.results;
        this.onSearchSelector = ''
        this.numPage_address = data.num_pages;
        this.addresses = data.results
        console.log(this.addresses)
        //event.component.endInfiniteScroll();
      })
      break;




    default:
      console.log('Default');

      break;

    //.... more code case
  }



}

filterCountries(event: any) {
  this.searchQuery = event.detail.value;
  console.log(this.searchQuery)
  if (this.searchQuery && this.searchQuery.trim() !== '') {
    this.filteredCountries = this.countries.filter((country) => {
      return country.name.toLowerCase().includes(this.searchQuery.toLowerCase());
    });
    //this.showCountryList = true; // Mostra la lista se ci sono risultati corrispondenti
  } else {
    this.filteredCountries = [];
  }
}


selectCountry(country: any) {
  this.createAddressForm.get('country_id').setValue(country.id);
  this.searchQuery = country.name;
  console.log(this.createAddressForm.value)
}


}
