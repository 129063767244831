// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content {
  --padding-top: 16px;
}
ion-content .add-photo-wrapper {
  justify-content: center;
}
ion-content .task-image {
  --size: 50px;
  margin: auto;
}

ion-title {
  padding-inline-start: 0;
  padding-inline-end: 0;
  text-align: center;
}

.size-14 {
  font-size: 1.4em;
  /*margin-right: 20px;*/
}

.custom-label ion-icon {
  font-size: 28px;
}

.p_d_t {
  padding-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/tasks/new-task/new-task.component.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;AACE;EACE,uBAAA;AACJ;AAEE;EACE,YAAA;EACA,YAAA;AAAJ;;AAIA;EACE,uBAAA;EACA,qBAAA;EACA,kBAAA;AADF;;AAIA;EACE,gBAAA;EACD,sBAAA;AADD;;AAKA;EACE,eAAA;AAFF;;AAKA;EACE,iBAAA;AAFF","sourcesContent":["ion-content {\n  --padding-top: 16px;\n\n  .add-photo-wrapper {\n    justify-content: center;\n  }\n\n  .task-image {\n    --size: 50px;\n    margin: auto;\n  }\n}\n\nion-title {\n  padding-inline-start: 0;\n  padding-inline-end: 0;\n  text-align: center;\n\n}\n.size-14{\n  font-size: 1.4em;\n /*margin-right: 20px;*/\n}\n\n\n.custom-label ion-icon{\n  font-size: 28px;\n}\n\n.p_d_t{\n  padding-top: 10px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
