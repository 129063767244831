import { DdtLineComponent } from './ddt-line-progress/ddt-line.component';
import { ModalDdtMetaComponent } from './../modal-ddt-meta/modal-ddt-meta.component';
import { DdtService } from './../../services/trasport_documents/ddt.service';
import { animate, query, sequence, stagger, state, style, transition, trigger } from '@angular/animations';
import { DatatableComponent, ColumnMode } from '@swimlane/ngx-datatable';
import { ActivatedRoute, Router } from '@angular/router';

import { Platform, AnimationController, IonNav, ModalController, PopoverController } from '@ionic/angular';
import { Component, OnInit, ElementRef, ViewChild, Input } from '@angular/core';
import { Ddt } from '../models/ddt.model';
import { NewDdtComponent } from '../new-ddt/new-ddt.component';
import { DdtUpdatePage } from '../ddt-update/ddt-update.page';
import { ApiService } from 'src/app/services/api/api.service';
import { UploadDesktopComponent } from 'src/app/components/media/upload-desktop/upload-desktop.component';
import { Order } from 'src/app/orders/models/order.model';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import {ProductsService} from "../../services/products/products.service";


const listAnimation = trigger('listAnimation', [
  transition('* <=> *', [
    query(':enter',
        [style({ opacity: 0 }), stagger('180ms', animate('300ms ease-out', style({ opacity: 1 })))],
        { optional: true }
    ),
    query(':leave',
        animate('200ms', style({ opacity: 0 })),
        { optional: true }
    )
  ])
]);

interface PageInfo {
  offset: number;
  pageSize: number;
  limit: number;
  count: number;
}

export class Page {
  // The number of elements in the page
  size: number = 0;
  // The total number of elements
  totalElements: number = 0;
  // The total number of pages
  totalPages: number = 0;
  // The current page number
  pageNumber: number = 0;
}
@Component({
  selector: 'app-ddt-details',
  templateUrl: './ddt-details.page.html',
  styleUrls: ['./ddt-details.page.scss'],
})
export class DdtDetailsPage {
  @ViewChild('myTable') table: DatatableComponent;
  @ViewChild('favIcon', { read: ElementRef }) favIcon: ElementRef;
  @ViewChild(UploadDesktopComponent, { static: true }) UploadDesktopComponent: UploadDesktopComponent;

  /* @Input() id: any = ''; */
  @Input() nav: IonNav;
  @Input() ddt: any;
  public showProgressStatus = false;
  public favState = false;
  public favOnAnimation: Animation;
  public favOffAnimation: Animation;
  public columns_line: any = [];
  rowIsNotExpanded = true

  page_number = 1;
  page_limit = 30;


  filteredAddress = [];
  selectedSegment
  ddt_lines: any = []
  ddtLinesTable:any = []

  total_page = 0;
  totalElements: number;
  pageNumber: number;
  cache: any = {};
  selected = [];
  timeout: any;
  subscription

  constructor(
      public platform: Platform,
      private ddtApi: DdtService,
      private api: ApiService,
      private route: ActivatedRoute,
      private router: Router,
      private animationCtrl: AnimationController,
      private popoverController: PopoverController,
      private productApi: ProductsService,
      private modalController: ModalController,
  ) {

    //this.id = this.route.snapshot.paramMap.get('id');


  }


  ionViewDidEnter() {
    this.subscription = this.platform.backButton.subscribe((e) => {
      console.log(e);
      this.dismissModal()
    });
  }

  ionViewWillLeave() {
    this.subscription.unsubscribe();
  }

  ionViewWillEnter() {

    this.total_page = 0;
    this.page_number = 1;
    //this.myInput.value = "";

    this.columns_line = [];
    this.ddt_lines = [];
    console.log('item', this.ddt);

    //this.ListFileComponent
    //this.dataService.getProjectById(parseInt(id, 10))
    /* this.ddtApi.getDdtById(parseInt(this.id, 10))
      .subscribe(e => {
        // if the project doesn't exists, return to home page
        if (!e) {
          //this.router.navigate(['/ddt-list']);
        } else {
          this.ddt = e;
                  

          this.selectedSegment = ''
        

        }
      }); */

    this.getDdtLines(false, "")



  }


  getDdtLines(isFirstLoad, event) {

    console.log('getDDTLines')

    let url = '?page=' + this.page_number + '&items=' + this.page_limit;

    this.ddtApi.mahazin_ddt_read(url, this.ddt.id, this.ddt.type).subscribe(data => {

      console.log(data.lines)
      //this.ddt_lines.push(data.lines);
      this.ddt_lines = [...data.lines];
      this.ddt.reference_name = (data.reference) ? data.reference.name : '';
      //this.total_page = data.num_pages;
      // let temp:any  = []
      // this.ddt_lines.forEach(e=> {
      //   this.productApi.product_product_read(e.id).subscribe(data => {
      //     console.log(data)
      //     temp.push(data);
      //     this.ddtLinesTable = [...temp]
      //   })
      // });


      /*if (data.num_pages >= (this.page_number)) {
        console.log('getDDTLines IF')

        for (let i = 0; i < data.lines.length; i++) {

          let page_ = 1;
          let url = '?page=' + page_ + '&items=' + 25;
          console.log(data.results[i]);

          this.ddt_lines.push(data.results[i]);
          this.ddt_lines = [...this.ddt_lines];
          //this.ddt[i].reference_name = (data.results[i].reference) ? data.results[i].reference.name : '';


        }


        console.log(this.ddt_lines)

        if (isFirstLoad)
          event.target.complete();

        this.page_number++;

      } else {
        console.log('last page');
        event.target ? event.target.complete() : null;

      }*/

    }, error => {
      this.total_page = 0;
      console.log(error);
    })

    // Ddt Types - Ddt,Services
    /* this.api.getDdtTypes(url)
      .subscribe(data => {
        console.log(data.results);
      })

    // Ddt Categories
    this.api.getDdtCategories(url)
      .subscribe(data => {
        console.log(data.results);
      })

    // Ddt Brands
    this.api.getDdtBrands(url)
      .subscribe(data => {
        console.log(data.results);
      }) */







    this.columns_line = [
      { prop: 'id', name: 'ID', frozenLeft: true, minWidth: 50, maxWidth: 75, cellClass: 'td-id', headerClass: 'th-id' },
      {
        prop: 'active',
        name: '',
        sortable: false,
        canAutoResize: false,
        draggable: false,
        resizable: true,
        minWidth: 35,
        maxWidth: 150
      },
      {
        prop: 'action',
        name: '',
        sortable: false,
        canAutoResize: false,
        draggable: false,
        resizable: true,
        minWidth: 35,
        maxWidth: 150
      },
      { prop: 'product.name', name: 'Product', minWidth: 250, maxWidth: 400 },
      { prop: 'quantity', maxWidth: 200 },
      { name: 'Note', prop: 'description', minWidth: 100 },
      /*{ prop: 'completed', name: 'Qty Stored', minWidth: 250 },*/
    ]


  }

  onGetRow1Class = (row) => {
    // id 3 is Delete for Status Order
    if (row.status === 'WAIT') {
      return 'wait';
    } else if (row.status === 'PREP') {
      return 'prep';
    } else if (row.status === 'EXE') {
      return 'exe';
    } else if (row.status === 'END') {
      return 'end';
    }
    else
      return ''
  }
s

  fav(): void {
    if (this.favState) {
      // this.favOnAnimation.stop();
      this.favOffAnimation.play();
    } else {
      // this.favOffAnimation.stop();
      this.favOnAnimation.play();
    }

    this.favState = !this.favState;
  }

  onChangeSelectAcc(event) {
    console.log(event);
  }


  old_editDdt() {
    this.dismissModal();
    //this.navCtrl.navigateForward(['/members/ddt-details/', item.id]);    
    //nav: this.nav(DdtUpdatePage, { id: this.ddt.id }, { animated: true, direction: 'forward' });
  }


  async openUpdateDdtModal() {
    console.log(this.ddt);
    const modal = await this.modalController.create({
      component: DdtUpdatePage,
      canDismiss: true,
      mode: 'md',
      componentProps: {
        title: 'Add Update Ddt',
        item: this.ddt,
        parent_type: 'root'
      }
      //presentingElement: this.routerOutlet.nativeEl,
    })


    modal.onWillDismiss().then((e) => {
      //if (e.data && e.data.status != -1) {
      this.ddtApi.getDdtById(this.ddt.id)
          .subscribe(e => {
            // if the project doesn't exists, return to home page
            if (!e) {
              //this.router.navigate(['/ddt-list']);
            } else {
              this.ddt = e;
            }
          })
      /* this.page_number = 1;
      this.myInput.value = "";
      this.ddt = [];
      this.getDdts(false, '') */
      //}
    });
    return await modal.present();
  }


  fabCallBack(action) {
    console.log(action);

    if (action) {
      this[action]()
    } else {
      console.log('not call in component, run callback', 'details');


    }
  }


  removeDuplicate(types) {
    if (types && types.lenght) {
      const names = types.map(o => o.name)
      const filtered = types.filter(({ name }, index) => !names.includes(name, index + 1))
      return filtered
    }
  }


  filterAddress(address, event) {

    if (event) {
      console.log(address, event);
      this.filteredAddress = address.filter(function (type) {
        return type.name == event.detail.value;
      });
    }

  }



  setPage(pageInfo: PageInfo) {
    // Current page number is determined by last call to setPage
    // This is the page the UI is currently displaying
    // The current page is based on the UI pagesize and scroll position
    // Pagesize can change depending on browser size
    this.pageNumber = pageInfo.offset;

    // Calculate row offset in the UI using pageInfo
    // This is the scroll position in rows
    const rowOffset = pageInfo.offset * pageInfo.pageSize;

    // When calling the server, we keep page size fixed
    // This should be the max UI pagesize or larger
    // This is not necessary but helps simplify caching since the UI page size can change
    const page = new Page();
    page.size = 20;
    page.pageNumber = Math.floor(rowOffset / page.size);

    // We keep a index of server loaded pages so we don't load same data twice
    // This is based on the server page not the UI
    if (this.cache[page.pageNumber]) return;
    this.cache[page.pageNumber] = true;

    // Counter of pending API calls


    /*  this.serverResultsService.getResults(page).subscribe(pagedData => {
       // Update total count
       this.totalElements = pagedData.page.totalElements;
 
       // Create array to store data if missing
       // The array should have the correct number of with "holes" for missing data
       if (!this.rows) {
         this.rows = new Array<CorporateEmployee>(this.totalElements || 0);
       }
 
       // Calc starting row offset
       // This is the position to insert the new data
       const start = pagedData.page.pageNumber * pagedData.page.size;
 
       // Copy existing data
       const rows = [...this.rows];
 
       // Insert new rows into correct position
       rows.splice(start, pagedData.page.size, ...pagedData.data);
 
       // Set rows to our new rows for display
       this.rows = rows;
 
       // Decrement the counter of pending API calls
       this.isLoading--;
     }); */
  }

  onPage(event) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      console.log('paged!', event);
    }, 100);
  }

  onSelect({ selected }) {
    console.log('Select Event', selected, this.selected);
  }

  onActivate(type_order_meta, type_order_form, event) {
    if (event.type == 'mouseenter' || event.type == 'mouseleave')
      return

    if(type_order_form === 'delete'){
      console.log(event)
      
      this.ddtApi.mahazin_ddtline_delete(this.ddt.id)
        .subscribe(data => {
          console.log(data);
        })

    }
    ////nav: this.nav(OrderDetailsPage, { id: event.detail }, { animated: true })
    else{
      
      this.modalDdtMeta(type_order_meta, type_order_form, event)
    }
    console.log(event);
  }

  setExpandStatus(expanded: any): void {
    console.log('setExpandStatus Event', expanded);
  }


  /**
   *
   * @param type_order_meta Lines or Legals
   * @param row
   * @returns
   */

  async modalDdtMeta(type_order_meta, type_order_form, row) {
    console.log(this.ddt.id);
    console.log(this.ddt)
    console.log(type_order_meta)
    console.log(type_order_form)
    console.log(row)
    const modal = await this.modalController.create({
      component: ModalDdtMetaComponent,
      canDismiss: true,
      mode: 'md',
      cssClass: 'halfscreen',
      componentProps: {
        //title: 'Add New Order',
        type_form: type_order_form,
        type_meta: type_order_meta, // if event.value
        ddt: this.ddt,
        data: row,
        //name: this.project.name,

      }
      //presentingElement: this.routerOutlet.nativeEl,
    })


    modal.onWillDismiss().then((e) => {
      console.log(e);
      if (e.data == 'OK') {
        this.ionViewWillEnter()
      }
    });
    return await modal.present();
  }




  async openModalUpload() {
    const modal = await this.modalController.create({
      component: UploadDesktopComponent,
      canDismiss: true,
      componentProps: {
        //title: 'Add New Ddt',
        item: this.ddt,
        header: { ion_header: true },
        files: [],
        parent_type: 'root'
      }
      //presentingElement: this.routerOutlet.nativeEl,
    })


    modal.onWillDismiss().then((e) => {
      if (e.data && e.data.status != -1) {
        /* this.page_number = 1;
        this.myInput.value = "";
        this.ddt = [];
        this.getDdts(false, '') */
      }
    });
    return await modal.present();
  }


  dismissModal(event?) {
    this.modalController.dismiss(event)
  }

  /** PUT Status Ddt */

  putDdtStatus(button) {
    console.log(button);
    const form = { "status": button }
    console.log(this.ddt.type)
    console.log(form)
    this.ddtApi.mahazin_ddt_update(this.ddt, this.ddt.type, form)
        .subscribe(data => {
          console.log(data.results);
        })

  }

  /**  Avanzamento Linea */

  checkCompleteAdvanceLine(line) {
    if (!line.complete)
      return ''

    if (line.complete.value == 100 && line.complete.type == 'P' || line.complete.value == line.qty && line.complete.type == 'N')
      return true

    else
      return ''
  }

  async openPopoverAdvanceLine(a, b, c, event) {
    //console.log(a); //line
    //console.log(b); //advance
    //console.log(c); //ddt line


    const popover = await this.popoverController.create({
      component: DdtLineComponent,
      //event: event,
      backdropDismiss: true,
      cssClass: 'popover-setting',
      componentProps: {
        //event: event,
        ddt: this.ddt,
        line: c,
        type: a
      },
      translucent: true
    });

    popover.onDidDismiss().then((e) => {
      console.log(e);
      if (!e.data) {
        console.log('no modify');
        return
      } else {

        console.log(e);

        //console.log(e.data, this.ddt_lines);

        let ddtLinesIndex = this.ddt_lines.map((e, i, a) => e.id).indexOf(c.id)
        console.log(ddtLinesIndex);

        console.log(this.ddt_lines[ddtLinesIndex].completed, parseInt(e.data.quantity));
        this.ddt_lines[ddtLinesIndex].completed = this.ddt_lines[ddtLinesIndex].completed + parseInt(e.data.quantity)
        //this.ddt_lines[ddtLinesIndex].complete.type = e.data.advance_type


        // if product on response
        this.ddt_lines = [...this.ddt_lines];
        //console.log(this.ddt_lines);


      }
    });

    return await popover.present();

    /** Sync event from popover component */
  }

}
