import { PaymentsService } from './../../services/payments/payments.service';
import { ModalOrderDiscountLineComponent } from './../components/discount-line/modal/modal.component';
import { PrintService } from '../../services/utils/print/print.service';
import { ModalOrderUploadFileComponent } from './../components/modal-order-upload-file/modal-order-upload-file.component';
import { ModalOrderLegalComponent } from './../components/modal-order-legal/modal-order-legal.component';
import { format } from 'date-fns';
import { Vendor } from 'src/app/vendors/models/vendor.model';
import { ActivatedRoute, Event, Router } from '@angular/router';

import {
  Platform,
  AnimationController,
  IonNav,
  ModalController,
  ToastController,
  PopoverController,
  AlertController,
  IonInput,
  ActionSheetController,
  IonSelect,
  IonAccordion,
  IonAccordionGroup,
  LoadingController,
} from '@ionic/angular';
import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  Input,
  TemplateRef,
  ChangeDetectorRef,
  AfterViewInit,
  Renderer2,
  HostListener,
} from '@angular/core';
import { Order } from '../models/order.model';
import { NewOrderComponent } from '../new-order/new-order.component';
import { OrderUpdatePage } from '../order-update/order-update.page';
import { ApiService } from 'src/app/services/api/api.service';
import { UploadDesktopComponent } from 'src/app/components/media/upload-desktop/upload-desktop.component';
import { OrdersService } from 'src/app/services/orders/orders.service';
import {
  ColumnMode,
  DatatableComponent,
  SelectionType,
} from '@swimlane/ngx-datatable';
import { ModalOrderCreateUpdateComponent } from '../components/modal-order-meta/modal-order-meta.component';
import { DiscountLineComponent } from '../components/discount-line/discount-line.component';
import { CurrencySymbolPipe } from 'src/app/pipes/currency/currency-symbol.pipe';
import { InvoiceLineComponent } from './invoice-line/invoice-line.component';
import { InvoicesService } from 'src/app/services/invoices/invoices.service';
import { InvoicesListPage } from 'src/app/invoices/invoices-list/invoices-list.page';
import { filter, raceWith, retryWhen } from 'rxjs/operators';
import { ThemeService } from 'src/app/services/theme/theme.service';

import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { ReportsService } from 'src/app/services/reports/reports.service';
import * as moment from 'moment';
import { PlatformService } from 'src/app/services/utils/platform/plarformservice';
import { DomSanitizer } from '@angular/platform-browser';
import {
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { FileSaverService } from 'ngx-filesaver';

class DateOnlyPipe extends DatePipe {
  public override transform(value): any {
    return super.transform(value, 'dd-MM-y');
  }
}
class CurrencyOnlyPipe extends CurrencyPipe {
  public override transform(value): any {
    return super.transform(value, 'EUR', 'symbol', '1.2-2');
  }
}

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.page.html',
  styleUrls: ['./order-details.page.scss'],
})
export class OrderDetailsPage implements AfterViewInit {
  @ViewChild('favIcon', { read: ElementRef }) favIcon: ElementRef;
  @ViewChild(UploadDesktopComponent, { static: true })
  UploadDesktopComponent: UploadDesktopComponent;
  @ViewChild('myTable') table: DatatableComponent;
  @ViewChild('myTablePay') tablePay: DatatableComponent;
  @ViewChild(CurrencySymbolPipe, { static: true })
  CurrencySymbolPipe: CurrencySymbolPipe;

  @ViewChild('descriptionRowTemplate') descriptionRowTemplate: TemplateRef<any>;
  @ViewChild('editRowTemplate') editRowTemplate: TemplateRef<any>;
  @ViewChild('deleteRowTemplate') deleteRowTemplate: TemplateRef<any>;
  @ViewChild('discountRowTemplate') discountRowTemplate: TemplateRef<any>;
  @ViewChild('totalSummaryTemplate') totalSummaryTemplate: TemplateRef<any>;
  @ViewChild('diffSummaryTemplate') diffSummaryTemplate: TemplateRef<any>;
  @ViewChild('unitSummaryTemplate') unitSummaryTemplate: TemplateRef<any>;
  @ViewChild('costSummaryTemplate') costSummaryTemplate: TemplateRef<any>;
  @ViewChild('advanceTemplate') advanceTemplate: TemplateRef<any>;
  @ViewChild('cellPaymentTypeTemplate')
  cellPaymentTypeTemplate: TemplateRef<any>;

  @ViewChild('costDiscountTemplate') costDiscountTemplate: TemplateRef<any>;
  @ViewChild('costUnityTemplate') costUnityTemplate: TemplateRef<any>;
  @ViewChild('costTemplate') costTemplate: TemplateRef<any>;
  @ViewChild('invoicedTemplate') invoicedTemplate: TemplateRef<any>;
  @ViewChild('cellTax') cellTax: TemplateRef<any>;

  @ViewChild('inputEl', { static: false }) inputEl: IonInput;
  @ViewChild('inputTax', { static: false }) inputTax: IonInput;
  @ViewChild('listenerOut', { static: true }) listenerOut: ElementRef;
  @ViewChild('elementSelectStatus') elementSelectStatus: ElementRef;
  @ViewChild('elementSelectStatusBack') elementSelectStatusBack: ElementRef;
  @ViewChild('accordionStatus', { static: true })
  accordionStatus: IonAccordionGroup;

  @ViewChild('primary', { static: true }) primary: TemplateRef<any>;
  @ViewChild('withAvatar', { static: true }) withAvatar: TemplateRef<any>;

  private values: string[] = ['details' /* , 'second', 'third' */];

  @Input() id: any = '';
  @Input() nav?: IonNav;

  public showProgressStatus = false;
  public order: Order;
  public favState = false;
  public favOnAnimation: Animation;
  public favOffAnimation: Animation;

  tabs_order: any = [
    { id: 0, name: 'lines', label: 'Lines', icon: 'menu-outline' },
    { id: 1, name: 'documents', label: 'Attach', icon: 'folder-outline' },
    { id: 2, name: 'timing', label: 'Date', icon: 'today-outline' },
    { id: 6, name: 'extra', label: 'Extra', icon: 'code-working-outline' },
    //{ id: 3, name: 'legals', label: 'Legals', icon: 'pencil-outline' },
    //{ id: 4, name: 'invoices', label: 'Invoices', icon: 'newspaper-outline' },
    { id: 5, name: 'payments', label: 'Pay', icon: 'cash-outline' },
  ];

  tab_order: any = 'Lines';
  hidden = false;
  offers: [];
  disableEdit = false;
  disableChip = false;

  //day = moment().add('1','d').format()

  page_number = 1;
  page_limit = 30;

  filteredAddress = [];
  selectedSegment: string = 'Lines';

  listTypeDates: any = [];
  listStatus: any = [];
  listStatusBack: any = [];
  listTypesInvoice: any = [];

  availableChangeStatusOrder: any = [];
  availableChangeStatusOrderBack: any = [];

  editing_local_id_cell = {};

  order_lines: any = [];
  order_legals: any = [];
  order_invoices: any = [];
  order_payments: any = [];

  columns_line = [];
  columns_legal = [];
  columns_extra = [];
  columns_invoices = [];
  columns_payments = [];

  selectedLinesRows = [];
  selectedLegalsRows = [];
  selectedExtraRows = [];
  selectedPaymentsRows = [];

  selectedFilterLinesRows = [];
  selectedFilterExtraRows = [];
  selectedFilterLegalsRows = [];

  lineAdvanceToInvoice = [];
  prepareLinesOrderToInvoice = [];

  ColumnMode = ColumnMode;
  SelectionType = SelectionType;

  total_order = 0;
  total_payments = 0;
  diff_discount_order = 0;
  total_discount_order = 0;

  response;
  subscription;
  disableGlobalDiscounts = false;
  disableLineDiscounts = false;

  vamps = [
    { name: 'Bad Vamp' },
    { name: 'Petrovitch the Slain' },
    { name: 'Bob of the Everglades' },
    { name: 'The Optimistic Reaper' },
  ];

  filterTerm: string;
  enableSearch = false;
  listTaxes;

  bindingAccordion;
  ordersTotals = [];

  media_query;

  totalInvoiceIn_completed = false;

  customAlertOptions = {
    header: 'Order status',
    //subHeader: 'Select order status',
    //message: 'Choose only one',
    translucent: true,
  };

  newOrderBtn = false;
  countNewOrderBtn = 3;
  countLoopNewOrderBtn = 0;
  limitCountNewOrderBtn = 1;
  lines_length;
  countAnimBtnStatus = 0;
  iconName;
  iconName_chip;
  showIcon = false;
  admin = false;
  invoiceEnabled = false;
  cprEnabled = false;
  under3kEnabled = false;
  isLine = -1;
  isGlobal = -1;
  emailPermission: any;
  // utentiAbilitati:any[]= ['test@coolprojects.it','roberta.bucci@coolprojects.it','vita.decarlo@coolprojects.it','salvatore.scialdone@coolprojects.it','federica.moretti@coolprojects.it']
  utentiAbilitatiORD: any[] = [
    'test@coolprojects.it',
    'roberta.bucci@coolprojects.it',
    'vita.decarlo@coolprojects.it',
    'barbara.mangiasciutto@coolprojects.it',
  ];
  utentiAbilitatiAdmin: any[] = [
    // 'test@coolprojects.it',
    'maurizio.lamotta@coolprojects.it',
    'marco.tuscano@coolprojects.it',
    'massimo.tarquini@coolprojects.it',
  ];
  utentiAbilitatiInv: any[] = [
    'test@coolprojects.it',
    'federica.moretti@coolprojects.it',
  ];
  utentiAbilitati3k: any[] = [
    'salvatore.scialdone@coolprojects.it',
    'gianluca.lamberti@coolprojects.it',
  ];
  ActionPermsAdmin: any[] = ['DRF', 'RDA', 'ORD', 'WAIT', 'CLO', 'CHG'];
  ActionPerms: any[] = ['CHK', 'CHG', 'DRF', 'RDA'];
  ActionPermsOrd: any[] = ['CHK', 'CHG', 'DRF', 'RDA', 'ACK', 'ORD', 'DEL'];
  ActionPermsOrd5k: any[] = ['CHK', 'CHG', 'DRF', 'RDA', 'ACK',];
  ActionPermsINV: any[] = ['CHK', 'CHG', 'DRF', 'RDA', 'PAY', 'DEL', 'INV'];
  ActionPermsCPR: any[] = ['CPR', 'ORD', 'CHG', 'DEL'];
  ActionPerms3k: any[] = ['CPR', 'CHK', 'CHG', 'DRF', 'RDA', 'ACK', 'ORD'];
  sendOrder = false;
  @ViewChild('createForm') createForm: FormGroupDirective;
  order_orders_createForm: FormGroup;
  currentRow: any;
  currentMouse: any;
  row: any;
  selectedTab: any;

  constructor(
    public platform: Platform,
    private orderApi: OrdersService,
    private invoiceApi: InvoicesService,
    private paymentApi: PaymentsService,
    private reportApi: ReportsService,
    private api: ApiService,
    private apiPrint: PrintService,
    private route: ActivatedRoute,
    private router: Router,
    private animationCtrl: AnimationController,
    //private nav: IonNav,
    private actionSheetController: ActionSheetController,
    private modalController: ModalController,
    private popoverController: PopoverController,
    private alertController: AlertController,
    private toastController: ToastController,
    private actionSheetCtrl: ActionSheetController,
    private currencyPipe: CurrencySymbolPipe,
    private _datePipe: DatePipe,
    private platformService: PlatformService,
    protected sanitizer: DomSanitizer,
    private cd: ChangeDetectorRef,
    private fb: FormBuilder,
    private auth: AuthenticationService,
    private themeService: ThemeService,
    private clipboard: Clipboard,
    private loadingController: LoadingController,
    private render: Renderer2,
    private _FileSaverService: FileSaverService
  ) {
    this.themeService.dark.subscribe((e) => {
      console.log(e);

      if (e === undefined || e == true) {
        this.iconName = '';
        this.iconName_chip = '';
        this.showIcon = false;
      } else {
        this.iconName = 'garland';
        this.iconName_chip = 'christmas-gift';
        this.showIcon = true;
      }
    });
    this.auth
      .getLocalData('auth-profile')
      .then((e) => {
        console.log("this.auth.getLocalData('auth-profile')", e);
        this.emailPermission = e.user_data.email;
        console.log(this.emailPermission);
        if (this.utentiAbilitatiAdmin.includes(this.emailPermission)) {
          console.log('utente admin abilitato');
          this.admin = true;
        } else if (this.utentiAbilitatiORD.includes(this.emailPermission)) {
          console.log('utente Ordini abilitato');
          this.sendOrder = true;
        } else if (this.utentiAbilitatiInv.includes(this.emailPermission)) {
          console.log('utente invoice abilitato');
          this.invoiceEnabled = true;
          this.cprEnabled = true;
        } else if (this.utentiAbilitati3k.includes(this.emailPermission)) {
          console.log('utente 3k abilitato');
          this.under3kEnabled = true;
        } else {
          this.sendOrder = false;
          console.log('utente non abilitato');
        }
      })
      .catch((err) => {
        console.log(err);
      });
    this.id = this.route.snapshot.paramMap.get('id');

    console.log(this.diffSummaryTemplate);

    this.media_query = window.innerWidth;
    console.log(this.media_query);

    this.platformService.autoWidth.subscribe((e) => {
      console.log(e);

      this.media_query = e;
    });

    /* setTimeout(() => {

      let n = 3
      let timer = setInterval(() => {
        if (n <= this.countAnimBtnStatus) {

        } else {
          //clearInterval(timer)
        }

        this.countAnimBtnStatus++
      }, 1000);
    }, 500);
 */
  }

  ngAfterViewInit(): void {
    this.createcolumns();
  }

  createHiddenForm() {
    // Generete FormGroup Create Order
    this.order_orders_createForm = this.fb.group({
      type_id: this.fb.control(
        this.order.type ? this.order.type.id : null,
        Validators.required
      ),
      category_id: this.fb.control(this.order.category.id, Validators.required),
      status_id: this.fb.control(this.order.status.id, Validators.required),
      priority_id: this.fb.control(this.order.priority.id, Validators.required),
      job_id: this.fb.control(
        this.order.job ? this.order.job.id : null,
        Validators.required
      ),
      customer_id: this.fb.control(
        this.order.customer ? this.order.customer.id : null,
        Validators.required
      ),
      vendor_id: this.fb.control(
        this.order.vendor ? this.order.vendor.id : null,
        Validators.required
      ),
      cost: this.fb.control(this.order.cost, Validators.required),
      //causal_id: this.fb.control(this.order.casual ? this.order.casual.id : null),
      //extra_type_id: this.fb.control(this.order.extra_type ? this.order.extra_type.id : null),
      code: this.fb.control(this.order.code, Validators.required),
      //parent_id: this.fb.control(this.order.parent ? this.order.parent.id : null),
      //invoice_term_id: this.fb.control(this.order.invoice_term ? this.order.invoice_term.id : '', Validators.required),
      //payment_term_id: this.fb.control(this.order.payment_term ? this.order.payment_term.id : '', Validators.required),
      //payment_type_id: this.fb.control(this.order.payment_type ? this.order.payment_type.id : '', Validators.required)
    });
  }

  firstLineOrder() {
    //console.log('start first lineOrder');

    let _interval = setInterval(() => {
      //console.log('interval', true);

      this.newOrderBtn = true;
      if (this.countLoopNewOrderBtn == this.limitCountNewOrderBtn) {
        clearInterval(_interval);
      }
      setTimeout(() => {
        //console.log('timeout', false);
        this.newOrderBtn = false;
      }, (this.countNewOrderBtn * 100) / 2);
      this.countLoopNewOrderBtn++;

      this.cd.detectChanges();
    }, this.countNewOrderBtn * 160);
    setTimeout(() => {
      clearInterval(_interval);
    }, this.countNewOrderBtn * 160 * 10);
  }

  createcolumns() {
    this.columns_line = [
      {
        /* prop: 'cost', */ name: 'Actions',
        maxWidth: 160,
        width: 160,
        summaryFunc: () => null,
        cellTemplate: this.editRowTemplate,
        //summaryTemplate: this.diffSummaryTemplate,
        sortable: false,
        cellClass: 'td-edit',
        headerClass: 'th-edit',
      },
      /* {
         name: "Del.", maxWidth: 55, width: 55,
        summaryFunc: () => null,
        cellTemplate: this.deleteRowTemplate,
        sortable: false,
        cellClass: 'td-delete', headerClass: 'th-delete',
      }, */
      {
        prop: 'product.name',
        name: 'Product',
        minWidth: 50,
        width: 50,
        maxWidth: 600,
        summaryFunc: () => null,
        cellTemplate: this.descriptionRowTemplate,
        //summaryTemplate: this.diffSummaryTemplate,
        sortable: true,
        cellClass: 'td-description',
        headerClass: 'th-description',
      },
      {
        prop: 'service.name',
        name: 'Service',
        minWidth: 50,
        width: 50,
        maxWidth: 600,
        summaryFunc: () => null,
        cellTemplate: this.descriptionRowTemplate,
        //summaryTemplate: this.diffSummaryTemplate,
        sortable: true,
        cellClass: 'td-description',
        headerClass: 'th-description',
      },
      {
        prop: 'description',
        name: 'Description',
        minWidth: 300,
        width: 300,
        maxWidth: 1250,
        summaryFunc: () => null,
        cellTemplate: this.descriptionRowTemplate,
        //summaryTemplate: this.diffSummaryTemplate,
        sortable: true,
        cellClass: 'td-description',
        headerClass: 'th-description',
      },
      //{ prop: 'id', name: 'ID', sortable: true, resizable: true, frozenLeft: true, width: 75, minWidth: 75, maxWidth: 75, cellClass: 'td-id', headerClass: 'th-id', summaryFunc: () => null },
      //{ prop: 'local_id', name: 'Loc ID', sortable: true, resizable: true, frozenLeft: true, width: 75, minWidth: 75, maxWidth: 75, cellClass: 'td-id', headerClass: 'th-id', summaryFunc: () => null },
      //{ prop: 'order', name: 'Order id', maxWidth: 100, summaryFunc: () => null },
      /*  {
         prop: 'complete', name: 'Complete', minWidth: 90, width: 90, maxWidth: 90,
         summaryFunc: () => null,
         cellTemplate: this.advanceTemplate,
         sortable: true,
         cellClass: 'td-complete', headerClass: 'th-complete',
       }, {
         prop: 'invoiced', name: 'Invoiced', minWidth: 90, width: 90, maxWidth: 90,
         summaryFunc: () => null,
         cellTemplate: this.invoicedTemplate,
         sortable: true,
         cellClass: 'td-invoiced', headerClass: 'th-invoiced',
       }, */
      {
        prop: 'qty',
        sortable: true,
        resizable: true,
        minWidth: 85,
        maxWidth: 85,
        cellClass: 'td-qty',
        headerClass: 'th-qty',
        frozenLeft: false,
        summaryFunc: () => null,
      },

      //{ prop: 'udm', sortable: true, resizable: true, width: 50, minWidth: 50, maxWidth: 50, summaryFunc: () => null },
      //{ prop: 'width', sortable: true, resizable: true, width: 100, maxWidth: 100, minWidth: 100, summaryFunc: () => null },
      //{ prop: 'length', sortable: true, resizable: true, width: 100, maxWidth: 100, summaryFunc: () => null },
      //{ prop: 'height', sortable: true, resizable: true, width: 100, maxWidth: 100, summaryFunc: () => null },
      //{ prop: 'weight', sortable: true, resizable: true, width: 100, maxWidth: 100, minWidth: 100, summaryFunc: () => null },
      //{ prop: 'description', sortable: true, resizable: true, width: 550, maxWidth: 550, minWidth: 550, summaryFunc: () => null },
      //{ prop: 'created', sortable: true, resizable: true, width: 250, maxWidth: 250, minWidth: 250, summaryFunc: () => null },
      //{ prop: 'deleted', sortable: true, resizable: true, width: 75, maxWidth: 75, minWidth: 75, summaryFunc: () => null },

      //{ prop: 'empty', sortable: false, resizable: false, width: 145, maxWidth: 145, minWidth: 145, summaryFunc: () => null },
      {
        prop: 'cost',
        name: 'Cost Unit',
        sortable: true,
        minWidth: 170,
        width: 180,
        maxWidth: 420,
        summaryFunc: () => null,
        cellTemplate: this.costUnityTemplate,
        //summaryTemplate: this.costSummaryTemplate,
        cellClass: 'td-cost_unit',
        headerClass: 'th-cost_unit',
      },
      {
        prop: 'cost',
        name: 'Cost',
        minWidth: 170,
        width: 180,
        maxWidth: 420,
        summaryFunc: () => null,
        cellTemplate: this.costTemplate,
        summaryTemplate: this.unitSummaryTemplate,
        sortable: true,
        cellClass: 'td-cost',
        headerClass: 'th-cost',
      },
      /*  {
         prop: 'tax', name: 'Tax', minWidth: 50, width: 50, maxWidth: 50,
         summaryFunc: () => null,
         cellTemplate: this.cellTax,
         sortable: true,
         cellClass: 'td-tax', headerClass: 'th-tax',
       }, */
      /*  {
          name: "Discount", minWidth: 85, width: 85, maxWidth: 85,
         summaryFunc: () => null,
         cellTemplate: this.discountRowTemplate,
         //summaryTemplate: this.totalSummaryTemplate,
         sortable: false,
         cellClass: 'td-discount', headerClass: 'th-discount',
       }, */
      /* {
        prop: 'cost', name: "Discount Cost",
        minWidth: 170, width: 180, maxWidth: 420,
        summaryFunc: () => null,
        cellTemplate: this.costDiscountTemplate,
        summaryTemplate: this.totalSummaryTemplate,
        sortable: true,
        cellClass: 'td-cost_discount', headerClass: 'th-cost_discount',
      }, */
      /*       {
               name: "", minWidth: 165, maxWidth: 165,
              summaryFunc: () => null,
              cellTemplate: null,
              summaryTemplate: this.diffSummaryTemplate,
              sortable: false,
              cellClass: 'td-total', headerClass: 'th-total',
            }, */

      /*       {
              prop: 'cost', name: "Discount Cost", minWidth: 190, maxWidth: 190,
              summaryFunc: () => null,
              //cellTemplate: this.costDiscountTemplate,
              summaryTemplate: this.diffSummaryTemplate,
              sortable: true,
              cellClass: 'td-cost_discount', headerClass: 'th-cost_discount',
            }, */
    ];

    this.columns_extra = [
      {
        /* prop: 'cost', */ name: 'Actions',
        maxWidth: 110,
        width: 110,
        summaryFunc: () => null,
        cellTemplate: this.editRowTemplate,
        //summaryTemplate: this.diffSummaryTemplate,
        sortable: false,
        cellClass: 'td-edit',
        headerClass: 'th-edit',
      },
      //{ prop: 'id', name: 'ID', frozenLeft: true, width: 100, maxWidth: 100, cellClass: 'td-id', headerClass: 'th-id', summaryFunc: () => null },
      //{ prop: 'sequence', width: 20, maxWidth: 20, },
      {
        prop: 'title',
        name: 'Title',
        width: 200,
        maxWidth: 200,
        frozenLeft: false,
      },
      /* { prop: 'active', maxWidth: 100 }, */
      { prop: 'content', width: 800 },

      /* { prop: 'created', maxWidth: 250 },
      { prop: 'deleted', width: 75, maxWidth: 75 }, */
    ];

    this.columns_legal = [
      {
        /* prop: 'cost', */ name: 'Actions',
        maxWidth: 110,
        width: 110,
        summaryFunc: () => null,
        cellTemplate: this.editRowTemplate,
        //summaryTemplate: this.diffSummaryTemplate,
        sortable: false,
        cellClass: 'td-edit',
        headerClass: 'th-edit',
      },
      //{ prop: 'id', name: 'ID', frozenLeft: true, width: 100, maxWidth: 100, cellClass: 'td-id', headerClass: 'th-id', summaryFunc: () => null },
      //{ prop: 'sequence', width: 20, maxWidth: 20, },
      {
        prop: 'title',
        name: 'Title',
        width: 200,
        maxWidth: 200,
        frozenLeft: false,
      },
      /* { prop: 'active', maxWidth: 100 }, */
      { prop: 'content', width: 800 },

      /* { prop: 'created', maxWidth: 250 },
      { prop: 'deleted', width: 75, maxWidth: 75 }, */
    ];

    this.columns_invoices = [
      /*  { prop: 'id', name: 'ID', frozenLeft: true, width: 10, maxWidth: 20, cellClass: 'td-id', headerClass: 'th-id', summaryFunc: () => null }, */
      {
        prop: 'code',
        name: 'Code',
        width: 200,
        maxWidth: 300,
        frozenLeft: false,
      },
      { prop: 'customer.name', name: 'Customer', width: 150 },
      { prop: 'vendor.name', name: 'Vendor', width: 150 },
      { prop: 'type.name', name: 'type', width: 150 },
      { prop: 'status.name', name: 'status', width: 100, maxWidth: 100 },
      { prop: 'fiscal_year', name: 'Fiscal Year', width: 150 },
      { prop: 'effective_date', name: 'data effettiva', width: 150 },
      { prop: 'cost', width: 150 },
    ];

    this.columns_payments = [
      {
        /* prop: 'cost', */ name: 'Actions',
        maxWidth: 110,
        width: 110,
        summaryFunc: () => null,
        cellTemplate: this.editRowTemplate,
        //summaryTemplate: this.diffSummaryTemplate,
        sortable: false,
        cellClass: 'td-edit',
        headerClass: 'th-edit',
      },
      /*  { prop: 'id', name: 'ID', frozenLeft: true, width: 10, maxWidth: 20, cellClass: 'td-id', headerClass: 'th-id', summaryFunc: () => null }, */
      {
        prop: 'category.name',
        name: 'Descrizione',
        width: 200,
        maxWidth: 300,
        frozenLeft: false,
      },

      {
        prop: 'term_reference.name',
        name: 'Data riferimento',
        width: 190,
        maxWidth: 190,
      },
      {
        name: 'Tipologia di pagamento',
        maxWidth: 355,
        width: 355,
        cellTemplate: this.cellPaymentTypeTemplate,
        sortable: false,
        cellClass: 'td-edit',
        headerClass: 'th-edit',
      }, //method.name + day_reference + day_reference_type.name

      { prop: 'term.name', name: 'Pagamento fatturazione', width: 150 },
      {
        prop: 'amount',
        name: 'Importo',
        width: 150,
        summaryFunc: () => null,
        cellTemplate: this.costTemplate,
        summaryTemplate: this.unitSummaryTemplate,
        sortable: true,
        //cellClass: 'td-cost', headerClass: 'th-cost',
        pipe: new CurrencyOnlyPipe('it-IT'),
      },

      //{ prop: 'term.name', name: 'Termine', width: 100, maxWidth: 100, },
    ];
  }

  getPropValue(dataItem: any): string {
    if (dataItem.product && dataItem.product.name) {
      return dataItem.product.name;
    } else if (dataItem.service && dataItem.service.name) {
      return dataItem.service.name;
    } else {
      return 'N/A';
    }
  }

  getProductOrServiceName() {
    // Implementa qui la logica per decidere se usare 'product.name' o 'service.name'
    // Ad esempio:
    console.log(this.order_lines);
    console.log('sto entrando');
    if (this.order_lines.product) {
      console.log('prodotto');
      return 'product.name';
    } else if (this.order_lines.service) {
      console.log('servzio');
      return 'service.name';
    } else {
      return '';
    }
  }
  reloadUpload = true;
  ionViewWillEnter(event?) {
    console.log('ionViewWillEnter', this.id, this.nav);
    this.reloadUpload = false;
    this.listStatus = [];
    this.listStatusBack = [];
    this.tabs_order = [
      { id: 0, name: 'lines', label: 'Lines', icon: 'menu-outline' },
      { id: 1, name: 'files', label: 'Attach', icon: 'attach-outline' },
      { id: 2, name: 'timing', label: 'Date', icon: 'today-outline' },
      { id: 6, name: 'extra', label: 'Extra', icon: 'code-working-outline' },
      //{ id: 3, name: 'legals', label: 'Legals', icon: 'pencil-outline' },
      //{ id: 4, name: 'invoices', label: 'Invoices', icon: 'newspaper-outline' },
      { id: 5, name: 'payments', label: 'Pay', icon: 'cash-outline' },
    ];
    this.selectedFilterLinesRows = [];
    /*  this.invoiceApi.getLinesTax(true).subscribe(e => {
       this.listTaxes = e.results
     }) */

    this.hidden = false;
    //this.ListFileComponent
    //this.dataService.getProjectById(parseInt(id, 10))
    this.orderApi.getOrderById(parseInt(this.id, 10), '').subscribe((e) => {
      // if the project doesn't exists, return to home page
      if (!e) {
        //this.router.navigate(['/order-list']);
      } else {
        this.order = e;
        console.log(e);

        console.log('this.order.status.id', this.order.status);

        this.createHiddenForm();

        if (this.order.status && this.order.status.id != 1) {
          this.tabs_order.push({
            id: 3,
            name: 'legals',
            label: 'Legals',
            icon: 'pencil-outline',
          });
        }
        console.log(e);

        this.order_lines = this.order.lines;
        this.order_payments = this.order.payments;
        this.lines_length = this.order.lines.length;
        console.log(this.lines_length);
        console.log(this.order_lines);
        this.firstLineOrder();

        this.order_legals = this.order.legals;

        //map address legal customer
        console.log(e);
        console.log(e.customer);
        console.log(this.order.customer);

        // if (this.order?.customer)
        //   this.order.customer._legal = this.getAddressLegal(this.order?.customer)
        // if (this.order?.vendor)
        //   this.order.vendor.address_legal = this.getAddressLegal(this.order?.vendor)

        this.order_legals.map((e) => {
          console.log(e);
          if (e.active) e.isSelected = true;
          this.selectedLinesRows.push(e);
        });

        let cost = this.order.cost;
        let invoiced = this.order.total_invoice_in;
        console.log(
          cost,
          invoiced,
          cost != 0 ? (cost <= invoiced ? true : false) : false
        );
        console.log(cost != 0, cost <= invoiced);

        this.totalInvoiceIn_completed =
          cost != 0 ? (cost <= invoiced ? true : false) : false;
        console.log(this.totalInvoiceIn_completed);

        this.caclulateSumm();
        this.caclulateSummDiscount();
        this.getMetaOrder();
        //kthis.getMetaInvoice();

        this.checkIsLineDiscountsApproved();
        this.checkIsGlobalDiscountsApproved();

        // Get invoices
        //this.getInvoices(this.order.id)
        // Get invoices
        //this.getPayments(this.order.id)
        // Get files
        this.getListFiles();

        /* if (this.UploadDesktopComponent && e.folders.length && e.folders[0].folder.id != undefined) {
            this.UploadDesktopComponent.passItem(e);
          } else {
            
          } */
      }
    });

    this.orderApi.newDiscountOrder.subscribe((e) => {
      console.log(e, e['orderline'], e['order']);
      let keys = Object.keys(e);
      let keyOrderDiscountLine = keys.indexOf('orderline');
      let keyOrderDiscountGlobal = keys.indexOf('order');

      console.log(keyOrderDiscountLine);
      console.log(keyOrderDiscountGlobal);

      if (keyOrderDiscountLine != -1) {
        this.order_lines.map((e) => {
          e.id == e.orderline ? e.discounts.push(e) : null;
        });
        //this.order_lines.push(e)
        //this.order.order_lines = this.order_lines
      } else if (keyOrderDiscountGlobal != -1) {
        //this.order.discounts.push(e)
      }

      this.checkIsLineDiscountsApproved();
      this.checkIsGlobalDiscountsApproved();

      this.caclulateSumm();
      this.caclulateSummDiscount();

      /* 


      
          this.checkIsLineDiscountsApproved()
          this.checkIsGlobalDiscountsApproved()
          
            // Get invoices
            this.getInvoices(this.order.id)
            // Get invoices
            this.getPayments(this.order.id)
            // Get files
            this.getListFiles() */
    });
  }

  simulateControlKeyPress() {
    const event = new KeyboardEvent('keydown', {
      key: 'Control',
      ctrlKey: true,
      bubbles: true,
    });

    document.dispatchEvent(event);
    console.log('Control key simulated');
  }

  ionViewDidEnter() {
    this.subscription = this.platform.backButton.subscribe((e) => {
      console.log(e);
      this.dismissModal();
    });

    // !! Await for load template Reference and cretea columns table
    //this.createcolumns();

    this.response = '';
    this.selectedLegalsRows = [];
    this.selectedExtraRows = [];
    this.selectedLinesRows = [];
  }

  ionViewWillLeave() {
    this.subscription.unsubscribe();
  }

  getMetaOrder() {
    console.log(this.order.status);
    if (this.order.status.code === 'DRF' || this.order.status.code === 'RDA') {
      this.disableEdit = false;
    } else {
      this.disableEdit = true;
    }

    if (
      this.order.status.post.length == 0 ||
      this.order.status.code === 'CLO'
    ) {
      this.disableChip = false;
    }
    // else{
    //   this.disableChip = true
    // }

    // fech array nested this.order.post anh while nested key post is != null push in listStatus

    function fetchNestedStatuses(status) {
      let listStatus = [];

      function recursiveFetch(status) {
        if (status.pre) {
          listStatus.push(status.pre);
          recursiveFetch(status.pre);
        }
      }

      recursiveFetch(status);

      return listStatus;
    }

    const nestedStatuses = fetchNestedStatuses(this.order.status).reverse();

    console.log(nestedStatuses);

    console.log(this.order);

    this.listStatus.push(...nestedStatuses);
    this.listStatus.push(this.order.status);
    this.listStatusBack.push(this.order.status.parent);
    // = this.listStatus

    this.orderApi.order_sequence_read(this.order.status.id).subscribe((e) => {
      console.log(e);
      // if the project doesn't exists, return to home page
      if (!e) {
        //this.router.navigate(['/order-list']);
      } else {
        //console.log(e.post[0].code)
        if (this.admin) {
          console.log('admin');

          this.disableChip = true;
        } else if (
          this.sendOrder &&
          this.ActionPermsOrd.includes(e.post[0].code) &&
          this.order?.cost <= 5000
        ) {
          console.log('utente abilitato a mandare in ORD sotto i 5k');
          console.log('sendOrder', this.sendOrder);
          console.log(this.ActionPermsOrd.includes(e.post[0].code));
          this.disableChip = true;
        } else if (
          this.sendOrder &&
          this.ActionPermsOrd5k.includes(e.post[0].code) &&
          this.order?.cost > 5000
        ) {
          console.log('utente abilitato a mandare in ACK sopra i 5k');
          console.log('sendOrder', this.sendOrder);
          console.log(this.ActionPermsOrd5k.includes(e.post[0].code));
          this.disableChip = true;
        } else if (
          !this.sendOrder &&
          this.ActionPerms.includes(e.post[0].code)
        ) {
          console.log('utente random');
          this.disableChip = true;
        } else if (
          this.invoiceEnabled &&
          (this.ActionPermsINV.includes(e.post[0].code) ||
            this.ActionPermsCPR.includes(e.post[0].code))
        ) {
          console.log('utente INV e CPR');
          this.disableChip = true;
        } else if (
          this.under3kEnabled &&
          this.ActionPerms3k.includes(e.post[0].code) &&
          this.order.cost <= 3000
        ) {
          console.log('utente under3k');
          this.disableChip = true;
        } else {
          console.log('chip disabled');
          this.disableChip = false;
        }

        this.listStatus.push(...e.post);
        console.log(this.compareStatusOrderOfstatusListOrder());

        this.listStatusBack.push(...e.parent?.post);
        console.log(this.compareStatusOrderOfstatusListOrderBack());
      }
    });
  }

  getMetaInvoice() {
    this.invoiceApi.getListType().subscribe((e) => {
      console.log(e);

      // if the project doesn't exists, return to home page
      if (!e) {
        //this.router.navigate(['/order-list']);
      } else {
        this.listTypesInvoice = e;
      }
    });
  }

  getDocuments(id_folder) {
    let url = '?page=' + this.page_number + '&items=' + this.page_limit;

    this.api.getFiles(id_folder, url).subscribe((x) => {
      this.page_number++;
      return x;
    });
  }

  getInvoices(id) {
    // API INVOICES
    let params = '?order_id=' + id;
    return;

    this.invoiceApi.getInvoicesListByOrder(params).subscribe((e) => {
      console.log(e);

      // if the project doesn't exists, return to home page
      if (!e) {
        //this.router.navigate(['/order-list']);
      } else {
        //this.order = e;
        this.order_invoices = e.results;
        console.log(e);
      }
    });
  }

  getPayments(id) {
    // API PAYMENTS
    let params = '?order_id=' + id;

    return;

    this.paymentApi.payment_categories_list(params).subscribe((e) => {
      console.log(e);

      // if the project doesn't exists, return to home page
      if (!e) {
        //this.router.navigate(['/order-list']);
      } else {
        //this.order = e;
        this.order_payments = e.results;
        console.log(e);
      }
    });
  }

  fav(): void {
    if (this.favState) {
      // this.favOnAnimation.stop();
      this.favOffAnimation.play();
    } else {
      // this.favOffAnimation.stop();
      this.favOnAnimation.play();
    }

    this.favState = !this.favState;
  }

  onChangeSelectAcc(event) {
    console.log(event);
  }

  onChangeDiscount(discount, event, row) {
    console.log(discount, event, row);
  }

  async openNewOrderModal() {
    const modal = await this.modalController.create({
      component: NewOrderComponent,
      canDismiss: true,
      componentProps: {
        title: 'Add New Order',
        parent_type: 'root',
      },
      //presentingElement: this.routerOutlet.nativeEl,
    });

    modal.onWillDismiss().then((e) => {
      if (e.data && e.data.status != -1) {
        /* this.page_number = 1;
        this.myInput.value = "";
        this.orders = [];
        this.getOrders(false, '') */
      }
    });
    return await modal.present();
  }

  fabCallBack(action) {
    console.log(action);

    if (action) {
      this[action]();
    } else {
      console.log('not call in component, run callback', 'details');
    }
  }
  methodAction(action: any) {
    console.log(action);

    // console.log(this.accordionStatus.value = '0');
    // try {
    //   this[action]()

    // } catch {
    console.log('not call in parent component');
    this.onSetTab(action.label);
    this.selectedTab = action.label;
    // }
    //console.log(action)
  }
  segmentChanged(event: any) {
    console.log(event);

    this.selectedSegment = event.detail.value;
    this.onSetTab(event.detail.value);
  }

  removeDuplicate(types) {
    if (types && types.lenght) {
      const names = types.map((o) => o.name);
      const filtered = types.filter(
        ({ name }, index) => !names.includes(name, index + 1)
      );
      return filtered;
    }
  }

  filterAddress(address, event) {
    if (event) {
      console.log(address, event);
      this.filteredAddress = address.filter(function (type) {
        return type.name == event.detail.value;
      });
    }
  }

  deleteLine(type_order_meta, event) {
    console.log(type_order_meta);
    console.log(event);

    if (type_order_meta == 'line') {
      this.orderApi.order_orderlines_delete(event.id).subscribe((e) => {
        console.log(e);
        this.ionViewWillEnter();
      });
    } else if (type_order_meta == 'legal') {
      this.orderApi.order_orderlegal_delete(event.id).subscribe((e) => {
        console.log(e);
        this.ionViewWillEnter();
      });
    } else if (type_order_meta == 'extra') {
      this.orderApi.order_orderextras_delete(event.id).subscribe((e) => {
        console.log(e);
        this.ionViewWillEnter();
      });
    } else if (type_order_meta == 'payments') {
      this.orderApi.order_orderpayment_delete(event.id).subscribe((e) => {
        console.log(e);
        this.ionViewWillEnter();
      });
    }
    // this.orderApi.order_order_update(this.order.id, this.order_orders_createForm.value).subscribe(e => {
    //   console.log(e);
    //   if (e.message && e.message.includes('OK')) {
    //     this.presentToast(e.message, 'success')
    //     this.response = e.data;
    //     //this.dismissModal();
    //   } else {
    //     this.response = 'KO';
    //   }
    // })
  }

  editLine(type_order_meta, type_order_form, event) {
    console.log(this.order.lines.length !== 0);

    console.log(type_order_meta, type_order_form, event);

    if (type_order_meta == 'line') {
      this.modalOrderCreateUpdate(
        type_order_meta,
        type_order_form,
        true,
        event /* .row */
      );
    } else if (type_order_meta == 'legal') {
      this.modalLegalCreateUpdate(
        type_order_meta,
        type_order_form,
        true,
        event /* .row */
      );
    } else if (type_order_meta == 'extra') {
      this.modalLegalCreateUpdate(
        type_order_meta,
        type_order_form,
        true,
        event /* .row */
      );
    } else if (type_order_meta == 'payments') {
      if (this.order.lines.length !== 0)
        this.modalLegalCreateUpdate(
          type_order_meta,
          type_order_form,
          true,
          event /* .row */
        );
      else {
        this.alertPayment();
      }
    }

    /* if (event.type == 'mouseenter' || event.type == 'mouseleave')
      return */

    console.log(event);
    //this.nav.push(OrderDetailsPage, { id: event.detail }, { animated: true })
  }

  copyLine(type_order_meta?, type_order_form?, event?) {
    console.log(type_order_form, type_order_meta, event);
    let item = type_order_form;
    let form = {
      order_id: item.order,
      product_id: item.product?.id,
      service_id: item.service?.id,
      cost: item.cost,
      description: item?.description,
      udm_id: item.udm?.id,
      qty: item?.qty,
    };
    this.orderApi.order_orderlines_create(form, item.order).subscribe((e) => {
      console.log(e);
      if (e.message && e.message.includes('OK')) {
        this.presentToast(e.message, 'success');
        this.response = 'OK';
        this.ionViewWillEnter();
      } else {
        this.response = 'KO';
      }
    });
  }

  copyPayment(type_order_meta?, type_order_form?, event?) {
    console.log(type_order_form, type_order_meta, event);
    let item = type_order_form;
    console.log(item);
    let form = {
      order_id: item.order,
      amount: item.amount,
      category_id: item.category?.id,
      day_reference: item.day_reference,
      day_reference_type_id: item?.day_reference_type?.id,
      method_id: item.method?.id,
      percentage: item.percentage,
      term_id: item.term?.id,
      term_reference_id: item?.term_reference?.id,
    };

    this.orderApi.order_orderpayment_create(form, item.order).subscribe((e) => {
      console.log(e);
      if (e.message && e.message.includes('OK')) {
        this.presentToast(e.message, 'success');
        this.response = 'OK';
        this.ionViewWillEnter();
      } else {
        this.response = 'KO';
      }
    });
  }

  async modalOrderEdit() {
    const modal = await this.modalController.create({
      component: OrderUpdatePage,
      canDismiss: true,
      mode: 'md',
      cssClass: 'editOrderDetails',
      componentProps: {
        //title: 'Add New Order',
        id: this.id,
        //name: this.project.name,
      },
      //presentingElement: this.routerOutlet.nativeEl,
    });

    modal.onWillDismiss().then((e) => {
      console.log('OrderUpdatePage to OrderDetailsPage', e);

      if (!e.data) {
        console.log('no modify');
        return;
      } else {
        this.orderApi
          .getOrderById(parseInt(this.id, 10), '')
          .subscribe((order) => {
            console.log(order, e.data);
            console.log('e.data.customer: ', e.data.customer);
            console.log('order: ', order.customer);

            // if the project doesn't exists, return to home page
            if (!order) {
              //this.router.navigate(['/order-list']);
            } else {
              this.order = order;

              if (this.order?.customer)
                this.order.customer._legal = this.getAddressLegal(
                  this.order.customer
                );
              if (this.order?.vendor)
                this.order.vendor.address_legal = this.getAddressLegal(
                  this.order.vendor
                );
              //console.log(this.order);
              this.response = e.data;

              /* if (this.UploadDesktopComponent && e.folders.length && e.folders[0].folder.id != undefined) {
                this.UploadDesktopComponent.passItem(e);
              } else {
                
              } */
            }
          });
      }
    });
    return await modal.present();
  }

  /**
   *
   * @param type_order_meta Lines or Legals
   * @param row
   * @returns
   */

  async modalOrderCreateUpdate(type_order_meta, type_order_form, canEdit, row) {
    console.log(row);

    const modal = await this.modalController.create({
      component: ModalOrderCreateUpdateComponent,
      cssClass: 'newLineOrderModalForm',
      canDismiss: true,
      mode: 'md',
      componentProps: {
        //title: 'Add New Order',
        type_form: type_order_form,
        type_meta: type_order_meta, // if event.value
        order: this.order,
        data: row,
        canEdit: canEdit,
        //name: this.project.name,
      },
    });

    modal.onWillDismiss().then((e) => {
      console.log(e);
      if (!e.data) {
        console.log('no modify');
        return;
      } else {
        this.ionViewWillEnter();
        this.orderApi
          .order_order_update(this.order.id, this.order_orders_createForm.value)
          .subscribe((e) => {
            console.log(e);
            if (e.message && e.message.includes('OK')) {
              this.presentToast(e.message, 'success');
              this.response = e.data;
              //this.dismissModal();
            } else {
              this.response = 'KO';
            }
          });
        this.response = e.data;
      }
    });
    return await modal.present();
  }

  dismissModal(event?) {
    //this.hidden = true
    this.modalController.dismiss(this.response);
  }

  emptySumm() {
    return null;
  }

  // Calcola la riga sommaria
  caclulateSumm() {
    console.log('caclulateSumm');

    this.total_order = 0;
    console.log(this.order_lines);
    this.order_lines.map((item, i) => {
      this.total_order += item.cost * item.qty;
      console.log(item.cost, item.qty, this.total_order);

      this.total_discount_order = this.total_order;
    });

    this.total_payments = 0;
    this.order_payments.map((item, i) => {
      console.log(this.total_payments);
      console.log(item);
      this.total_payments += item.amount;
      console.log(this.total_payments);
    });
  }

  caclulateSummDiscount() {
    console.log('caclulateSummDiscount lines', this.order_lines);

    console.log('caclulateSummDiscount global', this.order.discounts);
    console.log('total_order', this.total_order);

    this.isLine = -1;
    this.isGlobal = -1;
    let indexLine = -1;

    if (this.order.discounts) {
      this.isGlobal = this.order.discounts.map((e) => e.approved).indexOf(true);
    }
    if (this.order_lines) {
      this.isLine = this.order_lines.map((e, i) => {
        if (e.discounts) {
          indexLine = i;
          return e.discounts.map((f) => f.approved).indexOf(true);
        }
      })[0];
    }

    console.log(this.isGlobal, indexLine, this.isLine);
    console.log('caclulateSummDiscount isLines', this.order_lines[this.isLine]);

    this.diff_discount_order = 0;
    let approved;
    let val;

    if (this.isGlobal != -1) {
      approved = this.order.discounts[this.isGlobal];

      let discount_value = approved.value;
      let discount_symbol = approved.discount_type;

      console.log(approved, discount_value);
      console.log(this.total_order);
      console.log(this.diff_discount_order);

      switch (discount_symbol) {
        case 'P':
          val = this.calcPercent(this.total_order, discount_value);

          if (val >= 0) {
            this.total_discount_order -= Number(val);
            this.diff_discount_order += Number(val);
          }
          break;

        case 'S':
          val = this.calcSubtraction(this.total_order, discount_value);

          if (val >= 0) {
            this.total_discount_order -= Number(val);
            this.diff_discount_order += Number(val);
          }
          break;

        case 'D':
          break;

        default:
          break;
      }

      let rowGlobal = {
        id: '',
        local_id: 999999,
        order: 0,
        product: 0,
        description: 'SCONTO GLOBALE APPLICATO',
        qty: 1,
        cost: -this.total_discount_order,
        deleted: false,
        created: '2022-12-12T17:32:07.030446+01:00',
        discounts: [],
      };

      this.order_lines.push(rowGlobal);
    }
    if (this.isLine != -1) {
      this.order_lines.forEach((row: any) => {
        console.log('Single rows discount line', row);

        let val = row.cost;
        let approved = row.discounts
          ? row.discounts.filter((e) => e.approved)
          : [];

        console.log(approved, approved.length);
        console.log(row, val);

        if (approved.length == 0 && row.qty != 0) {
          console.log('val * row.qty', val * row.qty);
          this.diff_discount_order += Number(val * row.qty);
        } else if (approved.length != 0 && row.qty != 0) {
          let discount_value = approved[0].value;
          let discount_symbol = approved[0].discount_type;

          console.log(row, row.qty, discount_value);

          switch (discount_symbol) {
            case 'P':
              val = this.calcPercent(row.cost * row.qty, discount_value);
              break;

            case 'S':
              val = this.calcSubtraction(row.qty * row.cost, discount_value);
              break;

            // Gestione del caso 'D' e di qualsiasi altro caso non specificato
            case 'D':
            default:
              // Potresti voler aggiungere logica qui, o semplicemente non fare nulla
              break;
          }

          if (val >= 0) {
            this.total_discount_order -= Number(val);
            this.diff_discount_order += Number(val);
          }
        }
        // Non ci sono 'return' qui perché `.forEach()` non si aspetta valori di ritorno
      });
    }

    console.log('diffff', this.diff_discount_order);
  }

  // Calcola lo sconto sulla linea percent
  calcPercent(val, percentage) {
    console.log('calcPercent', val, percentage);

    let result = val * (percentage / 100);
    //console.log(result);

    return val - result;
  }

  // Calcola lo sconto sulla linea fixed
  calcSubtraction(tot, discount_value) {
    console.log('calcSubtraction', tot, discount_value);

    //console.log('tot, diff', tot, diff);

    return tot - discount_value;
  }

  // Calcola lo sconto sulla linea a seconda del tipo
  calculateSingleDiscount(val, row) {
    return row.cost * row.qty;

    /* if (!row.discounts)
      return

    let approved = row.discounts.filter(e => e.approved)
    console.log('calculateSingleDiff approved', approved, val, row);

    if (approved.length == 0 || row.qty != 0) {
      return val * row.qty
    } else if (approved.length == 0 || row.qty == 0) {
      return 0
    }

    let discount_value = approved[0].value
    let discount_symbol = approved[0].discount_type

    //console.log('approved2', val);

    switch (discount_symbol) {
      case 'P':
        val = this.calcPercent(val * row.qty, discount_value)
        break;

      case 'S':
        val = this.calcSubtraction(val * row.qty, discount_value)
        break;

      case 'D':

        break;

      default:
        break;
    }

    return val >= 0 ? val : 0 */
  }

  // Calcola lo sconto sulla linea a seconda del tipo
  calculateSingleDiff(val, row) {
    if (!row.discounts) return;

    let approved = row.discounts.filter((e) => e.approved);
    console.log('calculateSingleDiff approved', approved, val, row);

    if (approved.length == 0 && row.qty != 0) {
      return val * row.qty;
    } else if (approved.length == 0 && row.qty == 0) {
      return 0;
    } else if (approved.length != 0 && row.qty != 0) {
      let discount_value = approved[0].value;
      let discount_symbol = approved[0].discount_type;

      //console.log('approved2', val);

      switch (discount_symbol) {
        case 'P':
          //console.log(val, row.qty, discount_value);
          val = this.calcPercent(val * row.qty, discount_value);
          //console.log(val);

          break;

        case 'S':
          //console.log(val, row.qty, discount_value);
          val = this.calcSubtraction(val * row.qty, discount_value);
          //console.log(val);
          break;

        case 'D':
          break;

        default:
          break;
      }

      return val >= 0 ? val : 0;
    }
  }

  compareStatusOrderOfstatusListOrder() {
    console.log('compareStatusOrderOfstatusListOrder', this.order);
    //this.availableChangeStatusOrder = []

    let id = this.order.status.id;
    console.log(id);

    let current = this.listStatus.filter((o1) => o1.id == id);

    console.log(current);

    if (current) {
      this.availableChangeStatusOrder = current[0].post;
      console.log(typeof current[0].post);
      console.log(typeof current[0].pre);
      console.log(current[0].post);
      console.log(current[0].pre);
      //this.availableChangeStatusOrder += current[0].pre
      //this.availableChangeStatusOrder += current[0].pre
    }
    console.log(this.availableChangeStatusOrder);
  }

  compareStatusOrderOfstatusListOrderBack() {
    console.log('compareStatusOrderOfstatusListOrder', this.order);
    //this.availableChangeStatusOrder = []

    let id = this.order.status.parent?.id;
    console.log(id);

    console.log(this.listStatusBack);

    let current = this.listStatusBack.filter((o1) => o1.id == id);

    console.log(current);

    if (current) {
      this.availableChangeStatusOrderBack = current;
      console.log(this.availableChangeStatusOrderBack);

      console.log(typeof current[0].post);
      console.log(typeof current[0].pre);
      console.log(current[0].post);
      console.log(current[0].pre);
      //this.availableChangeStatusOrder += current[0].pre
      //this.availableChangeStatusOrder += current[0].pre
    }
    console.log(this.availableChangeStatusOrderBack);
  }

  checkInvoiced(event?: UIEvent) {
    console.log(event);
    this.customAlertOptions.header = 'Order status';
    let header = this.customAlertOptions.header;
    this.customAlertOptions.header = header;

    if (this.totalInvoiceIn_completed) {
      //this.actionSheetController.dismiss()+ ': ' + this.order?.status?.code
      setTimeout(async () => {
        //await this.actionSheetController.dismiss()
      }, 100);

      console.log(this.elementSelectStatus);
      // if(this.elementSelectStatus[0].code === 'ORD' && !this.sendOrder){
      //   this.disableChip = true
      // }
      console.log(this.order.status);
      if (this.ActionPermsOrd.includes(this.order.status[0].code)) {
        this.disableChip = true;
      }

      //return this.presentAlert('Attenzione', 'limite fattutre raggiunto', 'ok')
    }
    //this.elementSelectStatus.onClose()
  }

  checkInvoicedBack(event?: UIEvent) {
    console.log(event);
    console.log(this.order);

    this.customAlertOptions.header = 'Order status';
    let header = this.customAlertOptions.header;
    this.customAlertOptions.header = header;

    if (this.totalInvoiceIn_completed) {
      //this.actionSheetController.dismiss()+ ': ' + this.order.status?.parent?.code
      setTimeout(async () => {
        //await this.actionSheetController.dismiss()
      }, 100);

      console.log(this.elementSelectStatusBack);
      // if(this.elementSelectStatus[0].code === 'ORD' && !this.sendOrder){
      //   this.disableChip = true
      // }
      console.log(this.order.parent);
      if (this.ActionPermsOrd.includes(this.order.parent.code)) {
        this.disableChip = true;
      }

      //return this.presentAlert('Attenzione', 'limite fattutre raggiunto', 'ok')
    }
    //this.elementSelectStatus.onClose()
  }

  async updateStatus(string, status) {
    const loading = await this.loadingController.create();
    await loading.present();
    console.log(status);
    console.log(status.detail.value);
    this.elementSelectStatus['el'].value = null;

    if (status.detail.value == null) return;

    if (
      status.detail.value &&
      status.detail.value != 0 &&
      status.detail.value != '-'
    ) {
      status['id'] = status.detail.value.id;

      this.showProgressStatus = true;
      const updatedOrderStatus: any = { status_id: status.id };
      console.log(updatedOrderStatus, string);

      this.order_orders_createForm.controls['status_id'].setValue(status.id);
      /*const projectUpdated = await */
      console.log(this.order);
      if (status.detail.value.name !== 'Ordine') {
        this.orderApi
          .order_order_update(this.order.id, this.order_orders_createForm.value)
          .subscribe((e) => {
            console.log(e);
            if (e.message && e.message.includes('OK')) {
              this.presentToast(e.message, 'success');

              this.order.status = status;
              this.ionViewWillEnter();
              this.response = 'OK';
            } else {
              this.presentToast(e.message, 'danger');
            }

            this.showProgressStatus = false;
            loading.dismiss();
          });
      } else if (
        this.order.dates.length !== 0 &&
        this.order.payments.lenght !== 0
      ) {
        let total = 0;
        this.order.payments.forEach((e) => {
          total += e.amount;
        });
        if (total === this.order.cost) {
          this.orderApi
            .order_order_update(
              this.order.id,
              this.order_orders_createForm.value
            )
            .subscribe((e) => {
              console.log(e);
              if (e.message && e.message.includes('OK')) {
                this.presentToast(e.message, 'success');

                this.order.status = status;
                this.ionViewWillEnter();
                this.response = 'OK';
              } else {
                this.presentToast(e.message, 'danger');
              }

              this.showProgressStatus = false;
              loading.dismiss();
            });
        } else {
          loading.dismiss();
          this.checkOrder(this.order);
        }
      } else {
        loading.dismiss();
        this.checkOrder(this.order);
      }

      // this.orderApi.order_order_update(this.order.id, this.order_orders_createForm.value).subscribe(e => {
      //   console.log(e);
      //   if (e.message && e.message.includes('OK')) {
      //     this.presentToast(e.message, 'success')

      //     this.order.status = status;
      //     this.ionViewWillEnter();
      //     this.response = 'OK';

      //   } else {

      //     this.presentToast(e.message, 'danger')

      //   }

      //   this.showProgressStatus = false;
      //   loading.dismiss()
      // });
      /* if (projectUpdated != null) {
        this.router.navigate(['/projects-list']);
      } */

      setTimeout(() => {
        this.showProgressStatus = false;
      }, 7000);
    } else if (status.detail.value == 0 && !this.totalInvoiceIn_completed) {
      this.goToInvoice();
    } else if (status.detail.value == 0 && this.totalInvoiceIn_completed) {
      //this.presentAlert('Attenzione', 'limite fattutre raggiunto!s', 'ok')
    }
  }

  checkOrder(order: any) {
    let checkPayment: boolean = false;
    let checkDates: boolean = false;
    let checkAmount: boolean = false;
    if (order.payments.length === 0) {
      checkPayment = true;
    }
    if (order.dates.length === 0) {
      checkDates = true;
    }
    if (!checkPayment) {
      let total = 0;
      order.payments.forEach((e) => {
        total += e.amount;
      });
      if (total !== order.cost) {
        checkAmount = true;
      }
    }

    this.presentAlertCheckOrder(checkPayment, checkDates, checkAmount);
  }

  async presentAlertCheckOrder(
    checkPayment: boolean,
    checkDates: boolean,
    checkAmount: boolean
  ) {
    let message = '';

    if (checkPayment) {
      message += `<div style="color: red; font-weight: bold;">&#9888; Il seguente ordine non contiene nessuna informazione sui pagamenti.</div><br>`;
    }
    if (checkDates) {
      message += `<div style="color: orange; font-weight: bold;">&#9888; Il seguente ordine non contiene nessuna informazione sulle date.</div><br>`;
    }
    if (checkAmount) {
      message += `<div style="color: blue; font-weight: bold;">&#9888; Il costo delle linee dell'ordine non coincide con le linee di pagamento.</div><br>`;
    }

    if (message && (checkAmount || checkPayment)) {
      const alert = await this.alertController.create({
        header: 'Attenzione',
        subHeader: `Pagamenti non inseriti, continuare?`,
        message: message,
        cssClass: 'alert-warning-modal-appcomponent',
        buttons: [
          {
            text: 'Annulla',
            role: 'cancel',
            handler: () => {
              console.log('Alert canceled');
            },
          },
          {
            text: 'Prosegui',
            role: 'confirm',
            handler: () => {
              this.orderApi
                .order_order_update(
                  this.order.id,
                  this.order_orders_createForm.value
                )
                .subscribe((e) => {
                  console.log(e);
                  if (e.message && e.message.includes('OK')) {
                    this.presentToast(e.message, 'success');

                    this.order.status = status;
                    this.ionViewWillEnter();
                    this.response = 'OK';
                  } else {
                    this.presentToast(e.message, 'danger');
                  }

                  this.showProgressStatus = false;
                });
            },
          },
        ],
      });

      await alert.present();
    }
    else {
      const alert = await this.alertController.create({
        header: 'Attenzione',
        subHeader: `Impossibile proseguire con l'avanzamento dell'ordine`,
        message: message,
        cssClass: 'alert-warning-modal-appcomponent',
        buttons: ['OK'],
      });

      await alert.present();
    }
   
  }

  async updateStatusBack(string, status) {
    const loading = await this.loadingController.create();
    await loading.present();
    console.log(status);
    console.log(status.detail.value);
    this.elementSelectStatusBack['el'].value = null;

    if (status.detail.value == null) return;

    if (
      status.detail.value &&
      status.detail.value != 0 &&
      status.detail.value != '-'
    ) {
      status['id'] = status.detail.value;

      this.showProgressStatus = true;
      const updatedOrderStatus: any = { status_id: status.id };
      console.log(updatedOrderStatus, string);

      this.order_orders_createForm.controls['status_id'].setValue(status.id);
      /*const projectUpdated = await */
      this.orderApi
        .order_order_update(this.order.id, this.order_orders_createForm.value)
        .subscribe((e) => {
          console.log(e);
          if (e.message && e.message.includes('OK')) {
            this.presentToast(e.message, 'success');

            this.order.status = status;
            this.ionViewWillEnter();
            this.response = 'OK';
          } else {
            this.presentToast(e.message, 'danger');
          }

          this.showProgressStatus = false;
          loading.dismiss();
        });
      /* if (projectUpdated != null) {
        this.router.navigate(['/projects-list']);
      } */

      setTimeout(() => {
        this.showProgressStatus = false;
      }, 7000);
    } else if (status.detail.value == 0 && !this.totalInvoiceIn_completed) {
      this.goToInvoice();
    } else if (status.detail.value == 0 && this.totalInvoiceIn_completed) {
      //this.presentAlert('Attenzione', 'limite fattutre raggiunto!s', 'ok')
    }
  }

  async presentToast(text, color) {
    const toast = await this.toastController.create({
      message: text,
      position: 'bottom',
      duration: 3000,
      cssClass: 'toast',
      color: color,
    });
    return await toast.present();
  }

  getAllstatusadvanceLine(lines) {
    let array = [];
    lines.map((e) => {
      this.invoiceApi.getStatusAdvance(e).subscribe((e) => {
        console.log(e);
        array.push(e);
      });
    });
    return array;
  }

  addLineToInvoice(type, event) {
    const newInvoice = {
      type_id: '',
      vendor_id: this.order.vendor.id,
      customer_id: this.order.customer.id,
      lines: this.lineAdvanceToInvoice,
    };

    console.log(event, newInvoice);

    this.showPromptSelectTypeInvoice(newInvoice);
  }

  showPromptSelectTypeInvoice(newInvoice) {
    let inputs = [];
    this.listTypesInvoice.map((e) => {
      console.log(e);

      let item = {
        type: 'radio',
        label: e.name,
        value: e.id,
      };
      inputs.push(item);
    });

    this.alertController
      .create({
        header: 'TYPE INVOICE',
        message: 'Select status of invoice',
        inputs: inputs,
        buttons: [
          {
            text: 'Cancel',
            handler: (data: any) => {
              console.log('Canceled', data);
            },
          },
          {
            text: 'Create',
            handler: (data: any) => {
              console.log('Saved Information', data);

              newInvoice.type_id = data;
              console.log(newInvoice);

              this.invoiceApi.createInvoice(newInvoice).subscribe((ele) => {
                console.log(ele);
                if (!ele.data) return;

                ele.data.lines.map((d) => {
                  //console.log(d, this.order_lines);

                  this.order_lines.map((e, i, a) => {
                    //console.log(e.complete.details.filter(adv => adv.id === d.id));

                    let lines = e.complete.details.filter(
                      (adv) => adv.id !== d.id
                    );
                    e.complete.details = lines;
                  });

                  this.selectedFilterLinesRows.map((e, i, a) => {
                    //console.log(e.complete.details.filter(adv => adv.id === d.id));

                    let lines = e.complete.details.filter(
                      (adv) => adv.id !== d.id
                    );
                    e.complete.details = lines;
                  });
                });
                this.order_lines = [...this.order_lines];
              });
            },
          },
        ],
      })
      .then((res) => {
        res.present();
      });
  }

  filterLineResponse(lines) {
    console.log(lines);
    lines.map((line_res) => {
      this.order_lines.map((line_order) => {
        if (line_res.orderline == line_order.id)
          this.orderApi
            .getDetailsLinesOrder(line_res.orderline)
            .subscribe((e) => {
              console.log(e);
              return (line_order = e);
            });
      });
    });
  }

  changeAdvanceToInvoice(advance_id, checked) {
    console.log(advance_id, checked.checked);
    console.log(this.prepareLinesOrderToInvoice);

    if (checked.checked) this.prepareLinesOrderToInvoice.push(advance_id);
    else if (!checked.checked)
      this.prepareLinesOrderToInvoice = this.prepareLinesOrderToInvoice.filter(
        (e) => e != advance_id
      );
    else console.log('else');

    this.lineAdvanceToInvoice = this.prepareLinesOrderToInvoice;
    console.log(this.lineAdvanceToInvoice);
  }

  onActivate(event, type, canEdit) {
    console.log('Activate Event', event, type);

    if (type == 'legal' && event.type == 'click') {
      this.modalLegalCreateUpdate('legal', 'update', canEdit, event);
    } else if (type == 'extra' && event.type == 'click') {
      this.modalLegalCreateUpdate('extra', 'update', canEdit, event);
    } else if (type == 'line' && event.type == 'click') {
      this.modalLegalCreateUpdate('line', 'update', canEdit, event);
    } else if (type == 'payments' && event.type == 'click') {
      this.modalLegalCreateUpdate('payments', 'update', canEdit, event);
    } else if (event.type == 'click') {
      //this.modalLegalCreateUpdate('legal', 'update', canEdit, event)
    }
  }

  /**
   *
   * @param type create, update
   * @param row
   * @returns
   */

  async modalLegalCreateUpdate(type_order_meta, type_order_form, canEdit, row) {
    console.log(type_order_meta, type_order_form, canEdit, row);

    const modal = await this.modalController.create({
      component: ModalOrderLegalComponent,
      canDismiss: true,
      mode: 'md',
      componentProps: {
        data: row,
        order_id: this.order.id,
        type: type_order_meta,
        form: type_order_form,
        canEdit: true, // canEdit
        // amount: this.order.cost
      },
    });

    modal.onWillDismiss().then((e) => {
      console.log(e);
      if (!e.data) {
        console.log('no modify');
        return;
      } else {
        this.ionViewWillEnter();
        this.response = e.data;
      }
    });
    return await modal.present();
  }

  /**
   *
   * @param type create, update
   * @param row
   * @returns
   */

  async modalUploadFile(r) {
    const modal = await this.modalController.create({
      component: ModalOrderUploadFileComponent,
      canDismiss: true,
      mode: 'md',
      componentProps: {
        order_id: this.order.id,
        data: r.row,
      },
    });

    modal.onWillDismiss().then((e) => {
      console.log(e);
      if (!e.data) {
        console.log('no modify');
        return;
      } else {
        this.ionViewWillEnter();
        this.response = e.data;
      }
    });
    return await modal.present();
  }

  remove() {
    this.selectedLegalsRows = [];
    this.selectedExtraRows = [];
  }

  displayCheck(row) {
    return row.active !== false;
  }
  /* 
    goToInvoices(event) {
  
      this.modalController.dismiss(this.response).then(() => {
        this.nav.push(InvoicesListPage, { order_id: this.order.id }, { animated: true, direction: 'forward' })
      })
    } */

  async openPopoverDiscounts(discount, row) {
    console.log(this.order.discounts);
    console.log(discount);
    console.log(row);

    if (this.geCountDiscount(row) == 0) {
      //console.log('subito inserisci sconto');
      this.openNewDiscountModal('line', '', row);
    } else {
      const popover = await this.modalController.create({
        component: DiscountLineComponent,
        //event: event,
        backdropDismiss: true,
        //cssClass: 'popover-setting',
        componentProps: {
          order: this.order,
          lines_discount: discount,
          //event: event,
          line: row,
          type: 'line',
        },
        //translucent: true
      });

      popover.onDidDismiss().then((e) => {
        console.log(e);

        // cambiare nell'array dei discount della linea dell'ordine quello aprroved  altrimenti non setta subito l'option group con il valore dell'ultimo approvato
        if (e.data != undefined && e.data != '' && e.data != null) {
          var foundIndex = discount.findIndex((x) => x.id == e.data.id);
          discount[foundIndex] = e.data;
        }

        if (!e.data) {
          console.log('no modify');
          return;
        } else {
          console.log('ON Dissmiss app-discount-line from app-order-details :');

          console.log(e.data.data);
          this.ionViewWillEnter();
        }
      });
      return await popover.present();
    }
  }

  /**
   * @description Create new order, open modal
   * @returns
   */
  async openNewDiscountModal(type, event, row) {
    console.log(row, type);

    const modal = await this.modalController.create({
      component: ModalOrderDiscountLineComponent,
      backdropDismiss: true,
      mode: 'md',
      cssClass: 'custom-modal',
      componentProps: {
        title: 'on line ' + row.id,
        line: row,
        type: type,
        order_: this.order,
      },
      //presentingElement: this.routerOutlet.nativeEl,
    });

    modal.onWillDismiss().then((e) => {
      // Se Ok passo la risposta di conferma dell'api per risparmiare sui servizi http
      console.log(e);

      if (e.data != undefined) {
        // Fai si che aggiorna la linea dove viene aggiunto lo sconto
        let index = this.order_lines.findIndex(
          (x) => x.id === e.data.orderline
        );
        let order_line = this.order_lines.filter(
          (x) => x.id === e.data.orderline
        );
        order_line[0].discounts.push(e.data);
        this.order_lines.map(
          (obj) => order_line.find((x) => x.id === e.data.orderline) || obj
        );
      }

      // Adesso che hai aggiunto il discount fai il replace

      if (!e.data) {
        console.log('no modify');
        return;
      } else {
        //this.ionViewWillEnter(e.data)
      }
    });
    return await modal.present();
  }

  geCountDiscount(line) {
    return line.discounts.length;
  }

  getApprovedDiscount(discounts) {
    //console.log(discounts);
    if (discounts.length == 0) return 'no';

    let approved = discounts.filter((e) => e.approved);
    //console.log(approved);
    if (approved.length == 0) return 'no';

    let value =
      approved[0].discount_type == 'S'
        ? approved[0].value.toFixed(2)
        : approved[0].value;
    let symbol =
      approved[0].discount_type == 'P'
        ? '%'
        : approved[0].discount_type == 'S'
        ? ' '
        : approved[0].discount_type;

    return value + ' ' + symbol;
  }

  async openPopoverDiscountsToolbar(event) {
    console.log(this.order.discounts);
    console.log(event);

    const popover = await this.modalController.create({
      component: DiscountLineComponent,
      //event: event,
      backdropDismiss: true,
      //cssClass: 'popover-setting',
      componentProps: {
        order: this.order,
        event: event,
        type: 'global',
      },
      //translucent: true
    });

    popover.onWillDismiss().then((e) => {
      console.log(e);
      if (!e.data) {
        console.log('no modify');
        return;
      } else {
        // cambiare nell'array dei discount della linea dell'ordine quello aprroved  altrimenti non setta subito l'option group con il valore dell'ultimo approvato

        //get Order //Temporanea da togliere
        /*   this.order.discounts =
            this.orderApi.getOrderById(this.order.id, '').subscribe(e => {
              console.log(e);
              this.order.discounts = e.discounts
            })
   */

        console.log(e.data.data);
        this.ionViewWillEnter();
      }
    });

    return await popover.present();
    /** Sync event from popover component */
  }

  async openPopoverAdvanceLine(a, b, c, event) {
    console.log(a, b, c);

    const popover = await this.modalController.create({
      component: InvoiceLineComponent,
      //event: event,
      backdropDismiss: true,
      //cssClass: 'popover-setting',
      componentProps: {
        //event: event,
        order: this.order,
        line: c,
        type: a,
      },
      //translucent: true
    });

    popover.onDidDismiss().then((e) => {
      console.log(e);
      if (!e.data) {
        console.log('no modify');
        return;
      } else {
        console.log(e.data, this.order_lines);

        let orderLinesIndex = this.order_lines
          .map((e, i, a) => e.id)
          .indexOf(c.id);
        console.log(orderLinesIndex);

        this.order_lines[orderLinesIndex].complete.value =
          this.order_lines[orderLinesIndex].complete.value + e.data.value;
        this.order_lines[orderLinesIndex].complete.type = e.data.advance_type;

        if (this.order_lines[orderLinesIndex].complete.details)
          this.order_lines[orderLinesIndex].complete.details.push(e.data);

        // if product on response
        this.order_lines = [...this.order_lines];
        console.log(this.order_lines);
      }
    });

    return await popover.present();
    /** Sync event from popover component */
  }

  updateValue(event, cell, rowIndex) {
    console.log('inline editing_local_id_cell rowIndex', rowIndex);
    console.log(this.editing_local_id_cell);
    console.log(this.editing_local_id_cell[rowIndex + '-' + cell]);
    console.log(this.order_lines[rowIndex][cell]);

    this.editing_local_id_cell[rowIndex + '-' + cell] = false;
    this.order_lines[rowIndex][cell] = event.target.value;
    this.order_lines = [...this.order_lines];
    console.log(
      this.order_lines[rowIndex].id,
      this.order_lines[rowIndex][cell]
    );

    let form = {
      id: this.order_lines[rowIndex].id,
      local_id: Number(this.order_lines[rowIndex][cell]),
    };

    this.orderApi.updateNumberingIdLocal(this.order, form).subscribe((e) => {
      console.log(e);
      if (!e.data) {
        console.log('no modify');
        return;
      } else {
        console.log(
          'UPDATED!',
          this.order_lines[rowIndex][cell],
          this.editing_local_id_cell
        );
        console.log(this.order_lines);

        this.order_lines = [...e.data.order_lines];

        this.selectedFilterLinesRows = [];
      }
    });
  }

  checkCompleteAdvanceLine(line) {
    if (!line.complete) return '';

    if (
      (line.complete.value == 100 && line.complete.type == 'P') ||
      (line.complete.value == line.qty && line.complete.type == 'N')
    )
      return true;
    else return '';
  }

  concatInput(string) {
    setTimeout(() => {
      this.inputEl.setFocus();
    }, 50);

    return (string = true);
  }

  async presentActionSheetTaxes(item, ev: Event) {
    let buttons = [];
    console.log(item);

    this.listTaxes.map((e) => {
      buttons.push({
        text: e.name,
        //icon: 'thermometer-outline'
        handler: () => {
          console.log(e, ' clicked');
          item.clicked = false;
          item.tax = { id: e.id, name: e.name, code: e.code };
          this.invoiceApi.putAdvance(item, { tax_id: e.id }).subscribe((e) => {
            console.log(e);
          });
        },
      });
    });

    buttons.push({
      text: 'Cancel',
      icon: 'close',
      role: 'cancel',
      handler: () => {
        console.log('Cancel clicked');
        item.clicked = false;
      },
    });

    let actionSheet = await this.actionSheetCtrl.create({
      header: 'Tax lines',
      cssClass: 'my-custom-class',
      buttons: buttons,
    });

    await actionSheet.present();

    const { role } = await actionSheet.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
    console.log(this.order_lines);
  }

  changeTax(index, event) {
    console.log(index, event.detail.value);
    this.order_lines[index].tax = event.detail.value;

    console.log(this.order_lines[index]);
  }

  print_reomte() {
    this.orderApi.getOrdersPrint(this.order).subscribe((data) => {
      var fileName = 'test.pdf';
      let blob: any = new Blob([data], {
        type: 'application/pdf; charset=utf-8',
      });
      console.log(blob);
      var fileURL = window.URL.createObjectURL(blob);
      window.open(fileURL);

      //importedSaveAs(blob, fileName)
    });
  }

  parseString(string) {
    console.log(string);

    //string = string.replace(/'/g, '"')
    try {
      return JSON.parse(string);
    } catch (error) {
      console.log(error);
    }
  }

  async print() {
    console.log(this.order);
    this.apiPrint.print(this.order);
  }

  async export_lines() {
    var doc = new jsPDF();

    let headers = [];
    let cell = [];
    var totalPagesExp = '{total_pages_count_string}';

    //this.columns_line.map(e => {
    //  headers.push(e.name)
    //})
    headers = [
      'id',
      'local_id',
      'complete',
      'invoiced',
      'qty',
      'cost',
      'discount',
      'discountCost',
    ];

    for (let i = 0; i <= 0; i++) {
      this.order_lines.map((e) => {
        cell.push([
          e.id,
          e.local_id,
          e.complete.value + ' ' + e.complete.type,
          e.invoiced.value + ' ' + e.invoiced.type,
          e.qty,
          e.cost.toFixed(2) + '€',
          0,
          this.calculateSingleDiscount(e.cost, e).toFixed(2) + '€',
        ]);
      });
    }

    let creted_at =
      'Data creazione: ' +
      this._datePipe.transform(Date.now(), 'dd/MM/yyyy HH:mm:ss');
    let creator = 'Creatore: '; // + this.user.datas[0].firstname + ' ' + this.user.datas[0].lastname;
    let total = this.order_lines.reduce((sum, el) => sum + el.id, 0);

    console.log(cell);

    cell.push([
      {
        content: `ID = ${total}`,
        colSpan: 2,
        styles: { fillColor: [239, 154, 154] },
      },
    ]);

    doc.setFontSize(17);
    doc.text('CoolProjects Export data', 67, 17);
    doc.setTextColor(76, 175, 80);
    doc.setFontSize(10);
    doc.text('LISTA', 67, 23);
    doc.setTextColor(0, 0, 0);
    doc.text(creted_at, 67, 27);
    doc.text(creator, 67, 31);
    //doc.setFontSize(40);
    //doc.text("Export lista pratiche", 12, 25);
    doc.addImage('assets/img/logo_w.png', 'PNG', 15, 10, 50, 12);

    // Or use javascript directly:
    autoTable(doc, {
      head: [headers],
      body: cell,
      startY: 50,
      headStyles: {
        //fillColor: '#000',
        textColor: '#fff',
        halign: 'left',
        valign: 'middle',
        //lineColor: Color;
      },
      didParseCell: function (data) {
        var rows = data.table.body;
        if (data.row.index === rows.length - 1) {
          data.cell.styles.fillColor = [239, 154, 154];
        }
      },

      didDrawPage: function (data) {
        console.log(data, data.table, data.table.pageNumber);

        // Footer
        var str = 'Pagina ' + doc.getNumberOfPages();

        doc.setFontSize(10);

        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        var pageSize = doc.internal.pageSize;
        var pageHeight = pageSize.height
          ? pageSize.height
          : pageSize.getHeight();
        doc.text(str, data.settings.margin.right, pageHeight - 10);
      },
    });

    await doc.save('Test.pdf');
  }

  /* async export_legals() {
   
    var doc = new jsPDF();
   
    let headers = [];
    let cell = [];
    var totalPagesExp = '{total_pages_count_string}'
   
    //this.columns_line.map(e => {
    //  headers.push(e.name)
    //})
    headers = ['id', 'local_id', 'complete', 'invoiced', 'qty', 'cost', 'disount', 'discountCost']
   
   
    for (let i = 0; i <= 0; i++) {
      this.order_lines.map(e => {
        cell.push([e.id, e.local_id, e.complete.value + ' ' + e.complete.type, e.invoiced.value + ' ' + e.invoiced.type, e.qty, (e.cost).toFixed(2) + '€', 0, (this.calculateSingleDiscount(e.cost, e)).toFixed(2) + '€'])
      })
    }
   
   
    let creted_at = "Data creazione: " + this._datePipe.transform(Date.now(), 'dd/MM/yyyy HH:mm:ss');
    let creator = "Creatore: "// + this.user.datas[0].firstname + ' ' + this.user.datas[0].lastname;
    let total = this.order_lines.reduce((sum, el) => sum + el.id, 0);
   
    console.log(cell);
   
    cell.push([{
      content: `ID = ${total}`, colSpan: 2,
      styles: { fillColor: [239, 154, 154] }
    }])
   
   
    doc.setFontSize(17);
    doc.text("CoolProjects Export data", 67, 17);
    doc.setTextColor(76, 175, 80);
    doc.setFontSize(10);
    doc.text("LISTA", 67, 23)
    doc.setTextColor(0, 0, 0);
    doc.text(creted_at, 67, 27)
    doc.text(creator, 67, 31)
    //doc.setFontSize(40);
    //doc.text("Export lista pratiche", 12, 25);
    doc.addImage("assets/img/logo_w.png", "PNG", 15, 10, 50, 12);
   
    // Or use javascript directly:
    autoTable(doc, {
      head: [headers],
      body: cell,
      startY: 50,
      headStyles: {
        //fillColor: '#000',
        textColor: '#fff',
        halign: "left",
        valign: "middle",
        //lineColor: Color;
      },
      didParseCell: function (data) {
        var rows = data.table.body;
        if (data.row.index === rows.length - 1) {
          data.cell.styles.fillColor = [239, 154, 154];
        }
      },
   
      didDrawPage: function (data) {
   
        console.log(data, data.table, data.table.pageNumber);
   
        // Footer
        var str = "Pagina " + doc.getNumberOfPages();
   
        doc.setFontSize(10);
   
        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        var pageSize = doc.internal.pageSize;
        var pageHeight = pageSize.height
          ? pageSize.height
          : pageSize.getHeight();
        doc.text(str, data.settings.margin.right, pageHeight - 10);
      }
    })
   
    await doc.save('Test.pdf');
   
  } */

  /**
   * Get Address Legal
   */
  getAddressLegal(e) {
    console.log(e);
    return e?.address
      ? e?.address?.map((addr: any) => {
          //get first address legal
          if (addr?.name != 'work') {
            let p = addr?.position;
            return (
              p?.street + ' ' + p?.number + ', ' + p?.city + ', ' + p?.nation
            );
          } else return '';
        })
      : [];
  }

  /** Change Tab Order */
  onSetTab(event) {
    console.log(event);
    console.log('prev', this.tab_order);
    this.tab_order = event;
    console.log('next', this.tab_order);

    setTimeout(() => {
      this.createcolumns();
    }, 400);
  }

  getChipColor(tab): string {
    return this.selectedTab === tab.label ? 'tertiary' : 'primary';
  }

  getDateExpectedStop(event) {
    console.log(event);
  }

  accordionGroupChange = (ev: any) => {
    const collapsedItems = this.values.filter(
      (value) => value !== ev.detail.value
    );
    const selectedValue = ev.detail.value;
    this.bindingAccordion =
      selectedValue === undefined ? 'None' : ev.detail.value;
    // return this.bindingAccordion
  };

  async presentAlert(header, subHeader, message, path?, buttons?) {
    const alert = await this.alertController.create({
      cssClass: 'alert-warning-modal-appcomponent',
      header: header,
      subHeader: subHeader,
      message: message,
      //mode:'ios',
      buttons: [
        /*  {
           text: 'Cancel',
           role: 'cancel',
           cssClass: 'secondary',
           handler: () => {
             console.log('Confirm Cancel');
           }
         },  */
        {
          text: 'Ok',
          handler: () => {
            console.log('Confirm Ok');
            //path != 'update' ? this.router.navigateByUrl(path) : this.market.open(environment.appId);
          },
        },
        {
          text: 'Edit Project',
          handler: () => {
            console.log('Confirm edit prj');
            //path != 'update' ? this.router.navigateByUrl(path) : this.market.open(environment.appId);
            this.modalOrderEdit();
          },
        },
      ],
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
  }

  async alertPayment() {
    const alert = await this.alertController.create({
      cssClass: 'alert-warning-modal-appcomponent',
      header: 'Attenzione',
      message: "Aggiungi prima una linea d'ordine",
      buttons: [
        {
          text: 'Ok',
          handler: () => {
            console.log('Confirm Ok');
          },
        },
      ],
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
  }

  checkIsLineDiscountsApproved(item?) {
    console.log(
      'checkIsLineDiscountsApproved' /* , this.order, this.order_lines */
    );

    return this.order_lines.map((line) => {
      console.log(line);

      if (line.discounts && line.discounts.length != 0) {
        line.discounts.map((item) => {
          if (item.approved == true) {
            console.log(line);
            return (this.disableGlobalDiscounts = true);
          } else return '';
        });
      }
    });
  }

  checkIsGlobalDiscountsApproved(item?) {
    console.log(
      'checkIsGlobalDiscountsApproved' /* , this.order, this.order_lines */
    );
    // if (this.order?.discounts)
    //   return this.order.discounts.map(line => {
    //     console.log(line);

    //     if (line.approved == true) {
    //       console.log(line);
    //       console.log(this.columns_line);
    //       /* this.isGlobal != -1 ? */
    //       if (this.columns_line && this.columns_line[5].name == 'Description') {
    //         this.columns_line.splice(5, 1)/*  : null */
    //       }
    //       console.log('isGlobal', this.isGlobal);
    //       console.log(this.columns_line);

    //       return this.disableLineDiscounts = true;
    //     }
    //   })
  }

  getListFiles(e?) {
    /*  this.api.getFiles2(this.order.id).subscribe(e => {
       console.log(e);
       if (!e.data) {
         console.log('no modify');
         return
       } else {
 
         this.order.documents = e.data
 
       }
 
     }) */
    this.reloadUpload = false;

    this.orderApi.order_order_documents_list(this.order.id).subscribe((e) => {
      console.log(e);
      this.files = e.data;
      this.reloadUpload = true;
      this.simulateControlKeyPress();

      console.log(this.files);

      setTimeout(() => {}, 300);
    });
  }

  goToInvoice() {
    console.log(this.order);

    if (
      this.order?.status?.id >= 3 &&
      (!this.order?.invoice_term ||
        !this.order?.payment_term ||
        !this.order?.payment_type)
    ) {
      this.presentAlert(
        'Attention',
        'Infos project missing',
        ' Fields invoice-terms, payment-terms or payment-types'
      );
    }
    let form: any = {};
    form['order_id'] = this.order.id;
    form['type_id'] = 2; // status invoice defintivo
    form['vendor_id'] = this.order.vendor.id;
    form['customer_id'] = this.order.customer.id;

    this.invoiceApi.createInvoice(form).subscribe((e) => {
      console.log(e);
    });
  }

  async openSelect(event) {
    const loading = await this.loadingController.create();
    await loading.present();
    console.log(event);

    this.gotoBack = false;
    console.log(this.elementSelectStatus);
    setTimeout(() => {
      this.elementSelectStatus['el'].click();
      loading.dismiss();
    }, 200);
  }
  gotoBack = true;
  async openSelectBack(event) {
    const loading = await this.loadingController.create();
    await loading.present();
    console.log(event);

    this.gotoBack = true;
    console.log(this.elementSelectStatusBack);
    setTimeout(() => {
      this.elementSelectStatusBack['el'].click();
      loading.dismiss();
    }, 200);
  }

  onGetRowClass = (row) => {
    //console.log(row);
    // id 3 is Delete for Status Order
    let classes = '';
    /* if (row.status.id === 4 || row.status.id === 5) {
      classes += ' deleted';
    } else if (row.status.id === 2) {
      classes += ' request';
    } else if (row.status.id === 1) {
      classes += ' draft';
    } else if (row.status.id === 3) {
      classes += ' order';
    } */

    if (row.product === 0) {
      classes += ' total-discount';
    } /* else if (row.status.id === 3) {
      classes += ' order';
    } else if (row.status.id === 3) {
      classes += ' order';
    } else if (row.status.id === 3) {
      classes += ' order';
    } */

    return classes;
  };

  click(event: any) {
    event.preventDefault();
  }

  onRightClick(event?: any, row?: any) {
    if (this.currentMouse === 'mouseenter' || this.currentMouse === 'click') {
      console.log(event);

      event.preventDefault();

      console.log('Click destro rilevato');
    }
  }

  onActivateLines(event) {
    // console.log(event);
    this.currentRow = event.row;
    this.currentMouse = event.type;
    if (event.type === 'dblclick') {
      const textToCopy = event.row?.description;

      this.clipboard.copy(textToCopy);
      console.log('Testo copiato:', textToCopy);
    }
    if (event.type === 'click' && event.cellIndex !== 0) {
      this.editLine('line', 'update', this.currentRow);
    }
  }

  onActivateExtras(event) {
    // console.log(event);
    this.currentRow = event.row;
    this.currentMouse = event.type;
    if (event.type === 'dblclick') {
      const textToCopy = event.row?.description;

      this.clipboard.copy(textToCopy);
      console.log('Testo copiato:', textToCopy);
    }
    if (event.type === 'click' && event.cellIndex !== 0) {
      this.editLine('extra', 'update', this.currentRow);
    }
  }

  onActivateLegals(event) {
    // console.log(event);
    this.currentRow = event.row;
    this.currentMouse = event.type;
    if (event.type === 'dblclick') {
      const textToCopy = event.row?.description;

      this.clipboard.copy(textToCopy);
      console.log('Testo copiato:', textToCopy);
    }
    if (event.type === 'click' && event.cellIndex !== 0) {
      this.editLine('legal', 'update', this.currentRow);
    }
  }

  onActivatePayments(event) {
    console.log(event);
    this.currentRow = event.row;
    this.currentMouse = event.type;
    if (event.type === 'click' && event.cellIndex !== 0) {
      this.editLine('payments', 'update', this.currentRow);
    }
  }

  copyText = false;
  copyToClipboard(text: string): void {
    if (text) {
      const textToCopy = text;
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          console.log('Testo copiato negli appunti:', textToCopy);
          // Mostra un messaggio all'utente, se necessario
          this.copyText = true;
          setTimeout(() => {
            this.copyText = false;
          }, 1000);
        })
        .catch((err) => {
          console.error('Errore durante la copia negli appunti:', err);
        });
    } else {
      console.log('Nessun testo tra parentesi quadre trovato');
      // Gestisci il caso in cui non ci sono parentesi quadre
    }
  }

  selectFiles(event): void {
    this.isDisableUpload = true;
    console.log('this.disabled_expired', this.disabled_expired);
    this.loading.dismiss();

    if (!this.disabled_expired) {
      console.log('select files', this.isDisableUpload);

      //if (!this.presentModalExpires()) return
    } else {
      this.isDisableUpload = false;
      //this.presentModalExpires()
      this.presentPromntName();
    }
    console.log(event.target.files);

    this.progressInfos = [];
    console.log(this.selectedFiles);
    this.selectedFiles = event?.target?.files;
    console.log(this.selectedFiles);
  }

  item: any;

  async presentPromntName(ev?: any) {
    let bool = false;
    if (this.item != null) {
      bool = true;
    }

    const alert = await this.alertController.create({
      cssClass: 'alert-input-modal',
      header: 'Name file',
      //subHeader: 'insert name file',
      //message: '',
      //mode:'ios',
      inputs: [
        {
          placeholder: 'Name (max 100 characters)',
          value: this.item?.name ? this.item?.name : '',
          cssClass: 'input-text-modal',
          attributes: {
            maxlength: 100,
          },
          disabled: bool,
        },
      ],
      buttons: [
        /*  {
           text: 'Cancel',
           role: 'cancel',
           cssClass: 'secondary',
           handler: () => {
             console.log('Confirm Cancel');
           }
         },  */
        {
          text: 'Cancel',
          handler: (e) => {
            console.log('Undo');
            //path != 'update' ? this.router.navigateByUrl(path) : this.market.open(environment.appId);
            this.reset();
          },
        },
        {
          text: 'Upload',
          handler: (e) => {
            console.log(e);

            console.log('Confirm Ok', e, e[0]);
            //path != 'update' ? this.router.navigateByUrl(path) : this.market.open(environment.appId);
            this.nameFile = e[0];
            // this.codeFile = e[1]
            this.uploadFiles();
          },
        },
      ],
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
  }
  selectedFiles; //: FileList;
  // Dichiarazione oggetto relativo la bara avanzamento upload
  progressInfos = [];
  message = '';

  files: any = [];
  module: any;

  isRenew: any;
  data: any;
  disabled_expired = true;
  update: any = false;

  codeFile;
  nameFile;

  // @Output() emitEventUpload: EventEmitter<any> = new EventEmitter();

  @ViewChild('myInput')
  myInputVariable: ElementRef;
  @Input() showListFile: any; // Se true abilita in auto integrazione

  expires;
  isDisableUpload = true;
  uploadFiles(): void {
    this.message = '';
    console.log('upload-files');
    for (let i = 0; i < this.selectedFiles.length; i++) {
      console.log(i);
      console.log(this.selectedFiles.length);
      console.log(this.selectedFiles);
      console.log(this.selectedFiles[i]);
      this.upload(i, this.selectedFiles[i]);
    }
  }

  async upload(idx, file?, params?) {
    this.module = { type: 'orders', data: this.order };
    console.log(file, params, this.item, this.data);
    this.files = [];
    if (!this.nameFile) return;

    //console.log(this.item.folders[0].folder.id);
    console.log(file);
    console.log(this.codeFile);
    console.log(this.nameFile);
    var formData = new FormData();
    formData.append('orderfile', file);
    // formData.append('code', this.codeFile);
    formData.append('name', this.nameFile);

    formData.forEach((value, key) => {
      console.log(key + ': ' + value);
    });

    /* 
    formData.append('parent_id', this.item.folders[0].folder.id)
    formData.append('expires', this.datePipe.transform(this.expires, 'dd-MM-yyyy'));
    */
    /*     const loading = await this.loadingController.create({
          message: 'Uploading image...',
        });
    
        await loading.present(); 
    */

    if (!this.module) {
      this.presentToast('Please pass a module', 'warning');
      return;
    }

    {
      // Chiamo l'api update upload
      console.log(this.module.data.id);
      console.log(formData);
      this.orderApi
        .order_order_documents_create(this.module.data.id, formData)
        .subscribe({
          next: (res: any) => {
            this.reset();
            console.log('next', res);

            if (!res?.message?.includes('OK')) {
              console.log('errore');

              //return throwError(() => new Error('message'));
            }

            let show = this.showListFile;
            if (this.progressBar(event, idx, file)) {
              this.showListFile = !show;
              //loading.dismiss();
              this.presentToast(' File upload complete.', 'dark');

              this.cd.detectChanges();
              this.showListFile = show;
              this.isDisableUpload = true;

              /*   let url = '?page=1&items=' + this.page_limit;
              this.api.getFiles(this.item.folders[0].folder.id, url).subscribe(e => {
            }); */

              if (this.isRenew) {
                this.dismissModal({ item: this.item, data: this.data });
              }
            }

            this.alertController.dismiss(null);

            if (this.update) {
              this.modalController.dismiss({ update: this.update, res: res });
            }

            // this.emitEventUpload.emit()
            this.reset();
          },
          error(err) {
            console.log('error', err);
            this.presentToast('File upload failed.', 'danger');
            this.progressInfos[idx].value = 0;
            this.message = 'Could not upload the file:' + file.name;
            //loading.dismiss();
          },
          complete: () => {
            console.log('done');
            this.getListFiles();
          },
        });
    }
  }

  reset() {
    console.log(this.myInputVariable);
    this.render.setValue(this.myInputVariable['el'], '');
    console.log(this.myInputVariable);
    this.myInputVariable['el'].value = '';
    this.progressInfos = [];
  }
  progressBar(event, idx, file) {
    console.log(event, idx, file);
    this.progressInfos[idx] = { value: 0, fileName: file.name };
    console.log(event);
    if (HttpEventType.UploadProgress) {
      //if (event.type === HttpEventType.UploadProgress) {
      this.progressInfos[idx].value = Math.round(
        (100 * event.loaded) / event.total
      );
      return false;
    } else if (event instanceof HttpResponse) {
      return event;
    } else return '';
  }

  downloadFile(item) {
    console.log('list-file getdoc', item);
    console.log(this.module);

    // return
    // Chiamo l'api docs download  by order_id e doc_id
    this.orderApi
      .order_order_document_download_list(item.order.id, item)
      .subscribe((res) => {
        console.log(res, item);

        this._FileSaverService.save(
          res.body,
          item.name + this.getFileExtension(item.orderfile)
        );

        if (res && res.data.id && item) {
          //this.openFileInfosModal(res.data, item, item);
        } else {
          console.log('else openFileModal');
          this.presentToast('Error get file.', 'danger');
        }
      });
  }
  async openFileDeleteModal(infos, item?, file?) {
    console.log(this.module);
    this.module = { type: 'orders', data: this.order };
    this.orderApi
      .order_order_document_delete(this.module.data.id, infos)
      .subscribe((res) => {
        console.log(res, infos);
        this.getListFiles(true);
      });
  }
  async openFileUpdateModal(infos, item?, file?) {
    /* const checkPropmt = await this.modalController.getTop();
    if (checkPropmt) {
      this.modalController.dismiss();
    } */

    const modal = await this.modalController.create({
      component: UploadDesktopComponent,
      canDismiss: true,
      keyboardClose: true,
      breakpoints: [0, 0.2],
      initialBreakpoint: this.platform.is('desktop') ? 0.1 : 0.1,
      componentProps: {
        nameFile: infos.name,
        codeFile: infos.code,
        item: infos,
        update: true,
        header: {},
        module: this.module,
        showListFile: false,
        disabled_expired: true,
        colGridFile: [12, 12, 12],
      },
      //presentingElement: this.routerOutlet.nativeEl,
    });

    modal.onWillDismiss().then((e) => {
      console.log(e);

      if (e.data && e.data.res) {
        //this.canClick = true;
        //this.loadProjects(this.currentSegment);
        this.getListFiles(true);
      }
    });

    //this.ionLoaderService.dismissLoader();
    return modal.present().finally(() => {});
  }

  getFileExtension(filename) {
    const lastIndex = filename.lastIndexOf('.');

    // Se un punto è presente nella stringa e non è il primo carattere
    if (lastIndex !== -1 && lastIndex !== 0) {
      return filename.substring(lastIndex); // Restituisce la stringa dal punto in poi
    } else {
      return ''; // Restituisce una stringa vuota se non c'è un'estensione
    }
  }
  loading: any;
  async openLoadingController() {
    this.loading = await this.loadingController.create();

    this.loading.present();
  }
}
