// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.img-wrapper {
  min-height: 120px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

ion-img {
  margin: 0 auto;
  display: inline;
}

.border-dashed-padd {
  padding: 10px;
}

/* ion-item-divider {
    background: var(--ion-background-color, #f1f1f1);
    color: #3880ff;
} */
ion-item-divider {
  --background: #0000;
  color: --ion-color-secondary;
}

/* ion-item-divider {
    background: var(--ion-color-primary, #3880ff);
    color: var(--ion-color-primary, #3880ff);
} */
ion-row {
  border: 0px solid #425980;
  border-radius: 4px;
  margin: 4px 0px;
}

ion-title {
  font-family: "Source Sans Pro";
  font-size: 16px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/media/upload-desktop/upload-desktop.component.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,2BAAA;EACA,wBAAA;EACA,4BAAA;AACJ;;AACA;EACI,cAAA;EACA,eAAA;AAEJ;;AAAA;EAII,aAAA;AAAJ;;AAGA;;;GAAA;AAMA;EAEI,mBAAA;EACA,4BAAA;AAHJ;;AAOA;;;GAAA;AAKA;EACI,yBAAA;EACA,kBAAA;EACA,eAAA;AALJ;;AASA;EACI,8BAAA;EACA,eAAA;AANJ","sourcesContent":[".img-wrapper {\n    min-height: 120px;\n    background-position: center;\n    background-size: contain;\n    background-repeat: no-repeat;\n}\nion-img {\n    margin: 0 auto;\n    display: inline;\n}\n.border-dashed-padd {\n    //border: 1px dashed #444;\n    //border-radius: 2px;\n    //margin: 8px;    \n    padding: 10px;\n}\n\n/* ion-item-divider {\n    background: var(--ion-background-color, #f1f1f1);\n    color: #3880ff;\n} */\n\n\nion-item-divider {\n    \n    --background: #0000;\n    color: --ion-color-secondary;\n\n}\n\n/* ion-item-divider {\n    background: var(--ion-color-primary, #3880ff);\n    color: var(--ion-color-primary, #3880ff);\n} */\n\nion-row {\n    border: 0px solid #425980;\n    border-radius: 4px;\n    margin: 4px 0px;\n}\n\n\nion-title{\n    font-family: 'Source Sans Pro';\n    font-size: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
