import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencySymbol'
})
export class CurrencySymbolPipe implements PipeTransform {

  constructor() { }
  transform(value: any, curr: string): string {
    try {
      if (curr === "" || curr === null) {
        return value
      }
      else {
        return value + ' ' + curr;
      }
    } catch (error) {
      return value + ' ' + curr;
    }

  }

}
