import { Inject, Injectable } from '@angular/core';
import { IonNav, Platform } from '@ionic/angular';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class PlatformService {
  autoWidth = new Subject<any>();
  
  component = new Subject<any>();
  params = new Subject<any>();
  out = new Subject<any>();
  constructor(
    private platform: Platform
  ) { }


  getPlatform() {
    if (
      this.platform.is('ios')
      || this.platform.is('android')
      && !(this.platform.is('desktop'))) {

      console.log('mobile');
      return 'mobile'

    } else {

      console.log('browser');
      return 'browser'
    }

  }

  setDimensionWidthDisplay() {
    setTimeout(() => {
        let menu = document.querySelector('ion-menu').clientWidth
        console.log(menu, window.innerWidth);
        this.autoWidth.next(window.innerWidth - menu);
        console.log(this.autoWidth);
    }, 250);
}


}